'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default () => SvgHelper(
  `<path d="M0.625 5.625L5.625 0.625M5.625 0.625H3.125M5.625 0.625V3.125" stroke="#3B96FF" stroke-linecap="round" stroke-linejoin="round"/>
   <path d="M9.375 4.375L4.375 9.375M4.375 9.375L6.875 9.375M4.375 9.375L4.375 6.875" stroke="#3B96FF" stroke-linecap="round" stroke-linejoin="round"/>`,
  {
    width: `10`,
    height: `10`,
    viewBox: `0 0 10 10`
  }
);
/* eslint-enable */

// @flow
'use strict';

import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { getActiveTheme, renderTheme } from '../helpers/ThemeHelper.js';
import * as MarketsApi  from "../helpers/api/MarketsApi.js";
import { getPublicTrades, getPublicMarketDepthData } from "../helpers/api/ExchangeApi.js";
import { getPublicOhlc } from "../helpers/api/BalanceApi.js";
import type { MarketOrder } from '../types/MarketOrder.js';
import MarketOrderbookPanel from '../components/boards/MarketOrderbookPanel.jsx';
import MarketChartPanelChart from '../components/boards/MarketChartPanelChart.jsx';
import MarketDepthChartPanel from '../components/boards/MarketDepthChartPanel.jsx';
import PriceTrendPanel from '../components/boards/PriceTrendPanel.jsx';
import StreamingTradesPanel from '../components/boards/StreamingTradesPanel.jsx';
import DemoSettingsEditModal from './demo/DemoSettingsEditModal.jsx';
import DemoMarketSwitcher from './demo/DemoMarketSwitcher.jsx';
import DemoHeader from '../components/demo/DemoHeader.jsx';

type OHLCV = {
  open: number,
  high: number,
  low: number,
  close: number,
  volume: number,
  timeStart: string,
  timeEnd: string,
  closingTradeTime: string,
  openingTradeTime: string,
};


const THEME = `Dark`;
const API_POLL_MS = 20000;
const ONE_HOUR = 3600000;
const ONE_DAY = ONE_HOUR * 24;

const BOARDS = {
  'orderbook': {
    needMarketDepth: true
  },
  'depth-chart': {
    needMarketDepth: true
  },
  'chart': {
    needOhlc: true
  },
  'price-trend': {
    needTrades: true
  },
  'trades': {
    needTrades: true
  },
};

const Demo = () => {
  const [intervalId, setIntervalId] = useState(null);
  const [isLoadingDepth, setIsLoadingDepth] = useState(true);
  const [isLoadingTrades, setIsLoadingTrades] = useState(true);
  const [isLoadingOHLCV, setIsLoadingOHLCV] = useState(true);
  const [settings, setSettings] = useState(null);
  const [market, setMarket] = useState(null);
  const [panelData, setPanelData] = useState({});
  const [marketSelectorModalOpen, setMarketSelectorModalOpen] = useState(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [markets, setMarkets] = useState([]);
  const [ohlcv, setOhlcv] = useState([]);
  const [search, setSearch] = useState(``);
  const [size, setSize] = useState([0, 0]);
  const [showHeader, setShowHeader] = useState(true);
  const routerLocation = useLocation();
  const history = useHistory();

  const boardKey = Object.keys(BOARDS).find((b) => routerLocation.pathname.indexOf(`/${b}`) > -1);
  const board = boardKey && BOARDS.hasOwnProperty(boardKey) ? BOARDS[boardKey] : {};

  const getParam = (search, param) => {
    const urlParams = new URLSearchParams(search);
    return urlParams.get(param);
  };

  const sortOrders = (orders: Array<MarketOrder>): Array<MarketOrder> => {
    return orders.sort((a, b) => {
      if (a.price > b.price) return 1;
      if (b.price > a.price) return -1;
      return 0;
    });
  };

  const aggOHLCV = (ohlcv: Array<any>, agg: number): Array<OHLCV> => {
    return Array.from(ohlcv.reduce((m, o) => {
      let k = +(new Date(o.timeStart)) - (+(new Date(o.timeStart)) % agg);
      m.set(k, (m.get(k) || []).concat([o]));
      return m;
    }, new Map())).map(([timeStart: number, a: Array<OHLCV>]): OHLCV => {
      return a.reduce((r, o, j) => {
        if (j !== 0) r.volume += o.volume;
        if (o.high > r.high) r.high = o.high;
        if (o.low < r.low) r.low = o.low;
        if (j == (a.length - 1)) {
          r.close = o.close;
          let nextBarEnd = (+(new Date(o.timeEnd)) + agg) - ((+(new Date(o.timeEnd)) + agg) % agg) - 1000;
          r.timeEnd = new Date(nextBarEnd).toISOString();
        }
        return r;
      }, { ...a[0], timeStart: (new Date(timeStart)).toISOString() });
    });
  };

  const sortOHLCV = (ohlcv: Array<OHLCV>) => {
    return ohlcv.sort((a, b) => {
      if (+(new Date(a.timeStart)) < +(new Date(b.timeStart))) return -1;
      if (+(new Date(b.timeStart)) < +(new Date(a.timeStart))) return 1;
      return 0;
    });
  };

  const getBoardData = () => {
    const exch = getParam(routerLocation.search, `exchange`);
    const baseCurrCode = getParam(routerLocation.search, `baseCurrCode`);
    const quoteCurrCode = getParam(routerLocation.search, `quoteCurrCode`);

    if (exch && baseCurrCode && quoteCurrCode) {
      MarketsApi.getMarkets(exch, baseCurrCode, quoteCurrCode, 1)
        .then((data) => {
          if (data.success && data.result.length > 0) {
            const mkt = data.result[0];
            setMarket(mkt);
            setSettings({ exchange: mkt.exchCode, market: mkt.displayName });
            
            board?.needMarketDepth && getPublicMarketDepthData(mkt.exchCode, mkt.displayName)
              .then((data) => {
                if (data.success) {
                  setPanelData(
                    (prev) => {
                      const currentMkt = prev[`${mkt.exchCode}:${mkt.displayName}`] || {};
                      currentMkt.depth =  {
                        asks: sortOrders(data.result.asks).slice(0, 500),
                        bids: sortOrders(data.result.bids).reverse().slice(0, 500).reverse()
                      };

                      return {
                        ...prev,
                        [`${mkt.exchCode}:${mkt.displayName}`]: currentMkt
                      };
                    }
                  );
                  setIsLoadingDepth(false);
                }
              });

              board?.needTrades && getPublicTrades(mkt.exchCode, mkt.displayName)
              .then((data) => {
                if (data.success) {
                  setPanelData(
                    (prev) => {
                      const currentMkt = prev[`${mkt.exchCode}:${mkt.displayName}`] || {};
                      currentMkt.trades = data.result.map((t) => ({ ...t, backfillTrade: true }));

                      return {
                        ...prev,
                        [`${mkt.exchCode}:${mkt.displayName}`]: currentMkt
                      };
                    }
                  );                  
                  setIsLoadingTrades(false);
                }
              });

              board?.needOhlc && getPublicOhlc({ exchange: exch, market: mkt.displayName, data: `1m` })
                .then((data) => {
                  const _data = data.result.map((o) => ({ ...o, timeStart: o.startTime, timeEnd: o.endTime, date: new Date(o.startTime) }));
                  const _aggOHLCV = aggOHLCV(_data, ONE_DAY);
                  setOhlcv(sortOHLCV(_aggOHLCV));
                  setIsLoadingOHLCV(false);
                });
          }
        });
    }
  };

  const getFullMarkets = () => {
    if (search.length !== 1)  MarketsApi.getMarketSummaries(50, 1 ,1 ,1, 0, search, true).then((data) => setMarkets(data.result));
  };

  const updateRouterLocation = ({ exchange = null, baseCurrCode = null, quoteCurrCode = null, type = null, showVolume = null, showVolumeProfile = null }) => {
    const exch = exchange || getParam(routerLocation.search, `exchange`) || ``;
    const baseCode = baseCurrCode || getParam(routerLocation.search, `baseCurrCode`) || ``;
    const quoteCode = quoteCurrCode || getParam(routerLocation.search, `quoteCurrCode`) || ``;
    const chartType = type ?? getParam(routerLocation.search, `type`);
    const volume = showVolume ?? getParam(routerLocation.search, `showVolume`);
    const volumeProfile = showVolumeProfile ?? getParam(routerLocation.search, `show-olume-profile`);

    const path = `${routerLocation.pathname}?exchange=${exch}&baseCurrCode=${baseCode}&quoteCurrCode=${quoteCode}
${chartType ? `&type=${chartType}` : ``}${volume ? `&showVolume=${volume}` : ``}${volumeProfile ? `&showVolumeProfile=${volumeProfile}` : ``}`;

    history.push(path);
  };

  useEffect(() => {
    getFullMarkets();
  }, [search]);

  useEffect(() => {
    getBoardData();

    const interval = setInterval(() => {
      getBoardData();
    }, API_POLL_MS);
      
    setIntervalId(interval);
  }, [routerLocation.search, size]);

  useEffect(() => {
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [intervalId]);

  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight - 30]);
    };
    window.addEventListener(`resize`, updateSize);
    updateSize();
    return () => window.removeEventListener(`resize`, updateSize);
  }, []);

  return (
    <div className={ `app demo-page ${ getActiveTheme(THEME).bordersNoShadows ? `borders-no-shadows` : `` }` }>
      { showHeader && renderTheme(THEME) }
      <main>
        { showHeader &&
          <DemoHeader 
            title={ `${ market?.exchName || `` } ${ market?.displayName || `` }` } 
            hasEditAction={ boardKey === `chart` }
            showSettingsModalOpen={ setSettingsModalOpen }
            showMarketSelectorModalOpen={ setMarketSelectorModalOpen }/>
        }

        <Switch>
          <Route
            path="/demo/board/orderbook"
            render={ () =>
              !isLoadingDepth && settings && <div className={ `panel` }>
                <MarketOrderbookPanel market={ market } settings={ settings } panelData={ panelData }/>
              </div>
            } />
          <Route
            path="/demo/board/depth-chart"
            render={ () =>
              !isLoadingDepth && settings && <MarketDepthChartPanel 
                market={ market } 
                settings={ settings } 
                panelData={ panelData } 
                limitDeviation={ false } 
                theme={ THEME }
                height={ size[1] } 
                width={ size[0] } />
            } />
          <Route
            path="/demo/board/chart"
            render={ (routeProps) => {
              return !isLoadingOHLCV && settings && ohlcv.length && <MarketChartPanelChart 
                market={ market } 
                settings={ settings } 
                panelData={ panelData } 
                height={ size[1] } 
                width={ size[0] }
                realHeight={ 4 } 
                realWidth={ 6 } 
                type={ getParam(routeProps.location.search, `type`) ||  `candlestick` } // ['line' 'area' 'candlestick']
                ohlcv={ ohlcv } 
                showVolume={ [1, `1`, `true`, true].includes(getParam(routeProps.location.search, `showVolume`)) }
                showVolumeProfile={ [1, `1`, `true`, true].includes(getParam(routeProps.location.search, `showVolumeProfile`)) }
                name={ `${settings.exchange}:${settings.market}` }
                theme={ THEME }/>;
            } } />
          <Route
            path="/demo/board/trades"
            render={ () =>
              !isLoadingTrades && settings && <div className={ `panel` }>
                <StreamingTradesPanel market={ market } settings={ settings } panelData={ panelData }/>
              </div>
            } />
          <Route
            path="/demo/board/price-trend"
            render={ () =>
              !isLoadingTrades && settings && <PriceTrendPanel market={ market } settings={ settings } panelData={ panelData }/>
            } />
          <Route
            path="/demo/board/popout"
            render={ () => setShowHeader(false) } />
          <Route path="" render={ () => <Redirect to="/" /> } />
        </Switch>
        {
          market && marketSelectorModalOpen && (
            <DemoMarketSwitcher
              callback={ (props) => { updateRouterLocation(props); setMarketSelectorModalOpen(false); } }
              markets={ markets }
              active={ { exchmktId: market?.exchmktId } } 
              search={ search } 
              changeSearch={ setSearch }/>
          )
        }
        {
          market && settingsModalOpen && (
            <DemoSettingsEditModal
              settings={ {
                type: getParam(routerLocation.search, `type`) ||  `candlestick`,
                showVolume: getParam(routerLocation.search, `showVolume`),
                showVolumeProfile: getParam(routerLocation.search, `showVolumeProfile`),
              } }
              changeSettings={ updateRouterLocation } 
              close={ () => setSettingsModalOpen(false) } />
          )
        }
      </main>
    </div>
  );
};

export default Demo;

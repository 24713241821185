'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path class="book" d="M12.667 10.667V2H4.333a1 1 0 0 0-1 1v7.891a2.324 2.324 0 0 1 1-.224h8.334zm0 1.333H4.333a1 1 0 0 0 0 2h8.334v-2zM4.333.667h9c.369 0 .667.298.667.666v13.334a.667.667 0 0 1-.667.666h-9A2.333 2.333 0 0 1 2 13V3A2.333 2.333 0 0 1 4.333.667z"/>`,
  {
    width: `1.5rem`,
    height: `1.5rem`,
    viewBox: `0 0 15 15`
  }
);
/* eslint-enable */

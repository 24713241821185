// @flow
'use strict';

import React from 'react';
import { connect } from 'react-redux';
import Chart from './Chart.jsx';
import { getActiveTheme } from '../../helpers/ThemeHelper.js';
import { getColor } from '../../helpers/BalancesColorHelper.js';

type Props = {
  hasData: boolean,
  onBalanceClick: ({ start: *, end: * }) => void,
  currencyHistoryData: Map<string, Array<any>>,
  balances: Array<any>,
  theme: string,
  selectedQuoteCurrencyCode: string,
};

export const BalancesStackedBarChartComponent = (props: Props) => {
  const { currencyHistoryData, balances, theme, selectedQuoteCurrencyCode, hasData } = props;
  const keys = Array.from(currencyHistoryData.keys());
  let dataSetLength = 0;
  let timeSeries = [];

  keys.forEach((k) => {
    let v = currencyHistoryData.get(k);
    if (v && v.length > dataSetLength) {
      dataSetLength = v.length;
      timeSeries = v.map((d) => d.balanceDate).reverse();
    }
  });

  const categories = timeSeries.map((t) => new Date(t));
  const series = [];
  const selectedCurrencies = balances.map((item) => item.balanceCurrCode);

  currencyHistoryData.forEach((value, currency) => {
    if (selectedCurrencies.includes(currency)) {
      series.push({
        name: currency,
        data: timeSeries.map((d) => (value.find((v) => v.balanceDate == d) || { quoteBalance: 0 }).quoteBalance),
        color: getColor(selectedCurrencies.indexOf(currency))
      });
    }
  });

  const axesColor = getActiveTheme(theme, true)[`--body-text-color-alpha-54`];
  const tickColor = getActiveTheme(theme, true)[`--body-text-color-alpha-8`];
  const config = {
    legend: {
      enabled: true,
      itemStyle: {
        color: axesColor,
      },
      itemHiddenStyle: {
        color: getActiveTheme(theme, true)[`--body-text-color-alpha-20`]
      },
      itemWidth: 100,
    },
    title: {
      text: ``,
    },
    tooltip: {
      backgroundColor: getActiveTheme(theme).body,
      borderColor: `transparent`,
      borderRadius: 4,
      style: {
        color: getActiveTheme(theme).gray2,
      },
      headerFormat: ``,
      pointFormat: `<span style="color:{point.color}">\u25CF</span> {series.name}: ` + 
                         `<b>{point.y} ${ selectedQuoteCurrencyCode.toUpperCase() }</b><br/>`,
      valueDecimals: selectedQuoteCurrencyCode.toUpperCase() == `BTC` ? 8 : 2
    },
    plotOptions: {
      series: {
        animation: false,
        events: {
          click: (e) => props.onBalanceClick({
            start: +new Date(e.point.category) - 86400000,
            end: +new Date(e.point.category)
          })
        }
      },
      column: {
        stacking: `normal`,
        dataLabels: {
          enabled: false,
        },
        borderColor: `transparent`,
      }
    },
    chart: {
      type: `column`,
      backgroundColor: getActiveTheme(theme)[`--gray-2-theme-alpha`],
      zoomType: `xy`,
      height: `40%`,
      id: `balanceChart`,
      resetZoomButton: {
        theme: {
          display: `block`
        }
      }
    },
    credits: {
      enabled: false
    },
    navigator: {
      enabled: false
    },
    scrollbar: {
      enabled: false
    },
    rangeSelector: {
      inputEnabled: false,
      buttonTheme: {
        visibility: `hidden`
      },
      labelStyle: {
        visibility: `hidden`
      }
    },
    series,
    xAxis: {
      type: `datetime`,
      categories,
      lineWidth: 1,
      lineColor: `${ axesColor }`,
      labels: {
        enabled: true,
        style: {
          color: `${ axesColor } !important`,
          fontSize: `1.2rem`
        },
        format: `{value:%b %e}`
      },
    },
    noData: {
      style: {
        fontWeight: `bold`,
        fontSize: `15px`,
        color: `#fff`
      }
    },
    yAxis: {
      gridLineWidth: 1,
      tickLength: 0,
      tickWidth: 1,
      opposite: true,
      tickPosition: `inside`,
      tickAmount: 8,
      title: {
        enabled: false,
      },
      lineWidth: 1,
      lineColor: `transparent`,
      labels: {
        style: {
          color: `${axesColor} !important`,
          fontSize: `1.2rem`
        },
        formatter: function () {
          return this.value + ` ${ selectedQuoteCurrencyCode.toUpperCase() }`;
        }
      },
      gridLineColor: `${tickColor}`
    }
  };

  return (
    <Chart hasData={ hasData } options={ config }/>
  );
};

const mapStateToProps = (state) => ({
  theme: state.redisPrefs.theme,
  selectedQuoteCurrencyCode: state.app.selectedQuoteCurrencyCode
});

export default connect(mapStateToProps)(BalancesStackedBarChartComponent);

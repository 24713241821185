// @flow
'use strict';

import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getMarketFromPanelSettings } from '../../helpers/MarketPairHelper.js';
import { getActiveTheme } from '../../helpers/ThemeHelper.js';
import MarketLargeDepthChart from '../markets/MarketLargeDepthChart.jsx';
import MarketDepthChartExtraInfo from '../markets/MarketDepthChartExtraInfo.jsx';
import type { Market } from '../../types/Market.js';
import type { Exchange } from '../../types/Exchange.js';

type Settings = {
  exchange: string,
  market: string
};

type Props = {
  t: any,
  market: Market,
  exchanges: Array<Exchange>,
  settings: Settings, 
  panelData: any,
  width: number,
  height: number,
  limitDeviation: boolean,
  theme: string
};

const MarketDepthChartPanel = ({ t, market, settings, panelData, width, height, limitDeviation, theme }: Props) => {
  let mkt = `${ settings.exchange }:${ settings.market }`;

  if (panelData.hasOwnProperty(mkt) && 
    panelData[mkt].depth && 
    panelData[mkt].depth.bids &&
    panelData[mkt].depth.bids.length > 0 &&
    panelData[mkt].depth.asks &&
    panelData[mkt].depth.asks.length > 0 &&
    market) {

    const { bids, asks } = panelData[mkt].depth;

    const maxBid = bids.sort((a, b) => a.price < b.price ? 1 : -1)[0].price;
    const minAsk = asks.sort((a, b) => a.price > b.price ? 1 : -1)[0].price;

    const lastPrice = panelData[mkt]?.trades?.length > 0 ? panelData[mkt].trades[0].price : null;
    const lastTradeType= panelData[mkt]?.trades?.length > 0 ? panelData[mkt].trades[0].type : ``;

    return (
      <div className="market-depth-chart-panel">
        <MarketDepthChartExtraInfo market={ market } maxBid={ maxBid } minAsk={ minAsk } lastPrice={ lastPrice } lastTradeType={ lastTradeType } exchCode={ settings.exchange }/>
        <MarketLargeDepthChart
          width={ width - (4 * parseInt(getActiveTheme(theme).appScale)) }
          height={ height }
          buys={ bids }
          sells={ asks }
          limitDeviation={ limitDeviation }
          market={ market }
          maxBid={ maxBid }
          minAsk={ minAsk }
          theme={ theme }
          resetZoomText={ t(`markets:resetZoom`) } />
      </div>
    );
  } else {
    return (
      <div className="panel-loading">
        <span>
          { t(`app:loading`) }
        </span>
      </div>
    );
  }
};

MarketDepthChartPanel.getPanelTitle = (settings: Settings, markets: Array<Market>) => {
  let market = getMarketFromPanelSettings(markets, settings);

  return market ? market.exchName + ` - ` + market.displayName : `Market Depth Chart`;
};

const mapStateToProps = (state) => ({
  limitDeviation: state.redisPrefs.limitDepthChartDeviation,
  theme: state.redisPrefs.theme
});

export default translate(`boards`)(connect(mapStateToProps)(MarketDepthChartPanel));

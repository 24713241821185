// @flow
'use strict';

/**
 * It returns the index of the active exchange in the list of visible exchanges
 * @param props - any - this is the props object that is passed to the component.
 * @returns activeExchangeIndex
 */

import { useEffect, useState } from 'react';

export const useActiveExchangeIndex = (props: any) => {
  const [ activeExchangeIndex, setActiveExchangeIndex ] = useState(-1);
  const { visibleExchanges, activeExchMarket } = props;

  useEffect(() => {
    const index = visibleExchanges.findIndex((e) => e.exchId === activeExchMarket.exchange.exchId);    
    if (typeof activeExchMarket == `undefined`) {
      return setActiveExchangeIndex(-1);
    } else {
      setActiveExchangeIndex(index);
    }

  }, [visibleExchanges, activeExchMarket]);

  return activeExchangeIndex;
};

// @flow
'use strict';

import {
  SET_BALANCE_FILTERS,
  CHANGE_PORTFOLIO_CHART_TYPE
} from '../actions/index.js';

import { CHART_TYPE_LINE } from "../constants/balances.js";
import type { Balance } from '../types/Balance.js';

type State = {
  filters: Array<Balance>,
  chartType: string
};

type Action = {
  type: string,
  payload: any
};

export const initialState: State = {
  filters: [],
  chartType: CHART_TYPE_LINE
};

export default function balances(state: State = initialState, action: Action) {
  switch(action.type) {
  case SET_BALANCE_FILTERS:
    return {
      ...state,
      filters: action.payload
    };
  case CHANGE_PORTFOLIO_CHART_TYPE:
    return {
      ...state,
      chartType: action.payload
    };
  }
  return state;
}

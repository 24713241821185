// @flow
'use strict';

import React, { useEffect, useState } from 'react';
import { useVirtual } from 'react-virtual';
import { MarketSearchItem } from "../MarketSearch";
import type { Market } from '../../../types/Market.js';
import type { Favorite } from '../../../types/Favorite.js';
import type { Exchange } from '../../../types/Exchange.js';

type Props = {
  useCallback?: boolean,
  callback?: *,
  markets: Array<Market>,
  favorites: Array<Favorite>,
  active: {
    exchange: Exchange,
    market: Market
  },
  refListContainer: any,
  view: string,
};

export const MarketSearchList = (props: Props) => {
  const { markets, favorites, useCallback, callback, active, refListContainer, view } = props;

  const [displayVolume, setDisplayVolume] = useState(false);
  
  const rowVirtualizer = useVirtual({
    parentRef: refListContainer,
    size: markets.length,
    overscan: 5,
  });
  
  const { virtualItems: virtualMarkets, totalSize } = rowVirtualizer;
  const paddingTop = virtualMarkets.length > 0 ? virtualMarkets?.[0]?.start || 0 : 0;
  const paddingBottom = virtualMarkets.length > 0 ? totalSize - (virtualMarkets?.[virtualMarkets.length - 1]?.end || 0) : 0;

  const isActive = (market) => market.exchCode === active?.exchange?.exchCode && market.marketName === active?.market?.marketName;

  useEffect(() => {
    const id = setInterval( () => {
      setDisplayVolume((prev) => !prev);
    }, 4000);

    return () => clearInterval(id);
  }, []);

  return (
    <div className={ `market-search-list` }>
      <div>
        { paddingTop > 0 && (
          <div style={ { height: `${paddingTop}px` } } />
        ) }
        <ul>
          {
            virtualMarkets.map((m) => 
              <li key={ `${m.index}-${markets[m.index].exchmktId}` }>
                <MarketSearchItem 
                  market={ markets[m.index] }
                  favorites={ favorites }
                  useCallback={ useCallback }
                  callback={ callback }
                  isActive={ isActive(markets[m.index]) }
                  view={ view }
                  displayVolume={ displayVolume }/>
              </li>
            )
          }
        </ul>
        { paddingBottom > 0 && (
          <div style={ { height: `${paddingBottom}px` } } />
        ) }
      </div>
    </div>
  );
};

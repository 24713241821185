// @flow
import { useCallback, useState } from 'react';


const useSetState = <T:{}> (initialState: T): [T, any]=> {
  const [state, set] = useState<T>(initialState);

  const setState = useCallback((patch: any) => {
    set((prevState: T): T =>
      Object.assign({}, prevState, patch instanceof Function ? patch(prevState) : patch)
    );
  }, []);

  return [state, setState];
};

export default useSetState;

// @flow
'use strict';
import { $ } from './ApiHelper';
import type { Board } from "../../types/Board";

type Response = {
  result: any,
  success: boolean,
  error: string
};

type ResponseCallback = (data: Response) => void;

export const getPanelTypes = (success: ResponseCallback) => $({
  url: `/private/panels`,
  success
});

export const getBoards = (success: ResponseCallback) => $({
  url: `/private/user/boards`,
  success
});

export const getSharedBoard = (sourceTag: string, success: ResponseCallback) => $({
  url: `/private/user/board-previews/${ sourceTag }`,
  type: `POST`,
  success
});

export const addNewBoard = (label: string, success: ResponseCallback) => $({
  url: `/private/user/boards`,
  type: `POST`,
  body: {
    label,
    sortOrder: 0,
  },
  success
});

export const editBoard = ({ 
  board, 
  label, 
  sortOrder
}: { 
  board: Board, 
  label?: string,
  sortOrder?: number
}, success: ResponseCallback) => $({
  url: `/private/user/boards/${ board.boardId }`,
  type: `PUT`,
  body: {
    label: label || board.label,
    panelOrder: board.panelOrder,
    sortOrder: sortOrder || board.sortOrder
  },
  success
});

export const deleteBoard = (id: number, success: ResponseCallback) => $({
  url: `/private/user/boards/${ id }`,
  type: `DELETE`,
  success
});

export const addNewPanel = (boardId: number, body: any, success: ResponseCallback) => $({
  url: `/private/user/boards/${ boardId }/panels`,
  type: `POST`,
  body,
  success
});
export const editPanel = (boardId: number, panelId: number, body: any, success: ResponseCallback) =>
  $({
    url: `/private/user/boards/${ boardId }/panels/${ panelId }`,
    type: `PUT`,
    body,
    success,
  });

export const deletePanel = (boardId: number, id: number, success: ResponseCallback) => $({
  url: `/private/user/boards/${ boardId }/panels/${ id }`,
  type: `DELETE`,
  success
});

export const updatePanelOrder = (boardId: number, body: any, success?: ResponseCallback) => $({
  url: `/private/user/boards/${ boardId }/panel-order`,
  type: `PUT`,
  body,
  success
});

export const getBoardPanels = (boardId: number, success: ResponseCallback) => $({
  url: `/private/user/boards/${ boardId }/panels`,
  success
});

export const importBoard = (boardId: number, success: ResponseCallback) => $({
  url: `/private/user/boards/${ boardId }/status`,
  type: `PUT`,
  body: `imported`,
  success
});

export function postBoardSnapshots(body: any): Promise<any> {
  return new Promise((resolve, reject) => {
    $({
      url: `/private/user/board-snapshots`,
      type: `POST`,
      body,
      success: resolve,
      error: reject
    });
  });
}

export function getBoardLayout(boardId: number): Promise<any> {
  return new Promise((resolve, reject) => {
    $({
      url: `/private/user/boards/${ boardId }/panel-layout`,
      success: resolve,
      error: reject
    });
  });
}

export function putBoardLayout(boardId: number, body: any): Promise<any> {
  return new Promise((resolve, reject) => {
    $({
      url: `/private/user/boards/${ boardId }/panel-layout`,
      type: `PUT`,
      body,
      success: resolve,
      error: reject
    });
  });
}

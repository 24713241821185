'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default (uniqueID = `Checkmark`) => SvgHelper(
  `<defs><path id="${ uniqueID }a" d="M4.707 12.293a1 1 0 1 0-1.414 1.414l5 5a1 1 0 0 0 1.414 0l11-11a1 1 0 1 0-1.414-1.414L9 16.586l-4.293-4.293z"/></defs><g fill="none" fill-rule="evenodd"><mask id="${ uniqueID }b" fill="#fff"><use xlink:href="#${ uniqueID }a"/></mask><use fill="#000" fill-rule="nonzero" xlink:href="#${ uniqueID }a"/><g fill="#FFF" mask="url(#${ uniqueID }b)"><path d="M0 0h24v24H0z"/></g></g>`,
  {
    width: `3rem`,
    height: `3rem`,
    viewBox: `0 0 24 24`
  }
);
/* eslint-enable */

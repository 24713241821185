'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path fill-rule="evenodd" clip-rule="evenodd" d="M7 2H9C9.55228 2 10 2.44772 10 3V4H6V3C6 2.44772 6.44772 2 7 2ZM5 4V3C5 1.89543 5.89543 1 7 1H9C10.1046 1 11 1.89543 11 3V4H13C14.1046 4 15 4.89543 15 6V12C15 13.1046 14.1046 14 13 14H11H5H3C1.89543 14 1 13.1046 1 12V6C1 4.89543 1.89543 4 3 4H5ZM5 13H3C2.44772 13 2 12.5523 2 12V6C2 5.44772 2.44772 5 3 5H5V13ZM6 13V5H10V13H6ZM11 13H13C13.5523 13 14 12.5523 14 12V6C14 5.44772 13.5523 5 13 5H11V13Z" fill="currentColor"/>`,
  {
    width: `16px`,
    height: `16px`,
    viewBox: `0 0 16 16`
  }
);
/* eslint-enable */

'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default () => SvgHelper(
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M9 16.875C13.3492 16.875 16.875 13.3492 16.875 9C16.875 4.65076 13.3492 1.125 9 1.125C4.65076 1.125 1.125 4.65076 1.125 9C1.125 13.3492 4.65076 16.875 9 16.875ZM7.5455 5.9545C7.10616 5.51516 6.39384 5.51516 5.9545 5.9545C5.51516 6.39384 5.51516 7.10616 5.9545 7.5455L7.55901 9.15L5.9545 10.7545C5.51516 11.1938 5.51516 11.9062 5.9545 12.3455C6.39384 12.7848 7.10616 12.7848 7.5455 12.3455L9.15 10.741L10.7545 12.3455C11.1938 12.7848 11.9062 12.7848 12.3455 12.3455C12.7848 11.9062 12.7848 11.1938 12.3455 10.7545L10.741 9.15L12.3455 7.5455C12.7848 7.10616 12.7848 6.39384 12.3455 5.9545C11.9062 5.51516 11.1938 5.51516 10.7545 5.9545L9.15 7.55901L7.5455 5.9545Z" fill="#FB4C42"/>',
    {
        width: `18px`,
        height: `18px`,
        viewBox: `0 0 18 18`
    }
)

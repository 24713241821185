// @flow
'use strict';

import React, { useState } from 'react';
import { translate } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ContextMenu, MenuItem } from "react-contextmenu";
import MarketColorPicker from './../MarketColorPicker.jsx';
import { emitEvent, ADD_CHART_SYMBOL } from '../../../helpers/EventHelper.js';
import { getMarketPair } from '../../../helpers/MarketPairHelper.js';
import * as userApi from '../../../helpers/api/UserApi';
import { updateFavorites } from '../../../actions/app/updateFavorites.js';
import { setMarketSwitcherColorMode } from '../../../actions/app/setMarketSwitcherColorMode.js';
import type { Market } from '../../../types/Market.js';
import type { Favorite } from '../../../types/Favorite.js';

type Props = {
  t: any,
  id: string,
  favorites: Array<Favorite>,
};

const MarketSearchContextMenuComponent= (props: Props) => {
  const { t, id, favorites } = props;
  const [marketColorModalOpen, setMarketColorModalOpen] = useState<boolean>(false);
  const [marketColorModalMarket, setMarketColorModalMarket] = useState<string>(``);
  const history = useHistory();

  const marketSwitcherColorFilled = useSelector((state) => state.app.marketSwitcherColorFilled);
  const marketsAreClickable =  useSelector((state) => state.browser.marketsAreClickable);

  const dispatch = useDispatch();
  const dispatchUpdateFavorites = (favorites) => dispatch(updateFavorites(favorites));
  const dispatchUpdateMarketSwitcherColorMode = (b) => dispatch(setMarketSwitcherColorMode(b));

  const toggleFavorite = (e: Market) => {
    if (!e) return;

    const isFavorite = favorites?.some((f) => f.exchCode === e.exchCode && f.displayName === e.displayName );

    if (isFavorite) {
      userApi.deleteFavorite({ exchmktId: e.exchmktId }, (data) => {
        data.success && dispatchUpdateFavorites(data.result);
      });
    } else {
      userApi.addFavorite({ body: { exchmktId: e.exchmktId } }, (data) => {
        data.success && dispatchUpdateFavorites(data.result);
      });
    }
  };

  const handleContextMenuClick = (e: any, { market: m, action }: { market: Market, action: string } = {}) => {
    if (!m || !action) return;

    switch (action) {
    case `nav_new_tab`:
      window.open(`/markets/${ m.exchCode }/${ getMarketPair(m).toString() }`, `_blank`);
      break;
    case `nav_this_tab`:
      if (!marketsAreClickable) return false;
      history.push(`/markets/${ m.exchCode }/${ getMarketPair(m).toString() }`);
      break;
    case `assign_color`:
      setMarketColorModalOpen(true);
      setMarketColorModalMarket(getMarketPair(m).toString());
      break;
    case `toggle_favorites`:
      toggleFavorite(m);
      break;
    case `set_color_mode`:
      dispatchUpdateMarketSwitcherColorMode(!marketSwitcherColorFilled);
      break;
    case `add_symbol_to_chart`:
      emitEvent(ADD_CHART_SYMBOL, m);
      break;
    }
  };

  return (
    <>
      <ContextMenu id={ id }>
        <MenuItem
          onClick={ handleContextMenuClick.bind(this) }
          data={ { action: `add_symbol_to_chart` } }>
          { t(`addSymboltoChart`) }
        </MenuItem>
        <MenuItem
          onClick={ handleContextMenuClick.bind(this) }
          data={ { action: `nav_new_tab` } }>
          { t(`openInNewTab`) }
        </MenuItem>
        <MenuItem
          onClick={ handleContextMenuClick.bind(this) }
          data={ { action: `nav_this_tab` } }>
          { t(`openInCurrentTab`) }
        </MenuItem>
        <MenuItem divider />
        <MenuItem
          onClick={ handleContextMenuClick.bind(this) }
          data={ { action: `assign_color` } }>
          { t(`assignColor`) }
        </MenuItem>
        <MenuItem
          onClick={ handleContextMenuClick.bind(this) }
          data={ { action: `toggle_favorites` } }>
          { t(`toggleFavorite`) }
        </MenuItem>
        <MenuItem
          onClick={ handleContextMenuClick.bind(this) }
          data={ { action: `set_color_mode` } }>
          { marketSwitcherColorFilled ?  t(`shrinkAssignedColors`) : t(`expandAssignedColors`) }
        </MenuItem>
      </ContextMenu>
      {
        marketColorModalOpen && marketColorModalMarket.length > 0 && (
          <MarketColorPicker 
            marketDisplayName={ marketColorModalMarket } 
            onClose={ () => {
              setMarketColorModalOpen(false); 
              setMarketColorModalMarket(``); 
            } }/>
        )
      }
    </>
  );
};

export const MarketSearchContextMenu = translate(`markets`)(MarketSearchContextMenuComponent);

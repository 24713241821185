// @flow
'use strict';

import React from 'react';
import MobileNav from "../../svgs/MobileNav.jsx";

export const ToggleMobileButton = (props: any) => {
  const { toggleMobileMenu } = props;

  return(
    <a className="mobile-menu-link" data-testid={ `mobile-menu-link` } onClick={ toggleMobileMenu }>
      <MobileNav />
    </a>
  );
};

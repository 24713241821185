// @flow
'use strict';

import React from 'react';
import moment from 'moment';
import ScrollableArea from "./ScrollableArea.jsx";

type Props = {
  news: Array<{
    title: string,
    id: string,
    url: string,
    feedImage: string,
    feedName: string,
    pubDate: string
  }>
};

const NewsFeed = (props: Props) => {
  const { news } = props;
  return (
    <div className="news-feed">
      <ScrollableArea>
        {
          news.sort((a, b) => {
            if (+(new Date(a.pubDate)) < +(new Date(b.pubDate))) return 1;
            if (+(new Date(a.pubDate)) > +(new Date(b.pubDate))) return -1;
            return 0;
          }).slice(0, 50).map((n, i) => (
            <a key={ `${ n.id }-${ i }-newsitem` } href={ n.url } target="_blank" rel="noopener noreferrer" className="news-item">
              <div 
                className="title"
                dangerouslySetInnerHTML={ { __html: n.title } } />
              <div className="meta">
                <img src={ `https://www.coinigy.com/assets/img/news/${ n.feedImage }` } />
                <span>
                  { n.feedName }
                </span>
                <span>
                  { 
                    (+(new Date()) - +(new Date(n.pubDate))) <= 1000 * 60 * 60 ? 
                      moment(n.pubDate).fromNow() :
                      moment(n.pubDate).format(`MM/DD/YYYY hh:mma`)
                  }
                </span>
              </div>
            </a>
          ))
        }
      </ScrollableArea>
    </div>
  );
};

export default NewsFeed;

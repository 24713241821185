// @flow
'use strict';

import React from 'react';
import BalancesLineChart from './BalancesLineChart.jsx';
import BalancesStackedBarChart from './BalancesStackedBarChart.jsx';
import BalancesPieChart from './BalancesPieChart.jsx';
import type { Account } from "../../types/Account";
import type { Exchange } from "../../types/Exchange";

type Props = {
  chartType: string,
  hasData: boolean,
  accounts: Array<Account>,
  exchanges: Array<Exchange>
};

const BalancesChart = ({ chartType, ...props }: Props) => {
  switch(chartType) {
  case `line-chart`:
    return (<BalancesLineChart { ...props }/>);
  case `bar-chart`:
    return (<BalancesStackedBarChart { ...props }/>);
  case `distribution`:
    return (<BalancesPieChart { ...props }/>);
  default:
    return (<BalancesPieChart { ...props }/>);
  }
};

BalancesChart.displayName = `BalancesChart`;

export default BalancesChart;

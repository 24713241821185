'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default (uniqueID = `Trash`) => SvgHelper(
  `<defs><path d="M11.333 3.333H14a.667.667 0 0 1 0 1.334h-.667v8.666a2 2 0 0 1-2 2H4.667a2 2 0 0 1-2-2V4.667H2a.667.667 0 0 1 0-1.334h2.667v-.666a2 2 0 0 1 2-2h2.666a2 2 0 0 1 2 2v.666zm-1.333 0v-.666A.667.667 0 0 0 9.333 2H6.667A.667.667 0 0 0 6 2.667v.666h4zm2 1.334H4v8.666c0 .369.298.667.667.667h6.666a.667.667 0 0 0 .667-.667V4.667zM6 7.333a.667.667 0 0 1 1.333 0v4a.667.667 0 1 1-1.333 0v-4zm2.667 0a.667.667 0 1 1 1.333 0v4a.667.667 0 1 1-1.333 0v-4z" id="${ uniqueID }a"/></defs><g transform="translate(-1)" fill="none" fill-rule="evenodd"><mask id="${ uniqueID }b" fill="#fff"><use xlink:href="#${ uniqueID }a"/></mask><use fill="#000" fill-rule="nonzero" xlink:href="#${ uniqueID }a"/><g id="Trash2" mask="url(#${ uniqueID }b)"><path d="M0 0h16v16H0z"/></g></g>`,
  {
    width: `1.75rem`,
    height: `2rem`,
    viewBox: `0 0 14 14`
  }
);
/* eslint-enable */

'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default (uniqueID = `CollapseRight` ) => SvgHelper(
  `<path d="M15 13C15 14.1046 14.1046 15 13 15L11 15L11 1L13 1C14.1046 1 15 1.89543 15 3L15 13Z" fill="currentColor"/>
  <path d="M2 8L8 8M8 8L5 11M8 8L5 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>`,
  {
    width: `16px`,
    height: `16px`,
    viewBox: `0 0 16 16`
  }
);
/* eslint-enable */

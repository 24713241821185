// @flow
'use strict';

import { useCallback } from 'react';
import type { Account } from "../../types/Account.js";
import type { Balance } from "../../types/Balance.js";
import * as MarketsApi from '../api/MarketsApi';
import useMarketsBalances from './useMarketsBalances';

type Props = {
  accounts: Array<Account>,
  balances: Array<Balance>,
  offset?: number,
  page: number,
  pageSize: number,
  range: number,
  requestId: any,
  searchTerm: string,
  sort: number,
  sortOrder: number,
  searchTerms: Array<string>,
  ignoreDead: boolean,
  favoriteMarkets: string,
}

const PAGE_LIMIT = 250;

const useMarketsSummaries = () => {

  const { getMarketsBalances } = useMarketsBalances();

  const getMarketsSummaries = useCallback(async (props: Props) => {
    const { pageSize, page, sort, sortOrder, range, searchTerm, accounts, balances, requestId, searchTerms, ignoreDead, favoriteMarkets } = props;

    const search = [...searchTerms, favoriteMarkets, searchTerm].filter((s) => s).map((s) => encodeURIComponent(s)).join(`&SearchTerm=`);

    return new Promise((resolve, reject) => {
      const getMarketSummaries = () => {
        MarketsApi.getMarketSummaries(pageSize, page, sort, sortOrder, range, search, ignoreDead).then(async(markets) =>{
          if (markets?.success) {
            
            const marketsData = markets.result.map((market) => {
              return { ...market, accounts: accounts.filter((account) => market.exchId === account.authExchId).map((account) => account.authId) };
            });

            const { balances: blncs }  = getMarketsBalances({ accounts, markets: marketsData, balances });

            resolve({
              requestId,
              balances: blncs,
              markets: marketsData,
              totalRecords: markets.totalRecords
            });
          } else {
            reject({
              requestId,
              error: markets?.error
            });
          }
        });
      };

      getMarketSummaries();
    });
  }, []);

  const getMarketsSummariesPositions = useCallback(async (props: Props) => {
    const { offset = 0, pageSize, page, sort, sortOrder, range, searchTerm, accounts, balances, requestId, searchTerms, ignoreDead, favoriteMarkets } = props;
    const apiPageSize = pageSize*5 > PAGE_LIMIT ? PAGE_LIMIT : pageSize*5;

    const exchIds = [...new Set(accounts.filter((a) => a.authExchId !== 999).reduce((accum, a) => [...accum, a.authExchId], []))];

    const walletAuthIds =  accounts
      .filter((a) => a.authExchId === 999)
      .reduce((accum, a) => [...accum, a.authId], []);

    const currIds = [...new Set(balances
      .filter((b) => !walletAuthIds.includes(b.balanceAuthId))
      .reduce((accum, b) => [...accum, b.balanceCurrId], [])
    )];

    const searchExchangeIds= `ExchangeIds:${exchIds.join(`,`)}`;
    const searchBaseCurrencyIds= `BaseCurrencyIds:${currIds.join(`,`)}`;
    
    const search = [
      ...searchTerms, searchTerm, searchExchangeIds, searchBaseCurrencyIds, favoriteMarkets
    ].filter((s) => s).map((s) => encodeURIComponent(s)).join(`&SearchTerm=`);

    return new Promise((resolve, reject) => {
      const getMarketsPositions = (page, offset, accumMarketsPositions = [], accumBalances = []) => {
        MarketsApi.getMarketSummaries(apiPageSize, page, sort, sortOrder, range, search, ignoreDead).then(async (markets) =>{
          if (markets?.success) {

            const marketsData = markets.result.map((market) => {
              return { ...market, accounts: accounts.filter((account) => market.exchId === account.authExchId).map((account) => account.authId) };
            });

            const { balances: blncs, marketsBalances }  = getMarketsBalances({ accounts, markets: marketsData, balances });

            const accumBalancesIds = accumBalances.map((d) => ({
              balanceId: d.balanceId,
              marketId: d.marketId,
              exchId: d.exchId,
              authId: d.authId,           
            }));
            
            const newAccumBalances = [
              ...accumBalances, 
              ...blncs.filter((d) => !accumBalancesIds.find((a) => 
                a.balanceId === d.balanceId
                && a.marketId === d.marketId
                && a.exchId === d.exchId
                && a.authId === d.authId
              )                                     
              )];

            const newAccumMarketsPositions = [...accumMarketsPositions, ...marketsBalances.slice(-offset)];

            if (newAccumMarketsPositions.length < pageSize && markets.totalPages > markets.currentPage) {
              getMarketsPositions(page+1, 0, newAccumMarketsPositions, newAccumBalances);
            } else {
              resolve({
                requestId,
                page,
                isLastPage: markets.totalPages === markets.currentPage,
                balances: newAccumBalances,
                // $FlowIgnore: suppressing this error
                markets: newAccumMarketsPositions.slice(0, pageSize),
                offset: newAccumMarketsPositions.slice(pageSize).length
              });
            }
          } else {
            reject({
              requestId,
              error: markets?.error
            });
          }
        });
      };

      getMarketsPositions(page, offset);
    });
  }, []);

  return { getMarketsSummaries, getMarketsSummariesPositions };
};

export default useMarketsSummaries;

// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import MarketVerticalDepthChart from './MarketVerticalDepthChart.jsx';
import StreamingTradesList from './StreamingTradesList.jsx';
import NewOrder from '../orders/NewOrder.jsx';
import MarketInsights from "./MarketInsights.jsx";
import MarketNotes from "./MarketNotes.jsx";
import Tabs from '../utilities/Tabs.jsx';
import { FlexColumn } from '../utilities/FlexColumn.jsx';
import NewAlert from "../alerts/NewAlert.jsx";
import MarketAlerts from "./MarketAlerts.jsx";
import ScrollableArea from "../utilities/ScrollableArea.jsx";
import { toFixedDecimals } from '../../helpers/NumberHelper.js';
import type { Exchange } from '../../types/Exchange.js';
import type { Market } from '../../types/Market.js';
import type { Trade } from '../../types/Trade.js';
import type { MarketOrder } from '../../types/MarketOrder.js';
import type { Currency } from "../../types/Currency.js";
import type { Ticker } from "../../types/Ticker.js";
import type { Account } from "../../types/Account";
import type { Balance } from "../../types/Balance";
import type { Order } from '../../types/Order.js';

type Props = {
  t: any,
  active: {
    exchange: Exchange,
    market: Market
  },
  researchBaseCurrency: Currency,
  researchQuoteCurrency: Currency,
  trades: Array<Trade>,
  buys: Array<MarketOrder>,
  sells: Array<MarketOrder>,
  markets: Array<Market>,
  exchanges: Array<Exchange>,
  currencies: Array<Currency>,
  balances: Array<Balance>,
  accounts: Array<Account>,
  ticker: Ticker,
  range: {
    allTime: {
      high: number,
      low: number
    },
    "30D": {
      high: number,
      low: number
    },
    "7D": {
      high: number,
      low: number
    },
    "1D": {
      high: number,
      low: number
    }
  },
  lastPrice: number,
  notes: string,
  marketLabel: string,
  activeTab: number,
  zeroStyle: string,
  depthStyle: string,
  theme: string,
  updateNotes: (note: string) => void,
  updateTab: (tab: number) => void,
  deleteAlert: (id: number) => boolean,
  setCurrentAccount: (id: number) => void,
  currentAuthId: number,
  updateBOHTab: (tab: number) => void,
  orderFormType: string,
  updateOrderFormType: (orderFormType: string) => void,
  alertType: number,
  updateAlertType: (alertType: number) => void,
  baseCurrencyItbSignals: any,
  quoteCurrencyItbSignals: any,
  cmCalCategories: any,
  cmCalCoins: any,
  bestBid: number,
  bestAsk: number,
  openOrders: Array<Order>,
  alertsLastUpdateTime: number,
  refreshAlertsLastUpdateTime: () => void
};

const MarketInfo = ({
  t,
  buys,
  sells,
  trades,
  researchBaseCurrency,
  researchQuoteCurrency,
  active,
  notes,
  updateNotes,
  lastPrice,
  ticker,
  range,
  markets,
  exchanges,
  currencies,
  balances,
  marketLabel,
  accounts,
  activeTab,
  zeroStyle,
  depthStyle,
  updateTab,
  theme,
  deleteAlert,
  setCurrentAccount,
  currentAuthId,
  updateBOHTab,
  orderFormType,
  updateOrderFormType,
  alertType,
  updateAlertType,
  baseCurrencyItbSignals,
  quoteCurrencyItbSignals,
  cmCalCategories,
  cmCalCoins,
  bestBid,
  bestAsk,
  openOrders,
  alertsLastUpdateTime,
  refreshAlertsLastUpdateTime
}: Props) => {

  // Return "Time & Sales" if the market is not on CGY, otherwise return "Live Quotes"
  const getTabName = () => {
    if (active && active.exchange && active.exchange.exchCode === `CGY`) {
      return `Live Quotes`;
    }else {
      return `Time & Sales`;
    }
  };

  return (
    <div className="market-info">
      <Tabs
        tabPosition="center"
        tabNames={ [getTabName(), t(`app:trade`), t(`header:alerts`), t(`app:insights`), t(`app:notes`)] }
        activeTab={ activeTab }
        onChangeTab={ updateTab }>
        <ScrollableArea>
          <div className="market-data">
            {
              <div className="addFlex">
                {
                  buys.length > 0 && sells.length > 0 && (
                    <MarketVerticalDepthChart
                      market={ active && active.market }
                      buys={ buys.length > 20 ? buys.slice(buys.length - 20) : buys }
                      sells={ sells.slice(0, 20) }
                      zeroStyle={ zeroStyle }
                      depthStyle={ depthStyle }
                      theme={ theme } 
                      openOrders={ openOrders }/>
                  )
                }
                <StreamingTradesList
                  market={ active && active.market }
                  trades={ trades }
                  zeroStyle={ zeroStyle } 
                  exchanges={ exchanges }/>
              </div>
            
            }
          </div>
        </ScrollableArea>
        <FlexColumn>
          <NewOrder
            markets={ markets }
            exchanges={ exchanges }
            currencies={ currencies }
            active={ active }
            lastPrice={ lastPrice }
            marketLabel={ marketLabel }
            accounts={ accounts }
            balances={ balances }
            options={ Object.keys(ticker).filter((t) => t !== `volume`).map((k) => ({
              label: `${ t(`app:${ k }`) } (${ toFixedDecimals(ticker[k], true, `price`, active.market) })`,
              value: toFixedDecimals(ticker[k], true, `price`, active.market)
            })) }
            currentAuthId={ currentAuthId }
            setCurrentAccount={ setCurrentAccount }
            orderFormType={ orderFormType }
            updateOrderFormType={ updateOrderFormType }
            updateBOHTab={ updateBOHTab }
            bestBid={ bestBid }
            bestAsk={ bestAsk }
            buys={ buys.length > 20 ? buys.slice(buys.length - 20) : buys }
            sells={ sells.slice(0, 20) }/>
        </FlexColumn>
        <FlexColumn>
          <NewAlert
            markets={ markets }
            exchanges={ exchanges }
            active={ active }
            lastPrice={ lastPrice }
            marketLabel={ marketLabel }
            options={ Object.keys(ticker).filter((t) => t !== `volume`).map((k) => ({
              label: `${ t(`app:${ k }`) } (${ toFixedDecimals(ticker[k], false, `price`, active.market) })`,
              value: active.market ? toFixedDecimals(ticker[k], false, `price`, active.market) : ticker[t]
            })).concat([
              {
                label: `All Time High (${ range.allTime.high })`,
                value: range.allTime.high
              },
              {
                label: `All Time Low (${ range.allTime.low })`,
                value: range.allTime.low
              },
              {
                label: `30 Day High (${ range[`30D`].high })`,
                value: range[`30D`].high
              },
              {
                label: `30 Day Low (${ range[`30D`].low })`,
                value: range[`30D`].low
              },
              {
                label: `7 Day High (${ range[`7D`].high })`,
                value: range[`7D`].high
              },
              {
                label: `7 Day Low (${ range[`7D`].low })`,
                value: range[`7D`].low
              },
              {
                label: `1 Day High (${ range[`1D`].high })`,
                value: range[`1D`].high
              },
              {
                label: `1 Day Low (${ range[`1D`].low })`,
                value: range[`1D`].low
              }
            ]) }
            alertType={ alertType }
            updateAlertType={ updateAlertType } />
          <MarketAlerts
            active={ active }
            deleteAlert={ deleteAlert }
            exchanges={ exchanges }
            markets={ markets }
            alertsLastUpdateTime={ alertsLastUpdateTime }
            refreshAlertsLastUpdateTime={ refreshAlertsLastUpdateTime }/>
        </FlexColumn>
        <ScrollableArea>
          <MarketInsights
            baseCurrency={ researchBaseCurrency }
            quoteCurrency={ researchQuoteCurrency }
            baseCurrencyItbSignals={ baseCurrencyItbSignals }
            quoteCurrencyItbSignals={ quoteCurrencyItbSignals }
            currencies={ currencies }
            cmCalCategories={ cmCalCategories }
            cmCalCoins={ cmCalCoins } />
        </ScrollableArea>
        <ScrollableArea>
          <MarketNotes
            marketLabel={ marketLabel }
            notes={ notes }
            updateNotes={ updateNotes } />
        </ScrollableArea>
      </Tabs>
    </div>
  );
};

export { MarketInfo as PureMarketInfo };
export default translate(`markets`)(MarketInfo);

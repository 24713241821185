// @flow
'use strict';

import React from 'react';
import BoardsDataLayer from '../components/boards/BoardsDataLayer.jsx';
import type { Market } from '../types/Market.js';
import type { Exchange } from '../types/Exchange.js';
import type { Balance } from '../types/Balance.js';
import type { Account } from '../types/Account.js';

type Props = {
  refreshBalance: (n: number, d: boolean) => Promise<any>,
  markets: Array<Market>,
  exchanges: Array<Exchange>,
  accounts: Array<Account>,
  balances: Array<Balance>,
  currencies?: Array<any>,
  params: {
    source_tag?: string
  },
  aggregatedMarkets?: [],
};

const BoardsPage = ({ markets, exchanges, accounts, params, currencies, balances, refreshBalance, aggregatedMarkets = [] }: Props) => {

  return (
    <BoardsDataLayer 
      markets={ markets }
      exchanges={ exchanges }
      accounts={ accounts }
      currencies={ currencies }
      sourceTag={ params.source_tag } 
      balances={ balances }
      refreshBalance={ refreshBalance }
      aggregatedMarkets={ aggregatedMarkets }/>
  );
};

export default BoardsPage;

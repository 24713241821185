// @flow
'use strict';

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '../../utilities/TextField.jsx';
import { getSummariesCurrencies } from '../../../helpers/api/CurrencyApi.js';
import { setCurrencySelectorFilters } from '../../../actions/app/setCurrencySelectorFilters.js';
import Search from "../../../svgs/Search.jsx";
import DataTable from '../../utilities/DataTable';
import SelectField from '../../utilities/SelectField.jsx';
import ImportExportIcon from '@mui/icons-material/ImportExport';

type Props = {
  close: () => void,
  setCurrency: (curr: string) => void
};

const SORT_OPTIONS = [
  {
    value: `1-0`,
    label: `▲ Alphabetical A-Z`,
  },
  {
    value: `1-1`,
    label: `▼ Alphabetical Z-A`,
  },
  {
    value: `2-0`,
    label: `▲ Volume`,
  },
  {
    value: `2-1`,
    label: `▼ Volume`,
  },
];

const CurrencySelector = (props: Props) => {
  const { close, setCurrency } = props;
  
  const filters = useSelector((state) => state.app.currencySelectorFilters);
  const dispatch = useDispatch();
  const dispatchSetCurrencySelectorFilters = (o: any) => dispatch(setCurrencySelectorFilters(o));

  const [inputSearchValue, setInputSearchValue] = useState(``);
  const [sort, setSort] = useState(filters?.sort || `2-1`);
  const [lastUpdateTime, setLastUpdateTime] = useState(null);

  const columns = [
    {
      title: `currCode`,
      key: `currCode`,
      display: (row) => {
        return <div
          className={ `currency-selector-item` }
          onClick={ () => {
            setCurrency(row.currCode);
            close();
          } }>
          <div className={ `flex` }>
            <div className={ `currencies-section` }>
              <div className={ `quote-curr-logo` }>
                <img
                  className={ `curr-logo` }
                  // $FlowIgnore: suppressing this error
                  src={ `${ window.WWW_URL }/assets/img/currency/${ row.currCode }.png` } 
                  height={ `20rem` } 
                  onError={ (e)=>{e.target.onerror = null; e.target.src=`https://www.coinigy.com/assets/img/currency/empty.png`;} } />
              </div>
            </div>

            <div className={ `currency-name-section` }>
              <div className={ `currency-code` }>
                { row.currCode }
              </div>
              <div className={ `currency-name` }>
                { row.currName }
              </div>
            </div>
          </div>
        </div>;
      }
    }
  ];

  const getData = (currentPage, pageSize) => {
    const [sortName, sortOrder] = sort?.split(`-`);
    return new Promise((resolve, reject) => {
      getSummariesCurrencies({ params: { SearchTerm: inputSearchValue, Range: 4, PageNumber: currentPage, PageSize: pageSize, Sort: sortName, SortOrder: sortOrder } },
        (data) => {
          if (data.success) {
            resolve(data);
          } else {
            reject();
          }
        });
    });
  };

  useEffect(() => {
    const id = setTimeout(() => {
      setLastUpdateTime(new Date().getTime());
      if (filters?.sort !== sort) dispatchSetCurrencySelectorFilters({ sort });
    }, 400);

    return () => clearTimeout(id);
  }, [inputSearchValue, sort]);

  return (
    <div className={ `currency-selector-container fade-in` }>
      <div
        className="currency-selector-backdrop" onClick={ (e) => {
          close?.();
          e.stopPropagation();
        } } />
      <div className={ `currency-selector fade-in` }>
        <div className={ `currency-selector-header` }>
          <div className={ `filter-container` }>
            <TextField
              icon={ Search }
              label={ `Find currencies` }
              name="findCurrencies"
              value={ inputSearchValue }
              compact={ true }
              onDoubleClick={ () => setInputSearchValue(``) }
              onChange={ (e) => setInputSearchValue(e.target.value) }
              onEnter={ () => setInputSearchValue(``) }/>

            <div className={ `sort` }>
              <SelectField
                searchable={ false }
                label={ `` }
                name={ `sort` }
                value={ sort }
                options={ SORT_OPTIONS }
                hideValue
                hideCaret
                icon={ <ImportExportIcon sx={ { fontSize: `3rem`, cursor: `pointer` } }/> }
                onChange={ (e, v) => setSort(v) }/>
            </div>
          </div>
        </div>

        <div className={ `currency-selector-list-container` }>
          <DataTable
            id={ `currency-selector` }
            columns={ columns }
            fetchData={ (currentPage, pageSize) => getData(currentPage, pageSize) }
            filterText={ `` } 
            hideSideBar={ true }
            hideTableHeader={ true }
            forceRefetch={ lastUpdateTime }/>
        </div>
      </div>
    </div>
  );
};

export default CurrencySelector;

// @flow
'use strict';

import React from 'react';

type Props = {
  label: any,
  name: string,
  value: boolean,
  partial?: boolean,
  disabled?: boolean,
  title?: string,
  'data-testid'?: string,
  onChange?: (e: any, value: boolean) => void
};

const Checkbox = ({ label, name, value, partial, disabled, onChange, title, ...rest }: Props) => {
  let props: any = {
    type: `checkbox`,
    checked: !!value,
    id: name,
    name,
    onChange,
    'data-testid': rest[`data-testid`]
  };

  if (partial) props.checked = true;

  if (disabled) {
    props.disabled = `disabled`;
  } else if (onChange) {
    props.onChange = onChange;
  }

  let spanProps: any = { };

  if (title) {
    spanProps.title = title;
  }

  return (
    <label
      className={ `checkbox ${ value ? `active` :  partial ? `partial` : `` }` }
      htmlFor={ name }>
      <input { ...props } />
      <span { ...spanProps }>
        { label }
      </span>
    </label>
  );
};

Checkbox.displayName = `Checkbox`;

export default Checkbox;

// @flow
'use strict';

import React from 'react';
import FilterIcon from '@mui/icons-material/FilterList';
import SelectField from '../utilities/SelectField.jsx';

type Props = {
  t: any,
  changeSettings: (obj: any) => void,
  range: number,
  reset: () => void,
  toggleFilterModal: (prev: any) => void,
}

const ScreenerToolbar = (props: Props) => { 
  const { t, changeSettings, range, reset, toggleFilterModal } = props;

  const rangeOptions = [
    { label: `${t(`screener:oneDay`)}`, value: 0 },
    { label: `${t(`screener:sevenDay`)}`, value: 1 },
    { label: `${t(`screener:thirtyDay`)}`, value: 2 },
    { label: `${t(`screener:oneYear`)}`, value: 3 },
    { label: `${t(`screener:all`)}`, value: 4 },
  ];

  return (
    <div className='mui-custom-toolbar'>
      <div>
        <SelectField
          searchable={ false }
          label={ `` }
          name={ `range` }
          value={ range }
          options={ rangeOptions }
          onChange={ (e, v) => {
            reset();
            changeSettings({ range: v });
          } }/>
      </div>

      <div 
        className='filter'
        onClick={ () => toggleFilterModal((prev) => !prev) }>
        <FilterIcon/>
      </div>
    </div>
  );
};

export default ScreenerToolbar;

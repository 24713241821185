// @flow
'use strict';

import {
  UPDATE_MARKETS_INFO_ACTIVE_TAB,
  UPDATE_MARKETS_ORDERS_ACTIVE_TAB,
  UPDATE_CHARTS_ACTIVE_TAB,
  UPDATE_BOH_ACTIVE_TAB,
  UPDATE_ORDER_FORM_ACTIVE_TYPE,
  UPDATE_ALERT_ACTIVE_TYPE,
  PUSH_TO_HISTORY,
  TOGGLE_FAVORITE_FILTER,
  UPDATE_ALERT_SOUND,
  UPDATE_INSIGHTS_SECTIONS,
  UPDATE_RESOLUTION,
  CHANGE_MARKET
} from '../actions/index.js';

type State = {
  history: Array<{
    exchCode: string,
    displayName: string
  }>,
  marketsInfoActiveTab: number,
  marketsOrdersActiveTab: number,
  marketsChartsActiveTab: number,
  favoriteFilter: boolean,
  alertSound: string,
  marketSwitcherTriggered: boolean,
};

type Action = {
  type: string,
  payload: any
};

export const initialState: State = {
  marketsInfoActiveTab: 0,
  marketsOrdersActiveTab: 0,
  marketsChartsActiveTab: 0,
  marketsBOHActiveTab: 0,
  marketsOrderFormActiveType: `dual`,
  marketsAlertActiveType: 0,
  history: [
    {
      displayName: `BTC/USD`,
      exchCode: `BITS`
    },
    {
      displayName: `XVG/BTC`,
      exchCode: `BTRX`
    },
    {
      displayName: `BTC/USD`,
      exchCode: `GDAX`
    },
    {
      displayName: `XBT/USD`,
      exchCode: `BMEX`
    },
    {
      displayName: `ETH/BTC`,
      exchCode: `PLNX`
    },
    {
      displayName: `BTC/EUR`,
      exchCode: `BITF`
    },
    {
      displayName: `BTC/USD`,
      exchCode: `BITF`
    },
    {
      displayName: `BTC/USDT`,
      exchCode: `PLNX`
    }
  ],
  favoriteFilter: false,
  alertSound: `none`,
  insightsSections: [`currency`,`news`],
  marketSwitcherTriggered: false,
};

export default function markets(state: State = initialState, action: Action) {
  switch(action.type) {
  case UPDATE_MARKETS_INFO_ACTIVE_TAB:
    return {
      ...state,
      marketsInfoActiveTab: action.payload
    };
  case UPDATE_MARKETS_ORDERS_ACTIVE_TAB:
    return {
      ...state,
      marketsOrdersActiveTab: action.payload
    };
  case UPDATE_CHARTS_ACTIVE_TAB:
    return {
      ...state,
      marketsChartsActiveTab: action.payload
    };
  case UPDATE_BOH_ACTIVE_TAB:
    return {
      ...state,
      marketsBOHActiveTab: action.payload
    };
  case UPDATE_ORDER_FORM_ACTIVE_TYPE:
    return {
      ...state,
      marketsOrderFormActiveType: action.payload
    };
  case UPDATE_ALERT_ACTIVE_TYPE:
    return {
      ...state,
      marketsAlertActiveType: action.payload
    };
  case UPDATE_RESOLUTION:
    return {
      ...state,
      resolution: action.payload
    };
  case PUSH_TO_HISTORY: {
    let history = state.history.filter((h) => {
      return !(h.exchCode === action.payload.exchCode && h.displayName == action.payload.displayName);
    });

    history.unshift(action.payload);

    return {
      ...state,
      // $FlowIgnore: suppressing this error
      history: history.slice(0, 10)
    };
  }
  case TOGGLE_FAVORITE_FILTER: 
    return {
      ...state,
      favoriteFilter: action.payload
    };
  case UPDATE_ALERT_SOUND:
    return {
      ...state,
      alertSound: action.payload
    };
  case UPDATE_INSIGHTS_SECTIONS:
    return {
      ...state,
      insightsSections: action.payload
    };
  case CHANGE_MARKET:
    return {
      ...state,
      marketSwitcherTriggered: action.payload
    };
    
  }
  return state;
}


// @flow
'use strict';

import React from 'react';
import Lock from "../../../svgs/Lock.jsx";
import { Link } from 'react-router-dom';

type MenuLinkProps = {
  closeDropdownMenus: any,
  text: string,
}

export const TwoFactorAuthLink = (props: MenuLinkProps) => {
  const { text, closeDropdownMenus } = props;

  return (
    <Link onClick={ () => closeDropdownMenus() } to="/user/2fa">
      { Lock }
      <span>
        { text }
      </span>
    </Link>
  );
};

// @flow
'use strict';

import React from 'react';
import { withRouter } from 'react-router-dom';
import Modal from "../utilities/Modal.jsx";
import Lock from "../../svgs/Lock.jsx";

type Props = {
  t: any,
  onClose: () => void,
  history: { push: (any) => void }
};

const EnableTwoFactorAuthModal = (props: Props) => {
  const { t, onClose, history } = props;

  
  
  return (
    <div className="enable-2fa-modal">
      <Modal
        title={ t(`Please Enable Two-Factor Authentication`) }
        onConfirm={ () => { history.push(`/user/2fa`); onClose(); } }
        onClose={ onClose }
        confirmText={ t(`Enable 2FA Now`) }>
        <div>
          <div className="lock-icon">
            { Lock }
            { ` ` }
2FA Status: Disabled
          </div>
          <p>
        Before trading, please enable Two-Factor Authentication (2FA). Click &apos;Enable 2FA Now&apos; to add this important security measure. 
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default withRouter(EnableTwoFactorAuthModal);

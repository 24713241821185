// @flow
'use strict';

import React from 'react';
import { withRouter } from 'react-router-dom';
import Button from '../utilities/Button.jsx';
import EntityTable from '../utilities/EntityTable.jsx';
import type { ProfileProps } from './ProfileDataLayer.jsx';
import UserProfile from './UserProfile.jsx';
import PrimeTrustContactInformation from './PrimeTrustContactInformation.jsx';
import UpgradeNow from './UpgradeNow.jsx';
import { toLocalDate } from '../../helpers/DateHelper.js';
import { getInvoicesPdf } from '../../helpers/api/UserApi';
import TimesCheck from '../../svgs/TimesCheck.jsx';
import Verified from '../../svgs/Verified.jsx';


const AccountEditor = (props: ProfileProps) => {
  const { t, subscriptionInfo, user, invoices, subscriptionTypes, onChange, save } = props;

  if (!subscriptionInfo || !user) return ``;

  const previewPdf = (data: any) => {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {//IE
      window.navigator.msSaveOrOpenBlob(data);
    } else if (navigator.userAgent.indexOf(`Chrome`) != -1 || navigator.userAgent.indexOf(`Firefox`) != -1){
      window.open(window.URL.createObjectURL(data), `_blank`);
    } else { //Safari & Opera iOS
      const blobURL = window.URL.createObjectURL(data);
      const link = document.createElement(`a`);
      link.href = blobURL;
      link.setAttribute(`href`, blobURL);
      link.setAttribute(`target`, `_blank`);
      const event: any = document.createEvent(`MouseEvents`);
      event.initMouseEvent(`click`, true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
      link.dispatchEvent(event);
      setTimeout(() => {
        window.URL.revokeObjectURL(blobURL);
      }, 100);
    }
  };

  const delta = new Date(subscriptionInfo.prefSubscriptionExpires) - Date.now(),
    daysRemaining = Math.floor((delta) / 1000 / 60 / 60 / 24),
    columns = [
      {
        title: t(`date`),
        key: `paymentDate`,
        display: (row, time) => toLocalDate(time, `%M%M/%D%D/%Y%Y%Y%Y`)
      },
      {
        title: t(`invoiceId`),
        key: `paymentId`
      },
      {
        title: t(`invoiceAmount`),
        key: `paymentAmountUsd`
      },
      {
        title: t(`export`),
        key: `export`,
        nosort: true,
        class: `export`,
        display: (row) => (
          <Button
            onClick={ () => {
              // TODO: export pdf
              getInvoicesPdf(row.paymentId, (data) => {
                previewPdf(data);
              });
              return false;
            } }>
            <span>
              { t(`downloadPDF`) }
            </span>
          </Button>
        )
      }
    ];

  return (
    <div className="my-account">
      <h1 className="page-title">
        { t(`Subscription`) }
      </h1>
      {
        subscriptionInfo.subscriptionStatus && subscriptionInfo.subscriptionStatus !== `active` && subscriptionInfo.subscriptionStatus !== `lifetime` && (
          <UpgradeNow t={ t } />
        )
      }

      <section className="billing-subscription-info">
        <UserProfile 
          t={ t }
          user={ user }
          save={ save }
          onChange={ onChange } />

        <div className="subscription-info">
          <h2>
            { `${subscriptionInfo.subscriptionStatus} ${t(`Subscription`)}` }
            { subscriptionInfo.subscriptionStatus === `active` || subscriptionInfo.subscriptionStatus === `lifetime` ? <Verified /> : <TimesCheck /> } 
          </h2>

          <div>
            <div>
              { subscriptionInfo.serviceName &&
                <div>
                  <span>
                    { `${t(`Subscription Type`)}: ` }
                  </span>
                    
                  <span>
                    { subscriptionTypes?.find((st) => st.serviceName === subscriptionInfo.serviceName)?.serviceDescription || `n/a` }
                  </span>
                </div>
              }

              <div>
                <span>
                  { `${t(`paymentType`)}: ` }
                </span>
                
                <span>
                  { subscriptionInfo.subscriptionPaymentType ? subscriptionInfo.subscriptionPaymentType : `n/a` }
                </span>
              </div>

              <div>
                <span>
                  { `${t(`expires`)}: ` }
                </span>
                
                <span>
                  {
                    `${ subscriptionInfo.prefSubscriptionExpires.slice(0, 10) } ` +
                    `(${ daysRemaining } ${ t(`daysRemaining`) })`
                  }
                </span>
              </div>
            </div>

            <div>
              <span className="button-container">
                <Button
                  type="primary"
                  onClick={ (e) => {
                    e.preventDefault();
                    window.location.href = `${ window.WWW_URL }/subscription`;
                    return false;
                  } }>
                  { t(`manageSubscription`) }
                </Button>
              </span>
            </div>
          </div>
        </div> 
      </section>

      <section className="billing-subscription-info prime-trust-container">
        <PrimeTrustContactInformation t={ t } user={ user }/>
      </section>

      <h1>
        { t(`invoices`) }
      </h1>
      <section>
        <div>
          <EntityTable
            columns={ columns }
            data={ invoices || [] }
            defaultSortBy="-paymentDate"
            hidePagination={ true }
            pageSize={ `all` } />
        </div>
      </section>
    </div>
  );
};

export { AccountEditor as PureAccountEditor };
export default withRouter(AccountEditor);

'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path d="M24 16.971l-7.029 7.029h-9.942l-7.029-7.029v-9.942l7.029-7.029h9.942l7.029 7.029z" /><text text-anchor="middle" x="12" y="18" >S</text>`,
  {
    width: `2.5rem`,
    height: `2.5rem`,
    viewBox: `0 0 24 24`
  }
);

// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import AlertForm from './AlertForm.jsx';
import VolumeAlertForm from './VolumeAlertForm.jsx';
import { editAlert } from '../../helpers/api/AlertApi';
import { toFixedDecimals } from '../../helpers/NumberHelper.js';
import type { Market } from '../../types/Market.js';
import type { Exchange } from '../../types/Exchange.js';
import { emitEvent, ALERT_EDITED } from '../../helpers/EventHelper.js';
import type { ActiveAlert } from '../../types/ActiveAlert.js';
import CoinigyBaseComponent from '../CoinigyBaseComponent.jsx';

type Props = {
  t: any,
  alert: ActiveAlert,
  markets: Array<Market>,
  exchanges: Array<Exchange>,
  lastPrice: number,
  options: Array<{
    label: string,
    value: any,
    icon?: any,
    compareValue?: any
  }>,
  getTickerData: (e: Exchange, m: Market, c: (p: number) => void) => void,
  onRequestClose?: () => void
};

type State = {
  exchId: number,
  marketId: number,
  price: string,
  note: string,
  sound: string,
  conditionalOperator: string,
  reqMsg: string,
  interval: string,
  numCandles: number
};

class EditAlert extends CoinigyBaseComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    let alert: ActiveAlert = this.props.alert;
    let market: ?Market = this.props.markets.find((m) => m.exchmktId === alert.data.exchMktId);

    if (!market)
      return;
    let exchId = market.exchId;
    let marketId = market.marketId;
    let price = ``;
    let numCandles = 0;
    
    if (props.alert.type == `PRICE`) {
      price = toFixedDecimals(props.alert.data.price, false, `price`, market);
    } else if (props.alert.type == `VOLUME`) {
      price = toFixedDecimals(props.alert.data.value, false, `price`, market);
      numCandles = props.alert.data.numCandles;
    }

    this.state = {
      exchId,
      marketId,
      price: price,
      note: alert.data.note,
      sound: alert.data.sound,
      conditionalOperator: alert.data.condition,
      reqMsg: ``,
      interval: this.props.alert.interval,
      numCandles: numCandles
    };
  }

  componentWillMount() {
    this.refreshCurrentPrice();
  }

  handleInputChange(e: any, key: string, forceNumber?: boolean, value?: any) {
    let state = { };
    if (value) {
      if (value == ``) value = 0;
      if (forceNumber) {
        state[key] = parseInt(value);
      }else {
        state[key] = value;
      }
      
    } else {
      if (key == `note`) {
        state[key] = e.target.value;
      }else {
        if (e.target.value == ``) e.target.value = 0;
      
        if (/^\d+(\.\d*)?$/.test(e.target.value)) {
          if (forceNumber) { 
            state[key] = parseInt(e.target.value.toString().replace(/\D/g,``)); 
          }else {
            state[key] = e.target.value;
          }
        
        }      
      }
    }
    
    if (key == `exchId`) state.marketId = -1;
    
    if (key == `price` && this.props.alert.type == `PRICE` && this.props.lastPrice !== 0) {
      state.conditionalOperator = parseFloat(e.target.value) > this.props.lastPrice ? `GreaterThanOrEqual` : `LessThanOrEqual`;
    }

    this.setStateSafe(state, () => {
      const activeExch = this.props.exchanges.filter((e) => e.exchId == this.state.exchId)[0];
      const activeMkt = this.props.markets.filter((m) => {
        return m.exchId == this.state.exchId && m.marketId == this.state.marketId;
      })[0];

      if ((key == `exchId` || key == `marketId`) && !!activeExch && !!activeMkt) {
        this.props.getTickerData && this.props.getTickerData(activeExch, activeMkt, (price: number) => {
          this.setStateSafe({
            price: price ? toFixedDecimals(price, false, `price`, activeMkt) : ``
          });
        });
      }
    });

  }

  handleSubmit(e?: any): boolean {
    if (e) e.preventDefault();

    let market = this.props.markets.find((m) => m.exchId == this.state.exchId && m.marketId == this.state.marketId);
    if (!market) return false;

    let body = {
      type: this.props.alert.data.type,
      exchMktId: market.exchmktId,
      value: this.state.price,
      note: this.state.note,
      sound: this.state.sound,
      condition: this.state.conditionalOperator,
      interval: this.state.interval,
      numCandles: this.state.numCandles,
      ignoreCurrentBar: true,
    };

    if (!body.exchMktId) {
      this.setState({ reqMsg: this.props.t(`validMktExch`) });
      return false;
    }

    editAlert(this.props.alert.alertId.toString(), body, (data) => {
      if (data.success) {
        emitEvent(ALERT_EDITED, data.result);
        this.props.onRequestClose && this.props.onRequestClose();
      } else {
        this.setState({
          reqMsg: this.props.t(`errorModifying`)
        });
      }
    });

    return false;
  }

  refreshCurrentPrice() {
    let activeExch = this.props.exchanges.filter((e) => e.exchId == this.state.exchId)[0],
      activeMkt = this.props.markets.filter((m) => {
        return m.exchId == this.state.exchId && m.marketId == this.state.marketId;
      })[0];

    if (!!activeExch && !!activeMkt) {
      this.props.getTickerData && this.props.getTickerData(activeExch, activeMkt, (price: number) => {
        this.setState({
          price: price ? toFixedDecimals(price, false, `price`, activeMkt) : ``
        });
      });
    }
  }

  render() {
    return (
      <div className="edit-alert">
        { this.props.alert.type == `PRICE` &&
          <AlertForm
            hasActiveMarket={ false }
            exchanges={ this.props.exchanges }
            markets={ this.props.markets }
            exchId={ this.state.exchId }
            marketId={ this.state.marketId }
            price={ this.state.price }
            conditionalOperator={ this.state.conditionalOperator }
            note={ this.state.note }
            overrideAlertSound={ this.state.sound }
            reqMsg={ this.state.reqMsg }
            onSubmit={ (e) => this.handleSubmit(e) }
            onChange={ this.handleInputChange.bind(this) }
            refreshCurrentPrice={ this.refreshCurrentPrice.bind(this) }
            confirmText={ this.props.t(`editAlertConfirm`) }
            marketLabel={ `` } />
        }
        {
          this.props.alert.type == `VOLUME` &&
          <VolumeAlertForm
            hasActiveMarket={ false }
            exchanges={ this.props.exchanges }
            markets={ this.props.markets }
            exchId={ this.state.exchId }
            marketId={ this.state.marketId }
            price={ this.state.price }
            note={ this.state.note }
            sound={ this.state.sound }
            overrideAlertSound={ this.state.sound }
            conditionalOperator={ this.state.conditionalOperator }
            reqMsg={ this.state.reqMsg }
            onSubmit={ (e) => this.handleSubmit(e) }
            options={ this.props.options }
            interval={ this.state.interval }
            numCandles={ this.state.numCandles }
            onChange={ this.handleInputChange.bind(this) }
            confirmText={ this.props.t(`editAlertConfirm`) } />
        }
      </div>
    );
  }
}

export { EditAlert as PureEditAlert };
export default translate(`alerts`)(EditAlert);

// @flow
'use strict';

import { SET_PRICE_TYPES } from '../index.js';

export function setPriceTypes(priceTypes: Array<{ id: number, name: string }>) {
  return {
    type: SET_PRICE_TYPES,
    payload: priceTypes.reduce((ret, p) => ({ ...ret, [p.id]: p.name }), { })
  };
}

'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path class="message-square" d="M15.333 3.999V12c0 1.102-.898 2-2 2H2.667c-1.102 0-2-.898-2-2V4v-.001c0-1.101.899-1.999 2-1.999h10.666c1.102 0 2 .898 2 1.999zm-1.404-.296a.67.67 0 0 0-.596-.37H2.667a.67.67 0 0 0-.596.37l5.93 4.15 5.928-4.15zM14 5.28L8.382 9.213a.667.667 0 0 1-.764 0L2 5.28V12a.67.67 0 0 0 .667.667h10.666A.67.67 0 0 0 14 12V5.28z"/>`,
  {
    width: `1.6rem`,
    height: `1.4rem`,
    viewBox: `0 0 16 14`
  }
);
/* eslint-enable */

// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import EntityTable from '../utilities/EntityTable.jsx';
import { toFixedDecimalsHTML } from '../../helpers/NumberHelper.js';
import type { MarketOrder } from '../../types/MarketOrder.js';
import type { Market } from '../../types/Market.js';
import {
  emitEvent,
  MARKETS_PRICE_CLICK,
  ORDER_LEVEL_HOVER,
  ORDER_TABLE_UNHOVER
} from '../../helpers/EventHelper.js';

type Props = {
  t: any,
  market: Market,
  buys: Array<MarketOrder>,
  sells: Array<MarketOrder>,
  height?: number,
  id?: string,
  showCumulative?: boolean
};

const formatColumns = (orders, type, showCumulative = false) => {

  if (showCumulative) {
    return orders.sort((a, b) => {
      if (type == `sells` && a.price > b.price) return 1;
      if (type == `sells` && a.price < b.price) return -1;
      if (type == `buys` && a.price < b.price) return 1;
      if (type == `buys` && a.price > b.price) return -1;
      return 0;
    }).reduce((orders, order) => {
      return orders.concat([{
        ...order,
        cumulative: (orders.length > 0 ? orders[orders.length - 1].cumulative : 0) + order.total
      }]);
    }, []);
  }else {
    return orders.sort((a, b) => {
      if (type == `sells` && a.price > b.price) return 1;
      if (type == `sells` && a.price < b.price) return -1;
      if (type == `buys` && a.price < b.price) return 1;
      if (type == `buys` && a.price > b.price) return -1;
      return 0;
    });
  }
};

const handleOrderLevelHover = (i, d, s, type) => {
  emitEvent(ORDER_LEVEL_HOVER, { index: i, data: d, type, state: s });
};

const handleTableUnHover = (type) => {
  emitEvent(ORDER_TABLE_UNHOVER, { type });
};


const getId = () => {
  if (process.env.NODE_ENV !== `test`) {
    let text = ``,
      possible = `ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789`;

    for (var i = 0; i < 5; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  } else { 
    return `ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789`;
  }
  
};

const MarketDepthChartData = ({ t, buys, sells, market, height, id = getId(), showCumulative = false }: Props) => (
  <div>
    <div className={ `container container-${ id } market-large-depth-data` }>
      {
        height && (
          <style dangerouslySetInnerHTML={ { __html:
            `.container-${ id  } { --parent-height: ${ height }px }`
          } } />
        )
      }
      <div className="left">
        <EntityTable
          columns={ [
            {
              title: t(`app:bid`),
              key: `price`,
              display: (row, price) => (
                <span 
                  style={ { cursor: `pointer` } } 
                  onClick={ () => {
                    emitEvent(MARKETS_PRICE_CLICK, {
                      price
                    });
                  } } 
                  dangerouslySetInnerHTML={ 
                    toFixedDecimalsHTML(price, false, `price`, market)
                  } />
              )
            },
            {
              title: t(`app:qty`),
              key: `quantity`,
              display: (row, quantity) => (
                <span dangerouslySetInnerHTML={ 
                  toFixedDecimalsHTML(quantity, false, `quantity`, market)
                } />
              )
            },
            {
              title: t(`app:total`),
              key: `total`,
              display: (row, total) => (
                <span dangerouslySetInnerHTML={ 
                  toFixedDecimalsHTML(total, false, `price`, market)
                } />
              )
            },
            ...(showCumulative ? 
              [{
                title: t(`app:cumulative`),
                key: `cumulative`,
                display: (row, cumulative) => (
                  <span dangerouslySetInnerHTML={ 
                    toFixedDecimalsHTML(cumulative, false, `price`, market)
                  } />
                )
              }] 
              : [])
              
          ] }
          data={ formatColumns(buys, `buys`, showCumulative) }
          defaultSortBy="-price"
          hidePagination={ true }
          rowHover={ (i, d, s) => handleOrderLevelHover(i, d, s, `buy`) }
          tableUnhover={ () => handleTableUnHover(`buy`) }
          scrollFix={ true } />
      </div>
      <div className="right">
        <EntityTable
          columns={ [
            {
              title: t(`app:ask`),
              key: `price`,
              display: (row, price) => (
                <span 
                  style={ { cursor: `pointer` } } 
                  onClick={ () => {
                    emitEvent(MARKETS_PRICE_CLICK, {
                      price
                    });
                  } } 
                  dangerouslySetInnerHTML={ 
                    toFixedDecimalsHTML(price, false, `price`, market)
                  } />
              )
            },
            {
              title: t(`app:qty`),
              key: `quantity`,
              display: (row, quantity) => (
                <span dangerouslySetInnerHTML={ 
                  toFixedDecimalsHTML(quantity, false, `quantity`, market)
                } />
              )
            },
            {
              title: t(`app:total`),
              key: `total`,
              display: (row, total) => (
                <span dangerouslySetInnerHTML={ 
                  toFixedDecimalsHTML(total, false, `price`, market)
                } />
              )
            },
            ...(showCumulative ? 
              [{
                title: t(`app:cumulative`),
                key: `cumulative`,
                display: (row, cumulative) => (
                  <span dangerouslySetInnerHTML={ 
                    toFixedDecimalsHTML(cumulative, false, `price`, market)
                  } />
                )
              }]
              : [])

          ] }
          data={ formatColumns(sells, `sells`, showCumulative) }
          defaultSortBy="+price"
          hidePagination={ true }
          rowHover={ (i, d, s) => handleOrderLevelHover(i, d, s, `sell`) }
          tableUnhover={ () => handleTableUnHover(`sell`) }
          scrollFix={ true } />
      </div>
    </div>
  </div>
);

export { MarketDepthChartData as PureMarketDepthChartData };
export default translate(`markets`)(MarketDepthChartData);

// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import Modal from '../utilities/Modal.jsx';
import TextField from '../utilities/TextField.jsx';
import Button from '../utilities/Button.jsx';

type Props = {
  t: any,
  title: string,
  save: (l: string) => void,
  disabled: boolean,
  close: () => void,
  importDemo?: () => boolean,
  label?: string
};

type State = {
  newBoardLabel: string
};

class BoardFormModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      newBoardLabel: props.label ? props.label : ``
    };
  }

  handleInputChange(v: any, key: string) {
    this.setState({
      [key]: v
    });
  }

  render() {
    return (
      <Modal
        title={ this.props.title }
        onConfirm={ () => this.props.save(this.state.newBoardLabel) }
        onClose={ this.props.close }
        confirmText={ this.props.t(`app:save`) }
        cancelText={ this.props.t(`app:cancel`) }
        confirmDisabled={ this.props.disabled || this.state.newBoardLabel.length == 0 }>
        <TextField
          label={ this.props.t(`app:title`) }
          name={ `newBoardLabel` }
          value={ this.state.newBoardLabel }
          onChange={ (e) => this.handleInputChange(e.target.value, `newBoardLabel`) } />
        {
          this.props.importDemo && (
            <div style={ { textAlign: `center` } }>
              <Button 
                type="primary"
                disabled={ this.props.disabled }
                onClick={ () => !!this.props.importDemo && this.props.importDemo() }>
                Import Sample Board
              </Button>
            </div>
          )
        }
      </Modal>
    );
  }
}

export { BoardFormModal as PureBoardFormModal };
export default translate(`boards`)(BoardFormModal);

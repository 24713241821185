// @flow
'use strict';

import React from "react";

type Props = {
  index: number,
  activeIndex: number,
  children: any,
  isVisible?: boolean
};

const TabPane = ({ index, activeIndex, children, isVisible = true }: Props) => {
  if (index == activeIndex) {
    return (
      <div className={ `tab-pane fade-in ${!isVisible ? `hide` : ``} ` }>
        { children }
      </div>
    );
  } else {
    return null;
  }
};

export default TabPane;

// @flow
'use strict';

import { UPDATE_BOH_ACTIVE_TAB } from '../index.js';

export function updateBOHActiveTab(payload: number) {
  return {
    type: UPDATE_BOH_ACTIVE_TAB,
    payload
  };
}

// @flow
'use strict';

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toFixedDecimalsHTML } from "../helpers/NumberHelper";
import type { Favorite } from "../types/Favorite";
import type { Market } from "../types/Market";
import MarketUpArrow from "../svgs/MarketUpArrow.jsx";
import MarketDownArrow from "../svgs/MarketDownArrow.jsx";
import { getMarketPair } from "../helpers/MarketPairHelper";

type Props = {
  favorite: Favorite,
  market?: Market,
  isPercentVisible: boolean,
  clickable: boolean,
  changeMarket: (b: boolean) => void,
};

const FavoriteFooter = (props: Props) => {
  const [marketDirection, setMarketDirection ] = useState(0);
  const [price, setPrice ] = useState(0);
  const { favorite, market, isPercentVisible, clickable, changeMarket } = props;

  const fallback = `${ window.WWW_URL }/assets/img/currency/empty.png`;
  const currencyImage = `${ window.WWW_URL }/assets/img/currency/${ favorite.quoteImgName ? favorite.quoteImgName : favorite.baseCurrCode }.png`;
  
  const [currencySrc, setCurrencySrc] = useState<string>(currencyImage);
  const onError = () => setCurrencySrc(fallback);
  
  useEffect(() => {
    if (favorite && favorite.last) {
      if (favorite.marketDirection) {
        setPrice(parseFloat(favorite.last));
        setMarketDirection(favorite.marketDirection || 0);
      } else if (parseFloat(favorite.last) !== price) {
        setPrice(parseFloat(favorite.last));
        setMarketDirection((price > 0 && favorite.last - price) || 0);
      }
    }
  }, [favorite]);

  const resetDataFavorite = () => {
    setMarketDirection(0);
    setPrice(0);
    changeMarket(true);
  };

  const CurrencyIcon = () => {
    return  <img
      src={ currencySrc ? currencySrc : fallback }
      alt={ favorite.baseCurrCode }
      onError={ onError }/>;
  };

  if (!market) return (<div />);
  return (
    <Link
      data-testid="favorite-footer"
      className={
        clickable ? `` : `market-link-disabled`
      }
      to={ 
        clickable ?
          `/markets/${ market.exchCode }/${ getMarketPair(market).toString() }` :
          `#`
      }
      onClick={ () => resetDataFavorite() }>
      <div className="currency-icon">
        <CurrencyIcon />
      </div>
      <div className="exchmkt-container">
        <div className="mkt-name">
          { getMarketPair(market).toString() }
        </div>
        <div className="exch-container">
         
          <div className="exch-name">
            { favorite.exchName }
          </div>
        </div>
      </div> 
      <div className={ `price-arrow ${marketDirection > 0 ? `up` : ``} ${marketDirection < 0 ? `down` : ``}` }>
        { 
          marketDirection !== 0 && 
          (marketDirection < 0 ? 
            MarketDownArrow(`${ market.exchCode }_${ getMarketPair(market).toString().replace(`/`, `_`) }`) : 
            MarketUpArrow(`${ market.exchCode }_${ getMarketPair(market).toString().replace(`/`, `_`) }`)) 
        }
      </div>
      <div className={ `value` }>
        <div
          className={ `price ${marketDirection > 0 ? `up` : ``} ${marketDirection < 0 ? `down` : ``}` }
          dangerouslySetInnerHTML={
            price > 0 ? toFixedDecimalsHTML(price, false, `price`, market, true) :
              { __html: `Loading...` }
          } />
        <div 
          className={ `volume ${ favorite.percent ? (favorite.percent > 0 ? `up` : `down`) : `` }` }
          dangerouslySetInnerHTML={
            isPercentVisible && favorite.percent ?
              { __html: `${
                favorite.percent > 0 ?
                  `+` :
                  ``
              }${
                (Math.floor(favorite.percent * 100) / 100).toFixed(2)
              }%`
              } :
              toFixedDecimalsHTML(favorite.volume, true, `quantity`, market, true)
          } />
      </div>
    </Link>
  );
  
};

export default FavoriteFooter;

'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default (uniqueID = `CollapseUp`) => SvgHelper(
  `<path d="M8.52964 4.99331L12.7796 9.24331C13.0734 9.53706 13.0734 10.0121 12.7796 10.3027L12.0734 11.0089C11.7796 11.3027 11.3046 11.3027 11.014 11.0089L8.00151 7.99644L4.98901 11.0089C4.69526 11.3027 4.22026 11.3027 3.92964 11.0089L3.21714 10.3058C2.92339 10.0121 2.92339 9.53706 3.21714 9.24644L7.46714 4.99644C7.76089 4.69956 8.23589 4.69956 8.52964 4.99331Z" fill="currentColor"/>`,
  {
    width: `16px`,
    height: `16px`,
    viewBox: `0 0 16 16`
  }
);
/* eslint-enable */

'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path class="lock" d="M4 6.667v-2a4 4 0 1 1 8 0v2h.667a2 2 0 0 1 2 2v4.666a2 2 0 0 1-2 2H3.333a2 2 0 0 1-2-2V8.667a2 2 0 0 1 2-2H4zm1.333 0h5.334v-2a2.667 2.667 0 0 0-5.334 0v2zM3.333 8a.667.667 0 0 0-.666.667v4.666c0 .369.298.667.666.667h9.334a.667.667 0 0 0 .666-.667V8.667A.667.667 0 0 0 12.667 8H3.333z"/>`,
  {
    width: `1.5rem`,
    height: `1.5rem`,
    viewBox: `0 0 15 15`
  }
);
/* eslint-enable */

// @flow
'use strict';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import MarketChart from '../markets/MarketChart.jsx';
import type { Market } from '../../types/Market.js';
import type { Exchange } from '../../types/Exchange.js';
import * as orderApi from '../../helpers/api/OrderApi.js';
import { translateOrder } from '../../helpers/OrderTransformationHelper.js';
import {
  emitEvent,
  listenForEvent,
  removeEventListener,
  UPDATE_CURRENT_AUTHID,
  MARKET_RECEIVED_NEW_TRADE,
  SILENT_NOTIFICATION,
  REFRESH_ORDERS,
  PENDING_ORDER_ADDED,
  ORDER_ADDED_FAILED,
  ORDER_ADDED,
} from '../../helpers/EventHelper.js';

type Props = {
  t: any,
  panelData: any,
  resolution: string,
  user_hash: string,
  overrideChartTheme: boolean,
  theme: string,
  market: Market,
  exchange: Exchange,
  settings: any,
  autoSaveDisabled: any,
  showOrderHistory: any,
  showOpenOrders: any,
  showCurrentAskBidLine: any,
};

const TradingViewChartPanel = (props: Props) => {
  const { 
    panelData, resolution, user_hash, overrideChartTheme, theme, market, 
    exchange, settings, autoSaveDisabled, showOrderHistory, showOpenOrders,
    showCurrentAskBidLine 
  } = props;
  const [ tvcOpenOrders, setTvcOpenOrder ] = useState([]);
  const [ tvcOrderHistory, setTvcOrderHistory ] = useState([]);
  
  const mkt = `${ settings.exchange }:${ settings.market }`;
  const isReady = panelData.hasOwnProperty(mkt) && market && exchange;

  const getTVCOrderHistory = () => {
    orderApi.getOrderHistory({ params: { pageSize: 250, pageNumber: 1, ExchMktId: market.exchmktId } }, (data) => {
      setTvcOrderHistory(data.result ? data.result.map(translateOrder) : []);
    });
  };

  const getTVCOpenOrders = () => {
    orderApi.getOrders({ params: { pageSize: 250, pageNumber: 1, ExchMktId: market.exchmktId } }, (data) => {
      setTvcOpenOrder(data.result ? data.result.map(translateOrder) : []);
    });
  };

  const getTVCOrders = () => {
    getTVCOrderHistory();
    getTVCOpenOrders();
  };

  const silentNotificationCallback = (e: any) => {
    switch (e.detail.type) {
    case 221: // orders
      getTVCOrders();
      break;
    }
  };

  useEffect(() => {
    if (market?.exchmktId) {
      getTVCOrders();

      listenForEvent(REFRESH_ORDERS, getTVCOrders);
      listenForEvent(PENDING_ORDER_ADDED, getTVCOrders);
      listenForEvent(ORDER_ADDED_FAILED, getTVCOrders);
      listenForEvent(ORDER_ADDED, getTVCOrders);
      listenForEvent(SILENT_NOTIFICATION, silentNotificationCallback);
    }

    return () => {
      removeEventListener(REFRESH_ORDERS, getTVCOrders);
      removeEventListener(PENDING_ORDER_ADDED, getTVCOrders);
      removeEventListener(ORDER_ADDED_FAILED, getTVCOrders);
      removeEventListener(ORDER_ADDED, getTVCOrders);
      removeEventListener(SILENT_NOTIFICATION, silentNotificationCallback);
    };
  }, [market?.exchmktId ]);

  useEffect(() => {
    if (isReady && settings.account) {
      emitEvent(UPDATE_CURRENT_AUTHID, settings.account);
    }
  }, [isReady, settings.account]);

  useEffect(() => {
    if (isReady && panelData[mkt]?.trades?.length) {
      const trades = [...panelData[mkt].trades];
      trades.reverse().forEach(function (t, index) {
        let updateBar = false;
        let nextTrade = (trades.length - 1 == index) ? undefined : trades[index+1];
        let prevTrade = (0 == index) ? undefined : trades[index-1];
        if(!nextTrade || !prevTrade ||
          +(new Date(t.time)) != +(new Date(nextTrade.time)) ||
          +(new Date(t.time)) != +(new Date(prevTrade.time))){
          updateBar = true;
        }

        const tradeToEmit = {
          ...t,
          exchange: settings.exchange,
          label: settings.market
        };
  
        emitEvent(MARKET_RECEIVED_NEW_TRADE, {
          trade: {
            ...tradeToEmit,
            updateBar: updateBar
          }
        });
      });
    }
  }, [isReady, panelData[mkt]?.trades]);

  return isReady ? (
    <MarketChart
      active={ {
        exchange: exchange,
        market: market,
      } }
      bid={ panelData[mkt]?.depth?.bids?.length > 0 ?
        panelData[mkt].depth.bids[panelData[mkt].depth.bids.length - 1].price :
          panelData[mkt]?.ticker?.bid }      
      ask={ panelData[mkt]?.depth?.asks?.length > 0 ?
        panelData[mkt].depth.asks[0].price :
          panelData[mkt]?.ticker?.ask }
      theme={ theme }
      user_hash={ user_hash }
      resolution={ resolution }
      tvcOpenOrders={ tvcOpenOrders
      }
      tvcOrderHistory={ tvcOrderHistory }
      activeAlerts={ [] }
      chartClass={ `trading-view-chart-panel` }
      autoSaveDisabled={ autoSaveDisabled }
      showOrderHistory={ showOrderHistory }
      showOpenOrders={ showOpenOrders }
      showCurrentAskBidLine={ showCurrentAskBidLine }
      overrideChartTheme={ overrideChartTheme }
      showCustomButtons={ false }
      panelId={ settings.panelId }/>
  ) : null;
};

const mapStateToProps = (state) => ({
  theme: state.redisPrefs.theme,
  autoSaveDisabled: !!state.redisPrefs.autoSaveDisabled,
  showOrderHistory: state.redisPrefs.showOrderHistory,
  showOpenOrders: state.redisPrefs.showOpenPositions,
  showCurrentAskBidLine: state.redisPrefs.showCurrentAskLine,
  overrideChartTheme: state.redisPrefs.overrideChartTheme,
  user_hash: state.userInfo.user_hash,
  resolution: state.markets.resolution
});

// $FlowIgnore: suppressing this error
TradingViewChartPanel.getPanelTitle = (settings: Settings) => {
  return settings ? `Chart: ${settings.exchange}:${settings.market}` : `Candlestick Chart`;
};

export { TradingViewChartPanel };

export default translate(`boards`)(connect(mapStateToProps)(TradingViewChartPanel));

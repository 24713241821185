// @flow
'use strict';

import React from 'react';
import MessageSquare from "../../../svgs/MessageSquare.jsx";

type MenuLinkProps = {
  text: string,
}

export const SubmitTicketLink = (props: MenuLinkProps) => {
  const { text } = props;

  return (
    <a
      href="https://support.coinigy.com/hc/en-us/requests/new?ticket_form_id=360000134774"
      rel="noopener noreferrer"
      target="_blank">
      { MessageSquare }
      <span>
        { text }
      </span>
    </a>
  );
};

// @flow
'use strict';

import { SET_STATUS_TYPES } from '../index.js';

export function setStatusTypes(priceTypes: Array<{ id: number, name: string }>) {
  return {
    type: SET_STATUS_TYPES,
    payload: priceTypes.reduce((ret, p) => ({ ...ret, [p.id]: p.name }), { })
  };
}

// @flow
'use strict';

import React from 'react';
import User from "../../svgs/User.jsx";

export const ToggleProfileButton = (props: any) => {
  const { toggleProfileMenu } = props;

  return(
    <a onClick={ () => toggleProfileMenu() } data-testid={ `profile-menu-link` } className="profile-button">
      { User }
    </a>
  );
};

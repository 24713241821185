// @flow
'use strict';

export default [
  `pop`,
  `airhorn`,
  `alarm`,
  `alarm2`,
  `alarm3`,
  `alarmalert`,
  `alarmclock`,
  `alarmecho`,
  `atari`,
  `beeps`,
  `beeps2`,
  `big_dog`,
  `cathedral`,
  `chingching`,
  `ding`,
  `ding2`,
  `ding3`,
  `ding4`,
  `dinger`,
  `dog_bark1`,
  `dog_bark2`,
  `dog_bark3`,
  `dog_bark4`,
  `dogs`,
  `evil_laugh`,
  `gongbells`,
  `longalert`,
  `longalert2`,
  `longalert3`,
  `longalert4`,
  `mariocoin`,
  `martianscanner`,
  `missilealert`,
  `musicbox`,
  `pop2`,
  `radiointerrupt`,
  `redalert`,
  `register`,
  `register2`,
  `register3`,
  `register4`,
  `schoolbell`,
  `small_dog`,
  `smb_stage_clear`,
  `smb_warning`,
  `smb_world_clear`,
  `smokealarm`,
  `triangles`,
  `wobblesound`,
  `zeldarecorder`,
  `zeldasecret`,
  `none`
];

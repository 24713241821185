// @flow
'use strict';

import React from 'react';
import { flexRender } from '@tanstack/react-table';
import { useVirtual } from 'react-virtual';

export const TableBody = ({ table, tableContainerRef, sorting, rowHover, rowUnhover }: any) => {
  const { rows } = table.getRowModel();
  
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 5,
  });
  
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

  return (
    <tbody>
      { paddingTop > 0 && (
        <tr>
          <td style={ { height: `${paddingTop}px` } } />
        </tr>
      ) }
      { virtualRows.map((virtualRow) => {
        const row = rows[virtualRow.index];
        return (
          <React.Fragment key={ row.id }>
            <tr 
              onClick={ () => row.getCanExpand() && row.toggleExpanded() }
              onMouseEnter={ () => { 
                rowHover && rowHover(row.id, row.original, { sortBy: sorting[0]?.id, sortDirection: sorting[0]?.desc ? `-` : `+` }); 
              } }
              onMouseLeave={ () => { 
                rowUnhover && rowUnhover(row.id, row.original, { sortBy: sorting[0]?.id, sortDirection: sorting[0]?.desc ? `-` : `+` });
              } }>
              { row.getVisibleCells().map((cell) => (
                <td 
                  key={ cell.id }
                  className={ `${cell.column.columnDef.meta.tdClass || ``} ${cell.column.getIsPinned() ? `pinned` : ``}` }
                  style={ { 
                    left: cell.column.getIsPinned() ? `calc(${cell.column.getStart(`left`)}px - 1rem)` : ``,
                    minWidth: cell.column.getIsPinned() ? cell.column.getSize() : `` 
                  } }>
                  { flexRender(cell.column.columnDef.cell, cell.getContext()) }
                </td>
              )) }
            </tr>
            { row.getIsExpanded() ? row.original.collapsibleData.map((sr, idx) =>
              <tr key={ `${row.id}-cs-${idx}` }>
                { Object.keys(sr).map((c, i) => 
                  <React.Fragment key={ i }>
                    { sr[c](row.id) }
                  </React.Fragment>
                )
                }
              </tr>) : null
            }
          </React.Fragment>
        );
      }) }
      { paddingBottom > 0 && (
        <tr>
          <td style={ { height: `${paddingBottom}px` } } />
        </tr>
      ) }
    </tbody>
  );
};

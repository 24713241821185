'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default () => SvgHelper(
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M5 1H11C11.5523 1 12 1.44772 12 2V14C12 14.5523 11.5523 15 11 15H5C4.44772 15 4 14.5523 4 14V2C4 1.44772 4.44772 1 5 1ZM3 2C3 0.895431 3.89543 0 5 0H11C12.1046 0 13 0.895431 13 2V14C13 15.1046 12.1046 16 11 16H5C3.89543 16 3 15.1046 3 14V2ZM8 12C7.44772 12 7 12.4477 7 13C7 13.5523 7.44772 14 8 14C8.55228 14 9 13.5523 9 13C9 12.4477 8.55228 12 8 12Z" fill="#3B96FF"/>',
    {
        width: `16px`,
        height: `16px`,
        viewBox: `0 0 16 16`
    }
)
// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import Modal from '../../utilities/Modal.jsx';
import BakktAccountForm from './BakktAccountForm.jsx';

type Props = {
  t: any,
  onClose: () => void
};

const BakktAccountModal = function (props: Props) {

  return (
    <div className={ `bakkt-modal` }>
      <Modal
        title={ `` }
        onClose={ () => props.onClose && props.onClose() }
        handleEscape={ false }>
        <BakktAccountForm callback={ () => props.onClose && props.onClose() }/>
      </Modal>
    </div>
  );
};

export { BakktAccountModal as PureBakktAccountModal };
export default translate(`users`)(BakktAccountModal);

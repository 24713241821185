// @flow
'use strict';

import React, { useState, useEffect, useMemo } from 'react';
import { useStateRef } from '../helpers/useStateRef';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import PageVisibility from 'react-page-visibility';
import { isMobile, isIOS, isAndroid } from 'react-device-detect';
//import { Link } from 'react-router-dom';
import SelectField from './utilities/SelectField.jsx';
import UndockedSelectField from './utilities/UndockedSelectField.jsx';
import NotificationTray from './utilities/NotificationTray.jsx';
import KeyboardShortcutModal from './utilities/KeyboardShortcutModal.jsx';
import TryMobileAppModal from './utilities/TryMobileAppModal.jsx';
import Modal from './utilities/Modal.jsx';
import AlertBell from '../svgs/AlertBell.jsx';
import Globe from "../svgs/Globe.jsx";
import Bug from '../svgs/Bug.jsx';
import { updateRedisPrefs } from '../actions/redisPrefs/updateRedisPrefs.js';
import { emitEvent,
  listenForEvent,
  removeEventListener,
  NOTIFICATION,
  SHOW_KEYBOARD_LEGEND,
  // SHOW_LSCX_MODAL,
  SHOW_REPORTBUG_MODAL,
  SHOW_KEYBOARD_MODAL,
  START_TOUR,
  DISMISS_NOTIFICATION,
  SHOW_FAQS_MODAL,
  SHOW_MOTD_MODAL,
  TOUR_COMPLETED,
  SHOW_BAKKT_ACCOUNT_MODAL
} from '../helpers/EventHelper.js';
import { getActivity, bugReport } from '../helpers/api/UserApi.js';
import { formatMessageWithVars } from '../helpers/NotificationHelper.js';
import type { Market } from '../types/Market.js';
import type { User } from '../types/User.js';
// used for the ActiveSubscribersModal, removed 11/1/2022
//import type { UserPrefs } from '../types/UserPrefs.js';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { languages, getFlagEmoji } from '../helpers/I18nHelper.js';
// import PrimeTrustAccountModal from './user/PrimeTrustAccountModal.jsx';
// import PrimeTrustAcceptTermsModal from './user/PrimeTrustAcceptTermsModal.jsx';
import EnableTwoFactorAuthModal from './user/EnableTwoFactorAuthModal.jsx';
// used for the ActiveSubscribersModal, removed 11/1/2022
//import ActiveSubscribersModal from './user/ActiveSubscribersModal.jsx';
// import ExpiredAccessModal from './user/ExpiredAccessModal.jsx';
// import WireFundsModal from './user/WireFundsModal.jsx';
import FaqsModal from './user/FaqsModal.jsx';
import { readCookie, writeCookie } from '../helpers/CookieHelper';
// used for the ActiveSubscribersModal, removed 11/1/2022
//import { getSubscriptionData } from '../helpers/api/UserApi.js';
import type { Account } from "../types/Account.js";
import type { Exchange } from "../types/Exchange.js";
import type { UserApplication } from '../types/UserApplication.js';
import { getUserApplication } from '../helpers/UserApplicationsHelper.js';
import MOTDModal from './user/MOTDModal.jsx';
import Ticker from './utilities/Ticker.jsx';
//import Gear from "../svgs/Gear.jsx";
import BakktAccountModal from './user/Bakkt/BakktAccountModal.jsx';
// import NewUserModal from './user/NewUserModal.jsx';
import { isCoinigyProUser, isCoinigyTrialUser } from '../helpers/UserApplicationsHelper.js';

const EXPIRATION_2FA = 30; // 2fa modal popup expiration (in DAYS)
const EXPIRATION_MOTD = 1; // motd modal popup expiration (in DAYS)

type Props = {
  t: any,
  theme: string,
  user: User,
  isOnline: boolean,
  markets: Array<Market>,
  language: string,
  updateLanguage: (l: string) => void,
  platformId: number,
  fA2Enabled: boolean,
  rehydrated: boolean,
  // used for the ActiveSubscribersModal, removed 11/1/2022
  //userPrefs: UserPrefs,
  accounts: Array<Account>,
  exchanges: Array<Exchange>,
  userApplications: Array<any>,
  applicationsStatuses: Array<any>,
  tickerPosition: string,
  prefSubscriptionExpires: string,
  prefSubscriptionId: any,
  prefTrialUsed: boolean,
  newUserModalShown: string,
};

const Footer = (props: Props) => {
  const { t, theme, /* user, */ isOnline, markets, language, updateLanguage, platformId, fA2Enabled, 
    rehydrated, // userPrefs, 
    /* accounts, exchanges, */ userApplications, applicationsStatuses, tickerPosition, prefTrialUsed, prefSubscriptionExpires, prefSubscriptionId, newUserModalShown = false } = props;
  const [keyboardModalOpen, setKeyboardModalOpen] = useState<boolean>(false);
  const [tryMobileAppModalOpen, setTryMobileAppModalOpen] = useState<boolean>(false);
  const [notificationMenuModalOpen, setNotificationMenuModalOpen] = useState<boolean>(false);
  const [selectLanguageModalOpen, setSelectLanguageModalOpen] = useState<boolean>(false);
  const [reportBugModalOpen, setReportBugModalOpen] = useState<boolean>(false);
  const [reportBugType, setReportBugType] = useState<string>(`missingData`);
  const [reportBugText, setReportBugText] = useState<string>(``);
  // eslint-disable-next-line no-unused-vars
  const [isPercentVisible, setIsPercentVisible, isPercentVisible_ref] = useStateRef<boolean>(true);
  // eslint-disable-next-line no-unused-vars
  const [notifications, setNotifications, notifications_ref] = useStateRef<any>([]);
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [openPopover, setOpenPopover] = useState<string>(``);
  // const [lscxModalOpen, setLscxModalOpen]= useState<boolean>(false);
  // const [ acceptTermsModalOpen, setAcceptTermsModalOpen ] = useState(false);
  const [ fA2ModalOpen, setfA2ModalOpen ] = useState(false);
  const [ isTourOpen, setIsTourOpen ] = useState(false);
  // used for the ActiveSubscribersModal, removed 11/1/2022
  //const [ subscriptionInfo, setSubscriptionInfo ] = useState<number>(0);
  const [faqsModalOpen, setFaqsModalOpen] = useState<boolean>(false);
  const [MOTDModalOpen, setMOTDModalOpen] = useState<boolean>(false);
  //const [ isTourCompleted, setTourCompleted ] = useState<boolean>(Boolean(readCookie(`markets_tour_completed`)));
  const [bakktAccountModalOpen, setBakktAccountModalOpen]= useState<boolean>(false);
  const [newUserModalOpen, setNewUserModalOpen] = useState<boolean>(false);
  
  // const LSCXUserApplication: UserApplication = useMemo(() => getUserApplication(`LSCX`, userApplications, applicationsStatuses), [userApplications, applicationsStatuses]);
  const CGYUserApplication: UserApplication = useMemo(() => getUserApplication(`CGY`, userApplications, applicationsStatuses), [userApplications, applicationsStatuses]);

  const isTickerPositionHeader = tickerPosition === `header`;

  const handleShowKeyboardLegend = () => {
    if (!keyboardModalOpen) {
      setKeyboardModalOpen(true);
    }
  };

  // used for the ActiveSubscribersModal, removed 11/1/2022
  // const getPrefSubscriptionId = () => {
  //   getSubscriptionData((data) => (data.result) && 
  //   setSubscriptionInfo(data.result.subscriptionInfo.prefSubscriptionId));
  // };

  const handleNotifications = (e) => {
    const existingNotifications = [...notifications_ref.current];
    if (existingNotifications.length > 0 && existingNotifications.some((item) => item.id === e.detail.notification_id)) return;
    const incomingNotification = Object.assign(e.detail);
    const formattedIncomingNotification = {
      id: incomingNotification.notification_id,
      __html: formatMessageWithVars(incomingNotification.message, incomingNotification.message_vars),
      title: formatMessageWithVars(incomingNotification.title, incomingNotification.title_vars),
      pinned: incomingNotification.pinned,
      type: incomingNotification.style,
      height: 5 + (incomingNotification.message.split(`<br`).length - 1) * 1.875,
      link: incomingNotification.type == 3 ? `/markets/` + incomingNotification.message_vars.split(`,`)[1]  +  `/` + incomingNotification.message_vars.split(`,`)[0] : null,
      classes: ``,
      expire: incomingNotification.expire ? incomingNotification.expire : 9000,
    };
    if (
      formattedIncomingNotification.pinned || 
      formattedIncomingNotification.type === `info news` || 
      formattedIncomingNotification.type === `info newmarket` ) {
      formattedIncomingNotification.height += 4;
    }
    existingNotifications.unshift(formattedIncomingNotification);
    setNotifications(existingNotifications);
    if (existingNotifications.length > 5) {  //only allow 5 notifications on the screen at a given time
      const croppedNotifications = existingNotifications.slice(5, existingNotifications.length);
      setTimeout(() => croppedNotifications.forEach((n) => removeNotification(n.id)), 1);
    }
  };

  // // The below modals will pop on pageload, depending on conditions:
  // useEffect(() => {

  //   if (rehydrated) {
  //   // 1/2 if the user is pid=1, show them either the agreement (if not signed yet), 
  //     if (LSCXUserApplication.isEligible && platformId === 1 && !LSCXUserApplication.isAgreementSigned) {
  //       setAcceptTermsModalOpen(true);
  //       setLscxModalOpen(false);
  //     }
  //     // 2/2 or show them the PT modal, if the agreement is already signed.
  //     if (LSCXUserApplication.isEligible && platformId === 1 && LSCXUserApplication.isAgreementSigned && !LSCXUserApplication.isVerified) {
  //     //emitEvent(SHOW_LSCX_MODAL);
  //       setLscxModalOpen(true);
  //       setAcceptTermsModalOpen(false);
  //     }
    
  //     if (LSCXUserApplication.isVerified) {
  //       setLscxModalOpen(false);
  //     }

  //     if (LSCXUserApplication.isAgreementSigned) {
  //       setAcceptTermsModalOpen(false);
  //     }
  //   }

  // }, [LSCXUserApplication.isEligible, LSCXUserApplication.isAgreementSigned, LSCXUserApplication.isVerified, rehydrated]);
  // // end modal pageload section
  
  // const handleShowLscxModal = () => {
  //   if (!lscxModalOpen) {
  //     setLscxModalOpen(true);
  //   }
  // };

  const handleShowMOTDModal = () => {
    if (!MOTDModalOpen && platformId === 0) {
      setMOTDModalOpen(true);
      writeCookie(`cgy_motd`, true, EXPIRATION_MOTD);
    }
  };

  const handleShowBakktAccountModal = () => {
    // if (!bakktAccountModalOpen) {
    //   setBakktAccountModalOpen(true);
    // }
    setBakktAccountModalOpen(false);
  };

  const handleShowNewUserModal = () => {
    const isTrial = isCoinigyTrialUser(platformId, prefSubscriptionExpires, prefSubscriptionId, prefTrialUsed);
    const isPro = isCoinigyProUser(platformId, prefSubscriptionExpires, prefSubscriptionId, prefTrialUsed);

    setNewUserModalOpen(platformId === 0 && !newUserModalShown && !prefTrialUsed && !isTrial && !isPro);
  };

  useEffect(() => {
    const checkMOTDStatus = () => {
      const cgyMotd = readCookie(`cgy_motd`);
      if (!MOTDModalOpen && !cgyMotd) handleShowMOTDModal();
    };

    if (rehydrated && isOnline) {
      checkMOTDStatus();
    }

    const motdInterval = setInterval(() => {
      checkMOTDStatus();
    }, 600000);

    return () => {
      clearInterval(motdInterval);
    };
  }, [rehydrated, isOnline]);

  useEffect(() => {
    if (rehydrated && isOnline) {
      handleShowNewUserModal();
    }
  }, [rehydrated, isOnline, platformId, newUserModalShown, prefSubscriptionExpires, prefSubscriptionId, prefTrialUsed]);

  useEffect(() => {
    // used for the ActiveSubscribersModal, removed 11/1/2022
    //getPrefSubscriptionId();
    listenForEvent(SHOW_KEYBOARD_LEGEND, () => handleShowKeyboardLegend());
    // listenForEvent(SHOW_LSCX_MODAL, () => handleShowLscxModal());
    listenForEvent(SHOW_MOTD_MODAL, () => handleShowMOTDModal());
    listenForEvent(TOUR_COMPLETED, () => setIsTourOpen(false));
    listenForEvent(NOTIFICATION, (e: any) => handleNotifications(e));
    if (isMobile) {
      setTryMobileAppModalOpen(true);
    }
    listenForEvent(SHOW_REPORTBUG_MODAL, () => setReportBugModalOpen(true));
    listenForEvent(SHOW_KEYBOARD_MODAL, () => setKeyboardModalOpen(true));
    listenForEvent(START_TOUR, () => { setfA2ModalOpen(false); setIsTourOpen(true); });
    listenForEvent(SHOW_FAQS_MODAL, () => setFaqsModalOpen(true));
    listenForEvent(SHOW_BAKKT_ACCOUNT_MODAL, () => handleShowBakktAccountModal());
    return () => {
      removeEventListener(SHOW_KEYBOARD_LEGEND, () => handleShowKeyboardLegend());
      // removeEventListener(SHOW_LSCX_MODAL, () => handleShowLscxModal());
      removeEventListener(NOTIFICATION, (e: any) => handleNotifications(e));
      removeEventListener(TOUR_COMPLETED, () => setIsTourOpen(false));
      removeEventListener(SHOW_BAKKT_ACCOUNT_MODAL, () => handleShowBakktAccountModal());
    };
  }, []);

  // hide/show 2FA modal on page load
  useEffect(() => {
    const cgy2fa = readCookie(`cgy2fa`);
    const cgy2faExpired = !cgy2fa || (cgy2fa && Date.now() > parseInt(cgy2fa));
    if (rehydrated && !fA2Enabled && cgy2faExpired && isOnline) {
      
      let isTourCompleted = readCookie(`markets_tour_completed`);
      if (isTourCompleted) {
        
        let isfA2Dismissed = readCookie(`cgy2fa`);
        if (isfA2Dismissed) {
          setfA2ModalOpen(false);
        }else {
          setfA2ModalOpen(true);
        }
      }
    }
    if (rehydrated && fA2Enabled && isOnline) {
      setfA2ModalOpen(false);
    }
  }, [fA2Enabled, rehydrated, isOnline]);

  const handleClose2FAModal = () => {
    setfA2ModalOpen(false);
    writeCookie(`cgy2fa`, true, EXPIRATION_2FA);
  };

  const handleVisibilityChange = (isVisible: boolean) => setIsVisible(isVisible);

  const removeNotification = (id: number) => {
    emitEvent(DISMISS_NOTIFICATION, {
      notification_id: id,
    });
    setNotifications(notifications_ref.current.filter((n) => n.id !== id));
  };

  const removeAllNotifications = () => setNotifications([]);

  const handleNotificationController = (v: string) => {
    switch (v) {
    case `clear_all`:
      removeAllNotifications();
      break;
    case `display_recent`:
      getNotifications(5);
      break;
    case `display_last`:
      getNotifications(1);
      break;
    case `close_backdrop`:
      break;
    }
    setNotificationMenuModalOpen(false);
  };

  const handleSelectLanguageController = (l: string) => {
    if (l !== `close_backdrop`) updateLanguage(l);
    setSelectLanguageModalOpen(false);
  };

  const delayLoop = (fn, delay: number) => {
    return (x, i) => {
      setTimeout(() => {
        fn(x);
      }, i * delay);
    };
  };

  const getNotifications = (Limit: number) => {
    getActivity({ params: { "PageSize": Limit } }, (data) => {
      if (data.success) {
        data.result
          .filter(({ notificationId }) => notifications.every(({ id }) => (id !== notificationId)))
          .forEach(
            delayLoop(
              (n) => emitEvent(NOTIFICATION, {
                message: n.message,
                message_vars: n.messageVars,
                notification_id: n.notificationId,
                pinned: n.pinned,
                style: n.style,
                time: n.time,
                title: n.title,
                title_vars: n.titleVars,
                type: n.type
              })
              , 300)
          );
      }
    });
  };

  const reportBug = () => {
    bugReport({
      type: reportBugType,
      url: window.location.href,
      message: reportBugText
    }, (data) => {
      if (data.success) {
        setReportBugModalOpen(false);
        setReportBugText(``);
        setReportBugType(`missingData`);
        emitEvent(NOTIFICATION, {
          notification_id: new Date().getTime(),
          title: `Bug Report`,
          title_vars: ``,
          message_raw: `Thank you - your message has been received. If this issue requires a followup, please open a new support ticket at https://support.coinigy.com`,
          message: `Thank you - your message has been received. If this issue requires a followup, please open a new support ticket at https://support.coinigy.com`,
          message_vars: ``,
          pinned: true,
          style: `info`,
          url: ``
        });
      } else {
        emitEvent(NOTIFICATION, {
          notification_id: new Date().getTime(),
          title: `Bug Report`,
          title_vars: ``,
          message_raw: `There was an error while submitting your request. Please double check the fields and try again. If this issue persists, contact support@coinigy.com.`,
          message: `There was an error while submitting your request. Please double check the fields and try again. If this issue persists, contact support@coinigy.com.`,
          message_vars: ``,
          pinned: true,
          style: `error`,
          url: ``
        });
      }
    });
  };

  const validateBugReport = (body: string) => {
    if (reportBugText.length < 1001) {
      setReportBugText(body.substring(0, 1000));
    }
  };
  const dismissTryMobileModal = () => setTryMobileAppModalOpen(false);
  const platformCopy = platformId === 0 ? t(`coinigy`) : t(`lightspeed`);

  return (
    <footer className="fade-in">
      <PageVisibility onChange={ (e) => handleVisibilityChange(e) } />
      <NotificationTray 
        notifications={ notifications }
        removeNotification={ removeNotification }/>
      <div className="copyright">
        <span>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://insights.coinigy.com/tag/changelog/">
            { process.env.GIT_TAG }
          </a>
          { ` • ` }
            © 
          { ` ` }
          { (new Date()).getFullYear() }
          { ` ` }
          { platformCopy }
          { ` ` }
          <a target="_blank" rel="noopener noreferrer" href={ `${ window.WWW_URL }/terms` }>
            { t(`terms`) }
          </a>
          { ` • ` }
          <a target="_blank" rel="noopener noreferrer" href={ `${ window.WWW_URL }/privacy` }>
            { t(`privacy`) }
          </a>
          { ` • ` }
          <a target="_blank" rel="noopener noreferrer" href={ `${ window.WWW_URL }/security` }>
            { t(`security`) }
          </a>
        </span>
      </div>
      {
        isVisible && !isTickerPositionHeader && <Ticker t={ t } markets={ markets }/>
      }
      <div className="fns">
        <ul>
          <Popover
            isOpen={ openPopover == `bug` }
            positions={ [`top`] }
            content={ ({ position, childRect, popoverRect }) => (
              <ArrowContainer 
                position={ position }
                childRect={ childRect }
                popoverRect={ popoverRect }
                arrowColor={ `var(--gray-1)` }>
                <div className={ `popover-content` }>
                  { `Report a bug` }
                </div>
              </ArrowContainer>
            ) }>
            <li
              onMouseEnter={ () => setOpenPopover(`bug`) }
              onMouseLeave={ () => setOpenPopover(``) }>
              <a
                data-testid={ `open-report-bug-modal` }
                onClick={ () => setReportBugModalOpen(true) }>
                { Bug }
              </a>
            </li>
          </Popover>
          <Popover
            isOpen={ openPopover == `notifications` }
            positions={ [`top`] }
            content={ ({ position, childRect, popoverRect }) => (
              <ArrowContainer 
                position={ position }
                childRect={ childRect }
                popoverRect={ popoverRect }
                arrowColor={ `var(--gray-1)` }>
                <div className={ `popover-content` }>
                  { `View notifications` }
                </div>
              </ArrowContainer>
            ) }>
            <li
              onMouseEnter={ () => setOpenPopover(`notifications`) }
              onMouseLeave={ () => setOpenPopover(``) }
              onClick={ () => setNotificationMenuModalOpen(!notificationMenuModalOpen) }>
              { AlertBell }
            </li>
          </Popover>
          <Popover
            isOpen={ openPopover == `language` }
            positions={ [`top`] }
            content={ ({ position, childRect, popoverRect }) => (
              <ArrowContainer 
                position={ position }
                childRect={ childRect }
                popoverRect={ popoverRect }
                arrowColor={ `var(--gray-1)` }>
                <div className={ `popover-content` }>
                  { `Change language` }
                </div>
              </ArrowContainer>
            ) }>
            <li
              data-testid="footer-language-menu-popover"
              onMouseEnter={ () => setOpenPopover(`language`) }
              onMouseLeave={ () => setOpenPopover(``) }
              onClick={ () => setSelectLanguageModalOpen(!selectLanguageModalOpen) }>
              { Globe }
            </li>
          </Popover>
          <Popover
            isOpen={ openPopover == `status` }
            positions={ [`top`] }
            content={ ({ position, childRect, popoverRect }) => (
              <ArrowContainer 
                position={ position }
                childRect={ childRect }
                popoverRect={ popoverRect }
                arrowColor={ `var(--gray-1)` }>
                <div className={ `popover-content` }>
                  { `System status` }
                </div>
              </ArrowContainer>
            ) }>
            <li
              onMouseEnter={ () => setOpenPopover(`status`) }
              onMouseLeave={ () => setOpenPopover(``) }>
              <i className={ `server-status ${ isOnline ? `online` : `offline` }` } />
            </li>
          </Popover>
        </ul>
      </div>

      {
        keyboardModalOpen && (
          <KeyboardShortcutModal
            t={ t }
            close={ () => setKeyboardModalOpen(false) } />
        )
      }
      {
        reportBugModalOpen && (
          <Modal
            title={ `Report a bug` }
            onClose={ () => setReportBugModalOpen(false) }
            confirmText={ `I Understand, Submit Bug Report` }
            onConfirm={ () => reportBug() }
            confirmDisabled={ reportBugText.length < 3 } >
            <div className="report-bug-container" data-testid={ `report-bug-container` }>
              <div>
                <SelectField
                  className="reportBugSelectField"
                  label={ `Type` }
                  name="bugType"
                  value={ reportBugType }
                  onChange={ (e, v) => setReportBugType(v) }
                  options={ [
                    {
                      label: `Missing Data`,
                      value: `missingData`
                    },
                    {
                      label: `Incorrect Data`,
                      value: `incorrectData`
                    },
                    {
                      label: `Other`,
                      value: `other`
                    }
                  ] } />
              </div>
              <div>
                <textarea 
                  name="report-bug-textarea"
                  className="report-bug-textarea"
                  placeholder="Please leave a detailed description of your bug report here."
                  value={ reportBugText }
                  onChange={ (e) => {
                    validateBugReport(e.target.value);
                  } }/>
                <br />
                <div>
                  { 1000 - reportBugText.length }
                  { ` ` }
characters remaining.
                </div>
              </div>
              <div className="report-bug-disclaimer">
For bug reporting only; we will not respond. If you need help, please visit
                { ` ` }
                <a href="https://support.coinigy.com" target="_blank" rel="noopener noreferrer">
Coinigy Support
                </a>
              </div>
            </div>
          </Modal>
        )
      }
      {
        tryMobileAppModalOpen && isMobile && (
          <TryMobileAppModal
            onClose={ () => dismissTryMobileModal() }>
            <img src={ `/assets/img/figma/Logo.svg` } />
            <span>
              <b>
Install the Coinigy app
              </b>
              { ` ` }
and get the best experience on your device.
            </span>
            { isIOS && (<a
              href="https://apps.apple.com/us/app/coinigy/id1317482120" 
              rel="nofollow noopener noreferrer" 
              target="_blank ">
              <img src={ `/assets/img/figma/App_Store_Badge_US-UK.svg` } />
            </a>) }
            { isAndroid && (<a
              href="https://play.google.com/store/apps/details?id=com.coinigy" 
              rel="nofollow noopener noreferrer" 
              target="_blank ">
              <img src={ `/assets/img/figma/Play_Store_Badge_EN.svg` } />
            </a>) }
            { !isIOS && !isAndroid && (
              <>
                <a
                  href="https://apps.apple.com/us/app/coinigy/id1317482120" 
                  rel="nofollow noopener noreferrer" 
                  target="_blank ">
                  <img src={ `/assets/img/figma/App_Store_Badge_US-UK.svg` } />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.coinigy" 
                  rel="nofollow noopener noreferrer" 
                  target="_blank ">
                  <img src={ `/assets/img/figma/Play_Store_Badge_EN.svg` } />
                </a>
              </>
            ) }
          </TryMobileAppModal>
        )
      }
      {
        notificationMenuModalOpen && (
          <UndockedSelectField
            handleClick={ (v) => handleNotificationController(v) } 
            options={ [
              {
                label: t(`clearAll`),
                value: `clear_all`
              },
              {
                label: t(`displayRecent`),
                value: `display_recent`
              },
              {
                label: t(`displayLast`),
                value: `display_last`
              }
            ] }/>
        )
      }
      {
        selectLanguageModalOpen && (
          <UndockedSelectField
            handleClick={ (l) => handleSelectLanguageController(l) } 
            defaultValue={ language } 
            options={ languages.map((l: any) => (
              {
                label: `${getFlagEmoji(l.code)} ${l.subtitle}`,
                value: l.value
              }
            )) }/>
        )
      }
      { /* { 
        lscxModalOpen && LSCXUserApplication.isEligible && !isTourOpen && (
          <PrimeTrustAccountModal theme={ theme } user={ user } onClose={ () => setLscxModalOpen(false) } /> 
        )
      } */ }
      { /* {
        acceptTermsModalOpen && !isTourOpen &&  (
          <PrimeTrustAcceptTermsModal t={ t } onClose={ () => setAcceptTermsModalOpen(false) } /> 
        )
      } */ }
      { fA2ModalOpen && /* !acceptTermsModalOpen && !lscxModalOpen &&  */  !isTourOpen && !newUserModalOpen && (
        <EnableTwoFactorAuthModal
          t={ t }
          onClose={ handleClose2FAModal } /> //activateTwoFactorAuth }/> }
      )
      }
      { /* { platformId === 1 && LSCXUserApplication.isEligible && LSCXUserApplication.isVerified && LSCXUserApplication.isAgreementSigned && !fA2ModalOpen && !acceptTermsModalOpen && !lscxModalOpen && !isTourOpen && (
        <WireFundsModal t={ t } accounts={ accounts } exchanges={ exchanges } /> 
      )
      } */ }
      { /* 
      // ActiveSubscribersModal was previously popped to eligible users but was confusing to Coinigy users so it was disabled 11/1/2022
      { platformId == 0 && !LSCXUserApplication.isVerified && !fA2ModalOpen && !acceptTermsModalOpen && !lscxModalOpen && !isTourOpen && (
        
        //Allow active Coinigy subscribers to become LSCX Eligible
        <ActiveSubscribersModal 
          { ...{ userPrefs } }
          { ...{ platformId } }
          { ...{ subscriptionInfo } } />
      )
      } */ }
      { /* { !fA2ModalOpen && !acceptTermsModalOpen && !lscxModalOpen && !isTourOpen && (
        <ExpiredAccessModal
          { ...{ user } }
          isEligible={ LSCXUserApplication.isEligible }
          isVerified={ LSCXUserApplication.isVerified } />
      )
      } */ }

      { faqsModalOpen && !newUserModalOpen &&
        <FaqsModal  
          { ...{ t } }  
          { ...{ setFaqsModalOpen } } /> 
      }

      { MOTDModalOpen && !isTourOpen && !newUserModalOpen && !fA2ModalOpen &&
        <MOTDModal { ...{ theme } } { ...{ setMOTDModalOpen } } />
      }

      { bakktAccountModalOpen && CGYUserApplication.isEligible && !isTourOpen && !fA2ModalOpen &&  <BakktAccountModal onClose={ () => setBakktAccountModalOpen(false) }/> }

      { /* { newUserModalOpen && !isTourOpen && <NewUserModal { ...{ t } }  /> } */ }

    </footer>
  );
  
};

const mapStateToProps = (state) => ({
  isOnline: state.browser.online,
  theme: state.redisPrefs.theme,
  language: state.redisPrefs.language,
  platformId: state.userInfo.user.platformId,
  fA2Enabled: state.userInfo.user.fA2Enabled,
  rehydrated: state._persist.rehydrated,
  // used for the ActiveSubscribersModal, removed 11/1/2022
  //userPrefs: state.userInfo.userPrefs,
  userApplications: state.userInfo.userApplications,
  applicationsStatuses: state.userInfo.applicationsStatuses,
  tickerPosition: state.redisPrefs.tickerPosition,
  prefSubscriptionExpires: state.userInfo?.userPrefs?.prefSubscriptionExpires,
  prefSubscriptionId: state.userInfo?.userPrefs?.prefSubscriptionId,
  prefTrialUsed: state.userInfo?.userPrefs?.prefTrialUsed,
  newUserModalShown: state.app.newUserModalShown,
});

const mapDispatchToProps = (dispatch) => ({
  updateLanguage: (language) => dispatch(updateRedisPrefs({ key: `language`, value: language })),
});

export { Footer as PureFooter };
export default translate(`footer`)(connect(mapStateToProps, mapDispatchToProps)(Footer));

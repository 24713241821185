// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import {
  toFixedDecimalsHTML, 
  toFixedDecimals, 
  getSpreadFromBidAndAsk,
  optimizeTextForWidth
} from '../../helpers/NumberHelper.js';
import { 
  listenForEvent,
  emitEvent, 
  removeEventListener, 
  MARKETS_PRICE_HOVER, 
  MARKETS_PRICE_CLICK,
  WINDOW_RESIZED,
  THEME_CHANGED
} from '../../helpers/EventHelper.js';
import type { MarketOrder } from '../../types/MarketOrder.js';
import type { Market } from '../../types/Market.js';
import type { Order } from '../../types/Order.js';
import { getActiveTheme } from '../../helpers/ThemeHelper.js';
import SvgBgColor from '../../svgs/SvgBgColor.jsx';

type Props = {
  t: any,
  market: Market,
  buys: Array<MarketOrder>,
  sells: Array<MarketOrder>,
  zeroStyle: string,
  depthStyle: string,
  theme: string,
  openOrders: Array<Order>,
};

type State = {
  hoveredPriceIndex: number,
  hoveredPriceType: string,
  width: number
};

const SIDE_BUY = 1, SIDE_SELL = 2;

class MarketVerticalDepthChart extends React.Component<Props, State> {
  priceHoverHandler: (e: any) => void;
  priceClickProxyHandler: (e: any) => void;
  windowResizedHandler: (e: any) => void;

  constructor(props: Props) {
    super(props);

    this.priceHoverHandler = this.handlePriceHoverEvent.bind(this);
    this.priceClickProxyHandler = this.handleMarketPriceClickProxy.bind(this);
    this.windowResizedHandler = this.handleWindowResizedEvent.bind(this);

    this.state = {
      hoveredPriceIndex: -1,
      hoveredPriceType: ``,
      width: 300
    };
  }

  componentDidMount() {
    listenForEvent(MARKETS_PRICE_HOVER, this.priceHoverHandler);
    listenForEvent(WINDOW_RESIZED, this.windowResizedHandler);
    listenForEvent(THEME_CHANGED, this.windowResizedHandler);
  }

  componentWillUnmount() {
    removeEventListener(MARKETS_PRICE_HOVER, this.priceHoverHandler);
    removeEventListener(WINDOW_RESIZED, this.windowResizedHandler);
    removeEventListener(THEME_CHANGED, this.windowResizedHandler);
  }

  componentWillReceiveProps() {
    //console.log(this.props.sells, this.props.buys);
  }

  handleMarketPriceClickProxy(e: any) {
    emitEvent(MARKETS_PRICE_CLICK, {
      price: (
        e.detail.priceType == `buy` ? this.props.buys[e.detail.priceIndex].price :
          e.detail.priceType == `sell` ? this.props.sells[e.detail.priceIndex].price :
            0
      )
    });
  }

  onClick(price: number) {
    emitEvent(MARKETS_PRICE_CLICK, {
      price
    });
  }

  onMouseOverPrice(hoveredListPriceIndex: number, priceType: string) {
    emitEvent(MARKETS_PRICE_HOVER, {
      hoveredListPriceIndex,
      hoveredGraphPriceIndex: 19 - hoveredListPriceIndex,
      priceType
    });
  }

  onMouseOut() {
    emitEvent(MARKETS_PRICE_HOVER, {
      hoveredListPriceIndex: -1,
      hoveredGraphPriceIndex: -1,
      priceType: ``
    });
  }

  handlePriceHoverEvent(e: any) {
    this.setState({
      hoveredPriceIndex: e.detail.hoveredListPriceIndex,
      hoveredPriceType: e.detail.priceType
    });
  }

  handleWindowResizedEvent() {
    if(this.refs.marketVerticalDepthChart) this.setState({ width: this.refs.marketVerticalDepthChart.clientWidth });
  }

  render() {
    const { buys, sells, zeroStyle, theme, openOrders } = this.props;
    const haveSells = sells && sells.length > 0;
    const haveBuys = buys && buys.length > 0;
    const activeTheme = getActiveTheme(theme, true);
    const chartDataBuys = buys.sort((a, b) => {
      if (a.price < b.price) return 1;
      if (b.price < a.price) return -1;
      if (a.total < b.total) return 1;
      if (b.total < a.total) return -1;
      return 0;
    }).reduce((v: any, buy: MarketOrder, i: number) => {
      return [[buys.length - i, buy.quantity + (v.length > 0 ? v[0][1] : 0)]].concat(v);
    }, []).sort((a, b) => {
      if (a[0] > b[0]) return 1;
      if (b[0] > a[0]) return -1;
      return 0;
    });

    const chartDataSells = sells.sort((a, b) => {
      if (a.price > b.price) return 1;
      if (b.price > a.price) return -1;
      if (a.total > b.total) return 1;
      if (b.total > a.total) return -1;
      return 0;
    }).reduce((v: any, sell: MarketOrder, i: number) => {
      return v.concat([[i, sell.quantity + (v.length > 0 ? v[v.length - 1][1] : 0)]]);
    }, []);

    const isOpenOrder = (side: number, { price, quantity }: any) => {
      return openOrders?.some((o) => o.side === side && o.limitPrice === price && o.quantity === quantity);
    };

    return (
      <div
        className="market-vertical-depth-chart"
        ref="marketVerticalDepthChart"
        onMouseLeave={ () => this.onMouseOut() }>
        <div className="header">
          <span>
            { this.props.t(`app:asks`) }
          </span>
          <span>
            { this.props.t(`app:price`) }
          </span>
          <span>
            { this.props.t(`app:qty`).toUpperCase() }
          </span>
          <span>
            { this.props.t(`app:total`) }
          </span>
        </div>
        { haveSells ?
          sells.reverse().map((s, i) => {
            let barWidth = Math.floor((parseFloat(chartDataSells[chartDataSells.length - 1 - i][1]) /
                                     parseFloat(chartDataSells[chartDataSells.length-1][1])) * this.state.width);
            return(
              <div
                id="rowSellEvent"
                style={ {
                  backgroundImage: `${SvgBgColor(activeTheme[`--red--alpha-50`],
                    `100%`, `${barWidth}px`, `${this.state.width-barWidth}px` )}`
                } }
                key={ `ask-${ i }` }
                className={
                  `row sell ${
                    i == this.state.hoveredPriceIndex && this.state.hoveredPriceType == `sell` ?
                      `active` :
                      this.state.hoveredPriceIndex > -1 ?
                        `inactive` :
                        ``
                  } ${ isOpenOrder(SIDE_SELL, s) ? `open-order`: `` }`
                }
                onMouseOver={ () => this.onMouseOverPrice(i, `sell`) }
                onClick={ () => this.onClick(s.price) }>
                <span
                  className={ zeroStyle }
                  dangerouslySetInnerHTML={ 
                    toFixedDecimalsHTML(s.price, false, `price`, this.props.market, zeroStyle === `none`)
                  }/>
                <span
                  className={ zeroStyle }
                  dangerouslySetInnerHTML={
                    toFixedDecimalsHTML(s.quantity, true, `quantity`, this.props.market, zeroStyle === `none`)
                  } />
                <span
                  className={ zeroStyle }
                  dangerouslySetInnerHTML={
                    toFixedDecimalsHTML(
                      parseFloat(chartDataSells[chartDataSells.length-i-1][1]),
                      true, `total`, this.props.market, zeroStyle === `none`)
                  } />
              </div>
            );}) : null
        }
        <div className="info">
          <span className="line-center" />
          <span>
            {
              optimizeTextForWidth(
                toFixedDecimals(haveSells ? sells[sells.length - 1].price : ``, false, `price`, this.props.market) 
                + `   ` + 
                getSpreadFromBidAndAsk(haveBuys ? buys[0].price : 0, haveSells ? sells[sells.length - 1].price : 0) 
                + `   ` + 
                toFixedDecimals(haveBuys ? buys[0].price : ``, false, `price`, this.props.market), 
                16.5,
                false,
                false,
                (
                  <span>
                    { haveBuys && (
                      <>
                        <span className="label-color-buys">
                          { toFixedDecimals(buys[0].price, false, `price`, this.props.market) }
                        </span>
                          &nbsp;&nbsp;&nbsp;
                      </>
                    )
                    }
                    { haveSells && haveBuys && (
                      <>
                        <span className="label-color-spread">
                          { getSpreadFromBidAndAsk(buys[0].price, sells[sells.length - 1].price) }
                        </span>
                        &nbsp;&nbsp;&nbsp;
                      </>
                    )
                    }
                    { haveSells && (
                      <>
                        <span className="label-color-sells">
                          { toFixedDecimals(sells[sells.length - 1].price, false, `price`, this.props.market) }
                        </span>
                      </>
                    )
                    }
                  </span>
                )
              )
            }
          </span>
        </div>
        { 
          haveBuys ? 
            buys.map((b, i) => {
              const barWidth = Math.floor((parseFloat(chartDataBuys[chartDataBuys.length - 1 - i][1]) / parseFloat(chartDataBuys[0][1])) * this.state.width);
              return (
                <div
                  style={ {
                    backgroundImage: `${SvgBgColor(activeTheme[`--green--alpha-50`],
                      `100%`, `${barWidth}px`, `${this.state.width-barWidth}px` )}`
                  } }
                  id="rowBuyEvent"
                  key={ `bid-${ i }` }
                  className={
                    `row buy ${
                      i == this.state.hoveredPriceIndex && this.state.hoveredPriceType == `buy` ?
                        `active` :
                        this.state.hoveredPriceIndex > -1 ?
                          `inactive` :
                          `` 
                    } ${ isOpenOrder(SIDE_BUY, b) ? `open-order`: `` }` 
                  }
                  onMouseOver={ () => this.onMouseOverPrice(i, `buy`) }
                  onClick={ () => this.onClick(b.price) }>
                  <span
                    className={ zeroStyle }
                    dangerouslySetInnerHTML={
                      toFixedDecimalsHTML(b.price, false, `price`, this.props.market, zeroStyle === `none`)
                    }/>
                  <span
                    className={ zeroStyle }
                    dangerouslySetInnerHTML={ 
                      toFixedDecimalsHTML(b.quantity, true, `quantity`, this.props.market, zeroStyle === `none`)
                    }/>
                  <span
                    className={ zeroStyle }
                    dangerouslySetInnerHTML={
                      toFixedDecimalsHTML(
                        parseFloat(chartDataBuys[chartDataBuys.length-i-1][1]),
                        true, `total`, this.props.market, zeroStyle === `none`)
                    } />
                </div>
              );}) : null
        }
        <div className="footer">
          <span>
            { this.props.t(`app:bids`) }
          </span>
          <span>
            { this.props.t(`app:price`) }
          </span>
          <span>
            { this.props.t(`app:qty`).toUpperCase() }
          </span>
          <span>
            { this.props.t(`app:total`) }
          </span>
        </div>
      </div>
    );
  }
}

export { MarketVerticalDepthChart as PureMarketVerticalDepthChart };
export default translate(`markets`)(MarketVerticalDepthChart);

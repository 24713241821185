// @flow
'use strict';

import React from 'react';
import moment from 'moment';
import { translate } from 'react-i18next';
import Modal from '../utilities/Modal.jsx';

type Props = {
  t: any,
  label: string,
  event: any,
  currCode: string,
  close: () => void
};

type State = {
    
  }

class MarketEventModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal
        title={ this.props.t(`app:eventDetails`) }
        onClose={ this.props.close }>
        <div className="event-modal ltr fade-in">
          <div className="market-events row-reverse">
            <a
              href={ this.props.event.source }
              target="_blank"
              rel="noopener noreferrer">
              <div className="events-item modal-height">
                <div className="event-details">
                  <div className="event-currency">
                    <img
                      className="event-currency-img" src={ `https://www.coinigy.com/assets/img/currency/${this.props.currCode}.png` }/>
                  </div>
                  <div className="event-categories">
                    {
                      this.props.event.categories && this.props.event.categories.map((c) => (
                        <div key={ `category-` + c.id }>
                          { c.name }
                        </div>
                      ))
                    }
                  </div>
                  <div className="event-title-lg">
                    {
                      this.props.event.title.en + ` - `
                    }
                    { 
                      (+(new Date()) - +(new Date(this.props.event.pubDate))) <= 1000 * 60 * 60 ? 
                        moment(this.props.event.date_event).fromNow() :
                        moment(this.props.event.date_event).format(`MMMM Do YYYY`)
                    }
                  </div>
                  <div className="event-description">
                    {
                      this.props.event.description.en
                    }
                  </div>
                  <div className="event-proof">
                    <img className="fade-in" src={ this.props.event.proof } />
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </Modal>
    );
  }
}

export { MarketEventModal as PureMarketEventModal };
export default translate(`markets`)(MarketEventModal);

// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import Modal from '../utilities/Modal.jsx';

type Props = {
  t: any,
  title: string,
  confirm: (authKey: string) => void,
  close: () => void,
  authKey: string
};

const ApiDeleteModal = function (props: Props) {
  return (
    <Modal
      title={ props.title }
      onConfirm={ () => props.confirm(props.authKey) }
      onClose={ props.close }
      confirmText={ props.t(`app:confirm`) }
      cancelText={ props.t(`app:cancel`) }
      confirmDisabled={ false } >
      <>
        <p>
          { props.t(`user:deleteAccountConfirm1`) + props.authKey + props.t(`user:deleteKeyConfirm2`) }
        </p>
        <p>
          { props.t(`user:deleteAccountConfirm3`) }
        </p>
      </>
    </Modal>
  );
};

export { ApiDeleteModal as PureApiDeleteModal };
export default translate(`users`)(ApiDeleteModal);

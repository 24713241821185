'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path class="arrow-up" d="M0 5l5-5 5 5z"/>`,
  {
    width: `1rem`,
    height: `0.5rem`,
    viewBox: `0 0 10 5`
  }
);
/* eslint-enable */

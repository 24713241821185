import React, { useEffect, useState } from 'react';
import Form from '@rjsf/mui';
import validator from './CustomValidator.jsx';
import { JSON_SCHEMA_STEPS, AGREEMENT } from './constants';
import Button from '../../utilities/Button.jsx';
import LogoMark from '../../../svgs/LogoMark.jsx';
import Wallet from '../../../svgs/Wallet.jsx';
import CoinigyLogo from '../../../svgs/CoinigyLogo.jsx';
import { 
  emitEvent, 
  NOTIFICATION
} from '../../../helpers/EventHelper.js';
import { postCoinigyAccount } from '../../../helpers/api/UserApi.js';
import Hint from '../../utilities/Hint.jsx';
import Checkbox from '../../utilities/Checkbox.jsx';

const BakktAccountForm = (props) => {
  const { callback } = props;
  const [step, setStep] = useState(0);
  const [currentSchema, setCurrentSchema] = useState(null);
  const [formData, setFormData] = useState([]);
  const [agree, setAgree] = useState(false);

  const buildFormData = () => formData.reduce((accum, fdata) => {
    const groups = fdata?.propertyGroup?.split(`|`);
    if (groups?.length) {
      let subAccum = accum;
      groups.forEach((group, idx) => {
        if (!subAccum.hasOwnProperty(group)) {
          subAccum[group] = {};
        }
        if (idx === groups.length -1) {
          subAccum[group] = {
            ...subAccum[group],
            ...fdata.data
          };
        } else {
          subAccum = subAccum[group];
        }
      });
      return accum;
    } else {
      return {
        ...accum, 
        ...fdata.data
      };
    }
  }, {});

  const onSubmit = (data) => {
    setFormData((prev) => {
      prev[step] = {
        step,
        data: data.formData,
        propertyGroup: data.schema?.propertyGroup
      };
      return prev;
    });
    
    if (step < JSON_SCHEMA_STEPS.length) { 
      setStep((prev) => prev + 1);
    } else {
      const buildData = buildFormData();

      //Adds agreement to Owners
      if (agree && buildData.owners) {
        buildData.owners.map((o) => (
          o.agreements = [{
            name: AGREEMENT.name,
            version: AGREEMENT.version,
            text: AGREEMENT.text,
            eSignedDate: new Date().toISOString()
          }]
        ));

        postCoinigyAccount(buildData).then((data) => {
          if (data.success) {
            callback();
          } else {       
            let error;
            try {
              error = JSON.parse(data.error)?.message;
            } catch (e) {
              error = data.error;
            } finally {
              emitEvent(NOTIFICATION, {
                notification_id: new Date().getTime(),
                title: `Error`,
                title_vars: ``,
                message_raw: error,
                message: error,
                message_vars: ``,
                pinned: false,
                style: `error`,
                url: ``
              });
            }
          }
        });
      }
    }
  };

  const uiSchema = {
    "ui:submitButtonOptions": {
      "submitText": currentSchema?.submitText || `Next >>`
    }
  };

  // const customValidate = (formData, errors) => {
  //   formData?.owners?.forEach((f, idx) => {
  //     const err = errors.owners[idx].identity.verificationResults;
      
  //     if(f.identity.verificationResults.addressVerified === false) err.addressVerified.addError(`Address Verified is required`);

  //     if(f.identity.verificationResults.dateOfBirthVerified === false) err.dateOfBirthVerified.addError(`Date Of Birth Verified is required`);

  //     if(f.identity.verificationResults.nameVerified === false) err.nameVerified.addError(`Name Verified is required`);

  //     if(f.identity.verificationResults.taxIdVerified === false) err.taxIdVerified.addError(`Tax Id Verified is required`);
  //   });

  //   return errors;
  // };
  

  const transformErrors = (errors) => {
    const getPropertyTitle = (prop) => getTitle(prop.indexOf(`.`) > -1 ? prop.split(`.`).at(-1) : prop);

    return errors.map((error) => {
      if (error.name === `items` || error.name === `properties`) return { property: null };

      if (error?.property?.endsWith?.(`.`)) error.property = error.property.substr(0, error.property.length - 1);
      
      /*if ([`verificationResults.dateOfBirthVerified`,`verificationResults.nameVerified`, `verificationResults.addressVerified`, `verificationResults.taxIdVerified`, `verificationResults.manualOverride`]
        .some((p) => error.property.indexOf(p) > -1)) {
        error.message = `${getPropertyTitle(error.property)} is required`;
      } else */ 
      if (error.name === `required`) {
        error.message = `${getPropertyTitle(error.property)} is required`;
      } else if (error.name === `minItems`) {
        error.message = `${getPropertyTitle(error.property)} must NOT have fewer than 1 items`;
      } else if (error.property.indexOf(`streetAddress`) > -1) {
        error.message = `Street Address is required`;
      } else if (error.name === `pattern` && error.property.indexOf(`emailAddress`) > -1) {
        error.message = `Invalid email address`;
      }
      return error;
    });
  };
  
  const DescriptionFieldTemplate = (props) => {
    const { description, id } = props;
    return (
      <span id={ id } className='custom-description-field'>
        { description && 
          <Hint
            position="right" 
            content={ <div style={ { width: `40rem`, textAlign: `left` } }>
              { description.split(`</br>`).map((d, idx) => <div key={ idx }>
                { d }
              </div>) }
            </div> }>
            <img src={ `/assets/img/figma/Question.svg` } width="12" height="12" />
          </Hint>
        }
      </span>
    );
  };

  const getTitle = (key) => key.replace(/([a-z])([A-Z])/g, `$1 $2`);

  const parseSchema = (schema) => {
    return Object.entries(schema.properties).reduce((accum, prop) => {
      if (!prop[1].title) prop[1].title = getTitle(prop[0]);

      if (prop[1].hasOwnProperty(`properties`)) {
        prop[1].properties = parseSchema(prop[1]);
      }

      if (prop[1].hasOwnProperty(`items`) && prop[1].items.hasOwnProperty(`properties`)) {
        prop[1].items.properties = parseSchema(prop[1].items);
      }

      return {
        ...accum,
        [prop[0]]: {
          ...prop[1]
        }
      };
    }, {});
  };

  useEffect(() => {
    if (step < JSON_SCHEMA_STEPS.length) {
      setCurrentSchema({
        ...JSON_SCHEMA_STEPS[step],
        properties: parseSchema(JSON_SCHEMA_STEPS[step])
      });
    }
    setAgree(false);
  }, [step]);

  const agreementTemplate = () => {
    return (
      <div className='agreement-container'>
        <h2 className='title'>
          { `Agreement` }
        </h2>
        <h3 className='subtitle'>
          { `${AGREEMENT.name} - ${AGREEMENT.version}` }
        </h3>
        <p className='body'>
          { AGREEMENT.text }
        </p>
        <div style={ { margin: `4rem 0` } }>
          <Checkbox 
            label={ `I agree` } 
            name="agree" 
            onChange={ () => setAgree((prev) => !prev) } value={ agree } />
          <p>
            { `I agree that by signing this agreement it applies to any additional owners of the account.` }
          </p>
        </div>
        <Button 
          type={ `primary` } 
          onClick={ onSubmit } 
          disabled={ !agree } 
          className='submit'>
          { `Submit` }
        </Button>
      </div>
    );
  };


  return (
    <div className="bakkt-account">
      <div className='flex'>
        { step > 0 && 
          <div className='back-button-container'>
            <Button type={ `secondary` } onClick={ () => setStep((prev) => prev - 1) }>
              { `<< Back` }
            </Button>
          </div>
        }

        { step === 0 ?
          <div className='header-first-step'>
            { CoinigyLogo }
            <h2 className='title'>
              { `Create Your Wallet & Activate Trading` }
            </h2>
            <p className='subtitle'>
              { `Just a few more steps and you're good to go` }
            </p>
            <h3 className='json-header-text'>
              { currentSchema?.headerText }
            </h3>
          </div>
          :
          <div>
            <div className='coinigy-icon'>
              { LogoMark[0] }
            </div>
            { currentSchema && step < JSON_SCHEMA_STEPS.length &&
            <h3 style={ { fontSize: `3rem`, textAlign: `center` } }>
              { currentSchema?.headerText }
            </h3>
            }
          </div>
        }
      </div>

      { currentSchema && step < JSON_SCHEMA_STEPS.length &&
        <Form
          className="bakkt-account-form"
          idPrefix="bakkt-account"
          onSubmit={ onSubmit }
          schema={ currentSchema }
          uiSchema={ uiSchema }
          templates={ { DescriptionFieldTemplate } }
          formData={ formData[step]?.data }
          validator={ validator }
          showErrorList={ false } 
          noHtml5Validate
          transformErrors={ transformErrors }/>
      }

      { JSON_SCHEMA_STEPS.length === step && agreementTemplate() }

      { step === 0 && 
        <div className='footer-first-step'>
          <div className='flex' style={ { gap: `1.5rem`, margin: `2rem 0` } }>
            { Wallet }
            <span>
              { `Create your Coinigy wallet now to take advantage of low trading fees, high liquidity and industry-leading custody technology provided by Bakkt. Learn More` }
            </span>
          </div>
          <div style={ { textAlign: `center` } }>
            <span style={ { fontSize: `1.5rem` } }>
              <img src={ `${ window.WWW_URL }/assets/img/backed-by-bakkt.png` } height="32"/>
            </span>
          </div>
        </div>
      }
    </div>
  );
};

export default BakktAccountForm;

// @flow
'use strict';

import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import type { Market } from '../../types/Market.js';
import type { Exchange } from '../../types/Exchange.js';
import type { Currency } from '../../types/Currency.js';
import type { Balance } from "../../types/Balance.js";
import type { Account } from "../../types/Account.js";
import type { Trade } from "../../types/Trade.js";
import OrdersTable from './OrdersTable.jsx';
import * as exchangeApi from '../../helpers/api/ExchangeApi.js';
import { updateTitle } from '../../helpers/BrowserTitleHelper.js';

type Props = {
  t: any,
  markets: Array<Market>,
  exchanges: Array<Exchange>,
  currencies: Array<Currency>,
  balances: Array<Balance>,
  accounts: Array<Account>,
  platformId: number
};

type State = {
  active: {
    exchange: ?Exchange,
    market: ?Market
  },
  trades: Array<Trade>,
  lastPrice: number
}

class OrdersDataLayer extends React.PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = this.getInitialState();
  }

  // set this.state.active to an empty object initially
  getInitialState() {
    return {
      active: {
        exchange: null,
        market: null
      },
      trades: [],
      lastPrice: 0
    };

    
  }

  // setActiveMarket will selectively change this.state.active = {exchange, market}
  // to the specified exchId or mktId (which then flows into props)
  setActiveMarket(exchId: number, mktId: number) {
    if (exchId) {
      let activeExchange = this.props.exchanges.filter((exch) => {
        return exch.exchId == exchId;
      })[0];

      this.setState({
        active: {
          exchange: activeExchange,
          market: this.state.active.market,
        }
      });
    }

    if (mktId) {
      let activeMarket = this.props.markets.filter((mkt) => {
        return mkt.marketId == mktId;
      })[0];
      
      
      
      this.setState({
        active: {
          exchange: this.state.active.exchange,
          market: activeMarket
        }
      }, () => {
        if (this.state.active.exchange && this.state.active.market) {
          exchangeApi.getTrades(this.state.active.exchange.exchCode, this.state.active.market.marketName, (data) => {
            if (data.success) {
              let trades = this.sortTrades(this.state.trades.concat(data.result));
    
              if (trades && trades.length) {
                this.setState({
                  lastPrice: trades[0].price
                });
              }
    
            }
          });
        }
      });
    }
    
  }

  sortTrades(trades: Array<Trade>): Array<Trade> {
    return trades.sort((a, b) => {
      if (+new Date(a.time) > +new Date(b.time)) return -1;
      if (+new Date(b.time) > +new Date(a.time)) return 1;
      if (a.market_history_id < b.market_history_id) return 1;
      if (b.market_history_id < a.market_history_id) return -1;
      return 0;
    });
  }

  updateOrderFormType(orderFormType: string) {
    return orderFormType;
  }

  render() {

    updateTitle(`Orders`, this.props.platformId);

    return (
      <div className="cols orders-page">
        <section>
          <OrdersTable
            markets={ this.props.markets } 
            exchanges={ this.props.exchanges } />
        </section>
      </div>
    );
  }

}

const mapStateToProps = (state) => ({
  platformId: state.userInfo.user.platformId
});

export { OrdersDataLayer as PureOrdersDataLayer };

export default translate(`orders`)(connect(mapStateToProps)(OrdersDataLayer));

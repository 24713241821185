// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import MarketNotes from "../markets/MarketNotes.jsx";
import {
  saveNotes
} from "../../helpers/api/UserApi.js";

type Props = {
  t: any,
  panelData: any, 
  settings: any, 
  panel: any,
  setPanelMarket: (exchCode: string, mktName: string, activePanelEdit: any) => void,
};

type State = {
  updateNotesTimeout: any,
};

class NotesPanel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      updateNotesTimeout: null
    };
  }

  onNoteChange = (note: string, mkt: string) => {
    clearTimeout(this.state.updateNotesTimeout);
    this.setState({
      updateNotesTimeout: setTimeout(() => saveNotes({ note: note, marketPair: mkt }), 3000)
    });
  };

  switchNotes = (market: string) => this.props.setPanelMarket(``, market, this.props.panel);

  render() {
    const mkt = `${ this.props.settings.market }`;
    if (this.props.panelData.hasOwnProperty(mkt)) {
      return (
        <MarketNotes
          disattached={ true }
          switchNotes={ (e) => this.switchNotes(e) }
          marketLabel={ mkt }
          notes={ this.props.panelData[mkt].notes }
          updateNotes={ (e) => this.onNoteChange(e, this.props.settings.market) } />
      );
    } else {
      return (
        <div className="panel-loading">
          <span>
            { this.props.t(`app:loading`) }
          </span>
        </div>
      );
    }
  }
}

// $FlowIgnore: suppressing this error
NotesPanel.getPanelTitle = (settings: Settings) => {
  return settings.market ? settings.market : `Notes`;
};

export default translate(`boards`)(NotesPanel);

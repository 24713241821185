'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default (uniqueID = `Edit`) => SvgHelper(
  `<defs><path d="M12.667 9.773a.667.667 0 1 1 1.333 0v3.56a2 2 0 0 1-2 2H2.667a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h3.56a.667.667 0 0 1 0 1.333h-3.56A.667.667 0 0 0 2 4v9.333c0 .369.298.667.667.667H12a.667.667 0 0 0 .667-.667v-3.56zM12.47.862l2.667 2.667c.26.26.26.682 0 .942l-6.667 6.667a.667.667 0 0 1-.471.195H5.333a.667.667 0 0 1-.666-.666V8c0-.177.07-.346.195-.471L11.529.862c.26-.26.682-.26.942 0zM6 8.276V10h1.724l6-6L12 2.276l-6 6z" id="${ uniqueID }a"/></defs><g fill="none" fill-rule="evenodd"><mask id="${ uniqueID }b" fill="#fff"><use xlink:href="#${ uniqueID }a"/></mask><use fill="#000" fill-rule="nonzero" xlink:href="#${ uniqueID }a"/><g mask="url(#${ uniqueID }b)" fill="#FFF"><path d="M0 0h16v16H0z"/></g></g>`,
  {
    width: `2rem`,
    height: `2rem`,
    viewBox: `0 0 16 16`
  }
);
/* eslint-enable */

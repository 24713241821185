// @flow
'use strict';

import React from 'react';
import moment from 'moment';
import Today from '../../svgs/Today.jsx';
import { getActiveTheme } from '../../helpers/ThemeHelper.js';
import { Popover, ArrowContainer } from 'react-tiny-popover';

type Props = {
  events: any,
  currCode: string,
  onClickHandler: (e: any) => void
};

type State = {
  isPopoverOpen: boolean
}


export default class MarketEvents extends React.Component<Props, State> {
  theme: any;

  constructor(props: Props) {
    super(props);

    this.theme = getActiveTheme(undefined, true);

    this.state = {
      isPopoverOpen: false
    };
  }

  render() {
    return (
      <div
        className="market-events">
        {
          this.props.events.sort((a, b) => {
            if (+(new Date(a.date_event)) < +(new Date(b.date_event))) return 1;
            if (+(new Date(a.date_event)) > +(new Date(b.date_event))) return -1;
            return 0;
          }).slice(0, 50).map((n, i) => (
            <Popover
              key={ `${ n.id }-${ i }-eventitem` }
              isOpen={ this.state.isPopoverOpen }
              positions={ [`top`] }
              content={ ({ position, childRect, popoverRect }) => (
                <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                  position={ position }
                  childRect={ childRect }
                  popoverRect={ popoverRect }
                  arrowColor={ this.theme[`--gray-2`] }>
                  <div className={ `popover-content` }>
                    { `heyyy` }
                  </div>
                </ArrowContainer>
              ) }>
              <div
                className={ `events-item fade-in ` + (moment(n.date_event).isAfter(moment()) ? `future` : `past`) } 
                onClick={ () => this.props.onClickHandler(n.id) }>
          
                <div className="event-date">
                  <Today />
                  { 
                    (+(new Date()) - +(new Date(n.pubDate))) <= 1000 * 60 * 60 ? 
                      moment(n.date_event).fromNow() :
                      moment(n.date_event).format(`MMMM Do YYYY`)
                  }
                </div>
                <div className="event-currency">
                  <img
                    className="event-currency-img" src={ `https://www.coinigy.com/assets/img/currency/${this.props.currCode}.png` }
                    onError={ 
                      (e)=>{e.target.onerror = null; e.target.src=`https://www.coinigy.com/assets/img/currency/empty.png`;} 
                    }/>
                </div>
                <div className="event-title">
                  { n.title.en }
                </div>
              
                <div className="event-categories">
                  {
                    n.categories && n.categories.map((c) => (
                      <div key={ `category-` + c.id }>
                        { c.name }
                      </div>
                    ))
                  }
                </div>

              </div>
            </Popover>
          ))
        }
    
      </div>
    );
  }
}

// @flow
'use strict';

import React from 'react';
import Button from '../utilities/Button.jsx';
import ScrollableArea from '../utilities/ScrollableArea.jsx';
import PanelForm from './PanelForm.jsx';
import { getComponentFromPanelTypeId } from '../../helpers/PanelTypeHelper.js';
import type { PanelType } from '../../types/PanelType.js';
import type { Market } from '../../types/Market.js';
import type { Exchange } from '../../types/Exchange.js';
import type { Account } from '../../types/Account.js';

type Props = {
  market: ?Market,
  settings: any, 
  panelData: any,
  width: number,
  height: number,
  accounts: Array<Account>, 
};

type State = {
  typeId: number,
  sizeId: number,
  settings: any
};

const EditPanelHelper = (
  t: any,
  panelTypes: Array<PanelType>, 
  typeId: number, 
  sizeId: number, 
  settings: any, 
  markets: Array<Market>,
  accounts?: Array<Account>, 
  exchanges: Array<Exchange>,
  save: (body: any) => void,
  cancel: () => void,
  canCreateNewPanel: boolean) => {
  let Component = getComponentFromPanelTypeId(typeId);

  class EditPanel extends React.Component<Props, State> { 
    static getPanelTitle: (settings: any, markets: Array<Market>, exchanges: Array<Exchange>) => string;

    constructor(props: Props) {
      super(props);

      this.state = this.getInitialState();
    }

    getInitialState() {
      return {
        typeId,
        sizeId,
        settings
      };
    }

    getDefaultSettingsFromCustomOptions(activeType?: PanelType) {
      if (!activeType) return { };
      return Object.keys(activeType.customOptions).reduce((settings, key) => ({ 
        ...settings, [key]: ((activeType || {}).customOptions || {})[key].type == `Boolean` ? false : ``
      }), { });
    }

    handleInputChange(v: any, key: string) {
      if (key == `typeId`) {
        this.setState({
          ...this.getInitialState(),
          settings: this.getDefaultSettingsFromCustomOptions(panelTypes.find((t) => t.typeId == v)),
          [key]: v
        });
      } else {
        this.setState({
          [key]: v
        });
      }
    }

    handleCustomInputChange(v: any, key: string, modifier: (s: any) => any = (s) => s) {
      this.setState({
        settings: {
          ...modifier(this.state.settings),
          [key]: v
        }
      });
    }

    render() {
      return (
        <div className="edit-panel-wrapper">
          <ScrollableArea height={ this.props.height } width={ this.props.width }>
            <PanelForm
              t={ t }
              markets={ markets }
              exchanges={ exchanges }
              accounts={ this.props.accounts }
              panelTypes={ panelTypes }
              typeId={ this.state.typeId }
              sizeId={ this.state.sizeId }
              settings={ this.state.settings }
              customOptionFilter={ (o) => o !== `exchange` && o !== `market` }
              isUpdate={ true }
              handleCustomInputChange={ this.handleCustomInputChange.bind(this) }
              handleInputChange={ this.handleInputChange.bind(this) } />
            <div className="actions">
              <Button
                type="cancel"
                onClick={ () => cancel() || true }>
                Cancel
              </Button>
              <Button
                type="confirm"
                disabled={ !canCreateNewPanel }
                onClick={ () => save({
                  typeId: this.state.typeId,
                  sizeId: this.state.sizeId,
                  panelSetting: JSON.stringify(this.state.settings)
                }) || true }>
                Save
              </Button>
            </div>
          </ScrollableArea>
        </div>
      );
    }
  }

  // $FlowIgnore: suppressing this error
  EditPanel.getPanelTitle = Component.getPanelTitle;

  return EditPanel;
};

export default EditPanelHelper;

import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Refresh from '../../../svgs/Refresh.jsx';
import Checkbox from '../../utilities/Checkbox.jsx';
import { DetailsBalances } from './DetailsBalances.jsx';
import { DetailsPendingDeposits } from './DetailsPendingDeposits.jsx';
import { DetailsHistory } from './DetailsHistory.jsx';
import { getAccountBalance } from '../../../helpers/api/BalanceApi.js';
import { getSummariesCurrencies, getAllCurrencies } from '../../../helpers/api/CurrencyApi';
import { toFixedDecimalsHTML } from '../../../helpers/NumberHelper.js';
// import {
//   emitEvent,
//   NOTIFICATION,
// } from '../../../helpers/EventHelper.js';
import { updateTitle } from '../../../helpers/BrowserTitleHelper.js';
import BakktButton from './../Bakkt/BakktButton.jsx';
import CoinigyLogo from '../../../svgs/CoinigyLogo.jsx';
import { getUserApplication } from '../../../helpers/UserApplicationsHelper.js';
import Wallet from '../../../svgs/Wallet.jsx';
// import History from '../../../svgs/History.jsx';
// import Globe from '../../../svgs/Download.jsx';
import { setBalanceFormat } from "../../../helpers/BalancesHelper.js";
// import SelectField from "../../utilities/SelectField.jsx";
// import DatePicker from "../../utilities/DatePicker.jsx";
import CustomerStatements from '../CustomerStatements.jsx';
import { FiatFundsModal } from './../Bakkt/FiatFundsModal.jsx';
import { CryptoDepositModal } from './../Bakkt/CryptoDepositModal.jsx';
import { CryptoWithdrawModal } from './../Bakkt/CryptoWithdrawModal.jsx';

const API_POLL_MS = 90000;
// const END_DATE = `endDate`;
// const START_DATE = `startDate`;
// const DATE_FORMAT = `MM-dd-yyyy`;
// const ACTUAL_DATE = new Date(); 
// const MIN_DATE = new Date(2022, 0, 1);

export const AccountDetails = (props) => {
  const { accounts, exchanges, t, refreshBalance, isAccountsInit, isExchangesInit } = props;
  const { accountId } = useParams();
  const [ activeAccount, setActiveAccount ] = useState(null);
  const [ selectedExchange, setSelectedExchange ] = useState(null);
  const [ activeTab, setActiveTab ] = useState(0);
  const [ showZeroBalance, setShowZeroBalance ] = useState(true);
  const [ fiatWithdrawModalOpen, setFiatWithdrawModalOpen ] = useState(false);
  const [ cryptoWithdrawModalOpen, setCryptoWithdrawModalOpen ] = useState(false);
  const [ fiatDepositModalOpen, setFiatDepositModalOpen ] = useState(false);
  const [ cryptoDepositModalOpen, setCryptoDepositModalOpen ] = useState(false);
  
  //const [ filterText, setFilterText] = useState(``);
  const [ balances, setBalances ] = useState([]);
  const [ totalBalance, setTotalBalance ] = useState(0);
  const [ selectedCurrCode, setSelectedCurrCode ] = useState(null);
  const [ refreshing, setRefreshing ] = useState(false);

  const [ currencies, setCurrencies ] = useState(null);
  const [ isLoading, setIsLoading ]  = useState(true);
  const [ intervalId, setIntervalId ] = useState(null);
  // const [period, setPeriod] = useState(`ALL_TIME`);
  // const [ endDate, setEndDate ] = useState(new Date());
  // const [ startDate, setStartDate ] = useState(MIN_DATE);
  
  const history = useHistory();

  const platformId = useSelector((state) => state.userInfo.user.platformId);
  const userApplications = useSelector((state) => state.userInfo.userApplications);
  const applicationsStatuses = useSelector((state) => state.userInfo.applicationsStatuses);
  const rehydrated = useSelector((state) => state._persist.rehydrated);

  updateTitle(`Wallet`, platformId);

  const CGYUserApplication = useMemo(() => getUserApplication(`CGY`, userApplications, applicationsStatuses), [userApplications, applicationsStatuses]);

  const handleClick = () => history.push(`/markets/CGY/BTC/USD`);

  const setAccountInfo = () => {
    getAccountBalance(accountId, (balancesResponse) => {
      if (balancesResponse.success) {
        const balances = balancesResponse.result.map((b) =>({ ...b, hasBalance: true }));
        setTotalBalance(balances.reduce((total, value) => total + value.quoteBalance, 0));        
        getAllCurrencies({ params: { exchCode: `CGY` } }, (data) => {
          if (data.success && data.result?.length) {
            const supportedBalances = data.result.map((curr) => {
              const balance = balances?.find((b) => b.balanceCurrCode === curr.currCode);
              if (balance) {
                return {
                  ...balance,
                  fiat: curr.isFiat
                };
              }
                  
              return {
                balanceAuthId: 0,
                balanceCurrCode: curr.currCode,
                balanceCurrId: 0,
                balanceCurrName: curr.currName,
                balanceAmountAvailable: 0,
                balanceAmountHeld: 0,
                balanceAmountTotal: 0,
                quoteBalance: 0,
                balanceQuoteCurrCode: `USD`,
                lastPrice: 0,
                balanceHidden: false,
                hasImage: false,
                fiat: curr.isFiat,
                hasBalance: false,
              };
            });
            setBalances(supportedBalances);
          } else {
            setBalances(balances);
          }
        });
      }
    });
  };

  useEffect(() => {
    if (accounts && accountId) {
      const getActiveAccount = accounts.find((account) => account.authId == accountId);
      setActiveAccount(getActiveAccount);
    }
  }, [accounts, accountId]);

  useEffect(() => {
    if (exchanges && activeAccount) {
      setSelectedExchange(exchanges.find((e) => e.exchId == activeAccount.authExchId));
    }
  }, [exchanges, activeAccount]);

  useEffect(() => {
    if (selectedExchange) {
      getSummariesCurrencies({ params: { SearchTerm: `${selectedExchange.exchCode}:` } }, (data) => {
        const curr = data.result.reduce((accum, curr) => {
          return [
            ...accum, 
            {
              currId: curr.currId,
              currCode: curr.currCode,
              currName: curr.currName 
            }
          ];
        }, []);
  
        setCurrencies(curr);
      });

      setIsLoading(false);
    }
  }, [selectedExchange]);  

  useEffect(() => {
    if (isAccountsInit && isExchangesInit) {
      if (accountId) {
        setAccountInfo();

        const interval = setInterval(() => {
          setAccountInfo();
        }, API_POLL_MS);
  
        setIntervalId(interval);
      } else {
        const CGYExchange = exchanges.find((e) => e.exchCode === `CGY`);
        if (CGYExchange) {
          const authId = accounts.find((a) => a.authExchId == CGYExchange?.exchId)?.authId;
          if (authId) {
            history.push(`/wallet/${authId}`);
          } else{
            setIsLoading(false);
          }
        }
      }
    }
  }, [accountId, isAccountsInit, isExchangesInit]);

  useEffect(() => {
    if (refreshing) {
      refreshBalance(accountId)
        .then(() => {
          setAccountInfo();
          setRefreshing(false);
        });
    }
  }, [refreshing]);

  useEffect(() => {
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [intervalId]);

  const getExchangeIcon = () => {
    if ( selectedExchange && activeAccount ) {
      let src = `${ window.WWW_URL }/assets/img/currency/empty.png`;
      if (selectedExchange) {
        src = `${ window.WWW_URL }/assets/img/exchange/${selectedExchange.exchCode}-icon.png`;
      } else if (activeAccount.authOptional && activeAccount.authOptional.length > 0) {
        src = `${ window.WWW_URL }/assets/img/currency/${activeAccount.authOptional}-icon.png`;
      }
      return (
        <img
          src={ src }/>
      );
    }
  };

  const handleWithdrawModal = (open, rowDetails) => {
    setSelectedCurrCode(rowDetails.balanceCurrCode);
    if (rowDetails.balanceCurrCode === `USD`) {
      setFiatWithdrawModalOpen(open);
    } else {
      setCryptoWithdrawModalOpen(open);
    }
  };

  const handleDepositModal = (open, rowDetails) => {
    setSelectedCurrCode(rowDetails.balanceCurrCode);
    if (rowDetails.balanceCurrCode === `USD`) {
      setFiatDepositModalOpen(open);
    } else {
      setCryptoDepositModalOpen(open);
    }
  };
  
  /* const handlePeriod = (e, period) => {
    setPeriod(period);
  
    switch(period) {
    case `ALL_TIME`:
      setStartDate(MIN_DATE);
      setEndDate(ACTUAL_DATE);
      break;
    case `THIS_MONTH`:
    case `CUSTOM`:
      setStartDate(new Date(ACTUAL_DATE.getFullYear(), ACTUAL_DATE.getMonth(), 1));
      setEndDate(ACTUAL_DATE);
      break;
    case `LAST_MONTH`:
      setStartDate(new Date(ACTUAL_DATE.getFullYear(), ACTUAL_DATE.getMonth() -1, 1));
      setEndDate(new Date(ACTUAL_DATE.getFullYear(), ACTUAL_DATE.getMonth(), 0));
      break;
    case `THIS_YEAR`:
      setStartDate(new Date(ACTUAL_DATE.getFullYear(), 0, 1));
      setEndDate(new Date(ACTUAL_DATE.getFullYear() + 1, 0, 0));
      break;
    case `TODAY`:
      setStartDate(new Date(ACTUAL_DATE.getFullYear(), ACTUAL_DATE.getMonth(), ACTUAL_DATE.getDate() -1));
      setEndDate(ACTUAL_DATE);
      break;
    case `YESTERDAY`:
      setStartDate(new Date(ACTUAL_DATE.getFullYear(), ACTUAL_DATE.getMonth(), ACTUAL_DATE.getDate() -2));
      setEndDate(new Date(ACTUAL_DATE.getFullYear(), ACTUAL_DATE.getMonth(), ACTUAL_DATE.getDate() -1));
      break;
    case `THIS_WEEK`:
      setStartDate(new Date(ACTUAL_DATE.getFullYear(), ACTUAL_DATE.getMonth(), ACTUAL_DATE.getDate() - ACTUAL_DATE.getDay()));
      setEndDate(new Date(ACTUAL_DATE.getFullYear(), ACTUAL_DATE.getMonth(), ACTUAL_DATE.getDate() + 6 - ACTUAL_DATE.getDay()));
      break;
    case `LAST_WEEK`:
      setStartDate(new Date(ACTUAL_DATE.getFullYear(), ACTUAL_DATE.getMonth(), ACTUAL_DATE.getDate() - ACTUAL_DATE.getDay() - 7));
      setEndDate(new Date(ACTUAL_DATE.getFullYear(), ACTUAL_DATE.getMonth(), ACTUAL_DATE.getDate() + 6 - ACTUAL_DATE.getDay() - 7));
      break;
    }

    return false;
  }; */

  /* const handleUpdateState = (key, date) => {
    if (key && date) {
      key === START_DATE ? setStartDate(date) : setEndDate(date);
    }
  }; */

  const renderEntityTable = () => {
    const tableProps = {
      t,
      tableData: [],
      //filterText,
      selectedExchange,
      // startDate,
      // endDate,
    };

    if (balances) {
      tableProps.tableData = balances;
    }

    if (!showZeroBalance && balances.length > 0) {
      tableProps.tableData = balances.filter((balance) => {
        return (balance.balanceAmountTotal > 0);
      });
    }

    switch (activeTab) {
    case 0:
      return (
        <DetailsBalances
          { ...tableProps }
          handleDepositModal={ handleDepositModal }
          handleWithdrawModal={ handleWithdrawModal } />
      );
    case 1:
      return (<DetailsPendingDeposits { ...tableProps } />);
    case 2:
      return (<DetailsHistory { ...tableProps } currencies={ currencies } />);
    case 3:
      return (<CustomerStatements t={ t }/>);  
    }
  };

  const changeActiveTab = (v) => {
    setActiveTab(v);
  };

  const tabs = [
    {
      index: 0,
      text: <span>
        { Wallet }
        <span style={ { paddingLeft: `1.5rem` } }>
          { t(`Balances`) }
        </span>
      </span>
    },
    // {
    //   index: 2,
    //   text: <span>
    //     { History }
    //     <span style={ { paddingLeft: `1.5rem` } }>
    //       { t(`History`) }
    //     </span>
    //   </span>
    // },
    // {
    //   index: 3,
    //   text: <span>
    //     { Globe }
    //     <span style={ { paddingLeft: `1.5rem` } }>
    //       { t(`Customer Statements`) }
    //     </span>
    //   </span>
    // }    
  ];

  const accountExchName = (exchanges && activeAccount) && (exchanges.find((e) => e.exchId == activeAccount.authExchId))?.exchName || t(`Not found`);
  const accountExchCode = (exchanges && activeAccount) && (exchanges.find((e) => e.exchId == activeAccount.authExchId))?.exchCode || t(`Not found`);
  

  if (isLoading || !CGYUserApplication.isEligible || !rehydrated) {
    return ``;
  } else if (activeAccount && selectedExchange /* && CGYUserApplication.isVerified */ && !CGYUserApplication.isAccountClosed) {
    return (
      <div className="accounts account-details-header fade-in">
        <section>
          <div className="accounts-contain">
            <div className="header top-header">
              <div>
                <div className="left-side clickable" onClick={ () => handleClick() }>
                  <div className="exch-icon">
                    { getExchangeIcon() }
                  </div>
                  <div className="exch-account">
                    <div className="exch-name">
                      { `${accountExchName} (${accountExchCode})` }
                    </div>
                    <div className="exch-authName">
                      { activeAccount.authNickname }
                    </div>
                  </div>
                </div>
                <div
                  onClick={ () => setRefreshing(true) }
                  className={
                    `refresh-balance ${refreshing ? `animate` : ``}`
                  }>
                  { Refresh() }
                </div>
              </div>
              <div className="right-side">
                <span className="total-balance" dangerouslySetInnerHTML={ toFixedDecimalsHTML(totalBalance, true, setBalanceFormat(`USD`)) } />
                <h3>
                  USD
                </h3>
              </div>
            </div>
            <div className="header sub-header">
              <div className="left-side">
                <ul className="header-tab">
                  {
                    tabs.map((t, i) => (
                      <li
                        key={ i }
                        className={ activeTab == t.index ? `active` : `` }
                        onClick={ () => changeActiveTab(t.index) }>
                        <a

                          activeclassname="active">
                          <span>
                            { t.text }
                          </span>
                        </a>
                      </li>
                    ))
                  }
                </ul>
              </div>
              <div className="right-side">
                {
                  activeTab == 0 && (
                    <div className='filter-container'>
                      { /* <div className='flex period-filter'>
                        <SelectField
                          label={ `` }
                          name={ `period` }
                          value={ period }
                          options={ [
                            { value: `TODAY`, label: `Today` },
                            { value: `YESTERDAY`, label: `Yesterday` },
                            { value: `THIS_WEEK`, label: `This Week` },
                            { value: `LAST_WEEK`, label: `Last Week` },
                            { value: `THIS_MONTH`, label: `This Month` },
                            { value: `LAST_MONTH`, label: `Last Month` },
                            { value: `THIS_YEAR`, label: `This Year` },
                            { value: `ALL_TIME`, label: `All Time` },
                            { value: `CUSTOM`, label: `Custom` },
                          ] }
                          onChange={ (e, v) => handlePeriod(e, v) }/>
                        { period === `CUSTOM` && 
                        <>
                          <DatePicker
                            value={ startDate }
                            min={ MIN_DATE }
                            max={ ACTUAL_DATE }
                            dateFormat={ DATE_FORMAT }
                            name={ `startDate` }
                            onChange={ (value) => handleUpdateState(START_DATE, value, period) }/>

                          <DatePicker
                            value={ endDate }
                            min={ MIN_DATE }
                            max={ ACTUAL_DATE }
                            dateFormat={ DATE_FORMAT }
                            name={ `endDate` }
                            onChange={ (value) => handleUpdateState(END_DATE, value, period) }/>
                        </>
                        }
                      </div> */ }
                      <div className='show-wallets-filter'>
                        <Checkbox
                          name={ `zeroBalance` }
                          label={ t(`Show Empty Wallets`) }
                          value={ showZeroBalance }
                          onChange={ (e) => {
                            setShowZeroBalance(e.target.checked);
                          } } />
                      </div>
                    </div>                      
                  )
                }
                { /* 
                Search commented out for now
                <div className="search">
                  <TextField
                    icon={ Search }
                    label={ t(`Find balances`) }
                    name={ `balances` }
                    value={ filterText }
                    onChange={ (e) => setFilterText(e.target.value) } />
                </div> */
                }
              </div>
            </div>
            {
              renderEntityTable()
            }
          </div>
          {
            fiatWithdrawModalOpen && (
              <FiatFundsModal
                t={ t }
                currCode={ selectedCurrCode }
                close={ () => setFiatWithdrawModalOpen(false) }
                accountId={ accountId }
                direction={ 0 }/>
            )
          }
          {
            cryptoWithdrawModalOpen && (
              <CryptoWithdrawModal
                t={ t }
                currCode={ selectedCurrCode }
                close={ () => setCryptoWithdrawModalOpen(false) }
                accountId={ accountId }/>
            )
          }
          {
            fiatDepositModalOpen && (
              <FiatFundsModal
                t={ t }
                currCode={ selectedCurrCode }
                close={ () => setFiatDepositModalOpen(false) }
                accountId={ accountId } 
                direction={ 1 }/>
            )
          }
          {
            cryptoDepositModalOpen && (
              <CryptoDepositModal
                t={ t }
                currCode={ selectedCurrCode }
                accountId={ accountId } 
                close={ () => setCryptoDepositModalOpen(false) }/>
            )
          }
        </section>
      </div>
    );
  } else {
    return (
      <div className="accounts wallet-signup">
        <section>
          <div className="notification">
            <div className='logo'>
              { CoinigyLogo }
            </div>
            
            <span className='signup-message'>
              <h2 style={ { fontSize: `3rem`, textAlign: `center`, margin: 0 } }>
                { `Create Your Wallet & Activate Trading` }
              </h2>
              <p className={ `first-p-block` } style={ { color: `var(--body-text-color-alpha-54)`, fontSize: `2rem` } }>
                { `Just a few more steps and you're good to go` }
              </p>
              <div className="main-body">
                <p className="medium-text" style={ { color: `var(--body-text-color-alpha-54)`, fontSize: `1.6rem` } }>
                  { `Create your Coinigy wallet now to take advantage of low trading fees, high liquidity and industry-leading custody technology provided by Bakkt. Learn More` }     
                </p>
              </div>
              <div style={ { textAlign: `center` } }>
                <img src={ `${ window.WWW_URL }/assets/img/backed-by-bakkt.png` } height="32"/>
              </div>
            </span>
            <BakktButton accounts={ accounts } exchanges={ exchanges } />
          </div>
        </section>
      </div>
    );    
  }
};

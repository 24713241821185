'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path class="clipboard" d="M27.577,42.754c-0.041-6.509-0.082-13.018-0.123-19.526c4.802-1.558,8.273-6.087,8.24-11.399
  C35.652,5.265,30.278-0.042,23.715,0C17.15,0.041,11.844,5.413,11.886,11.979c0.031,5.101,3.29,9.429,7.818,11.091
  c0.014,2.19,0.027,4.383,0.041,6.573l-5.94,0.037l0.02,3.098l5.94-0.036c0.006,0.99,0.013,1.98,0.02,2.97l-8.956,0.056l0.02,3.1
  l8.954-0.057c0.005,0.813,0.011,1.63,0.016,2.444l-5.94,0.038l0.02,3.1l6.246-0.04C21.582,47.713,27.604,47.163,27.577,42.754z
   M16.535,11.948c-0.026-4,3.208-7.275,7.209-7.3c3.999-0.025,7.274,3.208,7.301,7.208c0.024,4.001-3.21,7.275-7.21,7.301
  C19.834,19.184,16.559,15.948,16.535,11.948z"/>`,
  {
    width: `1.8rem`,
    height: `2rem`,
    viewBox: `0 -2 50 50`
  }
);
/* eslint-enable */




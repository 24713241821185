// @flow
`use strict`;

import { $ } from "./ApiHelper";

export function getTradingPairDetail(exchangeCode: string, marketName: string, onSuccess: (data: any) => void) {
  $({
    url: `/private/exchanges/${exchangeCode}/markets/${marketName}`,
    success: onSuccess
  });
}

export function getTradingCurrencyDetail(exchangeCode: string, currCode: string, onSuccess: (data: any) => void) {
  $({
    url: `/private/exchanges/${exchangeCode}/${currCode}`,
    success: onSuccess
  });
}

export function getTrades(exchangeCode: string, marketName: string, onSuccess: (data: any) => void) {
  $({
    url: `/private/exchanges/${exchangeCode}/markets/${marketName}/trades`,
    success: onSuccess
  });
}

export function getPublicTrades(exchangeCode: string, marketName: string): Promise<any> {
  return new Promise((resolve, reject) => {
    $({
      url: `/public/exchanges/${exchangeCode}/markets/${marketName}/trades`,
      success: resolve,
      error: reject
    });
  });
}

export function getMarketDepthData(exchangeCode: string, marketName: string, onSuccess: (data: any) => void) {
  $({
    url: `/private/exchanges/${exchangeCode}/markets/${marketName}/depth`,
    success: onSuccess
  });
}

export function getPublicMarketDepthData(exchangeCode: string, marketName: string): Promise<any> {
  return new Promise((resolve, reject) => {
    $({
      url: `/public/exchanges/${exchangeCode}/markets/${marketName}/depth`,
      success: resolve,
      error: reject
    });
  });
}

export function getTickerData(exchangeCode: string, marketName: string, onSuccess: (data: any) => void) {
  $({
    url: `/private/exchanges/${exchangeCode}/markets/${marketName}/ticker`,
    success: onSuccess
  });
}

export function getMarketRange(exchangeCode: string, marketName: string, onSuccess: (data: any) => void) {
  $({
    url: `/private/exchanges/${exchangeCode}/markets/${marketName}/range`,
    success: onSuccess
  });
}

export function getExchanges(onSuccess: (data: any) => void) {
  $({
    url: `/private/exchanges`,
    success: onSuccess
  });
}

export function getMarkets(onSuccess: (data: any) => void) {
  $({
    url: `/private/markets`,
    success: onSuccess
  });
}

export function getAggregatedMarkets(onSuccess: (data: any) => void) {
  $({
    url: `/private/aggregatedMarkets`,
    success: onSuccess
  });
}

export function getItbSignals(currCode: string, onSuccess: (data: any) => void, onError: (data: any) => void) {
  $({
    url: `/private/thirdparty/intotheblock/${currCode}/signals`,
    success: onSuccess,
    error: onError,
  });
}

export function getCoinMarketCalCategories(): Promise<any> {
  return new Promise((resolve, reject) => {
    $({
      url: `/private/thirdparty/coinmarketcal/categories`,
      success: resolve,
      error: reject
    });
  });
}

export function getCoinMarketCalCoins(): Promise<any> {
  return new Promise((resolve, reject) => {
    $({
      url: `/private/thirdparty/coinmarketcal/coins`,
      success: resolve,
      error: reject
    });
  });
}


export function getCoinMarketCalEvents(currencyName: string, dateRangeStart: string, dateRangeEnd: string): Promise<any> {
  return new Promise((resolve, reject) => {
    $({
      url: `/private/thirdparty/coinmarketcal/events?max=35&sortBy=created_desc&dateRangeStart=${dateRangeStart}&dateRangeEnd=${dateRangeEnd}&coins=${currencyName}`,
      success: resolve,
      error: reject
    });
  });
}

export function getExchangesTags(): Promise<any> {
  return new Promise((resolve, reject) => {
    $({
      url: `/public/exchanges/tags`,
      success: resolve,
      error: reject
    });
  });
}

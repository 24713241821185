// @flow
'use strict';

import { SET_USER_HASH } from '../index.js';

export function setUserHash(userHash: string) {
  return {
    type: SET_USER_HASH,
    payload: userHash
  };
}

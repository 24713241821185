// @flow
'use strict';

import { toLocalDate } from "./DateHelper.js";

import { 
  getCustomerStatementsPdf,
  getCustomerStatementsPdfFromDay,
  getCustomerStatementsPdfFromMonth,
  getCustomerStatementsPdfFromYear,
  getCustomerStatementsJson,
  getCustomerStatementsJsonFromDay,
  getCustomerStatementsJsonFromMonth,
  getCustomerStatementsJsonFromYear
} from './api/UserApi';

const DAY = `day`;
const MONTH = `month`;
const YEAR = `year`;
const DATE_FORMAT_REQ = `%Y%Y%Y%Y-%M%M-%D%D`;


const downloadReport = (type: string, period: string, startDate: Date, endDate: Date) => {
  
  type === `pdf` ? 
    downloadPdf(period, startDate, endDate) : 
    downloadJson(period, startDate, endDate);

  return false;
};

const downloadPdf = (period: string, startDate: Date, endDate: Date) => {
  if(period === DAY) {
    // Range Period
    if(endDate) {
      getCustomerStatementsPdf(
        toLocalDate(startDate, DATE_FORMAT_REQ),
        toLocalDate(endDate, DATE_FORMAT_REQ) , (data) => {
          data && previewPdf(data);
        });
    } else {
      getCustomerStatementsPdfFromDay(
        toLocalDate(startDate, DATE_FORMAT_REQ) , (data) => {
          data && previewPdf(data);
        });
    }
  }

  if(period === MONTH) {
    getCustomerStatementsPdfFromMonth(
      toLocalDate(startDate, DATE_FORMAT_REQ) , (data) => {
        data && previewPdf(data);
      });
  }

  if(period === YEAR) {
    getCustomerStatementsPdfFromYear(
      toLocalDate(startDate, DATE_FORMAT_REQ) , (data) => {
        data && previewPdf(data);
      });
  }  
}; 

const downloadJson = (period: string, startDate: Date, endDate: Date) => {
  if(period === DAY) {
    // Range Period
    if(endDate) {
      getCustomerStatementsJson(
        toLocalDate(startDate, DATE_FORMAT_REQ),
        toLocalDate(endDate, DATE_FORMAT_REQ) , (data) => {
          data && previewPdf(data);
        });
    } else {
      getCustomerStatementsJsonFromDay(
        toLocalDate(startDate, DATE_FORMAT_REQ) , (data) => {
          data && previewPdf(data);
        });
    }
  }

  if(period === MONTH) {
    getCustomerStatementsJsonFromMonth(
      toLocalDate(startDate, DATE_FORMAT_REQ) , (data) => {
        data && previewPdf(data); 
      });
  }

  if(period === YEAR) {
    getCustomerStatementsJsonFromYear(
      toLocalDate(startDate, DATE_FORMAT_REQ) , (data) => {
        data && previewPdf(data);
      });
  }
};

const previewPdf = (data: any) => {
  const openFile = data.responseURL ? data.responseURL : window.URL.createObjectURL(data);
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {//IE
    window.navigator.msSaveOrOpenBlob(data);
  } else if (navigator.userAgent.indexOf(`Chrome`) != -1 || navigator.userAgent.indexOf(`Firefox`) != -1){
    window.open(openFile, `_blank`);
  } else { //Safari & Opera iOS
    window.open(openFile, `_self`);
  }
};

export { previewPdf, downloadReport };


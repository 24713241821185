// @flow
'use strict';

import React from 'react';
import ReactHighcharts from 'highcharts-react-official';
import BalancesEmptyData from './BalancesEmptyData.jsx';

type Props = {
  options: any,
  hasData: boolean
};

const Chart = (props: Props) => {
  if (props.options.series.length == 0 || props.options.series[0].data.length == 0) {
    return (<BalancesEmptyData l={ props.hasData } />);
  }

  return (
    <div className="wrapper-highchart">
      <ReactHighcharts { ...props } />
    </div>
  );
};

export default Chart;

import React, { useState } from 'react';
import Modal from '../../utilities/Modal.jsx';
import TextField from '../../utilities/TextField.jsx';
import SelectField from "../../utilities/SelectField.jsx";
import { postCoinigyAccountFunds } from '../../../helpers/api/UserApi.js';
import { sanitizeNumberInputs } from '../../../helpers/NumberHelper.js';

export const FiatFundsModal = ({ close, t, currCode, direction = 0, accountId }) => {
  const [ errors, setErrors ] = useState(``);
  const [ amount, setAmount ] = useState(null);
  const [ transferType, setTransferType ] = useState(null);
  const [ wireBankName, setWireBankName ] = useState(null);
  const [ wireBankAddress, setWireBankAddress ] = useState(null);
  const [ wireAccountNumber, setWireAccountNumber ] = useState(null);
  const [ wireRoutingNumber, setWireRoutingNumber ] = useState(null);
  const [ wireSwiftCode, setWireSwiftCode ] = useState(null);
  const [ loading, setLoading ] = useState(false);

  const onConfirm = () => {
    setLoading(true);
    const payload = {
      direction,
      amount: parseFloat(amount),
      transferType,
      wireWithdrawDetails: {
        wireBankName,
        wireBankAddress,
        wireAccountNumber,
        wireRoutingNumber,
        wireSwiftCode
      }
    };
    postCoinigyAccountFunds(accountId, payload).then((data) => {
      if (data.success) {
        close(false);
        setLoading(false);
      } else {       
        let error;
        try {
          error = JSON.parse(data.error);
          setErrors(error?.message);
        } catch (e) {
          setErrors(data.error);
        } finally {
          setLoading(false);
        }
      }
    });
  };
  
  return (
    <div className={ `deposit-modal fiat-deposit-modal }` }>
      <Modal
        title={ `New ` + currCode + (direction === 1 ? ` Deposit` : ` Withdraw`) }
        onConfirm={ onConfirm }
        confirmText={ direction === 1 ? t(`deposit`) : t(`withdraw`) }
        onClose={ () => close(false) }
        displayOnClose={ false }
        confirmDisabled={ !amount || !parseFloat(amount) > 0 || transferType === null || loading }>
        <div className={ `fiat-deposit-form` }>
          <div>   
            <div>
              <TextField 
                label={ t(`Amount`) + ` (`+currCode+`)` }
                name={ `amount` }
                value={ amount }
                type={ `number` }
                hintText={ `Enter the exact amount of ` + currCode + ` you will be sending.` }
                onChange={ (e) => sanitizeNumberInputs(e?.target?.value, setAmount) }/>

              <SelectField
                label={ `Transfer Type` }
                name="transferType"
                value={ transferType }
                onChange={ (e, v) => setTransferType(v) }
                options={ [
                  {
                    label: `ACH`,
                    value: 0
                  },
                  {
                    label: `WIRE`,
                    value: 1
                  },
                  {
                    label: `DEBIT`,
                    value: 2
                  }
                ] } />

              <TextField 
                label={ t(`Wire Bank Name`) }
                name={ `wireBankName` }
                value={ wireBankName }
                onChange={ (e) => setWireBankName(e?.target?.value) }/>                      
              
              <TextField 
                label={ t(`Wire Bank Address`) }
                name={ `wireBankAddress` }
                value={ wireBankAddress }
                onChange={ (e) => setWireBankAddress(e?.target?.value) }/>

              <TextField 
                label={ t(`Wire Account Number`) }
                name={ `wireAccountNumber` }
                value={ wireAccountNumber }
                onChange={ (e) => setWireAccountNumber(e?.target?.value) }/>

              <TextField 
                label={ t(`Wire Routing Number`) }
                name={ `wireRoutingNumber` }
                value={ wireRoutingNumber }
                onChange={ (e) => setWireRoutingNumber(e?.target?.value) }/>

              <TextField 
                label={ t(`Wire Swift Code`) }
                name={ `wireSwiftCode` }
                value={ wireSwiftCode }
                onChange={ (e) => setWireSwiftCode(e?.target?.value) }/>
            </div>
          </div>
          {
            errors &&
                <div className="row errors-message-row">
                  { errors }
                </div>
          }
        </div> 
      </Modal>
    </div>
  );
};

'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path fill-rule="evenodd" clip-rule="evenodd" d="M3 6.5C3 5.67157 3.67157 5 4.5 5H19.5C20.3284 5 21 5.67157 21 6.5C21 7.32843 20.3284 8 19.5 8H4.5C3.67157 8 3 7.32843 3 6.5ZM3 12.5C3 11.6716 3.67157 11 4.5 11H19.5C20.3284 11 21 11.6716 21 12.5C21 13.3284 20.3284 14 19.5 14H4.5C3.67157 14 3 13.3284 3 12.5ZM4.5 17C3.67157 17 3 17.6716 3 18.5C3 19.3284 3.67157 20 4.5 20H19.5C20.3284 20 21 19.3284 21 18.5C21 17.6716 20.3284 17 19.5 17H4.5Z" fill="currentColor" fill-opacity="0.6"/>`,
  {
    width: `24px`,
    height: `24px`,
    viewBox: `0 0 24 24`
  }
);
/* eslint-enable */

// @flow
'use strict';

import React from 'react';

type Props = {
  useSpanInsteadOfButton?: boolean,
  children?: any,
  type?: string,
  disabled?: boolean,
  onClick: (e: any) => boolean,
  customProps?: any,
  className?: string
};

const Button = ({ 
  useSpanInsteadOfButton, 
  children, 
  type, 
  disabled, 
  onClick, 
  customProps = { }, 
  className = ``
}: Props) => {
  let props: any = {
    onClick: onClick
  };

  let valid_types = [
    `primary`, 
    `confirm`, 
    `cancel`, 
    `secondary`, 
    `ghost`, 
    `ghost active`, 
    `ghost pull`, 
    `ghost active pull`
  ];

  if (!type || valid_types.indexOf(type) == -1) type = `primary`;

  props.className = `button ${ type } ${ className }`;

  if (disabled) {
    props.disabled = `disabled`;
  }

  props = {
    ...props,
    ...customProps
  };

  if (useSpanInsteadOfButton) return (
    <span { ...props }>
      <span>
        { children }
      </span>
    </span>
  );

  return (
    <button { ...props }>
      <span>
        { children }
      </span>
    </button>
  );
};

export default Button;

// @flow
'use strict';

const updateTitle = (title: string, platformId: number) => {
  const favicon: any = document.querySelector(`[rel=icon]`);
 
  const platformTitle = platformId == 1 ? `Lightspeed Crypto`: `Coinigy`;
  const browserIcon = platformId == 1 ? `https://www.coinigy.com/assets/img/platform/LightspeedCrypto-favicon.ico`: `/assets/img/favicon.png`;
  
  document.title = (title.length > 0) ?
    `${ title } | ${platformTitle}` : platformTitle;

  if(favicon) {
    favicon.href = browserIcon;
  }
};

export { updateTitle };

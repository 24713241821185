// @flow
'use strict';

import React from 'react';
import Book from "../../../svgs/Book.jsx";

type MenuLinkProps = {
  text: string,
}

export const FAQsLink = (props: MenuLinkProps) => {
  const { text } = props;

  return (
    <a
      href="https://lightspeedcrypto.com/en/learning/faqs/"
      rel="noopener noreferrer"
      target="_blank">
      { Book }
      <span>
        { text }
      </span>
    </a>
  );
};

// @flow
'use strict';

import React, { useState } from 'react';
import { getActiveTheme } from '../../helpers/ThemeHelper.js';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
HighchartsMore(Highcharts);

type Props = {
  title: string,
  value: number,
  score: number,
  lowThreshold: number,
  highThreshold: number,
  info: string
}

const SentimentGauge = (props: Props) => {
  const { title, value, score, lowThreshold, highThreshold, info } = props;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const theme = getActiveTheme(undefined, true);

  const getConfig = () => {
    return ({
      chart: {
        type: `gauge`,
        //zoomType: `x`,
        backgroundColor: `transparent`,
        margin: [1, 0, 1, 0],
        padding: 0,
        borderWidth: 0,
        spacing: [0, 0, 0, 0],
        width: 190,
        height: 120
      },
      credits: { enabled: false },
      pane: {
        startAngle: -90,
        endAngle: 90,
        background: [{
          backgroundColor: `transparent`,
          borderWidth: 0,
          innerRadius: `10%`,
          outerRadius: `93%`,
          shape: `arc`
        }],
        
      },
      plotOptions: {
        gauge: {
          animation: false,
          dataLabels: { enabled: false },
          dial: {
            backgroundColor: theme[`--body-text-color-adjust-40-alt`],
            baseWidth: 1,
            radius: `80%`,
            rearLength: `0%`,
            topWidth: 1
          },
          pivot: {
            backgroundColor: theme[`--body-text-color-adjust-40-alt`],
            borderColor: theme[`--body-text-color-adjust-40-alt`],
            borderWidth: 0,
            radius: `6`
          }
        }
      },
      title: { text: `` },
      tooltip: { enabled: false },
      yAxis: {
        min: 0,
        max: 1,
        minorTickLength: 0,
        tickLength: 0,
        tickPositions: [ 0.15, 0.85 ],
        lineWidth: 0,
        labels: {
          distance: 25,
          style: {
            color: theme[`--body-text-color`]
          },
          formatter: function(option) { return option.value < 0.5 ? `<span class="left-label">Bearish</span>` : `<span class="right-label">Bullish</span>`; }
        },
        plotBands: [
          { from: 0.00, to: lowThreshold, thickness: 3, color: theme[`--red-`] },
          { from: lowThreshold+0.01, to: highThreshold-0.01, thickness: 3, color: theme[`--brand-blue--adjust-10`] },
          { from: highThreshold, to: 1.00, thickness: 3, color: theme[`--green-`] }
        ]
      },
      series: [ { _colorIndex: 0, _symbolIndex: 0, name: `sentiment`, data: [score] } ],
      domProps: {}
    });
  };

  return (
    <Popover
      isOpen={ isPopoverOpen }
      positions={ [`top`] }
      content={ ({ position, childRect, popoverRect }) => (
        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
          position={ position }
          childRect={ childRect }
          popoverRect={ popoverRect }
          arrowColor={ `var(--gray-1)` }>
          <div className={ `popover-content` }>
            { info }
          </div>
        </ArrowContainer>
      ) }>
      <div
        className="signal"
        onMouseEnter={ () => setIsPopoverOpen(true) }
        onMouseLeave={ () => setIsPopoverOpen(false) }>
        <div className="title">
          
            
              
          { title }
              
            
          
        </div>
        <div className="data">
          <div className="signal-value-block">
            
            <span className={ 
              `signal-value-text ${score < lowThreshold ? `signal-down` : ``} 
              ${score > highThreshold ? `signal-up` : ``} 
              ${score > lowThreshold && score < highThreshold ? `signal-neutral` : ``}` }>
              { score < lowThreshold ? `Bearish` : `` }
              { score > highThreshold ? `Bullish` : `` }
              { score > lowThreshold && score < highThreshold ? `Neutral` : `` }
            </span>
            <span className={ `signal-value ${score < lowThreshold ? `signal-down` : ``} 
            ${score > highThreshold ? `signal-up` : ``} 
            ${score > lowThreshold && score < highThreshold ? `signal-neutral` : ``}` }>
              { `${(value*100).toFixed(2)}%` }
            </span>
          </div>
          <div className={ `hc-wrapper` }>
            <HighchartsReact options={ getConfig() } />
          </div>
        </div>
      </div>
    </Popover>
  );
};

export default SentimentGauge;

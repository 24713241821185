// @flow
'use strict';

import { UPDATE_FAVORITES } from '../index.js';
import type { Favorite } from '../../types/Favorite.js';

export function updateFavorites(favorites: Array<Favorite>) {
  return {
    type: UPDATE_FAVORITES,
    payload: { favorites }
  };
}

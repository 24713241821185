// @flow
'use strict';

/**
 * It returns the index of the active market in the visibleMarkets array
 * @param props - any - this is the props object that is passed to the component.
 * @returns activeMarketIndex
 */

import { useEffect, useState } from 'react';

export const useActiveMarketIndex = (props: any) => {
  const [ activeMarketIndex, setActiveMarketIndex] = useState(-1);
  const { visibleMarkets, activeExchMarket } = props;

  useEffect(() => {
    const index = visibleMarkets.findIndex((market) => market.exchmktId === activeExchMarket.market.exchmktId);
    if (typeof index == `undefined`) {
      return setActiveMarketIndex(-1);
    } else {
      setActiveMarketIndex(index);
    }

  }, [visibleMarkets, activeExchMarket]);

  return activeMarketIndex;
};

'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default (uniqueID = `CollapseLeft` ) => SvgHelper(
  `<path d="M1 3C1 1.89543 1.89543 1 3 1H5V15H3C1.89543 15 1 14.1046 1 13V3Z" fill="currentColor"/>
  <path d="M14 8L8 8M8 8L11 5M8 8L11 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>`,
  {
    width: `16px`,
    height: `16px`,
    viewBox: `0 0 16 16`
  }
);
/* eslint-enable */

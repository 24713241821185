// @flow
import SvgHelper from "../helpers/SvgHelper.jsx";

/* eslint-disable */
export default () => SvgHelper(
  `<path 
       d="M16 2h-1V0h-2v2H5V0H3v2H2C.9 2 0 2.9 0 4v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 16H2V7h14v11z" 
       fill="#FFF" 
       fill-rule="evenodd" fill-opacity=".54"/>`,
  {
    width: `1.8rem`,
    height: `2rem`,
    viewBox: `0 0 18 20`
  }
);
/* eslint-enable */


// @flow
'use strict';

import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useVirtual } from 'react-virtual';
import Crypt from './Crypt.jsx';
import { setTheCryptFilters } from '../../actions/app/setTheCryptFilters.js';
import TextField from '../utilities/TextField.jsx';
import ProgressSpinner from '../utilities/ProgressSpinner.jsx';
import SelectField from '../utilities/SelectField.jsx';
import Search from '../../svgs/Search.jsx';
import TheCrypt from '../../svgs/TheCrypt.jsx';
import ImportExportIcon from '@mui/icons-material/ImportExport';

const CRYPT_ITEM_WIDTH = 150;

const SORT_OPTIONS = [
  {
    value: `0-daysDead`,
    label: `▲ Days Dead`,
  },
  {
    value: `1-daysDead`,
    label: `▼ Days Dead`,
  },
  {
    value: `0-daysActive`,
    label: `▲ Days Active`,
  },
  {
    value: `1-daysActive`,
    label: `▼ Days Active`,
  },  
  {
    value: `0-currName`,
    label: `▲ Alphabetical A-Z`,
  },
  {
    value: `1-currName`,
    label: `▼ Alphabetical Z-A`,
  },
];

const CryptList = ({ currencies, isLoading, isFullAccess }: any) => {
  const [filterText, setFilterText] = useState(``);
  const [sort, setSort] = useState(`1-daysActive`);
  const [contentBox, setContentBox] = useState({});
  const [crypts, setCrypts] = useState([]);

  const inlineSize  = useRef(0);
  const refListContainer = useRef();

  const filters = useSelector((state) => state.app.theCryptFilters);
  const dispatch = useDispatch();
  const dispatchSetTheCryptFilters = (o: any) => dispatch(setTheCryptFilters(o));

  const chunkCurrencies = () => {
    if (currencies.length) {

      const [sortOrder, sortType] = sort.split(`-`);

      const flat = currencies
        .flatMap((c) => c)
        .filter((c) => 
          c?.currName?.toLowerCase?.()?.indexOf(filterText.toLowerCase()) > -1 ||
          c?.currCode?.toLowerCase?.()?.indexOf(filterText.toLowerCase()) > -1
        )
        .sort((a, b) => {
          if (a[sortType] > b[sortType]) return parseInt(sortOrder) ? -1 : 1;
          if (b[sortType] > a[sortType]) return parseInt(sortOrder) ? 1 : -1;
          return 0;
        });

      const size = Math.round(inlineSize.current / CRYPT_ITEM_WIDTH);
      if (size) {
        const chunk = Array.from({ length: Math.ceil(flat.length / size) }, (v, i) =>
          flat.slice(i * size, i * size + size)
        );
  
        setCrypts(chunk);
      }
    } else {
      setCrypts([]);
    }
  };

  useEffect(() => {
    const element = refListContainer?.current;
  
    if (!element) return;
    
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        // $FlowIgnore: suppressing this error
        if (entry?.contentBoxSize) {
          setContentBox(entry.contentBoxSize[0]);
        }
      }
    });
  
    observer.observe(element);
  
    return () => {
      // Cleanup the observer by unobserving all elements
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    const id = setTimeout(() => {
      if (contentBox?.inlineSize) {
        inlineSize.current = contentBox?.inlineSize;
        chunkCurrencies();
      }
    } , 500);

    return () => clearTimeout(id);
  }, [filterText, contentBox, currencies, sort]);

  useEffect(() => {
    if (filters?.sort !== undefined && filters.sort !== sort) setSort(filters.sort);
  }, [filters]);


  const rowVirtualizer = useVirtual({
    parentRef: refListContainer,
    size: crypts.length,
    overscan: 5,
  });
    
  const { virtualItems, totalSize } = rowVirtualizer;

  return (
    <div>
      <div className='header'>
        <h1 className="page-title">
          <span className='logo'>
            { TheCrypt }
          </span>
          { `The Crypt` }
        </h1>
        
        <div className='nav-holder'>     
          <div className={ `sort` }>
            <SelectField
              searchable={ false }
              label={ `` }
              name={ `sort` }
              value={ sort }
              options={ SORT_OPTIONS }
              hideValue
              hideCaret
              icon={ <ImportExportIcon sx={ { fontSize: `3rem`, cursor: `pointer` } }/> }
              onChange={ (e, v) => dispatchSetTheCryptFilters({ 'sort': v }) }
              disabled={ !isFullAccess }/>
          </div>
         
          <div className="search">
            <TextField
              icon={ Search }
              label={ `Cryptocurrency name or symbol` }
              name={ `search` }
              value={ filterText }
              onChange={ (e) => setFilterText(e.target.value) }
              onDoubleClick={ () => setFilterText(``) }
              readOnly={ !isFullAccess } />
          </div>
        </div>
      </div>
      
      <div ref={ refListContainer } className='the-crypt-table'>
        <div
          style={ {
            height: `${totalSize}px`,
            width: `100vw`,
            position: `relative`
          } }>
          { isLoading && <div className='spinner-container'>
            <ProgressSpinner/> 
          </div> }
          
          { virtualItems.map((vi) => (
            <ul
              key={ vi.index } 
              ref={ vi.measureRef }
              className='row' 
              style={ { transform: `translateY(${vi.start}px)` } }>
              {
                crypts[vi.index].map((c) => 
                  <Crypt 
                    key={ `${vi.index}-${c.currCode}` } 
                    currency={ c }
                    isClickable={ isFullAccess }/>
                )
              }
            </ul>
          )) }
        </div>
      </div>
    </div>
  );
};

export default CryptList;

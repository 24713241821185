// @flow
`use strict`;

import { $ } from "./ApiHelper";

export function getAccounts_v1(onSuccess: (data: any) => void) {
  $({
    url: `/getjson/get_current_user_api_keys`,
    version: `v1`,
    success: onSuccess
  });
}

export function getAccounts_v2(onSuccess: (data: any) => void) {
  $({
    url: `/private/user/accounts`,
    success: onSuccess
  });
}


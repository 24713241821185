'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path class="play" d="M4 3.222v9.558l7.434-4.779L4 3.222zM3.694 1.44l9.333 6a.667.667 0 0 1 0 1.122l-9.333 6a.667.667 0 0 1-1.027-.56v-12a.667.667 0 0 1 1.027-.562z"/>`,
  {
    width: `1.3rem`,
    height: `1.4rem`,
    viewBox: `0 0 13 14`
  }
);
/* eslint-enable */

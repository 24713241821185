// @flow
'use strict';

import React from 'react';
import { useSelector } from 'react-redux';
import { translate } from 'react-i18next';
import Modal from '../utilities/Modal.jsx';

type Props = {
  t: any,
  label: string,
  confirm: () => void,
  close: () => void,
  orderText: string,
};

const ConfirmOrderModal = (props: Props) => {
  const { t, confirm, close, orderText } = props;
  const platformId = useSelector((state) => state.userInfo.user.platformId);
  
  const RenderModalSubtitle =() => {
    return <p className="modal-subtitle">
      { t(`orders:pleaseConfirmOrder`) }
    </p>;
  };

  const RenderModalLink =() => {
    return platformId == 1 && <p className="modal-link">
      <a href="https://lightspeedcrypto.com/en/pricing" target="_blank" rel="noopener noreferrer">
        { t(`orders:learnMore`) }
      </a>
    </p>;
  };
  
  return (
    <Modal
      title={ t(`app:genericConfirm`) }
      onConfirm={ confirm }
      onClose={ close }
      confirmText={ t(`app:confirm`) }
      cancelText={ t(`app:cancel`) }
      confirmDisabled={ orderText === undefined }>
      <RenderModalSubtitle />
      { orderText }
      <RenderModalLink />
    </Modal>
  );
};

export { ConfirmOrderModal as PureConfirmOrderModal };
export default translate(`orders`)(ConfirmOrderModal);

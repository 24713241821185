// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import Screener from '../../svgs/Screener.jsx';
import ArbMatrix from '../../svgs/ArbMatrix.jsx';
import ReturnsRadar from '../../svgs/ReturnsRadar.jsx';
import TheCrypt from '../../svgs/TheCrypt.jsx';
// import ProBadsge from '../../components/utilities/ProBadge.jsx';

const ProToolsMenu = ({ t, isOpen }: any) => {

  const items = [
    {
      icon: Screener,
      text: t(`screener`),
      route: `/screener`,
      pro: true,
    }, {
      icon: ArbMatrix,
      text: t(`arbMatrix`),
      route: `/arbmatrix`,
      pro: true,
    }, {
      icon: ReturnsRadar,
      text: t(`returnsRadar`),
      route: `/returns-radar`,
      pro: true,
    }, {
      icon: TheCrypt,
      text: t(`theCrypt`),
      route: `/the-crypt`,
      pro: true,
    }
  ];

  return (
    <ul className={ `header-markets-menu ${isOpen ? `show` : ``}` } >
      { items.map((item, i) => 
        <li key={ i }>
          <NavLink to={ item.route } className={ `` } activeClassName="nav-selected">
            { item.icon }
            <span>
              { item.text }
              { /* { item?.pro && <ProBadge /> } */ }
            </span>
          </NavLink>
        </li>
      ) }
    </ul>
  );
};

export default translate(`header`)(ProToolsMenu);

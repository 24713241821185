// @flow
'use strict';

import React from 'react';
import { TableFilterList } from "mui-datatables";
import Chip from '@mui/material/Chip';

export type Props = {
  t: any,
  filterList: any,
  columnNames: any,
  myPositions: boolean,
  miningAlgorithm: string,
  miningType: string,
  exchangeTags: string,
  rsi7Min: any,
  rsi14Min: any,
  rsi7Hour: any,
  rsi14Hour: any,
  rsi7Day: any,
  rsi14Day: any,
  ignoreDead: boolean,
  percentBuys: any,
  activityScore: any,
  changeSettings: (obj: any) => void,
  reset: () => void,
  favoriteMarkets: boolean,
}

const ScreenerFilterList = (props: Props) => { 
  const { t, filterList, columnNames, myPositions, miningAlgorithm, miningType, exchangeTags, changeSettings, reset,
    rsi7Min, rsi14Min, rsi7Hour, rsi14Hour, rsi7Day, rsi14Day, ignoreDead, percentBuys, activityScore, favoriteMarkets } = props;

  const getColumnIndex = (columnName) => {
    return columnNames.findIndex((c) => c.name === columnName);
  };

  const rsiOptions = [
    { label: `${t(`screener:less`)}`, value: `<=` },
    { label: `${t(`screener:greater`)}`, value: `>=` },
  ];

  const getRsiConditionName = (condition: string) => rsiOptions.find((r) => r.value === condition)?.label || condition;

  filterList[getColumnIndex(`position`)] = myPositions ? [`${t(`screener:myPositions`)}`] : [];
  filterList[getColumnIndex(`miningAlgorithm`)] = miningAlgorithm ? [`${t(`screener:miningAlgorithm`)}: ${miningAlgorithm}`] : [];
  filterList[getColumnIndex(`miningType`)] = miningType ? [`${t(`screener:miningType`)}: ${miningType} `] : [];
  filterList[getColumnIndex(`exchange`)] = exchangeTags ? [`${t(`screener:exchangeTags`)}: ${exchangeTags}`] : [];
  filterList[getColumnIndex(`rsi7Min`)] = rsi7Min?.value && rsi7Min?.condition ? [`RSI (7, Min) ${ getRsiConditionName(rsi7Min.condition) } ${rsi7Min.value}`] : [];
  filterList[getColumnIndex(`rsi14Min`)] = rsi14Min?.value && rsi14Min?.condition ? [`RSI (14, Min) ${ getRsiConditionName(rsi14Min.condition) } ${rsi14Min.value}`] : [];
  filterList[getColumnIndex(`rsi7Hour`)] = rsi7Hour?.value && rsi7Hour?.condition ? [`RSI (7, Hour) ${ getRsiConditionName(rsi7Hour.condition) } ${rsi7Hour.value}`] : [];
  filterList[getColumnIndex(`rsi14Hour`)] = rsi14Hour?.value && rsi14Hour?.condition ? [`RSI (14, Hour) ${ getRsiConditionName(rsi14Hour.condition) } ${rsi14Hour.value}`] : [];
  filterList[getColumnIndex(`rsi7Day`)] = rsi7Day?.value && rsi7Day?.condition ? [`RSI (7, Day) ${ getRsiConditionName(rsi7Day.condition) } ${rsi7Day.value}`] : [];
  filterList[getColumnIndex(`rsi14Day`)] = rsi14Day?.value && rsi14Day?.condition ? [`RSI (14, Day) ${ getRsiConditionName(rsi14Day.condition) } ${rsi14Day.value}`] : [];
  filterList[getColumnIndex(`market`)] =  [!ignoreDead ? `${t(`screener:showInactiveMarkets`)}` : null, favoriteMarkets ? `${t(`screener:favoriteMarkets`)}` : null ].filter((s) => s);
  filterList[getColumnIndex(`percentBuys`)] = percentBuys?.value && percentBuys?.condition ? [`% Buys ${ getRsiConditionName(percentBuys.condition) } ${percentBuys.value}`] : [];
  filterList[getColumnIndex(`activityScore`)] = activityScore?.value && activityScore?.condition ? [`${t(`screener:activityScore`)} ${ getRsiConditionName(activityScore.condition) } ${activityScore.value}`] : [];

  const handleDelete = (idx: number, label: string = ``) => {
    const columnName = columnNames[idx].name;

    switch (columnName) {
    case `exchange`:
      changeSettings({ 'exchangeTags': `` });
      break;
    case `miningAlgorithm`:
      changeSettings({ 'miningAlgorithm': `` });
      break;
    case `miningType`:
      changeSettings({ 'miningType': `` });
      break;
    case `position`:
      changeSettings({ 'myPositions': false });
      break;
    case `rsi7Min`:
      changeSettings({ 'rsi7Min': null });
      break;
    case `rsi14Min`:
      changeSettings({ 'rsi14Min': null });
      break;
    case `rsi7Hour`:
      changeSettings({ 'rsi7Hour': null });
      break;
    case `rsi14Hour`:
      changeSettings({ 'rsi14Hour': null });
      break;
    case `rsi7Day`:
      changeSettings({ 'rsi7Day': null });
      break;
    case `rsi14Day`:
      changeSettings({ 'rsi14Day': null });
      break;
    case `market`:
      if (label === t(`screener:showInactiveMarkets`)) {
        changeSettings({ 'ignoreDead': true });
      } else {
        changeSettings({ 'favoriteMarkets': false });
      }
      break;
    case `percentBuys`:
      changeSettings({ 'percentBuys': null });
      break;
    case `activityScore`:
      changeSettings({ 'activityScore': null });
      break;                        
    }

    reset();
  };

  const customChip = ({ index, label }) => {
    return (
      <Chip
        color="primary"
        label={ label }
        onDelete={ () => handleDelete(index, label) } />
    );
  };

  return <TableFilterList { ...props } ItemComponent={ customChip } />;
};

export default ScreenerFilterList;

// @flow
'use strict';

import { SELECT_QUOTE_CURRENCY_CODE } from '../index.js';

export function selectQuoteCurrencyCode(payload: string) {
  return {
    type: SELECT_QUOTE_CURRENCY_CODE,
    payload
  };
}


// @flow
'use strict';

import React from 'react';
import { connect } from 'react-redux';
import type { Market } from '../../types/Market.js';
import type { Exchange } from '../../types/Exchange.js';
import type { Order } from '../../types/Order.js';
import type { Alert } from '../../types/Alert.js';
import TradingViewChart from './TradingViewChart.jsx';
import { getMarketPair } from '../../helpers/MarketPairHelper.js';
import { getActiveTheme } from '../../helpers/ThemeHelper.js';
import * as jstz from 'jstz';

type Props = {
  active: {
    market: Market,
    exchange: Exchange
  },
  user_hash: string,
  tvcOpenOrders: Array<Order>,
  tvcOrderHistory: Array<Order>,
  activeAlerts: Array<Alert>,
  bid: number,
  ask: number,
  autoSaveDisabled: boolean,
  chartClass: string,
  showOrderHistory: boolean,
  showOpenOrders: boolean,
  showCurrentAskBidLine: boolean,
  overrideChartTheme: boolean,
  resolution: string,
  theme: string,
  platformId: number,
  showCustomButtons?: boolean,
  panelId?: number
};

const getUserTimezone = () => {
  return jstz.determine().name() === undefined ? `UTC` : jstz.determine().name();
}; 

const MarketChart = ({ 
  active, 
  activeAlerts, 
  bid, 
  ask,
  tvcOpenOrders, 
  tvcOrderHistory, 
  user_hash,
  autoSaveDisabled,
  chartClass,
  showOrderHistory,
  showOpenOrders,
  showCurrentAskBidLine,
  overrideChartTheme,
  resolution,
  theme,
  platformId,
  showCustomButtons = true,
  panelId
}: Props) => {

  const convertToURLParams = (params: any) => {
    return `?${ Object.keys(params)
      .map((opt: string) => {
        return `${ opt }=${ encodeURIComponent(params[opt]) }`;
      }).join(`&`) }`;
  };

  const params = {
    version: 6,
    symbol: active?.market?.exchCode + `:` + getMarketPair(active?.market)?.toString?.(),
    user_hash: user_hash,
    order_history: showOrderHistory,
    open_orders: showOpenOrders,
    ask_bid: showCurrentAskBidLine,
    NautoSaveDisabled: autoSaveDisabled,
    userTimezone: getUserTimezone(),
    overrideChartTheme: overrideChartTheme,
    resolution: resolution,
    platformId: platformId,
    showCustomButtons: showCustomButtons,
    panelId: panelId,
    ...getActiveTheme(theme)
  };
  
  return (
    <div className={ `market-chart ${ chartClass }` } style={ { height: `100%`, overflow: `hidden` } }>
      <TradingViewChart
        iframeSrc={ `/chart.html${ convertToURLParams(params) }` }
        market={ active.market }
        user_hash={ user_hash }
        openOrders={ tvcOpenOrders }
        orderHistory={ tvcOrderHistory }
        activeAlerts={ activeAlerts }
        bid={ bid }
        ask={ ask }
        autoSaveDisabled={ autoSaveDisabled } 
        panelId={ panelId }/>
    </div>
  );
};

const mapStateToProps = (state) => ({
  theme: state.redisPrefs.theme,
  user_hash: state.userInfo.user_hash,
  autoSaveDisabled: !!state.redisPrefs.autoSaveDisabled,
  showOrderHistory: state.redisPrefs.showOrderHistory,
  showOpenOrders: state.redisPrefs.showOpenPositions,
  showCurrentAskBidLine: state.redisPrefs.showCurrentAskLine,
  overrideChartTheme: state.redisPrefs.overrideChartTheme,
  resolution: state.markets.resolution,
  platformId: state.userInfo.user.platformId,
});

export default connect(mapStateToProps)(MarketChart);

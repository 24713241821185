'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';



/* eslint-disable */
const LogoMark = [
  // Coinigy Icon
SvgHelper(
  `<path class="logo-mark" d="M174.83 105.55c-9.16 0-18.32-.1-27.48 0a19.63 19.63 0 0 0-19.75 19.69q-.19 27.24 0 54.48a19.62 19.62 0 0 0 19.76 19.67c17.82.1 35.65 0 53.48 0 13.78 0 19.68 6 19.7 19.91v42.49c0 13.77-6 19.66-19.93 19.68s-28 0-42 0c-14.32 0-20.15-5.78-20.17-20v-47c-.05-12.68-8.27-21-20.94-21-17.49-.08-35 0-52.48 0-13.48 0-19.53-6.07-19.56-19.55v-43c0-13.47 6.08-19.52 19.57-19.54h52c13.26 0 21.4-8.2 21.43-21.57V43.33c0-13.95 5.9-19.92 19.68-19.94h43c13.21 0 19.38 6.13 19.42 19.2q.06 22 0 44-.08 18.78-18.71 18.89c-9.02.12-18.02.06-27.02.07z"/>`,
  {
    width: `26.7rem`,
    height: `30rem`,
    viewBox: `0 0 267 300`
  }
),
// Lightspeed Icon
SvgHelper(
  `<path d="M53.93,203.83C59.1,182.1,64,161.41,69.1,140.13H9C18.35,97.71,27.6,55.94,36.79,14.48H99.26c-10,41.9-19.91,83.19-29.89,124.9H210l.28,1Z" transform="translate(-8.95 -14.48)" />`,
  {
    width: `26.7rem`,
    height: `30rem`,
    viewBox: `0 0 216 216`
  }
),
// Oanda Icon
SvgHelper(
  `<path class="logo-mark" d="M115,.6c3.76.57,7.53,1.13,11.29,1.72,44.61,7.07,81.2,43.71,88.18,88.43,3.9,25-.15,48.61-12.37,70.78-.52.95-1.09,1.87-1.85,3.16l-13.49-16.05L92,35.69c-4.35-5.19-8.77-10.32-13-15.6a4.78,4.78,0,0,0-4.28-2c-7.83.09-15.66,0-23.49,0l-.36-1.05c6-2.89,11.88-6,18-8.62A99.59,99.59,0,0,1,99.69,1.15,6,6,0,0,0,101.23.6Z" transform="translate(-1.02 -0.6)" /><path d="M100.64,216.6c-4.63-.78-9.27-1.52-13.89-2.35-9.44-1.71-9.43-1.73-9.43-11.32q0-56.35,0-112.7c0-.38,0-.75.12-1.66C108.17,125,138.59,161.14,169.6,198c-6.91,3.54-13.35,7.2-20.07,10.19a97.85,97.85,0,0,1-31.86,7.89c-.32,0-.62.36-.92.55Z" transform="translate(-1.02 -0.6)"/><path d="M31.4,183.74C-10.06,142.49-8.14,72,31.4,34.14Z" transform="translate(-1.02 -0.6)" />`,
  {
    width: `26.7rem`,
    height: `30rem`,
    viewBox: `0 0 216 216`
  }
)
];


export default LogoMark;
/* eslint-enable */

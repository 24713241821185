// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { getMarketFromPanelSettings } from '../../helpers/MarketPairHelper.js';
import StreamingTradesList from '../markets/StreamingTradesList.jsx';
import type { Market } from '../../types/Market.js';
import type { Exchange } from '../../types/Exchange.js';

type Settings = {
  exchange: string,
  market: string
};

type Props = {
  t: any,
  zeroStyle: string,
  market: Market,
  exchanges: Array<Exchange>,
  settings: Settings, 
  panelData: any,
  limitDeviation: boolean,
  theme: string
};

const StreamingTradesPanel = ({ t, zeroStyle, market, settings, panelData, exchanges }: Props) => {
  const mkt = `${ settings.exchange }:${ settings.market }`;
  if (
    panelData.hasOwnProperty(mkt) && 
    panelData[mkt].trades &&
    panelData[mkt].trades.length > 0 && 
    market
  ) {
    return (
      <div className="market-recent-trades-panel">
        <StreamingTradesList
          trades={ panelData[mkt].trades }
          market={ market }
          zeroStyle={ zeroStyle } 
          exchanges={ exchanges }/>
      </div>
    );
  } else {
    return (
      <div className="panel-loading">
        <span>
          { t(`app:loading`) }
        </span>
      </div>
    );
  }
};

StreamingTradesPanel.getPanelTitle = (settings: Settings, markets: Array<Market>) => {
  let market = getMarketFromPanelSettings(markets, settings);

  return market ? market.exchName + ` - ` + market.displayName : `Market Recent Trades`;
};

const mapStateToProps = (state) => ({
  zeroStyle: state.redisPrefs.zeroStyle
});

export default translate(`boards`)(connect(mapStateToProps)(StreamingTradesPanel));

// @flow
'use strict';

import React from 'react';
import { connect } from 'react-redux';
import Modal from '../utilities/Modal.jsx';
import { trimString } from "../../helpers/StringHelper.js";
import { emitEvent, REFRESH_ACCOUNTS, REFRESH_BALANCE, NOTIFICATION } from '../../helpers/EventHelper.js';
import { updateAccount } from '../../helpers/api/UserApi.js';
import { refreshBalanceV2 } from '../../helpers/api/BalanceApi.js';
import type { Exchange } from '../../types/Exchange.js';
import EntityTable from '../utilities/EntityTable.jsx';
import TextField from '../utilities/TextField.jsx';
import Button from '../utilities/Button.jsx';
import LogoMark from '../../svgs/LogoMark.jsx';

type Props = {
  t: any,
  exchanges: Array<Exchange>,
  account: any,
  close: () => void,
  platformId: number
};

type State = {
  activeExchName: string,
  activeExchCode: string,
  activeTutorialUrl: string,
  activeOptionalRequired: boolean,
  activeOptionalFieldName: string,
  activeOptionalFieldDesc: string,
  activeHasWhitelisting: boolean,
  currentStepKey: number,
  accountNickname: string,
  currentAccountNickname: string,
  apiKey: string,
  secretKey: string,
  optionalKeys: string,
  currentStepWallet: number,
  activeWallet: string,
  activeWalletCurrName: string,
  activeWalletFieldName: string,
  walletNickname: string,
  walletPublicKey: string,
  lastAuthId: number,
  isTesting: boolean,
  testingFailed: boolean,
  editingNickname: boolean
};

class EditAccountModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      activeExchName: ``,
      activeExchCode: ``,
      activeTutorialUrl: `https://youtube.com/coinigy`,
      activeOptionalRequired: false,
      activeOptionalFieldName: ``,
      activeOptionalFieldDesc: ``,
      activeHasWhitelisting: true,
      currentStepKey: -1,
      accountNickname: ``,
      currentAccountNickname: ``,
      apiKey: ``,
      secretKey: ``,
      optionalKeys: ``,
      currentStepWallet: -1,
      activeWallet: ``,
      activeWalletCurrName: ``,
      activeWalletFieldName: ``,
      walletNickname: ``,
      walletPublicKey: ``,
      lastAuthId: -1,
      isTesting: false,
      testingFailed: false,
      editingNickname: false
    };
  }
  

  handleInputChange(key: ?string, value: any) {
    if (key == `accountNickname`) value = value.replace(/,/g, ``);
    if (key) {
      this.setState({
        [key]: value
      });
    } else if (typeof value === `object`) {
      this.setState(value);
    }

  }

  startNicknameEditMode() {
    if(this.state.editingNickname == false){
      this.setState({ editingNickname: true });
      this.refs[`NicknameField`].refs.input.
        setSelectionRange(this.state.accountNickname.length, this.state.accountNickname.length);
    }
  }

  endNicknameEditMode() {
    const isLSCX = this.state.activeExchCode == `LSCX`;
    this.setState({ editingNickname: false });
    if(this.state.currentAccountNickname != this.state.accountNickname && this.state.accountNickname.length > 0){
      new Promise((resolve, reject) => {
        updateAccount(this.props.account.authId,
          {
            apiNickname: this.state.accountNickname
          }, (d) => {
            if (d.success) {
              resolve();
              emitEvent(REFRESH_ACCOUNTS);
              this.setState({ currentAccountNickname: this.state.accountNickname });
              isLSCX && this.props.close();
            }
          }, () => {
            reject(`An error occurred while attempting to update your API key. ` +
        `If this continues, please contact support. Error: 200134`);
          });
      });
    }
  }

  updateApiAccount(): Promise<void> {
    return new Promise((resolve, reject) => {
      const promiseTimeout = setTimeout(() => {
        reject(new Error(`Promise timed out after 1 minute`));
      }, 60000);

      updateAccount(this.props.account.authId, {
        apiKey: this.state.apiKey,
        apiSecret: this.state.secretKey,
        apiOptional1: this.state.optionalKeys
      }, (d) => {
        if (d.success) {
          this.setState({ lastAuthId: d.result.authId });
        }
        clearTimeout(promiseTimeout);
        resolve();
      }, () => {
        clearTimeout(promiseTimeout);
        reject(`An error occurred while attempting to update your API key. ` +
        `If this continues, please contact support. Error: 200134`);
      });
    });
  }

  updateWalletAccount(): Promise<void> {
    return new Promise((resolve, reject) => {
      updateAccount(this.props.account.authId,
        {
          apiKey: this.state.walletPublicKey,
        }, (d) => {
          if (d.success) {
            this.setState({ lastAuthId: d.result.authId });
            resolve();
          }
        }, () => {
          reject(`An error occurred while attempting to update your API key. ` +
        `If this continues, please contact support. Error: 200134`);
        });
    });
  }


  refreshApiBalance(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      refreshBalanceV2({
        balanceAuthId: this.state.lastAuthId
      }, (d) => {
        if (!d.success) {
          resolve(false);
        } else {
          resolve(true);
        }
      }, () => {
        reject(`An error occurred while attempting to test your API key. ` +
        `If this continues, please contact support. Error: 200151`);
      });
    });
  }


  handleApiKeyCompleteClick() {
    this.setState({ isTesting: true });
    this.updateApiAccount().then(() => {
      this.onAccountUpdated();
    }).catch((err) => {
      emitEvent(NOTIFICATION, {
        notification_id: new Date().getTime(),
        title: `Error`,
        title_vars: ``,
        message_raw: `An error occurred while attempting to update your API key: ${err}`,
        message: `An error occurred while attempting to update your API key: ${err}`,
        message_vars: ``,
        pinned: false,
        style: `error`,
        url: ``
      });
      this.setState({ testingFailed: true });
      this.setState({ isTesting: false });
    });
  }


  handleWalletApiKeyCompleteClick() {
    this.setState({ isTesting: true });
    this.updateWalletAccount().then(() => {
      this.onAccountUpdated();
    }).catch((err) => {
      emitEvent(NOTIFICATION, {
        notification_id: new Date().getTime(),
        title: `Error`,
        title_vars: ``,
        message_raw: `An error occurred while updating the wallet account: ${err}`,
        message: `An error occurred while updating the wallet account: ${err}`,
        message_vars: ``,
        pinned: false,
        style: `error`,
        url: ``
      });
      this.setState({ testingFailed: true });
      this.setState({ isTesting: false });
    });
  }


  onAccountUpdated() {
    this.refreshApiBalance().then((result) => {
      emitEvent(REFRESH_BALANCE);
      emitEvent(REFRESH_ACCOUNTS);

      if (result == true) {
        this.setState({ testingFailed: false });
        this.props.close();
      }else {
        this.setState({ testingFailed: true });
      }
      this.setState({ isTesting: false });
      return false;
    }).catch((err) => {
      emitEvent(NOTIFICATION, {
        notification_id: new Date().getTime(),
        title: `Error`,
        title_vars: ``,
        message_raw: `An error occurred while refreshing your balance: ${err}`,
        message: `An error occurred while refreshing your balance: ${err}`,
        message_vars: ``,
        pinned: false,
        style: `error`,
        url: ``
      });
      this.setState({ testingFailed: true });
      this.setState({ isTesting: false });
    });
  }

  getTutorialUrl() {
    let tutUrl = this.state.activeTutorialUrl;
    return tutUrl.includes(`youtu.be`) 
      ? ( <iframe
        className={ `videoFrame` } 
        width="330" height="190" src={ `https://www.youtube.com/embed/` + 
    tutUrl.split(`https://youtu.be/`)[1] + `?modestbranding=1&showinfo=0&showsearch=0` }
        frameBorder="0" allowFullScreen /> )
      : ( <a href={ tutUrl } target="_blank" rel="noopener noreferrer">
Click here for instructions on how to locate your
        { ` ` }
        { this.state.activeExchName }
        { ` ` }
API keys.
      </a>);
  }

  validateStep2() {
    if (this.state.activeOptionalRequired && this.state.optionalKeys.length == 0) return true;
    if (this.state.accountNickname.length == 0 || this.state.editingNickname) return true;
    if (this.state.apiKey.length == 0) return true;
    if (this.state.secretKey.length == 0) return true;
    return false;
  }


  getKeyData() {
    let { activeExchName, accountNickname, apiKey, optionalKeys } = this.state;

    accountNickname = trimString(accountNickname, 20, true);
    apiKey = trimString(apiKey, 12, true);
    optionalKeys = trimString(optionalKeys, 12, true);
    return [
      {
        activeExchName,
        accountNickname,
        apiKey,
        secretKey: `●●●●●●●●●●`,
        optionalKeys
      }
    ];
  }

  getWalletKeyData() {
    let { activeWallet, walletNickname, walletPublicKey } = this.state;

    walletNickname = trimString(walletNickname, 20, true);
    walletPublicKey = trimString(walletPublicKey, 12, true);

    return [
      {
        activeWallet,
        walletNickname,
        walletPublicKey
      }
    ];
  }

  UNSAFE_componentWillMount() {
    if (this.props.account) {
      if(this.props.account.authExchId == 999){ //wallet accounts
        this.setState({
          accountNickname: this.props.account.authNickname,
          currentAccountNickname: this.props.account.authNickname,
          apiKey: this.props.account.authKey,
          currentStepWallet: 0
        });
        this.handleInputChange(null,
          {
            "activeWallet": this.props.account.authOptional,
            "walletNickname": this.props.account.authNickname,
            "walletPublicKey": this.props.account.authKey
          }
        );
      }else{
        this.setState({
          accountNickname: this.props.account.authNickname,
          currentAccountNickname: this.props.account.authNickname,
          apiKey: this.props.account.authKey,
          currentStepKey: 0
        });
        let accountExch = this.props.exchanges.find((exch) => exch.exchId == this.props.account.authExchId);
        if(accountExch != undefined){
          let activeExchange: Exchange = accountExch;
          this.handleInputChange(null,
            {
              "activeExch": activeExchange.exchId,
              "activeExchName": activeExchange.exchName,
              "activeTutorialUrl": activeExchange.exchTutorialUrl,
              "activeExchCode": activeExchange.exchCode,
              "activeOptionalRequired": activeExchange.optionalRequired,
              "activeOptionalFieldName": activeExchange.optionalFieldName,
              "activeOptionalFieldDesc": activeExchange.optionalFieldDesc,
              "activeHasWhitelisting": activeExchange.exchHasWhitelisting
            }
          );
        }
      }
    }
  }

  setModalTitle() {
    const isLSCX = this.state.activeExchCode == `LSCX`;
    const lscxTitle = this.props.t(`user:updateNickName`);
    const regularTitle = 
      this.state.currentStepKey > -1 ? 
        this.props.t(`user:updateApiKey`) :  
        this.props.t(`user:updateWalletAddress`);
  
    return isLSCX ? lscxTitle : regularTitle;
  }

  customEventButton() {
    if(this.state.activeExchCode == `LSCX`) {
      this.endNicknameEditMode();
    }  else {
      this.handleInputChange(null, { currentStepKey: 1, testingFailed: false });
      this.handleApiKeyCompleteClick();
    }  

    return false;
  }

  render() {
    const isLSCX = this.state.activeExchCode == `LSCX`;

    return (
      <div className="edit-account-modal">
        <Modal
          title={ this.setModalTitle() }
          onClose={ this.props.close }>
          <div className="cryptocurrency">
            <div className="optional-key">
              { this.state.currentStepKey === 0  &&
                (
                  <div>
                    <div className="formSection">
                      <a
                        href={ `https://www.coinigy.com/site/exchange/${ this.state.activeExchCode }` }
                        target="_blank" rel="noopener noreferrer" >
                        <img 
                          className={ `exchImg` }
                          src={ `https://www.coinigy.com/assets/img/exchange/${ this.state.activeExchCode }.png` }
                          alt={ this.state.activeExchName } />
                      </a>
                      <h3 className={ `pull-left` }>
                        {  
                          this.props.t(`user:pleaseEnterYour`) + 
                            this.state.activeExchName +
                            this.props.t(`user:apiInfoBelow`)
                        }
                      </h3>
                      <TextField
                        label={ this.props.t(`user:accountNickname`) }
                        ref="NicknameField"
                        readOnly={ isLSCX ? this.state.editingNickname : !this.state.editingNickname }
                        name={ `accountNickname` }
                        value={ this.state.accountNickname }
                        hintText={ this.props.t(`user:nicknameHint`) }
                        toolTipText={ this.props.t(`user:doubleClickEdit`) }
                        onDoubleClick={ () => { !isLSCX && this.startNicknameEditMode(); } }
                        onBlur={ () => { !isLSCX && this.endNicknameEditMode();}  }
                        onEnter={ () => { !isLSCX && this.endNicknameEditMode();} }
                        onChange={ (e) => this.handleInputChange(`accountNickname`, e.target.value) } />

                      { !isLSCX && 
                          <>
                            <TextField
                              label={ this.props.t(`user:exchangeApiKey`) }
                              name={ `apiKey` }
                              value={ this.state.apiKey }
                              hintText={ this.props.t(`user:publicKeyHint`) }
                              onChange={ (e) => this.handleInputChange(`apiKey`, e.target.value) } />
                            <TextField
                              label={ this.props.t(`user:secretKey`) }
                              name={ `secretKey` }
                              value={ this.state.secretKey }
                              type={ `password` }
                              hintText={ this.props.t(`user:secretKeyHint`) }
                              onChange={ (e) => this.handleInputChange(`secretKey`, e.target.value) } />
                            
                            {
                              this.state.activeOptionalRequired ? (
                                <TextField
                                  label={ this.state.activeOptionalFieldName }
                                  name={ `optionalKeys` }
                                  value={ this.state.optionalKeys }
                                  hintText={ this.state.activeOptionalFieldDesc }
                                  onChange={ (e) => this.handleInputChange(`optionalKeys`, e.target.value) } />
                              ): null
                            }
                          </>
                      }
                    </div>
                    <div className={ isLSCX ? `hide` : `tutorialSection` } >
                      <h3>  
                        { this.props.t(`user:locateApiKeys`) }
                      </h3>
                      {
                        this.getTutorialUrl()
                      }
                      <h3> 
                        { this.props.t(`user:whitelistSettings`) }
                      </h3>
                      <h3>
                        {
                          this.state.activeHasWhitelisting 
                            ? ( <div>
                              <p>
                                {
                                  this.props.t(`user:ipWhitelistingSupported`) 
                                }
                              </p>
                              <p>
                                { 
                                  this.props.t(`user:whitelistIPAddresses`)
                                }
                              </p>
                              <p>
                                {
                                  this.props.t(`user:whitelistIPNote`)
                                }
                              </p>
                            </div> )
                            : 
                            this.props.t(`user:ipWhitelistingNotSupported`)
                        }
                      </h3>
                    </div>
                      
                    <Button
                      type="primary"
                      disabled={ !isLSCX && this.validateStep2() }
                      onClick={ () => this.customEventButton() }>
                      
                      { !isLSCX ?  
                        `${ this.props.t(`app:next`) } (${ this.props.t(`user:verifyConnection`) })` : 
                        `${this.props.t(`user:updateNickName`)}`
                      }
                    </Button>
                  </div>
                )
              }
              { this.state.currentStepKey === 1  &&
                  <div>
                    
                    <div className="testConnection">
                      <h2> 
                        { this.props.t(`user:verifyConnection`) }
                      </h2>
                      <h3>
  Account Information:
                      </h3>

                      <EntityTable
                        columns={ [
                          {
                            title: this.props.t(`app:exchange`),
                            key: `activeExchName`,
                          },
                          {
                            title: this.props.t(`user:accountNickname`),
                            key: `accountNickname`
                          },
                          {
                            title: this.props.t(`user:key`),
                            key: `apiKey`
                          },
                          {
                            title: this.props.t(`user:secretKey`),
                            key: `secretKey`
                          },
                          ...(this.state.optionalKeys.length > 0 ? 
                            [{ title: this.state.activeOptionalFieldName, key: `optionalKeys` }] 
                            : [])                  
                        ] }
                        data={ this.getKeyData() }
                        hidePagination={ true } />

                      <div className={ `connectionAnimation ${ this.state.isTesting ? `fullopacity` : `` }` }>
                        <div className="logo-wrapper">
                          { LogoMark[this.props.platformId ? this.props.platformId : 0] }
                        </div>
                        <div className={ 
                          `anim-wrapper ${ this.state.isTesting ? `animate` : `` }` 
                        }>
                          <div className="lds-ellipsis">
                            <div />
                            <div />
                            <div />
                            <div />
                          </div>
                        </div>
                        <div className="exchange-wrapper">
                          <img
                            className={ `exchange-logo` }
                            src={ `https://www.coinigy.com/assets/img/exchange/${ this.state.activeExchCode }.png` }
                            alt={ this.state.activeExchName } />
                        </div>
                      </div>
                      <div className={ 
                        `contacting ${ !this.state.isTesting ? `dimmed` : `` }` 
                      }>
                        {
                          this.state.isTesting 
                            ? 
                            `${ this.props.t(`user:contacting`)} ${ this.state.activeExchName }...`
                            :
                            ( this.state.testingFailed 
                              ? 
                              this.props.t(`user:testFailed`) 
                              : 
                              this.props.t(`user:readyToTest`))
                        }
                      </div>
                    </div>
                    <div>
                      <Button
                        type={ this.state.testingFailed ? `cancel` : `primary` }
                        disabled={ this.state.isTesting }
                        onClick={ () =>  { 
                          if (this.state.testingFailed)
                          {
                            this.handleInputChange(`currentStepKey`, 0);
                          }else {
                            isLSCX ? this.endNicknameEditMode() : this.handleApiKeyCompleteClick();
                          }
                          return false;
                        } 
                        }>
                        { 
                          this.state.testingFailed
                            ? 
                            `← ` + this.props.t(`user:testApiFailed`) 
                            : 
                            this.props.t(`user:testMyConnection`) + ` & ` + this.props.t(`orders:addAccount`) 
                        }
                      </Button>
                    </div>
                  </div>
              }
            </div>
          </div>
          <div className="wallet-address">
            { this.state.currentStepWallet === 0  &&
                <div className="confirm-data">
                  <div className="tutorialSection">
                    <div className={ `tutorial-currency-wrapper` }>
                      <div className={ `currency-image-large` }>
                        <img
                          src={ `https://www.coinigy.com/assets/img/currency/` + this.state.activeWallet + `.png` }
                          onError={ (e)=>{e.target.onerror = null; e.target.src=`https://www.coinigy.com/assets/img/currency/empty.png`;} } />
                      </div>
                      <div className={ `currency-name-wrapper` }>
                        <div className={ `currency-code` }>
                          { this.state.activeWallet }
                        </div>
                        <div className={ `currency-name` }>
                          { this.state.activeWalletCurrName }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="formSection">
                    <h3 className={ `pull-left` }>
                      {  
                        this.props.t(`user:pleaseEnterYour`) + 
                            this.state.activeWalletCurrName + 
                            this.props.t(`user:walletInfoBelow`)  
                      }
                    </h3>
                    <TextField
                      label={ this.props.t(`user:accountNickname`) }
                      ref="NicknameField"
                      readOnly={ !this.state.editingNickname }
                      name={ `accountNickname` }
                      value={ this.state.accountNickname }
                      hintText={ this.props.t(`user:nicknameHint`) }
                      toolTipText={ this.props.t(`user:doubleClickEdit`) }
                      onDoubleClick={ () => this.startNicknameEditMode() }
                      onBlur={ () => this.endNicknameEditMode()  }
                      onEnter={ () => this.endNicknameEditMode() }
                      onChange={ (e) => this.handleInputChange(`accountNickname`, e.target.value) } />

                    <TextField
                      label={ this.state.activeWalletFieldName.length > 0 
                        ? this.state.activeWallet + ` ` + this.state.activeWalletFieldName
                        : this.state.activeWallet + ` ` + this.props.t(`user:walletPubkey`) }
                      name={ `walletKey` }
                      value={ this.state.walletPublicKey }
                      hintText={ this.props.t(`user:walletPubkeyHint`) }
                      onChange={ (e) => this.handleInputChange(`walletPublicKey`, e.target.value) } />
                  </div>
                  
                  <Button
                    disabled={ !this.state.walletNickname || !this.state.walletPublicKey  }
                    type="primary"
                    onClick={ () => {
                      this.handleInputChange(`currentStepWallet`, 1);
                      this.handleWalletApiKeyCompleteClick();
                      return false;
                    } }>
                    { this.props.t(`user:verifyConnection`) }
                  </Button>
                  
                </div>
            }
            { this.state.currentStepWallet === 1  &&
                <div>
                  
                  <div className="testConnection">
                    <h2>
                      { this.props.t(`user:verifyConnection`) }
                    </h2>
                    <h3>
                      Account Information:
                    </h3>
                    <EntityTable
                      columns={ [
                        {
                          title: this.props.t(`app:wallet`),
                          key: `activeWallet`,
                        },
                        {
                          title: this.props.t(`user:walletNickname`),
                          key: `walletNickname`
                        },
                        {
                          title: this.state.activeWalletFieldName,
                          key: `walletPublicKey`
                        }
                      ] }
                      data={ this.getWalletKeyData() }
                      hidePagination={ true } />

                    <div className={ `connectionAnimation ${ this.state.isTesting ? `fullopacity` : `` }` }>
                      <div className="logo-wrapper">
                        { LogoMark[this.props.platformId ? this.props.platformId : 0] }
                      </div>
                      <div className={ 
                        `anim-wrapper ${ this.state.isTesting ? `animate` : `` }` 
                      }>
                        <div className="lds-ellipsis">
                          <div />
                          <div />
                          <div />
                          <div />
                        </div>
                      </div>
                      <div className="exchange-wrapper">
                        <img
                          className={ `currency-image` }
                          src={ `https://www.coinigy.com/assets/img/currency/${ this.state.activeWallet }.png` }
                          alt={ this.state.activeWallet } />
                      </div>
                    </div>
                    <div className={ 
                      `contacting ${ !this.state.isTesting ? `dimmed` : `` }` 
                    }>
                      {
                        this.state.isTesting 
                          ? 
                          `${ this.props.t(`user:contacting`)} ${ this.state.activeWallet }...`
                          :
                          ( this.state.testingFailed
                            ? 
                            this.props.t(`user:testFailed`) 
                            : 
                            this.props.t(`user:readyToTest`))
                      }
                    </div>
                  </div>
                  <div>
                    <Button
                      type={ this.state.testingFailed ? `cancel` : `primary` }
                      disabled={ this.state.isTesting }
                      onClick={ () =>  {
                        if (this.state.testingFailed)
                        {
                          this.handleInputChange(`currentStepWallet`, 0);
                          this.handleInputChange(`testingFailed`, false);
                        }else {
                          this.handleWalletApiKeyCompleteClick();
                        }
                        return false;
                      }
                      }>
                      { 
                        this.state.testingFailed
                          ?
                          `← ` + this.props.t(`user:testWalletFailed`) 
                          : 
                          this.props.t(`user:testMyConnection`) + ` & ` + this.props.t(`orders:addWallet`)
                      }
                    </Button>
                  </div>
                </div>
            }
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  platformId: state.userInfo.user.platformId
});

export default connect(mapStateToProps)(EditAccountModal);



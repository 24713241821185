// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import Modal from '../utilities/Modal.jsx';
import type { Account } from '../../types/Account.js';

type Props = {
  t: any,
  title: string,
  onConfirm: (authId: number) => void,
  onClose: () => void,
  account: Account
};

const AccountDeleteModal = (props: Props) => {
  const { t, title, onConfirm, onClose, account: { authId, authNickname } } = props;

  return (
    <Modal
      title={ title }
      onConfirm={ () => onConfirm(authId) }
      onClose={ onClose }
      confirmText={ t(`app:confirm`) }
      cancelText={ t(`app:cancel`) }
      confirmDisabled={ false } >
      <>
        <p>
          { t(`user:deleteAccountConfirm1`) + authNickname + t(`user:deleteAccountConfirm2`) }
        </p>
        <p>
          { t(`user:deleteAccountConfirm3`) }
        </p>
      </>
    </Modal>
  );
};

export { AccountDeleteModal as PureAccountDeleteModal };
export default translate(`users`)(AccountDeleteModal);

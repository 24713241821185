// @flow
'use strict';

import { SET_MARKET_SWITCHER_COLOR_MODE } from '../index.js';

export function setMarketSwitcherColorMode(filled: boolean) {
  return {
    type: SET_MARKET_SWITCHER_COLOR_MODE,
    payload: filled
  };
}

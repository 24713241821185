// @flow
'use strict';

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import { updateTitle } from '../helpers/BrowserTitleHelper.js';
import { NavLink } from 'react-router-dom';
import Gear from '../svgs/Gear.jsx';
import Mail from '../svgs/Mail.jsx';
import Lock from '../svgs/Lock.jsx';
import Connector from '../svgs/Connector.jsx';
import Book from '../svgs/Book.jsx';
import Play from '../svgs/Play.jsx';
import FavoriteIcon from '../svgs/FavoriteIcon.jsx';
import ProfileDataLayer from '../components/user/ProfileDataLayer.jsx';
import AccountEditor from '../components/user/AccountEditor.jsx';
import LSCXAccountEditor from '../components/user/LSCXAccountEditor.jsx';
import ScrollableArea from '../components/utilities/ScrollableArea.jsx';
import ReferralProgram from '../components/user/ReferralProgram.jsx';
import TwoFactorAuthentication from '../components/user/TwoFactorAuthentication/index.jsx';
import AccountActivity from '../components/user/AccountActivity.jsx';
import ActiveSessions from '../components/user/ActiveSessions.jsx';
import ManageSubscription from '../components/user/ManageSubscription.jsx';
import Preferences from '../components/user/Preferences.jsx';
import Favorites from '../components/user/Favorites.jsx';
import type { Market } from '../types/Market.js';
import type { Exchange } from '../types/Exchange.js';
import type { Account } from "../types/Account.js";

type Props = {
  t: any,
  exchanges: Array<Exchange>,
  markets: Array<Market>,
  accounts: Array<Account>,
  params: {
    tab: string,
  },
  userInfo: any,
};

const REDIRECT_TO_V1 = [`api`];

const UserPage = ({ t, params: { tab }, markets, exchanges, accounts, userInfo }: Props) => {
  const isLscxUser = (userInfo.platformId === 1);
  updateTitle(``, userInfo.platformId);

  const lscxUserTabs: Array<{
    name: string,
    text: string,
    v1?: string,
    icon: any,
    component?: any,
    hideFromNav?: boolean
  }> = [
    {
      name: `profile`,
      text: t(`header:userProfile`),
      icon: Mail,
      component: <LSCXAccountEditor t />
    },
    {
      name: `2fa`,
      text: t(`header:twoFactorAuth`),
      icon: Lock,
      component: <TwoFactorAuthentication t />
    },
    {
      name: `preferences`,
      text: `Notification Preferences`,
      icon: Gear,
      component: <Preferences t />
    },
    {
      name: `activity`,
      text: t(`header:activityLog`),
      icon: Book,
      component: <AccountActivity t />
    },
    {
      name: `sessions`,
      text: t(`header:activeSessions`),
      icon: Play,
      component: <ActiveSessions t />
    },
    {
      name: `favorites`,
      text: t(`header:favorites`),
      icon: FavoriteIcon,
      component: <Favorites t />
    }
  ];

  const tabs: Array<{
    name: string,
    text: string,
    v1?: string,
    icon: any,
    component?: any,
    hideFromNav?: boolean
  }> = [
    {
      name: `profile`,
      text: t(`header:subscription`),
      v1: `${ window.WWW_URL }/user/account#billing`,
      icon: Mail,
      component: <AccountEditor t />
    },
    {
      name: `subscription`,
      text: t(`manageSubscription`),
      icon: Mail,
      hideFromNav: true,
      component: <ManageSubscription t />
    },
    {
      name: `preferences`,
      text: t(`header:accountPrefs`),
      icon: Gear,
      component: <Preferences t />
    },
    {
      name: `2fa`,
      text: t(`header:twoFactorAuth`),
      icon: Lock,
      component: <TwoFactorAuthentication t />
    },    
    {
      name: `referrals`,
      text: t(`header:referral`),
      v1: `${ window.WWW_URL }/user/account#referral`,
      icon: Connector,
      component: <ReferralProgram t />
    },
    {
      name: `activity`,
      text: t(`header:activityLog`),
      icon: Book,
      component: <AccountActivity t />
    },
    {
      name: `sessions`,
      text: t(`header:activeSessions`),
      icon: Play,
      component: <ActiveSessions t />
    },
    {
      name: `favorites`,
      text: t(`header:favorites`),
      icon: FavoriteIcon,
      component: <Favorites t />
    }
  ];

  const tabsToRender = isLscxUser ? lscxUserTabs : tabs;
  return (
    <div className="cols user-profile-cols">
      <aside>
        <h2>
          { t(`header:profile`) }
        </h2>
        <ul className="header-tab">
          {
            tabsToRender.filter((tab) => !tab.hideFromNav).map((t, i) => (
              <li key={ i }>
                {
                  REDIRECT_TO_V1.indexOf(t.name) > -1 &&
                  !(process.env.NODE_ENV === `development` || process.env.STAGING) ? (
                      <a
                        href={ t.v1 }
                        rel="noopener noreferrer"
                        target="_blank">
                        { t.icon }
                        <span>
                          { t.text }
                        </span>
                      </a>
                    ) : (
                      <NavLink
                        to={ `/user/${ t.name }` }
                        activeClassName="active">
                        { t.icon }
                        <span>
                          { t.text }
                        </span>
                      </NavLink>
                    )
                }
              </li>
            ))
          }
        </ul>
      </aside>
      <section>
        <ScrollableArea>
          <ProfileDataLayer
            t={ t }
            activeTab={ tabsToRender.filter((t) => t.name == tab)[0] }
            markets={ markets }
            accounts={ accounts }
            exchanges={ exchanges } />
        </ScrollableArea>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.userInfo.user,
});

export { UserPage as PureUserPage };
export default withRouter(connect(mapStateToProps)(translate(`user`)(UserPage)));



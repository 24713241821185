// @flow
'use strict';

import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ColumnOrderState } from '@tanstack/react-table';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import PushPinIcon from '@mui/icons-material/PushPin';

export const ToolPanelColumnItem = ({ column, table }: any) => {
  const { getState, setColumnOrder } = table;
  const { columnOrder } = getState();

  const reorderColumn = (draggedColumnId: string, targetColumnId: string, columnOrder: string[]): ColumnOrderState => {
    columnOrder.splice(
      columnOrder.indexOf(targetColumnId),
      0,
      columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0]
    );

    return [...columnOrder];
  };

  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: `column`,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex =  item.index;
      const hoverIndex = columnOrder.findIndex((co) => co === column.id);
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      // $FlowIgnore: suppressing this error
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      // $FlowIgnore: suppressing this error
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      const newColumnOrder = reorderColumn(item.id, column.id, columnOrder);
      setColumnOrder(newColumnOrder);

      item.index = hoverIndex;
    }
  });
  const [{ isDragging }, drag, previewRef] = useDrag({
    type: `column`,
    item: () => {
      return { id: column.id, index: columnOrder.findIndex((co) => co === column.id ) };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });
  
  drag(drop(ref));

  return (
    <div ref={ ref } key={ column.id } className={ `draggable-column ${isDragging ? `is-dragging`: ``}` } data-handler-id={ handlerId }>
      <div className={ `drag ${isDragging ? `is-dragging`: ``}` }>
        <DragHandleIcon />
      </div>

      <div ref={ previewRef } className='column-preview'>
        { column.columnDef.header() }

        { column.getCanHide() && (
          <div 
            className={ column.getIsVisible() ? `enabled-icon` : `disabled-icon` }
            title={ column.getIsVisible() ? `Hide column` : `Show column` }
            onClick={ () => column.toggleVisibility(!column.getIsVisible()) }>
            <VisibilityIcon />
          </div>
        ) }

        { column.getCanPin() && (
          <div 
            className={ column.getIsPinned() ? `enabled-icon` : `disabled-icon` }
            title={ column.getIsPinned() === false  ? `Pin column` : `Unpin column` }
            onClick={ () => column.pin( column.getIsPinned() === false ? `left` : false ) }>
            <PushPinIcon />
          </div>
        ) }
      </div>
    </div>
  );
};

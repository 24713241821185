// @flow
'use strict';

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { translate } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import type { Account } from "../../types/Account.js";
import type { Exchange } from "../../types/Exchange.js";
import type { UserApplication } from '../../types/UserApplication.js';
import { 
  emitEvent, 
  SHOW_LSCX_MODAL,
  NOTIFICATION
} from '../../helpers/EventHelper.js';
import { getUserApplication } from '../../helpers/UserApplicationsHelper';


type Props = {
  t: any,
  setPrimeTrustModalOpen: () => void,
  accounts: Array<Account>,
  exchanges: Array<Exchange>,
  styleClass: string,
  onClick: void
};

const LSCXButton = (props: Props) => {
  const { t, accounts, exchanges, styleClass, onClick } = props;

  const history = useHistory();
  const handleClick = (authId) => history.push(`/wallet/${authId}`);
  const LSCXExchange = exchanges.filter((e) => e.exchCode === `LSCX`);
  const LSCXAccount = LSCXExchange[0] ? accounts.filter((a) => a.authExchId == LSCXExchange[0].exchId) : [];
  const userApplications = useSelector((state) => state.userInfo.userApplications);
  const applicationsStatuses = useSelector((state) => state.userInfo.applicationsStatuses);

  const exchActionsClass = styleClass ? `exch-actions ${styleClass}` : `exch-actions`;

  const userApplication: UserApplication = useMemo(() => getUserApplication(`LSCX`, userApplications, applicationsStatuses), [userApplications, applicationsStatuses]);

  return (
    <div className={ exchActionsClass } { ...{ onClick } } >
      {
        !userApplication.isEligible && !userApplication.isAccountClosed && (
          <a 
            target="_blank" 
            rel="noopener noreferrer"
            href={ `https://lightspeedcrypto.com/en/coinigy-partnership/` }>
            <div className={ `manage-funds-ls-button` }>
              { t(`subscribersModal:activeLS`) }
            </div>
          </a>
        )
      }
      { // LSCX Sign Up
        userApplication.isAccountClosed ? (
          <li className="subscription-status">
            <a rel="noopener noreferrer" onClick={ () => { emitEvent(SHOW_LSCX_MODAL); } }>
              <div className="closed-ls-button">
                { t(`header:accountClosed`) }
              </div>
            </a>
          </li>
        ) : (
          userApplication.isEligible && (
            <li className="subscription-status">
              { // Sign up button
                (userApplication.isEligible && userApplication.isAwaiting) && (
                  <a rel="noopener noreferrer" onClick={ () => { emitEvent(SHOW_LSCX_MODAL); } }>
                    <div className={ `sign-up-ls-crypto` }>
                      { t(`header:signUpLS`) }
                    </div>
                  </a>
                )
              }
              { // Pending app button
                (userApplication.isEligible && userApplication.isPending) && (
                  <a rel="noopener noreferrer" onClick={ () => { emitEvent(SHOW_LSCX_MODAL); } }>
                    <div className="warning-ls-button">
                      { t(`header:appPending`) }
                    </div>
                  </a>
                )
              }
              { // deposit funds button
                (userApplication.isEligible && userApplication.isVerified) && (
                  <a
                    rel="noopener noreferrer" onClick={ () => { LSCXAccount[0] ? handleClick(LSCXAccount[0].authId) : emitEvent(NOTIFICATION, {
                      notification_id: new Date().getTime(),
                      title: `Error`,
                      title_vars: ``,
                      message_raw: `No valid account found.`,
                      message: `No valid account found.`,
                      message_vars: ``,
                      pinned: false,
                      style: `error`,
                      url: ``
                    }); } }>
                    <div className={ `manage-funds-ls-button` }>
                      { t(`header:depositFunds`) }
                    </div>
                  </a>
                )
              }
              { // Sign up button
                (userApplication.isEligible && userApplication.isDenied) && (
                  <a rel="noopener noreferrer" onClick={ () => { emitEvent(SHOW_LSCX_MODAL); } }>
                    <div className="warning-ls-button">
                      { t(`header:reUploadKYC`) }
                    </div>
                  </a>
                )
              }
            </li>
          )
        )
      }
    </div>
  );
};

export { LSCXButton as PureLSCXButton };
export default translate(`markets`)(LSCXButton);

// @flow
'use strict';

import React from 'react';
import PresetTextField from "./../PresetTextField.jsx";
import SelectField from "./../SelectField.jsx";
import { FILTER_CONTROL_TEXT, FILTER_CONTROL_SELECT } from "../DataTable";

const FILTER_FN_OPTIONS = [
  {
    label: `includes`,
    value: `includesString`
  },
  {
    label: `equals`,
    value: `equalsString`
  }
];

export const ToolPanelFilterItem = ({ column, setColumnFilterFn }: any) => {

  return (
    <div className='filter-container'>
      <label>
        { column.columnDef.header() }
      </label> 
      <div className={ `inputs-container` }>
        { column.columnDef.meta.filterControl.type === FILTER_CONTROL_TEXT &&
          <PresetTextField
            label={ `` }
            name={ column.id }
            value={ column.getFilterValue() || `` }
            options={ FILTER_FN_OPTIONS }
            errorText={ `` }
            optionsLabel={ column.columnDef.filterFn === `equalsString` ? `equals` : `includes` }
            compact={ true }
            selectOnLeft={ true }
            onChange={ (v) => {
              if ([`includesString`, `equalsString`].includes(v))  {
                column.setFilterValue(null);
              
                setColumnFilterFn((prev) => {
                  prev[column.id] = v;
                  return prev;
                });                            
              } else {
                column.setFilterValue(v);
              }
            } }/>
        }

        { column.columnDef.meta.filterControl.type === FILTER_CONTROL_SELECT &&
          <SelectField
            label={ `` }
            name={ column.id }
            value={ column.getFilterValue() || `` }
            onChange={ (e, v) => {
              setColumnFilterFn((prev) => {
                prev[column.id] = `equalsString`;
                return prev;
              });
              
              column.setFilterValue(v);
            } }
            options={ [
              { value: ``, label: `All` },
              ...column.columnDef.meta.filterControl.options
            ] } 
            searchable={ column.columnDef.meta.filterControl.options?.length > 4 }
            maxOptions={ 5 }/>
        }
      </div>
    </div>
  );
};

// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import { getMarketPair, getMarketFromPanelSettings } from '../../helpers/MarketPairHelper.js';
import { toFixedDecimalsHTML } from '../../helpers/NumberHelper.js';
import MarketUpArrow from '../../svgs/MarketUpArrow.jsx';
import MarketDownArrow from '../../svgs/MarketDownArrow.jsx';
import type { Market } from '../../types/Market.js';

type Settings = {
  exchange: string,
  market: string
};

type Props = {
  t: any,
  market: ?Market,
  settings: Settings, 
  panelData: any,
  width: number,
  height: number
};

const MarketDataPanel = ({ t, market, settings, panelData }: Props) => { /*, width, height */
  if (!market) return null;

  let mkt = `${ settings.exchange }:${ settings.market }`;
  if (panelData.hasOwnProperty(mkt) && 
    panelData[mkt].ticker && 
    panelData[mkt].trades && 
    panelData[mkt].trades.length > 0) {
    let { ticker, trades } = panelData[mkt],
      marketDirection = 0,
      currentPrice = trades[0].price;

    if (trades.length >= 2) {
      for (let i = 0; i < trades.length; i += 1) {
        if (currentPrice > trades[i].price) {
          marketDirection = 1;
          break;
        } else if (currentPrice < trades[i].price) {
          marketDirection = -1;
          break;
        }
      }
    }

    return (
      <div className="market-data-panel">
        <span>
          { getMarketPair(market).toString() }
          { ` ` }
          - 
          { ` ` }
          <span dangerouslySetInnerHTML={ 
            currentPrice ? 
              toFixedDecimalsHTML(currentPrice, false, `price`, market, true) : 
              { __html: t(`app:loading`) }
          } />
          { marketDirection < 0 ? 
            MarketDownArrow(`${ settings.exchange }_${ getMarketPair(market).toString().replace(`/`, `_`) }`) : 
            MarketUpArrow(`${ settings.exchange }_${ getMarketPair(market).toString().replace(`/`, `_`) }`) }
        </span>
        <div>
          <div>
            <div>
              <span>
                <strong>
                  { t(`app:high`) }
                </strong>
                <span dangerouslySetInnerHTML={ 
                  ticker.high > 0 ? 
                    toFixedDecimalsHTML(ticker.high, false, `price`, market, true) : 
                    { __html: t(`app:loading`) }
                } />
              </span>
              <span>
                <strong>
                  { t(`app:low`) }
                </strong>
                <span dangerouslySetInnerHTML={
                  ticker.low > 0 ? 
                    toFixedDecimalsHTML(ticker.low, false, `price`, market, true) :
                    { __html: t(`app:loading`) }
                } />
              </span>
            </div>
            <div>
              <span>
                <strong>
                  { t(`app:ask`) }
                </strong>
                <span dangerouslySetInnerHTML={
                  ticker.ask > 0 ? 
                    toFixedDecimalsHTML(ticker.ask, false, `price`, market, true) :
                    { __html: t(`app:loading`) }
                } />
              </span>
              <span>
                <strong>
                  { t(`app:bid`) }
                </strong>
                <span dangerouslySetInnerHTML={
                  ticker.bid > 0 ? 
                    toFixedDecimalsHTML(ticker.bid, false, `price`, market, true) :
                    { __html: t(`app:loading`) }
                } />
              </span>
            </div>
          </div>
        </div>
        <div>
          <div>
            <span>
              <strong>
                { t(`app:vol`) }
                { ` ` }
                (
                { getMarketPair(market).base }
                )
              </strong>
              <span dangerouslySetInnerHTML={ 
                ticker.volume > 0 ?
                  toFixedDecimalsHTML(ticker.volume, true, `quantity`, market, true) :
                  { __html: t(`app:loading`) }
              } />
            </span>
            <span>
              <strong>
                { t(`app:vol`) }
                { ` ` }
                (
                { getMarketPair(market).quote }
                )
              </strong>
              <span dangerouslySetInnerHTML={
                ticker.volume > 0 && ticker.high > 0 && ticker.low > 0 ? 
                  toFixedDecimalsHTML(
                    ticker.volume * 
                  ((ticker.high + ticker.low) / 2), 
                    false, 
                    `quantity`, 
                    market, 
                    true) :
                  { __html: t(`app:loading`) }
              } />
            </span>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="panel-loading">
        <span>
          { t(`app:loading`) }
        </span>
      </div>
    );
  }
};

MarketDataPanel.getPanelTitle = (settings: Settings, markets: Array<Market>) => {
  let market = getMarketFromPanelSettings(markets, settings);

  return market ? market.exchName + ` - ` + market.displayName : `Market Data`;
};

export default translate(`boards`)(MarketDataPanel);

// @flow
'use strict';

import React from 'react';
import Button from '../utilities/Button.jsx';
import RadioButtons from '../utilities/RadioButtons.jsx';
import TextField from '../utilities/TextField.jsx';
import Checkmark from '../../svgs/Checkmark.jsx';

type Props = {
  t: any,
  onChange: (k: string, v: any) => void,
  checkout: (b: {
    name: string,
    street: string,
    city: string,
    state: string,
    zip: string,
    country: string,
    cc_num: string,
    cc_exp: string,
    cc_cvv: string
  }) => void,
  selectedSubscription?: {
    serviceName: string,
    serviceDescription: string,
    serviceHash: string,
    serviceDurationMonths: number,
    serviceOrder: number,
    servicePriceBtc: number,
    servicePriceUser: number,
    serviceFullPriceBtc: number,
    serviceFullPriceUsd: number,
    serviceRecurring: boolean,
    isDefault: boolean
  }
};

type State = {
  payment_type: number,
  name: string,
  street: string,
  city: string,
  state: string,
  zip: string,
  country: string,
  cc_num: string,
  cc_exp: string,
  cc_cvv: string
};

class PlanPaymentDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      payment_type: 1,
      name: ``,
      street: ``,
      city: ``,
      state: ``,
      zip: ``,
      country: ``,
      cc_num: ``,
      cc_exp: ``,
      cc_cvv: ``
    };
  }

  handleChange(k: string, v: any, i?: boolean = false) {
    this.setState({
      [k]: i ? parseInt(v) : v
    });
  }

  render() {
    let servicePriceUser = this.props.selectedSubscription ? this.props.selectedSubscription.servicePriceUser : 0;

    return (
      <section>
        <div>
          <div className="top">
            <h2>
              { this.props.t(`paymentDetails`) }
            </h2>
          </div>
          <div className="payment-details-cols">
            <div className="payment">
              <h4>
                { this.props.t(`paymentMethod`) }
              </h4>
              <RadioButtons
                options={ [
                  {
                    value: 1,
                    label: this.props.t(`card`),
                    name: `card`
                  },
                  {
                    value: 2,
                    label: this.props.t(`bitcoin`),
                    name: `bitcoin`
                  }
                ] }
                name={ `payment_type` }
                value={ this.state.payment_type }
                onChange={ (e) => this.handleChange(`payment_type`, e.target.value, true) } />
              {
                this.state.payment_type == 1 && this.props.selectedSubscription ? (
                  <div className="page-card">
                    <h4>
                      { this.props.t(`billingAddress`) }
                    </h4>
                    <TextField
                      label={ this.props.t(`nameOnCard`) }
                      name={ `name` }
                      value={ this.state.name }
                      onChange={ (e) => this.handleChange(`name`, e.target.value) } />
                    <TextField
                      label={ this.props.t(`street`) }
                      name={ `street` }
                      value={ this.state.street }
                      onChange={ (e) => this.handleChange(`street`, e.target.value) } />
                    <div className="input-row">
                      <TextField
                        label={ this.props.t(`city`) }
                        name={ `city` }
                        value={ this.state.city }
                        onChange={ (e) => this.handleChange(`city`, e.target.value) } />
                      <TextField
                        label={ this.props.t(`state`) }
                        name={ `state` }
                        value={ this.state.state }
                        onChange={ (e) => this.handleChange(`state`, e.target.value) } />
                    </div>
                    <div className="input-row">
                      <TextField
                        label={ this.props.t(`zip`) }
                        name={ `zip` }
                        value={ this.state.zip }
                        onChange={ (e) => this.handleChange(`zip`, e.target.value) } />
                      <TextField
                        label={ this.props.t(`country`) }
                        name={ `country` }
                        value={ this.state.country }
                        onChange={ (e) => this.handleChange(`country`, e.target.value) } />
                    </div>
                    <h4>
                      { this.props.t(`billingInformation`) }
                    </h4>
                    <TextField
                      label={ this.props.t(`creditCardNumber`) }
                      name={ `cc_num` }
                      value={ this.state.cc_num }
                      onChange={ (e) => this.handleChange(`cc_num`, e.target.value) } />
                    <div className="input-row">
                      <TextField
                        label={ this.props.t(`expDate`) }
                        name={ `cc_exp` }
                        value={ this.state.cc_exp }
                        onChange={ (e) => this.handleChange(`cc_exp`, e.target.value) } />
                      <TextField
                        label={ this.props.t(`cvv`) }
                        name={ `cc_cvv` }
                        value={ this.state.cc_cvv }
                        onChange={ (e) => this.handleChange(`cc_cvv`, e.target.value) } />
                    </div>
                    <Button
                      type="confirm"
                      onClick={ (e) => {
                        e.preventDefault();
                        this.props.checkout(this.state);
                        return false;
                      } }>
                      { this.props.t(`checkout`) }
                      { ` ` }
                      $
                      { servicePriceUser.toFixed(2) }
                    </Button>
                  </div>
                ) : ``
              }
            </div>
            {
              this.props.selectedSubscription && (
                <div className="selected-sub">
                  <div>
                    <h2>
                      { this.props.selectedSubscription.serviceName }
                    </h2>
                    <div className="price">
                      <span>
                      $
                        { servicePriceUser.toFixed(2) }
                      </span>
                    </div>
                    <Button
                      type="default"
                      onClick={ (e) => {
                        e.preventDefault();
                        this.props.onChange(`serviceHash`, ``);
                        return false;
                      } }>
                      { this.props.t(`changePlan`) }
                    </Button>
                  </div>
                  <ul>
                    <li>
                      { Checkmark(`Trusted`) }
                      <strong>
                        { this.props.t(`trustedByThousands`) }
                      </strong>
                      <p>
                        { this.props.t(`trustedText`) }
                      </p>
                    </li>
                    <li>
                      { Checkmark(`Latency`) }
                      <strong>
                        { this.props.t(`lowLatencyApiData`) }
                      </strong>
                      <p>
                        { this.props.t(`latencyText`) }
                      </p>
                    </li>
                    <li>
                      { Checkmark(`Social`) }
                      <strong>
                        { this.props.t(`socialTrading`) }
                      </strong>
                      <p>
                        { this.props.t(`socialTradingText`) }
                      </p>
                    </li>
                    <li>
                      { Checkmark(`Security`) }
                      <strong>
                        { this.props.t(`securityStability`) }
                      </strong>
                      <p>
                        { this.props.t(`securityText`) }
                      </p>
                    </li>
                    <li>
                      { Checkmark(`Cloud`) }
                      <strong>
                        { this.props.t(`cloudHosted`) }
                      </strong>
                      <p>
                        { this.props.t(`cloudText`) }
                      </p>
                    </li>
                  </ul>
                </div>
              )
            }
          </div>
        </div>
      </section>
    );
  }
}

export default PlanPaymentDetails;

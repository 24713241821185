// @flow
'use strict';

const writeCookie = (name: string, value: any, days?: number) => {
  var expires = ``;
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));
    expires = `; expires=` + date.toUTCString();
  }
  document.cookie = name + `=` + (value || ``)  + expires + `; path=/`;
};

const readCookie = (a: string) => {
  var b = document.cookie.match(`(^|;)\\s*` + a + `\\s*=\\s*([^;]+)`);
  return b ? b.pop() : ``;
};

export { writeCookie, readCookie };

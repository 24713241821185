// @flow
'use strict';

export const UPDATE_THEME = `UPDATE_THEME`;
export const UPDATE_FAVORITES = `UPDATE_FAVORITES`;
export const UPDATE_USER_DATA = `UPDATE_USER_DATA`;
export const UPDATE_MARKETS_COLLAPSE_PREFS = `UPDATE_MARKETS_COLLAPSE_PREFS`;
export const UPDATE_MARKETS_INFO_ACTIVE_TAB = `UPDATE_MARKETS_INFO_ACTIVE_TAB`;
export const CHANGE_MARKET = `CHANGE_MARKET`;
export const UPDATE_CHARTS_ACTIVE_TAB = `UPDATE_CHARTS_ACTIVE_TAB`;
export const UPDATE_BOH_ACTIVE_TAB = `UPDATE_BOH_ACTIVE_TAB`;
export const UPDATE_ORDER_FORM_ACTIVE_TYPE = `UPDATE_ORDER_FORM_ACTIVE_TYPE`;
export const UPDATE_ALERT_ACTIVE_TYPE = `UPDATE_ALERT_ACTIVE_TYPE`;
export const UPDATE_ONLINE = `UPDATE_ONLINE`;
export const PUSH_TO_HISTORY = `PUSH_TO_HISTORY`;
export const UPDATE_CSRF = `UPDATE_CSRF`;
export const SET_USER_HASH = `SET_USER_HASH`;
export const UPDATE_ACTIVE_BOARD_ID = `UPDATE_ACTIVE_BOARD_ID`;
export const UPDATE_BOARD_COLUMNS = `UPDATE_BOARD_COLUMNS`;
export const SELECT_QUOTE_CURRENCY_CODE = `SELECT_QUOTE_CURRENCY_CODE`;
export const UPDATE_CURRENT_FAVORITE = `UPDATE_CURRENT_FAVORITE`;
export const SET_CURRENT_MARKET = `SET_CURRENT_MARKET`;
export const SET_BALANCE_FILTERS = `SET_BALANCE_FILTERS`;
export const UPDATE_MARKETS_ORDERS_ACTIVE_TAB = `UPDATE_MARKETS_ORDERS_ACTIVE_TAB`;
export const UPDATE_USER_THEME = `UPDATE_USER_THEME`;
export const RESET_TO_THEME = `RESET_TO_THEME`;
export const SET_MARKET_SWITCHER_COLOR_MODE = `SET_MARKET_SWITCHER_COLOR_MODE`;
export const REWRITE_USER_THEME = `REWRITE_USER_THEME`;
export const TOGGLE_MARKET_CLICKABILITY = `TOGGLE_MARKET_CLICKABILITY`;
export const TOGGLE_FAVORITE_FILTER = `TOGGLE_FAVORITE_FILTER`;
export const CHANGE_PORTFOLIO_CHART_TYPE = `CHANGE_PORTFOLIO_CHART_TYPE`;
export const CHANGE_GLOBAL_PAGE_SIZE = `CHANGE_GLOBAL_PAGE_SIZE`;
export const SET_PRICE_TYPES = `SET_PRICE_TYPES`;
export const SET_ORDER_TYPES = `SET_ORDER_TYPES`;
export const SET_STATUS_TYPES = `SET_STATUS_TYPES`;
export const SET_KEY_SHORTCUT = `SET_KEY_SHORTCUT`; 
export const RESET_ALL_BINDINGS = `RESET_ALL_BINDINGS`;
export const UPDATE_ALERT_SOUND = `UPDATE_ALERT_SOUND`;
export const TOGGLE_BOARD_LOCKED = `TOGGLE_BOARD_LOCKED`;
export const UPDATE_INSIGHTS_SECTIONS = `UPDATE_INSIGHTS_SECTIONS`;
export const UPDATE_RESOLUTION = `UPDATE_RESOLUTION`;
export const SET_SUBSCRIPTION_INFO = `SET_SUBSCRIPTION_INFO`;
export const SET_SUBSCRIPTION_TYPES = `SET_SUBSCRIPTION_TYPES`;
export const SET_FIAT_CURRENCIES = `SET_FIAT_CURRENCIES`;
export const UPDATE_NEW_ORDER_AUTO_CONFIRM = `UPDATE_NEW_ORDER_AUTO_CONFIRM`;
export const UPDATE_PAGE_PREFS = `UPDATE_PAGE_PREFS`;
export const SET_USER_APPLICATIONS = `SET_USER_APPLICATIONS`;
export const SET_APPLICATIONS_STATUSES = `SET_APPLICATIONS_STATUSES`;
export const SET_PREVIOUS_THEME = `SET_PREVIOUS_THEME`;
export const SET_MARKETS_FLEXLAYOUT= `SET_MARKETS_FLEXLAYOUT`;
export const SET_TICKER_PAUSED = `SET_TICKER_PAUSED`;
export const SET_MARKET_SWITCHER_VERSION = `SET_MARKET_SWITCHER_VERSION`;
export const SET_MARKET_SWITCHER_FILTERS = `SET_MARKET_SWITCHER_FILTERS`;
export const SET_CURRENCY_SELECTOR_FILTERS = `SET_CURRENCY_SELECTOR_FILTERS`;
export const SET_MARKET_SELECTOR_FILTERS = `SET_MARKET_SELECTOR_FILTERS`;
export const SET_NEW_USER_MODAL_SHOWN = `SET_NEW_USER_MODAL_SHOWN`;
export const SET_THE_CRYPT_FILTERS = `SET_THE_CRYPT_FILTERS`;

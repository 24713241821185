// @flow
'use strict';

import { UPDATE_THEME } from '../index.js';

export function updateTheme(theme: string) {
  return {
    type: UPDATE_THEME,
    payload: { theme }
  };
}

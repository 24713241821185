// @flow
'use strict';

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from './languages/en.json';
import it from './languages/it.json';
import cs from './languages/cs.json';
import ru from './languages/ru.json';
import hr from './languages/hr.json';
import af from './languages/af.json';
import id from './languages/id.json';
import nl from './languages/nl.json';
import hi from './languages/hi.json';
import de from './languages/de.json';
import tr from './languages/tr.json';
import es from './languages/es.json';
import sr from './languages/sr.json';
import bg from './languages/bg.json';

i18n.use(LanguageDetector).init({
  resources: {
    en,
    it,
    cs, 
    ru,
    hr,
    af,
    id,
    in: id,
    nl,
    hi,
    de,
    tr,
    es,
    sr,
    bg
  },
  fallbackLng: `en`,
  debug: false,
  ns: [`app`],
  defaultNS: `app`,
  keySeparator: false,
  react: {
    wait: true
  }
});

export const languages = [
  {
    title: `English`,
    subtitle: `English`,
    code: `us`,
    value: `us`
  },
  {
    title: `Italian`,
    subtitle: `Italiano`,
    code: `it`,
    value: `it`
  },
  {
    title: `Czech`,
    subtitle: `Česky`,
    code: `cz`,
    value: `cs`
  },
  {
    title: `Russian`,
    subtitle: `Русский`,
    code: `ru`,
    value: `ru`
  },
  {
    title: `Croatian`,
    subtitle: `Hrvatski`,
    code: `hr`,
    value: `hr`
  },
  {
    title: `Afrikaans`,
    subtitle: `Afrikaans`,
    code: `af`,
    value: `af`
  },
  {
    title: `Malay`,
    subtitle: `Bahasa Melayu`,
    code: `id`,
    value: `id`
  },
  // `in`,
  {
    title: `Dutch`,
    subtitle: `Nederlands`,
    code: `nl`,
    value: `nl`
  },
  {
    title: `Hindi	`,
    subtitle: `हिन्दी`,
    code: `in`,
    value: `hi`
  },
  {
    title: `German`,
    subtitle: `Deutsch`,
    code: `de`,
    value: `de`
  },
  {
    title: `Turkish`,
    subtitle: `Türkçe`,
    code: `tr`,
    value: `tr`
  },
  {
    title: `Spanish`,
    subtitle: `Español`,
    code: `es`,
    value: `es`
  },
  {
    title: `Serbian`,
    subtitle: `Serbian`,
    code: `rs`,
    value: `sr`
  },
  {
    title: `Bulgarian`,
    subtitle: `Български`,
    code: `bg`,
    value: `bg`
  },
];

export const getFlagEmoji = (countryCode: string) => {
  const codePoints = countryCode
    .toUpperCase()
    .split(``)
    // $FlowIgnore: suppressing this error
    .map((char) => 127397 + char.charCodeAt());
  
  return String.fromCodePoint(...codePoints);
};

export default i18n;

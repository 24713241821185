// @flow
'use strict';

import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ContextMenuTrigger } from "react-contextmenu";
import { Sparklines, SparklinesLine } from 'react-sparklines';
import { MARKET_SEARCH_CONTEXT_MENU_ID } from "../MarketSearch";
import { getActiveTheme, translateHex } from '../../../helpers/ThemeHelper.js';
import { getMarketPair } from '../../../helpers/MarketPairHelper.js';
import { toFixedDecimalsHTML } from '../../../helpers/NumberHelper.js';
import { subscribe, unsubscribe } from '../../../helpers/SocketClusterHelper.js';
import FavoritesStar from '../../../svgs/FavoritesStar.jsx';
import FavoritesStarFilled from '../../../svgs/FavoritesStarFilled.jsx';
import type { Market } from '../../../types/Market.js';
import type { Favorite } from '../../../types/Favorite.js';
// import EqualizerIcon from '@mui/icons-material/Equalizer';

type Props = {
  useCallback?: boolean,
  callback?: *,
  market: Market,
  favorites: Array<Favorite>,
  isActive: boolean,
  view: string,
  displayVolume: boolean
};

const THROTTLE_MS = 500;

export const MarketSearchItem = (props: Props) => {
  const { market, favorites, useCallback, callback, isActive, view, displayVolume } = props;
  const history = useHistory();

  const mktColors = useSelector((state) => state.redisPrefs.mktColors);
  const marketSwitcherColorFilled = useSelector((state) => state.app.marketSwitcherColorFilled);
  const marketsAreClickable =  useSelector((state) => state.browser.marketsAreClickable);

  // $FlowIgnore: suppressing this error
  const [lastTradePrice, setLastTradePrice] = useState(market?.lastTradePrice);
  // $FlowIgnore: suppressing this error
  const [miniChartData, setMiniChartData] = useState(market?.miniChartData);
  const accumTradesPrice  = useRef({});
  
  const marketString = getMarketPair(market).toString();
  const isFavorite = favorites?.some((f) => f.exchCode === market.exchCode && f.displayName === market.displayName);
  const mktColor = mktColors?.find((m) => m.display_name === marketString)?.color || ``;

  const changePrice = miniChartData ? miniChartData[miniChartData.length -1]?.price - miniChartData[0]?.price : 0;
  const percentChange = miniChartData && miniChartData[0]?.price > 0 ? (changePrice * 100 / miniChartData[0]?.price) : 0;

  // $FlowIgnore: suppressing this error
  if (!market.basePricePrecision) market.basePricePrecision = market.pricePrecision;

  const handleClick = () => {
    if (useCallback) {
      return callback && callback(market.exchCode, market.marketName);
    } else if (marketsAreClickable) {
      history.push(`/markets/${ market.exchCode }/${ marketString }`);
    }
  };

  const getOptimizedTextColor = (r, g, b) => {
    const o = Math.round(((r * 299) + (g * 587) + (b * 114)) / 1000);
    return (o > 125) ? getActiveTheme().gray3 : getActiveTheme().body;
  };

  const getOptimizedColor = (_color: string) => {
    if (!marketSwitcherColorFilled) return;

    if (_color[0] == `#`) {
      const color = translateHex(_color);

      return getOptimizedTextColor(color.r, color.g, color.b);
    } else if (_color[0] == `r`) {
      const color = _color.split(`(`)[1].split(`)`)[0].split(`,`);

      return getOptimizedTextColor(parseInt(color[0]), parseInt(color[1]), parseInt(color[2]));
    }

    return;
  };

  const getTradeChannel = (exchCode: string, marketName: string) => {
    return `TRADE-${ exchCode }--${ marketName.replace(`/`, `--`) }`;
  };

  const subscribeMarket = () => {
    const marketName =  (market.exchCode === `DERI`) ? market.marketName.substr(4, market.marketName.length) : market.marketName;
    const tradeChannel = getTradeChannel(market.exchCode, marketName);
    subscribe([tradeChannel], (ch) => subscribeCallback(ch), `market-search`);
  };

  const unsubscribeMarket = () => {
    const marketName =  (market.exchCode === `DERI`) ? market.marketName.substr(4, market.marketName.length) : market.marketName;
    const tradeChannel = getTradeChannel(market.exchCode, marketName);
    unsubscribe([tradeChannel], `market-search`);
  };

  const subscribeCallback = (channels: any) => {
    for (const channel in channels) {
      const tradeChannel = channels[channel].channel || channels[channel].trades;
      if (tradeChannel) {
        tradeChannel.watch((d) => {
          if (market.marketName === d.label && market.exchCode === d.exchange) {  
            accumTradesPrice.current.price = d.price;
            accumTradesPrice.current.time = d.timestamp;
          }
        });
      }
    }
  };

  const updateMarketsPrice = () => {
    const price = accumTradesPrice.current?.price;
    const time = accumTradesPrice.current?.time;

    accumTradesPrice.current.price = undefined;
    accumTradesPrice.current.time = undefined;

    if (price !== undefined && time !== undefined) {
      setLastTradePrice(price);
      if (miniChartData?.length) {
        setMiniChartData((prev) => {
          return [
            ...prev.slice(0, prev.length - 1),
            { price, time }
          ];
        });
      }
    }
  };
  
  const formatVolume = (volume) => new Intl.NumberFormat( `en-US`, { maximumFractionDigits: 1,notation: `compact` , compactDisplay: `short` }).format(volume);

  useEffect(() => {
    let marketsTradesIntervalId = null;
    const id = setTimeout( () => {
      marketsTradesIntervalId = setInterval(updateMarketsPrice, THROTTLE_MS);
      subscribeMarket();
    }, 500);

    return () => {
      clearTimeout(id);
      if (marketsTradesIntervalId) {
        unsubscribeMarket();
        clearInterval(marketsTradesIntervalId);
      }
    };
  }, []);

  const renderPriceView = () => (
    <div className='price-view'>
      <div>
        <Sparklines
          svgWidth={ 70 }
          svgHeight={ 20 }
          // $FlowIgnore: suppressing this error
          data={ miniChartData ? miniChartData.reduce((accum, candle) => [...accum, candle.price], []) : [1, 1] }
          style={ { marginRight: `1rem` } }>
          <SparklinesLine
            style={ { strokeWidth: 6, fill: `none` } }
            // $FlowIgnore: suppressing this error
            color={ Math.sign(percentChange) === -1 ? `var(--red-)` : `var(--green-)` }/>
        </Sparklines>
      </div>
              
      <div style={ { width: `11rem`, alignItems: `end`, display: `flex`, flexDirection: `column`, justifyContent: `space-around` } }>
        <div
          className={ `nowrap align-right` }
          style={ { whiteSpace: `nowrap` } }
          // $FlowIgnore: suppressing this error
          dangerouslySetInnerHTML={ toFixedDecimalsHTML(lastTradePrice || 0, false, market.pricePrecision === 2 ? `two_decimals` : `price`, market) }/>        
        { displayVolume ?
          (<span className='flex' style={ { backgroundColor: `var(--gray-1-theme-alpha)` } }>
            <div style={ { fontSize: `1rem`, marginTop: `.7rem`, marginRight: `2px`, color: `var(--body-text-color-alpha-13)` } }>
            VOL
            </div>
            <div
              className={ `nowrap align-right` }
              style={ { whiteSpace: `nowrap`, marginTop: `.1rem` } }>
              { /* $FlowIgnore: suppressing this error */ }
              { formatVolume(market?.volume || 0) }
            </div>
          </span>)
          : 
          (<div style={ { 'color': Math.sign(percentChange) === -1 ? `var(--red-)` : `var(--green-)`, 'fontWeight': `bold` } }>
            { Math.sign(percentChange) === -1 ? `${(percentChange).toFixed(2)}%` : `+${(percentChange).toFixed(2)}%` }
          </div>)
        }
      </div>
    </div>
  );

  const renderRSIView = (period) => {
    const intervalNames = [`Minute`, `Hour`, `Day`];
    const rsi = (period, interval) => {
      /* $FlowIgnore: suppressing this error */
      const value = market?.indicators?.rsi?.find((rsi) => rsi.period === period && rsi.interval === interval)?.value ?? `-`;
      return (
        <div key={ `${market.exchmktId}-${period}-${interval}` }>
          <div className='title'>
            { intervalNames[interval] }
          </div>
          <div 
            className={ `value ${ value > 70 ? `red-border` : `${ value < 30 ? `green-border` : `` }`}` }>
            { value }
          </div>
        </div>
      );
    };
    return (
      <div className='rsi-view'>
        {
          [0, 1, 2].map((i) => rsi(period, i))
        }
      </div>
    );
  };

  return (
    <ContextMenuTrigger
      id={ MARKET_SEARCH_CONTEXT_MENU_ID }
      name={ `${ market.exchCode }/${ marketString }` }
      collect={ () => ({ market }) }>
      <div
        className={ `market-search-item ${!marketsAreClickable ? `disabled` : ``} ${isActive ? `selected` : ``}` }
        style={ 
          mktColor.length > 0 ? {
            [(marketSwitcherColorFilled ? `backgroundColor` : `borderRightColor`).toString()]:
            mktColor,
            color: getOptimizedColor(mktColor)
          } : undefined
        }
        onClick={ handleClick }>
        <div className={ `flex` } style={ { alignItems: `center`, width: `calc(100% - 30px)`, justifyContent: `space-between` } }>
          <div className={ `currencies-section` }>
            <div className={ `quote-curr-logo` }>
              <img
                className={ `curr-logo` }
                // $FlowIgnore: suppressing this error
                src={ `${ window.WWW_URL }/assets/img/currency/${ market.displayQuoteCurrencyCode ?? market.quoteCurrencyCode }.png` } 
                height={ `20rem` } 
                onError={ (e)=>{e.target.onerror = null; e.target.src=`https://www.coinigy.com/assets/img/currency/empty.png`;} } />
            </div>
            <div className={ `base-curr-logo` }>
              <img
                className={ `curr-logo` }
                // $FlowIgnore: suppressing this error
                src={ `${ window.WWW_URL }/assets/img/currency/${ market.displayBaseCurrencyCode ?? market.baseCurrencyCode }.png` } 
                height={ `20rem` } 
                onError={ (e)=>{e.target.onerror = null; e.target.src=`https://www.coinigy.com/assets/img/currency/empty.png`;} } />
            </div>
            <div className={ `exch-icon` }>
              <a href={ `${ window.WWW_URL }/site/exchange/${ market.exchCode }/` } target={ `_blank` }>
                <img
                  src={ `${ window.WWW_URL }/assets/img/exchange/${ market.exchCode }-icon.png` }
                  height={ `16rem` } 
                  className={  `exch-logo-icon`  } />
              </a>
            </div>
          </div>

          <div className={ `market-name-section` }>
            <div>
              <span className={ `market-name-large` }>
                { marketString }
              </span>
            </div>
            <div>
              <span className={ `exch-name` }>
                { market.exchName }
              </span>
            </div>
          </div>
          { view === `price` && renderPriceView() }

          { view === `rsi7` && renderRSIView(7) }

          { view === `rsi14` && renderRSIView(14) }
        </div>
        
        <div className={ `exchange-fav-star-container` }>
          <div className={ `fav-star ${isFavorite ? `is-favorite` : ``}` }>
            { isFavorite ? FavoritesStarFilled(`MarketsSearchStarFilled`) : FavoritesStar(`MarketsSearchStar`) }
          </div>
        </div>
      </div>
    </ContextMenuTrigger>
  );
};

// @flow
'use strict';

import React, { useState } from 'react';
import { translate } from 'react-i18next';
import QRCode from "react-qr-code";
import Button from '../../utilities/Button.jsx';
import Modal from '../../utilities/Modal.jsx';
import TextField from '../../utilities/TextField.jsx';

const TwoFactorAuthenticationModal = (props) => {
  const { t, fA2Enabled, onClose, onConfirm, platformId, secretKey } = props;

  const [authCode, setAuthCode] = useState(``);

  const platform = platformId === 1 ? `LightspeedCrypto` : `Coinigy`;

  const handleClose = () => {
    if (onClose) onClose();
    setAuthCode(``);
  };

  return (
    <div className={ `two-factor-authentication-modal` }>
      <Modal
        title={ `${ !fA2Enabled ? t(`enable`): t(`disable`) } ${ t(`twoFactorAuth`) }` }
        onClose={ handleClose }>
        <div className="tfa-modal-container">
          { secretKey && !fA2Enabled && 
              <div className="secret-container">
                <div className="qr-code-container">
                  <QRCode value={ `otpauth://totp/${ platform }?secret=${ secretKey }` } className="qr-code"/>                
                </div>

                <TextField
                  data-testid={ `2fa-modal-secret-testid` }
                  label="Secret Key" 
                  name="secretkey"
                  value={ secretKey }
                  disabled/>
              </div>
          }

          <TextField
            label="Authenticator code" 
            name="authcode"
            value={ authCode }
            onEnter={ () => onConfirm(authCode) }
            onChange={ (e) => setAuthCode(e.target.value) }/>

          <p>
            { t(`twoFactoAuthEnterCode`, { enabled: !fA2Enabled ? t(`enable`): t(`disable`) }) }
          </p>

          <Button type={ !fA2Enabled ? `primary`: `cancel` } onClick={ () => onConfirm(authCode) }>
            { !fA2Enabled ? t(`twoFactorAuthVerifyAndEnable`) : t(`twoFactorAuthDisable`) }
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default translate(`user`)(TwoFactorAuthenticationModal);

// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import TextField from '../utilities/TextField.jsx';
import SelectField from '../utilities/SelectField.jsx';

type Props = {
  t: any,
  options: Array<{
    label: string,
    value: any,
    compareValue?: any,
    icon?: any
  }>,
  label: string,
  name: string,
  value: any,
  onChange: (value: string) => void,
  onBlur?: () => void,
  onFocus?: (e: any) => void,
  disabled: boolean,
  errorText?: string,
  optionsLabel?: string,
  compact?: boolean,
  selectOnLeft?: boolean,
  commaSeparator: ? boolean,
};

const PresetTextField = (
  { t, 
    options, 
    label, 
    name, 
    value, 
    onChange, 
    onBlur,
    onFocus,
    optionsLabel,
    errorText, 
    compact, 
    disabled, 
    selectOnLeft,
    commaSeparator }: Props) => (
  <div className={ `preset-text-field ${ compact ? `compact` : `` } ${ disabled ? `disabled` : `` }` }>
    {
      selectOnLeft && options.length > 0 ? (<SelectField
        isLeftOfInput={ true }
        options={ options }
        name={ `selectPreset-${ name }` }
        label={ optionsLabel ? optionsLabel : t(`presets`) }
        value=""
        disabled={ !!disabled }
        onChange={ (e, v) => onChange(v) } /> ) : null
    }
    <TextField
      label={ label }
      name={ name }
      value={ value }
      disabled={ !!disabled }
      errorText={ errorText }
      compact={ compact }
      onBlur={ () => { onBlur && onBlur(); } }
      onFocus={ (e) => { onFocus && onFocus(e); } }
      onChange={ (e) => onChange(e.target.value) } 
      commaSeparator={ !!commaSeparator }/>
    {
      selectOnLeft ? null : (<SelectField
        options={ options }
        name={ `selectPreset-${ name }` }
        label={ optionsLabel ? optionsLabel : t(`presets`) }
        value=""
        disabled={ !!disabled }
        onChange={ (e, v) => onChange(v) } /> )
    }
  </div>
);

export { PresetTextField as PurePresetTextField };
export default translate(`app`)(PresetTextField);

'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<circle cx="12" cy="12" r="12" fill="currentColor" fill-opacity="0.2"/>
  <circle cx="12" cy="9" r="2.5" stroke="currentColor"/>
  <path d="M6.5 18V16.5C6.5 15.3954 7.39543 14.5 8.5 14.5H15.5C16.6046 14.5 17.5 15.3954 17.5 16.5V18" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
  `,
  {
    width: `24px`,
    height: `24px`,
    viewBox: `0 0 24 24`
  }
);
/* eslint-enable */

// @flow
'use strict';

import React, { useState } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import Search from '../../../svgs/Search.jsx';
import EntityTable from '../../utilities/EntityTable.jsx';
import TextField from '../../utilities/TextField.jsx';
import { changeGlobalPageSize } from '../../../actions/app/changeGlobalPageSize.js';
import { updateRedisPrefs } from '../../../actions/redisPrefs/updateRedisPrefs.js';
import { updateMarketsInfoActiveTab } from '../../../actions/markets/updateMarketsInfoActiveTab.js';
import type { Balance } from '../../../types/Balance.js';
import type { Account } from '../../../types/Account.js';
import type { Exchange } from '../../../types/Exchange.js';
import buildTableData from './buildTableData.jsx';
import { Market } from '../../../types/Market.js';


type Props = {
  t: any,
  accounts: Array<Account>,
  exchanges: Array<Exchange>,
  balances: Array<Balance>,
  currency: string,
  pageSize: any,
  markets: Array<Market>,
  changePageSize: (n: any) => void,
  updatePrefs: (n: any) => void,
  updateTab: (n: any) => void
};

const BalancesTableComponent = (props: Props) => {
  const { t, exchanges, accounts, balances, currency, pageSize, updateTab, updatePrefs, markets } = props;
  const [filterText, setFilterText] = useState(``);
  const [openPopover, setOpenPopover] = useState(``);

  const handleTradeClick = (exchId: number, authId: number) => {
    // SET Trade TAB
    updateTab(1);

    // Open order form
    updatePrefs({
      key: `marketInfoOpen`,
      value: true
    });

    // SET Selected AuthId and ExchId
    localStorage.setItem(`selectedAuthIds`, `[{"exchangeId": ${exchId},"selectedAuthId": ${authId}}]`);
  };

  // Build collapsible data
  const fullTableData = buildTableData(t, exchanges, accounts, balances, currency, openPopover, setOpenPopover, handleTradeClick, markets);

  return (
    <div className="balance-table">
      <div className="header">
        <div className="search">
          <TextField
            icon={ Search }
            label={ t(`app:search`) }
            name={ `balances-search` }
            value={ filterText }
            onChange={ (e) => setFilterText(e.target.value) } />
        </div>
      </div>      
      <EntityTable
        columns={ fullTableData.mainTableColumns }
        filterText={ filterText }
        data={ fullTableData.balancesWithCollapsibleData || [] }
        defaultSortBy={ `+balanceCurrCode` }
        isBalancesOrdersHistory={ true }
        hidePagination={ true }
        pageSize={ pageSize == `all` ? fullTableData.balancesWithCollapsibleData.length : parseInt(pageSize) }
        collapsibleColumns={ fullTableData.collapsbileColumns }
        hasCollapsibleRows={ true }/>
    </div>
  );
};


const mapStateToProps = (state) => ({
  currency: state.app.selectedQuoteCurrencyCode,
  pageSize: state.app.pageSize
});

const mapDispatchToProps = (dispatch) => ({
  changePageSize: (p) => dispatch(changeGlobalPageSize(p)),
  updatePrefs: (collapse) => dispatch(updateRedisPrefs(collapse)),
  updateTab: (tab) => dispatch(updateMarketsInfoActiveTab(tab))
});

export { BalancesTableComponent as PureBalancesTableComponent };
export default translate(`balances`)(connect(mapStateToProps, mapDispatchToProps)(BalancesTableComponent));

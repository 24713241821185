// @flow
'use strict';

import { SET_SUBSCRIPTION_INFO } from '../index.js';

export function setSubscriptionInfo(payload: boolean) {
  return {
    type: SET_SUBSCRIPTION_INFO,
    payload
  };
}

// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import EntityTable from "../utilities/EntityTable.jsx";
import MarketUpArrow from "../../svgs/MarketUpArrow.jsx";
import MarketDownArrow from "../../svgs/MarketDownArrow.jsx";
import { toFixedDecimalsHTML } from '../../helpers/NumberHelper.js';
import { toLocalDate } from '../../helpers/DateHelper.js';
import { emitEvent, MARKETS_PRICE_CLICK, SHOW_FAQS_MODAL } from '../../helpers/EventHelper.js';
import type { Trade } from '../../types/Trade.js';
import type { Market } from '../../types/Market.js';
import type { Exchange } from '../../types/Exchange.js';
import { adjustTradePrice } from '../../helpers/AdjustTradeHelper.js';

type Props = {
  t: any,
  trades: Array<Trade>,
  market: Market,
  zeroStyle: string,
  exchanges: Array<Exchange>,
};

const StreamingTradesList = (props: Props) =>  {
  const { trades, market, zeroStyle, t, exchanges } = props;
  const listTable = [];

  trades.sort((a, b) => {
    if (+new Date(a.time) > +new Date(b.time)) return -1;
    if (+new Date(b.time) > +new Date(a.time)) return 1;
    if (a.market_history_id < b.market_history_id) return 1;
    if (b.market_history_id < a.market_history_id) return -1;
    if (a.price < b.price) return 1;
    if (b.price < a.price) return -1;
    return 0;
  }).slice(0, 50).forEach( (t, i) => {
    listTable.push({
      id: t.tradeId,
      type: t.type,
      arrow: (
        <span>
          {
            i < trades.length - 1 ? (
              trades[i + 1].price > t.price ? MarketDownArrow(t.tradeId) :
                trades[i + 1].price < t.price ? MarketUpArrow(t.tradeId) : ``
            ) : ``
          }
        </span>
      ),
      exch: (
        (t.exchCode || t.exchange) &&
        <span className='exch-container'>
          <img
            className={ `exch-img` }
            src={ window.WWW_URL + `/assets/img/exchange/` + (t.exchCode || t.exchange) + `-icon.png` } 
            width={ `12rem` }
            height={ `12rem` }/>
          <label className='exch-name'>
            { exchanges.find((e) => e.exchCode === t.exchCode || e.exchCode === t.exchange)?.exchName }
          </label>
        </span>
      ),
      price: (
        <span
          className={ zeroStyle }
          style={ { cursor: `pointer` } }
          dangerouslySetInnerHTML={ toFixedDecimalsHTML((market.exchCode == `LSCX` ? adjustTradePrice(t.price, t.type) : t.price), false, `price`, market, zeroStyle === `none`) }
          onClick={ () => {
            emitEvent(MARKETS_PRICE_CLICK, {
              price: (market.exchCode == `LSCX` ? adjustTradePrice(t.price, t.type) : t.price)
            });
          } } />
      ),
      quantity: <span
        className={ zeroStyle }
        dangerouslySetInnerHTML={ 
          toFixedDecimalsHTML(t.quantity, true, `quantity`, market, zeroStyle === `none`) 
        } />,
      time: +new Date(t.time)
    });
  });

  const entityTableColumn = [
    {
      title: ``,
      key: `arrow`,
      thClass: `arrow-col`,
      tdClass: `arrow-col`,
    },
    {
      title: t(`app:price`),
      key: `price`,
      tdClass: `nowrap`,
    },
    {
      title: t(`app:qty`),
      key: `quantity`,
      tdClass: `inline-dot nowrap`,
      display: (row, data) => (
        <span>
          { data }
          { ` ` }
          <span className={ row.type.toLowerCase() == `buy` ? `green` : `red` }>
              &bull;
          </span>
        </span>
      )
    },
    {
      title: t(`app:time`),
      key: `time`,
      display: (row, data) => (
        <span>
          { toLocalDate(new Date(data), `%h%h:%m%m:%s%s`) }
        </span>
      )
    }
  ]; 

  // add the exchanges' icons when the market is LSCX 
  // because we source trades from multiple exchanges to make up the feed.
  if (market && market.exchCode === `LSCX`) {
    entityTableColumn.push({
      title: `EX`,
      key: `exch`,
      thClass: `exch-col`,
      tdClass: `align-right icon-col`
    });
  }

  return (
    <div className="streaming-trades-list">
      {
        market.exchCode === `LSCX` && 
        (
          <>
            <div
              className="executions"
              onClick={ () => emitEvent(SHOW_FAQS_MODAL) } >
              { t(`markets:executionsWithoutFees`) }
&nbsp;
              <img src={ `/assets/img/figma/Info.svg` } width="8" />
            </div>
          </>          
        ) 
      }
      <EntityTable
        columns={ entityTableColumn }
        data={ listTable }
        defaultSortBy="-time"
        hidePagination={ true } />
    </div>
  );
};

export { StreamingTradesList as PureStreamingTradesList };
export default translate(`markets`)(StreamingTradesList);

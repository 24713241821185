// @flow
'use strict';

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import { updateTitle } from '../helpers/BrowserTitleHelper.js';
import { NavLink } from 'react-router-dom';
import AlertBell from '../svgs/AlertBell.jsx';
import Interface from '../svgs/Interface.jsx';
import Cloud from '../svgs/Cloud.jsx';
import ProfileDataLayer from '../components/user/ProfileDataLayer.jsx';
import ScrollableArea from '../components/utilities/ScrollableArea.jsx';
import AccountAPI from '../components/user/AccountAPI.jsx';
import { default as InterfaceComponent } from '../components/settings/Interface.jsx';
import Notifications from '../components/settings/Notifications.jsx';
import type { Market } from '../types/Market.js';
import type { Exchange } from '../types/Exchange.js';
import type { Account } from "../types/Account.js";

type Props = {
  t: any,
  exchanges: Array<Exchange>,
  markets: Array<Market>,
  accounts: Array<Account>,
  params: {
    tab: string,
  },
  userInfo: any,
};

const REDIRECT_TO_V1 = [`api`];

const SettingsPage = ({ t, params: { tab }, markets, exchanges, accounts, userInfo }: Props) => {
  const isLscxUser = (userInfo.platformId === 1);
  updateTitle(``, userInfo.platformId);

  const tabs: Array<{
    name: string,
    text: string,
    v1?: string,
    icon: any,
    component?: any,
    hideFromNav?: boolean
  }> = [
    {
      name: `interface`,
      text: t(`settings:interface`),
      icon: Interface,
      component: <InterfaceComponent t />
    },
    {
      name: `notifications`,
      text: `Notification options`,
      icon: AlertBell,
      component: <Notifications t />
    },
  ];

  if (!isLscxUser) {
    const apiTab = {
      name: `api`,
      text: t(`header:api`),
      v1: `${ window.WWW_URL }/user/account#api`,
      icon: Cloud,
      component: <AccountAPI t />
    };

    tabs.push(apiTab);
  }

  return (
    <div className="settings-page cols user-profile-cols">
      <aside>
        <h2>
          { t(`header:settings`) }
        </h2>
        <ul className="header-tab">
          {
            tabs.filter((tab) => !tab.hideFromNav).map((t, i) => (
              <li key={ i }>
                {
                  REDIRECT_TO_V1.indexOf(t.name) > -1 &&
                  !(process.env.NODE_ENV === `development` || process.env.STAGING) ? (
                      <a
                        href={ t.v1 }
                        rel="noopener noreferrer"
                        target="_blank">
                        { t.icon }
                        <span>
                          { t.text }
                        </span>
                      </a>
                    ) : (
                      <NavLink
                        to={ `/settings/${ t.name }` }
                        activeClassName="active">
                        { t.icon }
                        <span>
                          { t.text }
                        </span>
                      </NavLink>
                    )
                }
              </li>
            ))
          }
        </ul>
      </aside>
      <section>
        <ScrollableArea>
          <ProfileDataLayer
            t={ t }
            activeTab={ tabs.filter((t) => t.name == tab)[0] }
            markets={ markets }
            accounts={ accounts }
            exchanges={ exchanges } />
        </ScrollableArea>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.userInfo.user,
});

export { SettingsPage as PureSettingsPage };
export default withRouter(connect(mapStateToProps)(translate(`user`)(SettingsPage)));



// @flow
'use strict';

import { forceUTCParsing } from "./DateHelper.js";
import { CURRENCY_MAP } from "../constants/balances.js";
import * as balanceApi from './api/BalanceApi';
import type { Account } from '../types/Account';
import type { Balance } from '../types/Balance';

export function sortByDate(result: Array<{ balanceDate: string }>): Array<any> {
  return result.map((item) => ({
    ...item,
    date: new Date(forceUTCParsing(item.balanceDate)).getTime(),
    balanceDate: Date.parse(item.balanceDate),
  }))
    .sort((a, b) => {
      return a.date <= b.date ? -1 : 1;
    });
}

export function getBalancesData(params: { [key: string]: any }): Promise<any> {
  return new Promise((resolve, reject) => {
    balanceApi.getBalancesHistory(
      { params },
      ({ result: currencyResult, success, error }) => success ?
        resolve(sortByDate(currencyResult)) : reject(error),
      (...args) => reject(args)        
    );
  });
}

export function getOhlc({ 
  selectedQuoteCurrencyCode, ...params 
}: { 
    selectedQuoteCurrencyCode: string, [key: string]: any 
  }): Promise<any> {
  if (CURRENCY_MAP.has(selectedQuoteCurrencyCode.toUpperCase())) {
    const currency = CURRENCY_MAP.get(
      selectedQuoteCurrencyCode.toUpperCase()
    );
    return new Promise((resolve, reject) => {
      balanceApi.getOhlc(
        { exchange: currency.exchange, market: currency.market, data: `d`, params },
        ({ result, success, error }) => success ? resolve(result) : reject(new Error(error)),
        (...args) => reject(args)
      );
    });
  }
  return Promise.resolve([]);
}

export function mapCurrencyHistoryData(currencyHistoryData: Array<any>, ohlcData: Array<any>): Map<string, Array<any>> {

  let res = currencyHistoryData.reduce((map: Map<string, Array<any>>, currentItem) => {
    const item = { ...currentItem },
      key = item.balanceCurrCode;

    let current = map.get(key),
      currencyPrice = 1;

    const searchCurrencyPrice = ohlcData
      .find(({ timeStart, timeEnd }) => new Date(item.balanceDate).getTime() >= new Date(timeStart).getTime()
        && new Date(item.balanceDate).getTime() <= new Date(timeEnd).getTime());

    if (searchCurrencyPrice) {
      currencyPrice = searchCurrencyPrice.close;
    }

    if (!searchCurrencyPrice && ohlcData.length > 0) {
      currencyPrice = ohlcData[ohlcData.length - 1].close;
    }

    item.quoteBalance = item.quoteBalance * currencyPrice;

    if (map.has(key) && current) {
      const index = current.findIndex((balance) => balance.balanceDate === item.balanceDate);

      if (index < 0) {
        current = [item].concat(...current);
      } else {
        current[index].quoteBalance += item.quoteBalance;
      }

      map.set(key, current);
    } else {
      map.set(key, [item]);
    }

    return map;
  }, new Map());

  return res;
}

export function setBalanceFormat (balanceCurrCode: string): string {
  return  balanceCurrCode === `USD` ? `two_decimals` : `price`;
} 


export function getBalanceTotal (balances: Array<Balance>, balanceFilters: Array<Balance>, selectedQuoteCurrencyCode: string): number {
  const firstBalanceQuote = balances[0] ? balances[0].balanceQuoteCurrCode : ``;
  if (typeof selectedQuoteCurrencyCode === `string` || selectedQuoteCurrencyCode instanceof String ) {
    if (selectedQuoteCurrencyCode.toUpperCase() == firstBalanceQuote.toUpperCase()) {
      const filteredBalances = balances.filter((filterBalance) => !balanceFilters.find((balance) =>
        balance.balanceAuthId === filterBalance.balanceAuthId && balance.balanceCurrId === filterBalance.balanceCurrId
      )
      );
      return filteredBalances.reduce((total, value) => total + value.quoteBalance, 0);
    } else {
      return 0;
    }
  } else {
    return 0;
  }
}


export function addZeroBalance (accounts: Array<Account>, balances: Array<Balance>): Array<Balance> {
  const fullBalances = [...balances];

  accounts.map((account) => {
    if (balances.find((bal) => bal.balanceAuthId === account.authId) == null) {
      const zeroBalance = {
        balanceAmountAvailable: 0,
        balanceAmountHeld: 0,
        balanceAmountTotal: 0,
        balanceAuthId: account.authId,
        balanceCurrCode: `No Balances Detected.`,
        balanceCurrId: 0,
        balanceDate: `2021-03-04T18:52:45Z`,
        balanceHidden: false,
        balanceId: Math.floor(100000000 + Math.random() * 900000000),
        balanceQuoteCurrCode: `USD`,
        quoteBalance: 0,
        fiat: false,
        hasImage: false,
        lastPrice: 0,
        lastTrade: 0
      };
      fullBalances.push(zeroBalance);
    }
  });

  return fullBalances;
}

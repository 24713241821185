// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import Modal from '../utilities/Modal.jsx';

type Props = {
  t: any,
  type: string,
  max: number,
  cancel: () => void
};

const UpgradeRequiredModal = ({ t, cancel, max, type }: Props) => (
  <Modal
    title={ t(`accountAccountRequired`) }
    onConfirm={ () => {
      // $FlowIgnore: suppressing this error
      window.location = `${ process.env.WWW_URL }/subscription`;
    } }
    onClose={ cancel }
    confirmText={ t(`upgrade`) }
    cancelText={ t(`app:cancel`) }>
    <p>
      { t(`reachedLimit`) }
      { ` ` }
      { max }
      { ` ` }
      { type }
      { max !== 1 ? `s` : ``  }
      { type == `panel` ? ` ` + t(`reachedLimit2`) : `` }
      { `. ` }
      { t(`upgradeSubscription`) }
      { ` ` }
      { type }
      { `s.` }
    </p>
  </Modal>
);


export { UpgradeRequiredModal as PureUpgradeRequiredModal };
export default translate(`boards`)(UpgradeRequiredModal);

'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<circle cx="8" cy="8" r="6.5" stroke="currentColor" fill="none" fill-opacity="0"/>
  <path d="M15 8C15 11.866 11.866 15 8.00001 15V1C11.866 1 15 4.13401 15 8Z" fill="currentColor"/>`,
  {
    width: `16px`,
    height: `16px`,
    viewBox: `0 0 16 16`
  }
);
/* eslint-enable */

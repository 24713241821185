import { convertScinotToDecimal } from './NumberHelper.js';

export const mapRawAlertsToActiveAlerts = (rawAlerts) => {
  let alerts = rawAlerts.map((raw) => {
    if(raw.type == 0){
      let direction = raw.condition === `GreaterThanOrEqual` ? `above` : `below`;
      let alert = {
        alertId: raw.alertId,
        exchMktId: raw.exchMktId,
        exchange: raw.exchangeName,
        market: raw.marketName,
        type: `PRICE`,
        note: raw.note,
        sound: raw.sound,
        time: raw.createdOn,
        criteria: `Price goes ${direction} ${convertScinotToDecimal(raw.price)}`,
        interval: ``,
        numCandles: 0,
        data: raw
      };
      return alert;
    } else if(raw.type == 1){
      let alert = {
        alertId: raw.alertId,
        exchMktId: raw.exchMktId,
        exchange: raw.exchangeName,
        market: raw.marketName,
        type: `VOLUME`,
        note: raw.note,
        sound: raw.sound,
        time: raw.createdOn,
        criteria: `Current volume crossed ${raw.numCandles}x${raw.interval} VMA by
                    ${convertScinotToDecimal(raw.value)}${raw.condition == `PercentChange` ? `%` : ` ${raw.marketName.split(`/`)[0]}`}`,
        interval: raw.interval,
        numCandles: raw.numCandles,
        data: raw
      };
      return alert;
    } else {
      throw new Error(`Unexpected alert type: ${raw.type}`);
    }
  });

  return alerts;
};

export const mapRawHistoryAlertsToHistoryAlerts = (rawAlerts) => {
  let alerts = rawAlerts.map((raw) => {
  
    if (raw.type == 0)
    {
      let direction = raw.condition === `GreaterThanOrEqual` ? `above` : `below`;
      let alert = {
        alertId: raw.alertId,
        exchMktId: raw.exchMktId,
        exchange: raw.exchangeName,
        market: raw.marketName,
        type: `PRICE`,
        note: raw.note,
        sound: raw.sound,
        time: raw.triggeredOn,
        criteria: `Price goes ${direction} ${convertScinotToDecimal(raw.price)}`,
        data: raw
      };
      return alert;
    } else if(raw.type == 1){
      let alert = {
        alertId: raw.alertId,
        exchMktId: raw.exchMktId,
        exchange: raw.exchangeName,
        market: raw.marketName,
        type: `VOLUME`,
        note: raw.note,
        sound: raw.sound,
        time: raw.triggeredOn,
        criteria: `Current volume crossed above ${raw.numCandles}x${raw.interval} VMA by
                    ${convertScinotToDecimal(raw.value)}${raw.condition == `PercentChange` ? `%` : ` ${raw.marketName.split(`/`)[0]}`}`,
        data: raw
      };
      return alert;
    } else {
      throw new Error(`Unexpected history alert type: ${raw.type}`);
    }
  });
    
  return alerts;
};
  

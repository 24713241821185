// @flow
'use strict';

import React, { useState } from 'react';
import { translate } from 'react-i18next';
import Checkbox from '../utilities/Checkbox.jsx';
import Modal from '../utilities/Modal.jsx';
import SelectField from '../utilities/SelectField.jsx';

type Props = {
  t: any,
  close: () => void,
  settings: {
    type: string,
    showVolume: boolean,
    showVolumeProfile: boolean,
  },
  changeSettings: (obj: any) => void,
};

const DemoSettingsEditModal = (props: Props) => {
  const { t, close, changeSettings } = props;
  const [settings, setSettings] = useState(props.settings);
  
  const handleSettings = (obj) => {
    setSettings((prev) => ({ ...prev, ...obj }));
  };

  const confirmSettings = () => {
    changeSettings(settings);
    close();
  };

  return (
    <Modal
      title={ `Settings` }
      onConfirm={ confirmSettings }
      onClose={ close }
      confirmText={ t(`app:confirm`) }>
      <div className='settings-container'>
        <div className="show-volume">
          <Checkbox 
            label={ `Show Volume` } 
            name="show-volume" 
            value={ settings.showVolume }
            onChange={ () => handleSettings({ 'showVolume': !settings.showVolume })  }/>
        </div>
        <div className="show-volume-profile">
          <Checkbox 
            label={ `Show Volume Profile` } 
            name="show-volume-profile" 
            value={ settings.showVolumeProfile }
            onChange={ () => handleSettings({ 'showVolumeProfile': !settings.showVolumeProfile })  }/>
        </div>
        <div className="type">
          <SelectField
            searchable={ false }
            label={ `Chart Type` }
            name={ `type` }
            value={ settings.type }
            options={ [
              { label: `Area`, value: `area` },
              { label: `Candlestick`, value: `candlestick` },
              { label: `Line`, value: `line` },
            ] }
            onChange={ (e, v) => handleSettings({ 'type': v }) }/>
        </div>
      </div>  
    </Modal>
  );
};


export { DemoSettingsEditModal as PureDemoSettingsEditModal };
export default translate(`panel`)(DemoSettingsEditModal);

// @flow
'use strict';

const months = [ `January`, `February`, `March`, `April`, `May`, `June`, `July`,
    `August`, `September`, `October`, `November`, `December`],
  days = [`Sunday`, `Monday`, `Tuesday`, `Wednesday`, `Thursday`, `Friday`, `Saturday`];

const toLocalDate = (date: any, format: string = `%M%M/%D%D/%Y%Y%Y%Y %h%h:%m%m:%s%s`) => {
  if (date == null) return ``;

  date = forceUTCParsing(date);

  const _date = new Date(date);
  const month = _date.getMonth();
  const day = _date.getDate();
  const year = _date.getFullYear();
  const hour = _date.getHours();
  const minutes = _date.getMinutes();
  const seconds = _date.getSeconds();

  format = format.replace(`%Y%Y%Y%Y`, `${ year }`);
  format = format.replace(`%Y%Y`, `${ year.toString().substr(2, 2) }`);

  format = format.replace(`%M%M%M%M`, months[month]);
  format = format.replace(`%M%M`, `${ month + 1 }`.length == 1 ? `0${ month + 1 }` : `${ month + 1 }`);
  format = format.replace(`%M`, `${ month + 1 }`);

  format = format.replace(`%D%D%D%D`, days[day]);
  format = format.replace(`%D%D`, `${ day }`.length == 1 ? `0${ day }` : `${ day }`);
  format = format.replace(`%D`, `${ day }`);

  format = format.replace(`%h%h`, `${ hour }`.length == 1 ? `0${ hour }` : `${ hour }`);
  format = format.replace(`%h`, `${ hour }`);

  format = format.replace(`%m%m`, `${ minutes }`.length == 1 ? `0${ minutes }` : `${ minutes }`);
  format = format.replace(`%m`, `${ minutes }`);

  format = format.replace(`%s%s`, `${ seconds }`.length == 1 ? `0${ seconds }` : `${ seconds }`);
  format = format.replace(`%s`, `${ seconds }`);

  return format;
};

const forceUTCParsing = (date: any) => {
  if (typeof date == `string` && date[date.length - 1] !== `Z`) date = `${ date }Z`.split(` `).join(`T`);
  if (typeof date == `object` && date.toISOString()[date.toISOString().length - 1] !== `Z`) {
    date = `${ date.toISOString() }Z`.split(` `).join(`T`);
  }
  return date;
};

export { toLocalDate, forceUTCParsing };

// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import PageVisibility from 'react-page-visibility';
import { subscribe, subscribeToAggregatedMarkets, unsubscribe } from '../../helpers/SocketClusterHelper.js';
import {
  emitEvent,
  listenForEvent,
  removeEventListener,
  PAGE_SHORTCUT,
  ENTER,
  SHOW_KEYBOARD_LEGEND,
  MARKET_BUY_MODE_DEACTIVATED,
  MARKET_SELL_MODE_DEACTIVATED,
  MARKET_ALERT_MODE_DEACTIVATED,
  DATA_MODE_REQUESTED,
  ALERT_MODE_REQUESTED,
  BUY_MODE_REQUESTED,
  BUY_MODE_REQUESTED_WITH_PRICE,
  BUY_MODE_REQUESTED_WITH_STOP_PRICE,
  SELL_MODE_REQUESTED,
  SELL_MODE_REQUESTED_WITH_PRICE,
  SELL_MODE_REQUESTED_WITH_STOP_PRICE,
  SWITCH_TO_SELL_MODE,
  SWITCH_TO_SELL_MODE_WITH_PRICE,
  SWITCH_TO_SELL_MODE_WITH_STOP_PRICE,
  SWITCH_TO_BUY_MODE,
  SWITCH_TO_BUY_MODE_WITH_PRICE,
  SWITCH_TO_BUY_MODE_WITH_STOP_PRICE,
  DELETE_BUY_NODE,
  DELETE_BUY_STOP_NODE,
  DELETE_SELL_NODE,
  DELETE_SELL_STOP_NODE,
  ALERT_ADDED,
  ALERT_EDITED,
  ORDER_ADDED,
  ORDER_ADDED_FAILED,
  PENDING_ORDER_ADDED,
  MARKETS_PRICE_CLICK,
  PRICE_CHANGE_REQUESTED,
  REFRESH_ORDERS,
  MARKET_RECEIVED_NEW_TRADE,
  NOTIFICATION,
  SILENT_NOTIFICATION,
  START_TOUR,
  ADVANCE_TOUR,
  TOUR_COMPLETED
} from '../../helpers/EventHelper.js';
import { getExchangeTradeApiVersion } from '../../helpers/ExchangeTradingHelper';
import { getMarketPair } from '../../helpers/MarketPairHelper.js';
import { forceUTCParsing } from '../../helpers/DateHelper.js';
import { updateTitle } from '../../helpers/BrowserTitleHelper.js';
import { toFixedDecimals } from '../../helpers/NumberHelper.js';
import MarketHeader from './MarketHeader.jsx';
import MarketChart from './MarketChart.jsx';
import MarketInfo from './MarketInfo.jsx';
import Tabs from '../utilities/Tabs.jsx';
import { FlexColumn } from '../utilities/FlexColumn.jsx';
import MarketOrders from './MarketOrders.jsx';
import CollapseLeft from '../../svgs/CollapseLeftAlt.jsx';
import CollapseRight from '../../svgs/CollapseRightAlt.jsx';
import { updateFavorites } from '../../actions/app/updateFavorites.js';
import { updateRedisPrefs } from '../../actions/redisPrefs/updateRedisPrefs.js';
import { updateMarketsInfoActiveTab } from '../../actions/markets/updateMarketsInfoActiveTab.js';
import { updateMarketsOrdersActiveTab } from '../../actions/markets/updateMarketsOrdersActiveTab.js';
import { updateChartsActiveTab } from '../../actions/markets/updateChartsActiveTab.js';
import { updateBOHActiveTab } from '../../actions/markets/updateBOHActiveTab.js';
import { updateOrderFormActiveType } from '../../actions/markets/updateOrderFormActiveType.js';
import { updateAlertActiveType } from '../../actions/markets/updateAlertActiveType.js';
import { updateCurrentFavorite } from '../../actions/app/updateCurrentFavorite.js';
import { changeGlobalPageSize } from '../../actions/app/changeGlobalPageSize.js';
import { translateOrder } from '../../helpers/OrderTransformationHelper.js';
import CoinigyBaseComponent from '../CoinigyBaseComponent.jsx';
import * as exchangeApi from '../../helpers/api/ExchangeApi.js';
import * as balanceApi from '../../helpers/api/BalanceApi.js';
import * as userApi from '../../helpers/api/UserApi.js';
import * as orderApi from '../../helpers/api/OrderApi.js';
import * as alertApi from '../../helpers/api/AlertApi.js';
import type { Exchange } from '../../types/Exchange.js';
import type { Favorite } from '../../types/Favorite.js';
import type { Market } from '../../types/Market.js';
import type { Trade } from '../../types/Trade.js';
import type { MarketOrder } from '../../types/MarketOrder.js';
import type { Currency } from "../../types/Currency.js";
import type { Balance } from "../../types/Balance.js";
import type { Account } from "../../types/Account.js";
import type { Ticker } from "../../types/Ticker.js";
import type { Order } from '../../types/Order.js';
import type { Alert } from '../../types/Alert.js';
import ScrollableArea from '../utilities/ScrollableArea.jsx';
import MarketVerticalDepthChart from './MarketVerticalDepthChart.jsx';
import StreamingTradesList from './StreamingTradesList.jsx';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import BalancesOrdersHistory from '../markets/BalancesOrdersHistory.jsx';
import MarketSwitcher from './MarketSwitcher/MarketSwitcher.jsx';
import MarketSearch from './MarketSearch';
import MarketFlexlayout from './MarketFlexlayout.jsx';

// tour-specific imports
import Tour from 'reactour';
import { readCookie, writeCookie } from '../../helpers/CookieHelper.js';
import LogoMark from '../../svgs/LogoMark.jsx';
import { isMobile } from 'react-device-detect';
// import { isFullAccessUser } from '../../helpers/UserApplicationsHelper.js';

// only used for LSCX, removed
// import { adjustTradePrice } from '../../helpers/AdjustTradeHelper.js';
// import _ from 'lodash';
// end LSCX removed


const API_POLL_TICKER_MS = 90000,
  THROTTLE_MS = 100,
  CHART_TRADE_THROTTLE_MS = 200,
  TRADE_LIST_MAX_ITEMS = 50,
  INACTIVE_TAB_THROTTLE_MS = 300000,
  REFRESH_TAB_THROTTLE_MS = 3600000 * 24, //REFRESH TAB AFTER 24 HRS INACTIVITY
  INACTIVE_TAB_TITLEBAR_THROTTLE_MS = 200,
  TAB_WIDTH = 130;

type Props = {
  t: any,
  marketSwitcherClass: string,
  favorites: Array<Favorite>,
  exchanges: Array<Exchange>,
  currencies: Array<Currency>,
  accounts: Array<Account>,
  balances: Array<Balance>,
  markets: Array<Market>,
  aggregatedMarkets: [],
  active: {
    exchange: Exchange,
    market: Market
  },
  updateFavorites: Function,
  marketInfoOpen: boolean,
  marketSwitcherOpen: boolean,
  ordersTableOpen: boolean,
  showLargerOrdersTable: boolean,
  marketChartOpen: boolean,
  updatePrefs: (obj: any) => void,
  activeTab: number,
  activeOrdersTab: number,
  activeChartsTab: number,
  activeBOHTab: number,
  alertType: number,
  orderFormType: string,
  updateTab: (tab: number) => void,
  updateOrdersTab: (tab: number) => void,
  updateChartsTab: (tab: number) => void,
  updateBOHTab: (tab: number) => void,
  updateOrderFormType: (orderFormType: string) => void,
  updateAlertType: (alertType: number) => void,
  history: Array<{
    exchCode: string,
    displayName: string
  }>,
  updateCurrentFavorite: Function,
  toggleOpen: Function,
  priceClick: string,
  zeroStyle: string,
  depthStyle: string,
  theme: string,
  marketsAreClickable: boolean,
  orderTypes: *,
  platformId: number,
  size: string,
  orderPageSize: any,
  alertPageSize: any,
  isMarketsFlexlayout: boolean,
  // prefSubscriptionExpires: string,
  // prefSubscriptionId: any,
  // prefTrialUsed: boolean,
  subscriptionStatus: string,
  marketSwitcherVersion: string,
  rehydrated: boolean,
};

type State = {
  exchFilter: string,
  mktFilter: string,
  authFilter: string,
  trades: Array<Trade>,
  buys: Array<MarketOrder>,
  sells: Array<MarketOrder>,
  notes: string,
  baseCurrency: Currency,
  quoteCurrency: Currency,
  researchCurrency: string,
  lastPrice: number,
  updateNotesTimeout: any,
  apiInterval: any,
  throttleTimeout: any,
  marketInfoClass: string,
  ordersTableClass: string,
  marketChartClass: string,
  ticker: Ticker,
  range: {
    allTime: {
      high: number,
      low: number
    },
    "30D": {
      high: number,
      low: number
    },
    "7D": {
      high: number,
      low: number
    },
    "1D": {
      high: number,
      low: number
    }
  },
  tvcOpenOrders: Array<Order>,
  tvcOrderHistory: Array<Order>,
  activeAlerts: Array<Alert>,
  breakdownTabs: number,
  abbreviateHeader: boolean,
  useAnimation: boolean,
  currentAuthId: number,
  breakdownTimeout: any,
  useSmallCollapse: boolean,
  isVisible: boolean,
  timeVisibilityChanged: number,
  baseCurrencyItbSignals: any,
  quoteCurrencyItbSignals: any,
  cmCalCategories: any,
  cmCalCoins: any,
  showDepthOrdersAlerts: boolean,
  showTour: boolean,
  currentTourStep: number,
  openPrice: number,
  marketSwitcherPopover: boolean,
  marketInfoPopover: boolean,
  balancesOrdersHistoryPopover: boolean,
  ordersLastUpdateTime: number,
  isFullAccess: boolean,
  alertsLastUpdateTime: number,
  tourSelectedTabNodeId: string,
};

const default_currency = {
  currId: 0,
  currCode: ``,
  currName: ``,
  dateAdded: ``,
  fiat: false,
  description: ``,
  descriptionSource: ``,
  developerName: ``,
  urlLogo: ``,
  urlAnnThread: ``,
  urlWhitepaper: ``,
  urlWebsite: ``,
  urlWallet: ``,
  urlTwitter: ``,
  urlFacebook: ``,
  urlLinkedin: ``,
  urlGplus: ``,
  urlForum: ``,
  urlBlockExplorer: ``,
  miningAlgorithm: ``,
  miningReward: ``,
  miningType: ``,
  miningConfirmations: ``,
  miningConfirmationTime: ``,
  miningBlockTime: ``,
  miningTotalCirculation: ``,
  availableSupply: ``,
  marketCap: ``,
  ipoPrice: 0,
  launchDate: ``,
  hasImage: false,
  abbreviateHeader: false
};

let buffered_trades = [],
  last_orderbook_update = 0,
  last_titlebar_update = 0;

class MarketDataLayer extends CoinigyBaseComponent<Props, State> {
  kbShortcutHandler: (e: any) => void;
  tvMsgHandler: (e: any) => void;
  newAlertHandler: (e: any) => void;
  editedAlertHandler: (e: any) => void;
  newOrderHandler: (e: any) => void;
  newOrderFailedHandler: (e: any) => void;
  pendingOrderHandler: (e: any) => void;
  priceClickHandler: (e: any) => void;
  refreshOrdersHandler: () => void;
  resizeHandler: () => void;
  handleResizeTimeout: any;

  constructor(props: Props) {
    super(props);

    this.handleResizeTimeout = -1;
    this.kbShortcutHandler = this.handleKeyboardShortcut.bind(this);
    this.tvMsgHandler = this.handleTVMessage.bind(this);
    this.newAlertHandler = this.onAlertAdded.bind(this);
    this.editedAlertHandler = this.onAlertEdited.bind(this);
    this.newOrderHandler = this.onOrderAdded.bind(this);
    this.newOrderFailedHandler = this.onOrderAddedFailed.bind(this);
    this.pendingOrderHandler = this.onPendingOrderAdded.bind(this);
    this.priceClickHandler = this.onPriceClick.bind(this);
    this.refreshOrdersHandler = this.onRefreshOrders.bind(this);
    this.resizeHandler = this.handleWindowResize.bind(this);

    this.state = {
      ...this.getInitialState(),
      exchFilter: this.props.active.exchange.exchCode || `all`,
      mktFilter: this.props.active.market.displayName || `all`,
      authFilter: `all`,      
      tvcOpenOrders: [],
      tvcOrderHistory: [],
      activeAlerts: [],
      breakdownTabs: 3,
      abbreviateHeader: false,
      useAnimation: true,
      useSmallCollapse: false,
      marketSwitcherPopover: false,
      marketInfoPopover: false,
      balancesOrdersHistoryPopover: false,
      ordersLastUpdateTime: 0,
      isFullAccess: false,
      alertsLastUpdateTime: 0,
      tourSelectedTabNodeId: ``
    };
  }

  getInitialState(clearDataTab: boolean = true) {
    let ret = {
      trades: [],
      buys: [],
      sells: [],
      notes: ``,
      updateNotesTimeout: -1,
      baseCurrency: default_currency,
      quoteCurrency: default_currency,
      researchCurrency: `quoteCurrency`,
      lastPrice: 0,
      ticker: {
        volume: 0,
        last: 0,
        high: 0,
        low: 0,
        ask: 0,
        bid: 0
      },
      range: {
        allTime: {
          high: 0,
          low: 0
        },
        "30D": {
          high: 0,
          low: 0
        },
        "7D": {
          high: 0,
          low: 0
        },
        "1D": {
          high: 0,
          low: 0
        }
      },
      apiInterval: -1,
      throttleTimeout: -1,
      marketInfoClass: this.props.marketInfoOpen ? `` : `out`,
      ordersTableClass: ``,
      marketChartClass: ``,
      currentAuthId: 0,
      breakdownTimeout: -1,
      isVisible: true,
      timeVisibilityChanged: 0,
      baseCurrencyItbSignals: null,
      quoteCurrencyItbSignals: null,
      cmCalCategories: [],
      cmCalCoins: [],
      showDepthOrdersAlerts: true,
      showTour: this.state && this.state.showTour ? true : false,
      currentTourStep: this.state && this.state.currentTourStep ? this.state.currentTourStep : 0,
      openPrice: 0
    };

    if (!clearDataTab)  {
      ret.trades = this.state.trades;
      ret.buys = this.state.buys;
      ret.sells = this.state.sells;
    }

    return ret;
  }


  findBreakdownTabs(props: Props = this.props, prev: Props = this.props) {
    let middleCol = this.refs.middleCol;

    if (!middleCol) return;

    let width = middleCol.offsetWidth,
      windowWidth = window.innerWidth,
      fillerWidth = 40;

    if (props.marketSwitcherOpen) {
      windowWidth -= 152;

      if (prev.marketSwitcherOpen === false) {
        width -= 152;
      }
    }

    if (props.marketInfoOpen) {
      windowWidth -= 304;

      if (prev.marketInfoOpen === false) {
        width -= 304; 
      }
    }

    const abbreviateHeader = windowWidth <= 1280;

    if (abbreviateHeader) {
      fillerWidth -= 50;
    }


    // add margin of one of the node
    fillerWidth += 20;
    if (fillerWidth < 800) {
      fillerWidth = 800;
    }

    let breakdownTabs = Math.floor((width - fillerWidth) / TAB_WIDTH);

    if (windowWidth <= 960) {
      breakdownTabs = 1;
    }

    if (windowWidth <= 840) {
      breakdownTabs = 0;
    }

    clearTimeout(this.state.breakdownTimeout);

    this.setStateSafe({
      useAnimation: false,
      breakdownTabs,
      abbreviateHeader,
      breakdownTimeout: setTimeout(() => this.setStateSafe({ useAnimation: true }), 500)
    });
  }

  updateSmallCollapse() {
    this.setStateSafe({
      useSmallCollapse: window.innerHeight < 850
    });
  }

  componentDidMount() {
    this._isMounted = true;
    this.getData(this.props.active);
    this.handleWindowResize(true);

    listenForEvent(PAGE_SHORTCUT, this.kbShortcutHandler);
    listenForEvent(DATA_MODE_REQUESTED, this.tvMsgHandler);
    listenForEvent(ALERT_MODE_REQUESTED, this.tvMsgHandler);
    listenForEvent(BUY_MODE_REQUESTED, this.tvMsgHandler);
    listenForEvent(SELL_MODE_REQUESTED, this.tvMsgHandler);
    listenForEvent(BUY_MODE_REQUESTED_WITH_PRICE, this.tvMsgHandler);
    listenForEvent(SELL_MODE_REQUESTED_WITH_PRICE, this.tvMsgHandler);
    listenForEvent(BUY_MODE_REQUESTED_WITH_STOP_PRICE, this.tvMsgHandler);
    listenForEvent(SELL_MODE_REQUESTED_WITH_STOP_PRICE, this.tvMsgHandler);
    listenForEvent(ALERT_ADDED, this.newAlertHandler);
    listenForEvent(ALERT_EDITED, this.editedAlertHandler);
    listenForEvent(ORDER_ADDED, this.newOrderHandler);
    listenForEvent(ORDER_ADDED_FAILED, this.newOrderFailedHandler);
    listenForEvent(PENDING_ORDER_ADDED, this.pendingOrderHandler);
    listenForEvent(MARKETS_PRICE_CLICK, this.priceClickHandler);
    listenForEvent(REFRESH_ORDERS, this.refreshOrdersHandler);
    listenForEvent(NOTIFICATION, (e: any) => {
      switch (e.detail.type) {
      // Alert notification
      case 3: // alert fired
      case 216: // price or volume alert added
      case 223: // volume alert fired
        this.getUserAlerts();
        this.refreshAlertsLastUpdateTime();
        break;
      }
    });
    listenForEvent(SILENT_NOTIFICATION, (e: any) => {
      
      switch (e.detail.type) {
      case 221: // orders
        this.getMarketExchOpenOrders();
        this.getTVCOrderHistory();
        this.refreshOrdersLastUpdateTime();
        break;
      }
    });
    listenForEvent(START_TOUR, (e: any) => {
      if (e.detail == `markets`) {
        this.toggleTour(true);
      }
    });
    listenForEvent(ADVANCE_TOUR, () => {
      this.advanceTour();
    });
    
    // Tour - if markets page tour hasn't been completed, show it when page loads
    let isTourCompleted = readCookie(`markets_tour_completed`);
    if (!isTourCompleted && !isMobile && this.props.platformId === 0) {
      //this.setState({ showTour: true });
      emitEvent(START_TOUR, `markets`);
    }
    
    window.addEventListener(`resize`, this.resizeHandler);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.active.market.exchmktId !== nextProps.active.market.exchmktId) {
      this.getData(nextProps.active, this.props.active);
    }

    if (this.props.marketInfoOpen !== nextProps.marketInfoOpen
      || this.props.marketSwitcherOpen !== nextProps.marketSwitcherOpen) {

      if (this.props.marketInfoOpen !== nextProps.marketInfoOpen) {
        this.toggleOpen(`marketInfoOpen`, nextProps, false, nextProps.marketInfoOpen);
      }

      if (
        (this.props.marketInfoOpen && !nextProps.marketInfoOpen) ||
        (this.props.marketSwitcherOpen && !nextProps.marketSwitcherOpen)
      ) {
        setTimeout(this.findBreakdownTabs.bind(this, nextProps), 300);
      } else {
        this.findBreakdownTabs(nextProps);
      }
    }

    if (nextProps.rehydrated) {
      // const isFullAccess = isFullAccessUser(nextProps.platformId, nextProps.prefSubscriptionExpires, nextProps.prefSubscriptionId, nextProps.prefTrialUsed);
      const isFullAccess = nextProps.platformId === 1 || ([`lifetime`, `active`].includes(nextProps.subscriptionStatus) && nextProps.platformId === 0);
      if (this.state.isFullAccess !== isFullAccess ) {
        this.setStateSafe({ isFullAccess: isFullAccess });
      }
    } 
  }

  componentWillUnmount() {
    this._isMounted = false;
    unsubscribe();
  
    clearInterval(this.state.apiInterval);
    clearTimeout(this.state.throttleTimeout);
    removeEventListener(PAGE_SHORTCUT, this.kbShortcutHandler);
    removeEventListener(DATA_MODE_REQUESTED, this.tvMsgHandler);
    removeEventListener(ALERT_MODE_REQUESTED, this.tvMsgHandler);
    removeEventListener(BUY_MODE_REQUESTED, this.tvMsgHandler);
    removeEventListener(SELL_MODE_REQUESTED, this.tvMsgHandler);
    removeEventListener(BUY_MODE_REQUESTED_WITH_PRICE, this.tvMsgHandler);
    removeEventListener(SELL_MODE_REQUESTED_WITH_PRICE, this.tvMsgHandler);
    removeEventListener(BUY_MODE_REQUESTED_WITH_STOP_PRICE, this.tvMsgHandler);
    removeEventListener(SELL_MODE_REQUESTED_WITH_STOP_PRICE, this.tvMsgHandler);
    removeEventListener(PENDING_ORDER_ADDED, this.pendingOrderHandler);
    removeEventListener(ALERT_ADDED, this.newAlertHandler);
    removeEventListener(ALERT_EDITED, this.editedAlertHandler);
    removeEventListener(ORDER_ADDED, this.newOrderHandler);
    removeEventListener(ORDER_ADDED_FAILED, this.newOrderFailedHandler);
    removeEventListener(MARKETS_PRICE_CLICK, this.priceClickHandler);
    removeEventListener(REFRESH_ORDERS, this.refreshOrdersHandler);
    window.removeEventListener(`resize`, this.resizeHandler);
  }

  handleWindowResize(immediate: boolean = false) {
    clearTimeout(this.handleResizeTimeout);

    if (immediate) {
      this.findBreakdownTabs();
      this.updateSmallCollapse();
    } else {
      this.handleResizeTimeout = setTimeout(() => {
        this.findBreakdownTabs();
        this.updateSmallCollapse();
      }, 500);
    }
  }

  shouldApplyUpdates = () => {
    if(this.state.timeVisibilityChanged == 0) return true;
    return this.state.isVisible === true || (this.state.isVisible == false &&  Date.now()-this.state.timeVisibilityChanged < INACTIVE_TAB_THROTTLE_MS);
  }


  handleVisibilityChange = (isVisible: boolean) => {
    let timeNow = Date.now();
    let timeVisibilityChanged = this.state.timeVisibilityChanged;

    this.setStateSafe({
      isVisible,
      timeVisibilityChanged: timeNow
    });

    if(isVisible && timeNow-timeVisibilityChanged > REFRESH_TAB_THROTTLE_MS){
      window.location.reload();
    }
    
    if(isVisible && timeNow-timeVisibilityChanged > INACTIVE_TAB_THROTTLE_MS){
      this.getStaleData(this.props.active);
    }

  }


  getStaleData(
    { exchange, market }: { market: Market, exchange: Exchange }) {

    clearTimeout(this.state.throttleTimeout);
    buffered_trades = [];

    this.setStateSafe({
      throttleTimeout: setTimeout(() => {
        this.applyTradeBuffer(market, exchange);
      }, THROTTLE_MS)
    });

    this.getTickerData(market, exchange);
    this.getHistoricalRange(market, exchange);
    this.getMarketDepthData(market, exchange);

    exchangeApi.getTrades(exchange.exchCode, market.marketName, (data) => {
      if (data.success) {
        let trades = this.sortTrades(this.state.trades.concat(data.result));

        if (trades && trades.length) {

          // Adjust time & sales if the exchange is LSCX - removed
          // if (exchange.exchCode == `LSCX`) {
          //   trades.forEach((t, i) => {
          //     trades[i].price = adjustTradePrice(t.price, t.type);
          //   });
          // }

          this.setStateSafe({
            trades,
            lastPrice: trades[0].price
          });
        }

        this.updateCurrentFavorite(exchange, market, trades);
      }
    });
  }

  subscribeCallback(channels: any, market: any) {
    for (const channel in channels) {
      if (channels[channel].orders) {
        channels[channel].orders.watch((d) => {
          if (channel.includes(`LSCX`) || (new Date().getTime() - last_orderbook_update > THROTTLE_MS && this.shouldApplyUpdates())) {
            this.setStateSafe({
              buys: this.compileOrderData(
                this.sortOrders(d.filter((o) => o.ordertype == `Buy`)),
                this.sortOrders(this.state.buys),
                `buys`
              ),
              sells: this.compileOrderData(
                this.sortOrders(d.filter((o) => o.ordertype == `Sell`)),
                this.sortOrders(this.state.sells),
                `sells`
              )
            }, () => {
              last_orderbook_update = new Date().getTime();
            });
          }
        });
      }
    }
    for (const channel in channels) {
      if (channels[channel].trades) {
        channels[channel].trades.watch((d) => {            
          if(d.exchange != channel.split(`:`)[0] || d.label != market.marketName){
            return;
          }
          buffered_trades.push({
            ...d,
            time: forceUTCParsing(d.time_local),
            tradeId: d.tradeid
          });
          //applyTradeBuffer() WILL NOT UPDATE lastPrice IN STATE IF IT ISN'T GOING TO APPLY UPDATES
          if (this.shouldApplyUpdates() === false &&
                (new Date().getTime()) - last_titlebar_update > INACTIVE_TAB_TITLEBAR_THROTTLE_MS &&
                d.price != this.state.lastPrice) {
            this.setStateSafe({ lastPrice: d.price }, () => last_titlebar_update = new Date().getTime());
          }
        });
      }
    }
  }
  
  getData({ exchange, market }: { market: Market, exchange: Exchange }, last?: { market: Market, exchange: Exchange }) {
    let clearDataTab = false;

    if (last) {
      const lastRealtimeMktName =
        last.market.exchCode == `DERI` ?
          last.market.marketName.substr(4, last.market.marketName.length) :
          last.market.marketName;

      let marketsToUnsubscribe = [];
      if (last.exchange.exchCode === `LSCX`) {
        marketsToUnsubscribe = this.props.aggregatedMarkets.filter((agM) => agM.marketName === last.market.marketName)[0].aggregatedMarkets.map((selectedAggregatedMarket) => {
          const [exchCode, marketName] = selectedAggregatedMarket.split(`:`);
          return {
            type: `market`,
            data: [exchCode, marketName]
          };
        });
        marketsToUnsubscribe.push({ type: `market`, data: [last.market.exchCode, lastRealtimeMktName] });
      } else {
        marketsToUnsubscribe = [{ type: `market`, data: [last.market.exchCode, lastRealtimeMktName] }];
      }
      unsubscribe(marketsToUnsubscribe);
      clearDataTab = true;
    }

    clearInterval(this.state.apiInterval);
    clearTimeout(this.state.throttleTimeout);
    buffered_trades = [];

    this.setStateSafe({
      ...this.getInitialState(clearDataTab),
      apiInterval: setInterval(() => {
        this.getTickerData(market, exchange);
        this.getHistoricalRange(market, exchange);
        this.getMarketDepthData(market, exchange);
        this.getMarketExchOpenOrders();
        this.getTVCOrderHistory();
        this.getUserAlerts();
      }, API_POLL_TICKER_MS),
      throttleTimeout: setTimeout(() => {
        this.applyTradeBuffer(market, exchange);
      }, THROTTLE_MS)
    }, async () => { // <-- set state callback vv
      this.getTickerData(market, exchange);
      this.getHistoricalRange(market, exchange);
      this.getMarketDepthData(market, exchange);
      this.getNotes();
      this.getMarketExchOpenOrders();
      this.getTVCOrderHistory();
      this.getUserAlerts();

      let realtimeMktName = 
          market.exchCode == `DERI` ?
            market.marketName.substr(4, market.marketName.length) :
            market.marketName;

      let marketsToSubscribe = [];
      if (this.props.aggregatedMarkets.length > 0 && market.exchCode === `LSCX`) {
        marketsToSubscribe = this.props.aggregatedMarkets.filter((agm) => agm.marketName === market.marketName)[0].aggregatedMarkets.map((selectedAggregatedMarket) => {
          const [exchCode, marketName] = selectedAggregatedMarket.split(`:`);
          return {
            type: `market`,
            data: [exchCode, marketName]
          };
        });
      } else {
        marketsToSubscribe = [{ type: `market`, data: [market.exchCode, realtimeMktName] }];
      }

      if (marketsToSubscribe.length > 1) {
        subscribeToAggregatedMarkets(marketsToSubscribe, { type: `market`, data: [market.exchCode, realtimeMktName] }, (ch) => this.subscribeCallback(ch, market));
      } else {
        subscribe(marketsToSubscribe, (ch) => this.subscribeCallback(ch, market));
      }

      exchangeApi.getTradingCurrencyDetail(exchange.exchCode, getMarketPair(market).base, (data) => {
        if (data.success) {
          this.setStateSafe({
            baseCurrency: data.result
          });
        }
      });

      exchangeApi.getTradingCurrencyDetail(exchange.exchCode, getMarketPair(market).quote, (data) => {
        if (data.success) {
          this.setStateSafe({
            quoteCurrency: data.result
          });
        }
      });


      exchangeApi.getTrades(exchange.exchCode, market.marketName, (data) => {
        if (data.success) {
          let trades = this.sortTrades(this.state.trades.concat(data.result));
            
          if (trades && trades.length) {
            
            // Adjust time & sales if the exchange is LSCX - removed
            // if (exchange.exchCode == `LSCX`) {
            //   trades.forEach((t, i) => {
            //     trades[i].price = adjustTradePrice(t.price, t.type);
            //   });
            // }
            
            this.setStateSafe({
              trades,
              lastPrice: trades[0].price
            });
          }

          this.updateCurrentFavorite(exchange, market, trades);
        }
      });

      if (getMarketPair(market).base) {
        exchangeApi.getItbSignals(getMarketPair(market).base, (result) => {
          this.setStateSafe({ baseCurrencyItbSignals: result });
        }, () => this.setStateSafe({ baseCurrencyItbSignals: null }));
      }

      if (getMarketPair(market).quote) {
        exchangeApi.getItbSignals(getMarketPair(market).quote, (result) => {
          this.setStateSafe({ quoteCurrencyItbSignals: result });
        }, () => this.setStateSafe({ baseCurrencyItbSignals: null }));
      }

      try {
        let cmCalCategories = await exchangeApi.getCoinMarketCalCategories();
        let cmCalCoins = await exchangeApi.getCoinMarketCalCoins();
        this.setStateSafe({ 
          cmCalCategories: cmCalCategories.body, 
          cmCalCoins: cmCalCoins.body
        });

      } catch (ex) {
        this.setStateSafe({ cmCalCategories: null, cmCalCoins: null });
      }

    });
  }

  applyTradeBuffer(market: Market, exchange: Exchange) {

    if(buffered_trades.length == 0){
      this.setStateSafe({
        throttleTimeout: setTimeout(() => {
          this.applyTradeBuffer(market, exchange);
        }, THROTTLE_MS)
      });
      return;
    }

    // Adjust time & sales if the exchange is LSCX - removed
    // if (exchange.exchCode == `LSCX`) {
    //   buffered_trades.forEach((t, i) => {
    //     buffered_trades[i].price = adjustTradePrice(buffered_trades[i].price, buffered_trades[i].type);
    //   });
    //   //console.log(buffered_trades);
    // }

    let sortedBuffer = this.sortTrades(buffered_trades),
      lastTrade = sortedBuffer[0],
      lastPrice = lastTrade.price,
      trades = [];

    buffered_trades = [];
    const activeExchange = this.props.active.exchange.exchCode;

    trades = [...sortedBuffer,  ...this.state.trades]
      .slice(0, sortedBuffer.length > TRADE_LIST_MAX_ITEMS ? sortedBuffer.length : TRADE_LIST_MAX_ITEMS);

    //use promises to throttle event emitter so TV chart can process bar updates properly
    var promise = Promise.resolve();
    sortedBuffer.reverse().forEach(function (t, index) {
      promise = promise.then(function () {
        let updateBar = false;
        let nextTrade = (sortedBuffer.length - 1 == index) ? undefined : sortedBuffer[index+1];
        let prevTrade = (0 == index) ? undefined : sortedBuffer[index-1];
        if(!nextTrade || !prevTrade ||
          +(new Date(t.time)) != +(new Date(nextTrade.time)) ||
          +(new Date(t.time)) != +(new Date(prevTrade.time))){
          updateBar = true;
        }
        
        // Will change exchange property for aggregatedMarket trade
        const tradeToEmit = {
          ...t
        };
        if (activeExchange === `LSCX`) {
          tradeToEmit.exchange = `LSCX`;
        }

        emitEvent(MARKET_RECEIVED_NEW_TRADE, {
          trade: {
            ...tradeToEmit,
            time: forceUTCParsing(t.time_local),
            tradeId: t.tradeid,
            updateBar: updateBar
          }
        });
        return new Promise(function (resolve) {
          setTimeout(resolve, CHART_TRADE_THROTTLE_MS);
        });
      });
    });

    //this will run before the above promise chain completes in the event of a lot of trades
    //this could mean things get backed up with many promises or who knows what under heavy load
    //if this code is delayed until the end of the promise chain (it used to be) then it can overwrite a trade state created by a market change
    //ideally need to be able to cancel this in the event of a market change but allowing it to run is the temporary solution
    if(this.shouldApplyUpdates()){
      this.updateCurrentFavorite(exchange, market, trades);
      this.setStateSafe({
        lastPrice,
        trades,
        throttleTimeout: setTimeout(() => this.applyTradeBuffer(market, exchange), THROTTLE_MS)
      });
    }else{
      this.setStateSafe({
        throttleTimeout: setTimeout(() => this.applyTradeBuffer(market, exchange), THROTTLE_MS)
      });
    }
  }

  getMarketDepthData(market: Market, exchange: Exchange) {
    exchangeApi.getMarketDepthData(exchange.exchCode, market.marketName, (data) => {
      if (data.success) {
        if (data.result.bids.length > 0 && data.result.asks.length > 0) {
          this.setStateSafe({
            sells: this.sortOrders(data.result.asks).slice(0, 500),
            buys: this.sortOrders(data.result.bids).reverse().slice(0, 500).reverse()
          });
        }
      }
    });
  }

  getNotes(){
    userApi.getNotes(getMarketPair(this.props.active.market).toString(), (data) => {
      if (data.success) {
        this.setStateSafe({
          notes: data.result
        });
      }
    });
  }

  updateCurrentFavorite(exchange: Exchange, market: Market, trades: Array<Trade>) {
    let favorite = this.props.favorites.find((f) => f.exchCode == exchange.exchCode &&
                                                  f.displayName == market.displayName);
    if (favorite && trades[0]) {
      favorite.last = trades[0].price;
      favorite.marketDirection = this.getMarketDirection(trades);
      this.props.updateCurrentFavorite(favorite);
    }
  }

  getHistoricalRange(market: Market, exchange: Exchange) {
    exchangeApi.getMarketRange(exchange.exchCode, market.marketName, (data) => {
      if (data.success) {
        const range = data.result;

        

        // Adjust range if the exchange is LSCX - removed
        // let newRange = _.cloneDeep(range);
        // if (exchange.exchCode == `LSCX`) {
          
        //   //trades[i].price = adjustTradePrice(t.price, t.type);
        //   const rangeKeys = Object.keys(newRange);

        //   rangeKeys.forEach((k, i) => {
        //     const newHigh = range[k].high;
        //     const newLow = range[k].low;
        //     newRange[k].high = adjustTradePrice(newHigh, `BUY`);
        //     newRange[k].low = adjustTradePrice(newLow, `SELL`);
        //   });

        //   console.log(range, newRange);
        //   this.setStateSafe({
        //     range: newRange
        //   });
        // } else {
        //   this.setStateSafe({
        //     range
        //   });
        // }

        this.setStateSafe({
          range
        });
        
      }
    });
  }

  getTickerData(market: Market, exchange: Exchange) {
    exchangeApi.getTickerData(exchange.exchCode, market.marketName, (data) => {
      if (data.success) {
        let result: Ticker = {
          volume: 0,
          last: 0,
          high: 0,
          low: 0,
          ask: 0,
          bid: 0
        };

        Object.keys(data.result).forEach((k) => result[k] = data.result[k] === null ? 0 : data.result[k]);
        // modify high/low values for LSCX - removed
        // Object.keys(data.result).forEach((k) => {
        //   result[k] = data.result[k] === null ? 0 : data.result[k];
        //   if (exchange.exchCode == `LSCX`) {
        //     if (k == `high`) {
        //       result[k] = adjustTradePrice(result[k], `BUY`);
        //     }
        //     if (k == `low`) {
        //       result[k] = adjustTradePrice(result[k], `SELL`);
        //     }
        //   }
        // });

        this.setStateSafe({
          ticker: result,
          lastPrice: result.last
        });
      }
    });


    // fetch the OPEN price 24 hours ago so as to populate the percentage increase/decrease on MarketHeader
    balanceApi.getOhlc(
      { exchange: exchange.exchCode,
        market: market.marketName,
        data: `H`,
        params: {
          StartDate: (new Date(new Date().getTime() - (23 * 60 * 60 * 1000))).toISOString(),
          EndDate: (new Date(new Date().getTime() - (23 * 60 * 60 * 1000))).toISOString()
        } },
      (data) => {
        if (data.success) {
          this.setState({ openPrice: data.result[0].open });
        }
      }
    );
  }

  getMarketExchOpenOrders() {
    orderApi.getOrders({ params: { pageSize: 250, pageNumber: 1, ExchMktId: this.props.active.market.exchmktId } }, (data) => {
      this.setStateSafe({
        tvcOpenOrders: data.result ? data.result.map(translateOrder) : [],
      });
    });
  }

  getTVCOrderHistory() {
    orderApi.getOrderHistory({ params: { pageSize: 250, pageNumber: 1, ExchMktId: this.props.active.market.exchmktId } }, (data) => {
      this.setStateSafe({
        tvcOrderHistory: data.result ? data.result.map(translateOrder) : [],
      });
    });
  }

 

  getApiVersion = () => {
    const exch = this.props.exchanges.find((e) => e.exchId == this.props.active.exchange.exchId);
    return  getExchangeTradeApiVersion(exch);
  };

  getActiveAccounts = (): Array<Account> => {
    return this.props.accounts.filter((account: Account) =>
      account.authVersion == this.getApiVersion()
          && account.authExchId === this.props.active.exchange.exchId
          && account.authTrade 
          && account.authActive);
  };

  getCurrentAuthIdValue = () => { 
    let initCurrentAuthId = (this.state.currentAuthId && this.state.currentAuthId > 0) ? this.state.currentAuthId : 0;
    const activeAccounts = this.getActiveAccounts();
    if (initCurrentAuthId === 0) { 
      initCurrentAuthId = ``;

      if (activeAccounts.length === 1) {
        initCurrentAuthId = activeAccounts[0].authId;
      }
    }
    return initCurrentAuthId.toString();
  };

  updateUserOrderFilters(exchFilter: ?string, mktFilter: ?string, authFilter: ?string) { 
    if (exchFilter) {
      this.setStateSafe({
        exchFilter
      });
    }
    if (mktFilter) {
      this.setStateSafe({
        mktFilter
      });
    }
    if (authFilter) {
      this.setStateSafe({
        authFilter
      });
    }
  }

  getUserAlerts() {
    type optionType = {
      params: {
        PageSize: number,
        PageNumber: number,
        SearchTerm?: string,
        ExchMktId?: number
      }
    };

    const options: optionType = {
      params: {
        PageSize: 250,
        PageNumber: 1,
        ExchMktId: this.props.active.market.exchmktId
      }
    };

    alertApi.getAlerts(options, (data) => {
      this.setStateSafe({
        activeAlerts: data.result,
      });
    });
  }

  onAlertAdded(e: any) {
    this.setStateSafe({
      activeAlerts: this.state.activeAlerts.concat(e.detail),
    });
  }

  // eslint-disable-next-line no-unused-vars
  onAlertEdited(e: any) {
    //let filteredAlerts = this.state.activeAlerts.filter((a) => a.alertId !== e.detail.alertId);

    //TODO: Write a global mapper for this?
    // this.setStateSafe({
    //   activeAlerts: [...filteredAlerts, ...this.mapRawAlertsToActiveAlerts([e.detail])]
    // });
  }

  onOrderAdded() {
    this.getMarketExchOpenOrders();
    this.refreshOrdersLastUpdateTime();
  }

  onPendingOrderAdded(e: any) {
    this.setStateSafe({
      openOrders: this.state.tvcOpenOrders.concat(e.detail),
    });  
  }

  onOrderAddedFailed() {
    this.setStateSafe({
      openOrders: this.state.tvcOpenOrders.filter((orders) => orders.autoDestroy !== true) 
    });
  }

  onPriceClick(e: any) {
    if (!this.props.marketInfoOpen) {
      this.toggleOpen(`marketInfoOpen`);
    }
    this.handleInfoTabChange(this.props.priceClick === `order` ? 1 : 2);
    setTimeout(() => emitEvent(PRICE_CHANGE_REQUESTED, e.detail.price), 1);
  }

  onRefreshOrders() {
    this.refreshOrdersLastUpdateTime();
  }

  deleteAlert(id: number) {
    alertApi.deleteAlert([].concat(id), (data) => {
      if (data.success) {
        this.setStateSafe({
          activeAlerts: this.state.activeAlerts.filter((a) => a.alertId !== id)
        });
        this.refreshAlertsLastUpdateTime();
      }
    });

    return true;
  }

  deleteOrder(authId: number, orderId: number, exchCode: string) {
    if (this.props.exchanges.some((e) => e.exchCode == exchCode && e.exchTradeEnabledV2 === true)) {
      orderApi.cancelV2Order({ authId, orderId }, (data) => {
        if (data.success) {
          this.setStateSafe({
            // openOrders: this.state.openOrders.filter((o) => o.orderId !== orderId),
            tvcOpenOrders: this.state.tvcOpenOrders.filter((o) => o.orderId !== orderId)
          });
        }
      });
    } else {
      orderApi.cancelV1Order(orderId, () => {
        this.setStateSafe({
          // openOrders: this.state.openOrders.filter((o) => o.orderId !== orderId),
          tvcOpenOrders: this.state.tvcOpenOrders.filter((o) => o.orderId !== orderId)
        });
      });
    }

    return true;
  }

  sortTrades(trades: Array<Trade>): Array<Trade> {
    return trades.sort((a, b) => {
      if (+new Date(a.time) > +new Date(b.time)) return -1;
      if (+new Date(b.time) > +new Date(a.time)) return 1;
      if (a.market_history_id < b.market_history_id) return 1;
      if (b.market_history_id < a.market_history_id) return -1;
      return 0;
    });
  }

  sortOrders(orders: Array<MarketOrder>): Array<MarketOrder> {
    return orders.sort((a, b) => {
      if (a.price > b.price) return 1;
      if (b.price > a.price) return -1;
      return 0;
    });
  }

  compileOrderData(newData: Array<MarketOrder>, existingData: Array<MarketOrder>, type: string): Array<MarketOrder> {
    if (newData.length == 0) return existingData;

    if (type == `buys`) {
      // New data will be ordered with lowest price item first (newData[0]). Filter out all existing data
      // that where the price is less than newData[0]. Cap size at 500.
      return existingData.filter((o) =>  o.price < newData[0].price).concat(newData).slice(0, 500);
    } else if (type == `sells`) {
      // New data will be ordered with lowest price item first (newData[0]). Need to append all existing data
      // sells where the price is greater than the last array item price in the new data newData[newData.length - 1].
      // Cap size at 500.
      return newData.concat(existingData.filter((o) =>  o.price > newData[newData.length - 1].price)).slice(0, 500);
    }

    return existingData;
  }

  toggleFavorite() {
    let isFavorite = this.props.favorites.filter((f) => {
      return f.exchCode == this.props.active.market.exchCode && f.displayName == this.props.active.market.displayName;
    }).length > 0;

    if (isFavorite) {
      userApi.deleteFavorite({ exchmktId: this.props.active.market.exchmktId }, (data) => {
        if (data.success) this.props.updateFavorites(data.result);
      });
    } else {
      userApi.addFavorite({ body: { exchmktId: this.props.active.market.exchmktId } }, (data) => {
        if (data.success)  this.props.updateFavorites(data.result);
      });
    }
  }

  postNotes(note: string = this.state.notes) {
    userApi.saveNotes({ note: note, marketPair: getMarketPair(this.props.active.market).toString() });
  }

  updateNotes(notes: string) {
    clearTimeout(this.state.updateNotesTimeout);

    this.setStateSafe({
      updateNotesTimeout: setTimeout(() => this.postNotes(), 3000),
      notes
    });
  }

  handleKeyboardShortcut(e: any) {
    switch (e.detail.action) {
    case `TOGGLE_MARKET_SWITCHER`:
      this.props.toggleOpen(`marketSwitcherOpen`);
      break;
    case `TOGGLE_MARKET_INFO`:
      this.toggleOpen(`marketInfoOpen`);
      break;
    case `TOGGLE_MARKET_ORDERS_TABLE`:
      //this.toggleOpen(`ordersTableOpen`);
      this.handleChartsTabChange(1);
      break;
    case `TOGGLE_MARKET_CHART`:
      //this.toggleOpen(`marketChartOpen`);
      this.handleChartsTabChange(0);
      break;
    case `ENTER`:
      emitEvent(ENTER);
      break;
    case `SHOW_KEYBOARD_LEGEND`:
      emitEvent(SHOW_KEYBOARD_LEGEND);
      break;
    case `FOCUS_MARKET_FILTER`:
      if (!this.props.marketSwitcherOpen)
        this.props.toggleOpen(`marketSwitcherOpen`);
      break;
    case `SHOW_LARGER_BOTTOM_MARKETS_PANEL`:
      this.showLargerOrdersTable(!this.props.showLargerOrdersTable);
      break;
    case `FOCUS_CHART`: {
      let TVFrame = document.querySelector(`iframe[src^="/chart.html"]`);
      if (TVFrame) {
        // $FlowIgnore: suppressing this error
        let TV = TVFrame.contentWindow.document.querySelector(`iframe`).focus();

        if (TV) {
          TV.focus();
        }
      }
      break;
    }
    case `OPEN_DATA_TAB`:
      this.handleInfoTabChange(0);
      break;
    case `OPEN_TRADE_TAB`:
      this.handleInfoTabChange(1);
      break;
    case `OPEN_ALERTS_TAB`:
      this.handleInfoTabChange(2);
      break;
    case `OPEN_INSIGHTS_TAB`:
      this.handleInfoTabChange(3);
      break;
    case `OPEN_NOTES_TAB`:
      this.handleInfoTabChange(4);
      break;
    case `OPEN_BUY`:
      emitEvent(BUY_MODE_REQUESTED);
      break;
    case `OPEN_SELL`:
      emitEvent(SELL_MODE_REQUESTED);
      break;
    default:
      // do something here
      break;
    }
  }

  showLargerOrdersTable(value: boolean) {
    this.props.updatePrefs({
      key: `showLargerOrdersTable`,
      value
    });
  }

  toggleOpen(key: string, props: Props = this.props, push: boolean = true, override?: boolean) {
    let classKey = key == `marketInfoOpen` ? `marketInfoClass` :
        key == `ordersTableOpen` ? `ordersTableClass` : 
          key == `marketSwitcherOpen` ? `marketSwitcherClass` :`marketChartClass`,

      state = { };

    if (typeof override == `boolean`) {
      state[classKey] = override ? `in` : `out`;
    } else {
      state[classKey] = props[key] ? `out` : `in`;
    }

    this.setStateSafe(state, () => {
      if (state[classKey] == `in`) {
        if (push) {
          props.updatePrefs({
            key,
            value: !props[key]
          });

          if ([`xs`, `sm`].includes(this.props.size)) {
            if (key == `marketSwitcherOpen`) {
              props.updatePrefs({
                key: `marketInfoOpen`,
                value: false
              });
            } else if (key == `marketInfoOpen`) {
              props.updatePrefs({
                key: `marketSwitcherOpen`,
                value: false
              });
            }
          }
        }

        setTimeout(() => {
          state[classKey] = `in moving`;

          this.setStateSafe(state, () => {
            setTimeout(() => {
              state[classKey] = ``;
              this.setStateSafe(state, () => window.dispatchEvent(new Event(`resize`)));
            }, 1);
          });
        }, 1);
      } else {
        setTimeout(() => {
          if (push) {
            props.updatePrefs({
              key,
              value: !props[key]
            });
          }

          state[classKey] = `out`;

          this.setStateSafe(state, () => window.dispatchEvent(new Event(`resize`)));
        }, 1);
      }
    });
  }

  handleInfoTabChange(tab: number) {
    emitEvent(MARKET_BUY_MODE_DEACTIVATED);
    emitEvent(MARKET_SELL_MODE_DEACTIVATED);
    emitEvent(MARKET_ALERT_MODE_DEACTIVATED);

    if (tab != 1) {
      // remove on-chart order markers if any tab other than 'Trade' is visible
      emitEvent(DELETE_BUY_NODE);
      emitEvent(DELETE_BUY_STOP_NODE);
      emitEvent(DELETE_SELL_NODE);
      emitEvent(DELETE_SELL_STOP_NODE);
    }

    this.props.updateTab(tab);
  }

  handleBOHTabChange(tab: number) {
    
    this.props.updateBOHTab(tab);
  }

  handleOrdersTabChange(tab: number) {
    this.props.updateOrdersTab(tab);
  }

  handleChartsTabChange(tab: number) {
    this.props.updateChartsTab(tab);
  }

  handleOrderFormTypeChange(orderFormType: string) {
    emitEvent(DELETE_BUY_NODE);
    emitEvent(DELETE_BUY_STOP_NODE);
    emitEvent(DELETE_SELL_NODE);
    emitEvent(DELETE_SELL_STOP_NODE);
    this.props.updateOrderFormType(orderFormType);
  }

  handleAlertTypeChange(alertType: number) {
    this.props.updateAlertType(alertType);
  }

  handleCurrLogoClick(currCode: string) {
    this.handleInfoTabChange(3);
    return currCode;
  }

  handleTVMessage(e: any) {
    switch(e.type) {
    case DATA_MODE_REQUESTED:
      this.handleInfoTabChange(0);
      break;
    case ALERT_MODE_REQUESTED:
      this.handleInfoTabChange(2);
      break;
    case BUY_MODE_REQUESTED:
      this.handleInfoTabChange(1);
      setTimeout(() => {
        emitEvent(SWITCH_TO_BUY_MODE);
      }, 100);
      break;
    case SELL_MODE_REQUESTED:
      this.props.updateTab(1);
      setTimeout(() => {
        emitEvent(SWITCH_TO_SELL_MODE);
      }, 100);
      break;
    case BUY_MODE_REQUESTED_WITH_PRICE:
      this.handleInfoTabChange(1);
      emitEvent(SWITCH_TO_BUY_MODE_WITH_PRICE, e.detail);
      break;
    case SELL_MODE_REQUESTED_WITH_PRICE:
      this.props.updateTab(1);
      emitEvent(SWITCH_TO_SELL_MODE_WITH_PRICE, e.detail);
      break;
    case BUY_MODE_REQUESTED_WITH_STOP_PRICE:
      this.handleInfoTabChange(1);
      emitEvent(SWITCH_TO_BUY_MODE_WITH_STOP_PRICE, e.detail);
      break;
    case SELL_MODE_REQUESTED_WITH_STOP_PRICE:
      this.props.updateTab(1);
      emitEvent(SWITCH_TO_SELL_MODE_WITH_STOP_PRICE, e.detail);
      break;
    }
  }

  getMarketDirection(trades: Array<Trade>) {
    if (trades.length < 2) return 0;

    let currentPrice = trades[0].price;
    for (let i = 0; i < trades.length; i += 1) {
      if (currentPrice > trades[i].price) {
        return 1;
      } else if (currentPrice < trades[i].price) {
        return -1;
      }
    }
    return 0;
  }

  setCurrentAccount = (id: number) => {
    if (this.state.currentAuthId !== id) {
      this.setStateSafe({ 
        currentAuthId: id,
        authFilter: this.getCurrentAuthIdValue()
      });
    }
  }

  toggleShowDepthOrdersAlerts() {
    this.setState({
      showDepthOrdersAlerts: !this.state.showDepthOrdersAlerts
    });
  }

  // when tour is dismissed or completed, set a cookie that prevents it from being displayed for a year
  toggleTour(hideOrShow: boolean) {
    this.setState({
      showTour: hideOrShow
    });
    if (hideOrShow === false) {
      writeCookie(`markets_tour_completed`, true, 365);
      emitEvent(TOUR_COMPLETED);
    }
  }

  advanceTour() {
    const nextStep = this.state.currentTourStep + 1;
    setTimeout(() => {
      this.setState({
        currentTourStep: nextStep
      });
    }, 0);
  }

  // steps of the tour
  tourSteps() {
    return [
      {
        // step1 - welcome
        content: () => (
          <div className={ `tour-content` }>
            {
              LogoMark[this.props.platformId ? this.props.platformId : 0]
            }
            <div className={ `step-title` }>
              { this.props.t(`tour:getStarted`) }
            </div>
            <div>
              { this.props.t(`tour:followTutorial`) }
            </div>
          </div>
        ), 
        style: {
          backgroundColor: `var(--gray-2)`
        },
        navDotAriaLabel: this.props.t(`tour:getStarted`)
      },
      {
        // step 2 - markets page
        selector: `.tour-markets-nav`,
        content: () => (
          <div className={ `tour-content` }>
            <div className={ `step-subtitle` }>
              { this.props.t(`tour:marketsPage`) }
            </div>
            <div>
              { this.props.t(`tour:marketsPageDescription`) }
            </div>
          </div>
        ), 
        beforeAction: () => {
          if (!this.props.marketSwitcherOpen) {
            this.props.toggleOpen(`marketSwitcherOpen`);
          }
          this.setStateSafe({ tourSelectedTabNodeId: `market-switcher` });
        },
        style: {
          backgroundColor: `var(--gray-2)`
        },
        navDotAriaLabel: this.props.t(`tour:marketsPage`),
        stepInteraction: false
      },
      {
        // step 3 - market switcher
        selector: `.market-switcher, .market-search`,
        content: () => (
          <div className={ `tour-content` }>
            <div className={ `step-subtitle` }>
              { this.props.t(`tour:selectMarket`) }
            </div>
            <div>
              { this.props.t(`tour:selectMarketDescription`) }
            </div>
            <div className={ `step-hint` }>
              { this.props.t(`tour:selectMarketHint`) }
            </div>
          </div>
        ), 
        action: (node: any) => {
          node?.focus?.();
        },
        style: {
          backgroundColor: `var(--gray-2)`
        },
        navDotAriaLabel: this.props.t(`tour:selectMarket`)
      },
      {
        // step 4 - chart
        selector: `.market-chart`,
        content: () => (
          <div className={ `tour-content` }>
            <div className={ `step-subtitle` }>
              { this.props.t(`tour:greatJob`) }
            </div>
            <div>
              { this.props.t(`tour:theChartHasNowChanged`) }
            </div>
          </div>
        ), 
        beforeAction: () => {
          this.setStateSafe({ tourSelectedTabNodeId: `market-tv-chart` });
          if (!this.props.marketInfoOpen) {
            this.props.toggleOpen(`marketInfoOpen`);
          }
          this.handleInfoTabChange(0);
        },
        style: {
          backgroundColor: `var(--gray-2)`
        },
        navDotAriaLabel: this.props.t(`tour:theChartHasNowChanged`),
        stepInteraction: false
      },
      {
        // step - balances list
        selector: `.balances-orders-history`,
        content: () => (
          <div className={ `tour-content` }>
            <div className={ `step-subtitle` }>
              { this.props.t(`tour:ordersTable`) }
            </div>
            <div>
              { this.props.t(`tour:ordersTableDescription`) }
            </div>
          </div>
        ), 
        beforeAction: () => {
          this.setStateSafe({ tourSelectedTabNodeId: `balances-order-history` });
          if (!this.props.ordersTableOpen) {
            this.toggleOpen(`ordersTableOpen`);
          }
        },
        style: {
          backgroundColor: `var(--gray-2)`
        },
        navDotAriaLabel: this.props.t(`tour:ordersTableDescription`),
        stepInteraction: false
      },
      {
        // step 5 - market header
        selector: `.market-header`,
        content: () => (
          <div className={ `tour-content` }>
            <div className={ `step-subtitle` }>
              { this.props.t(`tour:currentMarketData`) }
            </div>
            <div>
              { this.props.t(`tour:currentMarketDescription`) }
            </div>
          </div>
        ), 
        beforeAction: () => {
          this.setStateSafe({ tourSelectedTabNodeId: `market-header` });
          if (!this.props.marketInfoOpen) {
            this.props.toggleOpen(`marketInfoOpen`);
          }
          this.handleInfoTabChange(0);
        },
        style: {
          backgroundColor: `var(--gray-2)`
        },
        navDotAriaLabel: this.props.t(`tour:currentMarketDescription`),
        stepInteraction: false
      },
      {
        // step 5 - market header
        selector: `.market-header .fav-star`,
        content: () => (
          <div className={ `tour-content` }>
            <div className={ `step-subtitle` }>
              { this.props.t(`tour:favStar`) }
            </div>
            <div>
              { this.props.t(`tour:favStarDescription`) }
            </div>
          </div>
        ), 
        beforeAction: () => {
          if (!this.props.marketInfoOpen) {
            this.props.toggleOpen(`marketInfoOpen`);
          }
          this.handleInfoTabChange(0);
        },
        style: {
          backgroundColor: `var(--gray-2)`
        },
        navDotAriaLabel: this.props.t(`tour:favStarDescription`),
        stepInteraction: true
      },
      {
        // step 6 - orderbook
        selector: `.market-vertical-depth-chart`,
        content: () => (
          <div className={ `tour-content` }>
            <div className={ `step-subtitle` }>
              { this.props.t(`tour:liveOrderbookData`) }
            </div>
            <div>
              { this.props.t(`tour:liveOrderbookDescription`) }
            </div>
          </div>
        ), 
        beforeAction: () => {
          this.setStateSafe({ tourSelectedTabNodeId: `market-time-sales` });
          if (!this.props.marketInfoOpen) {
            this.props.toggleOpen(`marketInfoOpen`);
          }
          this.handleInfoTabChange(0);
        },
        style: {
          backgroundColor: `var(--gray-2)`
        },
        navDotAriaLabel: this.props.t(`tour:liveOrderbookDescription`),
        stepInteraction: false
      },
      {
        // step 7 - trades list
        selector: `.streaming-trades-list`,
        content: () => (
          <div className={ `tour-content` }>
            <div className={ `step-subtitle` }>
              { this.props.t(`tour:liveTradesData`) }
            </div>
            <div>
              { this.props.t(`tour:liveTradesDescription`) }
            </div>
          </div>
        ), 
        beforeAction: () => {
          this.setStateSafe({ tourSelectedTabNodeId: `market-time-sales` });
          if (!this.props.marketInfoOpen) {
            this.props.toggleOpen(`marketInfoOpen`);
          }
          this.handleInfoTabChange(0);
        },
        style: {
          backgroundColor: `var(--gray-2)`
        },
        navDotAriaLabel: this.props.t(`tour:liveTradesDescription`),
        stepInteraction: false
      },
      {
        // step 7 - trades list
        selector: `.market-info .tabs-wrapper`,
        content: () => (
          <div className={ `tour-content` }>
            <div className={ `step-subtitle` }>
              { this.props.t(`tour:tradeTab`) }
            </div>
            <div>
              { this.props.t(`tour:tradeTabDescription`) }
            </div>
          </div>
        ), 
        beforeAction: () => {
          this.setStateSafe({ tourSelectedTabNodeId: `market-trades` });
          if (!this.props.marketInfoOpen) {
            this.props.toggleOpen(`marketInfoOpen`);
          }
          this.handleInfoTabChange(1);
        },
        action: (node: any) => {
          node?.focus?.();
        },
        style: {
          backgroundColor: `var(--gray-2)`
        },
        navDotAriaLabel: this.props.t(`tour:tradeTabDescription`),
        stepInteraction: false
      },
      {
        // step 7 - trades list
        selector: `.market-info .tabs-wrapper, .new-alert`,
        content: () => (
          <div className={ `tour-content` }>
            <div className={ `step-subtitle` }>
              { this.props.t(`tour:alertsTab`) }
            </div>
            <div>
              { this.props.t(`tour:alertsTabDescription`) }
            </div>
          </div>
        ), 
        beforeAction: () => {
          this.setStateSafe({ tourSelectedTabNodeId: `market-alerts` });
          if (!this.props.marketInfoOpen) {
            this.props.toggleOpen(`marketInfoOpen`);
          }
          this.handleInfoTabChange(2);
          this.props.updateAlertType(0);
        },
        style: {
          backgroundColor: `var(--gray-2)`
        },
        navDotAriaLabel: this.props.t(`tour:alertsTabDescription`),
        stepInteraction: false
      },
      {
        // step 7 - trades list
        selector: `.add-alert-button`,
        content: () => (
          <div className={ `tour-content` }>
            <div className={ `step-subtitle` }>
              { this.props.t(`tour:alertsForm`) }
            </div>
            <div>
              { this.props.t(`tour:alertsFormDescription`) }
            </div>
          </div>
        ), 
        beforeAction: () => {
          if (!this.props.marketInfoOpen) {
            this.props.toggleOpen(`marketInfoOpen`);
          }
          this.handleInfoTabChange(2);
          this.props.updateAlertType(0);
        },
        style: {
          backgroundColor: `var(--gray-2)`
        },
        navDotAriaLabel: this.props.t(`tour:alertsFormDescription`),
      },
      {
        // step 7 - trades list
        selector: `.market-info .tabs-wrapper .market-alerts, .market-alerts`,
        content: () => (
          <div className={ `tour-content` }>
            <div className={ `step-subtitle` }>
              { this.props.t(`tour:greatJob`) }
            </div>
            <div>
              { this.props.t(`tour:alertsTableDescription`) }
            </div>
          </div>
        ), 
        beforeAction: () => {
          if (!this.props.marketInfoOpen) {
            this.props.toggleOpen(`marketInfoOpen`);
          }
          this.handleInfoTabChange(2);
          this.props.updateAlertType(0);
        },
        style: {
          backgroundColor: `var(--gray-2)`
        },
        navDotAriaLabel: this.props.t(`tour:alertsTabDescription`),
        stepInteraction: false
      },
      {
        // step 7 - trades list
        selector: `.market-info .tabs-wrapper, .market-insights`,
        content: () => (
          <div className={ `tour-content` }>
            <div className={ `step-subtitle` }>
              { this.props.t(`tour:insightsTab`) }
            </div>
            <div>
              { this.props.t(`tour:insightsTabDescription`) }
            </div>
          </div>
        ), 
        beforeAction: () => {
          this.setStateSafe({ tourSelectedTabNodeId: `market-insights` });
          if (!this.props.marketInfoOpen) {
            this.props.toggleOpen(`marketInfoOpen`);
          }
          this.handleInfoTabChange(3);
        },
        style: {
          backgroundColor: `var(--gray-2)`
        },
        navDotAriaLabel: this.props.t(`tour:insightsTabDescription`),
        stepInteraction: false
      },
      {
        // step 7 - trades list
        selector: `.market-info .tabs-wrapper, .market-notes`,
        content: () => (
          <div className={ `tour-content` }>
            <div className={ `step-subtitle` }>
              { this.props.t(`tour:notesTab`) }
            </div>
            <div>
              { this.props.t(`tour:notesTabDescription`) }
            </div>
          </div>
        ), 
        beforeAction: () => {
          this.setStateSafe({ tourSelectedTabNodeId: `market-notes` });
          if (!this.props.marketInfoOpen) {
            this.props.toggleOpen(`marketInfoOpen`);
          }
          this.handleInfoTabChange(4);
        },
        style: {
          backgroundColor: `var(--gray-2)`
        },
        navDotAriaLabel: this.props.t(`tour:notesTabDescription`),
        stepInteraction: false
      },
      {
        // step 7 - trades list
        selector: `.help-menu-link`,
        content: () => (
          <div className={ `tour-content` }>
            <div className={ `step-subtitle` }>
              { this.props.t(`tour:needHelp`) }
            </div>
            <div>
              { this.props.t(`tour:needHelpDescription`) }
            </div>
          </div>
        ), 
        style: {
          backgroundColor: `var(--gray-2)`
        },
        navDotAriaLabel: this.props.t(`tour:needHelpDescription`),
        stepInteraction: false
      },
    ];
  }
  
  refreshOrdersLastUpdateTime ()  {
    this.setStateSafe({
      ordersLastUpdateTime: new Date().getTime()
    });
  }

  refreshAlertsLastUpdateTime ()  {
    this.setStateSafe({
      alertsLastUpdateTime: new Date().getTime()
    });
  }

  render() {
    const isFavorite = this.props.favorites.some((f) => f.exchCode == this.props.active.market.exchCode && f.displayName == this.props.active.market.displayName);
    
    const marketDirection = this.getMarketDirection(this.state.trades);
    const { lastPrice } = this.state;
    let title = marketDirection < 0 ? `▼ ` : `▲ `;

    if (lastPrice > 0) {
      title += `${ toFixedDecimals(lastPrice, false, `price`, this.props.active.market) } `;
    }

    title += `${ getMarketPair(this.props.active.market).toString() } [${ this.props.active.market.exchCode }]`;

    updateTitle(title, this.props.platformId);

    // Return "Time & Sales" if the market is not on CGY, otherwise return "Live Quotes"
    const verticalDepthChartTabName = this.props?.active?.exchange?.exchCode && this.props.active.exchange.exchCode === `CGY` ?
      `Live Quotes` : `Time & Sales`;

    const MarketSwitcherComponent = this.props.marketSwitcherVersion === `v1` ? MarketSwitcher : MarketSearch;

    if (this.props?.isMarketsFlexlayout && this.state.isFullAccess) {
      return (
        <>
          <Tour
            steps={ this.tourSteps() }
            isOpen={ this.state.showTour }
            onRequestClose={ () => this.toggleTour(false) }
            showNumber={ false }
            rounded={ 4 }
            getCurrentStep={ (curr) => this.setState({ currentTourStep: curr }) }
            goToStep={ this.state.currentTourStep }
            onBeforeClose={ () => this.setState({ currentTourStep: -1 }) }
            disableFocusLock={ true }
            startAt={ 0 }
            nextStep={ () => {
              const steps = this.tourSteps();
              // $FlowIgnore: suppressing this error
              steps[this.state.currentTourStep + 1]?.beforeAction?.();

              setTimeout(() => {
                this.setState({ currentTourStep: this.state.currentTourStep + 1  });
              }, 250);
            } }
            prevStep={ () => {
              const steps = this.tourSteps();
              // $FlowIgnore: suppressing this error
              steps[this.state.currentTourStep - 1]?.beforeAction?.();

              setTimeout(() => {
                this.setState({ currentTourStep: this.state.currentTourStep - 1  });
              }, 250);
            } }/>
          <MarketFlexlayout 
            exchanges={ this.props.exchanges }
            markets={ this.props.markets }
            accounts={ this.props.accounts }
            favorites={ this.props.favorites }
            active={ this.props.active }
            activeAlerts={ this.state.activeAlerts && this.state.activeAlerts.filter((a) => {
              return a.exchMktId == this.props.active.market.exchmktId;
            }) }
            bid={
              this.state.buys.length > 0 ?
                this.state.buys[this.state.buys.length - 1].price :
                this.state.ticker.bid
            }
            ask={
              this.state.sells.length > 0 ?
                this.state.sells[0].price :
                this.state.ticker.ask
            }
            tvcOrderHistory={ this.state.tvcOrderHistory } 
            tvcOpenOrders={ this.state.tvcOpenOrders }
            chartClass={ this.state.marketChartClass }
            isFavorite={ isFavorite }
            toggleFavorite={ this.toggleFavorite.bind(this) }
            ticker={ this.state.ticker }
            lastPrice={ lastPrice }
            lastTradeType={ this.state.trades.length > 0 ? this.state.trades[0].type : `` }
            marketDirection={ marketDirection }
            inlineMode={ false }
            abbreviate={ this.state.abbreviateHeader }
            bestBid={
              this.state.buys.length > 0 ?
                this.state.buys.reduce(function(prev, curr) {
                  return prev.price > curr.price ? prev : curr;
                }).price :
                this.state.ticker.bid
            }
            bestAsk={
              this.state.sells.length > 0 ?
                this.state.sells.reduce(function(prev, curr) {
                  return prev.price < curr.price ? prev : curr;
                }).price :
                this.state.ticker.ask
            }
            handleCurrLogoClick={ this.handleCurrLogoClick.bind(this) }
            openPrice={ this.state.openPrice }
            marketSwitcherOpen={ this.props.marketSwitcherOpen }
            toggleOpen={ this.toggleOpen.bind(this) }
            showLarger={ true }
            activeBOHTab={ this.props.activeBOHTab || 0 }
            updateBOHTab={ (tab) => this.handleBOHTabChange(tab) }
            isFull={ true }
            openOrders={  this.state.tvcOpenOrders }
            orderHistory={ this.state.tvcOrderHistory }
            exchFilter={ this.state.exchFilter }
            mktFilter={ this.state.mktFilter }
            authFilter={ this.state.authFilter }
            getCurrentAuthIdValue={ this.getCurrentAuthIdValue.bind(this) }
            updateUserOrderFilters={ this.updateUserOrderFilters.bind(this) }
            deleteOrder={ this.deleteOrder.bind(this) }
            balances={ this.props.balances }
            currentAuthId={ this.state.currentAuthId }
            notes={ this.state.notes }
            updateNotes={ this.updateNotes.bind(this) } 
            researchBaseCurrency={ this.state.baseCurrency }
            researchQuoteCurrency={ this.state.quoteCurrency }
            baseCurrencyItbSignals={ this.state.baseCurrencyItbSignals }
            quoteCurrencyItbSignals={ this.state.quoteCurrencyItbSignals }
            currencies={ this.props.currencies }
            cmCalCategories={ this.state.cmCalCategories }
            cmCalCoins={ this.state.cmCalCoins }
            alertType={ this.props.alertType }
            updateAlertType={ (alertType) => this.handleAlertTypeChange(alertType) }
            range={ this.state.range }
            deleteAlert={ this.deleteAlert.bind(this) }
            buys={ this.state.buys.length > 20 ? this.state.buys.slice(this.state.buys.length - 20) : this.state.buys }
            sells={ this.state.sells.slice(0, 20) }
            zeroStyle={ this.props.zeroStyle }
            depthStyle={ this.props.depthStyle }
            theme={ this.props.theme } 
            trades={ this.state.trades } 
            setCurrentAccount={ this.setCurrentAccount }
            orderFormType={ this.props.orderFormType }
            updateOrderFormType={ (orderFormType) => this.handleOrderFormTypeChange(orderFormType) }
            activeTab={ this.props.activeOrdersTab || 0 }
            showDepthOrdersAlerts={ this.state.showDepthOrdersAlerts }
            toggleShowDepthOrdersAlerts={ this.toggleShowDepthOrdersAlerts.bind(this) }
            ordersLastUpdateTime={ this.state.ordersLastUpdateTime }
            refreshOrdersLastUpdateTime={ this.refreshOrdersLastUpdateTime.bind(this) }
            alertsLastUpdateTime={ this.state.alertsLastUpdateTime }
            refreshAlertsLastUpdateTime={ this.refreshAlertsLastUpdateTime.bind(this) }
            size={ this.props.size }
            marketSwitcherVersion={ this.props.marketSwitcherVersion }
            tourSelectedTabNodeId={ this.state.tourSelectedTabNodeId }/>
        </>
      );
    } else {
      return (
        <>
          <MarketSwitcherComponent
            className={ this.props.marketSwitcherClass }
            exchanges={ this.props.exchanges }
            markets={ this.props.markets }
            accounts={ this.props.accounts }
            favorites={ this.props.favorites }
            active={ this.props.active }>
            { this.props.marketSwitcherVersion !== `v1` && [`xs`, `sm`].includes(this.props.size) && this.props.marketSwitcherOpen &&
            <div 
              className={ `toggle-panel market-switcher ${this.props.marketSwitcherOpen ? `open ${this.props.size}`: `` }` }>
              <a onClick={ () => this.toggleOpen(`marketSwitcherOpen`) }>
                <Popover
                  isOpen={ this.state.marketSwitcherPopover }
                  positions={ [ `right` ] }
                  content={ ({ position, childRect, popoverRect }) => (
                    <ArrowContainer 
                      position={ position }
                      childRect={ childRect }
                      popoverRect={ popoverRect }
                      arrowColor={ `var(--gray-1)` }>
                      <div className={ `popover-content` }>
                        { this.props.t(`Toggle Markets`) }
                      </div>
                    </ArrowContainer>
                  ) }>
                  <label
                    className="filter-popover" 
                    onMouseEnter={ () =>  this.setState({ marketSwitcherPopover: true }) }
                    onMouseLeave={ () => this.setState({ marketSwitcherPopover: false }) }>
                    {
                      this.props.marketSwitcherOpen ? CollapseLeft(`marketSwitcherOpen`) :CollapseRight(`marketSwitcherOpen`) 
                    }
                  </label>
                </Popover>
              </a>
            </div>
            }
          </MarketSwitcherComponent>
          <div className={ `market-data-layer ${this.props.marketSwitcherVersion !== `v1` ? `has-market-search` : ``}` }>
            <Tour
              steps={ this.tourSteps() }
              isOpen={ this.state.showTour }
              onRequestClose={ () => this.toggleTour(false) }
              showNumber={ false }
              rounded={ 4 }
              getCurrentStep={ (curr) => this.setState({ currentTourStep: curr }) }
              goToStep={ this.state.currentTourStep }
              onBeforeClose={ () => this.setState({ currentTourStep: -1 }) }
              disableFocusLock={ true }
              startAt={ 0 }
              nextStep={ () => {
                const steps = this.tourSteps();
                // $FlowIgnore: suppressing this error
                steps[this.state.currentTourStep + 1]?.beforeAction?.();

                setTimeout(() => {
                  this.setState({ currentTourStep: this.state.currentTourStep + 1  });
                }, 250);
              } }
              prevStep={ () => {
                const steps = this.tourSteps();
                // $FlowIgnore: suppressing this error
                steps[this.state.currentTourStep - 1]?.beforeAction?.();

                setTimeout(() => {
                  this.setState({ currentTourStep: this.state.currentTourStep - 1  });
                }, 250);
              } }/>
            <div 
              className={ `market-data-column fade-in 
              ${ this.props.showLargerOrdersTable ? `larger-orders` : `` } 
              ${this.props.marketSwitcherOpen ? `market-switcher-open`: ``} 
              ${this.props.marketInfoOpen ? `market-info-open`: `` }` 
              }
              ref="middleCol">
              { <PageVisibility onChange={ this.handleVisibilityChange } /> }
              {
                <div className={ `vertical-view ${this.props.ordersTableOpen ? `split-view` : `` }` }>
                  <div className={ `tabs ${this.props.size}` }>
                    <Tabs
                      tabPosition="center"
                      tabNames={ [`Candlestick Chart`, `Depth Chart`, verticalDepthChartTabName] }
                      activeTab={ this.props.activeChartsTab || 0 }
                      optionalClass={ `markets-charts-tabs` }
                      renderAllTabs={ true }
                      showMarketHeader={ !this.props.marketInfoOpen && this.props.size !== `xs` }
                      toggleFavorite={ this.toggleFavorite.bind(this) }
                      exchange={ this.props.active.exchange }
                      market={ this.props.active.market }
                      isFavorite={ isFavorite }
                      ticker={ this.state.ticker }
                      lastPrice={ lastPrice }
                      lastTradeType={ this.state.trades.length > 0 ? this.state.trades[0].type : `` }
                      marketDirection={ marketDirection }
                      onChangeTab={ (tab) => this.handleChartsTabChange(tab) } >
                      {
                      //this.props.activeChartsTab == 0 && (
                        <div className={ this.props.activeChartsTab == 0 ? `` : `hide` } style={ { height: `100%` } }>
                          <MarketChart
                            active={ this.props.active }
                            activeAlerts={ this.state.activeAlerts && this.state.activeAlerts.filter((a) => {
                              return a.exchMktId == this.props.active.market.exchmktId;
                            }) }
                            bid={
                              this.state.buys.length > 0 ?
                                this.state.buys[this.state.buys.length - 1].price :
                                this.state.ticker.bid
                            }
                            ask={
                              this.state.sells.length > 0 ?
                                this.state.sells[0].price :
                                this.state.ticker.ask
                            }
                            tvcOrderHistory={ this.state.tvcOrderHistory } 
                            tvcOpenOrders={ this.state.tvcOpenOrders }
                            chartClass={ this.state.marketChartClass } />
                        </div>
                      //)
                      }
                      {
                        this.props.activeChartsTab == 1 && (
                        //<div className={ this.props.activeChartsTab == 1 ? `` : `hide` }>
                          <MarketOrders
                            marketSwitcherOpen={ this.props.marketSwitcherClass !== `out` }
                            marketInfoOpen={ this.state.marketInfoClass !== `out` }
                            showLarger={ true }
                            active={ this.props.active }
                            markets={ this.props.markets }
                            buys={ this.state.buys }
                            sells={ this.state.sells }
                            isFull={ true }
                            openOrders={ this.state.tvcOpenOrders }
                            activeAlerts={ this.state.activeAlerts }
                            orderHistory={ this.state.tvcOrderHistory }
                            balances={ this.props.balances }
                            accounts={ this.props.accounts }
                            exchanges={ this.props.exchanges }
                            deleteOrder={ this.deleteOrder.bind(this) }
                            currentAuthId={ this.props.activeTab == 1 ? this.state.currentAuthId : 0 }
                            activeTab={ this.props.activeOrdersTab || 0 }
                            showDepthOrdersAlerts={ this.state.showDepthOrdersAlerts }
                            toggleShowDepthOrdersAlerts={ this.toggleShowDepthOrdersAlerts.bind(this) }
                            lastPrice={ lastPrice }
                            lastTradeType={ this.state.trades.length > 0 ? this.state.trades[0].type : `` }/>
                        //</div>
                        )
                      }
                      {
                        this.props.activeChartsTab == 2 && (
                    
                          <div className="market-data-main-chart">
                            <ScrollableArea>
                              <div className="addFlex">
                                {
                                  this.state.buys.length > 0 && this.state.sells.length > 0 && (
                                    <MarketVerticalDepthChart
                                      market={ this.props.active && this.props.active.market }
                                      buys={ this.state.buys.length > 20 ? this.state.buys.slice(this.state.buys.length - 20) : this.state.buys }
                                      sells={ this.state.sells.slice(0, 20) }
                                      zeroStyle={ this.props.zeroStyle }
                                      depthStyle={ this.props.depthStyle }
                                      theme={ this.props.theme } 
                                      openOrders={ this.state.tvcOpenOrders } />
                                  )
                                }
                                <StreamingTradesList
                                  market={ this.props.active && this.props.active.market }
                                  trades={ this.state.trades }
                                  zeroStyle={ this.props.zeroStyle } 
                                  exchanges={ this.props.exchanges }/>
                              </div>
                            </ScrollableArea>
                          </div>
                    
                        )
                      }
                    </Tabs>
                  </div>
                  <div className={ `balances-orders` }>
                    <div className="toggle-panel">
                      <a onClick={ () =>  this.toggleOpen(`ordersTableOpen`) }>
                        <Popover
                          isOpen={ this.state.balancesOrdersHistoryPopover }
                          positions={ [ `left` ] }
                          content={ ({ position, childRect, popoverRect }) => (
                            <ArrowContainer 
                              position={ position }
                              childRect={ childRect }
                              popoverRect={ popoverRect }
                              arrowColor={ `var(--gray-1)` }>
                              <div className={ `popover-content` }>
                                { `${this.props.t(`Toggle Balances & Orders`)}` }
                              </div>
                            </ArrowContainer>
                          ) }>
                          <label
                            className="filter-popover" 
                            onMouseEnter={ () =>  this.setState({ balancesOrdersHistoryPopover: true }) }
                            onMouseLeave={ () => this.setState({ balancesOrdersHistoryPopover: false }) }>
                            {
                              this.props.ordersTableOpen ? CollapseRight(`ordersTableOpen`) : CollapseLeft(`ordersTableOpen`)
                            }
                          </label>
                        </Popover>
                      </a>
                    </div>
                    { this.props.ordersTableOpen  &&
                    <div>
                      <BalancesOrdersHistory
                        marketSwitcherOpen={ this.props.marketSwitcherOpen }
                        toggleOpen={ this.toggleOpen.bind(this) }
                        showLarger={ true }
                        active={ this.props.active }
                        markets={ this.props.markets }
                        activeBOHTab={ this.props.activeBOHTab || 0 }
                        updateBOHTab={ (tab) => this.handleBOHTabChange(tab) }
                        isFull={ true }
                        exchFilter={  this.state.exchFilter }
                        mktFilter={  this.state.mktFilter }
                        authFilter={  this.state.authFilter }
                        getCurrentAuthIdValue={ this.getCurrentAuthIdValue.bind(this) }
                        updateUserOrderFilters={ this.updateUserOrderFilters.bind(this) }
                        deleteOrder={ this.deleteOrder.bind(this) }
                        balances={ this.props.balances }
                        accounts={ this.props.accounts }
                        exchanges={ this.props.exchanges }
                        currentAuthId={ this.state.currentAuthId } 
                        ordersLastUpdateTime={ this.state.ordersLastUpdateTime }
                        refreshOrdersLastUpdateTime={ this.refreshOrdersLastUpdateTime.bind(this) }/>
                    </div>
                    }
                  </div>
                </div>
              }
              { !(this.props.marketSwitcherVersion !== `v1` && [`xs`, `sm`].includes(this.props.size) && this.props.marketSwitcherOpen) &&
              <div className={ `toggle-panel market-switcher ${this.props.marketSwitcherOpen ? `open`: `` }` }>
                <a onClick={ () => this.toggleOpen(`marketSwitcherOpen`) }>
                  <Popover
                    isOpen={ this.state.marketSwitcherPopover }
                    positions={ [ `right` ] }
                    content={ ({ position, childRect, popoverRect }) => (
                      <ArrowContainer 
                        position={ position }
                        childRect={ childRect }
                        popoverRect={ popoverRect }
                        arrowColor={ `var(--gray-1)` }>
                        <div className={ `popover-content` }>
                          { this.props.t(`Toggle Markets`) }
                        </div>
                      </ArrowContainer>
                    ) }>
                    <label
                      className="filter-popover" 
                      onMouseEnter={ () =>  this.setState({ marketSwitcherPopover: true }) }
                      onMouseLeave={ () => this.setState({ marketSwitcherPopover: false }) }>
                      {
                        this.props.marketSwitcherOpen ? CollapseLeft(`marketSwitcherOpen`) :CollapseRight(`marketSwitcherOpen`) 
                      }
                    </label>
                  </Popover>
                </a>
              </div>
              }
              <div className={ `toggle-panel market-info ${!this.props.marketInfoOpen ? `close`: `` } ${this.props.size}` }>
                <a onClick={ () => this.toggleOpen(`marketInfoOpen`) }>
                  <Popover
                    isOpen={ this.state.marketInfoPopover }
                    positions={ [ `left` ] }
                    content={ ({ position, childRect, popoverRect }) => (
                      <ArrowContainer 
                        position={ position }
                        childRect={ childRect }
                        popoverRect={ popoverRect }
                        arrowColor={ `var(--gray-1)` }>
                        <div className={ `popover-content` }>
                          { `${this.props.t(`Toggle`)} ${this.props.t(`markets:marketDataTrading`)}` }
                        </div>
                      </ArrowContainer>
                    ) }>
                    <label
                      className="filter-popover" 
                      onMouseEnter={ () =>  this.setState({ marketInfoPopover: true }) }
                      onMouseLeave={ () => this.setState({ marketInfoPopover: false }) }>
                      {
                        this.props.marketInfoOpen ? CollapseRight(`marketInfoOpen`) : CollapseLeft(`marketInfoOpen`)
                      }
                    </label>
                  </Popover>
                </a>
              </div>   
            </div>
            <div onClick={ () => this.advanceTour() } className={ `market-data-column fade-in ${ this.state.marketInfoClass }` }>
              <FlexColumn>
                <div className={ `toggle-panel market-info` }> 
                  <a onClick={ () => this.toggleOpen(`marketInfoOpen`) }>
                    <Popover
                      isOpen={ this.state.marketInfoPopover }
                      positions={ [ `left` ] }
                      content={ ({ position, childRect, popoverRect }) => (
                        <ArrowContainer 
                          position={ position }
                          childRect={ childRect }
                          popoverRect={ popoverRect }
                          arrowColor={ `var(--gray-1)` }>
                          <div className={ `popover-content` }>
                            { `${this.props.t(`Toggle`)} ${this.props.t(`markets:marketDataTrading`)}` }
                          </div>
                        </ArrowContainer>
                      ) }>
                      <label
                        className="filter-popover" 
                        onMouseEnter={ () =>  this.setState({ marketInfoPopover: true }) }
                        onMouseLeave={ () => this.setState({ marketInfoPopover: false }) }>
                        {
                          this.props.marketInfoOpen ? CollapseRight(`marketInfoOpen`) : CollapseLeft(`marketInfoOpen`)
                        }
                      </label>
                    </Popover>
                  </a>
                </div>
                <MarketHeader
                  exchange={ this.props.active.exchange }
                  market={ this.props.active.market }
                  isFavorite={ isFavorite }
                  toggleFavorite={ this.toggleFavorite.bind(this) }
                  ticker={ this.state.ticker }
                  lastPrice={ lastPrice }
                  lastTradeType={ this.state.trades.length > 0 ? this.state.trades[0].type : `` }
                  marketDirection={ marketDirection }
                  inlineMode={ false }
                  abbreviate={ this.state.abbreviateHeader }
                  bestBid={
                    this.state.buys.length > 0 ?
                      this.state.buys.reduce(function(prev, curr) {
                        return prev.price > curr.price ? prev : curr;
                      }).price :
                      this.state.ticker.bid
                  }
                  bestAsk={
                    this.state.sells.length > 0 ?
                      this.state.sells.reduce(function(prev, curr) {
                        return prev.price < curr.price ? prev : curr;
                      }).price :
                      this.state.ticker.ask
                  }
                  handleCurrLogoClick={ this.handleCurrLogoClick.bind(this) }
                  openPrice={ this.state.openPrice }/>
                <MarketInfo
                  active={ this.props.active }
                  trades={ this.state.trades }
                  buys={ this.state.buys }
                  sells={ this.state.sells }
                  notes={ this.state.notes }
                  lastPrice={ lastPrice }
                  balances={ this.props.balances }
                  accounts={ this.props.accounts }
                  ticker={ this.state.ticker }
                  range={ this.state.range }
                  markets={ this.props.markets }
                  exchanges={ this.props.exchanges }
                  currencies={ this.props.currencies }
                  updateNotes={ this.updateNotes.bind(this) }
                  activeTab={ this.props.activeTab }
                  updateTab={ (tab) => this.handleInfoTabChange(tab) }
                  updateBOHTab={ (tab) => this.handleBOHTabChange(tab) }
                  orderFormType={ this.props.orderFormType }
                  updateOrderFormType={ (orderFormType) => this.handleOrderFormTypeChange(orderFormType) }
                  alertType={ this.props.alertType }
                  updateAlertType={ (alertType) => this.handleAlertTypeChange(alertType) }
                  marketLabel={
                    `${ this.props.active.exchange.exchCode } ${ getMarketPair(this.props.active.market).toString() }`
                  }
                  researchBaseCurrency={ this.state.baseCurrency }
                  researchQuoteCurrency={ this.state.quoteCurrency }
                  deleteAlert={ this.deleteAlert.bind(this) }
                  setCurrentAccount={ this.setCurrentAccount }
                  zeroStyle={ this.props.zeroStyle }
                  depthStyle={ this.props.depthStyle }
                  theme={ this.props.theme }
                  isVisible={ this.state.isVisible }
                  currentAuthId={ this.state.currentAuthId }
                  baseCurrencyItbSignals={ this.state.baseCurrencyItbSignals }
                  quoteCurrencyItbSignals={ this.state.quoteCurrencyItbSignals }
                  cmCalCategories={ this.state.cmCalCategories }
                  cmCalCoins={ this.state.cmCalCoins }
                  bestBid={
                    this.state.buys.length > 0 ?
                      this.state.buys.reduce(function(prev, curr) {
                        return prev.price > curr.price ? prev : curr;
                      }).price :
                      this.state.ticker.bid
                  }
                  bestAsk={
                    this.state.sells.length > 0 ?
                      this.state.sells.reduce(function(prev, curr) {
                        return prev.price < curr.price ? prev : curr;
                      }).price :
                      this.state.ticker.ask
                  } 
                  openOrders={ this.state.tvcOpenOrders }
                  alertsLastUpdateTime={ this.state.alertsLastUpdateTime }
                  refreshAlertsLastUpdateTime={ this.refreshAlertsLastUpdateTime.bind(this) }/>
              </ FlexColumn>
            </div>
          </div>
        </>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  favorites: state.app.favorites,
  marketInfoOpen: state.redisPrefs.marketInfoOpen,
  marketSwitcherOpen: state.redisPrefs.marketSwitcherOpen,
  ordersTableOpen: state.redisPrefs.ordersTableOpen,
  showLargerOrdersTable: state.redisPrefs.showLargerOrdersTable,
  marketChartOpen: state.redisPrefs.marketChartOpen,
  priceClick: state.redisPrefs.priceClick,
  zeroStyle: state.redisPrefs.zeroStyle,
  depthStyle: state.redisPrefs.depthStyle,
  theme: state.redisPrefs.theme,
  activeTab: state.markets.marketsInfoActiveTab,
  activeOrdersTab: state.markets.marketsOrdersActiveTab,
  activeChartsTab: state.markets.marketsChartsActiveTab,
  activeBOHTab: state.markets.marketsBOHActiveTab,
  orderFormType: state.markets.marketsOrderFormActiveType,
  alertType: state.markets.marketsAlertActiveType,
  history: state.markets.history,
  marketsAreClickable: state.browser.marketsAreClickable,
  orderTypes: state.orders.orderTypes,
  platformId: state.userInfo.user.platformId,
  orderPageSize: state.redisPrefs.prefPage?.balancesOrderHistory?.pageSize,
  alertPageSize: state.redisPrefs.prefPage?.marketAlerts?.pageSize,
  // isMarketsFlexlayout: state.app.marketsFlexlayout,
  // prefSubscriptionExpires: state.userInfo?.userPrefs?.prefSubscriptionExpires,
  // prefSubscriptionId: state.userInfo?.userPrefs?.prefSubscriptionId,
  // prefTrialUsed: state.userInfo?.userPrefs?.prefTrialUsed,
  subscriptionStatus: state.userInfo?.subscriptionInfo?.subscriptionStatus,
  marketSwitcherVersion: state.app.marketSwitcherVersion,
  rehydrated: state._persist.rehydrated,
});

const mapDispatchToProps = (dispatch) => ({
  updateFavorites: (favorites) => dispatch(updateFavorites(favorites)),
  updatePrefs: (collapse) => dispatch(updateRedisPrefs(collapse)),
  updateTab: (tab) => dispatch(updateMarketsInfoActiveTab(tab)),
  updateOrdersTab: (tab) => dispatch(updateMarketsOrdersActiveTab(tab)),
  updateChartsTab: (tab) => dispatch(updateChartsActiveTab(tab)),
  updateBOHTab: (tab) => dispatch(updateBOHActiveTab(tab)),
  updateOrderFormType: (orderFormType) => dispatch(updateOrderFormActiveType(orderFormType)),
  updateAlertType: (alertType) => dispatch(updateAlertActiveType(alertType)),
  updateCurrentFavorite: (favorite) => dispatch(updateCurrentFavorite(favorite)),
  changePageSize: (p) => dispatch(changeGlobalPageSize(p))
});

export { MarketDataLayer as PureMarketDataLayer };
export default translate(`markets`)(connect(mapStateToProps, mapDispatchToProps)(MarketDataLayer));

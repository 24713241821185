// @flow
'use strict';

import React, { useEffect, useState } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CollapseLeft from '../../svgs/CollapseLeft.jsx';
import CollapseRight from '../../svgs/CollapseRight.jsx';
import SelectField from "../utilities/SelectField.jsx";
import BalancesSwitcher from './BalancesSwitcher.jsx';
import BalancesTable from './BalancesTable/index.jsx';
import BalancesOverview from './BalancesOverview.jsx';
import { updateRedisPrefs } from '../../actions/redisPrefs/updateRedisPrefs.js';
import { setBalanceFilters } from '../../actions/balances/setBalanceFilters.js';
import { selectQuoteCurrencyCode } from "../../actions/app/selectQuoteCurrencyCode.js";
import { CURRENCY_OPTIONS } from "../../constants/balances.js";
import { toFixedDecimalsHTML } from "../../helpers/NumberHelper.js";
import type { Balance } from "../../types/Balance.js";
import type { Account } from "../../types/Account.js";
import type { Exchange } from "../../types/Exchange.js";
import { Market } from '../../types/Market.js';
import { updateTitle } from '../../helpers/BrowserTitleHelper.js';
import { addZeroBalance, getBalanceTotal } from '../../helpers/BalancesHelper.js';
import { addWalletExchange } from '../../helpers/ExchangeTradingHelper.js';
import PillToggle from "../utilities/PillToggle.jsx";

export type Props = {
  t: any,
  refreshBalance: (n: number, d: boolean) => Promise<any>,
  balanceSwitcherOpen: boolean,
  balances: Array<Balance>,
  balanceFilters: Array<Balance>,
  accounts: Array<Account>,
  exchanges: Array<Exchange>,
  markets: Array<Market>,
  params: Object,
  updatePrefs: (any) => void,
  setBalanceFilters: (balances: Array<Balance>) => void,
  selectedQuoteCurrencyCode: string,
  selectQuoteCurrencyCode: (string) => void,
  platformId: number
};

const BalancesDataLayerComponent = (props: Props) => {
  const { 
    t, refreshBalance, balanceSwitcherOpen: propsBalanceSwitcherOpen, 
    balances: propsBalances, balanceFilters, accounts, exchanges: propsExchanges, 
    selectedQuoteCurrencyCode, selectQuoteCurrencyCode, setBalanceFilters,
    updatePrefs, platformId, markets
  } = props;
  const [activeTab, setActiveTab] = useState(`balances`);
  const [balanceSwitcherOpen, setBalanceSwitcherOpen] = useState(propsBalanceSwitcherOpen);

  const history = useHistory();

  const toggleOpen = () => {
    setBalanceSwitcherOpen(!balanceSwitcherOpen);
    updatePrefs({ key: `balanceSwitcherOpen`, value: !balanceSwitcherOpen });
  };

  const onChangeFilter = (filters: Array<Balance>) => {
    setBalanceFilters(filters);
  };

  const onChangeCurrency = (currency: string) => {
    selectQuoteCurrencyCode(currency);
  };

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
    history.push(`/portfolio/${tabName}`);
  };

  updateTitle(`Balances`, platformId);

  useEffect(() => {
    const pathMatch = location.pathname.match( /(line-chart|bar-chart|distribution|gainers-losers)/g );
    if (pathMatch) {
      setActiveTab(pathMatch[0]);
    }
  }, []);

  const tabs: Array<any> = [
    {
      value: `balances`,
      label: t(`balances`),
      component: BalancesTable
    },
    {
      value: `distribution`,
      label: t(`distribution`),
      component: BalancesOverview
    },
    {
      value: `line-chart`,
      label: t(`lineChart`),
      component: BalancesOverview
    },
    {
      value: `bar-chart`,
      label: t(`barChart`),
      component: BalancesOverview
    },
    {
      value: `gainers-losers`,
      label: t(`gainersLosers`),
      component: BalancesOverview
    }
  ];

  const CurrentComponent = (tabs.find((item) => item.value === activeTab) || { component: null }).component;
  const CurrentTitle = (tabs.find((item) => item.value === activeTab) || { label: null }).label;
  const exchanges = addWalletExchange(propsExchanges);

  /* 
  Inject "zero balance" object with the currency code "No Balances Detected."
  So user is able to select/de-select accounts even if they have no balances for a specific account. 
  **/
  const balances = addZeroBalance(accounts, propsBalances);

  const balanceTotal = toFixedDecimalsHTML(getBalanceTotal(balances, balanceFilters, selectedQuoteCurrencyCode), false, `balance`);

  const lscxExchange = exchanges.filter((e) => e.exchCode === `LSCX`);
  let hasLSCX = false;
  if (lscxExchange.length > 0) {
    if (accounts.some((a) => a.authExchId === lscxExchange[0].exchId)) {
      hasLSCX = true;
    }
  }

  return (
    <div className="balances cols">
      <div className="main-content">

        { balanceSwitcherOpen && <BalancesSwitcher
          className={ balanceSwitcherOpen ? `in` : `out` }
          balances={ balances }
          accounts={ accounts }
          exchanges={ exchanges }
          filters={ balanceFilters }
          refreshBalance={ refreshBalance }
          onChangeFilter={ (f) => onChangeFilter(f) }/>
        }
        <a className={ `collapse account-switcher-collapse ${ balanceSwitcherOpen ? `open` : `` }` } data-testid={ `balance-switcher-toggle-container` }>
          <span
            onClick={ () => toggleOpen() }
            data-testid={ `balance-switcher-toggle` }
            className={ `vertical-text` }>
            {
              balanceSwitcherOpen ?
                CollapseLeft(`balanceSwitcherOpen`) :
                CollapseRight(`balanceSwitcherOpen`) 
            }
            {
              !balanceSwitcherOpen && t(`accountsWallets`)
            }
            {
              !balanceSwitcherOpen && CollapseRight(`balanceSwitcherOpen`) 
            }
          </span>
        </a>          
        <div className="main-section">
          <h1 className="page-title">
            { t(`portfolio`) }
          </h1>
          <div className="heading">
            <ul className="header-tab">
              <PillToggle
                options={ tabs }
                value={ activeTab }
                onChange={ (e, v) => handleTabClick(v) } />
            </ul>
            <div className="total-balance">
              <div className="balance-value">
                <div className="total-balance-value">
                  <span dangerouslySetInnerHTML={ balanceTotal } />
                </div>
              </div>
              <div className="balance-options">
                <SelectField
                  label={ `` }
                  name={ `currency` }
                  options={ CURRENCY_OPTIONS }
                  onChange={ (e, v) => onChangeCurrency(v) }
                  value={ selectedQuoteCurrencyCode != `undefined` ? selectedQuoteCurrencyCode : `btc` }/>
              </div>
            </div>
          </div>               
          { 
            CurrentComponent && (
              <CurrentComponent
                accounts={ accounts }
                exchanges={ exchanges }
                balances={
                  balances.filter((filterBalance) => 
                    !balanceFilters.find((balance) =>
                      balance.balanceAuthId === filterBalance.balanceAuthId &&
                        balance.balanceCurrId === filterBalance.balanceCurrId
                    )
                  ).filter((bal) => bal.balanceCurrId !== 0) 
                }
                activeTab={ activeTab }
                activeTabName={ CurrentTitle } 
                markets={ markets }/>
            )
          }
        </div>       
      </div>
      {
        hasLSCX && (
          <div className="lscx-disclaimer">
* Lightspeed Crypto balances are held at your account at Prime Trust, LLC. Wallets are provided by Prime Trust, LLC with an arrangement with Fireblocks
          </div>
        )
      }
      
    </div>
  );
};

const mapStateToProps = (state) => ({
  balanceSwitcherOpen: state.redisPrefs.balanceSwitcherOpen,
  balanceFilters: state.balances.filters,
  selectedQuoteCurrencyCode: state.app.selectedQuoteCurrencyCode,
  platformId: state.userInfo.user.platformId
});

const mapDispatchToProps = (dispatch) => ({
  updatePrefs: (collapse) => dispatch(updateRedisPrefs(collapse)),
  setBalanceFilters: (filters) => dispatch(setBalanceFilters(filters)),
  selectQuoteCurrencyCode: (currency) => dispatch(selectQuoteCurrencyCode(currency))
});

export { BalancesDataLayerComponent as PureBalancesDataLayerComponent };
export default translate(`balances`)(connect(mapStateToProps, mapDispatchToProps)(BalancesDataLayerComponent));

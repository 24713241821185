// @flow
'use strict';

import React from 'react';
import Book from "../../../svgs/Book.jsx";

type MenuLinkProps = {
  text: string,
}

export const VideoTutorialsLink = (props: MenuLinkProps) => {
  const { text } = props;

  return (
    <a
      href="https://www.youtube.com/channel/UCSAQcN2DObuF_7NE-DdBXJw"
      rel="noopener noreferrer"
      target="_blank">
      { Book }
      <span>
        { text }
      </span>
    </a>
  );
};

// @flow
'use strict';

import React from 'react';
import { Portal } from './Portal.jsx';
type Props = {
  handleClick: (e: any) => void,
  options: Array<{
    label: any,
    value: any,
  }>,
  defaultValue?: any
};

const UndockedSelectField = (props: Props) => {
  const { handleClick, options, defaultValue } = props;

  return (
    options && (
      <Portal
        className={ `notifications-menu` }>
        <div className="undocked-select-field_backdrop" data-testid={ `select-field-backdrop` } onClick={ () => handleClick(`close_backdrop`) }>
          <div className="undocked-select-field">
            <ul>
              {
                options.map((option, index) => {
                  const { value, label } = option;
                  const isDefaultValueClassName = defaultValue === value ? `selected`: undefined;
                  return (
                    <li className={ isDefaultValueClassName } key={ index } onClick={ () => handleClick(option.value) }>
                      <a>
                        { label }
                      </a>
                    </li>
                  );
                })
              }
            </ul>
          </div>
        </div>
      </Portal>
    )
  );
};

export default UndockedSelectField;

// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import { getMarketFromPanelSettings } from '../../helpers/MarketPairHelper.js';
import MarketDepthChartData from '../markets/MarketDepthChartData.jsx';
import type { Market } from '../../types/Market.js';

type Settings = {
  exchange: string,
  market: string
};

type Props = {
  t: any,
  market: Market,
  settings: Settings,
  height: number,
  panelData: any
};

const MarketOrderbookPanel = ({ t, market, settings, panelData, height }: Props) => {
  let mkt = `${ settings.exchange }:${ settings.market }`;

  if (panelData.hasOwnProperty(mkt) && 
    panelData[mkt].depth &&
    panelData[mkt].depth.bids &&
    panelData[mkt].depth.bids.length > 0 &&
    panelData[mkt].depth.asks &&
    panelData[mkt].depth.asks.length > 0 &&
    market) {

    let { bids, asks } = panelData[mkt].depth;

    return (
      <div className="market-depth-chart-wrapper market-depth-chart-data-panel">
        <div className="box">
          <MarketDepthChartData
            market={ market }
            height={ height }
            buys={ bids }
            sells={ asks }/>
        </div>
      </div>
    );
  } else {
    return (
      <div className="panel-loading">
        <span>
          { t(`app:loading`) }
        </span>
      </div>
    );
  }
};

MarketOrderbookPanel.getPanelTitle = (settings: Settings, markets: Array<Market>) => {
  let market = getMarketFromPanelSettings(markets, settings);

  return market ? market.exchName + ` - ` + market.displayName : `Market Orderbook`;
};

export default translate(`boards`)(MarketOrderbookPanel);

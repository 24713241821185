import React, { useEffect, useState } from 'react';
// import EntityTable from '../../utilities/EntityTable.jsx';
import DataTable from '../../utilities/DataTable';
import Button from '../../utilities/Button.jsx';
import { toFixedDecimalsHTML } from '../../../helpers/NumberHelper.js';
import { setBalanceFormat } from "../../../helpers/BalancesHelper.js";
// import { getCustomerStatementsJson } from '../../../helpers/api/UserApi.js';
// import { toLocalDate } from "../../../helpers/DateHelper.js";
// import ProgressSpinner from '../../utilities/ProgressSpinner.jsx';

// const DATE_FORMAT_REQ = `%Y%Y%Y%Y-%M%M-%D%D`;

export const DetailsBalances = ({ t, tableData = [], handleWithdrawModal, selectedExchange, filterText, handleDepositModal, startDate, endDate }) => {
  const [data, setData] = useState([]);
  const exchangeIsCGY = selectedExchange && selectedExchange.exchCode === `CGY`;

  // const getUnrealizedByBaseCurr = (baseCurrCode, lastPrice, unrealizedPositions = []) => {
  //   return unrealizedPositions
  //     .filter((up) => up.currCode.indexOf(`${baseCurrCode}/`) === 0 )
  //     .reduce((accum, unrealized) => accum + ((unrealized.remaining_qx * lastPrice) - (unrealized.remaining_qx * unrealized.buy_price)), 0);
  // };
  
  // const getRealizedByBaseCurr = (baseCurrCode, realizedPositions = []) => {
  //   return realizedPositions
  //     .filter((rp) => rp.currency.indexOf(`${baseCurrCode}/`) === 0)
  //     .reduce((accum, realized) => accum + realized.realized_gain_loss, 0);
  // };

  useEffect(() => {
    setData(tableData);
    
    // if (exchangeIsCGY && tableData) {
    //   getCustomerStatementsJson(toLocalDate(startDate, DATE_FORMAT_REQ), toLocalDate(endDate, DATE_FORMAT_REQ), (data) => {
    //     const _data = tableData.map((tData) =>({
    //       ...tData,
    //       unrealized: getUnrealizedByBaseCurr(tData.balanceCurrCode, tData.lastTrade || tData.lastPrice || 0, data.result?.unrealizedPositions),
    //       realized: getRealizedByBaseCurr(tData.balanceCurrCode, data.result?.realizedGainsLosses)
    //     }
    //     ));

    //     setData(_data);
    //   });
    // }
  }, [exchangeIsCGY, tableData, startDate, endDate]);  

  const columns = [
    {
      title: t(`currency`),
      key: `balanceCurrCode`,
      display: (row) => (
        <div className="align-left">
          <img 
            src={ `${ window.WWW_URL }/assets/img/currency/${ row.balanceCurrCode }.png` } 
            width={ 20 } />
          { ` ` }
          <span style={ { fontWeight: `bold`, fontSize: `1.8rem` } }>
            { row.balanceCurrCode }
          </span>
          <span>
            { row.balanceCurrName }
          </span>
        </div>
      ),
      thClass: `align-left`,
      tdClass: `align-left currency`,
      enableSorting: true,
      compareValue: (row) => `${row.balanceCurrCode}-${row.balanceCurrName}`
    },
    {
      title: t(`available`),
      key: `balanceCurrName`,
      display: (row) => (
        <span dangerouslySetInnerHTML={ toFixedDecimalsHTML(row.balanceAmountAvailable, true, setBalanceFormat(row.balanceCurrCode)) } />
      ),
      thClass: `align-right`,
      tdClass: `align-right`,
      enableSorting: true,
      compareValue: (row) => row.balanceAmountAvailable
    },
    {
      title: t(`held`),
      key: `held`,
      display: (row) => (
        <span dangerouslySetInnerHTML={ toFixedDecimalsHTML(row.balanceAmountHeld, true, setBalanceFormat(row.balanceCurrCode)) } />
      ),
      thClass: `align-right`,
      tdClass: `align-right`,
      enableSorting: true,
      compareValue: (row) => row.balanceAmountHeld
    },
    {
      title: t(`total`),
      key: `total`,
      display: (row) => (
        <span dangerouslySetInnerHTML={ toFixedDecimalsHTML(row.balanceAmountTotal, true, setBalanceFormat(row.balanceCurrCode)) } />
      ),
      thClass: `align-right`,
      tdClass: `align-right`,
      enableSorting: true,
      compareValue: (row) => row.balanceAmountTotal

    },
    {
      title: t(`price (USD)`),
      key: `price`,
      display: (row) => (
        <span dangerouslySetInnerHTML={ toFixedDecimalsHTML((row.lastTrade || row.lastPrice || 0), true, setBalanceFormat(`USD`)) } />
      ),
      thClass: `align-right`,
      tdClass: `align-right`,
      enableSorting: true,
      compareValue: (row) => row.lastTrade || row.lastPrice || 0
    },
    {
      title: t(`total (USD)`),
      key: `usd`,
      display: (row) => (
        <span dangerouslySetInnerHTML={ toFixedDecimalsHTML(row.quoteBalance, true, setBalanceFormat(`USD`)) } />
      ),
      thClass: `align-right`,
      tdClass: `align-right`,
      enableSorting: true,
      compareValue: (row) => row.quoteBalance
    },
  ];


  
  if (exchangeIsCGY) {

    columns.push(
      // {
      //   title: t(`Unrealized Gain`),
      //   key: `unrealized`,
      //   display: (row) => (
      //     row.unrealized !== undefined ? 
      //       <span 
      //         className={ `${(row.unrealized) < 0 ?  `red` : `green`}` }
      //         dangerouslySetInnerHTML={ toFixedDecimalsHTML(row.unrealized, true, setBalanceFormat(`USD`)) }/>
      //       :
      //       <span className={ `nowrap align-center` }>
      //         <ProgressSpinner/>
      //       </span>
      //   ),
      //   thClass: `align-right gains`,
      //   tdClass: `align-right gains`
      // },
      // {
      //   title: t(`Realized Gain`),
      //   key: `realized`,
      //   display: (row) => (
      //     row.realized !== undefined  ?
      //       <span 
      //         className={ `${(row.realized) < 0 ?  `red` : `green`}` }
      //         dangerouslySetInnerHTML={ toFixedDecimalsHTML(row.realized, true, setBalanceFormat(`USD`)) }/>
      //       :
      //       <span className={ `nowrap align-center` }>
      //         <ProgressSpinner/>
      //       </span>
      //   ),
      //   thClass: `align-right gains`,
      //   tdClass: `align-right gains green`
      // },
      // {
      //   title: t(`Total Profit/Loss`),
      //   key: `total P/L`,
      //   display: (row) => (
      //     row.unrealized !== undefined && row.realized !== undefined  ? 
      //       <span 
      //         className={ `${(row.unrealized + row.realized) < 0 ?  `red` : `green`}` }
      //         dangerouslySetInnerHTML={ toFixedDecimalsHTML(row.unrealized + row.realized, true, setBalanceFormat(`USD`)) }/>
      //       :
      //       <span className={ `nowrap align-center` }>
      //         <ProgressSpinner/>
      //       </span>
      //   ),
      //   thClass: `align-right gains`,
      //   tdClass: `align-right gains`
      // },      
      {
        title: t(`actions`),
        thClass: `align-right actions`,
        key: `actions`,
        display: (row) => (
          <div className="balances-actions">
            { 
              row.hasBalance ? (
                <>
                  <Button
                    type="primary" onClick={ () => handleDepositModal(true, row) }>
                    { t(`deposit`) }
                  </Button>
                  <Button 
                    type="secondary" onClick={ () => handleWithdrawModal(true, row) }>
                    { t(`accounts:withdraw`) }
                  </Button>
                </>
              ) 
                : 
                (
                  <>
                    <Button type="primary" onClick={ () => handleDepositModal(true, row) }>
                      { t(`deposit`) }
                    </Button>
                  </>
                )
            }
          </div>
        )
      });
  }

  return (
    <div className="details-table fade-in">
      <DataTable
        id={ `detail-balances-table` }
        columns={ columns }
        data={ data }
        filterText={ filterText }
        serverSideSorting={ false }
        serverSideFiltering={ false }
        defaultColumnPinning={ { left: [``], right: [`actions`] } }
        defaultSortBy={ `-usd` } 
        isFullWidth
        hideSideBar
        wrapperHeight={ undefined }/>
    </div>
  );
};

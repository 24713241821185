// @flow
'use strict';

import React from 'react';
import Button from './../Button.jsx';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import FilterIcon from '@mui/icons-material/FilterAlt';

export const SideBar = ({ toggleColumns, setToggleColumns, toggleFilters, setToggleFilters }: any) => {

  return (
    <div className="side-bar">
      <div className="side-buttons">
        <Button 
          className={ toggleColumns ? `primary` : `secondary` } 
          onClick={ () => { setToggleColumns((prev) => !prev); setToggleFilters(false); return false; } } 
          customProps={ { style: { borderWidth: 0, padding: `0.2rem 0.2rem 0.8rem 0.2rem` } } }>
          <div>
            <ViewColumnIcon/> 
            <span>
              { `Columns` }
            </span>
          </div>
        </Button>

        <Button
          className={ toggleFilters ? `primary` : `secondary` }
          onClick={ () => { setToggleFilters((prev) => !prev); setToggleColumns(false); return false; } }
          customProps={ { style: { borderWidth: 0, padding: `0.2rem 0.2rem 0.8rem 0.2rem` } } }>
          <div>
            <FilterIcon/> 
            <span>
              { `Filters` }
            </span>
          </div>
        </Button>
      </div>
    </div>
  );
};

// @flow
'use strict';

import React from 'react';
import store from './StoreHelper.js';
import THEMES from '../constants/themes.js';
import { THEME_FOOTPRINT } from '../constants/themeFootprint.js';
import type { Theme } from '../types/Theme.js';
import { resetToTheme } from '../actions/theme/resetToTheme.js';
import { readCookie } from './CookieHelper';

const adjustColor = (color, amt) => {
  amt = (amt / 100) * 255;
  color = color.slice(1);

  let num = parseInt(color, 16),
    r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00FF) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000FF) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  let e = `#${ (g | (b << 8) | (r << 16)).toString(16) }`;
  if (e == `#0`) return `#000000`;
  return e;
};

const translateHex = (hex: string) => {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : { r: 0, g: 0, b: 0 };
};

const processTheme = (theme: Theme) => {
  let styles = { };

  Object.keys(THEME_FOOTPRINT).forEach((k) => {
    let selector = THEME_FOOTPRINT[k];

    // $FlowIgnore: suppressing this error
    if (!theme.hasOwnProperty(k) && !theme[k]) {
      let value = theme.invertToLight ? selector.lightValue : selector.darkValue;

      // $FlowIgnore: suppressing this error
      if (theme.bordersNoShadows && selector.hasOwnProperty(`bnsValue`)) {
        // $FlowIgnore: suppressing this error
        value = selector.bnsValue;
      }

      styles[`--${ selector.name }`] = value;
      return;
    }

    // $FlowIgnore: suppressing this error
    let themeColor = theme[k],
      rgb = translateHex(themeColor),
      m = theme.invertToLight ? 1 : -1;

    //console.log(theme);
    styles[`--${ selector.name }`] = themeColor;

    /* eslint-disable */
    if(selector.notFriendly != true) {
      let rgbAlt = translateHex(adjustColor(themeColor, m * 5));
      if(selector.name == "gray-3"){
        if(theme.backgroundRepeat == "cover"){
          styles[`--background-image`] = `url('${ theme.backgroundImage }') center center/cover no-repeat ${ themeColor }`;
        }else{
          styles[`--background-image`] = `url('${ theme.backgroundImage }') top left ${ theme.backgroundRepeat } ${ themeColor }`;
        }
      }

      styles[`--${ selector.name }-theme-alpha`] = `rgba(${ rgb.r }, ${ rgb.g }, ${ rgb.b }, ${ 1-(theme.transparency / 100) })`;
      styles[`--${ selector.name }-table-alpha`] = `rgba(${ rgb.r }, ${ rgb.g }, ${ rgb.b }, ${ 1-(theme.tableTransparency / 100) })`;
      styles[`--${ selector.name }-table-alpha-alt`] = `rgba(${ rgbAlt.r }, ${ rgbAlt.g }, ${ rgbAlt.b }, ${ 1-(theme.tableTransparency / 100) })`;
      styles[`--${ selector.name }-tab-alpha`] = `rgba(${ rgb.r }, ${ rgb.g }, ${ rgb.b }, ${ 1-(theme.tabTransparency / 100) })`;
      styles[`--${ selector.name }-left-sidebar-alpha`] = `rgba(${ rgb.r }, ${ rgb.g }, ${ rgb.b }, ${ 1-(theme.leftSidebarTransparency / 100) })`;
      styles[`--${ selector.name }-right-sidebar-alpha`] = `rgba(${ rgb.r }, ${ rgb.g }, ${ rgb.b }, ${ 1-(theme.rightSidebarTransparency / 100) })`;
      styles[`--${ selector.name }-right-sidebar-alpha-alt`] = `rgba(${ rgbAlt.r }, ${ rgbAlt.g }, ${ rgbAlt.b }, ${ 1-(theme.rightSidebarTransparency / 100) })`;
      styles[`--${ selector.name }-checkbox`] = `url('data:image/svg+xml;utf8,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22M-3-3h24v24H-3z%22%2F%3E%3Cpath%20d%3D%22M16%202v14H2V2h14zm0-2H2C.9%200%200%20.9%200%202v14c0%201.1.9%202%202%202h14c1.1%200%202-.9%202-2V2c0-1.1-.9-2-2-2z%22%20fill%3D%22${encodeURIComponent(themeColor)}%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');`;
      styles[`--${selector.name}-checkbox-partial`] = `url('data:image/svg+xml;utf8,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22M-3-3h24v24H-3z%22%2F%3E%3Cpath%20d%3D%22M16%200H2C.9%200%200%20.9%200%202v14c0%201.1.9%202%202%202h14c1.1%200%202-.9%202-2V2c0-1.1-.9-2-2-2zM7%2014L2%209.192l1.4-1.346L7%2011.308%2014.6%204%2016%205.346%207%2014z%22%20fill%3D%22${encodeURIComponent(themeColor)}%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E')`;
      styles[`--${ selector.name }-checkbox-checked`] = `url('data:image/svg+xml;utf8,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22M-3-3h24v24H-3z%22%2F%3E%3Cpath%20d%3D%22M16%200H2C.9%200%200%20.9%200%202v14c0%201.1.9%202%202%202h14c1.1%200%202-.9%202-2V2c0-1.1-.9-2-2-2zM7%2014L2%209.192l1.4-1.346L7%2011.308%2014.6%204%2016%205.346%207%2014z%22%20fill%3D%22${encodeURIComponent(themeColor)}%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E')`;
      styles[`--${ selector.name }-radio`] = `url('data:image/svg+xml;utf8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22M-2-2h24v24H-2z%22%2F%3E%3Cpath%20d%3D%22M10%200C4.5%200%200%204.5%200%2010s4.5%2010%2010%2010%2010-4.5%2010-10S15.5%200%2010%200zm0%2018c-4.4%200-8-3.6-8-8s3.6-8%208-8%208%203.6%208%208-3.6%208-8%208z%22%20fill-opacity%3D%22.538%22%20fill%3D%22${encodeURIComponent(themeColor)}%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E')`;
      styles[`--${ selector.name }-radio-checked`] = `url('data:image/svg+xml;utf8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22M-2-2h24v24H-2z%22%2F%3E%3Cpath%20d%3D%22M10%205c-2.8%200-5%202.2-5%205s2.2%205%205%205%205-2.2%205-5-2.2-5-5-5zm0-5C4.5%200%200%204.5%200%2010s4.5%2010%2010%2010%2010-4.5%2010-10S15.5%200%2010%200zm0%2018c-4.4%200-8-3.6-8-8s3.6-8%208-8%208%203.6%208%208-3.6%208-8%208z%22%20fill%3D%22${encodeURIComponent(themeColor)}%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E')`;

    }
    /* eslint-enable */

    if (selector.variants && selector.hasOwnProperty(`variants`)) {

      if (selector.variants && selector.variants.alpha && selector.variants.hasOwnProperty(`alpha`)) {
        // $FlowIgnore: suppressing this error
        selector.variants.alpha.forEach((a) => {
          styles[`--${ selector.name }-alpha-${ a }`] = `rgba(${ rgb.r }, ${ rgb.g }, ${ rgb.b }, ${ a / 100 })`;
        });
      }

      if (selector.variants && selector.variants.adjust && selector.variants.hasOwnProperty(`adjust`)) {
        // $FlowIgnore: suppressing this error
        selector.variants.adjust.forEach((a) => {
          styles[`--${ selector.name }-adjust-${ a }`] = adjustColor(themeColor, m * a);
        });
      }

      if (selector.variants && selector.variants.altAdjust && selector.variants.hasOwnProperty(`altAdjust`)) {
        // $FlowIgnore: suppressing this error
        selector.variants.altAdjust.forEach((a) => {
          styles[`--${ selector.name }-adjust-${ a }-alt`] = adjustColor(themeColor, m * a);
        });
      }
    }


  });

  styles[`--font-stack`] = replaceCircularStdFont(styles[`--font-stack`]);

  return styles;
};

const wrapStyles = (styles) => {
  return `:root {${ Object.keys(styles).reduce((s, k) => `${ s }${ k }:${ styles[k] };`, ``) }}`;
};

const renderTheme = (theme: string = store.getState().theme) => (
  <style dangerouslySetInnerHTML={ { __html: wrapStyles(processTheme(getActiveTheme(theme))) } } />
);

const validateTheme = (theme: Theme) => {
  let valid = true,
    darkKeys = Object.keys(THEMES.Dark).sort(),
    inputKeys = Object.keys(theme).sort();

  for (let i = 0; i < darkKeys.length; i += 1) {
    if (darkKeys[i] !== inputKeys[i]) {
      /* eslint-disable */
      console.debug(
        `Invalid key: ${ inputKeys[i] }`,
        `Expected: ${ darkKeys[i] }`
      );
      /* eslint-enable */

      valid = false;
    }

    // $FlowIgnore: suppressing this error
    if (valid && typeof THEMES.Dark[darkKeys[i]] !== typeof theme[darkKeys[i]]) {
      /* eslint-disable */
      console.debug(
        `Invalid type of ${ darkKeys[i] }: ${ typeof theme[darkKeys[i]] }`,
        `Expected: ${ typeof THEMES.Dark[darkKeys[i]] }`
      );
      /* eslint-enable */

      valid = false;
    }

    // $FlowIgnore: suppressing this error
    if (valid && typeof THEMES.Dark[darkKeys[i]] == `string` && THEMES.Dark[darkKeys[i]][0] == `#`) {
      let v = theme[darkKeys[i]];

      // $FlowIgnore: suppressing this error
      if (v[0] !== `#`) {
        /* eslint-disable */
        console.debug(
          // $FlowIgnore: suppressing this error
          `Invalid hex value of ${ darkKeys[i] }: ${ v }`,
          // $FlowIgnore: suppressing this error
          `Expected: #${ v }`
        );
        /* eslint-enable */

        valid = false;
      }

      // $FlowIgnore: suppressing this error
      if (valid && v.length !== 7) {
        /* eslint-disable */
        console.debug(
          // $FlowIgnore: suppressing this error
          `Invalid hex value of ${ darkKeys[i] }: ${ v }`,
          `Expected length: 7`
        );
        /* eslint-enable */

        valid = false;
      }

      let allowed = `#0123456789ABCDEF`.toLowerCase().split(``);

      if (valid) {
        // $FlowIgnore: suppressing this error
        v.toLowerCase().split(``).forEach((k) => {
          if (!allowed.includes(k)) {
            /* eslint-disable */
            console.debug(
              // $FlowIgnore: suppressing this error
              `Unexpected character found in ${ darkKeys[i] }: ${ k }`,
              `Allowed characters: `,
              allowed
            );
            /* eslint-enable */

            valid = false;
          }
        });
      }
    }
  }

  return { valid, theme };
};

const getActiveTheme = (theme: string = store.getState().redisPrefs.theme, process?: boolean = false): any => {
  let ret;
  const defaultTheme = getDefaultTheme();

  if (THEMES.hasOwnProperty(theme)) {
    ret = THEMES[theme];
  } else if (theme == `user` && !!store.getState().theme) {
    ret = store.getState().theme;

    if (!validateTheme(ret).valid) {
      store.dispatch(resetToTheme(defaultTheme));
      ret = THEMES[defaultTheme];
    }
  } else {
    ret = THEMES[defaultTheme];
  }

  if (process) return processTheme(ret);
  return ret;
};

const getDefaultTheme = (): string => {
  const darkModeCookieValue = readCookie(`darkModeCookie`);
  if (darkModeCookieValue) return darkModeCookieValue;

  const matchMediaIsLight = window.matchMedia && window.matchMedia(`(prefers-color-scheme: light)`).matches;
  const isLinux = /Linux/.test(window.navigator?.userAgentData?.platform);

  // prefers-color-scheme not working properly in Linux
  return matchMediaIsLight && !isLinux ? `Light` : `Dark`;
};

const replaceCircularStdFont = (fontFamily: string = ``) => fontFamily?.replace(`CircularStd`, `Poppins`);

export { renderTheme, getActiveTheme, THEMES, validateTheme, translateHex, getDefaultTheme, replaceCircularStdFont };

// @flow
'use strict';

import React from 'react';
import Button from '../utilities/Button.jsx';
import Checkmark from '../../svgs/Checkmark.jsx';

type Props = {
  t: any,
  currentSubscription?: {
    prefUserId: string,
    prefSubscriptionId: number,
    subscriptionPaid: boolean,
    prefSubscriptionExpires: string,
    prefPromoId: number,
    subscriptionPaymentType: string,
    prefLifetime: boolean,
    prefServiceTier: number,
    serviceName: string,
    serviceHash: string,
    daysLeft: number,
    subscriptionStatus: string,
    referralBalance: string
  },
  history: { push: (any) => void }
};

const PlanPurchaseComplete = ({ t, currentSubscription, history }: Props) => {
  let exp = ``;

  if (currentSubscription) exp = currentSubscription.prefSubscriptionExpires;

  return (
    <section>
      <div>
        <div className="top">
          <h2>
            { t(`purchaseComplete`) }
          </h2>
        </div>
        <div className="sub-activated">
          { Checkmark(`Activated`) } 
          { ` ` }
          <span>
            { t(`subActivated`) }
          </span>
        </div>
        {
          currentSubscription && (
            <div className="sub-info">
              <div>
                <strong>
                  { t(`orders:status`) }
                </strong>
                <span>
                  { currentSubscription.subscriptionStatus }
                </span>
              </div>
              <div>
                <strong>
                  { t(`expires`) }
                </strong>
                <span>
                  {
                    `${ exp.slice(0, 10) } (` +
                    Math.floor((new Date(exp) - Date.now()) / 1000 / 60 / 60 / 24).toString() + 
                    ` ${ t(`daysRemaining`) })`
                  }
                </span>
              </div>
            </div>
          )
        }
        <div className="close">
          <Button
            type="default"
            onClick={ (e) => {
              e.preventDefault();
              history.push(`/user/profile`);
              return false;
            } }>
            { t(`close`) }
          </Button>
        </div>
      </div>
    </section>
  );
};

export { PlanPurchaseComplete as PurePlanPurchaseComplete };
export default PlanPurchaseComplete;

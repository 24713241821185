// @flow
'use strict';

const getSize = (n: number) => {
  if (n > 1200) return `xl lg`;
  if (n > 1000) return `lg`;
  if (n > 950) return `md`;
  if (n > 600) return `sm`;
  return `xs`;
};

export { getSize };

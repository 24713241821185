// @flow
'use strict';

import type { Market } from '../types/Market.js';
import type { Exchange } from '../types/Exchange';

const reversedExchs = [`PLNX`, `BITS`, `GOLD`];

const getMarketPair = (market: Market) => {
  return {
    base: market.displayBaseCurrencyCode ?? market.baseCurrCode,
    baseFull: market.displayBaseCurrencyName ?? market.baseCurrName,
    quote: market.displayQuoteCurrencyCode ?? market.quoteCurrCode,
    quoteFull: market.displayQuoteCurrencyName ?? market.quoteCurrName,
    toString: () => market.displayName,
    toFullString: () => `${ market.displayBaseCurrencyName ?? market.baseCurrName }/${ market.displayQuoteCurrencyName ?? market.quoteCurrName }`
  };
  
};

const getMarketFromPanelSettings = (markets: Array<Market>, panelSettings: *): ?Market => {
  if (panelSettings.hasOwnProperty(`market`) && panelSettings.hasOwnProperty(`exchange`)) {
    return markets.find((m) => m.marketName == panelSettings.market && m.exchCode == panelSettings.exchange);
  }

  return null;
};

const getExchangeFromPanelSettings = (exchanges: Array<Exchange>, panelSettings: *): ?Exchange => {
  if (panelSettings.hasOwnProperty(`market`) && panelSettings.hasOwnProperty(`exchange`)) {
    return exchanges.find((m) => m.exchCode == panelSettings.exchange);
  }

  return null;
};

const sortMarkets = (a: Market, b: Market) => {
  if (a.exchCode > b.exchCode) return 1;
  if (a.exchCode < b.exchCode) return -1;
  if (a.displayName > b.displayName) return 1;
  if (a.displayName < b.displayName) return -1;
  return 0;
};

const getMarketUrl = (markets: Array<Market>, exchange: Exchange, primaryCoin: string,  baseCoin: string): string | null => {
  
  const getUrl = (exchange: Exchange, market: Market) => `/markets/${ exchange.exchCode }/${ getMarketPair(market).toString() }`;

  const marketsByExch = markets.filter((m) => m.exchId == exchange.exchId);
  
  const marketsByExchAndPrimaryCoin = marketsByExch.filter((m) => m.baseCurrCode == primaryCoin || m.quoteCurrCode == primaryCoin);
  
  const marketsByExchAndPrimaryCoinAndBaseCoin = marketsByExchAndPrimaryCoin.filter((m) => (m.baseCurrCode == baseCoin || m.quoteCurrCode == baseCoin) && baseCoin !== primaryCoin);

  if (marketsByExchAndPrimaryCoinAndBaseCoin.length) {
    return getUrl(exchange, marketsByExchAndPrimaryCoinAndBaseCoin[0]);

  } else if (marketsByExchAndPrimaryCoin.length) {
    return getUrl(exchange, marketsByExchAndPrimaryCoin.sort(sortMarkets)[0]);

  } else if (marketsByExch.length){
    return getUrl(exchange, marketsByExch.sort(sortMarkets)[0]);
  }
  
  return null;
};

export { getMarketPair, getMarketFromPanelSettings, getExchangeFromPanelSettings, reversedExchs, sortMarkets, getMarketUrl };

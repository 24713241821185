// @flow
'use strict';

function adjustTradePrice(price: number, type: string) {
  let adjustedPrice = 0;

  if (type == `BUY`) {
    adjustedPrice = price * 1.006;
  }
  if (type == `SELL`) {
    adjustedPrice = price * 0.994;
  }

  return adjustedPrice;
}

export { adjustTradePrice };

// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import Modal from '../utilities/Modal.jsx';
import Button from '../utilities/Button.jsx';
import type { Board } from '../../types/Board.js';

type Props = {
  t: any,
  board: Board,
  confirm: () => void,
  cancel: () => void,
  disabled: boolean
};

const ImportBoardModal = ({ t, confirm, cancel, board, disabled }: Props) => (
  <Modal
    title={ t(`importBoardConfirmTitle`) }
    onConfirm={ confirm }
    onClose={ cancel }
    confirmText={ t(`app:import`) }
    cancelText={ t(`app:cancel`) }
    confirmDisabled={ disabled }>
    <h1 className="board-preview-label">
      <span>
        { board.label }
      </span>
      <small>
        { ` ` }
        { t(`app:by`) }
        { ` ` }
        <strong>
          { board.sourceChatNick }
        </strong>
      </small>
    </h1>
    <p>
      { t(`importBoardConfirmText`) }
    </p>

    {
      disabled && (
        <div>
          <p className="error">
            { t(`importBoardConfirmError`) }
          </p>
          <Button 
            type="default"
            onClick={ () => {
              // $FlowIgnore: suppressing this error
              window.location = `${ process.env.WWW_URL }/subscription`;
              return false;
            } }>
            { t(`upgradeAccountNow`) }
          </Button>
        </div>
      )
    }
  </Modal>
);


export { ImportBoardModal as PureImportBoardModal };
export default translate(`boards`)(ImportBoardModal);

// @flow
'use strict';

import React, { useState, useEffect } from 'react';
import TabPane from './TabPane.jsx';
import SelectField from '../utilities/SelectField.jsx';
import Ellipses from '../../svgs/Ellipses.jsx';
import MarketHeader from '../../components/markets/MarketHeader.jsx';
import type { Exchange } from '../../types/Exchange.js';
import type { Market } from '../../types/Market.js';
import type { Ticker } from '../../types/Ticker.js';

type Props = {
  children: any,
  tabPosition: string,
  tabNames?: Array<any>,
  activeTab?: number,
  onChangeTab?: (number) => void,
  breakAfter?: number,
  getTabKey?: (number) => any,
  useAnimation?: boolean,
  disabled?: boolean,
  optionalClass?: string, // add an optional css class to tabs-buttons
  renderAllTabs?: boolean, // set to true if you want all tabs to render upon pageload, you then have to hide/show them manually in the parent component
  showMarketHeader?: boolean, // optionally show the inline market header
  toggleFavorite?: Function, // needs this function for the favorites star to work
  exchange?: Exchange,
  market?: Market,
  isFavorite?: boolean,
  ticker?: Ticker,
  lastPrice?: number,
  lastTradeType?: string,
  marketDirection?: number,
  
};

const handleCurrLogoClick = (currCode: string) => currCode;

const Tabs = (props: Props) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { useAnimation = true, disabled, optionalClass = ``, renderAllTabs, getTabKey, tabNames, tabPosition, showMarketHeader, activeTab,
    onChangeTab, children, breakAfter: breakAfterProp, exchange, market, isFavorite, toggleFavorite, ticker, lastPrice, lastTradeType, marketDirection } = props;
  const containerProps = useAnimation ? { duration: 300, easing: `ease-in-out` } : {};
  let numberOfTabs = React.Children.count(children);

  useEffect(() => {
    if (activeTab !== undefined) {
      setActiveIndex(activeTab);
    }
  }, [activeTab]);

  const updateActiveIndex = (e?: any, activeIndex: any = 0) => {
    if (e) e.preventDefault();
    if (onChangeTab) {
      onChangeTab(activeIndex);
    }
    if (!activeTab) {
      setActiveIndex(activeIndex);
    }
    return;
  };

  if (numberOfTabs == 0) {
    return null;
  } else if (numberOfTabs == 1) {
    return children;
  } else {
    let numberOfDropdowns = 0;
    let breakAfter = breakAfterProp;
    if (typeof breakAfter !== `undefined`) {
      if (breakAfter < 0) {
        breakAfter = 0;
      }
      if (breakAfter >= 0 && numberOfTabs > breakAfter) {
        numberOfDropdowns = numberOfTabs - breakAfter;
        numberOfTabs = breakAfter;
      }
    }
    return (
      <div className="tabs-wrapper">
        <div className={ showMarketHeader ? `add-flex` : `` }>
          <div className={ `tabs-buttons ${ tabPosition } ${ optionalClass }` }>
            <div { ...containerProps }>
              {
                children.map((e, i) => (
                  <a
                    data-testid={ `tab-${ i }` }
                    key={ getTabKey ? getTabKey(i) : i }
                    onClick={ (e) => updateActiveIndex(e, i) }
                    className={ `${ activeIndex == i ? `active` : `` } ${ disabled ? `disabled` : `` }` }>
                    <span>
                      { tabNames && tabNames[i] ? tabNames[i] : i + 1 }
                    </span>
                  </a>
                ))
              }
            </div>
            {
              numberOfDropdowns > 0 ? (
                <SelectField
                  options={ `0`.repeat(numberOfDropdowns).split(``).map((e, i) => ({
                    value: numberOfTabs + i,
                    label: tabNames && tabNames[numberOfTabs + i] ?
                      tabNames[numberOfTabs + i] : (numberOfTabs + i + 1).toString()
                  })) }
                  icon={ Ellipses }
                  hideValue={ true }
                  label="TabsDropdown"
                  name="TabsDropdown"
                  value=""/>
              ) : ``
            }
          </div>
          {
            showMarketHeader && (
              <MarketHeader
                exchange={ exchange }
                market={ market }
                isFavorite={ isFavorite }
                toggleFavorite={ toggleFavorite }
                ticker={ ticker }
                lastPrice={ lastPrice }
                lastTradeType={ lastTradeType }
                marketDirection={ marketDirection }
                inlineMode={ true }
                abbreviate={ true }
                bestBid={ 0 }
                bestAsk={ 0 }
                handleCurrLogoClick={ handleCurrLogoClick }/> 
            )
          }
        </div>
        <div className="tabs-pane">
          {
            React.Children.map(children, (c, i) => (
              <TabPane
                key={ i }
                index={ i }
                activeIndex={ !renderAllTabs ? activeIndex : i }
                isVisible={ activeIndex === i  }>
                { c }
              </TabPane>
            ))
          }
        </div>
      </div>
    );
  }
};

export default Tabs;

// @flow
'use strict';

import React from 'react';
import { useLocation } from 'react-router-dom';
import SelectField from "../utilities/SelectField.jsx";
import Trash from '../../svgs/Trash.jsx';
import Edit from '../../svgs/Edit.jsx';
import Copy from '../../svgs/Copy.jsx';
import Gear from '../../svgs/Gear.jsx';
import Markets from '../../svgs/Markets.jsx';

type Props = {
  title: string,
  hasEditAction: boolean,
  showSettingsModalOpen: (open: boolean) => void,
  showMarketSelectorModalOpen: (open: boolean) => void,
};

const ACTIONS = [
  {
    label: `Edit Market`,
    icon: Markets,
    value: `editMarket`
  },
  {
    label: `Edit Settings`,
    icon: Edit(),
    value: `edit`
  },
  {
    label: `Duplicate`,
    icon: Copy,
    value: `duplicate`
  },
  {
    label: `Delete`,
    icon: Trash(),
    value: `delete`
  }
];

const DemoHeader = (props: Props) => {
  const { title, hasEditAction, showSettingsModalOpen, showMarketSelectorModalOpen } = props;
  const routerLocation = useLocation();
  
  const handleDuplicate = () => window.open(`${routerLocation.pathname}${routerLocation.search}`, `_blank`);

  const handleDelete = () => {
    window.open(`about:blank`, `_self`);
    window.close();
  };

  const invokeMethod = (e?: any, action: any = 0) => {
    switch(action) {
    case `edit`:
      showSettingsModalOpen(true);
      showMarketSelectorModalOpen(false);
      break;
    case `editMarket`:
      showSettingsModalOpen(false);
      showMarketSelectorModalOpen(true);
      break;
    case `duplicate`:
      handleDuplicate();
      break;
    case `delete`:
      handleDelete();
      break;
    }
  };

  return (
    <div className={ `demo-header` }>
      <div>
        { title }
      </div>

      <div className={ `actions` }>
        <SelectField 
          options={ hasEditAction ? ACTIONS : ACTIONS.filter((a) => a.value !== `edit`) }
          icon={ Gear }
          hideValue={ true }
          hideCaret={ true }
          label=""
          name=""
          value=""
          onChange={ invokeMethod }/>
      </div>
    </div>
  );
};

export default DemoHeader;

// @flow
'use strict';

import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ToolPanelColumnItem } from '../DataTable';

export const ToolPanelColumn = ({ table }: any) => {
  return (
    <div className='tool-panel-wrapper'>
      <div>
        <h3>
          { `Columns` }
        </h3>
        <DndProvider backend={ HTML5Backend } context={ window }>
          { table.getAllLeafColumns().map((column) => {
            if (column.columnDef.header() && (column.getCanHide() || column.getCanPin())) {
              return (<ToolPanelColumnItem key={ column.id } column={ column } table={ table }/>);
            }
          }) 
          }
        </DndProvider>
      </div>
    </div>
  );
};

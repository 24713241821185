// @flow
'use strict';

import React from 'react';
import ScreenerDataLayer from '../components/screener/ScreenerDataLayer.jsx';
import type { Market } from '../types/Market.js';
import type { Balance } from '../types/Balance.js';
import type { Account } from '../types/Account.js';

type Props = {
  markets: Array<Market>,
  accounts: Array<Account>,
  balances: Array<Balance>,
  aggregatedMarkets?: [],
  isAccountsInit?: boolean, 
  isBalancesInit?: boolean
};

const ScreenerPage = (props: Props) => {
  const { accounts, balances, isAccountsInit, isBalancesInit, aggregatedMarkets, markets } = props;

  return (
    <ScreenerDataLayer
      accounts={ accounts }
      balances={ balances }
      isAccountsInit={ isAccountsInit }
      isBalancesInit={ isBalancesInit }
      aggregatedMarkets={ aggregatedMarkets } 
      markets={ markets }/>
  );
};

export default ScreenerPage;

// @flow
'use strict';

export const colors = [
  `#EF9A9A`,
  `#F48FB1`,
  `#CE93D8`,
  `#B39DDB`,
  `#9FA8DA`,
  `#90CAF9`,
  `#81D4FA`,
  `#80DEEA`,
  `#80CBC4`,
  `#A5D6A7`,
  `#C5E1A5`,
  `#E6EE9C`,
  `#FFF59D`,
  `#FFE082`,
  `#FFCC80`,
  `#FFAB91`,
  `#BCAAA4`,
  `#EEEEEE`,
  `#B0BEC5`,
  `#F44336`,
  `#E91E63`,
  `#9C27B0`,
  `#673AB7`,
  `#3F51B5`,
  `#2196F3`,
  `#03A9F4`,
  `#00BCD4`,
  `#009688`,
  `#4CAF50`,
  `#8BC34A`,
  `#CDDC39`,
  `#FFEB3B`,
  `#FFC107`,
  `#FF9800`,
  `#FF5722`,
  `#795548`,
  `#9E9E9E`,
  `#607D8B`,
  `#C62828`,
  `#AD1457`,
  `#6A1B9A`,
  `#4527A0`,
  `#283593`,
  `#1565C0`,
  `#0277BD`,
  `#00838F`,
  `#00695C`,
  `#2E7D32`,
  `#558B2F`,
  `#9E9D24`,
  `#F9A825`,
  `#FF8F00`,
  `#EF6C00`,
  `#D84315`,
  `#4E342E`,
  `#424242`,
  `#37474F`
];

export const getColor = (index: number) => {
  if (colors[index]) return colors[index];
  return colors[index % colors.length];
};


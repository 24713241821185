// @flow
'use strict';

import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import MarketDepthChartWrapper from './MarketDepthChartWrapper.jsx';
//import EntityTable from '../utilities/EntityTable.jsx';
import Hint from '../utilities/Hint.jsx';
//import Tabs from '../utilities/Tabs.jsx';
//import Button from '../utilities/Button.jsx';
//import PillToggle from "../utilities/PillToggle.jsx";
import { getMarketPair } from '../../helpers/MarketPairHelper.js';
import { toFixedDecimalsHTML, toFixedDecimals } from '../../helpers/NumberHelper.js';
import { toLocalDate } from '../../helpers/DateHelper.js';
import type { Alert } from '../../types/Alert.js';
import type { Order } from '../../types/Order.js';
import type { Market } from "../../types/Market.js";
import type { MarketOrder } from '../../types/MarketOrder.js';
import type { Exchange } from "../../types/Exchange.js";
import type { Balance } from "../../types/Balance.js";
import type { Account } from "../../types/Account.js";
import { changeMarket } from '../../actions/markets/changeMarket.js';
//import Trash from "../../svgs/Trash.jsx";

type Props = {
  t: any,
  active: {
    market: Market,
    exchange: Exchange
  },
  showLarger: boolean,
  marketSwitcherOpen: boolean,
  marketInfoOpen: boolean,
  buys: Array<MarketOrder>,
  sells: Array<MarketOrder>,
  className: string,
  isFull: boolean,
  openOrders: Array<Order>,
  activeAlerts: Array<Alert>,
  orderHistory: Array<Order>,
  markets: Array<Market>,
  exchanges: Array<Exchange>,
  accounts: Array<Account>,
  balances: Array<Balance>,
  deleteOrder: (authId: number, orderId: number, exchCode: string) => boolean,
  currentAuthId?: number,
  //activeTab: number,
  //updateTab: (tab: number) => void,
  marketsAreClickable: boolean,
  priceTypes: *,
  statusTypes: *,
  orderTypes: *,
  showDepthOrdersAlerts: boolean,
  toggleShowDepthOrdersAlerts: () => void,
  changeMarket: (b: boolean) => void,
  lastPrice: number,
  lastTradeType: string,
  width?: number,
  height?: number,
};

type State = {
  exchFilter: string,
  mktFilter: string,
  authFilter: string | number
};

class MarketOrders extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { active: { market, exchange }, currentAuthId } = this.props;

    this.state = {
      exchFilter: exchange.exchCode,
      mktFilter: getMarketPair(market).toString(),
      authFilter: currentAuthId || -1
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { active: { market, exchange } } = nextProps;
    let { exchFilter, mktFilter, authFilter } = this.state;
    let state = { };

    if (exchFilter !== exchange.exchCode && exchFilter !== `all`) {
      state.exchFilter = nextProps.active.exchange.exchCode;
    }

    if (mktFilter !== getMarketPair(market).toString() && mktFilter !== `all`) {
      state.mktFilter = getMarketPair(nextProps.active.market).toString();
    }
    
    if (authFilter !== nextProps.currentAuthId && authFilter !== `all`) {
      state.authFilter = nextProps.currentAuthId ? nextProps.currentAuthId : ``;
    }

    if (Object.keys(state).length > 0) this.setState(state);
  }

  handleUpdateState(key: string, value: any) {
    if (!key) return;
    this.setState({
      [key]: value
    });
  }

  getFilteredData(tab: string): Array<any> {
    if (![`openOrders`, `orderHistory`, `balances`].includes(tab)) return [];
    const { exchFilter, mktFilter, authFilter } = this.state;

    return this.props[tab].filter((item) => {
      const conditions = [true, true, true];

      if ([`openOrders`, `orderHistory`].includes(tab)) {
        if (exchFilter !== `all`) {
          conditions[0] = item.exchCode === exchFilter;
        }

        if (mktFilter !== `all`) {
          conditions[1] = item.displayName === mktFilter;
        }
        
        if (!!this.props.currentAuthId && authFilter !== `all` && typeof authFilter == `number` && authFilter > -1) {
          conditions[2] = item.authNickname === this.displayAccount(null, this.props.currentAuthId);
        }
      } else {
        if (item.balanceHidden) return false;

        if (exchFilter !== `all`) {
          let account = this.props.accounts.filter((a) => a.authId == item.balanceAuthId)[0];
          if (!account) {
            conditions[0] = false;
          } else {
            conditions[0] = account.authExchId == this.props.active.exchange.exchId;
          }
        }

        if (mktFilter !== `all`) {
          conditions[1] = mktFilter.split(`/`).indexOf(item.balanceCurrCode) > -1;
        }

        if (!!this.props.currentAuthId && authFilter !== `all` && typeof authFilter == `number` && authFilter > -1) {
          conditions[2] = item.balanceAuthId === this.props.currentAuthId;
        }
      }

      return !conditions.includes(false);
    });
  }

  displayMarket(row: Order) {
    if (this.props.marketsAreClickable) {
      return (
        <Link 
          onClick={ () => this.props.changeMarket(true) }
          to={ `/markets/${ row.exchCode }/${ row.displayName }` }>
          { row.exchCode }
          { ` ` }
          { row.displayName }
        </Link>
      );
    } else {
      return (
        <a className="market-link-disabled">
          { row.exchCode }
          { ` ` }
          { row.displayName }
        </a>
      );
    }
  }

  displayPrice(row: any, price: number, key: string = ``, requireMarket: boolean = false, html: boolean = true): any {
    let market;

    if (requireMarket) {
      market = this.props.markets.find((m) => m.exchCode == row.exchCode && m.displayName == row.displayName);
    }

    let args = requireMarket ? [price, true, key, market] : [price];

    if (html) {
      return (
        <span dangerouslySetInnerHTML={
          toFixedDecimalsHTML(...args)
        } />
      );
    }

    return toFixedDecimals(...args);
  }

  displayAccount(row: ?Balance, id: number = 0) {
    let account = this.props.accounts.find((a) => a.authId == id);
    if (account) {
      return account.authNickname;
    }
    return ``;
  }
  
  getOrderColumns() {
    const columns = [
      {
        title: this.props.t(`app:account`),
        key: `authNickname`
      },
      {
        title: this.props.t(`app:market`),
        key: `market`,
        display: this.displayMarket.bind(this)
      },
      {
        title: this.props.t(`app:type`),
        key: `orderType`,
        display: (row: Order, data: number) => (
          <Hint
            position="right" 
            content={ 
              `Total: ${ this.displayPrice(row, row.limitPrice * row.quantity, `quantity`, true, false) }`
            }>
            { this.props.priceTypes[data] } 
            { ` ` }
            { this.props.orderTypes[row.side] }
          </Hint>
        )
      },
      {
        title: this.props.t(`app:qty`),
        key: `quantity`,
        display: (row: any, quantity: number) => this.displayPrice(row, quantity, `quantity`, true)
      },
      {
        title: this.props.t(`app:price`),
        key: `limitPrice`,
        display: (row: any, price: number) => this.displayPrice(row, price, `price`, true)

      },
      {
        title: this.props.t(`orders:status`),
        key: `status`,
        display: (row: any, status: number) => this.props.statusTypes[status]
      },
      {
        title: this.props.t(`app:time`),
        key: `orderTime`,
        display: (row: any, date: string) => toLocalDate(date)
      }
    ];
    
    // if (this.props.activeTab === 2) {
    //   columns.push({
    //     title: ``,
    //     key: `deleteOrder`,
    //     nosort: true,
    //     display: (row: Order) => (
    //       <Button
    //         type="cancel"
    //         onClick={ (e) => {
    //           e.preventDefault();

    //           if (this.props.deleteOrder) {
    //             this.props.deleteOrder(row.authId, row.orderId, row.exchCode);
    //           }
    //           return false;
    //         } }>
    //         { Trash(`Trash${ row.orderId }`) }
    //         <span>
    //           { this.props.t(`app:cancel`) }
    //         </span>
    //       </Button>
    //     )
    //   });
    // }
    return columns;
  }
  getTabs() {
    return [
      { key: `marketDepth`, title: this.props.t(`markets:marketDepth`) },
      { key: `balances`, title: this.props.t(`balances:balances`) },
      { key: `openOrders`, title: this.props.t(`orders:openOrders`) },
      { key: `orderHistory`, title: this.props.t(`orders:orderHistory`) }
    ];
  }

  render() {
    const tabs = this.getTabs();
    return (
      <div className={ `market-orders ${ this.props.className } ` + `full` }>
        <MarketDepthChartWrapper
          marketInfoOpen={ this.props.marketInfoOpen }
          marketSwitcherOpen={ this.props.marketSwitcherOpen }
          showLarger={ this.props.showLarger }
          market={ this.props.active && this.props.active.market }
          buys={ [ ...this.props.buys ] }
          sells={ [ ...this.props.sells ] } 
          openOrders={ this.props.openOrders }
          activeAlerts={ this.props.activeAlerts }
          showDepthOrdersAlerts={ this.props.showDepthOrdersAlerts }
          toggleShowDepthOrdersAlerts={ this.props.toggleShowDepthOrdersAlerts.bind(this) }
          lastPrice={ this.props.lastPrice }
          lastTradeType={ this.props.lastTradeType }
          exchange={ this.props.active && this.props.active.exchange }
          width={ this.props.width }
          height={ this.props.height }/>
        {
          tabs.filter((t) => t.key !== `marketDepth`).map(({ key: item }) => {
            //const authName = this.displayAccount(null, this.props.currentAuthId);
            return (
              <div key={ item } />
            );}
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  marketsAreClickable: state.browser.marketsAreClickable,
  priceTypes: state.orders.priceTypes,
  statusTypes: state.orders.statusTypes,
  orderTypes: state.orders.orderTypes
});

const mapDispatchToProps = (dispatch) => ({
  changeMarket: (b) => dispatch(changeMarket(b))
});

export { MarketOrders as PureMarketOrders };
export default translate(`markets`)(connect(mapStateToProps, mapDispatchToProps)(MarketOrders));

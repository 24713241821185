// @flow
'use strict';

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Close from '../../svgs/Close.jsx';
type Props = {
  id: number,
  __html: string,
  title: string,
  type: string,
  height: number,
  pinned: boolean,
  classes?: string,
  link?: string,
  onClose?: (id: any) => void,
  expire?: number,
};

const Notification = (props: Props) => {
  const { id, __html, title, type, height, pinned, onClose, classes, link, expire } = props;

  useEffect(() => {
    if (expire && !pinned) {
      setTimeout(() => {
        onClose && onClose(id);
      }, expire);
    }
  }, []);
  
  return (
    <div
      style={ { height: `${ height }rem` } }
      className={ `global_notification ${ pinned || type === `info news`|| type === `info newmarket` ? `pinned` : `` } ${ type } ${ classes ? classes : `` }` }>
      {
        pinned || type === `info news`|| type === `info newmarket` ? (
          <div className="header">
            <span>
              { title }
            </span>
            <a
              className="close"
              onClick={ () => onClose && onClose(id) }>
              { Close(`notification${  id }`) }
            </a>
          </div>
        ) : null
      }
      {
        link ? (
          <Link
            to={ link }>
            <div className="inner" dangerouslySetInnerHTML={ { __html } } />
          </Link>
        )
          :
          (
            <div className="inner" dangerouslySetInnerHTML={ { __html } } />
          )
      }
    </div> 
  );
};

export default Notification;

// @flow
'use strict';

import React from 'react';
import BalancesDataLayer from '../components/balances/BalancesDataLayer.jsx';
import type { RouteProps } from '../types/RouteProps.js';

const BalancesPage = ({ refreshBalance, balances, exchanges, accounts, params, markets }: RouteProps) => {

  return (
    <BalancesDataLayer
      refreshBalance={ refreshBalance }
      params={ params }
      balances={ balances }
      exchanges={ exchanges }
      accounts={ accounts }
      markets={ markets }/>
  );
};

export default BalancesPage;

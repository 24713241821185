// @flow
'use strict';

import { UPDATE_NEW_ORDER_AUTO_CONFIRM } from '../index.js';

export function updateNewOrderAutoConfirm(payload: boolean) {
  return {
    type: UPDATE_NEW_ORDER_AUTO_CONFIRM,
    payload
  };
}

// @flow
'use strict';

import {
  SET_PRICE_TYPES,
  SET_ORDER_TYPES,
  SET_STATUS_TYPES
} from '../actions/index.js';

type State = {
  priceTypes: any,
  orderTypes: any,
  statusTypes: any
};

type Action = {
  type: string,
  payload: any
};

export const initialState: State = {
  priceTypes: { },
  orderTypes: { },
  statusTypes: { }
};

export default function app(state: State = initialState, action: Action) {
  switch(action.type) {
  case SET_PRICE_TYPES:
    return {
      ...state,
      priceTypes: action.payload
    };
  case SET_ORDER_TYPES:
    return {
      ...state,
      orderTypes: action.payload
    };
  case SET_STATUS_TYPES:
    return {
      ...state,
      statusTypes: action.payload
    };
  }
  return state;
}

// @flow
'use strict';

import { emitEvent, listenForEvent, UNAUTHORIZED } from '../EventHelper.js';
import store from '../StoreHelper.js';
import { updateCSRF } from '../../actions/app/updateCSRF.js';

const API_URLS = {
  v1: process.env.WWW_URL,
  v2: process.env.API_V2_URL
};

const COOKIE_DOMAIN = process.env.COOKIE_DOMAIN ? process.env.COOKIE_DOMAIN.toString() : `coinigy.com`;

type Options = {
  url: string,
  type?: string,
  contentType?: string,
  params?: {
    [key: string]: any
  },
  body?: {
    [key: string]: any
  } | string | Array<any>,
  success?: Function,
  error?: Function,
  version?: string,
  responseType?: string,
};

let charPool = `0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ`;

function generateCSRF(length: number = 32, chars: string = charPool) {
  let csrf = ``;
  for (let i = length; i > 0; --i) csrf += chars[Math.floor(Math.random() * chars.length)];
  document.cookie = `coinigy_csrf_token=${ csrf };domain=.${ COOKIE_DOMAIN };path=/`;
  return;
}

generateCSRF(); // generate new CSRF on pageload

setInterval(() => { // generate new CSRF every 5 minutes
  generateCSRF();
}, 300000);

window.API_AUTHENTICATED = true;
window.V1_CSRF_ACQUIRED = false;

let nativeSend = XMLHttpRequest.prototype.send;
// $FlowIgnore: suppressing this error
XMLHttpRequest.prototype.send = function() {
  if (window.API_AUTHENTICATED) {
    nativeSend.apply(this, arguments);
  }
};

const $ = (options: Options, isRetry: boolean = false) => {

  if (options.version == `v1` && options.type == `POST` && !window.V1_CSRF_ACQUIRED) {
    setTimeout(() => $(options), 100);
    return;
  }

  let request = new XMLHttpRequest(),
    isPrivatePath = options.url.split(`/`)[1] == `private`,
    params: { [key: string]: string } = options.params || { },
    body: { [key: string]: string } | string | Array<any> = options.body || { },
    success: Function = options.success || (() => { }),
    error: Function = options.error || (() => { }),
    version: string = options.version || `v2`,
    responseType: string = options.responseType || `blob`,
    timeout: number = 50000,
    paramString;

  if (Object.keys(params).length > 0) {
    paramString = `?${ Object.keys(params)
      .map((opt: string) => {
        return `${ opt }=${ params[opt] }`;
      }).join(`&`) }`;
  } else {
    paramString = ``;
  }

  request.timeout = timeout;

  request.responseType = responseType;

  if (isPrivatePath || version == `v1`) {
    request.withCredentials = true;
  }

  request.onreadystatechange = () => {
    if (request.readyState !== 4) {
      return;
    }

    if (request.status == 401  && !isRetry) {
      setTimeout(() => $(options, true), 1000);
      return;
    } else if (request.status == 401  && isRetry) {
      emitEvent(UNAUTHORIZED);
    }

    // $FlowIgnore: suppressing this error
    if (request.status === 200 && !request._hasError) {
      if (request.response.type === `application/pdf` && request.response instanceof Blob) {
        return success(request);
      } else if (request.responseType === `blob` && request.response instanceof Blob) {
        let reader = new FileReader();
        reader.onload = function() {
          if (version == `v2`) return success(JSON.parse(this.result));
          if (version == `v1`) return success(this.result);
        };
        reader.readAsText(request.response);
      } else if (request.responseType === `blob`){
        if (version == `v2`) return success(JSON.parse(request.response));
        if (version == `v1`) return success(request.response);
      }else{
        if (version == `v2`) return success(JSON.parse(request.responseText));
        if (version == `v1`) return success(request.responseText);
      }
    } else {
      if (request.responseType === `blob` && request.response instanceof Blob) {
        let reader = new FileReader();
        reader.onload = function() {
          if (version == `v2`) return error(this.result);
          if (version == `v1`) return error(this.result);
        };
        reader.readAsText(request.response);
      } else if (request.responseType === `blob`){
        if (version == `v2`) return success(JSON.parse(request.response));
        if (version == `v1`) return success(request.response);
      }else{
        if (version == `v2`) return error(request.responseText);
        if (version == `v1`) return error(request.responseText);
      }
    }
  };

  request.open(
    options.type || `GET`,
    `${ API_URLS[version] }${ options.url }${ paramString }`
  );

  let sendData: any;

  if (version == `v1` && options.type == `POST` && typeof body == `object` && !Array.isArray(body)) {
    // $FlowIgnore: suppressing this error
    body.csrf_coinigy = store.getState().app.csrf;

    sendData = `${ Object.keys(body)
      .map((k: string) => {
        return `${ k }=${ body[k] }`;
      }).join(`&`) }`;

    request.setRequestHeader(`Content-Type`, `application/x-www-form-urlencoded; charset=UTF-8`);
  }

  if (version == `v2`) {
    sendData = JSON.stringify(body) || undefined;
    request.setRequestHeader(`Content-Type`, options.contentType || `application/json`);
    // $FlowIgnore: suppressing this error
    request.setRequestHeader(`X-CSRF-TOKEN`, /coinigy\_csrf\_token\=([^$;]*)/gi.exec(document.cookie)[1]);
  }

  request.send(sendData);
  return request;
};

function acquireV1CSRF() {
  $({
    url: `/getjson/csrfv1`,
    version: `v1`,
    success: (token) => {
      window.V1_CSRF_ACQUIRED = true;
      store.dispatch(updateCSRF(token));
    }
  });
}

window.onload = () => {
  acquireV1CSRF();
  setInterval(() => {
    acquireV1CSRF();
  }, 300000);
};

function checkPHPConnectionStatus() {
  $({
    url: `/getjson/get_php_connection_status`,
    version: `v1`,
    success: (data) => {
      data = JSON.parse(data);

      if (data.session_expired || data.maint_mode) {
        emitEvent(UNAUTHORIZED);
      }

      let gitTag = process.env.GIT_TAG;
      let v2Version = data.v2_frontend_version;

      if (gitTag && gitTag.indexOf(`v`) == 0) {
        gitTag = gitTag.replace(/[^0-9]+/g,``);
        v2Version = v2Version.replace(/[^0-9]+/g,``);
        
        if (parseFloat(v2Version) > parseFloat(gitTag)) {
          let doReload = confirm(`A new version of Coinigy is available. The window will be reloaded.`);
          if (doReload == true) {
            location.reload(true);
          }
        }
      }
    }
  });
}

checkPHPConnectionStatus();

setInterval(() => {
  checkPHPConnectionStatus();
}, 55000); // call every 55 seconds

listenForEvent(UNAUTHORIZED, () => {
  
  const getPid = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(`pid`) || `0`;
  };

  if (window.location.pathname.indexOf(`/demo/`) < 0) {
    window.location.href = `${ window.WWW_URL }/main/beta_error?pid=${ getPid() }&fwd=${ window.location.href }`;
  }
});

export { $ };

// @flow
'use strict';

type SELECTOR = {
  name: string,
  friendlyName?: string,
  notFriendly?: boolean,
  showIfEnabled?: string,
  darkValue?: string,
  lightValue?: string,
  variants?: {
    alpha?: Array<number>,
    altAdjust?: Array<number>,
    adjust?: Array<number>
  }
};

type FOOTPRINT = {
  [string]: SELECTOR
};

const THEME_FOOTPRINT: FOOTPRINT = {
  appScale: {
    name: `app-scale`,
    friendlyName: `App Scale`,
    notFriendly: true
  },
  transparency: {
    name: `transparency`,
    friendlyName: `Header/Footer Transparency`,
    notFriendly: true
  },
  leftSidebarTransparency: {
    name: `left-sidebar-transparency`,
    friendlyName: `Left Sidebar Transparency`,
    notFriendly: true
  },
  rightSidebarTransparency: {
    name: `right-sidebar-transparency`,
    friendlyName: `Right Sidebar Transparency`,
    notFriendly: true
  },
  tabTransparency: {
    name: `tab-transparency`,
    friendlyName: `Tab Transparency`,
    notFriendly: true
  },
  tableTransparency: {
    name: `table-transparency`,
    friendlyName: `Table Transparency`,
    notFriendly: true
  },
  fontStack: {
    name: `font-stack`,
    friendlyName: `Font Stack`,
    notFriendly: true
  },
  button: {
    name: `button-text-color`,
    friendlyName: `Button Text Color`,
    variants: {
      altAdjust: [100]
    }
  },
  body: {
    name: `body-text-color`,
    friendlyName: `Body Text Color`,
    variants: {
      alpha: [3, 8, 10, 20, 38, 48, 54, 70],
      altAdjust: [40, 100]
    }
  },
  borders: {
    name: `border-color`,
    friendlyName: `Border Color`,
    showIfEnabled: `bordersNoShadows`
  },
  highlight: {
    name: `highlight-color`,
    friendlyName: `Highlight`
  },
  brand: {
    name: `brand-blue-`,
    friendlyName: `Brand Blue`,
    variants: {
      alpha: [38],
      adjust: [10],
      altAdjust: [10]
    }
  },
  gray1: {
    name: `gray-1`,
    friendlyName: `Gray 1`,
    variants: {
      alpha: [10, 20, 30, 40, 50, 60, 70, 80],
      adjust: [5, 30],
      altAdjust: [5, 10]
    }
  },
  gray2: {
    name: `gray-2`,
    friendlyName: `Gray 2`,
    variants: {
      alpha: [0, 5, 10, 20, 30, 40, 50, 60, 70, 80],
      adjust: [5, 30],
      altAdjust: [5, 10]
    }
  },
  gray3: {
    name: `gray-3`,
    friendlyName: `Gray 3`,
    variants: {
      alpha: [10, 20, 30, 40, 50, 60, 70, 80],
      adjust: [5, 30],
      altAdjust: [5, 10]
    }
  },
  lightBlue: {
    name: `light-blue-`,
    friendlyName: `Light Blue`,
    variants: {
      alpha: [50],
      altAdjust: [10]
    }
  },
  red: {
    name: `red-`,
    friendlyName: `Red`,
    variants: {
      alpha: [40, 50, 60],
      adjust: [10],
      altAdjust: [10]
    }
  },
  green: {
    name: `green-`,
    friendlyName: `Green`,
    variants: {
      alpha: [40, 50, 60],
      adjust: [10],
      altAdjust: [10]
    }
  },
  yellow: {
    name: `yellow-`,
    friendlyName: `Yellow`
  },
  invertFilter: {
    name: `invert-filter`,
    notFriendly: true,
    lightValue: `brightness(0)`,
    darkValue: `grayscale(100%)`
  },
  greenOverride: {
    name: `green-override`,
    notFriendly: true,
    lightValue: `#66A57C`,
    darkValue: `#66A57C`
  },
  redOverride: {
    name: `red-override`,
    notFriendly: true,
    lightValue: `#C35A54`,
    darkValue: `#C35A54`
  },
  lightBlueOverride: {
    name: `light-blue-override`,
    notFriendly: true,
    lightValue: `#2986b5`,
    darkValue: `#2986b5`
  },
  boxShadow: {
    name: `box-shadow`,
    notFriendly: true,
    lightValue: `0 0.3rem 0.6rem rgba(0,0,0,0.16), 0 0.3rem 0.6rem rgba(0,0,0,0.23)`,
    darkValue: `0 0.3rem 0.6rem rgba(0,0,0,0.16), 0 0.3rem 0.6rem rgba(0,0,0,0.23)`
  }
};

export { THEME_FOOTPRINT };

// @flow
'use strict';

import React from 'react';
import Button from '../utilities/Button.jsx';
import { getComponentFromPanelTypeId } from '../../helpers/PanelTypeHelper.js';
import type { Panel as PanelType } from '../../types/Panel.js';

const InvalidMarketPanelHelper = (panel: PanelType, editMarketFn: (p: any) => void) => {
  let Component = getComponentFromPanelTypeId(panel.typeId);

  let ret = () => (
    <div className="panel-invalid-market">
      <p>
        The market selected for this panel is dead or invalid. Please select a new market.
      </p>
      <Button 
        type="primary"
        onClick={ () => {
          editMarketFn(panel);
          return true;
        } }>
        Select new market
      </Button>
    </div>
  );

  // $FlowIgnore: suppressing this error
  ret.getPanelTitle = Component.getPanelTitle;

  return ret;
};

export default InvalidMarketPanelHelper;

// @flow
'use strict';

import React, { useState, useEffect, useRef } from 'react';

type Props = {
  children: any,
  tbody?: boolean,
  horizontal?: boolean,
  height?: number,
  width?: number
};

const ScrollableArea = (props: Props) => {
  let { children, tbody, horizontal, height, width } = props;

  const [scrollBarSize, setScrollBarSize] = useState(0);
  const [scrollBarTimeout, setScrollBarTimeout] = useState(null);

  const scrollBarRef = useRef();

  const getScrollbarSize = () => {
    if (scrollBarRef) return 0;

    if (horizontal) {
      return scrollBarRef.offsetHeight - scrollBarRef.clientHeight;
    }

    return scrollBarRef.offsetWidth - scrollBarRef.clientWidth;
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (getScrollbarSize() > scrollBarSize) {
        setScrollBarSize(getScrollbarSize());
      }
    }, 100);
    setScrollBarTimeout(timeout);

    return () => {
      clearTimeout(scrollBarTimeout);
    };
  }, []);

  useEffect (() => {
    clearTimeout(scrollBarTimeout);
    const timeout = setTimeout(() => {
      if (getScrollbarSize() != scrollBarSize) {
        setScrollBarSize(getScrollbarSize());
      }
    }, 100);
    setScrollBarTimeout(timeout);
  }, [scrollBarSize]);

  
  if (tbody) {
    return (
      <tbody 
        className="scrollbar-wrapper" 
        ref={ scrollBarRef }
        style={ {
          paddingRight: `calc(3rem - ${ scrollBarSize }px)`,
          height: height,
          width: width
        } }>
        { children }
      </tbody>
    );
  }

  return (
    <div
      className={ `scrollbar-wrapper ${ horizontal ? `horizontal` : `` }` } style={ {
        width: width,
        height: height
      } }>
      <div 
        className="scrollbar-wrapper__inner" 
        ref={ scrollBarRef }
        style={ horizontal ? {
          paddingBottom: `calc(3rem - ${ scrollBarSize }px)`
        } : {
          paddingRight: `calc(3rem - ${ scrollBarSize }px)`
        } }>
        { children }
      </div>
    </div>
  );
};

export default ScrollableArea;

// @flow
'use strict';

import { SET_MARKET_SWITCHER_FILTERS } from '../index.js';

export function setMarketSwitcherFilters(filters: any) {
  return {
    type: SET_MARKET_SWITCHER_FILTERS,
    payload: filters
  };
}

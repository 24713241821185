// @flow
'use strict';

import {
  UPDATE_USER_THEME,
  REWRITE_USER_THEME,
  RESET_TO_THEME
} from '../actions/index.js';

import THEMES from '../constants/themes.js';

import type { Theme } from '../types/Theme.js';

type Action = {
  type: string,
  payload: any
};

export const initialState = THEMES[`Dark`];

export default function theme(state: Theme = initialState, action: Action) {
  switch(action.type) {
  case UPDATE_USER_THEME:
    return {
      ...state,
      [action.payload.key]: action.payload.color
    };
  case REWRITE_USER_THEME:
    return action.payload;
  case RESET_TO_THEME:
    return THEMES[action.payload];
  }
  return state;
}



// @flow
'use strict';

import React, { useState, useEffect } from 'react';
import CollapseDown from '../../svgs/CollapseDown.jsx';
import CollapseUp from '../../svgs/CollapseUp.jsx';

type Props = {
  isOpen?: boolean,
  children: any,
  collapsibleData: any,
  even: boolean
};

const CollapsibleRow = (props: Props) => {
  const { isOpen, children, collapsibleData, even } = props;

  const [open, setOpen] = useState(isOpen);
  const [rowData, setRowData] = useState(children);

  useEffect(() => {
    setRowData(children);
  }, [children]);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const toggleOpen = (e: any) => {
    e.preventDefault();
    setOpen(!open);
  };

  return (
    <>
      <tr className={ `collapsible-row ${even ? `even` : ``}` } onClick={ (e) => toggleOpen(e) }>
        { rowData }
        <td className={ `align-right action-col` }> 
          { open ? <CollapseUp /> : <CollapseDown/> }
        </td>
      </tr>
      {
        open && (
          <tr className={ `collapsible-body open` }>
            <td className={ `collapsible-container` } colSpan={ `100%` }>
              { collapsibleData }
            </td>
          </tr>
        )
      }
    </>
  );
};

export default CollapsibleRow;

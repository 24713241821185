// @flow
'use strict';

import React from 'react';
import OrdersDataLayer from '../components/orders/OrdersDataLayer.jsx';
import type { RouteProps } from '../types/RouteProps.js';

const OrdersPage = ({ markets, exchanges, balances, accounts }: RouteProps) => {

  return (
    <OrdersDataLayer
      markets={ markets }
      exchanges={ exchanges }
      balances={ balances }
      accounts={ accounts } />
  );
};

export default OrdersPage;

// @flow
'use strict';

import React from 'react';
import Button from '../utilities/Button.jsx';

type Props = {
  t: any,
  onChange: (k: string, v: any) => void,
  currentSubscription?: {
    prefUserId: string,
    prefSubscriptionId: number,
    subscriptionPaid: boolean,
    prefSubscriptionExpires: string,
    prefPromoId: number,
    subscriptionPaymentType: string,
    prefLifetime: boolean,
    prefServiceTier: number,
    serviceName: string,
    serviceHash: string,
    daysLeft: number,
    subscriptionStatus: string,
    referralBalance: string
  },
  subscriptionTypes?: Array<{
    serviceName: string,
    serviceDescription: string,
    serviceHash: string,
    serviceDurationMonths: number,
    serviceOrder: number,
    servicePriceBtc: number,
    servicePriceUser: number,
    serviceFullPriceBtc: number,
    serviceFullPriceUsd: number,
    serviceRecurring: boolean,
    isDefault: boolean
  }>,
  cancelSubscription?: () => void
};

const ChoosePlan = ({ t, onChange, currentSubscription, subscriptionTypes, cancelSubscription }: Props) => (
  <section>
    <div>
      <div className="top">
        {
          currentSubscription && (
            <span>
              { t(`expire1`) }
              <strong>
                {
                  Math.floor((new Date(currentSubscription.prefSubscriptionExpires) - Date.now()) / 1000 / 60 / 60 / 24)
                }
                { t(`expire2`) }
              </strong>
            </span>
          )
        }
        <h2>
          { t(`chooseAPlan`) }
        </h2>
      </div>
      <div className="plans">
        {
          subscriptionTypes && subscriptionTypes.map((s) => (
            <div key={ s.serviceHash }>
              <h2>
                { s.serviceName }
              </h2>
              <div className="price">
                <span>
                  $
                  { s.servicePriceUser.toFixed(2) }
                </span>
              </div>
              {
                currentSubscription && currentSubscription.serviceHash == s.serviceHash ? (
                  <Button
                    type="confirm"
                    disabled={ true }
                    onClick={ () => false }>
                    { t(`current`) }
                  </Button>
                ) : (
                  <Button
                    type="confirm"
                    onClick={ (e) => {
                      e.preventDefault();
                      onChange(`serviceHash`, s.serviceHash);
                      return false;
                    } }>
                    { t(`getStarted`) }
                  </Button>
                )
              }
            </div>
          ))
        }
      </div>
      <div className="plan-info">
        <p>
          { t(`switchPlans`) }
          <br />
          { t(`refundInfo`) }
        </p>
        <ul>
          {
            [
              t(`unlimitedTrading`),
              t(`mobileWebAccess`),
              t(`hiDefCharting`),
              t(`realTimeAccess`),
              t(`unlimitedAccounts`),
              t(`customerSupport`),
              t(`stability`),
              t(`security`),
              t(`advancedOrderTypes`),
              t(`monitoring`),
              t(`indicators`),
              t(`features`)
            ].map((t, i) => (
              <li key={ i }>
                { t }
              </li>
            ))
          }
        </ul>
      </div>
      <div className="cc">
        <p>
          { t(`paymentsAccepted`) }
        </p>
        <img src="/assets/img/cc.png" />
      </div>
      <div className="cancel">
        <Button
          type="cancel"
          onClick={ (e) => {
            e.preventDefault();
            cancelSubscription && cancelSubscription();
            return false;
          } }>
          { t(`cancelSubscription`) }
        </Button>
      </div>
    </div>
  </section>
);

export default ChoosePlan;

// @flow
'use strict';

import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PrimeTrustAccountModal from './PrimeTrustAccountModal.jsx';
import { getPrimeTrustContacts } from '../../helpers/api/UserApi.js';
import Button from '../utilities/Button.jsx';
import TimesCheck from '../../svgs/TimesCheck.jsx';
import Warning from '../../svgs/Warning.jsx';
import Verified from '../../svgs/Verified.jsx';
import type { User } from '../../types/User.js';
import type { UserApplication } from '../../types/UserApplication.js';
import { getUserApplication } from '../../helpers/UserApplicationsHelper';

export type PrimeTrustContactInformationProps = {
  t: any,
  user?: User,
};

const PrimeTrustContactInformation = (props: PrimeTrustContactInformationProps) => {
  const { t, user } = props;

  const [ ptContact, setPtContact ] = useState(null);
  const [ isModalOpen, setIsModalOpen ] = useState(false);

  const theme = useSelector((state) => state.redisPrefs.theme);
  const userApplications = useSelector((state) => state.userInfo.userApplications);
  const applicationsStatuses = useSelector((state) => state.userInfo.applicationsStatuses);

  const maskedSSN = ptContact?.taxIdNumber ? ptContact.taxIdNumber.replace(/\d(?=\d{4})/mg, `#`) : ``;

  const userApplication: UserApplication = useMemo(() => getUserApplication(`LSCX`, userApplications, applicationsStatuses), [userApplications, applicationsStatuses]);

  useEffect(() => {
    userApplication.isEligible && !userApplication.isAccountClosed && !userApplication.isAwaiting && getPrimeTrustContacts({}, (data) => { 
      if (data.result?.length) {
        setPtContact(data.result[0]);
      }
    });

  }, [userApplication.isEligible, userApplication.isAccountClosed, userApplication.isAwaiting]);
 
  if (!ptContact) return null;

  return (
    <>
      <div className="prime-trust-contact-info">
        <h2>
          { t(`Prime Trust Contact Information`) } 
          { ` ` }
          { userApplication.isVerified && Verified() }
          { userApplication.isDenied && TimesCheck() }
          { !userApplication.isVerified && !userApplication.isDenied && Warning }
        </h2>
        { user &&
          <div className="info">
            <div>
              <div style={ { fontWeight: `bold` } }>
                { `${ptContact.name}` }
              </div>

              <div>
                { `${ptContact.email}` }
              </div>

              { ptContact.contactType === `natural_person` &&
              <div>
                { `${ptContact.dateOfBirth}` }
              </div>
              }

              <div>
                { `${ptContact.primaryPhoneNumber?.number}` }
              </div>

              { (ptContact.primaryAddress) &&
              <div className="address">
                <span>
                  { `${t(`Address`)}` }
                </span>

                <div>
                  { `${[ptContact.primaryAddress.street1, ptContact.primaryAddress.street2].join(` `)}` }
                </div>

                <div>
                  { `${[ptContact.primaryAddress.city, ptContact.primaryAddress.region].filter((s) => s).join(`, `)} ${ptContact.primaryAddress.postalCode}` }
                </div>

                <div>
                  { `${ptContact.primaryAddress.country}` }
                </div>
              </div>
              }

              <div className="address">
                <span>
                  { `${t(`Tax`)}` }
                </span>

                <div>
                  { `${maskedSSN}` }
                </div>

                <div>
                  { `${[ptContact.taxState, ptContact.taxCountry].filter((s) => s).join(`, `)}` }
                </div>
              </div>
            </div>

            <div>
              <span className="button-container">
                <Button
                  type="primary"
                  onClick={ () => { setIsModalOpen(true); return false; } }>
                  { userApplication.isVerified ? t(`Edit Contact Information`) : t(`Edit and Re-submit`) }
                </Button> 
              </span>
            </div>
          </div>
        }
      </div>

      {
        isModalOpen && (
          <PrimeTrustAccountModal theme={ theme } user={ user } onClose={ () => setIsModalOpen(false) } /> 
        )
      }
    </>
  );
};


export default PrimeTrustContactInformation;

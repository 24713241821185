// @flow
'use strict';

import React from 'react';

class CoinigyBaseComponent<Props, State> extends React.Component<Props, State> {
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setStateSafe = (o: any, callback: any) => {
    if (this._isMounted) {
      if (callback) {
        this.setState(o, callback);
      }else {
        this.setState(o);
      }
    }else{
      window.console.warn(`setStateSafe called on an unmounted component`);
    }
  };
}

export default CoinigyBaseComponent;

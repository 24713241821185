// @flow
'use strict';

import React from 'react';
import moment from 'moment';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BalancesChart from './BalancesChart.jsx';
import GainersLosers from './GainersLosers.jsx';
import BalanceEditModal from './BalanceEditModal.jsx';
import DatePicker from "../utilities/DatePicker.jsx";
import { toLocalDate } from "../../helpers/DateHelper.js";
import { $ } from "../../helpers/api/ApiHelper.js";
import CoinigyBaseComponent from '../CoinigyBaseComponent.jsx';
import { toFixedDecimals, toFixedDecimalsHTML } from "../../helpers/NumberHelper.js";
import { getBalancesData, getOhlc, mapCurrencyHistoryData } from "../../helpers/BalancesHelper.js";
import { selectQuoteCurrencyCode } from "../../actions/app/selectQuoteCurrencyCode.js";
import type { Exchange } from "../../types/Exchange.js";
import type { Account } from "../../types/Account.js";
import type { Balance } from "../../types/Balance.js";

type OHLC = {
  open: number,
  high: number,
  low: number,
  close: number,
  timeEnd: string,
  timeStart: string,
  volume: number
};

type Props = {
  t: any,
  balances: Array<Balance>,
  accounts: Array<Account>,
  exchanges: Array<Exchange>,
  selectedQuoteCurrencyCode: string,
  selectQuoteCurrencyCode: (string) => void,
  history: { push: (any) => void },
  activeTab: string,
  activeTabName: string
};

type State = {
  startDate: any,
  endDate: any,
  hasData: boolean,
  historyData: Array<any>,
  ohlc: Array<OHLC>,
  editingBalances: Array<Balance>,
  editingDate: string,
  editingStart: number,
  isFirstDay: boolean,
  isLastDay: boolean
};

const dateFormat = `%Y%Y%Y%Y-%M%M-%D%D`;


class BalancesOverviewComponent extends CoinigyBaseComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      startDate: new Date().getTime() - 14 * 24 * 60 * 60 * 1000,
      endDate: new Date(), //toLocalDate(new Date(), dateFormat),
      historyData: [],
      ohlc: [],
      hasData: false,
      editingBalances: [],
      editingDate: ``,
      editingStart: 0,
      isFirstDay: false,
      isLastDay: false
    };
  }


  componentDidMount() {
    this._isMounted = true;
    if (!this.state.hasData) {
      this.getData(this.props, this.state);
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return nextProps.balances.length !== this.props.balances.length
      || nextState.startDate !== this.state.startDate
      || nextState.endDate !== this.state.endDate
      || nextState.hasData !== this.state.hasData
      || nextState.ohlc !== this.state.ohlc
      || nextProps.selectedQuoteCurrencyCode !== this.props.selectedQuoteCurrencyCode
      || nextProps.activeTab !== this.props.activeTab
      || nextProps.activeTabName !== this.props.activeTabName
      || nextState.historyData.length !== this.state.historyData.length
      || nextState.editingDate !== this.state.editingDate
      || JSON.stringify(nextState.editingBalances) !== JSON.stringify(this.state.editingBalances)
      || this.getBalanceTotal(this.props) !== this.getBalanceTotal(nextProps);
  }

  UNSAFE_componentWillUpdate(nextProps: Props, nextState: State) {
    if (
      this.props.balances.length !== nextProps.balances.length
      || this.state.startDate !== nextState.startDate
      || this.state.endDate !== nextState.endDate
      || this.getBalanceTotal() !== this.getBalanceTotal(nextProps)
    ) {
      this.getData(nextProps, nextState);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  formatStartDate (date: any) {
    return toLocalDate(new Date(date), dateFormat);
  }

  getBalanceTotal(props: Props = this.props): number {
    return props.balances.reduce((total, value) => total + value.quoteBalance, 0);
  }

  getData(props: Props, state: State) {
    if (props.balances.length === 0) {
      this.setState({
        hasData: true,
        historyData: [],
        ohlc: []
      });
    } else {
      Promise.all([
        getBalancesData({
          AuthIds: props.balances.map((b) => b.balanceAuthId)
            .reduce((a, b) => a.indexOf(b) == -1 ? [...a, b] : a, [])
            .join(`,`),
          StartDate: this.formatStartDate(state.startDate),
          EndDate: toLocalDate(state.endDate, dateFormat),
          QuoteCurrCode: `BTC`,
        }),
        getOhlc({
          StartDate: this.formatStartDate(state.startDate),
          EndDate: toLocalDate(state.endDate, dateFormat),
          selectedQuoteCurrencyCode: (props.selectedQuoteCurrencyCode || `btc`),
        }),
      ]).then(([historyData, ohlc]) => this.setStateSafe({
        hasData: true,
        historyData,
        ohlc
      })).catch((err) => err);
    }
  }

  parseData(props: Props = this.props, state: State = this.state) {
    let balanceHistoryData = [], total = 0;

    if (state.historyData.length > 0) {
      const mapSelectedBalances = props.balances.reduce((maps, item) => {
        maps.set(item.balanceAuthId, [...(maps.get(item.balanceAuthId) || []), item.balanceCurrCode]);

        return maps;
      }, new Map());

      const calculatedHistoryData = this.groupByBalance(state.historyData, mapSelectedBalances),
        data = this.mapBalanceHistory(
          props.selectedQuoteCurrencyCode,
          calculatedHistoryData,
          state.ohlc
        ),
        current = new Date(),
        currentDate = new Date(Date.UTC(current.getFullYear(), current.getMonth(), current.getDate(), 0, 0, 0));

      total = this.getBalanceTotal();

      if (data.has(currentDate.getTime())) {
        data.set(currentDate.getTime(), {
          ...data.get(currentDate.getTime()),
          quoteBalance: total,
        });
      }

      balanceHistoryData = Array.from<Balance>(data.values());
    }

    let then, now, profit = 0;

    if (balanceHistoryData.length > 0) {
      now = balanceHistoryData[balanceHistoryData.length - 1].quoteBalance,
      then = balanceHistoryData[0].quoteBalance;

      profit = ((now - then) / then) * 100;
    }

    return {
      balanceHistoryData,
      then,
      now,
      profit,
      currencyHistoryData: mapCurrencyHistoryData(state.historyData, state.ohlc)
    };
  }

  groupByBalance(result: Array<Balance>, filters: Map<number, Array<string>>): Array<Balance> {
    return Array.from(
      result.filter((item) => {
        const filter = filters.get(item.balanceAuthId);

        return filter && filter.includes(item.balanceCurrCode);
      }).reduce((maps, item: Balance) => {
        let current = maps.get(item.balanceDate) || { ...item };

        if (maps.has(item.balanceDate)) {
          current.quoteBalance += item.quoteBalance;
        }

        maps.set(item.balanceDate, current);

        return maps;
      }, new Map()).values()
    );
  }

  mapBalanceHistory(
    selectedQuoteCurrencyCode: string,
    balanceHistoryData: Array<any>,
    ohlc: Array<any>
  ): Map<number, Balance> {
    if (selectedQuoteCurrencyCode.toUpperCase() === `BTC` || ohlc.length === 0) {
      return balanceHistoryData.reduce((maps, item) => {
        maps.set(item.balanceDate, item);
        return maps;
      }, new Map());
    }

    return balanceHistoryData.reduce((maps, item) => {
      const time = new Date(item.balanceDate).getTime(),
        findOhlc = ohlc.filter((data) => time >= new Date(data.timeStart) && time <= new Date(data.timeEnd)),
        closePrice = findOhlc.length > 0 ? findOhlc[0].close : ohlc[0].close;

      maps.set(item.balanceDate, ({
        ...item,
        quoteBalance: closePrice * item.quoteBalance
      }));

      return maps;
    }, new Map());
  }

  handleUpdateState(key: string, value: string) {
    if (key === `startDate`) {
      this.setState({ startDate: value });
    }

    if (key === `endDate`) {
      this.setState({ endDate: value });
    }
  }

  onChangeCurrency(e: any, currency: string) {
    this.props.selectQuoteCurrencyCode(currency);
  }

  handleBalanceClick({ start, end }: { start: *, end: * }) {
    let editingBalances = this.state.historyData.filter((b) => {
      return +(new Date(start || 0)) < +(new Date(b.date)) && +(new Date(end || 9999999999999)) >= +(new Date(b.date));
    }).map((b) => ({
      ...b,
      balanceAmountAvailable: toFixedDecimals(b.balanceAmountAvailable, true, `price`),
      balanceAmountHeld: toFixedDecimals(b.balanceAmountHeld, true, `price`),
      balanceAmountTotal: toFixedDecimals(b.balanceAmountTotal, true, `price`),
      lastPrice: toFixedDecimals(b.lastPrice, true, `price`)
    }));

    let editingDate = ``;

    editingDate = moment(end + 86400000).format(`ll`);

    this.setState({
      editingStart: +(new Date(start || 0)),
      editingBalances,
      editingDate,
      isFirstDay: +(new Date(this.formatStartDate(this.state.startDate))) > +(new Date(start || 0)),
      isLastDay: +(new Date(toLocalDate(this.state.endDate))) <= +(new Date(end || 0))
    });
  }

  handleRetrievePrevDay(end: number = this.state.editingStart): Array<Balance> {
    let start = end - 86400000;

    return this.state.historyData.filter((b) => {
      return +(new Date(start || 0)) < +(new Date(b.date)) && +(new Date(end || 9999999999999)) >= +(new Date(b.date));
    }).map((b) => ({
      ...b,
      balanceAmountAvailable: toFixedDecimals(b.balanceAmountAvailable, true, `price`),
      balanceAmountHeld: toFixedDecimals(b.balanceAmountHeld, true, `price`),
      balanceAmountTotal: toFixedDecimals(b.balanceAmountTotal, true, `price`),
      lastPrice: toFixedDecimals(b.lastPrice, true, `price`)
    }));
  }

  saveEditingBalances(
    existingBalances: Array<Balance>, 
    newBalances: Array<Balance>, 
    changedCurrCodes: Array<Balance>,
    deletedBalances: Array<Balance>) {

    this.clearEditingBalances();

    Promise.all([
      Promise.all(deletedBalances.map((b) => new Promise((resolve, reject) => {
        if (!b.balanceId) return resolve();

        $({
          url: `/private/user/accounts/${ b.balanceAuthId }/balances/history/${ b.balanceId }`,
          type: `DELETE`,
          success: resolve,
          error: reject
        });
      }))),
      Promise.all(existingBalances.map((b) => new Promise((resolve, reject) => {
        $({
          url: `/private/user/accounts/${ b.balanceAuthId }/balances/history/${ b.balanceId || b.balanceCurrCode }`,
          type: `PUT`,
          body: {
            balanceDate: new Date(b.balanceDate).toISOString(),
            btcPrice: parseFloat(b.lastPrice),
            balanceAmountAvailable: parseFloat(b.balanceAmountAvailable),
            balanceAmountHeld: parseFloat(b.balanceAmountHeld)
          },
          success: resolve,
          error: reject
        });
      }))),
      Promise.all(newBalances.map((b) => new Promise((resolve, reject) => {
        $({
          url: `/private/user/accounts/${ b.balanceAuthId }/balances/history/${ b.balanceCurrCode }`,
          type: `POST`,
          body: {
            balanceDate: new Date(b.balanceDate).toISOString(),
            btcPrice: parseFloat(b.lastPrice),
            balanceAmountAvailable: parseFloat(b.balanceAmountAvailable),
            balanceAmountHeld: parseFloat(b.balanceAmountHeld)
          },
          success: resolve,
          error: reject
        });
      }))),
      Promise.all(changedCurrCodes.map((b) => new Promise((resolve, reject) => {
        if (!b.balanceId) return resolve();

        $({
          url: `/private/user/accounts/${ b.balanceAuthId }/balances/history/${ b.balanceId }`,
          type: `DELETE`,
          success: () => {
            $({
              url: `/private/user/accounts/${ b.balanceAuthId }/balances/history/${ b.balanceCurrCode }`,
              type: `POST`,
              body: {
                balanceDate: new Date(b.balanceDate).toISOString(),
                btcPrice: parseFloat(b.lastPrice),
                balanceAmountAvailable: parseFloat(b.balanceAmountAvailable),
                balanceAmountHeld: parseFloat(b.balanceAmountHeld)
              },
              success: resolve,
              error: reject
            });
          },
          error: reject
        });
      })))
    ]).then(() => {
      setTimeout(() => this.getData(this.props, this.state), 5000);
    }).catch((e) => {
      /* eslint-disable */
      console.error(e);
      /* eslint-enable */
    });
  }

  clearEditingBalances() {
    this.setState({
      editingBalances: [],
      editingStart: 0,
      isFirstDay: false,
      isLastDay: false,
      editingDate: ``
    });
  }
  
  editDay(m: number) {
    let end = +new Date(`${ this.state.editingDate } UTC`) + (m * 86400000);

    this.handleBalanceClick({
      start: end - 86400000,
      end
    });

    return true;
  }

  render () {
    const { balanceHistoryData, currencyHistoryData, then, now, profit } = this.parseData(this.props, this.state);

    return (
      <div className="overview">
        {
          this.props.activeTab && this.props.activeTab.match(/^(line-chart|bar-chart|gainers-losers)$/) && (
            <>
              <div className="chart-options">
                <div className="profit-loss">
                  <label>
                    { `Starting Balance` }
                    { ` (${this.props.selectedQuoteCurrencyCode.toUpperCase()})` }
                  </label>
                  <span dangerouslySetInnerHTML={ 
                    then ? 
                      toFixedDecimalsHTML(then) : 
                      { __html: this.props.t(`app:loading`) }
                  } />
                </div>
                <div className="profit-loss">
                  <label>
                    { `Ending Balance` }
                    { ` (${this.props.selectedQuoteCurrencyCode.toUpperCase()})` }
                  </label>
                  <span dangerouslySetInnerHTML={ 
                    now ? 
                      toFixedDecimalsHTML(now) : 
                      { __html: this.props.t(`app:loading`) }
                  } />
                </div>
                <div className="profit-loss">
                  <label>
                    { this.props.t(`equityChange`) }
                    { ` (${this.props.selectedQuoteCurrencyCode.toUpperCase()})` }
                  </label>
                  <span className={ profit && profit >= 0 ? `profit` : `loss` }>
                  
                    { profit && profit >= 0 ? `+` : `-` }
                    { profit && Math.abs(profit).toFixed(3) }
                    { ` %` }
                  
                  </span>
                </div>
              </div>
              <div className="chart-options">
                <div className="date-range">
                  <label>
                    { this.props.t(`dateRange`) }
                  </label>
                  <div className="date-range-data">
                    <div className="start">
                      <DatePicker
                        dateFormat={ `MM-dd-yyyy` }
                        min={ new Date(`1/9/2009`) }
                        value={ this.state.startDate }
                        max={ this.state.endDate }
                        onChange={ (value) => this.handleUpdateState(`startDate`, value) }/>
                    </div>
                    <span>
                –
                    </span>
                    <div className="end">
                      <DatePicker
                        dateFormat={ `MM-dd-yyyy` }
                        min={ this.state.startDate }
                        value={ this.state.endDate }
                        max={ new Date() }
                        onChange={ (value) => this.handleUpdateState(`endDate`, value) }/>
                    </div>
                  </div>
                </div>              
              </div>
            </>
          )
        }
        {
          this.props.activeTab == `gainers-losers` ? (
            <GainersLosers 
              hasData={ this.state.hasData }
              onBalanceClick={ this.handleBalanceClick.bind(this) }
              chartType={ this.props.activeTab }
              accounts={ this.props.accounts }
              exchanges={ this.props.exchanges }
              balances={ this.props.balances } 
              currencyHistoryData={ currencyHistoryData }
              balanceHistoryData={ balanceHistoryData } />
          ) : (
            <BalancesChart
              hasData={ this.state.hasData }
              onBalanceClick={ this.handleBalanceClick.bind(this) }
              chartType={ this.props.activeTab }
              accounts={ this.props.accounts }
              exchanges={ this.props.exchanges }
              balances={ this.props.balances } 
              currencyHistoryData={ currencyHistoryData }
              balanceHistoryData={ balanceHistoryData } />
          )
        }

        {
          this.state.editingDate.length > 0 && (
            <BalanceEditModal
              t={ this.props.t }
              retrievePrev={ this.handleRetrievePrevDay.bind(this) }
              isFirstDay={ this.state.isFirstDay }
              isLastDay={ this.state.isLastDay }
              accounts={ this.props.accounts }
              balances={ this.state.editingBalances }
              date={ this.state.editingDate }
              goToNextDay={ () => this.editDay(1) }
              goToPrevDay={ () => this.editDay(-1) }
              save={ this.saveEditingBalances.bind(this) }
              exit={ this.clearEditingBalances.bind(this) } />
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedQuoteCurrencyCode: state.app.selectedQuoteCurrencyCode,
});

const mapDispatchToProps = (dispatch) => ({
  selectQuoteCurrencyCode: (currency) => dispatch(selectQuoteCurrencyCode(currency)),
});

export { BalancesOverviewComponent as PureBalancesOverviewComponent };
export default withRouter(translate(`balances`)(connect(mapStateToProps, mapDispatchToProps)(BalancesOverviewComponent)));

// @flow
'use strict';

import { CHANGE_MARKET } from '../index.js';

export function changeMarket(payload: boolean) {
  return {
    type: CHANGE_MARKET,
    payload
  };
}

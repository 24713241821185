'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path fill-rule="evenodd" clip-rule="evenodd" d="M4.99264 0.109583C5.24864 -0.0365285 5.56279 -0.0365274 5.81879 0.109585L9.97802 2.48346L14.1373 0.109583C14.3935 -0.0366041 14.7078 -0.0365224 14.9638 0.109797L19.5307 2.71943C19.7904 2.86782 19.9506 3.14399 19.9506 3.44307C19.9505 3.74216 19.7902 4.01829 19.5305 4.16661L10.3913 9.38588C10.1352 9.53209 9.82101 9.53213 9.56494 9.38598L0.420258 4.16672C0.160402 4.0184 -4.96704e-07 3.74217 0 3.44297C4.96706e-07 3.14376 0.160403 2.86753 0.420261 2.71922L4.99264 0.109583ZM2.5145 3.44297L9.97789 7.70265L17.4372 3.44275L14.5501 1.79298L10.3911 4.16672C10.1351 4.31283 9.82094 4.31283 9.56494 4.16671L5.40571 1.79284L2.5145 3.44297Z" fill="#3B96FF"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M19.6685 2.8182C19.6279 2.78239 19.5835 2.75026 19.5356 2.72249C19.4052 2.64677 19.2593 2.60921 19.1135 2.60987C18.9722 2.61051 18.8309 2.64705 18.704 2.71955L9.97789 7.70288L1.24641 2.71945C0.98848 2.57224 0.671699 2.57342 0.414875 2.72255C0.158051 2.87168 0 3.14621 0 3.4432V8.66246C0 8.96166 0.160403 9.2379 0.420261 9.38621L4.57238 11.756V16.486C4.57238 16.7852 4.73278 17.0614 4.99263 17.2097L9.56494 19.8194C9.59535 19.8367 9.62659 19.852 9.65845 19.8653C9.89467 19.9634 10.1654 19.9482 10.3911 19.8194L14.9635 17.2097C15.2233 17.0614 15.3837 16.7852 15.3837 16.486V11.7557L19.5307 9.386C19.7904 9.23763 19.9506 8.96151 19.9506 8.66246V3.4432C19.9506 3.20149 19.8459 2.97466 19.6685 2.8182ZM10.8114 17.6605L13.7171 16.0021V11.2721C13.7171 11.0104 13.8398 10.7663 14.0444 10.61C14.0736 10.5876 14.1045 10.5671 14.137 10.5486L18.2839 8.17886V4.87875L10.8114 9.14621V17.6605ZM9.14469 9.14636V17.6605L6.23904 16.0021V11.2721C6.23904 10.9729 6.07864 10.6967 5.81878 10.5483L1.66667 8.17857V4.87833L9.14469 9.14636Z" fill="#3B96FF"/>
  <path opacity="0.1" fill-rule="evenodd" clip-rule="evenodd" d="M10.4946 19.7601C10.4638 19.7845 10.431 19.8069 10.3964 19.827C10.1395 19.9761 9.82275 19.9773 9.56483 19.8301L4.99252 17.2204C4.73267 17.0721 4.57227 16.7959 4.57227 16.4967C4.57227 16.1975 4.73267 15.9212 4.99252 15.7729L9.56483 13.1633C9.81941 13.018 10.1313 13.0173 10.3863 13.1607L10.391 13.1525L14.9634 15.7621C15.2233 15.9104 15.3837 16.1867 15.3837 16.4859C15.3837 16.7851 15.2233 17.0613 14.9634 17.2096L10.4946 19.7601ZM9.14457 17.6712L7.08675 16.4967L9.14457 15.3222V17.6712ZM10.8113 17.6604V15.3114L12.8692 16.4859L10.8113 17.6604Z" fill="#3B96FF"/>
  <path opacity="0.1" fill-rule="evenodd" clip-rule="evenodd" d="M14.9689 0.112684C15.2257 0.261813 15.3837 0.536352 15.3837 0.833333V6.04177C15.3837 6.34098 15.2233 6.61722 14.9635 6.76553L10.4954 9.31557C10.4627 9.34142 10.4279 9.365 10.3911 9.38601L5.81879 11.9956C5.56279 12.1418 5.24864 12.1418 4.99264 11.9956L0.420261 9.38601C0.160403 9.2377 0 8.96146 0 8.66226C0 8.36306 0.160403 8.08682 0.420261 7.93851L4.57235 5.56875V0.833333C4.57235 0.536351 4.7304 0.261812 4.98722 0.112682C5.24405 -0.0364469 5.56083 -0.0376268 5.81876 0.109585L9.97801 2.48347L14.1373 0.109582C14.3953 -0.0376274 14.712 -0.0364458 14.9689 0.112684ZM6.23901 5.56871L9.14465 7.2271V3.92685L6.23901 2.26847V5.56871ZM5.40571 7.01214L2.5145 8.66226L5.40571 10.3124L8.29687 8.66226L5.40571 7.01214ZM10.8114 3.92686V7.21623L13.7171 5.55787V2.26846L10.8114 3.92686Z" fill="#3B96FF"/>`,
  {
    width: `20px`,
    height: `20px`,
    viewBox: `0 0 20 20`
  }
);
/* eslint-enable */

// @flow
'use strict';

import { TOGGLE_BOARD_LOCKED } from '../index.js';

export function toggleBoardLocked() {
  return {
    type: TOGGLE_BOARD_LOCKED
  };
}

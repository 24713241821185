'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default (uniqueID) => SvgHelper(
  `<defs><path d="M6.539 5.802a.667.667 0 0 1-.502.364l-3.27.478L5.132 8.95a.667.667 0 0 1 .191.59l-.558 3.255 2.924-1.537a.667.667 0 0 1 .621 0l2.924 1.537-.558-3.255a.667.667 0 0 1 .192-.59l2.366-2.305-3.27-.478a.667.667 0 0 1-.502-.364L8 2.84 6.539 5.802zm-1.041-.904l1.905-3.86a.667.667 0 0 1 1.196 0l1.905 3.86 4.26.622c.546.08.764.752.369 1.138L12.05 9.66l.728 4.24a.667.667 0 0 1-.968.703L8 12.6l-3.809 2.003a.667.667 0 0 1-.967-.702L3.95 9.66.869 6.658a.667.667 0 0 1 .369-1.138l4.26-.622z" id="${ uniqueID }a"/></defs><g fill="none" fill-rule="evenodd"><mask id="${ uniqueID }b" fill="#fff"><use xlink:href="#${ uniqueID }a"/></mask><use fill="#000" fill-rule="nonzero" xlink:href="#${ uniqueID }a"/><g mask="url(#${ uniqueID }b)" fill="#FFF"><path d="M0 0h16v16H0z"/></g></g>`,
  {
    width: `1.6rem`,
    height: `1.5rem`,
    viewBox: `0 0 16 15`
  }
);
/* eslint-enable */

// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import Button from '../utilities/Button.jsx';

const UpgradeNow = ({ t }) => {

  return (
    <section className="upgrade-now fade-in">
      <div className="upgrade-left">

        <div className="title">
          { t(`user:getMoreWithProTrader`) }
        </div>

        <div className="subtitle">
          { t(`user:upgradeToTakeAdvantage`) }
        </div>

        <div className="features">
          <ul>
            <li>
              { t(`user:unlimitedAccounts`) }
            </li>

            <li>
              { t(`user:unlimitedWallets`) }
            </li>

            <li>
              { t(`user:unlimitedSession`) }
            </li>

            <li>
              { `100 ${ t(`user:priceVolumeAlerts`) }` }
            </li>

            <li>
              { `25 ${ t(`user:stopLimitOrders`) }` }
            </li>

            <li>
              { t(`user:smsTextAlerts`) }
            </li>

            <li>
              { t(`user:arbMatrixApp`) }
            </li>

            <li>
              { t(`header:screener`) }
            </li>

            <li>
              { t(`header:returnsRadar`) }
            </li>

            <li>
              { t(`header:theCrypt`) }
            </li>
          </ul>
        </div>

        <Button
          type="primary"
          className="upgrade-now-button"
          onClick={ (e) => {
            e.preventDefault();
            window.location.href = `${ window.WWW_URL }/subscription`;
            return false;
          } }>
          { t(`user:upgradeNow`) }
        </Button>

      </div>
      
      <div className="testimonials">
        <div className="testimonial">
          <div className="name">
            <span>
              { `⭐️⭐️⭐️⭐️⭐️` }
            </span>
            { `Joel B.` }
          </div>

          <div className="title">
            { `Look no further.` }
          </div> 

          <div className="review">
            { `You found it. If you're a technical analyst and a discretional retail crypto trader. This is it.` }
          </div>
        </div>

        <div className="testimonial">
          <div className="name">
            <span>
              { `⭐️⭐️⭐️⭐️⭐️` }
            </span> 
            { `Abdul Y.` }
          </div>

          <div className="title">
            { `Great way to analyze the market.` }
          </div> 

          <div className="review">
            { `You get, with this app, the best bird's eye view of what's happening.` }
          </div>
        </div>

        <div className="testimonial">
          <div className="name">
            <span>
              { `⭐️⭐️⭐️⭐️⭐️` }
            </span>
            { `Brandon T.` }
          </div>

          <div className="title">
            { `Great customer service.` }
          </div> 

          <div className="review">
            { `This is the first thing I can say “great customer service” to in the crypto world. The reason I feel comfortable spending money on it.` }
          </div>
        </div>
      </div>
    </section>
  );

};

export default translate(`user`)(UpgradeNow);

export const CHART_COLORS = [
  `#30b0f6`,
  `#43a047`,
  `#1e88e5`,
  `#e65100`,
  `#9c27b0`,
  `#e91e63`,
  `#ffc107`,
  `#F9CDAD`,
  `#C8C8A9`,
  `#83AF9B`,
  `#ECD078`,
  `#D95B43`,
  `#C02942`,
  `#4ECDC4`,
  `#C7F464`,
  `#FF6B6B`,
  `#C44D58`,
  `#E08E79`,
  `#F1D4AF`,
  `#ECE5CE`,
  `#C5E0DC`,
  `#E8DDCB`,
  `#CDB380`,
  `#BD1550`,
  `#E97F02`,
  `#F8CA00`,
  `#8A9B0F`,
  `#547980`,
  `#45ADA8`,
  `#9DE0AD`,
  `#E5FCC2`,
  `#00A0B0`,
  `#CC333F`,
  `#EB6841`,
  `#EDC951`,
  `#E94E77`,
  `#D68189`,
  `#C6A49A`,
  `#C6E5D9`,
  `#F4EAD5`,
  `#D9CEB2`,
  `#CC2A41`
];
export const CHART_TYPE_PIE = `pie`;
export const CHART_TYPE_STACKED_BAR = `stacked`;
export const CHART_TYPE_LINE = `line`;
export const CURRENCY_MAP = new Map([
  [`USD`, { exchange: `BITS`, market: `BTC/USD` }],
  [`EUR`, { exchange: `KRKN`, market: `BTC/EUR` }],
  [`ARS`, { exchange: `RPIO`, market: `BTC/ARS` }],
  [`AUD`, { exchange: `BTCM`, market: `BTC/AUD` }],
  [`BRL`, { exchange: `MERC`, market: `BTC/BRL` }],
  [`CAD`, { exchange: `KRKN`, market: `BTC/CAD` }],
  [`CHF`, { exchange: `KRKN`, market: `BTC/CHF` }],
  [`CNH`, { exchange: `BITF`, market: `BTC/CNHT` }],
  [`CZK`, { exchange: `MATE`, market: `BTC/CZK` }],
  [`DAI`, { exchange: `KRKN`, market: `BTC/DAI` }],
  [`ETH`, { exchange: `GDAX`, market: `ETH/BTC` }],
  [`GBP`, { exchange: `KRKN`, market: `BTC/GBP` }],
  [`IDR`, { exchange: `BIND`, market: `BTC/IDR` }],
  [`JPY`, { exchange: `KRKN`, market: `BTC/JPY` }],
  [`KRW`, { exchange: `BTHM`, market: `BTC/KRW` }],
  [`KZT`, { exchange: `EXMO`, market: `BTC/KZT` }],
  [`NGN`, { exchange: `BINA`, market: `BTC/NGN` }],
  [`PLN`, { exchange: `EXMO`, market: `BTC/PLN` }],
  [`RUB`, { exchange: `CXIO`, market: `BTC/RUB` }],
  [`SGD`, { exchange: `GMNI`, market: `BTC/SGD` }],
  [`TRY`, { exchange: `BINA`, market: `BTC/TRY` }],
  [`UAH`, { exchange: `BINA`, market: `BTC/UAH` }]
]);
export const CURRENCY_OPTIONS = [`BTC`].concat(...Array.from(CURRENCY_MAP.keys()))
  .map((item) => ({
    label: item,
    value: item.toLowerCase(),
  }));


// @flow
'use strict';

import React from 'react';

const EmptyPanel = () => (
  <div className="panel-loading" />
);

// $FlowIgnore: suppressing this error
EmptyPanel.getPanelTitle = () => `Empty Panel`;

export default EmptyPanel;

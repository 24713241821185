// @flow
'use strict';

import React, { useEffect, useState } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { ChromePicker } from 'react-color';
import { updateRedisPrefs } from '../../actions/redisPrefs/updateRedisPrefs.js';
import { emitEvent, PUSH_REDIS_PREFS } from '../../helpers/EventHelper.js';
import Modal from "../utilities/Modal.jsx";
import Button from "../utilities/Button.jsx";

const MarketColorPicker = ({ t, marketDisplayName, onClose, mktColors, updateMktColors }) => {
  const [color, setColor] = useState(``);
  const [defaultColor, setDefaultColor] = useState(``);

  const handleColorPickerChange = (color: any) => {
    if (!color) return;

    setColor(`rgba(${ color.rgb.r }, ${ color.rgb.g }, ${ color.rgb.b }, ${ color.rgb.a })`);
  };

  const assignColor = () => {
    const idx = mktColors?.findIndex((m) => m.display_name === marketDisplayName);

    if (idx > -1) {
      mktColors[idx] = {
        display_name: marketDisplayName,
        color: color
      };
    } else {
      mktColors.push({
        display_name: marketDisplayName,
        color: color
      });
    }

    updateMktColors(mktColors);
    emitEvent(PUSH_REDIS_PREFS);
    onClose && onClose();
    return false;
  };

  const clearColor = () => {
    const mkts = mktColors?.filter((m) => m.display_name !== marketDisplayName);
    updateMktColors(mkts);
    emitEvent(PUSH_REDIS_PREFS);
    onClose && onClose();
    return false;
  };

  useEffect(() => {
    const _color = mktColors?.find((m) => m.display_name === marketDisplayName)?.color || ``;
    setColor(_color);
    setDefaultColor(_color);
  }, []);

  return (
    <div className='market-color-picker'> 
      <Modal
        title={ `Assign Color To ${ marketDisplayName }` }
        onClose={ onClose }>
        <div>
          <ChromePicker
            color={ color }
            onChangeComplete={ handleColorPickerChange } />
          <div className='button-container'>
            <Button onClick={ assignColor } disabled={ color === defaultColor }>
              { t(`markets:assignColor`) }
            </Button>
            { defaultColor && <Button type={ `cancel` } onClick={ clearColor }>
              { t(`markets:clearColor`) }
            </Button>
            }
          </div>
        </div>
      </Modal>
    </div>
  );
};


const mapStateToProps = (state) => ({
  mktColors: state.redisPrefs.mktColors
});

const mapDispatchToProps = (dispatch) => ({
  updateMktColors: (c) => dispatch(updateRedisPrefs({ key: `mktColors`, value: c })),
});

export default translate(`boards`)(connect(mapStateToProps, mapDispatchToProps)(MarketColorPicker));

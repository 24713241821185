// @flow
'use strict';

import { SET_CURRENCY_SELECTOR_FILTERS } from '../index.js';

export function setCurrencySelectorFilters(filters: any) {
  return {
    type: SET_CURRENCY_SELECTOR_FILTERS,
    payload: filters
  };
}

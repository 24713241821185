import {
  MARKET_CHART,
  MARKET_DATA,
  MARKET_DEPTH,
  MARKET_RECENT_TRADES,
  MARKET_ORDERBOOK,
  NEWS_FEED,
  PRICE_TREND,
  INSIGHTS,
  NOTES,
  ORDER_FORM,
  ORDERS,
  BALANCES,
  TRADING_VIEW_CHART,
  MARKET_SPLIT_TRADES
} from '../helpers/PanelTypeHelper.js';

export const minMaxSizes = [
  {
    panel: MARKET_CHART,
    minWidth: 2,
    maxWidth: 6,
    minHeight: 1,
    maxHeight: 2,
  },
  {
    panel: MARKET_DATA,
    minWidth: 2,
    maxWidth: 2,
    minHeight: 1,
    maxHeight: 1,
  },
  {
    panel: MARKET_DEPTH,
    minWidth: 2,
    maxWidth: 6,
    minHeight: 1,
    maxHeight: 2,
  },
  {
    panel: MARKET_RECENT_TRADES,
    minWidth: 2,
    maxWidth: 4,
    minHeight: 1,
    maxHeight: 6,
  },
  {
    panel: MARKET_ORDERBOOK,
    minWidth: 4,
    maxWidth: 6,
    minHeight: 2,
    maxHeight: 6,
  },
  {
    panel: NEWS_FEED,
    minWidth: 2,
    maxWidth: 6,
    minHeight: 2,
    maxHeight: 6,
  },   
  {
    panel: PRICE_TREND,
    minWidth: 2,
    maxWidth: 4,
    minHeight: 1,
    maxHeight: 2,
  }, 
  {
    panel: INSIGHTS,
    minWidth: 4,
    maxWidth: 6,
    minHeight: 2,
    maxHeight: 6,
  }, 
  {
    panel: NOTES,
    minWidth: 2,
    maxWidth: 6,
    minHeight: 2,
    maxHeight: 6,
  }, 
  {
    panel: ORDER_FORM,
    minWidth: 4,
    maxWidth: 4,
    minHeight: 3,
    maxHeight: 4,
  }, 
  {
    panel: ORDERS,
    minWidth: 4,
    maxWidth: 6,
    minHeight: 2,
    maxHeight: 6,
  }, 
  {
    panel: BALANCES,
    minWidth: 6,
    maxWidth: 6,
    minHeight: 3,
    maxHeight: 6,
  }, 
];

export const panelsMinSize = {
  [MARKET_ORDERBOOK]: {
    minWidth: 600,
    minHeight: 150,
  },
  [INSIGHTS]: {
    minWidth: 450,
    minHeight: 150,
  },
  [ORDER_FORM]: {
    minWidth: 440,
    minHeight: 150,
  },
  [ORDERS]: {
    minWidth: 650,
    minHeight: 150,
  },
  [BALANCES]: {
    minWidth: 700,
    minHeight: 150,
  },
  [TRADING_VIEW_CHART]: {
    minWidth: 450,
    minHeight: 150,
  },
  [MARKET_SPLIT_TRADES]: {
    minWidth: 500,
    minHeight: 150,
  }
};

// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { UserProfileLink } from '../header/optionsMenuLinks/UserProfileLink.jsx';
import { TwoFactorAuthLink } from '../header/optionsMenuLinks/TwoFactorAuthLink.jsx';
import { ActivityLogLink } from '../header/optionsMenuLinks/ActivityLogLink.jsx';
import { ActiveSessionsLink } from '../header/optionsMenuLinks/ActiveSessionsLink.jsx';
import { FavoritesLink } from '../header/optionsMenuLinks/FavoritesLink.jsx';
import { LogoutLink } from '../header/optionsMenuLinks/LogoutLink.jsx';
import { SupportCenterLink } from '../header/optionsMenuLinks/SupportCenterLink.jsx';
import { VideoTutorialsLink } from '../header/optionsMenuLinks/VideoTutorialsLink.jsx';
import { FAQsLink } from '../header/optionsMenuLinks/FAQsLink.jsx';
import { NotificationPreferencesLink } from '../header/optionsMenuLinks/NotificationPreferencesLink.jsx';
import { ColorSchemeLink } from '../header/optionsMenuLinks/ColorSchemeLink.jsx';
//import { ChartsLink } from '../header/optionsMenuLinks/ChartsLink.jsx';
import { ReportBugLink } from '../header/optionsMenuLinks/ReportBugLink.jsx';
import { KeyboardLink } from '../header/optionsMenuLinks/KeyboardLink.jsx';
import { ToggleMobileButton } from './ToggleMobileButton.jsx';
import { ToggleProfileButton } from '../header/ToggleProfileButton.jsx';
import { ToggleHelpButton } from '../header/ToggleHelpButton.jsx';
import { MenuSeparator } from '../header/MenuSeparator.jsx';
import { TourThisPageButton } from '../header/TourThisPageButton.jsx';
import MobileAppQRCode  from '../header/MobileAppQRCode.jsx';
import { ToggleSettingsButton } from '../header/ToggleSettingsButton.jsx';
import { NotificationsLink } from './optionsMenuLinks/NotificationsLink.jsx';
type LSCXUserMenuType = {
  t: any, 
  toggleHelpMenu: () => void,
  size: string,
  helpMenuOpen: () => void,
  profileMenuOpen: boolean,
  toggleProfileMenu: () => void,
  isDevelopmentOrStaging: boolean,
  closeDropdownMenus: () => void,
  triggerPageTour: () => void,
  toggleMobileMenu: () => void,
  mobileMenuOpen: boolean,
  toggleSettingsMenu: () => void,
  settingsMenuOpen: boolean,  
}

const LSCXUserMenu = (props: LSCXUserMenuType) => {
  const { toggleHelpMenu, size, helpMenuOpen, profileMenuOpen, toggleProfileMenu, isDevelopmentOrStaging,
    closeDropdownMenus, t, triggerPageTour, toggleMobileMenu, mobileMenuOpen, toggleSettingsMenu, settingsMenuOpen } = props;

  const linkProps = {
    'isDevelopmentOrStaging': isDevelopmentOrStaging,
    'closeDropdownMenus': () => closeDropdownMenus() 
  };

  return(
    <>
      <li>
        <ToggleMobileButton 
          toggleMobileMenu={ toggleMobileMenu } 
          mobileMenuOpen={ mobileMenuOpen }/>
        <ul className={ mobileMenuOpen ? `sub-nav-open` : null }>
          <li>
            <MobileAppQRCode />
          </li>
        </ul>
      </li>
      <li>
        <ToggleHelpButton 
          text={ t(`help`) } 
          toggleHelpMenu={ toggleHelpMenu } 
          helpMenuOpen={ helpMenuOpen } 
          size={ size } />
        <ul
          className={ helpMenuOpen ? `sub-nav-open` : null }
          onClick={ () => toggleHelpMenu() }>
          {
            window.location.href.match(/markets/) && (
              <TourThisPageButton 
                text={ t(`tour:tourThisPage`) }
                triggerPageTour={ triggerPageTour } />
            )
          }
          <li>
            <FAQsLink text={ t(`faqs`) }/>
          </li>
          <li>
            <SupportCenterLink text={ t(`supportCenter`) } />
          </li>
          <li>
            <VideoTutorialsLink text={ t(`videoTutorials`) } />
          </li>
        </ul>
      </li>
      <li>
        <ToggleSettingsButton 
          text={ t(`settings`) } 
          settingsMenuOpen={ settingsMenuOpen } 
          toggleSettingsMenu={ toggleSettingsMenu } 
          size={ size }/>
        <ul className={ settingsMenuOpen ? `sub-nav-open` : null }>
          <li>
            <ColorSchemeLink { ...linkProps } text={ t(`settings:interface`) }/>
          </li>
          <li>
            <NotificationsLink { ...linkProps } text={ t(`footer:notifications`) } />
          </li>
          <li>
            <KeyboardLink { ...linkProps } text={ `Keyboard Shortcuts` } />
          </li>          
        </ul> 
      </li>      
      <li>
        <ToggleProfileButton 
          text={ t(`profile`) } 
          profileMenuOpen={ profileMenuOpen } 
          toggleProfileMenu={ toggleProfileMenu } 
          size={ size }/>
        <ul className={ profileMenuOpen ? `sub-nav-open` : null }>
          <li>
            <MenuSeparator text={ t(`accountSettings`) } />
          </li>
          <li>
            <UserProfileLink { ...linkProps } text={ t(`userProfile`) }/>
          </li>
          <li>
            <TwoFactorAuthLink { ...linkProps } text={ t(`twoFactorAuth`) } />
          </li>
          <li>
            <NotificationPreferencesLink { ...linkProps } text={ t(`notificationPreferences`) }/>
          </li>
          <li>
            <FavoritesLink { ...linkProps } text={ t(`favorites`) } />
          </li>          
          <li>
            <MenuSeparator text={ t(`reports`) } />
          </li>
          <li>
            <ActivityLogLink { ...linkProps } text={ t(`activityLog`) } />
          </li>
          <li>
            <ActiveSessionsLink { ...linkProps } text={ t(`activeSessions`) } />
          </li>
          { /* <li>
            <ChartsLink { ...linkProps } text={ t(`charts`) } />
          </li> */ }
          <li>
            <MenuSeparator text={ `Other` } />
          </li> 
          <li>
            <ReportBugLink { ...linkProps } text={ `Report a Bug` } />
          </li>
          <li>
            <LogoutLink text={ t(`logout`) } />
          </li>
        </ul> 
      </li>
    </>
  );
};

export { LSCXUserMenu as PureLSCXUserMenu };
export default translate(`header`)(connect()(LSCXUserMenu));

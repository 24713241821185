// @flow
'use strict';

import React, { useState, useEffect, useRef } from 'react';
import { listenForEvent, removeEventListener, ENTER } from '../../helpers/EventHelper.js';
import { formatCommaSeparator } from '../../helpers/NumberHelper';

type Props = {
  label: string,
  name: string,
  value: any,
  readOnly?: boolean,
  onChange?: (e: any) => void,
  onEnter?: () => void,
  onDoubleClick?: () => void,
  onBlur?: () => void,
  onFocus?: (e: any) => void,
  errorText?: string,
  hintText?: string,
  toolTipText?: string,
  disabled?: boolean,
  compact?: boolean,
  type?: string,
  icon?: any,
  ariaLabel?: string,
  commaSeparator?: boolean
};

const TextField = (props: Props) => {
  const { label, name, value, readOnly, onChange, onEnter, onDoubleClick, onBlur, onFocus,
    errorText, hintText, toolTipText, disabled, compact, type, icon, commaSeparator = false } = props;

  const [focused, setFocused] = useState(false);
  const [dirty, setDirty] = useState(value?.toString?.()?.length > 0);

  const inputRef = useRef();

  useEffect(() => {
    listenForEvent(ENTER, handleEnterEvent);
    return () => {
      removeEventListener(ENTER, handleEnterEvent);
    };
  }, []);

  useEffect(() => {
    setDirty(value?.toString?.()?.length > 0);
  }, [value]);

  const handleEnterEvent = () => {
    if (focused && onEnter) onEnter();
  };

  const handleInputFocus = (e) => {
    setFocused(true);
    if(onFocus) onFocus(e);
  };

  const handleInputBlur = () => {
    setFocused(false);
    if(onBlur) onBlur();
  };

  const handleInputKeyPress = (e: any) => {
    return e.key == `Enter` && onEnter ? onEnter() : true;
  };

  let _props: any = {
    id: name,
    name: name,
    value: (commaSeparator && !focused) ? formatCommaSeparator(value) : value,
    onFocus: (e) => handleInputFocus(e),
    onBlur: () => handleInputBlur(),
    placeholder: label,
    onDoubleClick: onDoubleClick,
    ref: inputRef,
    type: type || `text`
  };

  if (disabled) {
    _props.disabled = `disabled`;
  } else if (onChange) {
    _props.onChange = onChange;
  }

  if (readOnly) {
    _props.readOnly = true;
  }

  if (onEnter) {
    _props.onKeyPress = (e) => handleInputKeyPress(e);
  }

  let valid = errorText ? errorText.length == 0 : true;

  let hasHint = hintText !== undefined ? true : false;

  return (
    <label
      htmlFor={ name }
      className={ `text-field ${ focused ? `focused` : `` } ` +
          `${ dirty ? `dirty` : `` } ${ !valid ? `invalid` : `` } ` +
          `${ compact ? `compact` : `` }` +
          `${ disabled ? `disabled ` : `` }` }>
      <span className="label">
        { icon }
        <span className="label-text">
          { label }
        </span>
      </span>
      <input data-testid={ `input-text` } className={ readOnly ? `readOnly` : `` } autoComplete="off" title={ toolTipText } alt={ toolTipText } { ..._props } />
      {
        !valid ? (
          <span className="errorText">
            { errorText }
          </span>
        ) : ``
      }
      {
        hasHint ? (
          <span className={ `hintText ${ focused ? `focused` : `` }` }>
            { hintText }
          </span>
        ) : ``
      }
    </label>
  );
  
};

export default TextField;

// @flow
'use strict';

import React from 'react';
import Gear from "../../svgs/Gear.jsx";

export const ToggleSettingsButton = (props: any) => {
  const { toggleSettingsMenu } = props;

  return(
    <a className="settings-menu-link" data-testid={ `settings-menu-link` } onClick={ () => toggleSettingsMenu() }>
      { Gear }    
    </a>
  );
};

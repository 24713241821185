// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import * as alertApi from '../../helpers/api/AlertApi';
import { listenForEvent, removeEventListener, NOTIFICATION, ALERT_ADDED, ALERT_EDITED } from '../../helpers/EventHelper.js';
import { toLocalDate } from '../../helpers/DateHelper.js';
import { convertScinotToDecimal } from '../../helpers/NumberHelper.js';
import EditAlert from './EditAlert.jsx';
import DataTable, { FILTER_CONTROL_SELECT }  from '../utilities/DataTable';
import PillToggle from "../utilities/PillToggle.jsx";
import Button from '../utilities/Button.jsx';
import TextField from '../utilities/TextField.jsx';
import Modal from '../utilities/Modal.jsx';
import CoinigyBaseComponent from '../CoinigyBaseComponent.jsx';
import Trash from '../../svgs/Trash.jsx';
import Search from '../../svgs/Search.jsx';
import Edit from '../../svgs/Edit.jsx';
import type { ActiveAlert } from '../../types/ActiveAlert.js';
import type { Market } from '../../types/Market.js';
import type { Exchange } from '../../types/Exchange.js';
import { updateAlertActiveType } from '../../actions/markets/updateAlertActiveType.js';
import NewAlert from './NewAlert.jsx';

const API_POLL_MS = 90000;
const NOTIFICATION_TYPES = [3, 8, 9, 127, 138, 143, 223];

type Props = {
  t: any,
  title: string,
  markets?: Array<Market>,
  exchanges?: Array<Exchange>,
  getTickerData?: (e: Exchange, m: Market, c: (p: number) => void) => void,
  changeActiveTab: (n: any) => void,
  rehydrated: boolean,
  updateAlertType: (alertType: number) => void,
};

type State = {
  activeAlerts: Array<ActiveAlert>,
  filterText: string,
  editing?: ActiveAlert,
  editModalOpen: boolean,
  isTyping: boolean,
  apiInterval: any,
  newModalOpen: boolean,
  lastUpdateTime: any,
};

class ActiveAlertsTable extends CoinigyBaseComponent<Props, State> {
  notificationHandler: (e: any) => void;
  newAlertHandler: (e: any) => void;
  editedAlertHandler: (e: any) => void;

  constructor(props: Props) {
    super(props);

    this.notificationHandler = this.handleNotification.bind(this);
    this.newAlertHandler = this.onAlertAdded.bind(this);
    this.editedAlertHandler = this.onAlertEdited.bind(this);

    this.state = {
      activeAlerts: [],
      filterText: ``,
      editModalOpen: false,
      isTyping: false,
      apiInterval: -1,
      newModalOpen: false,
      lastUpdateTime: 0
    };
  }


  componentDidMount() {
    this._isMounted = true;

    listenForEvent(NOTIFICATION, this.notificationHandler);
    listenForEvent(ALERT_ADDED, this.newAlertHandler);
    listenForEvent(ALERT_EDITED, this.editedAlertHandler);
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.state.apiInterval);
    removeEventListener(NOTIFICATION, this.notificationHandler);
    removeEventListener(ALERT_ADDED, this.newAlertHandler);
    removeEventListener(ALERT_EDITED, this.editedAlertHandler);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {

    if (prevState.isTyping == true && this.state.isTyping == false) {
      this.setStateSafe({ lastUpdateTime: new Date().getTime() });
    }
  }

  handleNotification(e: any) {
    if (NOTIFICATION_TYPES.indexOf(e.detail.type) > -1) {
      setTimeout(() => this.setStateSafe({ lastUpdateTime: new Date().getTime() }), 500);
    }
  }

  successCallback = (data: any) => {
    return {
      result: this.mapRawAlertsToActiveAlerts(data.result),
      totalRecords: data.totalRecords,
      totalPages: data.totalPages
    };
  };

  getData(currentPage: number, pageSize: number): any {
    return new Promise((resolve) => {
      const apiEndpointFunction = alertApi.getAlerts;

      let options = {
        params: {
          PageSize: pageSize,
          PageNumber: currentPage,
          SearchTerm: this.state.filterText
        }
      };

      clearInterval(this.state.apiInterval);  

      this.setStateSafe({
        apiInterval: setInterval(() => {
          this.setStateSafe({ lastUpdateTime: new Date().getTime() });
        }, API_POLL_MS),
      }, () => {
        apiEndpointFunction(options, (data) => resolve(this.successCallback(data)));
      });
    });
  }

  handleInputChange(value: any, key: string, forceNumber?: boolean) {
    this.setStateSafe({
      [key]: forceNumber ? parseInt(value) : value
    });

    if (key == `filterText`) {
      this.setStateSafe({ alertPage: 1, isTyping: true });

      setTimeout(() => {
        this.setStateSafe({ isTyping: false });
      }, 1000);
      
    }
  }

  onAlertEditClick(e: any, alert: ActiveAlert) {
    e.preventDefault();

    this.setStateSafe({
      editModalOpen: true,
      editing: alert
    });

    return false;
  }

  cancelAlertEdit() {
    this.setStateSafe({
      editModalOpen: false,
      editing: undefined
    });
  }

  onAlertEdited(e: any) {
    let filteredAlerts = this.state.activeAlerts.filter((a) => a.alertId !== e.detail.alertId);

    this.setStateSafe({
      activeAlerts: [...filteredAlerts, ...this.mapRawAlertsToActiveAlerts([e.detail])]
    });
  }

  onAlertDeleteClick(e: any, alert: ActiveAlert) {
    e.preventDefault();
    if (!confirm(this.props.t(`deleteAlertConfirm`))) return false;

    alertApi.deleteAlert([alert.alertId], (data) => {
      if (data.success) {
        this.setStateSafe({ lastUpdateTime: new Date().getTime() });
      }
    });

    return false;
  }

  onAlertAdded() {
    this.setStateSafe({ lastUpdateTime: new Date().getTime() });
  }

  render = () => {
    let columns = [
      {
        title: this.props.t(`app:exchange`),
        key: `exchange`,
        display: (row, exchange) => {
          const exchCode = this.props.exchanges?.find( (e) => e.exchName === exchange)?.exchCode || ``;
          return (
            <span className='cell-span'>
              <img
                className={ `exch-img` }
                src={ window.WWW_URL + `/assets/img/exchange/` + exchCode + `-icon.png` } 
                onError={ (e) => {e.target.onerror = null; e.target.src=`${ window.WWW_URL }/assets/img/currency/empty.png`;} } 
                width={ `20rem` }
                height={ `20rem` }/>
              { exchange }
            </span>
          );
        }
      },
      {
        title: this.props.t(`app:market`),
        key: `market`,
        display: (row, market) => {
          const logo = market?.split(`/`);
          return (
            <span className='cell-span gap'>
              { logo?.length >= 2 && 
              <span className='currencies-section'>
                <img
                  src={ `${ window.WWW_URL }/assets/img/currency/${ logo[1] }.png` } 
                  width={ 20 }
                  height={ 20 }
                  onError={  (e)=>{e.target.onerror = null; e.target.src=`${ window.WWW_URL }/assets/img/currency/empty.png`;} } />
                <img
                  src={ `${ window.WWW_URL }/assets/img/currency/${ logo[0] }.png` }
                  className='base-curr-logo'
                  width={ 30 } 
                  height={ 30 }        
                  onError={ (e) => {e.target.onerror = null; e.target.src=`${ window.WWW_URL }/assets/img/currency/empty.png`;} } />
              </span>
              }
              { market }
            </span>
          );
        }
      },
      {
        title: this.props.t(`app:type`),
        key: `type`,
        filterControl: {
          type: FILTER_CONTROL_SELECT,
          options: [
            {
              label: `Price`,
              value: `price`
            },
            {
              label: `Volume`,
              value: `volume`
            },
          ]
        }
      },
      {
        title: this.props.t(`app:criteria`),
        key: `criteria`
      },
      {
        title: this.props.t(`note`),
        key: `note`
      },
      {
        title: this.props.t(`app:time`),
        key: `time`,
        display: (row, date) => toLocalDate(date),
        compareValue: (row) => toLocalDate(row.time),
      }
    ];

    columns.push({
      title: ``,
      key: `editAlert`,
      nosort: true,
      tdClass: `align-right`,
      display: (row) => (
        <Button type="primary" onClick={ (e) => this.onAlertEditClick(e, row) }>
          { Edit(`Edit${ row.alertId }`) }
          <span>
            { this.props.t(`app:replace`) }
          </span>
        </Button>
      )
    });

    columns.push({
      title: ``,
      key: `deleteAlert`,
      nosort: true,
      tdClass: `align-right`,
      display: (row) => (
        <Button type="cancel" onClick={ (e) => this.onAlertDeleteClick(e, row) }>
          { Trash(`Trash${ row.alertId }`) }
          <span>
            { this.props.t(`app:delete`) }
          </span>
        </Button>
      )
    });


    return (
      <>
        <div className="header">
          <div className="title-holder">
            <h1 className="page-title">
              { this.props.t(`app:alert`) }
            </h1>
            <div>
              <Button
                className="add-alert"
                type="ghost" onClick={ () => { this.setStateSafe({ newModalOpen: true }); return false; } }>
                { `+ ` + this.props.t(`newAlert`) }
              </Button>
            </div>
          </div>
          <div className="nav-holder">
            <div className="button-container">
              <PillToggle
                options={ [
                  {
                    value: 0,
                    label: this.props.t(`alerts:activeAlerts`)
                  },
                  { 
                    value: 1,
                    label: this.props.t(`alerts:alertHistory`)
                  }
                ] }
                value={ 0 }
                onChange={ (e, v) => {
                  this.setStateSafe({ lastUpdateTime: new Date().getTime() });
                  this.props.changeActiveTab(v); 
                } } />
            </div>
            <div className="search">
              <TextField
                icon={ Search }
                label={ this.props.t(`app:search`) }
                name={ `search` }
                value={ this.state.filterText }
                onChange={ (e) => this.handleInputChange(e.target.value, `filterText`) }
                onDoubleClick={ () => this.handleInputChange(``, `filterText`) } />
            </div>
          </div>
        </div>
        <div className="alerts-container">
        
          <div className="alerts-table">
            <DataTable
              id={ `active-alerts-table` }
              columns={ columns }
              defaultSortBy={ `-time` }
              filterText={ `` } 
              fetchData={ (currentPage, pageSize) => this.getData(currentPage, pageSize) }
              forceRefetch={ this.state.lastUpdateTime }
              isFullWidth/>
          </div>
          {
            this.state.editModalOpen && this.state.editing && (
              <Modal
                title={ this.props.t(`editAlert`) }
                onClose={ this.cancelAlertEdit.bind(this) }>
                <EditAlert
                  alert={ this.state.editing }
                  markets={ this.props.markets || [] }
                  exchanges={ this.props.exchanges || [] }
                  getTickerData={ this.props.getTickerData || (() => { }) }
                  onRequestClose={ this.cancelAlertEdit.bind(this) } />
              </Modal>
            )
          }
          {
            this.state.newModalOpen && (
              <Modal
                title={ this.props.t(`newAlert`) }
                onClose={ () => this.setStateSafe({ newModalOpen: false }) }>
                <NewAlert
                  markets={ this.props.markets || [] }
                  exchanges={ this.props.exchanges || [] }
                  getTickerData={ this.props.getTickerData || (() => { }) }
                  updateAlertType={ this.props.updateAlertType }
                  onRequestClose={ () => this.setStateSafe({ newModalOpen: false }) } />
              </Modal>
            )
          }
        </div>
      </>
    );
  }

  mapRawAlertsToActiveAlerts(rawAlerts: Array<any>): Array<ActiveAlert> {
    let alerts: Array<ActiveAlert> = rawAlerts.map<ActiveAlert>((raw) => {
      if(raw.type == 0){
        let direction = raw.condition === `GreaterThanOrEqual` ? `above` : `below`;
        let alert: ActiveAlert = {
          alertId: raw.alertId,
          exchMktId: raw.exchMktId,
          exchange: raw.exchangeName,
          market: raw.marketName,
          type: `PRICE`,
          note: raw.note,
          sound: raw.sound,
          time: raw.createdOn,
          criteria: `Price goes ${direction} ${convertScinotToDecimal(raw.price)}`,
          interval: ``,
          numCandles: 0,
          data: raw
        };
        return (alert: ActiveAlert);
      } else if(raw.type == 1){
        let alert: ActiveAlert = {
          alertId: raw.alertId,
          exchMktId: raw.exchMktId,
          exchange: raw.exchangeName,
          market: raw.marketName,
          type: `VOLUME`,
          note: raw.note,
          sound: raw.sound,
          time: raw.createdOn,
          criteria: `Current volume crossed ${raw.numCandles}x${raw.interval} VMA by
                    ${convertScinotToDecimal(raw.value)}${raw.condition == `PercentChange` ? `%` : ` ${raw.marketName.split(`/`)[0]}`}`,
          interval: raw.interval,
          numCandles: raw.numCandles,
          data: raw
        };
        return (alert: ActiveAlert);
      } else {
        throw new Error(`Unexpected alert type: ${raw.type}`);
      }
    });

    return alerts;
  }
}


const mapStateToProps = (state) => ({
  rehydrated: state._persist.rehydrated
});

const mapDispatchToProps = (dispatch) => ({
  updateAlertType: (alertType) => dispatch(updateAlertActiveType(alertType))
});

export { ActiveAlertsTable as PureAlertsTable };
export default translate(`alerts`)(connect(mapStateToProps, mapDispatchToProps)(ActiveAlertsTable));

// @flow
'use strict';

import { UPDATE_MARKETS_INFO_ACTIVE_TAB } from '../index.js';

export function updateMarketsInfoActiveTab(payload: number) {
  return {
    type: UPDATE_MARKETS_INFO_ACTIVE_TAB,
    payload
  };
}

'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path d="M9.808 8.703a1 1 0 0 1-.752.546l-4.906.718 3.549 3.457a1 1 0 0 1 .288.885l-.838 4.883 4.387-2.307a1 1 0 0 1 .93 0l4.387 2.307-.838-4.883a1 1 0 0 1 .288-.885l3.55-3.457-4.907-.718a1 1 0 0 1-.752-.546l-2.193-4.444-2.193 4.444zM8.247 7.346l2.857-5.789a1 1 0 0 1 1.794 0l2.857 5.79 6.39.934a1 1 0 0 1 .554 1.705l-4.623 4.503 1.09 6.362a1 1 0 0 1-1.45 1.054L12 18.9l-5.714 3.005a1 1 0 0 1-1.452-1.054l1.092-6.362-4.624-4.503a1 1 0 0 1 .553-1.705l6.39-.935z"/>`,
  {
    width: `2.4rem`,
    height: `2.4rem`,
    viewBox: `0 0 24 24`
  }
);
/* eslint-enable */

// @flow
'use strict';

import React from 'react';
import DataTable from '../utilities/DataTable';
import ProgressSpinner from '../utilities/ProgressSpinner.jsx';
import Hint from '../utilities/Hint.jsx';
import { toFixedDecimalsHTML } from '../../helpers/NumberHelper.js';

const ReturnsRadarTable = ({ activeTab, data, isLoading, onStateChangeDataTable, t }: any) => {
  const isQuarterly = activeTab === `quarterly`;

  const renderPercentChangeColumn = (percent, open, close, isAvg = false) => {

    const percentChange = (percent) => { 
      return (percent !== undefined ?
        <div
          className={ `nowrap align-center percent ${percent >= 0 ? `bg-green` : `bg-red` } ${Math.abs(percent) < 10 ? `low`: Math.abs(percent) < 20 ? `med`: Math.abs(percent) > 50 ? `high` : `` }` }
          dangerouslySetInnerHTML={
            toFixedDecimalsHTML(percent, false,`two_decimals`, undefined, false, false, `%`)
          }/>
        : <div className={ `align-center percent` }>
          { `-` }
        </div>
      );
    };

    return (
      !isAvg ?
        <Hint
          position='top'
          content={ (<div>
            <div className={ `price` }>
              <span>
                { `Opening Price: ` }
              </span>
              <span
                className='open'
                dangerouslySetInnerHTML={
                  open ?
                    toFixedDecimalsHTML(open, false, `price`) :
                    { __html: `-` }
                } />
            </div>
            <div className={ `price` }>
              <span>
                { `Closing Price: ` }
              </span>
              <span
                className='close'
                dangerouslySetInnerHTML={
                  close ?
                    toFixedDecimalsHTML(close, false, `price`) :
                    { __html: `-` }
                } />
            </div>
          </div> ) }>
          { percentChange(percent) }
        </Hint>
        : percentChange(percent));
  };

  const getMonthlyColumns = () => {
    return [
      {
        title: t(`time`),
        key: `time`,
        display: (row: any) =>  [`0-avg`, `9999-avg`].includes(row.time) ? t(`avg`) : row.time,
        enableSorting: true,
        thClass: `th-time align-center`,
        tdClass: `td-time align-center`,
      },
      {
        title: t(`january`),
        key: `january`,
        display: (row: any) => {
          const change = row?.columns?.[`1`]?.change;
          const open = row?.columns?.[`1`]?.open;
          const close = row?.columns?.[`1`]?.close;
          return renderPercentChangeColumn(change, open, close, [`0-avg`, `9999-avg`].includes(row.time));
        }
      },
      {
        title: t(`february`),
        key: `february`,
        display: (row: any) => {
          const change = row?.columns?.[`2`]?.change;
          const open = row?.columns?.[`2`]?.open;
          const close = row?.columns?.[`2`]?.close;
          return renderPercentChangeColumn(change, open, close, [`0-avg`, `9999-avg`].includes(row.time));
        }
      },
      {
        title: t(`march`),
        key: `march`,
        display: (row: any) => {
          const change = row?.columns?.[`3`]?.change;
          const open = row?.columns?.[`3`]?.open;
          const close = row?.columns?.[`3`]?.close;
          return renderPercentChangeColumn(change, open, close, [`0-avg`, `9999-avg`].includes(row.time));
        }
      },
      {
        title: t(`april`),
        key: `april`,
        display: (row: any) => {
          const change = row?.columns?.[`4`]?.change;
          const open = row?.columns?.[`4`]?.open;
          const close = row?.columns?.[`4`]?.close;
          return renderPercentChangeColumn(change, open, close, [`0-avg`, `9999-avg`].includes(row.time));
        }
      },
      {
        title: t(`may`),
        key: `may`,
        display: (row: any) => {
          const change = row?.columns?.[`5`]?.change;
          const open = row?.columns?.[`5`]?.open;
          const close = row?.columns?.[`5`]?.close;
          return renderPercentChangeColumn(change, open, close, [`0-avg`, `9999-avg`].includes(row.time));
        }
      },
      {
        title: t(`june`),
        key: `june`,
        display: (row: any) => {
          const change = row?.columns?.[`6`]?.change;
          const open = row?.columns?.[`6`]?.open;
          const close = row?.columns?.[`6`]?.close;
          return renderPercentChangeColumn(change, open, close, [`0-avg`, `9999-avg`].includes(row.time));
        }
      },
      {
        title: t(`july`),
        key: `july`,
        display: (row: any) => {
          const change = row?.columns?.[`7`]?.change;
          const open = row?.columns?.[`7`]?.open;
          const close = row?.columns?.[`7`]?.close;
          return renderPercentChangeColumn(change, open, close, [`0-avg`, `9999-avg`].includes(row.time));
        }
      },
      {
        title: t(`august`),
        key: `august`,
        display: (row: any) => {
          const change = row?.columns?.[`8`]?.change;
          const open = row?.columns?.[`8`]?.open;
          const close = row?.columns?.[`8`]?.close;
          return renderPercentChangeColumn(change, open, close, [`0-avg`, `9999-avg`].includes(row.time));
        }
      },
      {
        title: t(`september`),
        key: `september`,
        display: (row: any) => {
          const change = row?.columns?.[`9`]?.change;
          const open = row?.columns?.[`9`]?.open;
          const close = row?.columns?.[`9`]?.close;
          return renderPercentChangeColumn(change, open, close, [`0-avg`, `9999-avg`].includes(row.time));
        }
      },
      {
        title: t(`october`),
        key: `october`,
        display: (row: any) => {
          const change = row?.columns?.[`10`]?.change;
          const open = row?.columns?.[`10`]?.open;
          const close = row?.columns?.[`10`]?.close;
          return renderPercentChangeColumn(change, open, close, [`0-avg`, `9999-avg`].includes(row.time));
        }
      },
      {
        title: t(`november`),
        key: `november`,
        display: (row: any) => {           
          const change = row?.columns?.[`11`]?.change;
          const open = row?.columns?.[`11`]?.open;
          const close = row?.columns?.[`11`]?.close;
          return renderPercentChangeColumn(change, open, close, [`0-avg`, `9999-avg`].includes(row.time));
        }
      },
      {
        title: t(`december`),
        key: `december`,
        display: (row: any) => {           
          const change = row?.columns?.[`12`]?.change;
          const open = row?.columns?.[`12`]?.open;
          const close = row?.columns?.[`12`]?.close;
          return renderPercentChangeColumn(change, open, close, [`0-avg`, `9999-avg`].includes(row.time));
        }
      },
    ];
  };

  const getQuarterlyColumns = () => {
    return [
      {
        title: t(`time`),
        key: `time`,
        display: (row: any) =>  [`0-avg`, `9999-avg`].includes(row.time) ? t(`avg`) : row.time,
        enableSorting: true,
        thClass: `th-time align-center`,
        tdClass: `td-time align-center`,
      },
      {
        title: t(`Q1`),
        key: `Q1`,
        display: (row: any) => {
          const change = row?.columns?.[`3`]?.change;
          const open = row?.columns?.[`3`]?.open;
          const close = row?.columns?.[`3`]?.close;
          return renderPercentChangeColumn(change, open, close, [`0-avg`, `9999-avg`].includes(row.time));
        }
      },
      {
        title: t(`Q2`),
        key: `Q2`,
        display: (row: any) => {
          const change = row?.columns?.[`6`]?.change;
          const open = row?.columns?.[`6`]?.open;
          const close = row?.columns?.[`6`]?.close;
          return renderPercentChangeColumn(change, open, close, [`0-avg`, `9999-avg`].includes(row.time));
        }
      },
      {
        title: t(`Q3`),
        key: `Q3`,
        display: (row: any) => {
          const change = row?.columns?.[`9`]?.change;
          const open = row?.columns?.[`9`]?.open;
          const close = row?.columns?.[`9`]?.close;
          return renderPercentChangeColumn(change, open, close, [`0-avg`, `9999-avg`].includes(row.time));
        }
      },
      {
        title: t(`Q4`),
        key: `Q4`,
        display: (row: any) => {
          const change = row?.columns?.[`12`]?.change;
          const open = row?.columns?.[`12`]?.open;
          const close = row?.columns?.[`12`]?.close;
          return renderPercentChangeColumn(change, open, close, [`0-avg`, `9999-avg`].includes(row.time));
        }
      },
    ];
  };

  return (
    <div className={ `return-radar-table` }>
      { !isLoading  ?
        <DataTable
          id={ `return-radar-${activeTab}` }
          columns={ isQuarterly ? getQuarterlyColumns() : getMonthlyColumns() }
          defaultSortBy={ `-time` }
          data={ data }
          isFullWidth={ true }
          hideSideBar={ true }
          wrapperHeight={ undefined }
          serverSideSorting={ false }
          defaultColumnPinning={ { left: [`time`], right: [] } }
          onStateChange={ (state) => onStateChangeDataTable(state) }/>
        :
        <ProgressSpinner className={ `spinner` }/> }
    </div>
  );
};

export default ReturnsRadarTable;

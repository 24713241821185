'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default (uniqueId = `Close`) => {

  if (process && process.env.NODE_ENV === `test`) {
    uniqueId = '1234567'
  };

  return SvgHelper(
    `<defs><path d="M13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 1 1-1.414-1.414L10.586 12 5.293 6.707a1 1 0 0 1 1.414-1.414L12 10.586l5.293-5.293a1 1 0 0 1 1.414 1.414L13.414 12z" id="${ uniqueId }a"/></defs><g transform="translate(-5 -5)" fill="none" fill-rule="evenodd"><mask id="${ uniqueId }b" fill="#fff"><use xlink:href="#${ uniqueId }a"/></mask><use fill="#000" fill-rule="nonzero" xlink:href="#${ uniqueId }a"/><g mask="url(#${ uniqueId }b)" fill="#97CEF5"><path d="M0 0h24v24H0z"/></g></g>`,
    {
      width: `1.75rem`,
      height: `1.75rem`,
      viewBox: `0 0 14 14`
    }
  );
}
/* eslint-enable */

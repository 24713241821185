// @flow
'use strict';

import React, { useEffect, useState, useMemo } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { ChromePicker } from 'react-color';
import Modal from '../utilities/Modal.jsx';
import SelectField from '../utilities/SelectField.jsx';
import Checkbox from '../utilities/Checkbox.jsx';
import RadioButtons from '../utilities/RadioButtons.jsx';
import ScrollableArea from '../utilities/ScrollableArea.jsx';
import Button from '../utilities/Button.jsx';
import { updateUserTheme, rewriteUserTheme } from '../../actions/theme/updateUserTheme.js';
import { resetToTheme } from '../../actions/theme/resetToTheme.js';
import { updateTheme } from '../../actions/theme/updateTheme.js';
import { THEME_FOOTPRINT } from '../../constants/themeFootprint.js';
import themeColors from '../../constants/themeColors.js';
import THEMES from '../../constants/themes.js';
import communityThemes from '../../constants/communityThemes.json';
import { validateTheme, getDefaultTheme, replaceCircularStdFont } from '../../helpers/ThemeHelper.js';
import type { Theme } from '../../types/Theme.js';
import LogoMark from '../../svgs/LogoMark.jsx';
import { emitEvent, NOTIFICATION } from '../../helpers/EventHelper';
import { setPreviousTheme } from '../../actions/app/setPreviousTheme.js';
import UndoIcon from '@mui/icons-material/Undo';

type Props = {
  t: any,
  hideTitle?: boolean,
  theme: string,
  userTheme: Theme,
  updateUserTheme: (k: string, v: any) => void,
  rewriteUserTheme: (t: Theme) => void,
  resetToTheme: (k: string) => void,
  updateTheme: (K: string) => void,
  platformId: number,
  previousTheme: string,
  updatePreviousTheme: (theme: string) => void,
  rehydrated: boolean
};

/* eslint-disable */
const BACKGROUND_IMAGES = [
  `None`,
  `https://lh4.googleusercontent.com/-wkrGBuk0DoA/Us9JnUoXnTI/AAAAAAAAkTA/yDQexzLKhKY/s1920-w1920-h1080-c/DSC_0660.JPG`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/apple_texture_pack_9%5B1%5D.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/14-3%5B1%5D.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/Blue-and-Purple-Abstract-Wallpaper.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/soer6ae-imgur%5B1%5D.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/gailis_planet_rings_stars_space_94952_1920x1080.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/wrap-2560x1600%5B1%5D.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/teq-colourful-lines-abstract-wallpaper-1920x1080%5B1%5D.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/7-7%5B1%5D.jpg`,
  `https://lh6.googleusercontent.com/-El8tXEJMqm4/UOD9tk8_rkI/AAAAAAAAkM0/sZP34rwkVsQ/s1920-w1920-h1080-c/02202012-04.jpg`,
  `https://lh4.googleusercontent.com/-WbNq4f1kE7Y/Tu9tVtXGCYI/AAAAAAABFWY/N9NL1MKUt4A/s1920-w1920-h1080-c/DunesEdge.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/wallpaper-39daf.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/lOaoQVy.jpg`,
  `https://lh6.googleusercontent.com/-KiRwrTTHEWk/UdFoWqh2bFI/AAAAAAAAXhA/6PIDVYH9nFY/s1920-w1920-h1080-c/DSC_6887-Edit-Edit.JPG`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/papers.co-ak85-space-red-earch-winter-dark-art-1920x1080.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/inclined-abstract-lines_836723%5B1%5D.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/basic_orange_and_blue_background_by_berethead-d70bmh6%5B1%5D.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/seagreen_withoutlogo_1920x1200%5B1%5D.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/sYeJx4T.png`,
  `https://lh5.googleusercontent.com/-F1T9flY075Y/U0Mgnwr2AJI/AAAAAAAJBJY/eeooel6-ZXY/s1920-w1920-h1080-c/Trey%2BRatcliff%2B-%2BQueenstow,n%2BAurora%2BAustralis.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/22%20-%20tOw9nwQ.jpg`,
  `https://lh4.googleusercontent.com/-bEYj5hwZ1G8/U0MSbgTqo4I/AAAAAAAJF9c/ijCLJ4jvSuU/s1920-w1920-h1080-c/Approaching%2BYosemite.jpg`,
  `https://lh4.googleusercontent.com/-h782AeqXwL4/U0MTcwzNuyI/AAAAAAAJBlg/B0ICHy6uj-I/s1920-w1920-h1080-c/Across%2Bthe%2BAlaskan%2BFjords.jpg`,
  `https://lh3.googleusercontent.com/-JMRLCkgYWUI/UR0He_hKbyI/AAAAAAAAOyM/Fjml1QshbrU/s1920-w1920-h1080-c/ENS-%2BMacro%2BSnow-.jpg`,
  `https://lh6.googleusercontent.com/-f6OToCJWRrg/UUsOySkJXoI/AAAAAAAATgk/DWwubrzxFPY/s1920-w1920-h1080-c/MountainSplendor.jpg`,
  `https://lh5.googleusercontent.com/-X3k5JeDbKW4/UZtfuMrJ49I/AAAAAAAAVpI/Qy3yA3weGXM/s1920-w1920-h1080-c/DSC_6674-Edit-Edit.jpg`,
  `https://lh3.googleusercontent.com/-NE7B7ZtjC9M/UknSexY2-_I/AAAAAAAAN6g/od62b4KTS9U/s1920-w1920-h1080-c/5-07-13-hodgeman-ks-lightning-supercell.png`,
  `https://www.coinigy.com/assets/img/wallpapers/wall_num2.jpg`,
  `https://storage.googleapis.com/temporaryfiles/6340.jpg`,
  `https://storage.googleapis.com/temporaryfiles/moonbg.jpg`,
  `https://lh6.googleusercontent.com/-A5xnIm26hoM/UQ6xWBcP60I/AAAAAAAAFZI/xCilpfxW-3Y/s1920-w1920-h1080-c/maui-13.jpg`,
  `https://lh5.googleusercontent.com/-2oWY5eMxbnY/TwJ1W3DnAII/AAAAAAABFbs/V9UTfZKkOnM/s1920-w1920-h1080-c/Sloat-SunsetBeachFoam.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/VNC9AMZ.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/yR5y3Kd.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/W8auJrH.png`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/papers.co-si80-rainbow-blue-gradation-blur-1920x1080.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/low-polygonal-mountain.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/green-abstract-wallpaper-1.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/Aero-Abstract-Blue-Wallpaper.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/Wb6.jpg`,
  `https://lh4.googleusercontent.com/-xjlOWCsBOHg/UL2Bg7-fWJI/AAAAAAAAEQM/_Af3LToQDmg/s1920-w1920-h1080-c/IMGP8003.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/16%20-%20mcmbfcS.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/papers.co-si72-green-emerald-blue-gradation-blur-1920x1080.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/papers.co-vp65-polygon-blue-purple-art-pattern-1920x1080.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/papers.co-vp61-galaxy-samsung-7-dark-blue-pattern-1920x1080.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/24%20-%20YoL35FM.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/16x10%5B1%5D.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/aqua-red%5B1%5D.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/18%20-%204LpnxMx.png`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/3%5B1%5D.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/16%20-%208RrvVLg.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/15%20-%20tmLph1r.png`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/05%20-%208G2wDWL.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/04%20-%20WOEvSsc.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/superglass-g-1080p%5B1%5D.png`,
  `https://lh6.googleusercontent.com/-AM_AYNzr-a0/UpBNRCa9JbI/AAAAAAAAD_w/gATI2Qr7EHw/s1920-w1920-h1080-c/DSC01099%2BPlant.jpg`,
  `https://storage.googleapis.com/temporaryfiles/wallpapers/302-rainbow-dotted-pattern-1920x1080-abstract-wallpaper.jpg`,
  `https://lh6.googleusercontent.com/-Hy-GW9jdRmM/UOOY4T51IhI/AAAAAAAAKiI/dj3WLyRqMJE/s1920-w1920-h1080-c/LowerAntelope1.jpg`,
  `https://lh3.googleusercontent.com/-ree1zkofuLM/UPBTkiEo3pI/AAAAAAAAfWU/t0UKepl53qw/s1920-w1920-h1080-c/IMGP8556-Edit.jpg`,
  `https://lh6.googleusercontent.com/-DZDQ-Jc4VPA/TSjtGSyY5lI/AAAAAAAAkNQ/r8BTRHGP7qI/s1920-w1920-h1080-c/136.JPG`,
  `https://lh3.googleusercontent.com/-ggjaMcHz6Fw/U0Mgf-CsIDI/AAAAAAAJFdQ/iyuO6PIuUdg/s1920-w1920-h1080-c/Trey%2BRatcliff%2B-%2BOTW%2Bto%2BGlen.jpg`,
  `https://lh5.googleusercontent.com/-IFmqkzp2Z74/SlgjPMN0INI/AAAAAAAAJlI/cVrfkqJwBj4/s1920-w1920-h1080-c/IMG_8642.jpg`,
  `https://lh4.googleusercontent.com/-EIEk1-tv81w/TGD0hHBXZEI/AAAAAAAAav0/MfGEerqGlfk/s1920-w1920-h1080-c/IMG_1531.jpg`,
  `https://lh6.googleusercontent.com/-TiuUGmgrIJo/T2fhWrPoAtI/AAAAAAAAX-0/KhLFCt5muZ0/s1920-w1920-h1080-c/RaceTrack-tilted.JPG`,
  `https://lh6.googleusercontent.com/-s-CrcrZfoEY/TzlsdNVcPpI/AAAAAAAAS2I/vgewR4SWII0/s1920-w1920-h1080-c/GGB-SlackersRidge-Sunrise-fog-headlights-wide.jpg`,
  `https://lh6.googleusercontent.com/-sVsjQwFKpM0/UFN9mKAiexI/AAAAAAAASuM/qpOQevOJY8k/s1920-w1920-h1080-c/5-20-11-sunset-clouds365-kdelay.jpg`,
  `https://lh4.googleusercontent.com/-sVAg7ixe6zM/US-gUOy49yI/AAAAAAAAXrM/6iXuvw5XdBw/s1920-w1920-h1080-c/by%2BJoel%2B%2BTjintjelaar.jpg`,
  `https://lh5.googleusercontent.com/-V4KqnGwqPtA/Ur0CJf-GmTI/AAAAAAAAGBA/LO0uFjb-Erc/s1920-w1920-h1080-c/IMG_3857-Edit-Edit-2.jpg`,
  `https://lh5.googleusercontent.com/-qiK0GrZEcpQ/TtZtCl10xrI/AAAAAAAAHow/WGJu8GP6dmA/s1920-w1920-h1080-c/GGB_DarkMorningWindyFog.jpg`,
  `https://lh5.googleusercontent.com/-HiLp9PsqEEg/U0MiTxSu0yI/AAAAAAAJP3w/ZWMfpXVaQVI/s1920-w1920-h1080-c/Trey-ratcliff-toronto-Recovered.jpg`,
  `https://lh5.googleusercontent.com/-VGh9JQ9DCzM/Tg1cKU7qBRI/AAAAAAAAAJ4/Dyi6MrlgffU/s1920-w1920-h1080-c/070319-2657-PathToLight.jpg`,
  `https://lh6.googleusercontent.com/-UBxJsB_kCVU/UettpK1i2BI/AAAAAAAAK3s/siEVsMTSHIg/s1920-w1920-h1080-c/MWF_6244-7.jpg`,
  `https://lh5.googleusercontent.com/-sTiWMAC13YQ/UTmo6ED7v9I/AAAAAAAAWsQ/PfI4WYswhJw/s1920-w1920-h1080-c/DSC_8520.png`,
  `https://lh4.googleusercontent.com/-YOIwgQ1uXcM/SRGZJ-A3yvI/AAAAAAAADMI/6qvORkHqWS4/s1920-w1920-h1080-c/700_1870.JPG`,
  `https://lh4.googleusercontent.com/-H8NDmFzpF_A/TgtZEOagTaI/AAAAAAAIPp0/eTfdvWDBvt4/s1920-w1920-h1080-c/Farewell%2BIndia.jpg`,
  `https://lh3.googleusercontent.com/-x0qCPDjYL9w/T2Z8tuYaHII/AAAAAAABFXc/empnlAhR_eY/s1920-w1920-h1080-c/SonomaCoast-Arch-surf-longexp.jpg`,
  `https://lh3.googleusercontent.com/-bXrGY2Cafa0/TgtZGEzbZDI/AAAAAAAJA5g/JWbeuWkbTLc/s1920-w1920-h1080-c/2398605326_bf7dde0cf7_o.jpg`,
  `https://lh5.googleusercontent.com/-txUs9Q95dM8/U0MR9lvvDNI/AAAAAAAJIA8/vY9TD4TiPzw/s1920-w1920-h1080-c/A%2BView%2Bfrom%2Bthe%2BRanch%2Bin%2BArgentina.jpg`,
  `https://lh5.googleusercontent.com/-5uz5EzVcvNU/UEpN7gJg2nI/AAAAAAAAI7s/-Xwxg57SCn0/s1920-w1920-h1080-c/inverness-to-istanbul-00077.jpg`,
  `https://lh3.googleusercontent.com/-465XgZS4LQo/UOuDAiDRcrI/AAAAAAAAKng/4m7maZotsgg/s1920-w1920-h1080-c/SanGregorioCliffReflection.jpg`,
  `https://lh4.googleusercontent.com/-U0yjKIXF2fs/U0MjfTbjf1I/AAAAAAAJEbY/MkqCoe1DfG4/s1920-w1920-h1080-c/santa-cruz-trey-ratcliff.jpg`,
  `https://lh5.googleusercontent.com/-7Zi62qJ-NNg/Tg1bfusNzfI/AAAAAAAAAJA/P4bDaf-68Ys/s1920-w1920-h1080-c/061122-1421-LtAtEndOfPier.jpg`,
  `https://lh4.googleusercontent.com/-vA0vdrM3_ro/UnDyzCqXFaI/AAAAAAAAGxw/GAtvLCwZbAM/s1920-w1920-h1080-c/_SUE4322.jpg`,
  `https://lh4.googleusercontent.com/-bkNEhcz00Z8/U0MZQ3MdO3I/AAAAAAAJGy4/S-coTfmsQfE/s1920-w1920-h1080-c/The%2BBamboo%2BForest.jpg`,
  `https://lh5.googleusercontent.com/-tyOlTzJRvmw/UpBQXuuK_JI/AAAAAAAAD_0/PL7enPiYUT8/s1920-w1920-h1080-c/DSC02146%2BRed%2BPlant.jpg`,
  `https://lh5.googleusercontent.com/-YHEf8jl_LDg/Tgxgtdub2iI/AAAAAAAAAMQ/zmkWf0WkFTI/s1920-w1920-h1080-c/01_MG_3677.jpg`,
  `https://lh6.googleusercontent.com/-feW_gVKW6j8/UI5fg6xQBXI/AAAAAAAAWQY/DIOuGTieAMM/s1920-w1920-h1080-c/RedTide.jpg`,
  `https://lh4.googleusercontent.com/-lR3vG062kEk/Tg1fjlh053I/AAAAAAAAAOc/Sv1zKPXPkPQ/s1920-w1920-h1080-c/091220-2536-TheCurl.jpg`,
  `https://lh6.googleusercontent.com/-Q1AA5A9uiYU/U0MkdTp90YI/AAAAAAAJJIw/SuamKxYgwts/s1920-w1920-h1080-c/trey-ratcliff-close-to-tepako-new-zealand.jpg`,
  `https://lh3.googleusercontent.com/-Hqmu2H_l2XY/SwIysuFOIiI/AAAAAAABLWk/W9rX5qlmk4c/s1920-w1920-h1080-c/DSC_6837_CropBrightContrast.JPG`,
  `https://lh3.googleusercontent.com/-VZ8vGJ6Qfvc/Tg1bgCMJ1HI/AAAAAAAAAJE/QMa5LYy7phM/s1920-w1920-h1080-c/061121-1389-UndPierHanalei.jpg`,
  `https://lh3.googleusercontent.com/-eCSjsP08EcA/T_0K5wVePeI/AAAAAAAARxk/I-Qx3ciOzUE/s1920-w1920-h1080-c/20100924-IMG_5794-HDR-Edit.jpg`,
  `https://lh3.googleusercontent.com/-N7h4Iit041g/U0MUZ6H8WdI/AAAAAAAJKok/V-IfPQ_XXK4/s1920-w1920-h1080-c/Farewell%2BSan%2BFrancisco.jpg`,
  `https://lh3.googleusercontent.com/-GCr_cZIif7g/Tnarp7XhOMI/AAAAAAAABPU/WNrHyAJOLNA/s1920-w1920-h1080-c/stream.JPG`,
  `https://lh3.googleusercontent.com/-0DC0pkVJw5g/Tg1cjeX4YpI/AAAAAAAAAKc/bNEDQtv0J4A/s1920-w1920-h1080-c/071110-3579-HvnsGate.jpg`,
  `https://lh5.googleusercontent.com/-m-0LmCqZxSo/Tg1gLoaJJKI/AAAAAAAAAPI/KNkzyqLgUa0/s1920-w1920-h1080-c/100726-4239-LightAtAhalanui.jpg`,
  `https://lh5.googleusercontent.com/-4026KDnYCqc/ThYb2MMKRLI/AAAAAAABSEs/3N3nOtyl_lI/s1920-w1920-h1080-c/RHeaRy_380.jpg`,
  `https://lh6.googleusercontent.com/-OwU2batkg6A/TrbvnMATI7I/AAAAAAAAF3w/q_7_jgF0ngE/s1920-w1920-h1080-c/CrissyField-SaltMarsh-2.jpg`,
  `https://lh6.googleusercontent.com/-mGgW1ovI2PE/U0Mjzq2YJuI/AAAAAAAJA0o/aLuxOVgUjlY/s1920-w1920-h1080-c/tekapo-new-zealand-trey-ratcliff-2.jpg`,
  `https://lh3.googleusercontent.com/-patMHu2oyfs/RJBWFz5TABI/AAAAAAACnSo/ZP9KYfCSFM8/s1920-w1920-h1080-c/p1000284.jpg`,
  `https://lh4.googleusercontent.com/-_p1iKjPzjGc/TIW63f_px1I/AAAAAAAAOck/NCDLhcVRam8/s1920-w1920-h1080-c/IMG_2617.jpg`,
  `https://lh6.googleusercontent.com/-9Nj6ilPU0NI/Ts9Gk0pfW_I/AAAAAAAAD4I/GWbtz0c91tE/s1920-w1920-h1080-c/DSC02700.JPG`,
  `https://lh5.googleusercontent.com/-uk4W-F3OXbM/Tqwm9e_vuJI/AAAAAAABFcE/zPYFePKqTxs/s1920-w1920-h1080-c/TufaSunset-1-2.jpg`,
  `https://lh6.googleusercontent.com/-7ZuJOGpJ5-0/UHRHfwcGEUI/AAAAAAAAKsU/s31OrXjVZ9A/s1920-w1920-h1080-c/P1142336.jpg`,
  `https://lh5.googleusercontent.com/-YxXTf0j_MTQ/UtTwF4t9yEI/AAAAAAAAW8g/dYsGmWzs81Y/s1920-w1920-h1080-c/Glacier%2BPoint%2BMilky%2BWay%2BPanorama.jpg`,
  `https://lh5.googleusercontent.com/-gBb9HtLb3zw/U_yjyTFvcAI/AAAAAAAB-64/G_Jt5mnm-mU/s1920-w1920-h1080-c/_DX_6908_09_10_11_32bit-Edit.jpg`,
  `https://lh5.googleusercontent.com/-yCO0vebn5x0/UFBrOZtDNZI/AAAAAAAAJO8/jX4MgPfHQbE/s1920-w1920-h1080-c/inverness-to-istanbul-00177.jpg`,
  `https://lh5.googleusercontent.com/-59MYsAxhB_I/UtTzqg1a7VI/AAAAAAAAWds/BLWELYZHUWE/s1920-w1920-h1080-c/DSC_0554.jpg`,
  `https://lh3.googleusercontent.com/-p4V1WVO8Dhw/UBSl6P5W35I/AAAAAAAAPdg/UFIS4M1KDBE/s1920-w1920-h1080-c/Blue.jpg`,
  `https://lh5.googleusercontent.com/-5IpprOs0T78/T3PwhE-VJ9I/AAAAAAAAYzA/-2X-oY9C3N0/s1920-w1920-h1080-c/BayBridge-night-mono.jpg`,
  `https://lh6.googleusercontent.com/-utp4ouvAO10/Ud6okYh24yI/AAAAAAAAYrg/fRr-tnaqxBI/s1920-w1920-h1080-c/DSC_9645-Edit-Edit-Edit.JPG`,
  `https://lh6.googleusercontent.com/-bCmJvz5jD9s/U_yj0wk6KLI/AAAAAAAB-7s/t7dIu3T7nvw/s1920-w1920-h1080-c/_X7A8818-Edit.jpg`,
  `https://lh4.googleusercontent.com/-Pg1HXtyXBZ0/UGHWhKaSrhI/AAAAAAAASZE/q3omNbvxCJU/s1920-w1920-h1080-c/FI4C6008.jpg`,
  `https://lh3.googleusercontent.com/-boXHwu7eW_g/UjjGBMhSzwI/AAAAAAAANJE/9w555NqrJws/s1920-w1920-h1080-c/10-13-12highres.jpg`,
  `https://lh4.googleusercontent.com/-gKcKe_o5slw/RapYX1Oyx0I/AAAAAAAAa9Q/P9rYqEPs7dc/s1920-w1920-h1080-c/P1080441.JPG`,
  `https://lh3.googleusercontent.com/-PtAU0On5OII/Tr8FOZ3yVuI/AAAAAAAAGY4/IYyAYzHZzo0/s1920-w1920-h1080-c/Seal_Rocks-centered.jpg`,
  `https://lh4.googleusercontent.com/-129Q91osfKU/UKsTjV0_Q0I/AAAAAAACKD0/ejMXCx6_w14/s1920-w1920-h1080-c/IMG_3144-3.jpg`,
  `https://lh3.googleusercontent.com/-7wj7ipdaCbI/U_yj0qysNgI/AAAAAAAB-7k/dl8d2M4N5vE/s1920-w1920-h1080-c/_X7A5208-Edit.jpg`,
  `https://lh4.googleusercontent.com/-vK7sTdwps8g/UKPHFTynz-I/AAAAAAAARas/_RETAXGVU9g/s1920-w1920-h1080-c/Red%2Bby%2BAlistair%2BNicol.jpg`,
  `https://lh5.googleusercontent.com/-rQ9SZ0dfJ_I/UtTzyqBufcI/AAAAAAAAOj4/zAOnrvu9aG8/s1920-w1920-h1080-c/Silver%2BLake%2BStarTrails.jpg`,
  `https://lh4.googleusercontent.com/-fOXNAoVsl2E/UONnO-Zp2zI/AAAAAAAAyPo/kjEzrMZXylk/s1920-w1920-h1080-c/IMGP7962.jpg`,
  `https://lh3.googleusercontent.com/-q2yAJwhwIic/Th0QR-JaxiI/AAAAAAAABCo/YIGFyyYRXBI/s1920-w1920-h1080-c/untitled-19.jpg`,
  `https://lh3.googleusercontent.com/-cjUv9eE6oRE/Tg1dXWtEjRI/AAAAAAAAc1E/ScCXWwu6HbM/s1920-w1920-h1080-c/080229-4653-GraytonStream.jpg`,
  `https://lh5.googleusercontent.com/-x838dk0Z5UE/UaXxyeQ21gI/AAAAAAAAm-o/7OSZeQrHSO0/s1920-w1920-h1080-c/DSC_6902-Edit-Edit.jpg`,
  `https://lh5.googleusercontent.com/-k1-SvxtkRaI/TzW2PE5LU_I/AAAAAAAA14A/JuGDMoDvbCQ/s1920-w1920-h1080-c/BayBridgeSunrise-LE.jpg`,
  `https://lh3.googleusercontent.com/-B4s7SDef3U8/TwttGH3PVKI/AAAAAAAAR8k/Gp5uWnBs4JE/s1920-w1920-h1080-c/the%2Btrane%2Band%2Bthe%2Bpharoah.jpg`,
  `https://lh6.googleusercontent.com/-F44_mY6bA2c/UaiW3nWnsrI/AAAAAAAAWKk/pVPuQcy_ygQ/s1920-w1920-h1080-c/DSC_6930-Edit-Edit.jpg`,
  `https://lh6.googleusercontent.com/-hs9pldJQseQ/Tw9Z3Mea0PI/AAAAAAABFTs/u8KBBQQ9At4/s1920-w1920-h1080-c/Funston-Sunset.jpg`,
  `https://lh3.googleusercontent.com/-ouB3hbNdnVY/Tzlw8hO_W7I/AAAAAAAAAVQ/GNAVkaDa0lw/s1920-w1920-h1080-c/IMGP0913.jpg`,
  `https://lh6.googleusercontent.com/-YiCUO9G6Vfg/ULgv8pQPeGI/AAAAAAAAD-k/NGhYvPuu5bc/s1920-w1920-h1080-c/IMGP5017.jpg`,
  `https://lh6.googleusercontent.com/-JkxqJu_ZG6I/Tg1b6ZErN-I/AAAAAAAAAJk/sD_vHpTz-hU/s1920-w1920-h1080-c/061228-2049-UTPScripps1.jpg`,
  `https://lh6.googleusercontent.com/-o11hGOKretw/Tg1cW-5ZUOI/AAAAAAAAAKQ/bz8-ylAE0_A/s1920-w1920-h1080-c/071010-3287-SausMorn1.jpg`,
  `https://lh3.googleusercontent.com/-uw6YFcXJKbo/U0MgUuctfgI/AAAAAAAJEXg/rLxRH7N4ths/s1920-w1920-h1080-c/Trey%2BRatcliff%2B-%2BNew%2BYork%2B-%2BInception.jpg`,
  `https://lh3.googleusercontent.com/-lEy_31bnK1A/TyYVYFaoUxI/AAAAAAAAPxc/_4v9O3EKy6c/s1920-w1920-h1080-c/LandsEnd-Sunset.jpg`,
  `https://lh6.googleusercontent.com/-MjADvnIjxV0/U0MdmA5ytOI/AAAAAAAJKn4/0ZUjaXODMKA/s1920-w1920-h1080-c/The%2BSpires-2.jpg`,
  `https://lh4.googleusercontent.com/-IPbUv2dc9E4/Ugotamqb0xI/AAAAAAAAbME/zTVKgdkCdRY/s1920-w1920-h1080-c/DSC_2018-Edit-Edit.JPG`,
  `https://lh5.googleusercontent.com/-tFk1NsophJc/UqWc4p6NF9I/AAAAAAAAHws/4zvvKSi-qRk/s1920-w1920-h1080-c/MSU_9976.jpg`,
  `https://lh3.googleusercontent.com/-5-bmm_yEw8Q/ToaN8JLAR1I/AAAAAAABC7I/eMpXTtyj67Y/s1920-w1920-h1080-c/Reef-mono.jpg`,
  `https://lh4.googleusercontent.com/-D-a8TeEMxVU/T0u5OcN_KcI/AAAAAAAAQ3A/buaoFgy2i-Y/s1920-w1920-h1080-c/IMG_2705.jpg`,
  `https://lh3.googleusercontent.com/-F3PyWBuTa9A/Txo6n6KVzRI/AAAAAAABFQ4/bhv0lJobcUE/s1920-w1920-h1080-c/SutroSunset-WaterfallRock.jpg`,
  `https://lh5.googleusercontent.com/-m6gJXedR5K0/Tg1bJIJrdDI/AAAAAAAAAIk/luzGf-NHcC0/s1920-w1920-h1080-c/060820-0818-ThePhotogs.jpg`,
  `https://lh5.googleusercontent.com/-F33Ezsq01cw/TgkRlUvin5I/AAAAAAAI6wA/g434OYIBJiA/s1920-w1920-h1080-c/Portofino2.jpg`,
  `https://lh3.googleusercontent.com/-QOLm5NyrBAg/Tg1hGjt7CQI/AAAAAAAAc2A/oW-ErAUPpKY/s1920-w1920-h1080-c/110521-8046-PacificaLt2.jpg`,
  `https://lh4.googleusercontent.com/-QVbxD_ZZCa0/U_yjxBJn4WI/AAAAAAAB-6k/fAnDqFKCfh4/s1920-w1920-h1080-c/_DX_3511_2_3_4_5_32bit-Edit.jpg`,
  `https://lh5.googleusercontent.com/-uNim7D5ywdM/UqTGRcbr78I/AAAAAAAAXKY/2Fv7yLI2kZc/s1920-w1920-h1080-c/C21T0880.jpg`,
  `https://lh4.googleusercontent.com/-FNrstx8M7hY/TyH51o60WuI/AAAAAAAAGTY/3nRvYRuPjEU/s1920-w1920-h1080-c/Pescadero.jpg`,
  `https://lh6.googleusercontent.com/-I7qT8fiUqZU/TgkSotvmFkI/AAAAAAAI6h0/ASyt4FPWAso/s1920-w1920-h1080-c/Temple%2BOver%2BKyoto.jpg`,
  `https://lh6.googleusercontent.com/-7FTPwrBoDBs/Ufb1a1gM-UI/AAAAAAAADK0/NrOPNAfCG7I/s1920-w1920-h1080-c/DSC_4154b.jpg`,
  `https://lh6.googleusercontent.com/-JLMulqzQsQQ/UqBrOg83JWI/AAAAAAAAgsY/vzUeMdvC-h0/s1920-w1920-h1080-c/DSC_0663-Edit-Edit-Edit.JPG`,
  `https://lh5.googleusercontent.com/-IevMmNQigxY/Uc3F-z0I5qI/AAAAAAAAItw/eRKlk6yr6TY/s1920-w1920-h1080-c/GGB_130628_MCu_1-2.jpg`,
  `https://lh4.googleusercontent.com/-SUKN-r5lw5A/Tg1fNM6yIEI/AAAAAAAAAN8/PULlaJpHBkI/s1920-w1920-h1080-c/090810-1930-NeedleAndHaystack.jpg`,
  `https://lh3.googleusercontent.com/-vtVHPLJKT3w/T9KLSzUhuOI/AAAAAAAABiY/3a5M4phhFs0/s1920-w1920-h1080-c/2012.%2Bpurple%2Bis%2Bmy%2Bfavorite%2Bcolor.jpg`,
  `https://lh6.googleusercontent.com/-55UeILqE2s0/T0PDELei3HI/AAAAAAABFbU/4_nqVtef2m8/s1920-w1920-h1080-c/SealRocks-sunset-reflection.jpg`,
  `https://lh5.googleusercontent.com/-XxP1oir93Fg/U0MW6gL3miI/AAAAAAAJA0U/hP0D1e9JC-w/s1920-w1920-h1080-c/New%2BYork%2B-%2BNEX7%2B-%2BTrey%2BRatcliff%2B%25288%2Bof%2B21%2529.jpg`,
  `https://lh4.googleusercontent.com/-Gp7BKH79U6k/T_rPHeEUv4I/AAAAAAAAPL8/LSoFOwB9QNU/s1920-w1920-h1080-c/Patience.jpg`,
  `https://lh4.googleusercontent.com/-RZKOyMbLobw/UONnVGAE9tI/AAAAAAAAyPM/60as7LUVuHI/s1920-w1920-h1080-c/IMGP8828.jpg`,
  `https://lh4.googleusercontent.com/-oYOr7AcS0F4/UZUYFMNixiI/AAAAAAAAGL0/6fp4ZSWjH90/s1920-w1920-h1080-c/5-01-13-spider-crawler-lightning-road-albany-tx.png`,
  `https://lh4.googleusercontent.com/-4wJlrgKnUsI/U0MZbQCL-5I/AAAAAAAJOwE/yL0yy2OMWtI/s1920-w1920-h1080-c/The%2BBlue%2BCity.jpg`,
  `https://lh6.googleusercontent.com/-_OVIBu35BFs/UOD9ybxkzmI/AAAAAAAAkNo/YyQXlMNgeEk/s1920-w1920-h1080-c/06152012-12.jpg`,
  `https://lh6.googleusercontent.com/-onm54VnIRFQ/SBnwVTP3Y4I/AAAAAAAAvJc/jJxlxmTu5t8/s1920-w1920-h1080-c/D30_0895.JPG`,
  `https://lh4.googleusercontent.com/-TKsDKeRS95U/Tkrw6TiAyPI/AAAAAAAC4HY/bygELaJa5-s/s1920-w1920-h1080-c/Metal-1.jpg`,
  `https://lh6.googleusercontent.com/-b_ZyO6A8eec/UNcwXdvdtMI/AAAAAAAAR4k/YcThGSa1EHE/s1920-w1920-h1080-c/DSC_0300.jpg`,
  `https://lh6.googleusercontent.com/-Mc32ZHV--b0/SwIy9sStc0I/AAAAAAABLXg/AB-YvtyK-wk/s1920-w1920-h1080-c/DSC_6868.JPG`,
  `https://lh5.googleusercontent.com/-EUrlRFim4uQ/Uo5D2fBJduI/AAAAAAAABZU/73PLVw3Xxk0/s1920-w1920-h1080-c/class3_05.JPG`,
  `https://lh3.googleusercontent.com/-_SFDsIPPKzI/TxhNwgko__I/AAAAAAABFhY/uz_fTrjtNFg/s1920-w1920-h1080-c/MarshalBeach-sunset-rocks-.jpg`,
  `https://lh6.googleusercontent.com/-WrzPVL3SMFs/Uq-D3XLVHUI/AAAAAAAAPBY/JQpQw38ggyM/s1920-w1920-h1080-c/_O9V5569_HDR.jpg`,
  `https://lh6.googleusercontent.com/-n8kCRwIAl3I/U0MlTxDHALI/AAAAAAAJEL0/VwbBzYmP0nQ/s1920-w1920-h1080-c/trey-ratcliff-road-to-mountain-forever.jpg`,
  `https://lh4.googleusercontent.com/-grccgI_cL8k/TwH0-_dHIvI/AAAAAAABFbM/sqNxtA-Gi30/s1920-w1920-h1080-c/RodeoBeach-sunset-16x9.jpg`,
  `https://lh6.googleusercontent.com/-XZGw1LIoIQk/Ua2ANshiwmI/AAAAAAAAfX8/PIcgUJRHb3M/s1280-w1280-c-h720-k-no/Sky%2BPainting`,
  `https://lh4.googleusercontent.com/-dKrL8_a8MmE/UrWktdbZX_I/AAAAAAAAilY/2Ce0qzN9r3s/s1920-w1920-h1080-c/DSC_0816-Edit-Edit-Edit-Edit-Edit.JPG`,
  `https://lh5.googleusercontent.com/-NvzWYFZm9b0/UQmFrRIGaDI/AAAAAAAAlew/em5aqVHnV3M/s1920-w1920-h1080-c/IMG_1204.jpg`,
  `https://lh6.googleusercontent.com/-9SBIVQE_N98/UnDyKXBM-VI/AAAAAAAAGw8/JRqWBIObFek/s1920-w1920-h1080-c/_MSU8463.jpg`,
  `https://lh4.googleusercontent.com/-oVqvzpNBaE8/UlmYCAD6pNI/AAAAAAAAQPQ/xDn8iGotkyo/s1920-w1920-h1080-c/DSC_4067.jpg`,
  `https://lh6.googleusercontent.com/-SzwsBTne5SM/U_yjvpyvA6I/AAAAAAAB-6A/q0imuvbf0Yw/s1920-w1920-h1080-c/_D3_0763-Edit-Edit.jpg`,
  `https://lh5.googleusercontent.com/-uXesNMoRpN8/U08k4CCBTsI/AAAAAAAAvr0/7ZtIaGpNfHU/s1920-w1920-h1080-c/IMG_4772%2Bp4.JPG`,
  `https://lh6.googleusercontent.com/-sSh1MI_KB_c/TwXF_f9Tr4I/AAAAAAAAL-g/ivbuKnmkfLI/s1920-w1920-h1080-c/SutroSunset-rocks-misty.jpg`,
  `https://lh3.googleusercontent.com/-djAiXa_L7EU/TgtZGJZPcFI/AAAAAAAJBA0/Ih2FgNUBZYE/s1920-w1920-h1080-c/the%2Bdocs.jpg`,
  `https://lh4.googleusercontent.com/-25-BIcSpaLU/UX-tEB0seYI/AAAAAAAAU2Y/SX0W4AL4YQM/s1920-w1920-h1080-c/AtAnchor.jpg`,
  `https://lh4.googleusercontent.com/-WUT3n130gGw/U0MR8lh-SbI/AAAAAAAIpgw/VO52olLVJN8/s1920-w1920-h1080-c/A%2BRazor%2Bto%2Bthe%2BSky.jpg`,
  `https://lh5.googleusercontent.com/--ETG9eU1_Xw/Tgt5l8cyMwI/AAAAAAAIWTg/nWSbj2O5c6w/s1920-w1920-h1080-c/4070581709_a1c668a779_o.jpg`,
  `https://lh6.googleusercontent.com/-aIBq7YhM6-M/TxMQytFssDI/AAAAAAAANXs/9RurRnKmOOw/s1920-w1920-h1080-c/CliffHouseSunset.jpg`,
  `https://lh4.googleusercontent.com/--Km5pB3lBaM/Ua3DdDBGtcI/AAAAAAAAPV4/p_BFy_Ps50w/s2560/DSC01034`,
  `https://lh3.googleusercontent.com/-LVkYomDgo1g/TwoREmpuoLI/AAAAAAAAMiE/duaVKfg5Blg/s1920-w1920-h1080-c/Big-Sur-Coastal-Seascape-2.jpg`,
  `https://lh6.googleusercontent.com/-hXPVBw1iPyc/Tg1gUs9WABI/AAAAAAAAAPU/J-g25o_00Gk/s1920-w1920-h1080-c/100804-4696-PololuMorn1.jpg`,
  `https://lh6.googleusercontent.com/-GwSLzDzfyGA/UWoqCg-pk2I/AAAAAAAAMh0/JeyhZ3UqFys/s1920-w1920-h1080-c/MOL_1600.jpg`,
  `https://lh6.googleusercontent.com/-Sm0TD1_vzFo/ULrHwJOPf3I/AAAAAAAAEMY/NGUYdwUEtGY/s1920-w1920-h1080-c/IMGP0652_3_4_tonemapped.jpg`,
  `https://lh4.googleusercontent.com/-gE1QbuQR45Q/TqiD0bdOG7I/AAAAAAAAE8w/fawdaKhXGu4/s1920-w1920-h1080-c/CGPier-storm-mono.jpg`,
  `https://lh5.googleusercontent.com/-uaGTbkMBysI/R7qYVHTGv6I/AAAAAAAABiQ/IU0yK7OgvYc/s1920-w1920-h1080-c/IMG_0213.JPG`,
  `https://lh5.googleusercontent.com/-14sNcCfmulY/UOj-YBSQ8oI/AAAAAAAALsk/v-C7ZmZ-UIk/s1920-w1920-h1080-c/IMGP1985_stitch.jpg`,
  `https://lh6.googleusercontent.com/-qd290nJU0FM/UZYXTBbrzeI/AAAAAAAAVhI/gNH2tEzLT80/s1920-w1920-h1080-c/Reflection.jpg`,
  `https://lh3.googleusercontent.com/-QyxGZeaBON4/TgtZEYemvZI/AAAAAAAAgVE/_VepyvNFiTE/s1920-w1920-h1080-c/ibiza%2Bdock.jpg`,
  `https://lh5.googleusercontent.com/-b4ZiJXUmZLg/TUCPhc_z2pI/AAAAAAAAAgM/uY37UMRjGsw/s1920-w1920-h1080-c/Trippin%2527-3.jpg`,
  `https://lh5.googleusercontent.com/-2A31P4WBC8s/UtT1BndamcI/AAAAAAAAO1E/Qj5CF7S1UeU/s1920-w1920-h1080-c/DSC_1612.jpg`,
  `https://lh3.googleusercontent.com/-1dANL8dJDT0/T6h0JShpn-I/AAAAAAAAIDk/l_dKhWvFXUs/s1920-w1920-h1080-c/YosemiteFalls.jpg`,
  `https://lh3.googleusercontent.com/-8kQQYYzz_l8/UZSathUsJGI/AAAAAAAAsUM/3Z4WK9CG8wE/s1920-w1920-h1080-c/07_20090416.jpg`,
  `https://lh4.googleusercontent.com/-yYJUfqBHHXw/UoffDIFgJOI/AAAAAAAARpQ/M7Nskp7pTDg/s1920-w1920-h1080-c/DSC_7222-Edit-2.jpg`,
  `https://lh6.googleusercontent.com/-gcU_icksx-A/TnuyXEh2MeI/AAAAAAAAFhE/sjZHDkU_Vlg/s1920-w1920-h1080-c/IMG_9581.JPG`,
  `https://lh6.googleusercontent.com/--y_TRuOOPTA/T_0KxukaPeI/AAAAAAAARw0/VTeenM1J2xI/s1920-w1920-h1080-c/20100525-IMG_6788-HDR-Edit.jpg`,
  `https://lh3.googleusercontent.com/-6nBwDVfR-BE/UXrL2u6iqII/AAAAAAAAUro/CL47d2nXDzE/s1920-w1920-h1080-c/MagicBallContest.jpg`,
  `https://lh5.googleusercontent.com/-eDbulGr2hu8/Ur0CIxLk-FI/AAAAAAAAGA4/R7oAYMeXRws/s1920-w1920-h1080-c/IMG_3857-Edit-Edit.jpg`,
  `https://lh4.googleusercontent.com/-K258GmbSDxM/T_TBV0i4kLI/AAAAAAAABro/rW_nlHdct7U/s1920-w1920-h1080-c/IMGP9268.jpg`,
  `https://lh6.googleusercontent.com/-MxVUPW7j-L0/UCFpW985f5I/AAAAAAAAJI8/HQfELZDoN7I/s1920-w1920-h1080-c/_MG_4776.CR2.jpg`,
  `https://lh4.googleusercontent.com/-1cz2zcbiz-4/Th0QVgyAMBI/AAAAAAAABC4/gVc-Bp_mFGs/s1920-w1920-h1080-c/untitled-3-2.jpg`,
  `https://lh3.googleusercontent.com/-1o-jUyycpcE/UIdjwEWDS3I/AAAAAAAAED4/7Mj-xSqdO5E/s1920-w1920-h1080-c/sky_leaves.jpg`,
  `https://lh6.googleusercontent.com/-GGVPhdUiKwY/UOOZDe9JFQI/AAAAAAAAKiY/Noq6gsZlXxk/s1920-w1920-h1080-c/TunnelViewWinter.jpg`,
  `https://lh5.googleusercontent.com/-ZGfUihferj8/TgtZGKAJjOI/AAAAAAAAdd8/e-EXjm51hhA/s1920-w1920-h1080-c/3674678524_f49854f3b1_o.jpg`,
  `https://lh5.googleusercontent.com/-HdhuOcwGEcs/TgtZEZtITGI/AAAAAAAAVmw/nPx5UAR0qic/s1920-w1920-h1080-c/morning%2Bwith%2Bcoffee%2Bin%2Byellowstone.jpg`,
  `https://lh5.googleusercontent.com/-xu-TTAEZtQo/UbtDr65unqI/AAAAAAAABKo/-2asqRlMolw/s1920-w1920-h1080-c/Bristlecone_Stars.jpg`,
  `https://lh5.googleusercontent.com/-Bz1rXnbAy1s/UQNThZKgMRI/AAAAAAAAScM/eUItE7glPn0/s1920-w1920-h1080-c/StillStanding.jpg`,
  `https://lh4.googleusercontent.com/-FDe6_2rxtEs/UkBgjq9ZJmI/AAAAAAAAELo/zT29ZhT2NAA/s1920-w1920-h1080-c/Salt_Point.jpg`,
  `https://lh5.googleusercontent.com/-KPCupfttZeg/UYEMyuiN84I/AAAAAAAAU5k/74L4iR6gMDk/s1920-w1920-h1080-c/untouched.jpg`,
  `https://lh6.googleusercontent.com/--LqjPK05as8/TsSfH1cu69I/AAAAAAAATUE/JyMyDxcW1Ms/s1920-w1920-h1080-c/TacomaChiluly--10.jpg`,
  `https://lh6.googleusercontent.com/-5ntl45J8Lxg/UiHZG2mJ1nI/AAAAAAAAb8s/f10TxWDh2zA/s1920-w1920-h1080-c/DSC_5165-Edit-Edit.JPG`,
  `https://lh3.googleusercontent.com/-5lm5qxI06OU/TqkYjHdt_lI/AAAAAAAAKOc/Eqb40U2Jsiw/s1920-w1920-h1080-c/CrackedIsSometimesGood.jpg`,
  `https://lh5.googleusercontent.com/-ot6zDqm0p1s/UM-oZwFOYBI/AAAAAAAAImc/_9QNnbQXa5Y/s1920-w1920-h1080-c/IMG_0472.jpg`,
  `https://lh4.googleusercontent.com/-3vJ7YydpvVU/T3Fad0DfOvI/AAAAAAAAIIg/rJ5piFMcgKg/s1920-w1920-h1080-c/IMG_5328.JPG`,
  `https://lh4.googleusercontent.com/-v5W9oXSutcs/UjX_nq7Q1DI/AAAAAAAAPVE/A201XC4J5qs/s1920-w1920-h1080-c/DSCF0155-Edit.jpg`,
  `https://lh5.googleusercontent.com/-GMJoozxAcgE/Tk7h8aldhrI/AAAAAAAAFZ8/SnFiv5CZcYE/s1920-w1920-h1080-c/IMG_6000.JPG`,
  `https://lh5.googleusercontent.com/-11t9DgvNYhY/UG3HjpR9T9I/AAAAAAAAIO4/FSkNbPYzUMA/s1920-w1920-h1080-c/IMG_2526-3b.jpg`,
  `https://lh4.googleusercontent.com/-iTxENBfrZfY/T5ZLrTvoeCI/AAAAAAAAJD8/ik0c31ZcTOw/s1920-w1920-h1080-c/IMG_6499.JPG`,
  `https://lh6.googleusercontent.com/-8K8X3n7zPKE/UOOZGSlNvjI/AAAAAAAAKig/yTCbEVh-lCw/s1920-w1920-h1080-c/MorningGlory.jpg`,
  `https://lh4.googleusercontent.com/-MXAy1Q1e8pw/TtPRnPCM4AI/AAAAAAAAQc0/Vvc74HYL35s/s1920-w1920-h1080-c/IMG_1984.jpg`,
  `https://lh4.googleusercontent.com/-iVr5r1Yycbs/UkPSMp_2CZI/AAAAAAAALrA/ME5aBtr5fdM/s1920-w1920-h1080-c/9082667654_c7919ec6ed_o.jpg`,
  `https://lh3.googleusercontent.com/-jwJEWGscrls/T-dsvGIbyRI/AAAAAAAABp4/Pgn_t5V2LNs/s1920-w1920-h1080-c/Wyoming-5.jpg`,
  `https://lh4.googleusercontent.com/-FeRCoqwVOB4/Tkgq-geJE3I/AAAAAAAAFYo/xdj-91ytvg8/s1920-w1920-h1080-c/IMG_8981.JPG`,
  `https://lh5.googleusercontent.com/-ENSOsH-5iPQ/UZSar_1xTLI/AAAAAAAAsTo/CjPKgR3jXAs/s1920-w1920-h1080-c/04_20080526.jpg`,
  `https://lh3.googleusercontent.com/-QpB1YMuAXEA/TikI95S2KmI/AAAAAAAAPSw/kJPeHft92m4/s1920-w1920-h1080-c/MC2_8779.JPG`,
  `https://lh4.googleusercontent.com/-E57LJIzkkd8/UM-oHjiKHJI/AAAAAAAAIjY/QKAP7QTXBNY/s1920-w1920-h1080-c/IMG_0366.jpg`,
  `https://lh5.googleusercontent.com/--qL9ddvO4gs/UWoqHffdewI/AAAAAAAAMh0/uf5mp_xqsHI/s1920-w1920-h1080-c/MOL_1818.jpg`,
  `https://lh4.googleusercontent.com/-Xdz2ceNVzdw/Tm5L7A9tdYI/AAAAAAAAFsg/k4Tjwbi73Cw/s1920-w1920-h1080-c/IMG_3027.jpg`,
  `https://lh5.googleusercontent.com/-ejLi6Bg7IUI/TonR_AZLYLI/AAAAAAAAQWk/A1bVgL3p2A4/s1920-w1920-h1080-c/IMG_0242.jpg`,
  `https://lh3.googleusercontent.com/-iNpCGNJTrRo/Ugbk1ZMLczI/AAAAAAAAOE0/FZWVWqXGdT8/s1920-w1920-h1080-c/DSC_0423-Edit.jpg`,
  `https://lh6.googleusercontent.com/-q9gWC1ZIAvg/UbZrg9lIcAI/AAAAAAAAL4I/xzSlyLfAGrQ/s1920-w1920-h1080-c/WahclelaFalls.jpg`,
  `https://lh5.googleusercontent.com/-CaqC7SHp_2w/SF8sHokHjwI/AAAAAAAACyw/gC4j26zZen8/s1920-w1920-h1080-c/DSC_9166.JPG`,
  `https://lh6.googleusercontent.com/-Bo0_R3Hshio/UOUefGb4bpI/AAAAAAAARno/EBZGWRhWwzk/s1920-w1920-h1080-c/IMG_7449.jpg`,
  `https://lh3.googleusercontent.com/-y9mHkm6F9NI/UJH6oUvIN3I/AAAAAAAADqU/ueLAmjtceMI/s1920-w1920-h1080-c/path_of_leaves.jpg`,
  `https://lh5.googleusercontent.com/-ps4uT_pEdCE/RpKjlC16MuI/AAAAAAAAAt0/n09gNExGMl0/s1920-w1920-h1080-c/DSC_2079-1.JPG`,
];
/* eslint-enable */

const FONT_STACK_OPTIONS = [
  {
    label: `Arial`,
    value: `'Arial', 'Helvetica', sans-serif`
  },
  {
    label: `Poppins`,
    value: `'Poppins', 'Helvetica', Arial, sans-serif`
  },
  {
    label: `Courier`,
    value: `'Courier', 'Helvetica', sans-serif`
  },
  {
    label: `Roboto`,
    value: `'Roboto', 'Helvetica', Arial, sans-serif`
  },
  {
    label: `Roboto Mono`,
    value: `'Roboto Mono', monospace`
  },
  {
    label: `Verdana`,
    value: `'Verdana', 'Helvetica', sans-serif`
  },
];

const ThemeEditor = (props: Props) => {
  const { t, hideTitle, theme, userTheme, updateUserTheme, rewriteUserTheme, updateTheme, platformId, previousTheme, updatePreviousTheme, rehydrated } = props;
  const [viewingTheme, setViewingTheme] = useState(``);
  const [editingColor, setEditingColor] = useState(``);
  const [lastDefaultTheme, setLastDefaultTheme] = useState(``);
  const editingEnabled = theme == `user`;
  const viewingThemePrefs = viewingTheme ? communityThemes[viewingTheme] : userTheme;

  
  const selectedFontStack = useMemo(() => {
    if (viewingThemePrefs?.fontStack) {
      return replaceCircularStdFont(viewingThemePrefs?.fontStack);
    } else {
      return THEMES.Classic.fontStack;
    }
  }, [viewingThemePrefs.fontStack]);

  const importExportTheme = useMemo(() => {
    userTheme.fontStack = replaceCircularStdFont(userTheme?.fontStack);
    return JSON.stringify(userTheme);
  }, [userTheme]);

  const handleColorChange = (key, { hex }) => {
    updateUserTheme(key, hex);
  };

  const getRandomFromArray = (a: Array<any>) => {
    return a[Math.floor(Math.random() * a.length)];
  };

  const getRandomColor = (shade: number) => {
    return getRandomFromArray(themeColors[shade.toString()] || []);
  };

  const generateRandomTheme = () => {
    const invertToLight = getRandomFromArray([true, false]);
    const transparency = getRandomFromArray([0,10,10,20, 30, 40, 50, 60, 70, 80]);
    const backgroundImage = getRandomFromArray(BACKGROUND_IMAGES);

    rewriteUserTheme({
      appScale: `8px`,
      fontStack: `'Roboto', 'Helvetica', Arial, sans-serif`,
      backgroundImage: backgroundImage,
      backgroundRepeat: `cover`,
      bordersNoShadows: false,
      invertToLight,
      transparency: transparency,
      leftSidebarTransparency: transparency,
      rightSidebarTransparency: transparency,
      tabTransparency: transparency,
      tableTransparency: transparency,
      borders: getRandomColor(50),
      button: getRandomColor(50),
      body: invertToLight ? getRandomColor(600) : getRandomColor(50),
      highlight: invertToLight ? getRandomColor(400) : getRandomColor(50),
      brand: getRandomColor(400),
      gray1: getRandomColor(100),
      gray2: invertToLight ? getRandomColor(50) : getRandomColor(600),
      gray3: invertToLight ? getRandomColor(100) : getRandomColor(900),
      lightBlue: invertToLight ? getRandomColor(400) : getRandomColor(100),
      red: getRandomColor(300),
      green: getRandomColor(300),
      yellow: getRandomColor(300)
    });

    return true;
  };

  const validateValue = (themeString: string) => {
    try {
      const { valid, theme } = validateTheme(JSON.parse(themeString));
      if (valid) {
        Object.keys(theme).forEach((k) => {
          updateUserTheme(k, theme[k]);
        });
      }
    } catch(e) {
      emitEvent(NOTIFICATION, {
        notification_id: new Date().getTime(),
        title: `Error`,
        title_vars: ``,
        message_raw: `An error occurred validating the theme value: ${e.message}`,
        message: `An error occurred validating the theme value: ${e.message}`,
        message_vars: ``,
        pinned: false,
        style: `error`,
        url: ``
      });
      return;
    }
  };

  const renderPreview = (className: string) => {
    return (
      <div className={ `background-preview ${className}` } >
        <div className="background-preview-header">
          <div className="nav-left">
            <div className="logo-wrapper">
              { LogoMark[platformId ? platformId : 0] }
            </div>
            <div className="nav-item" >
                  Markets
            </div>
            <div className="nav-item" >
                  Portfolio
            </div>
            <div className="nav-item" >
                  Orders
            </div>
            <div className="nav-item" >
                  Alerts
            </div>
          </div>
          <div className="nav-right">
            <div className="nav-item" >
                    Profile
            </div>
            <div className="nav-item" >
                    Help
            </div>
          </div>
        </div>
        <div className="background-preview-tab-strip">
          <div className="left" />
          <div className="center" >
            <div className="tabs" >
                  Candlestick
            </div>
            <div className="tabs">
                  Depth
            </div>
          </div>
          <div className="right" >
            <div className="tabs" >
                  Data
            </div>
            <div className="tabs" >
                  Orders
            </div>
            <div className="tabs" >
                  Alerts
            </div>
            <div className="tabs" >
                  Insights
            </div>
          </div>
        </div>
        <div className="background-preview-body" >
          <div className="left" />
          <div className="center"  />
          <div className="right" >
            <div className="table">
              <div>
                    Example Text
              </div>
              <div className="buy">
                      Buy
              </div>
              <div className="sell">
                      Sell
              </div>
              <div>
                <span className="light-blue">
                      Light Blue
                </span>
              </div>
              <div>
                <span className="highlight">
                      Highlight
                </span>
              </div>
              { ` ` }
            </div>
          </div>
        </div>
        <div className="background-preview-footer" />
      </div>
    );
  };

  useEffect(() => {
    if (rehydrated) { 
      let lastTheme = theme;
      if (editingEnabled) {
        lastTheme = previousTheme || getDefaultTheme();
      }
      setLastDefaultTheme(lastTheme);
      updatePreviousTheme(lastTheme);
    }
  }, [rehydrated]);

  return (
    <section className="edit-theme">
      {
        editingColor !== `` && (
          <Modal
            title={
              // $FlowIgnore: suppressing this error
              `Editing ${ THEME_FOOTPRINT[editingColor].friendlyName }`
            }
            onClose={ () => setEditingColor(``) }
            confirmText={ t(`app:done`) }
            onConfirm={ () => setEditingColor(``) }>
            <ChromePicker
              color={ userTheme[editingColor] }
              onChangeComplete={ (v) => handleColorChange(editingColor, v) }
              disableAlpha={ true } />
          </Modal>
        )
      }
      <div className='section-header'>
        {
          !hideTitle && (
            <h1>
              { !editingEnabled ? t(`settings:themes`) : t(`settings:themesAdvancedMode`) }
            </h1>
          )
        }

        <Button
          type="primary"
          onClick={ () => { 
            updateTheme(!editingEnabled ? `user` : lastDefaultTheme);
            return false; 
          } }>
          { !editingEnabled ? `Advanced mode` :  `Back to Default themes` }
        </Button>
      </div>
      
      { !editingEnabled && (
        <div className="active-theme">
          <RadioButtons
            label={ `` }
            options={
              [
                { label: t(`footer:classic`), value: `Classic`, name: `Classic` },
                { label: t(`footer:dark`), value: `Dark`, name: `Dark` },
                { label: t(`footer:light`), value: `Light`, name: `Light` },
              ]
            }
            name={ `activeTheme` }
            value={ theme }
            onChange={ (e) => {
              updateTheme(e.target.value);
              if (e.target.value != `user`) {
                setLastDefaultTheme(e.target.value);
                updatePreviousTheme(e.target.value);
              }
            } } />

          { renderPreview(``) }
        </div>
      ) }

      <div className={ `edit-theme-row ${ !editingEnabled ? `disabled` : `` }` }>
        <div className="theme-home">
          <h3>
            { t(`themeLibrary`) }
          </h3>
          <div className="theme-library">
            <ScrollableArea>
              {
                Object.keys(communityThemes).map((name, i) => (
                  <div
                    className={ `theme ${ name == viewingTheme ? `active` : `` }` }
                    key={ i }
                    onClick={ () => {
                      if (!editingEnabled) return false;
                      // $FlowIgnore: suppressing this error
                      setViewingTheme(name);
                    } }>
                    { name }
                  </div>
                ))
              }
            </ScrollableArea>

            <div>
              {
                viewingTheme == `` ? (
                  <Button 
                    type="secondary"
                    onClick={ () => {
                      if (!editingEnabled) return false;
                      generateRandomTheme();
                      return true;
                    } }>
                    { t(`generateRandomTheme`) }
                  </Button>
                ) : (
                  <div className='flex' style={ { gap: `1rem` } }>
                    <Button
                      type="secondary"
                      // $FlowIgnore: suppressing this error
                      disabled={ viewingTheme && communityThemes[viewingTheme] === userTheme }
                      onClick={ () => {
                        if (!editingEnabled) return false;
                        setViewingTheme(``);
                        return true;
                      } }>
                      <UndoIcon sx={ { fontSize: `2.2rem` } }/>
                    </Button>
                    
                    <Button
                      type="primary"
                      onClick={ () => {
                        if (!editingEnabled) return false;
                        rewriteUserTheme(communityThemes[viewingTheme]);
                        setViewingTheme(``);
                        return true;
                      } }>
                      { t(`cloneThisTheme`) }
                    </Button>
                  </div>
                )
              }
            </div>
          </div>
        </div>

        <div className="theme-viewer">
          <div>
            <h3>
Colors
            </h3>
            <div className="swatch-wrapper">
              {
                Object.keys(THEME_FOOTPRINT).filter((f) => {
                  return !THEME_FOOTPRINT[f].notFriendly;
                }).filter((f) => {
                  return THEME_FOOTPRINT[f].hasOwnProperty(`showIfEnabled`) ?
                    // $FlowIgnore: suppressing this error
                    viewingThemePrefs[THEME_FOOTPRINT[f].showIfEnabled] :
                    true;
                }).sort().map((k, i) => (
                  <div key={ i } className='swatch-container'>
                    <div
                      style={ { 
                        // $FlowIgnore: suppressing this error
                        backgroundColor: viewingThemePrefs[k]
                      } }
                      className={ `swatch ${ viewingTheme == `` ? `editable` : `` }` } 
                      onClick={ () => {
                        if (!editingEnabled) return false;
                        if (viewingTheme == ``) {
                          setEditingColor(k);
                        }
                      } }/>
                    <div className='swatch-label'>
                      { THEME_FOOTPRINT[k].friendlyName }
                    </div>
                  </div>
                ))
              }
            </div>
            <div className="theme-settings">
              <h3>
Settings
              </h3>

              <div>
                <Checkbox
                  name="invertToLight"
                  label={ t(`invertDetails`) + ` ` + t(`invertDetailsNote`) }
                  value={ !!viewingThemePrefs.invertToLight }
                  disabled={ !editingEnabled || viewingTheme !== `` }
                  onChange={ (e) => updateUserTheme(`invertToLight`, e.target.checked) } />
              </div>
              <div>
                <Checkbox
                  name="bordersNoShadows"
                  label={ t(`useBorders`) }
                  value={ !!viewingThemePrefs.bordersNoShadows }
                  disabled={ !editingEnabled || viewingTheme !== `` }
                  onChange={ (e) => updateUserTheme(`bordersNoShadows`, e.target.checked) } />
              </div>
              <div>
                <span className="range-label">
                  { THEME_FOOTPRINT.appScale.friendlyName }
                  { ` ` }
                (
                  { viewingThemePrefs.appScale }
                )
                  { ` ` }
                </span>
                <input
                  type="range"
                  value={ parseInt(viewingThemePrefs.appScale) }
                  onChange={ (e) => {
                    if (!editingEnabled) return false;
                    if (viewingTheme == ``) {
                      updateUserTheme(`appScale`, `${ e.target.value }px`);
                    }
                  } }
                  min="4"
                  max="12" />
              </div>
              <div>
                <span className="range-label">
                  { THEME_FOOTPRINT.transparency.friendlyName }
                  { ` ` }
                (
                  { viewingThemePrefs.transparency }
                )
                  { ` ` }
                </span>
                <input
                  type="range"
                  value={ parseInt(viewingThemePrefs.transparency) }
                  onChange={ (e) => {
                    if (!editingEnabled) return false;
                    if (viewingTheme == ``) {
                      updateUserTheme(`transparency`, parseInt(e.target.value));
                    }
                  } }
                  min="0"
                  max="90"
                  step="10" />
              </div>
              <div>
                <span className="range-label">
                  { THEME_FOOTPRINT.leftSidebarTransparency.friendlyName }
                  { ` ` }
                (
                  { viewingThemePrefs.leftSidebarTransparency }
                )
                  { ` ` }
                </span>
                <input
                  type="range"
                  value={ parseInt(viewingThemePrefs.leftSidebarTransparency) }
                  onChange={ (e) => {
                    if (!editingEnabled) return false;
                    if (viewingTheme == ``) {
                      updateUserTheme(`leftSidebarTransparency`, parseInt(e.target.value));
                    }
                  } }
                  min="0"
                  max="90"
                  step="10" />
              </div>
              <div>
                <span className="range-label">
                  { THEME_FOOTPRINT.rightSidebarTransparency.friendlyName }
                  { ` ` }
                (
                  { viewingThemePrefs.rightSidebarTransparency }
                )
                  { ` ` }
                </span>
                <input
                  type="range"
                  value={ parseInt(viewingThemePrefs.rightSidebarTransparency) }
                  onChange={ (e) => {
                    if (!editingEnabled) return false;
                    if (viewingTheme == ``) {
                      updateUserTheme(`rightSidebarTransparency`, parseInt(e.target.value));
                    }
                  } }
                  min="0"
                  max="90"
                  step="10" />
              </div>
              <div>
                <span className="range-label">
                  { THEME_FOOTPRINT.tableTransparency.friendlyName }
                  { ` ` }
                (
                  { viewingThemePrefs.tableTransparency }
                )
                  { ` ` }
                </span>
                <input
                  type="range"
                  value={ parseInt(viewingThemePrefs.tableTransparency) }
                  onChange={ (e) => {
                    if (!editingEnabled) return false;
                    if (viewingTheme == ``) {
                      updateUserTheme(`tableTransparency`, parseInt(e.target.value));
                    }
                  } }
                  min="0"
                  max="90"
                  step="10" />
              </div>
              <div>
                <span className="range-label">
                  { THEME_FOOTPRINT.tabTransparency.friendlyName }
                  { ` ` }
                (
                  { viewingThemePrefs.tabTransparency }
                )
                  { ` ` }
                </span>
                <input
                  type="range"
                  value={ parseInt(viewingThemePrefs.tabTransparency) }
                  onChange={ (e) => {
                    if (!editingEnabled) return false;
                    if (viewingTheme == ``) {
                      updateUserTheme(`tabTransparency`, parseInt(e.target.value));
                    }
                  } }
                  min="0"
                  max="90"
                  step="10" />
              </div>
              <div>
                <SelectField
                  label={
                    // $FlowIgnore: suppressing this error
                    THEME_FOOTPRINT.fontStack.friendlyName
                  }
                  name="fontStack"
                  value={ selectedFontStack }
                  onChange={ (e, v) => updateUserTheme(`fontStack`, v) }
                  disabled={ !editingEnabled || viewingTheme !== `` }
                  options={ FONT_STACK_OPTIONS } />
              </div>

              <h3>
                { t(`backgroundPreview`) }
              </h3>
              <div className="background-wrapper">
                <div className="background-nav">
                  <div
                    className="lt"
                    onClick={ () => {
                      let currentIndex = BACKGROUND_IMAGES.findIndex((b) => b == viewingThemePrefs.backgroundImage);
                      let nextIndex = BACKGROUND_IMAGES.length -1;
                      if(currentIndex - 1 >= 0)
                        nextIndex = currentIndex - 1;
                      updateUserTheme(`backgroundImage`, `${ BACKGROUND_IMAGES[nextIndex] }`);
                    } }>
                    &lt;Previous
                  </div>
                  <div
                    className="none"
                    onClick={ () => {
                      updateUserTheme(`backgroundImage`, `None`);
                    } }>
                    None
                  </div>
                  <div
                    className="gt"
                    onClick={ () => {
                      let currentIndex = BACKGROUND_IMAGES.findIndex((b) => b == viewingThemePrefs.backgroundImage);
                      let nextIndex = 0;
                      if(currentIndex + 1 < BACKGROUND_IMAGES.length)
                        nextIndex = currentIndex + 1;
                      updateUserTheme(`backgroundImage`, `${ BACKGROUND_IMAGES[nextIndex] }`);
                    } }>
                    Next&gt;
                  </div>
                </div>
                <div className="background-repeat-controls">
                  <RadioButtons
                    options={
                      [
                        { label: `Repeat-X`, value: `repeat-x`, name: `repeat-x` },
                        { label: `Repeat-Y`, value: `repeat-y`, name: `repeat-y` },
                        { label: `Repeat Both`, value: ``, name: `both` },
                        { label: `Cover`, value: `cover`, name: `cover` },
                        { label: `No Repeat`, value: `no-repeat`, name: `none` },
                      ]
                    }
                    name={ `backgroundRepeat` }
                    value={ viewingThemePrefs.backgroundRepeat }
                    onChange={ (e) => {
                      updateUserTheme(`backgroundRepeat`, `${ e.target.value }`);
                    } } />
                </div>
              </div>
            </div>
          </div>
            
          <div>
            <h3>
            Live Example
            </h3>
            { renderPreview(`sm`) }
          </div>
        </div>

        <div>
          <h3>
            { t(`importExportTheme`) }
          </h3>
          <div>
            <textarea
              value={ importExportTheme }
              onChange={ (e) => {
                if (!editingEnabled) return false;
                validateValue(e.target.value);
              } } />
          </div>        
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  userTheme: state.theme,
  theme: state.redisPrefs.theme,
  platformId: state.userInfo.user.platformId,
  previousTheme: state.app.previousTheme,
  rehydrated: state._persist.rehydrated,
});

const mapDispatchToProps = (dispatch) => ({
  updateUserTheme: (k, c) => dispatch(updateUserTheme(k, c)),
  rewriteUserTheme: (theme) => dispatch(rewriteUserTheme(theme)),
  resetToTheme: (t) => dispatch(resetToTheme(t)),
  updateTheme: (theme) => dispatch(updateTheme(theme)),
  updatePreviousTheme: (theme) => dispatch(setPreviousTheme(theme))
});

export default connect(mapStateToProps, mapDispatchToProps)(translate(`user`)(ThemeEditor));

// @flow
'use strict';

import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Button from '../utilities/Button.jsx';
import TextField from '../utilities/TextField.jsx';
import EntityTable from '../utilities/EntityTable.jsx';
import Search from '../../svgs/Search.jsx';
import type { ProfileProps } from './ProfileDataLayer.jsx';
import { toLocalDate } from '../../helpers/DateHelper.js';
import Trash from "../../svgs/Trash.jsx";
import SessionDeleteModal from '../user/SessionDeleteModal.jsx';

const ActiveSessions = (props: ProfileProps) => {
  const { killSessions, t, sessions } = props;
  const [filterText, setFilterText] = useState<string>(``);
  const [deleteSessionModalOpen, setDeleteSessionModalOpen] = useState<boolean>(false);
  const [deletingSession, setDeletingSession] = useState<any>([]);

  const closeDeleteSessionModal = () => {
    setDeleteSessionModalOpen(false);
    setDeletingSession([]);
  };

  const onDeleteSessionConfirm = () => {
    if(killSessions && deletingSession){
      if (deletingSession.length) {
        killSessions(deletingSession);
      } else { 
        killSessions([deletingSession]);
      }
    }
    closeDeleteSessionModal();
  };

  const columns = [
    {
      title: `Location`,
      key: `ipAddress`,
      display: (row) => {
        if (row.ipAddress) {
          return row.ipAddress;
        }else {
          return `Mobile`;
        }
      }
    },
    {
      title: t(`lastActive`),
      key: `lastActivity`,
      display: (row, lastActivity) => toLocalDate(lastActivity)
    },
    {
      title: t(`userAgent`),
      key: `userAgent`,
      nosort: true
    },
    {
      title: ``,
      key: `sessionId`,
      nosort: true,
      display: (row) => (
        <Button
          type="cancel"
          onClick={ () => {
            setDeleteSessionModalOpen(true);
            setDeletingSession(row);
            return false;
          } }>
          { Trash(`Trash${ row.sessionId }`) }
          <span>
            { t(`killSession`) }
          </span>
        </Button>
      )
    }
  ];

  return (
    <div className="profile-table-data">
      {
        deleteSessionModalOpen && (
          <SessionDeleteModal
            t={ t }
            all={ deletingSession.length ? true : false }
            title={ t(`app:genericConfirm`) }
            close={ () => closeDeleteSessionModal() }
            confirm={ () => onDeleteSessionConfirm() } />
        )
      }
      <h1 className="page-title">
        { t(`header:activeSessions`) }
      </h1>
      <section>
        <div>
          <div className="header">
            <div className="search">
              <TextField
                icon={ Search }
                label={ t(`app:search`) }
                name={ `search` }
                value={ filterText }
                onChange={ (e) => setFilterText(e.target.value) } />
            </div>
          </div>
          <EntityTable
            columns={ columns }
            data={ sessions || [] }
            defaultSortBy="lastActive"
            pageSize={ `all` }
            hidePagination={ true }
            filterText={ filterText } />
          <Button
            className="same-row"
            type="cancel"
            onClick={ () => {
              setDeleteSessionModalOpen(true);
              setDeletingSession(sessions);
              return false;
            } }>
            { t(`killAllSessions`) }
          </Button>
        </div>
      </section>
    </div>
  );

};

export default withRouter(ActiveSessions);

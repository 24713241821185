// @flow
'use strict';

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import NewAccountModal from './NewAccountModal.jsx';
import EditAccountModal from './EditAccountModal.jsx';
import DataTable, { FILTER_CONTROL_SELECT } from '../utilities/DataTable';
import Checkbox from '../utilities/Checkbox.jsx';
import Button from "../utilities/Button.jsx";
import TextField from "../utilities/TextField.jsx";
import { ucfirst } from "../../helpers/StringHelper.js";
import Search from "../../svgs/Search.jsx";
import Edit from "../../svgs/Edit.jsx";
import Trash from "../../svgs/Trash.jsx";
import { $ } from '../../helpers/api/ApiHelper.js';
import { emitEvent, UPDATE_ACCOUNT, DELETE_ACCOUNT } from '../../helpers/EventHelper.js';
import { changeGlobalPageSize } from '../../actions/app/changeGlobalPageSize.js';
import type { Account } from '../../types/Account.js';
import type { Exchange } from '../../types/Exchange.js';
import AccountDeleteModal from '../accounts/AccountDeleteModal.jsx';
import type { Chain } from '../../types/Chain.js';
import LSCXButton from '../../components/markets/LSCXButton.jsx';
import { updateTitle } from '../../helpers/BrowserTitleHelper.js';
//import TwoFactorAuthenticationModal from './TwoFactorAuthenticationModal.jsx';


type Props = {
  t: any,
  exchanges?: Array<Exchange>,
  currencies?: Array<Chain>,
  accounts?: Array<Account>,
  pageSize: any,
  changePageSize: (n: any) => void,
};

const AccountsTable = (props: Props) => {
  const { t, exchanges, accounts, currencies } = props;
  const [ filterText, setFilterText ] = useState(``);
  const [ newAccountModalOpen, setNewAccountModalOpen ] = useState(false);
  const [ deleteAccountModalOpen, setDeleteAccountModalOpen ] = useState(false);
  const [ editAccountModalOpen, setEditAccountModalOpen ] = useState(false);
  //const [ enable2faModalOpen, setEnable2faModalOpen ] = useState(false);
  //const [ enable2faDeclined, setEnable2faDeclined ] = useState(false);
  const [ deletingAccount, setDeletingAccount ] = useState(undefined);
  const [ editingAccount, setEditingAccount ] = useState(undefined);

  const platformId = useSelector((state) => state.userInfo.user.platformId);
  updateTitle(`Accounts`,platformId);

  //const user = useSelector((state) => state.userInfo.user);

  // useEffect(() => {
  //   if (accounts) {
  //     const tradingEnabled = accounts.some((e) => e.authTrade === true);
      
  //     if (tradingEnabled && !user.fA2Enabled && !enable2faDeclined) {
  //       setEnable2faModalOpen(true);
  //     }
  //   }
  // }, [ accounts, user ]);


  const onAccountEditClick = (e: any, account: Account, isLSCX: boolean) => {
    if (account.authVersion == 2 || isLSCX) {
      setEditAccountModalOpen(true);
      setEditingAccount(account);
    } else {
      window.open(`${ window.WWW_URL }/user/api`, `_blank`, ``);
    }
    return false;
  };

  // onAccountEditConfirm(authId: number) {
  //   $({
  //     url: `/private/user/accounts/${ authId }`,
  //     type: `PUT`,
  //     success: (data) => {
  //       if (data.success) {
  //         emitEvent(UPDATE_ACCOUNT, { authId, update: true });
  //       }
  //     }
  //   });
  //   this.setState({ editAccountModalOpen: false, editingAccount: undefined });
  //   return false;
  // }

  const onAccountDeleteClick = (e: any, account: Account) => {
    setDeleteAccountModalOpen(true);
    setDeletingAccount(account);
    return false;
  };

  const onAccountDeleteConfirm = (authId: number) => {
    $({
      url: `/private/user/accounts/${ authId }`,
      type: `DELETE`,
      success: (data) => {
        if (data.success) {
          emitEvent(DELETE_ACCOUNT, { authId, update: false });
        }
      }
    });
    setDeleteAccountModalOpen(false);
    setDeletingAccount(undefined);
    return false;
  };

  const addAccount = () => {
    setNewAccountModalOpen(true);
    return false;
  };

  const closeDeleteAccountModal = () => {
    setDeleteAccountModalOpen(false);
    setDeletingAccount(undefined);
  };

  const closeEditAccountModal = () => {
    setEditAccountModalOpen(false);
    setEditingAccount(undefined);
  };

  // const closeEnable2faModal = () => {
  //   setEnable2faModalOpen(false);
  //   setEnable2faDeclined(true);
  // };

  const toggleTrading = (authId: number, bool: boolean) => {
    $({
      url: `/private/user/accounts/${ authId }/tradingStatus`,
      type: `PUT`,
      body: {
        tradingEnabled: bool
      },
      success: (data) => {
        if (data.success) {
          emitEvent(UPDATE_ACCOUNT, { authId, update: { authTrade: bool } });
        }
      }
    });
  };

  const toggleActive = (authId: number, bool: boolean) => {
    $({
      url: `/private/user/accounts/${ authId }/activeStatus`,
      type: `PUT`,
      body: {
        enabled: bool
      },
      success: (data) => {
        if (data.success) {
          if(bool == true)
            emitEvent(UPDATE_ACCOUNT, { authId, update: { authActive: bool } });
          else
            emitEvent(UPDATE_ACCOUNT, { authId, update: { authActive: bool, authTrade: bool } });
        }
      }
    });
  };

  const RenderEditButton = ({ row, isLSCX }) => {
    return <Button type="primary" onClick={  (e) => onAccountEditClick(e, row, isLSCX) }>
      { Edit(`Edit${ row.authId }`) }
      <span>
        { `${t(`app:edit`)}` }
      </span>
    </Button>;     
  };

  const hideLSCXItems = (exchange) => 
    exchange && exchange.exchCode == `LSCX` ? false : true;
  

  const columns= [
    {
      title: t(`app:exchange`) + ` / ` + t(`app:currency`),
      key: `authExchId`,
      enableSorting: true,
      display: (row) => {
        const exchange = (exchanges || []).find((e) => e.exchId == row.authExchId);
        let src = `${ window.WWW_URL }/assets/img/currency/empty.png`;

        if (exchange) {
          src = `${ window.WWW_URL }/assets/img/exchange/${ exchange.exchCode }-icon.png`;
          //width = 80;
        } else if (row.authOptional && row.authOptional.length > 0) {
          src = `${ window.WWW_URL }/assets/img/currency/${ row.authOptional }.png`;
        }

        return (
          <div className='td-exchange'>
            <img
              src={ src }
              width={ `20rem` }
              height={ `20rem` }
              onError={ (e) => {e.target.onerror = null; e.target.src=`${ window.WWW_URL }/assets/img/currency/empty.png`;} } />
            <span>
              { row.authExchId < 990 ? exchange?.exchName || `` : row.authOptional }
            </span>
          </div>
        );
      },
      compareValue: (row) => row.authExchId < 990 ? (
        ((exchanges || []).find((e) => e.exchId == row.authExchId) || { exchName: `` }).exchName
      ) : row.authOptional
    },
    {
      title: t(`accountName`),
      key: `authNickname`,
      enableSorting: true,
      display: (row) => row.authNickname 
    },
    {
      title: t(`accountType`),
      key: `authTradingType`,
      enableSorting: true,
      display: (row) => ucfirst(row.authTradingType),
      filterControl: {
        type: FILTER_CONTROL_SELECT,
        options: [
          {
            label: t(`app:exchange`),
            value: `exchange`
          },
          {
            label: t(`deposit`),
            value: `deposit`
          },
          {
            label: t(`wallet`),
            value: `wallet`
          },
        ]
      }
    },                
    {
      title: t(`key`),
      key: `authKey`,
      enableSorting: true,
      display: (row, data) => data.length >= 18 ? data.substr(0, 15) + `...` : data
    },
    {
      title: t(`version`),
      key: `authVersion`,
      enableSorting: true,
      display: (row, authVersion) => `V${authVersion}`,
      filterControl: {
        type: FILTER_CONTROL_SELECT,
        options: [
          {
            label: `V1`,
            value: `1`
          },
          {
            label: `V2`,
            value: `2`
          },
        ]
      }
    },
    {
      title: t(`trading`),
      key: `authTrade`,
      thClass: `center`,
      display: (row, data) => row.authExchId < 990 ? (
        <div className="center">
          <Checkbox
            label=""
            name={ `trading-${ row.authId }` }
            value={ data }
            onChange={ (e) => toggleTrading(row.authId, e.target.checked) } />
        </div>
      ) : (
        <div className="center" />
      ),
      filterControl: {
        type: FILTER_CONTROL_SELECT,
        options: [
          {
            label: `Yes`,
            value: `true`
          },
          {
            label: `No`,
            value: `false`
          },
        ]
      }
    },
    {
      title: t(`active`),
      key: `authActive`,
      thClass: `center`,
      display: (row, data) => (
        <div className="center">
          <Checkbox
            label=""
            name={ `active-${ row.authId }` }
            value={ data }
            onChange={ (e) => toggleActive(row.authId, e.target.checked) } />
        </div>
      ),
      filterControl: {
        type: FILTER_CONTROL_SELECT,
        options: [
          {
            label: `Yes`,
            value: `true`
          },
          {
            label: `No`,
            value: `false`
          },
        ]
      }
    },
    {
      title: ``,
      key: `editAccount`,
      display: (row) => {
        const exchange = (exchanges || []).find((e) => e.exchId == row.authExchId);
        const isLSCX = exchange && exchange.exchCode == `LSCX`;
        const showEdit = hideLSCXItems(exchange);

        return  <>
          { isLSCX 
            ? <> 
              <RenderEditButton { ...{ row } } { ...{ isLSCX } }  /> 
              <LSCXButton styleClass="start"  accounts={ accounts } exchanges={ exchanges } /> 
            </> 
            : showEdit && <RenderEditButton { ...{ row } } isLSCX={ false } />  

          } 
        </>;
      }
    },
    {
      title: ``,
      key: `removeAccount`,
      display: (row) => {
        let exchange = (exchanges || []).find((e) => e.exchId == row.authExchId);
        let showDelete = hideLSCXItems(exchange);

        return showDelete ? (
          <Button type="cancel" onClick={ (e) => onAccountDeleteClick(e, row) }>
            { Trash(`Delete${ row.authId }`) }
            <span>
              { t(`app:delete`) }
            </span>
          </Button>
        ) : null;}
    }
  ];


  return (
    <div className="accounts">
      <section>
        <div className="accounts-contain">
          <div className="header">
            <div className="left-side">
              <h1 className="page-title">
                { t(`header:accounts`) }
              </h1>
              <Button className="add-account" type="ghost" onClick={ addAccount }>
                { `+ ` + t(`addNewAccount`) }
              </Button>
            </div>
            <div className="right-side">
              <div className="search">
                <TextField
                  icon={ Search }
                  label={ t(`app:search`) }
                  name={ `search` }
                  value={ filterText }
                  onChange={ (e) => setFilterText(e.target.value) } />
              </div>
            </div>
          </div>
          <DataTable
            id={ `accounts-table` }
            columns={ columns }
            data={ accounts }
            filterText={ filterText }
            serverSideSorting={ false }
            serverSideFiltering={ false }
            noDataText={ t(`user:keysNoDataText`) } />
        </div>
        {
          newAccountModalOpen && (
            <NewAccountModal
              t={ t }
              exchanges={ exchanges || [] }
              accounts={ accounts || [] }
              currencies={ currencies || [] }
              close={ () => setNewAccountModalOpen(false) } />
          )
        }
        {
          editAccountModalOpen && (
            <EditAccountModal
              t={ t }
              exchanges={ exchanges || [] }
              account={ editingAccount || {} }
              currencies={ currencies || [] }
              close={ () => closeEditAccountModal() } />
          )
        }
        {
          deleteAccountModalOpen && (
            <AccountDeleteModal
              t={ t }
              title={ t(`app:genericConfirm`) }
              onClose={ () => closeDeleteAccountModal() }
              account={ deletingAccount }
              onConfirm={ (authId) => onAccountDeleteConfirm(authId) } />
          )
        }
        { /* { enable2faModalOpen && (
          <TwoFactorAuthenticationModal
            t={ t }
            title={ t(`user:enable2fa`) }
            onClose={ () => closeEnable2faModal() } />) } */ }
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({
  pageSize: state.app.pageSize,
});

const mapDispatchToProps = (dispatch) => ({
  changePageSize: (p) => dispatch(changeGlobalPageSize(p))
});

export { AccountsTable as PureAccountsTable };
export default connect(mapStateToProps, mapDispatchToProps)(AccountsTable);

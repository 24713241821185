// @flow
'use strict';

import React from 'react';
import ReturnsRadarDataLayer from '../components/returnsRadar/ReturnsRadarDataLayer.jsx';

const ReturnsRadarPage = () => {
  return (
    <ReturnsRadarDataLayer />
  );
};

export default ReturnsRadarPage;


// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import type { Market } from '../../types/Market.js';
import type { Exchange } from '../../types/Exchange.js';
import ActiveAlertsTable from './ActiveAlertsTable.jsx';
import HistoryAlertsTable from './HistoryAlertsTable.jsx';
// import NewAlert from './NewAlert.jsx';
import { getTickerData, getMarketRange } from '../../helpers/api/ExchangeApi';
import { updateAlertActiveType } from '../../actions/markets/updateAlertActiveType.js';
import { toFixedDecimals } from '../../helpers/NumberHelper.js';
import { updateTitle } from '../../helpers/BrowserTitleHelper.js';

type Props = {
  t: any,
  markets: Array<Market>,
  exchanges: Array<Exchange>,
  alertType: number,
  updateAlertType: (alertType: number) => void,
  platformId: number
};

type State = {
  newAlertOptions: Array<{
    label: string,
    value: any,
    icon?: any,
    compareValue?: any
  }>,
  marketRangeOptions: Array<{
    label: string,
    value: any,
    icon?: any,
    compareValue?: any
  }>,
  newAlertLastPrice: number,
  activeTab: number
};

class AlertsDataLayer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      newAlertOptions: [
        {
          label: `${ this.props.t(`app:last`) } (0)`,
          value: 0
        },
        {
          label: `${ this.props.t(`app:high`) } (0)`,
          value: 0
        },
        {
          label: `${ this.props.t(`app:low`) } (0)`,
          value: 0
        },
        {
          label: `${ this.props.t(`app:ask`) } (0)`,
          value: 0
        },
        {
          label: `${ this.props.t(`app:bid`) } (0)`,
          value: 0
        }
      ],
      marketRangeOptions: [],
      newAlertLastPrice: 0,
      activeTab: 0
    };
  }

  getTickerData(exchange: Exchange, market: Market, callback: (price: number) => void) {
    this.getHistoricalRange(exchange, market);
    
    getTickerData(exchange.exchCode, market.marketName, (data) => {
      if (data.success) {
        let ticker = data.result;
        this.setState({
          newAlertOptions: Object.keys(ticker).filter((t) => t !== `volume`).map((t) => ({
            label: `${ t.charAt(0).toUpperCase() }${ t.slice(1) } ` + 
                   `(${ toFixedDecimals(ticker[t] || 0, false, `price`, market) })`,
            value: toFixedDecimals(ticker[t] || 0, false, `price`, market)
          })),
          newAlertLastPrice: ticker.last
        }, () => callback(ticker.last));
      }
    });
  }

  getHistoricalRange(exchange: Exchange, market: Market) {
    getMarketRange(exchange.exchCode, market.marketName, (data) => {
      if (data.success) {
        let range = data.result;
        let thirtyDayRange = range[`30D`];
        let sevenDayRange = range[`7D`];

        this.setState({
          marketRangeOptions: [
            {
              label: `All Time High (${ range.allTime.high })`,
              value: range.allTime.high
            },
            {
              label: `All Time Low (${ range.allTime.low })`,
              value: range.allTime.low
            },
            {
              label: `30 Day High (${ thirtyDayRange.high })`,
              value: thirtyDayRange.high
            },
            {
              label: `30 Day Low (${ thirtyDayRange.low })`,
              value: thirtyDayRange.low
            },
            {
              label: `7 Day High (${ sevenDayRange.high })`,
              value: sevenDayRange.high
            },
            {
              label: `7 Day Low (${ sevenDayRange.low })`,
              value: sevenDayRange.low
            }
          ]
        });
      }
    });
  }

  changeActiveTab(n: number) {
    this.setState({ activeTab: n });
  }

  render() {
    updateTitle(`Alerts`, this.props.platformId);

    return (
      <div className="cols alerts-page">
        <section>
          {
            this.state.activeTab == 0 && (
              <ActiveAlertsTable 
                markets={ this.props.markets }
                exchanges={ this.props.exchanges }
                getTickerData={ this.getTickerData.bind(this) }
                lastPrice={ this.state.newAlertLastPrice }
                changeActiveTab={ this.changeActiveTab.bind(this) } />
            )
          }
          {
            this.state.activeTab == 1 && (
              <HistoryAlertsTable 
                markets={ this.props.markets }
                exchanges={ this.props.exchanges }
                getTickerData={ this.getTickerData.bind(this) }
                changeActiveTab={ this.changeActiveTab.bind(this) } />
            )
          }
         
        </section>
      </div>
    );
  }
}



const mapStateToProps = (state) => ({
  alertType: state.markets.marketsAlertActiveType,
  platformId: state.userInfo.user.platformId
});

const mapDispatchToProps = (dispatch) => ({
  updateAlertType: (alertType) => dispatch(updateAlertActiveType(alertType))
});

export { AlertsDataLayer as PureAlertsDataLayer };
export default translate(`alerts`)(connect(mapStateToProps, mapDispatchToProps)(AlertsDataLayer));

// @flow
'use strict';

import { SET_APPLICATIONS_STATUSES } from '../index.js';

export function setApplicationsStatuses(payload: any) {
  return {
    type: SET_APPLICATIONS_STATUSES,
    payload
  };
}

// @flow
'use strict';

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import Tabs from '../utilities/Tabs.jsx';
import PillToggle from "../utilities/PillToggle.jsx";
import Button from '../utilities/Button.jsx';
import Trash from '../../svgs/Trash.jsx';
import { getMarketPair } from '../../helpers/MarketPairHelper';
import { toLocalDate } from '../../helpers/DateHelper.js';
import { changeMarket } from '../../actions/markets/changeMarket.js';
import type { ActiveAlert } from '../../types/ActiveAlert.js';
import type { Market } from '../../types/Market.js';
import type { Exchange } from '../../types/Exchange.js';
import { emitEvent, POPULATE_ALERT_FORM } from '../../helpers/EventHelper.js';
import { mapRawAlertsToActiveAlerts, mapRawHistoryAlertsToHistoryAlerts } from '../../helpers/MapAlertsHelper';
import * as alertApi from '../../helpers/api/AlertApi';
import DataTable from '../utilities/DataTable';

const API_POLL_MS = 90000;
const DATA_TABLE_MIN_HEIGHT = 200;
const NEW_ALERT_PRICE_HEIGHT = 470;
const NEW_ALERT_VOLUME_HEIGHT = 550;

export type Props = {
  t: any,
  markets?: Array<Market>,
  exchanges?: Array<Exchange>,
  active: {
    market: Market,
    exchange: Exchange
  },
  deleteAlert: (id: number) => boolean,
  marketsAreClickable: boolean,
  history: { push: (any) => void },
  changeMarket: (b: boolean) => void,
  alertsLastUpdateTime: number,
  refreshAlertsLastUpdateTime: () => void,
  height?: number,
  alertType: number
};

const MarketAlerts = (props: Props) => {
  const { active: { market, exchange }, alertsLastUpdateTime, refreshAlertsLastUpdateTime, height = 0, alertType } = props;
  const [activeTab, setActiveTab] = useState(0);
  const [exchFilter, setExchFilter] = useState(exchange.exchCode);
  const [mktFilter, setMktFilter] = useState(getMarketPair(market).toString());

  const getDataTableHeight = height < 700 ? DATA_TABLE_MIN_HEIGHT : (height - (alertType === 0 ? NEW_ALERT_PRICE_HEIGHT : NEW_ALERT_VOLUME_HEIGHT));

  useEffect(() => {
    setExchFilter(exchange.exchCode);
  }, [exchange.exchCode]);

  useEffect(() => {
    setMktFilter(getMarketPair(market).toString());
  }, [getMarketPair(market).toString()]);

  useEffect(() => {
    refreshAlertsLastUpdateTime();
  }, [exchFilter, mktFilter]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      refreshAlertsLastUpdateTime();
    }, API_POLL_MS);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const displayTime = (row: any, date: string) => {
    if (!row || !date) return null;
    return toLocalDate(date);
  };

  const displayDeleteButton = (row: any, id: number) => {
    if (!row || !id) return null;
    return (
      <Button
        type="cancel"
        onClick={ () => props.deleteAlert(id) }>
        { Trash(`Delete${ id }`) }
      </Button>
    );
  };

  const getUserAlerts = (currentPage: number, pageSize: number) => {
    return new Promise((resolve) => {   
      type optionType = {
        params: {
          PageSize: number,
          PageNumber: number,
          SearchTerm?: string,
          ExchMktId?: number
        }
      };

      const options: optionType = {
        params: {
          PageSize: pageSize,
          PageNumber: currentPage
        }
      };

      let searchTerm = ``;

      if (exchFilter !== `all` && mktFilter == `all`) {
        searchTerm = `${exchFilter}:`;
      }
  
      if (exchFilter == `all` && mktFilter !== `all`) {
        searchTerm = mktFilter;
      }
  
      if (exchFilter == `all` && mktFilter == `all`) {
        searchTerm = `all`;
      }

      if (searchTerm == `all`) {
        options.params.SearchTerm = ``;
      } else if (searchTerm == ``) {
        options.params.ExchMktId = market.exchmktId;
      } else if (searchTerm.length > 0) {
        options.params.SearchTerm = searchTerm;
      }

      if (activeTab === 0) {
        alertApi.getAlerts(options, (data) => {
          resolve({
            result: mapRawAlertsToActiveAlerts(data.result),
            totalRecords: data.totalRecords,
            totalPages: data.totalPages
          });
        });
      } else {
        alertApi.getAlertsHistory(options, (data) => {
          resolve({
            result: mapRawHistoryAlertsToHistoryAlerts(data.result),
            totalRecords: data.totalRecords,
            totalPages: data.totalPages
          });
        });
      }
    });
  };  
  
  const activeColumns = [
    {
      title: props.t(`app:market`),
      key: `market`,
      display: (row: ActiveAlert) => (
        <Link
          to={ `/markets/${ row.data.exchCode }/${ row.market }` }
          onClick={ (e) => {
            e.preventDefault();
            props.history.push(`/markets/${row.data.exchCode}/${row.market}`);
            setTimeout(() => emitEvent(POPULATE_ALERT_FORM, row), 0);
            props.changeMarket(true);
          } } >
          { `${ row.data.exchCode }:${ row.market }` }
        </Link>
      )
    },
    {
      title: props.t(`app:criteria`),
      key: `criteria`,
      nosort: true
    },
    {
      title: props.t(`app:time`),
      key: `time`,
      display: displayTime
    },
    {
      title: ``,
      key: `alertId`,
      nosort: true,
      display: displayDeleteButton.bind(this)
    }
  ];
  const historyColumns = [
    {
      title: props.t(`app:market`),
      key: `market`,
      display: (row: ActiveAlert) => (
        <Link
          to={ `/markets/${ row.data.exchCode }/${ row.market }` }
          onClick={ (e) => {
            e.preventDefault();
            props.history.push(`/markets/${row.data.exchCode}/${row.market}`);
            setTimeout(() => emitEvent(POPULATE_ALERT_FORM, row), 0);
            props.changeMarket(true);
          } } >
          { `${ row.data.exchCode }:${ row.market }` }
        </Link>
      )

    },
    {
      title: props.t(`app:criteria`),
      key: `criteria`,
      nosort: true
    },
    {
      title: props.t(`app:triggered`),
      key: `time`,
      display: displayTime
    }
  ];

  return (
    <div className="market-alerts">
      <div className="header">
        <PillToggle
          options={ [
            { label: props.active.exchange.exchCode, value: props.active.exchange.exchCode },
            { label: props.t(`app:all`), value: `all` }
          ] }
          value={ exchFilter }
          onChange={ (e, v) => setExchFilter(v) } />
        <PillToggle
          options={ [
            {
              label: getMarketPair(props.active.market).toString(),
              value: getMarketPair(props.active.market).toString()
            },
            { label: props.t(`app:all`), value: `all` }
          ] }
          value={ mktFilter }
          onChange={ (e, v) => setMktFilter(v) } />
      </div>
      <Tabs
        activeTab={ activeTab }
        tabPosition="center"
        onChangeTab={ (v) => setActiveTab(v) }
        tabNames={ [props.t(`alerts:activeAlerts`), props.t(`alerts:alertHistory`)] }>
        {
          [`activeAlerts`, `alertHistory`].map((item, i) => (
            <DataTable
              key={ i }
              id={ `market-alerts-${item}` }
              columns={ item == `activeAlerts` ? activeColumns : historyColumns }
              fetchData={ getUserAlerts }
              defaultSortBy={ `-time` }
              hidePagination={ true }
              isFullWidth={ false }
              hideSideBar={ true }
              forceRefetch={ alertsLastUpdateTime }
              wrapperHeight={ height > 0 ?  getDataTableHeight : undefined }/>
          ))
        }
      </Tabs>
    </div>
  );
};

const mapStateToProps = (state) => ({
  marketsAreClickable: state.browser.marketsAreClickable,
  alertType: state.markets.marketsAlertActiveType
});

const mapDispatchToProps = (dispatch) => ({
  changeMarket: (b) => dispatch(changeMarket(b))
});

export { MarketAlerts as PureMarketAlerts };
export default withRouter(translate(`markets`)(connect(mapStateToProps, mapDispatchToProps)(MarketAlerts)));


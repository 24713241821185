'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path fill-rule="evenodd" clip-rule="evenodd" d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill="currentColor"/><path d="M8.4438 9.55813H7.1348C7.10546 9.4188 7.0908 9.25747 7.0908 9.07413C7.0908 8.45813 7.36946 7.94113 7.9268 7.52313L8.4878 7.10513C8.7518 6.90713 8.8838 6.65047 8.8838 6.33513C8.8838 6.10047 8.79946 5.90247 8.6308 5.74113C8.46213 5.57247 8.22013 5.48813 7.9048 5.48813C7.5968 5.48813 7.35113 5.59447 7.1678 5.80713C6.98446 6.01247 6.8928 6.2508 6.8928 6.52213C6.8928 6.70547 6.9038 6.84113 6.9258 6.92913L5.3858 6.87413C5.35646 6.7348 5.3418 6.5808 5.3418 6.41213C5.3418 5.7448 5.5728 5.1838 6.0348 4.72913C6.50413 4.26713 7.12746 4.03613 7.9048 4.03613C8.74813 4.03613 9.40446 4.2488 9.8738 4.67413C10.3431 5.09947 10.5778 5.60913 10.5778 6.20313C10.5778 6.9878 10.2221 7.6478 9.5108 8.18313L9.0928 8.49113C8.88013 8.65247 8.7188 8.8138 8.6088 8.97513C8.4988 9.13647 8.4438 9.3308 8.4438 9.55813ZM6.8818 11.1641C6.8818 10.9001 6.97346 10.6765 7.1568 10.4931C7.34013 10.3025 7.5638 10.2071 7.8278 10.2071C8.0918 10.2071 8.31546 10.3025 8.4988 10.4931C8.68946 10.6765 8.7848 10.9001 8.7848 11.1641C8.7848 11.4208 8.68946 11.6445 8.4988 11.8351C8.31546 12.0185 8.0918 12.1101 7.8278 12.1101C7.5638 12.1101 7.34013 12.0185 7.1568 11.8351C6.97346 11.6445 6.8818 11.4208 6.8818 11.1641Z" fill="currentColor"/>`,
  {
    width: `16px`,
    height: `16px`,
    viewBox: `0 0 16 16`
  }
);
/* eslint-enable */

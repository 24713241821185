// @flow
'use strict';

import { SET_MARKET_SWITCHER_VERSION } from '../index.js';

export function setMarketSwitcherVersion(version: string) {
  return {
    type: SET_MARKET_SWITCHER_VERSION,
    payload: version
  };
}

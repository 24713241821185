// @flow
'use strict';

import { UPDATE_MARKETS_COLLAPSE_PREFS, SET_FIAT_CURRENCIES, UPDATE_PAGE_PREFS } from '../index.js';

export function updateRedisPrefs({ key, value }: { key: string, value: boolean }) {
  let payload = { };
  payload[key] = value;
  return {
    type: UPDATE_MARKETS_COLLAPSE_PREFS,
    payload
  };
}

export function batchUpdateRedisPrefs(payload: any) {
  return {
    type: UPDATE_MARKETS_COLLAPSE_PREFS,
    payload
  };
}

export function setFiatCurrencies(payload: any) {
  return {
    type: SET_FIAT_CURRENCIES,
    payload,
  };
}

export function updateRedisPrefPage({ key, value }: { key: string, value: any }) {
  const payload = { 
    key,
    value
  };
  return {
    type: UPDATE_PAGE_PREFS,
    payload
  };
}

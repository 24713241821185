// @flow
'use strict';

import { SET_USER_APPLICATIONS } from '../index.js';

export function setUserApplications(payload: any) {
  return {
    type: SET_USER_APPLICATIONS,
    payload
  };
}

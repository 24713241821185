'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path class="info" d="M8 15.333A7.333 7.333 0 1 1 8 .667a7.333 7.333 0 0 1 0 14.666zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12zm.667-3.333a.667.667 0 0 1-1.334 0V8a.667.667 0 0 1 1.334 0v2.667zM7.529 6.47a.667.667 0 1 1 .942-.942.667.667 0 0 1-.942.942z"/>`,
  {
    width: `1.5rem`,
    height: `1.5rem`,
    viewBox: `0 0 15 15`
  }
);
/* eslint-enable */

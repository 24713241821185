// @flow
'use strict';

import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const ProgressSpinner = ({ className = `` }: any) => {
  return (
    <div className={ `progress-spinner` }>
      <CircularProgress className={ className }/>
    </div>
  );
};

export default ProgressSpinner;

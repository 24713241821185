// @flow
'use strict';

import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TableHeaderItem } from '../DataTable';

export const TableHeader = ({ table, tableUnhover }: any) => {
  return (
    <thead onMouseLeave={ () => { tableUnhover && tableUnhover(); } }>
      <DndProvider backend={ HTML5Backend } context={ window }>
        { table.getHeaderGroups().map((headerGroup) => (
          <tr key={ headerGroup.id }>
            { headerGroup.headers.map((header) => (
              <TableHeaderItem key={ header.id } table={ table } header={ header }/>
            )) }
          </tr>
        )) }
      </DndProvider>
    </thead>
  );
};

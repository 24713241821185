// @flow
'use strict';

import React from 'react';
import Modal from '../utilities/Modal.jsx';

type Props = {
  t: any,
  setFaqsModalOpen: any,
};

const FaqsModal = (props: Props) => {
  const { t, setFaqsModalOpen } = props;

  return (
    <div className={ `faqs-modal` }>
      <Modal
        title={ t(`markets:executionsWithoutFees`)  }
        onClose={ () => setFaqsModalOpen(false)  }>
        <div className="faqs-modal">
          <p className="title">
            { t(`markets:adjustedTimeAndSaleTitle`) }
          </p>
          <p>
            { t(`markets:adjustedTimeAndSaleContent`) }
          </p>
          <p className="title">
            { t(`markets:timeAndSaleTitle`) }
          </p>
          <p>
            { t(`markets:timeAndSaleContent`) }
          </p>

          <p className="title">
            { t(`markets:orderNotFillTitle`) }
          </p>
          <p>
            { t(`markets:orderNotFillContent`) }
          </p>
          
          <p className="title">
            { t(`markets:ohlcChartTitle`) }
          </p>
          <p>
            { t(`markets:ohlcChartContent`) }
          </p>
          
          <p className="title">
            { t(`markets:limitOrderTitle`) }
          </p>
          <p>
            { t(`markets:limitOrderContent`) }
          </p>
        </div>
      </Modal>        
    </div>
  );
};

export default FaqsModal;

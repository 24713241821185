// @flow
'use strict';

import React, { useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { setKeyboardShortcut } from '../../actions/kb/setKeyboardShortcut.js';
import { resetAllBindings } from '../../actions/kb/resetAllBindings.js';
import Modal from './Modal.jsx';
import Button from './Button.jsx';
import keymap from '../../kb.js';

type Props = {
  t: any,
  close: () => void
};

const keyboard = [
    [`\``, `1`, `2`, `3`, `4`, `5`, `6`, `7`, `8`, `9`, `0`, `-`, `=`, `backspace`],
    [`tab`, `q`, `w`, `e`, `r`, `t`, `y`, `u`, `i`, `o`, `p`, `[`, `]`, `\\`],
    [`capslock`, `a`, `s`, `d`, `f`, `g`, `h`, `j`, `k`, `l`, `;`, `'`, `enter`],
    [`lshift`, `z`, `x`, `c`, `v`, `b`, `n`, `m`, `,`, `.`, `?`, `rshift`],
    [`ctrl`, `winkey`, `alt`, `space`, `alt`, `winkey`, `ctrl`]
  ], sizes = {
    tab: 1.5,
    backspace: 1.7,
    '\\': 1.5,
    capslock: 2,
    'enter': 2,
    lshift: 2.5,
    rshift: 2.5,
    space: 5.5
  };

const keyColors = {
  "esc": `hsla(76, 82.76%, 75%, 0.9)`,
  "0": `hsla(166, 100%, 75%, 0.9)`,
  "1": `hsla(222, 84.94%, 75%, 0.9)`,
  "2": `hsla(230, 67.31%, 75%, 0.9)`,
  "3": `hsla(224, 79.66%, 75%, 0.9)`,
  "4": `hsla(258, 78.28%, 75%, 0.9)`,
  "5": `hsla(356, 77.38%, 75%, 0.9)`,
  "6": `hsla(290, 100%, 75%, 0.9)`,
  "7": `hsla(33, 90.06%, 75%, 0.9)`,
  "8": `hsla(212, 84.93%, 75%, 0.9)`,
  "9": `hsla(215, 100%, 75%, 0.9)`,
  "`": `hsla(31, 83.43%, 75%, 0.9)`,
  "-": `hsla(145, 92.45%, 75%, 0.9)`,
  "=": `hsla(348, 76.69%, 75%, 0.9)`,
  "backspace": `hsla(299, 90.3%, 75%, 0.9)`,
  "tab": `hsla(130, 94.54%, 75%, 0.9)`,
  "q": `hsla(122, 94.23%, 75%, 0.9)`,
  "w": `hsla(183, 66.85%, 75%, 0.9)`,
  "e": `hsla(248, 100%, 75%, 0.9)`,
  "r": `hsla(106, 100%, 75%, 0.9)`,
  "t": `hsla(168, 77.51%, 75%, 0.9)`,
  "y": `hsla(88, 100%, 75%, 0.9)`,
  "u": `hsla(170, 78.99%, 75%, 0.9)`,
  "i": `hsla(195, 95.96%, 75%, 0.9)`,
  "o": `hsla(318, 76.26%, 75%, 0.9)`,
  "p": `hsla(111, 91.51%, 75%, 0.9)`,
  "[": `hsla(11, 93.69%, 75%, 0.9)`,
  "]": `hsla(175, 92.97%, 75%, 0.9)`,
  "\\": `hsla(335, 100%, 75%, 0.9)`,
  "capslock": `hsla(76, 82.76%, 75%, 0.9)`,
  "a": `hsla(277, 88.15%, 75%, 0.9)`,
  "s": `hsla(37, 82.61%, 75%, 0.9)`,
  "d": `hsla(143, 81.62%, 75%, 0.9)`,
  "f": `hsla(254, 89.37%, 75%, 0.9)`,
  "g": `hsla(162, 100%, 75%, 0.9)`,
  "h": `hsla(6, 100%, 75%, 0.9)`,
  "j": `hsla(134, 75.64%, 75%, 0.9)`,
  "k": `hsla(207, 84.93%, 75%, 0.9)`,
  "l": `hsla(340, 92.24%, 75%, 0.9)`,
  ";": `hsla(127, 100%, 75%, 0.9)`,
  "'": `hsla(273, 100%, 75%, 0.9)`,
  "enter": `hsla(84, 92.01%, 75%, 0.9)`,
  "lshift": `hsla(41, 88.69%, 75%, 0.9)`,
  "z": `hsla(105, 90.95%, 75%, 0.9)`,
  "x": `hsla(81, 88.99%, 75%, 0.9)`,
  "c": `hsla(71, 78.99%, 75%, 0.9)`,
  "v": `hsla(327, 87.16%, 75%, 0.9)`,
  "b": `hsla(163, 83.77%, 75%, 0.9)`,
  "n": `hsla(274, 70.15%, 75%, 0.9)`,
  "m": `hsla(9, 83.16%, 75%, 0.9)`,
  ",": `hsla(344, 92.52%, 75%, 0.9)`,
  ".": `hsla(283, 72.7%, 75%, 0.9)`,
  "?": `hsla(2, 100%, 75%, 0.9)`,
  "rshift": `hsla(109, 83.44%, 75%, 0.9)`,
  "ctrl": `hsla(3, 83.8%, 75%, 0.9)`,
  "winkey": `hsla(155, 100%, 75%, 0.9)`,
  "alt": `hsla(211, 76.42%, 75%, 0.9)`,
  "space": `hsla(101, 85.71%, 75%, 0.9)`
};

const KeyboardShortcutModal = (props: Props) => {
  const { t, close } = props;
  const [editingShortcut, setEditingShortcut] = useState(``);
  
  const _keymap = useSelector((state) => state.kb);

  const dispatch = useDispatch();

  const onShortcutClick = (editingShortcut) => {
    if (window.innerWidth <= 832) return;

    setEditingShortcut(editingShortcut);
  };

  const onKeySelect = (key) => {
    setEditingShortcut(``);
    dispatch(setKeyboardShortcut(editingShortcut, key));
  };


  let kb = {
    ...keymap,
    ..._keymap
  };

  let metakeys = [`enter`, `esc`, `winkey`, `alt`, `ctrl`, `capslock`, `rshift`, `lshift`, `backspace`];

  let reserved = Object.keys(kb).filter((k) => k !== editingShortcut).map((k) => {
    return kb[k];
  }).concat(metakeys);

  let highlight = reserved.filter((k) => !metakeys.includes(k));

  return (
    <Modal
      title={ t(`keyboardShortcuts`) }
      onClose={ close }>
      <div className={ `kb-viewer ${ editingShortcut.length > 0 ? `editing` : `` }` } data-testid={ `keyboard-legend-modal` }>
        {
          keyboard.map((row, i) => (
            <div key={ i } className="kb-row">
              {
                row.map((key, j) => (
                  <div 
                    key={ `${ key }${ j }`  } 
                    className={ `kb-key ${ !reserved.includes(key) ? `eligible` : `reserved` }` }
                    onClick={ () => {
                      if (editingShortcut.length == 0) return;

                      if (!reserved.includes(key)) onKeySelect(key);
                    } }
                    style={ { 
                      // $FlowIgnore: suppressing this error
                      flexGrow: sizes[key] || 1,
                      backgroundColor: highlight.includes(key) ? keyColors[key] : undefined,
                      color: highlight.includes(key) ? undefined : `transparent`
                    } }>
                    <span>
                      { key }
                    </span>
                  </div>
                ))
              }
            </div>
          ))
        }
      </div>
      {
        editingShortcut.length > 0 ? (
          <div className="editing-shortcut-wrapper">
            <p>
                Select a new key from above to use for 
              { 
                ` "` + editingShortcut
                  .replace(/\_/g, ` `)
                  .toLowerCase()
                  .split(` `)
                  .map((w) => w[0].toUpperCase() + w.substr(1, w.length).toLowerCase())
                  .join(` `) + `"`
              }
            </p>
          </div>
        ) : (
          <div>
            <div className="kb-shortcut-note">
              <p>
                  Select a key from below to modify or
                <Button
                  type="default"
                  onClick={ () => {
                    dispatch(resetAllBindings());
                    return true;
                  } }>
                    Reset all keys
                </Button>
              </p>
            </div>
            <div className="kb-shortcut-wrapper">
              {
                Object.keys(kb).filter((k) => k !== `ENTER` && k !== `ESCAPE`).map((k) => (
                  <div className="kb-shortcut" key={ k }>
                    <div>
                      <i 
                        style={ { backgroundColor: keyColors[kb[k]] } }
                        onClick={ () => onShortcutClick(k) }>
                        { kb[k] }
                      </i>
                    </div>
                    <div>
                      <span>
                        { 
                          k
                            .replace(/\_/g, ` `)
                            .toLowerCase()
                            .split(` `)
                            .map((w) => w[0].toUpperCase() + w.substr(1, w.length).toLowerCase())
                            .join(` `) 
                        }
                      </span>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        )
      }
    </Modal>
  );
  
};

export default connect()(KeyboardShortcutModal);

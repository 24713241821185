// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import Modal from '../utilities/Modal.jsx';

type Props = {
  t: any,
  label: string,
  confirm: () => void,
  close: () => void
};

const CancelOrderModal = (props: Props) => {
  const { t, confirm, close } = props;

  return (
    <Modal
      title={ t(`app:genericConfirm`) }
      onConfirm={ confirm }
      onClose={ close }
      confirmText={ t(`app:confirm`) }>
      <p>
        { t(`orders:orderCancelConfirm`) }
      </p>
    </Modal>
  );
};


export { CancelOrderModal as PureCancelOrderModal };
export default translate(`orders`)(CancelOrderModal);

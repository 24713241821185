// @flow
'use strict';

/**
 * It takes an array of markets and an array of exchanges and returns an array of exchanges that are
 * available for the markets
 * @param props - any - this is the props object that is passed to the component.
 * @returns An array of available exchanges depending on available markets.
 */

import { useState, useEffect } from 'react';

export const useVisibleExchanges = (props: any) => {
  const [visibleExchanges, setVisibleExchanges] = useState([]);
  const { visibleMarkets, exchanges } = props;

  useEffect(() => {
    const availableExchangesCodes = visibleMarkets.map((item) => item.exchCode);
    setVisibleExchanges(exchanges.filter((e) => {
      return availableExchangesCodes.includes(e.exchCode);
    }).sort((a, b) => {
      if (a.exchCode > b.exchCode) return 1;
      if (a.exchCode < b.exchCode) return -1;
      return 0;
    }));
  }, [visibleMarkets, exchanges]);

  return visibleExchanges;
};

// @flow
'use strict';

import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useInfiniteQuery } from '@tanstack/react-query';
import TextField from '../../utilities/TextField.jsx';
import PillToggle from "../../utilities/PillToggle.jsx";
import SelectField from '../../utilities/SelectField.jsx';
import ProgressSpinner from '../../utilities/ProgressSpinner.jsx';
import { MarketSearchList } from "../MarketSearch";
import { MarketSearchContextMenu, MARKET_SEARCH_CONTEXT_MENU_ID } from "../MarketSearch";
import { listenForEvent, removeEventListener, PAGE_SHORTCUT } from '../../../helpers/EventHelper.js';
import { getMarketPair } from '../../../helpers/MarketPairHelper.js';
import * as MarketsApi from '../../../helpers/api/MarketsApi';
import { changeMarket } from '../../../actions/markets/changeMarket.js';
import { pushToHistory } from '../../../actions/markets/pushToHistory.js';
import { setCurrentMarket } from '../../../actions/app/setCurrentMarket.js';
import { setMarketSwitcherFilters } from '../../../actions/app/setMarketSwitcherFilters.js';
import Search from "../../../svgs/Search.jsx";
import type { Exchange } from '../../../types/Exchange.js';
import type { Market } from '../../../types/Market.js';
import ImportExportIcon from '@mui/icons-material/ImportExport';
// import { isFullAccessUser } from '../../../helpers/UserApplicationsHelper.js';

type Props = {
  useCallback?: boolean,
  callback?: *,
  className: string,
  exchanges: Array<Exchange>,
  markets: Array<Market>,
  active: {
    exchange: Exchange,
    market: Market
  },
  useHeight: boolean,
  height: number,
  children: any
};

const PAGE_SIZE = 100;
const DEFAULT_CACHE_TIME = 5000;
const API_POLL_MS = 90000;

const RANGE_OPTIONS = [
  { label: `24H`, value: 0 },
  { label: `1W`, value: 1 },
  { label: `1M`, value: 2 },
  { label: `1Y`, value: 3 },
  { label: `All`, value: 4 },
];

const VIEW_OPTIONS = [
  { label: `Price`, value: `price` },
  { label: `RSI (7)`, value: `rsi7` },
  { label: `RSI (14)`, value: `rsi14` },
];

const PILL_OPTIONS = [
  { 
    value: 1,
    label: `Favorites`,
  },
  { 
    value: 2,
    label: `Recent`,
  },
  {
    value: 0,
    label: `All`,
  },
];

const SORT_OPTIONS = [
  {
    value: `0-0`,
    label: `▲ Volume`,
  },
  {
    value: `0-1`,
    label: `▼ Volume`,
  },  
  {
    value: `1-0`,
    label: `▲ Popularity`,
  },
  {
    value: `1-1`,
    label: `▼ Popularity`,
  },
  {
    value: `2-0`,
    label: `▲ Alphabetical A-Z`,
  },
  {
    value: `2-1`,
    label: `▼ Alphabetical Z-A`,
  },
  {
    value: `3-0`,
    label: `▲ Percent Change`,
  },
  {
    value: `3-1`,
    label: `▼ Percent Change`,
  },
];

const MarketSearch = (props: Props) => {
  const { markets: propMarkets, useCallback: propUseCallback = false, callback, className = ``, active, useHeight, height } = props;
  const [inputSearchValue, setInputSearchValue] = useState(``);
  const [filterText, setFilterText] = useState(``);
  const [filterOption, setFilterOption] = useState(0);
  const [range, setRange] = useState(3);
  const [sort, setSort] = useState(`1-1`);
  const [blockSize, setBlockSize] = useState(0);
  const [maxHeight, setMaxHeight] = useState(0);
  const [view, setView] = useState(`price`);
  // const [isFullAccess, setIsFullAccess] = useState(true);

  const marketsHistory = useSelector((state) => state.markets.history);
  const favorites = useSelector((state) => state.app.favorites);
  const marketsAreClickable =  useSelector((state) => state.browser.marketsAreClickable);
  const filters = useSelector((state) => state.app.marketSwitcherFilters);

  // const platformId = useSelector((state) =>  state.userInfo.user.platformId);

  // const prefSubscriptionExpires = useSelector((state) =>  state.userInfo?.userPrefs?.prefSubscriptionExpires);
  // const prefSubscriptionId = useSelector((state) =>  state.userInfo?.userPrefs?.prefSubscriptionId);
  // const prefTrialUsed = useSelector((state) =>  state.userInfo?.userPrefs?.prefTrialUsed);
  // const rehydrated = useSelector((state) =>  state._persist.rehydrated);
  
  const dispatch = useDispatch();
  const dispatchChangeMarket = (b) => dispatch(changeMarket(b));
  const dispatchPushToHistory = (e: string, m: string) => dispatch(pushToHistory(e, m));
  const dispatchSetCurrentMarket = (e: string, m: string) => dispatch(setCurrentMarket(e, m));
  const dispatchSetMarketSwitcherFilters = (o: any) => dispatch(setMarketSwitcherFilters(o));

  const history = useHistory();
  
  const ref = useRef();
  const refListContainer = useRef();

  const fetchData = (pageNumber = 1, pageSize = 100) => {
    const [sortValue, sortOrder] = sort.split(`-`);
    let exchMarketIds = ``;
    
    if (filterOption === 1) {
      exchMarketIds = favorites.map((f) => propMarkets.find((m) => m.marketName === f.marketName && m.exchCode === f.exchCode)?.exchmktId)
        .filter((s) => s)
        .join(`,`);
    } else if (filterOption === 2) {
      exchMarketIds = marketsHistory.map((f) => propMarkets.find((m) => m.displayName === f.displayName && m.exchCode === f.exchCode)?.exchmktId)
        .filter((s) => s)
        .join(`,`);
    }
    
    const searchTerm = [filterText.length > 1 ? filterText : ``, exchMarketIds.length > 0 ? `ExchMktIds:${exchMarketIds}` : ``]
      .filter((s) => s)
      .join(`&SearchTerm=`);

    const exchName = /* !isFullAccess ? `coinigy`: */ ``;

    return MarketsApi.getMarketSummaries(pageSize, pageNumber, parseInt(sortValue), parseInt(sortOrder), range, searchTerm , true, ``, exchName)
      .then((data) => (
        {
          ...data,
          result: data.success ? data.result : []
        }
      ));
  };


  const { data, fetchNextPage, isFetching, isLoading } =
  useInfiniteQuery(
    [range, filterText, filterOption, sort, favorites.length/* , isFullAccess */], //causes table to reset and fetch from new beginning
    async ({ pageParam = 0 }) => {
      if (fetchData) {
        return fetchData( pageParam + 1, PAGE_SIZE);
      }
    },
    {
      getNextPageParam: (_lastGroup, groups) => groups.length,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: DEFAULT_CACHE_TIME,
      initialData: [],
      refetchInterval: API_POLL_MS,
    }
  );  

  const markets = useMemo(
    () => data?.pages?.flatMap((page) => page?.result) ?? [],
    [data]
  );

  const totalRecords = data?.pages?.[0]?.totalRecords ?? 0;
  
  const totalFetched = markets.length;

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
        if (
          scrollHeight - scrollTop - clientHeight  < (clientHeight / 2) &&
          !isFetching && 
          totalFetched < totalRecords
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalRecords]
  );

  const activeMarketIndex = markets?.findIndex((m) => m.exchCode === active?.exchange?.exchCode && m.marketName === active?.market?.marketName) ?? -1;
 
  const updateFilterText = (text: string) => setFilterText(text);
 
  const nextMarket = () => {
    if (marketsAreClickable) {
      const m = markets[activeMarketIndex + 1];
      if (m) redirect(m);
    }
  };

  const prevMarket = () => {
    if (marketsAreClickable) {
      const m = markets[activeMarketIndex - 1];
      if (m) redirect(m);
    }
  };

  const nextExchange = () => {
    const m = markets.find((m,idx) => idx > activeMarketIndex && m.exchCode !== active?.exchange?.exchCode);
    if (m) redirect(m);
  };

  const prevExchange = () => {
    // $FlowIgnore: suppressing this error
    const m = markets?.findLast((m,idx) => idx < activeMarketIndex && m.exchCode !== active?.exchange?.exchCode);
    if (m) redirect(m);
  };

  const redirect = (market: Market) => {
    const mktString = getMarketPair(market).toString();
    history.push(`/markets/${ market.exchCode }/${ mktString }`);
  };

  const handleKeyboardShortcut = (e: any) => {
    if (!e || !e.detail || !e.detail.action) return;

    switch (e.detail.action) {
    case `FOCUS_MARKET_FILTER`:
      setTimeout(() => document.getElementById(`findMarkets`)?.focus(), 1);
      break;
    case `GO_TO_NEXT_MARKET`:
      nextMarket();
      break;
    case `GO_TO_PREVIOUS_MARKET`:
      prevMarket();
      break;
    case `GO_TO_NEXT_EXCHANGE`:
      nextExchange();
      break;
    case `GO_TO_PREVIOUS_EXCHANGE`:
      prevExchange();
      break;
    }
  };

  const updateListsHeight = (availableHeight) => setMaxHeight(availableHeight);

  useEffect(() => {
    if (!useHeight) {
      const element = ref?.current;
  
      if (!element) return;
  
      const observer = new ResizeObserver((entries) => {
        for (const entry of entries) {
          // $FlowIgnore: suppressing this error
          if (entry?.contentBoxSize) {
            const contentBoxSize = entry.contentBoxSize[0];
            setBlockSize(contentBoxSize?.blockSize);
          }
        }
      });
  
      observer.observe(element);
  
      return () => {
        // Cleanup the observer by unobserving all elements
        observer.disconnect();
      };
    }
  }, []);

  useEffect(() => {
    if (useHeight) setBlockSize(height);
  }, [useHeight, height]);

  useEffect(() => {
    if (!propUseCallback && marketsAreClickable) {
      listenForEvent(PAGE_SHORTCUT, handleKeyboardShortcut);
      
      return () => {
        removeEventListener(PAGE_SHORTCUT, handleKeyboardShortcut);
      };
    }
  }, [propUseCallback, marketsAreClickable, activeMarketIndex, markets]);

  useEffect(() => {
    updateListsHeight(blockSize - 100);
  }, [blockSize, markets.length]);

  useEffect(() => {
    if (!propUseCallback) {
      const mktString = getMarketPair(active.market).toString();
      dispatchPushToHistory(active.exchange.exchCode, mktString);
      dispatchSetCurrentMarket(active.exchange.exchCode, mktString);
      dispatchChangeMarket(true);
    }
  }, [propUseCallback, active.exchange.exchCode, active.market.displayName]);

  //a check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  useEffect(() => {
    fetchMoreOnBottomReached(refListContainer.current);
  }, [fetchMoreOnBottomReached]);

  useEffect(() => {
    if (filters?.type !== undefined && filters.type !== filterOption) setFilterOption(filters.type);
    if (filters?.range !== undefined && filters.range !== range) setRange(filters.range);
    if (filters?.sort !== undefined && filters.sort !== sort) setSort(filters.sort);
    if (filters?.view !== undefined && filters.view !== view) setView(filters.view);
  }, [filters]);

  const updateFilter = (filterObject) => {
    dispatchSetMarketSwitcherFilters(filterObject);
  };

  useEffect(() => {
    const id = setTimeout(() => updateFilterText(inputSearchValue), 600);

    return () => clearTimeout(id);
  }, [inputSearchValue]);

  // useEffect(() => { 
  //   if (rehydrated) {
  //     setIsFullAccess(isFullAccessUser(platformId, prefSubscriptionExpires, prefSubscriptionId, prefTrialUsed));
  //   }
  // }, [rehydrated, prefSubscriptionExpires, prefSubscriptionId, prefTrialUsed, platformId]);

  return (
    <>
      <div ref={ ref } className={ `market-search fade-in ${ className }` }>
        { props.children }
        <div className={ `market-search-header` }>
          <div className={ `filter-container` }>
            <TextField
              icon={ Search }
              label={ `Find markets` }
              name="findMarkets"
              value={ inputSearchValue }
              compact={ true }
              onDoubleClick={ () => setInputSearchValue(``) }
              onChange={ (e) => setInputSearchValue(e.target.value) }
              onEnter={ () => setInputSearchValue(``) }/>

            <div className={ `view` }>
              <SelectField
                searchable={ false }
                label={ `` }
                name={ `view` }
                value={ view }
                options={ VIEW_OPTIONS }
                hideCaret
                onChange={ (e, v) => updateFilter({ 'view': v }) }/>
            </div>

            <div className={ `range` }>
              <SelectField
                searchable={ false }
                label={ `` }
                name={ `range` }
                value={ range }
                options={ RANGE_OPTIONS }
                hideCaret
                onChange={ (e, v) => updateFilter({ 'range': v }) }/>
            </div>

            <div className={ `sort` }>
              <SelectField
                searchable={ false }
                label={ `` }
                name={ `sort` }
                value={ sort }
                options={ SORT_OPTIONS }
                hideValue
                hideCaret
                icon={ <ImportExportIcon sx={ { fontSize: `3rem`, /* rotate: `90deg` ,*/ cursor: `pointer` } }/> }
                onChange={ (e, v) => updateFilter({ 'sort': v }) }/>
            </div>
          </div>

          <div>
            <PillToggle
              options={ PILL_OPTIONS }
              value={ filterOption }
              onChange={ (e, v) => updateFilter({ 'type': v }) }/>
          </div>
        </div>

        <div
          ref={ refListContainer }
          className={ `market-search-list-container` }
          style={ { height: maxHeight, maxHeight, overflow: `auto` } }
          onScroll={ (e) => fetchMoreOnBottomReached(e.target) }>
          { !isLoading && 
            <MarketSearchList
              markets={ markets }
              favorites={ favorites }
              useCallback={ propUseCallback }
              callback={ callback }
              active={ active }
              refListContainer={ refListContainer }
              view={ view }/>
          }
          { !isLoading && !isFetching && markets.length === 0 && 
          <div className='no-data'>
            { `There is no data to show for this view.` }
          </div>
          }
          { isLoading || isFetching && <ProgressSpinner className={ `spinner` }/> }
        </div>
    
      </div>
      <MarketSearchContextMenu id={ MARKET_SEARCH_CONTEXT_MENU_ID } favorites={ favorites }/>
    </>
  );
};

export default MarketSearch;

// @flow
'use strict';

import { UPDATE_INSIGHTS_SECTIONS } from '../index.js';

export function updateInsightsSections(payload: string) {
  return {
    type: UPDATE_INSIGHTS_SECTIONS,
    payload
  };
}

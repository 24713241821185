import React from 'react';
import EntityTable from '../../utilities/EntityTable.jsx';

export const DetailsPendingDeposits = ({ t, mockedTableData }) => {
  return (
    <EntityTable
      columns={ [
        {
          title: t(`currency`),
          key: `currency`,
          display: () => (
            <></>
          )
        },
        {
          title: t(`available`),
          key: `available`,
          display: () => (
            <></>
          )
        },
        {
          title: t(`held`),
          key: `held`,
          display: () => (
            <></>
          )
        },
        {
          title: t(`total`),
          key: `total`,
          display: () => (
            <></>
          )
        },
        {
          title: t(`price`),
          key: `price`,
          display: () => (
            <></>
          )
        },
        {
          title: t(`usd`),
          key: `usd`,
          display: () => (
            <></>
          )
        },
        {
          title: t(`actions`),
          key: `actions`,
          display: () => (
            <></>
          )
        },
      ] }
      data={ mockedTableData || [] }
      filterText={ null }
      pageSize={ 100 }
      hidePagination={ true }
      noDataText={ `No data found` }/>
  );
};

// @flow
'use strict';

import React from 'react';
import Cloud from "../../../svgs/Cloud.jsx";
import { Link } from 'react-router-dom';

type MenuLinkProps = {
  closeDropdownMenus: any,
  isDevelopmentOrStaging: boolean,
  text: string,
}

export const CoinigyApiLink = (props: MenuLinkProps) => {
  const { isDevelopmentOrStaging, text, closeDropdownMenus } = props;

  if (isDevelopmentOrStaging) {
    return (
      <Link onClick={ () => closeDropdownMenus() } to="/settings/api">
        { Cloud }
        <span>
          { text }
        </span>
      </Link>
    );
  } else {
    return (
      <a
        href={ `${ window.WWW_URL }/user/account#api` }
        rel="noopener noreferrer"
        target="_blank">
        { Cloud }
        <span>
          { text }
        </span>
      </a>
    );
  }
};

// @flow
'use strict';

import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import Chart from './Chart.jsx';
import { getActiveTheme } from '../../helpers/ThemeHelper.js';

type Props = {
  onBalanceClick: ({ start: *, end: * }) => void,
  t: any,
  hasData: boolean,
  balanceHistoryData: Array<any>,
  theme: string,
  selectedQuoteCurrencyCode: string,
};

const BalancesLineChartComponent = ({ 
  onBalanceClick, 
  hasData,
  balanceHistoryData, 
  theme, 
  selectedQuoteCurrencyCode, 
  t 
}: Props) => {
  const gradientColor = `rgba(10, 85, 182, 0)`,
    color = `rgba(151,206,245, 0.5)`,
    data = balanceHistoryData || [],
    chartConfig = {
      type: `area`,
      name: t(`balances`),
      data: data.map((item) => [item.balanceDate, parseFloat(item.quoteBalance)]),
      marker: { symbol: `circle` },
      dataLabels: { padding: 0 },
      lineColor: `#789fff`,
      color: `#789fff`,
      fillColor: {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
        stops: [
          [0, color],
          [1, gradientColor],
        ]
      }
    },
    axesColor = getActiveTheme(theme, true)[`--body-text-color-alpha-54`],
    tickColor = getActiveTheme(theme, true)[`--body-text-color-alpha-8`],
    config = {
      legend: {
        enabled: false,
      },
      title: {
        text: ``,
      },
      tooltip: {
        backgroundColor: getActiveTheme(theme).body,
        borderColor: `transparent`,
        borderRadius: 4,
        style: {
          color: getActiveTheme(theme).gray2,
        },
        headerFormat: ``,
        pointFormat: `<span style="color:{point.color}">\u25CF</span> <b>{point.y} ` + 
                         `${ selectedQuoteCurrencyCode.toUpperCase() }</b><br/>`,
        valueDecimals: selectedQuoteCurrencyCode.toUpperCase() == `BTC` ? 8 : 2
      },
      plotOptions: {
        series: {
          animation: false,
          events: {
            click: (e) => onBalanceClick({
              start: +new Date(e.point.category) - 86400000,
              end: +new Date(e.point.category)
            })
          }
        }
      },
      chart: {
        backgroundColor: getActiveTheme(theme)[`--gray2-theme-alpha`],
        zoomType: `xy`,
        height: `40%`,
        id: `balanceChart`,
        resetZoomButton: {
          theme: {
            display: `block`
          }
        },
      },
      credits: {
        enabled: false
      },
      navigator: {
        enabled: false
      },
      scrollbar: {
        enabled: false
      },
      rangeSelector: {
        inputEnabled: false,
        buttonTheme: {
          visibility: `hidden`
        },
        labelStyle: {
          visibility: `hidden`
        }
      },
      series: [chartConfig],
      xAxis: {
        type: `datetime`,
        tickLength: 0,
        lineWidth: 1,
        lineColor: `${ axesColor }`,
        labels: {
          enabled: true,
          style: {
            color: `${ axesColor } !important`,
            fontSize: `1.2rem`
          },
          format: `{value:%b %e}`
        },
      },
      noData: {
        style: {
          fontWeight: `bold`,
          fontSize: `15px`,
          color: `#fff`
        }
      },
      yAxis: [{
        gridLineWidth: 1,
        tickLength: 0,
        tickWidth: 1,
        opposite: true,
        tickPosition: `inside`,
        tickAmount: 8,
        title: {
          enabled: false,
        },
        lineWidth: 1,
        lineColor: `transparent`,
        labels: {
          style: {
            color: `${axesColor} !important`,
            fontSize: `1.2rem`
          },
          formatter: function () {
            return this.value + ` ${ selectedQuoteCurrencyCode.toUpperCase() }`;
          }
        },
        gridLineColor: `${tickColor}`
      }]
    };
    
  return (
    <Chart hasData={ hasData } options={ config }/>
  );
};

BalancesLineChartComponent.displayName = `BalancesLineChart`;

const mapStateToProps = (state) => ({
  theme: state.redisPrefs.theme,
  selectedQuoteCurrencyCode: state.app.selectedQuoteCurrencyCode
});

export { BalancesLineChartComponent as PureBalancesLineChartComponent };
export default translate(`balances`)(connect(mapStateToProps)(BalancesLineChartComponent));

// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import { useSelector } from 'react-redux';
import { updateTitle } from '../helpers/BrowserTitleHelper.js';
import Key from '../svgs/Key.jsx';
import ProfileDataLayer from '../components/user/ProfileDataLayer.jsx';
import ScrollableArea from '../components/utilities/ScrollableArea.jsx';
import type { Market } from '../types/Market.js';
import type { Exchange } from '../types/Exchange.js';
import type { Currency } from '../types/Currency.js';
import type { Account } from '../types/Account.js';
import AccountsTable from '../components/user/AccountsTable.jsx';
import { AccountDetails } from '../components/user/AccountDetails/AccountDetails.jsx';
type Props = {
  t: any,
  exchanges: Array<Exchange>,
  currencies: Array<Currency>,
  markets: Array<Market>,
  accounts: Array<Account>,
  params: any,
  balances: any,
  refreshBalance: (authId: number) => void,
  isAccountsInit: boolean,
  isExchangesInit: boolean,
};

const AccountsPage = ({ t, markets, params: { tab }, exchanges, currencies, accounts, refreshBalance, isAccountsInit, isExchangesInit }: Props) => {
  const platformId = useSelector((state) => state.userInfo.user.platformId);
  updateTitle(`Accounts`,platformId);
  
  const props = {
    t,
    currencies,
    refreshBalance,
    isAccountsInit,
    isExchangesInit
  };

  const keysTab: Array<{
    name: string,
    text: string,
    v1?: string,
    icon: any,
    component?: any,
    hideFromNav?: boolean
  }> = [    
    {
      name: `accounts`,
      text: t(`header:accounts`),
      icon: Key,
      component: <AccountsTable { ...props } />,
    },
    
  ];

  // if (process.env.NODE_ENV === `development` || process.env.STAGING) {
  // Previously only available in development
  keysTab.push({
    name: `details`,
    text: `Account Details`,
    icon: Key,
    component: <AccountDetails { ...props } />
  });
  

  return (
    <div className="cols user-profile-cols">
      <section>
        <ScrollableArea>
          <ProfileDataLayer
            t={ t }
            activeTab={ keysTab.filter((t) => t.name == tab)[0] }
            markets={ markets }
            accounts={ accounts }
            exchanges={ exchanges }
            currencies={ currencies } />
        </ScrollableArea>
      </section>
    </div>
  );
};

export { AccountsPage as PureAccountsPage };
export default translate(`user`)(AccountsPage);

// @flow
'use strict';

import { PUSH_TO_HISTORY } from '../index.js';

export function pushToHistory(exchCode: string, displayName: string) {
  return {
    type: PUSH_TO_HISTORY,
    payload: { exchCode, displayName }
  };
}

// @flow
'use strict';

import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import TextField from '../utilities/TextField.jsx';
import EntityTable from '../utilities/EntityTable.jsx';
import Button from "../utilities/Button.jsx";
import Copy from '../../svgs/Copy.jsx';
import Twitter from '../../svgs/Twitter.jsx';
import Facebook from '../../svgs/Facebook.jsx';
import type { ProfileProps } from './ProfileDataLayer.jsx';
import { toLocalDate } from '../../helpers/DateHelper.js';
import { 
  emitEvent, 
  NOTIFICATION
} from '../../helpers/EventHelper.js';

const  MIN_DATE = `2010-01-01`;

const ReferralProgram = (props: ProfileProps) => {
  const [referralCodeFriend, setReferralCodeFriend ] = useState(``);
  const { t, userPrefs, referrals, subscriptionInfo, user } = props;

  const platform = user?.platformId === 1 ? `Lightspeed Crypto`: `Coinigy`;

  const copyToClipboard = () => {
    const toCopy: any = document.getElementById(`prefReferralCode`);
    if (toCopy) {
      toCopy.focus();
      toCopy.select();      
      document.execCommand(`copy`);
    }
  };

  const applyAccount = () => {
    // TODO: redeem referral rewards

    emitEvent(NOTIFICATION, {
      notification_id: new Date().getTime(),
      title: `Error`,
      title_vars: ``,
      message_raw: `There was an error trying to apply`,
      message: `There was an error trying to apply`,
      message_vars: ``,
      pinned: false,
      style: `error`,
      url: ``
    });

    return false;
  };

  const addFriendReferralCode = () => {
    // TODO: add friend referral code

    emitEvent(NOTIFICATION, {
      notification_id: new Date().getTime(),
      title: `Error`,
      title_vars: ``,
      message_raw: `There was an error trying to apply`,
      message: `There was an error trying to apply`,
      message_vars: ``,
      pinned: false,
      style: `error`,
      url: ``
    });

    return false;
  };  

  if (!userPrefs || !referrals || !subscriptionInfo) return ``;

  const columns = [
    {
      title: t(`signUpDate`),
      key: `referralDate`,
      display: (row, referralDate) => toLocalDate(referralDate)
    }, {
      title: t(`user`),
      key: `userCode`
    }, {
      title: t(`subscriptionPurchasedAbbreviated`),
      key: `referralCredited`
    }, {
      title: t(`purchaseDateAbbreviated`),
      key: `referralCreditedDate`,
      display: (row, referralCreditedDate) => referralCreditedDate > MIN_DATE ? toLocalDate(referralCreditedDate) : null
    }, {
      title: t(`referralPaidAbbreviated`),
      key: `referralPaid`
    },{
      title: t(`referralPaymentDateAbbreviated`),
      key: `referralPaidDate`,
      display: (row, referralPaidDate) => referralPaidDate > MIN_DATE ? toLocalDate(referralPaidDate) : null
    }, {
      title: t(`referralAmount`),
      key: `referralPayout`
    }
  ];

  return (
    <div className="profile-preferences">
      <h1 className="page-title">
        { t(`referralProgram`) }
      </h1>
      <section>
        <div className="referral">
          <h2>
            { t(`referralText1`) }
          </h2>

          <div className="referralCode">
            <h3>
              { t(`yourUniqueReferralLink`) }
            </h3>
            <TextField
              label={ `` }
              name="prefReferralCode"
              onChange={ () => { } }
              value={ `${ window.WWW_URL }/?r=${userPrefs.prefReferralCode}` } 
              readOnly/>
            <button
              id="buttonCopyReferralCode"
              className="icon"
              onClick={ () => copyToClipboard() }>
              { Copy }
            </button>
            <div className="social">
              <a 
                rel="noopener noreferrer" 
                target="_blank" 
                href={ 
                  `https://api.addthis.com/oexchange/0.8/forward/twitter/offer` + 
              `?url=https%3A%2F%2Fwww.coinigy.com%2F%3Fr%3Da70cda7a&pubid=ra-54fa0ed42b3b7c8a&` +
              `ct=1&title=Coinigy%20-%20Professional%20Tools%20For%20Cryptocurrency%20Traders&pco=tbxnj-1.0` }>
                { Twitter }
              </a>
              <a 
                rel="noopener noreferrer" 
                target="_blank" 
                href={ `https://api.addthis.com/oexchange/0.8/forward/facebook/offer` + 
              `?url=https%3A%2F%2Fwww.coinigy.com%2F%3Fr%3Da70cda7a&pubid=ra-54fa0ed42b3b7c8a&` + 
              `ct=1&title=Coinigy%20-%20Professional%20Tools%20For%20Cryptocurrency%20Traders&pco=tbxnj-1.0` }>
                { Facebook }
              </a>
              <a 
                rel="noopener noreferrer" 
                target="_blank" 
                href={ `https://www.addthis.com/bookmark.php` + 
                `?source=tbx32nj-1.0&v=300&url=https%3A%2F%2Fwww.coinigy.com%2F%3Fr%3Da70cda7a&` +
                `pubid=ra-54fa0ed42b3b7c8a&ct=1` + 
                `&title=Coinigy%20-%20Professional%20Tools%20For%20Cryptocurrency%20Traders&pco=tbxnj-1.0` }
                className="more">
                { `More` }
              </a>
            </div>
          </div>
          <p className="explanation">
            { t(`referralExplanation` , { platform })  } 
          </p>
        </div>

        <div className="rewards">
          <div className="balance-container">
            <h2>
              { `${t(`app:balance`)}:` }
            </h2>
            { subscriptionInfo.referralBalance !== `` && <h2 className="referral-balance">
              { `$${subscriptionInfo.referralBalance}` }
            </h2>
            }
          </div>
          <p className="explanation">
            <span>
              { t(`putRewardsToWork`, { platform })  } 
            </span>
            <br/>
            { ` ${t(`rewardsToWorkText`)} (${t(`redemptionMinimum`) })` }
          </p>
          <Button
            type="primary"
            onClick={ () => {
              applyAccount();
              return false;
            } }>
            { t(`applyToMyAccount`) }
          </Button>
          <h2>
            { t(`My History `) }
          </h2>
          <EntityTable
            columns={ columns }
            data={ referrals }
            defaultSortBy="referralDate"
            hidePagination={ true }
            pageSize={ `all` }/>
        </div>

        <div className="referred-code">
          <h2>
            { t(`whoReferredMe`) }
          </h2>
          <p>
            { t(`referredMeText1`) }
            <br/>
            { t(`referredMeText2`) }
          </p>
          <div className="friend-referral-code">
            <span>
              { t(`friendReferralCode`) }
            </span>
            <TextField
              label={ `` }
              name="referralCodeFriend"
              value={ referralCodeFriend }
              key="prefReferralCode"
              onChange={ (e) => setReferralCodeFriend(e.target.value) } />
            <Button
              type="primary"
              onClick={ addFriendReferralCode }>
              { t(`giveCredit`) }
            </Button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withRouter(ReferralProgram);

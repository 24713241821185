'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path fill-rule="evenodd" clip-rule="evenodd" d="M2 13V3C2 1.89543 2.89543 1 4 1H9.74869C10.2553 1 10.743 1.19224 11.1133 1.53789L13.3646 3.63911C13.7699 4.01736 14 4.54685 14 5.10122V13C14 14.1046 13.1046 15 12 15H4C2.89543 15 2 14.1046 2 13ZM3 3V13C3 13.5523 3.44772 14 4 14H12C12.5523 14 13 13.5523 13 13V6H10.5C9.67157 6 9 5.32843 9 4.5V2H4C3.44772 2 3 2.44772 3 3ZM12.6823 4.37016C12.8601 4.53612 12.9705 4.76029 12.9949 5H10.5C10.2239 5 10 4.77614 10 4.5V2.03209C10.1599 2.07361 10.3083 2.15444 10.431 2.26894L12.6823 4.37016ZM5.5 9C5.22386 9 5 9.22386 5 9.5C5 9.77614 5.22386 10 5.5 10L10.5 10C10.7761 10 11 9.77614 11 9.5C11 9.22386 10.7761 9 10.5 9L5.5 9ZM5 11.5C5 11.2239 5.22386 11 5.5 11L10.5 11C10.7761 11 11 11.2239 11 11.5C11 11.7761 10.7761 12 10.5 12L5.5 12C5.22386 12 5 11.7761 5 11.5ZM5.5 7C5.22386 7 5 7.22386 5 7.5C5 7.77614 5.22386 8 5.5 8L8.5 8C8.77614 8 9 7.77614 9 7.5C9 7.22386 8.77614 7 8.5 7L5.5 7Z" fill="currentColor"/>`,
  {
    width: `16px`,
    height: `16px`,
    viewBox: `0 0 16 16`
  }
);

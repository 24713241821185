// @flow
'use strict';

import { UPDATE_ACTIVE_BOARD_ID } from '../index.js';

export function updateActiveBoardId(id: number) {
  return {
    type: UPDATE_ACTIVE_BOARD_ID,
    payload: id
  };
}

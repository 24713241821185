// @flow
'use strict';

import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { flexRender, ColumnOrderState } from '@tanstack/react-table';
import DragHandleIcon from '@mui/icons-material/DragHandle';

export const TableHeaderItem = ({ header, table }: any) => {
  const { getState, setColumnOrder } = table;
  const { columnOrder } = getState();
  const ref = useRef(null);

  const canDrag = header.column.columnDef.header() && !header.column.getIsPinned();

  const reorderColumn = (draggedColumnId: string, targetColumnId: string, columnOrder: string[]): ColumnOrderState => {
    columnOrder.splice(
      columnOrder.indexOf(targetColumnId),
      0,
      columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0]
    );

    return [...columnOrder];
  };

  const [{ handlerId }, drop] = useDrop({
    accept: `data-table-th`,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex =  item.index;
      const hoverIndex = columnOrder.findIndex((co) => co === header.column.id);
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get horizontal middle
      // $FlowIgnore: suppressing this error
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the left
      // $FlowIgnore: suppressing this error
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;

      const leftwards = dragIndex > hoverIndex && hoverClientX > hoverMiddleX;
      const rightwards = dragIndex < hoverIndex && hoverClientX < hoverMiddleX;

      if (leftwards || rightwards){
        return;
      }

      const newColumnOrder = reorderColumn(item.id, header.column.id, columnOrder);
      setColumnOrder(newColumnOrder);

      item.index = hoverIndex;
    }
  });
  const [{ isDragging }, drag] = useDrag({
    type: `data-table-th`,
    item: () => {
      return canDrag ? { id: header.column.id, index: columnOrder.findIndex((co) => co === header.column.id ) } : null;
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });
  
  drag(drop(ref));  

  return (
    <th 
      ref={ ref }
      data-handler-id={ handlerId }
      className={ `${header.column.columnDef.meta.thClass || ``} ${header.column.getIsPinned() ? `pinned` : ``} ${isDragging ? `is-dragging`: ``}` }
      style={ { 
        left: header.column.getIsPinned() ? `calc(${header.column.getStart(`left`)}px - 1rem)` : ``,
        minWidth: header.column.getIsPinned() ? header.column.getSize() : `` 
      } }>
      { header.isPlaceholder ? null : 
        <div
          { ...{
            className: header.column.getCanSort() ? `cursor-pointer` : ``,
            onClick: !isDragging ? header.column.getToggleSortingHandler() : undefined,
          } }>          
          { flexRender(header.column.columnDef.header, header.getContext()) }
          
          { { asc: ` ▲`, desc: ` ▼` }[header.column.getIsSorted()] ?? null }
          
          { canDrag && 
            <div className={ `drag` }>
              <DragHandleIcon />
            </div>
          }
        </div>
      }
    </th>
  );
};

'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path class="message-square" d="M4.195 10.862a.667.667 0 0 1 .472-.195h8a.667.667 0 0 0 .666-.667V3.333a.667.667 0 0 0-.666-.666H3.333a.667.667 0 0 0-.666.666v9.058l1.528-1.53zM4.943 12L2.47 14.471A.667.667 0 0 1 1.333 14V3.333a2 2 0 0 1 2-2h9.334a2 2 0 0 1 2 2V10a2 2 0 0 1-2 2H4.943z"/>`,
  {
    width: `1.5rem`,
    height: `1.5rem`,
    viewBox: `0 0 15 15`
  }
);
/* eslint-enable */

// @flow
'use strict';

import { UPDATE_ALERT_SOUND } from '../index.js';

export function updateAlertSound(payload: string) {
  return {
    type: UPDATE_ALERT_SOUND,
    payload
  };
}

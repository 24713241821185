// @flow
'use strict';

import { listenForEvent, NOTIFICATION, DISMISS_NOTIFICATION } from '../helpers/EventHelper.js';

type Notification = {
  notification_id: number,
  message: string,
  message_vars: string,
  title: string,
  title_vars: string,
  sound: boolean,
  sound_id: string,
  sound_override: string,
  url?: string,
  message_raw?: string
};

const playSound = (soundId: string) => {
  if (soundId !== ``) {
    const audio = document.createElement(`audio`);
  
    audio.src = `${ window.WWW_URL }/assets/sounds/${ soundId }.wav`;
  
    audio.play();

    listenForEvent(DISMISS_NOTIFICATION, () => {
      if (audio && !audio.paused) {
        audio.pause();
        audio.currentTime = 0;
      }
    }, true);
  }
};

const showNotification = (n: Notification) => {
  const text = formatMessageWithVars(n.message, n.message_vars);
  const node = window.document.createElement(`div`);

  node.innerHTML = text.replace(/\>\</gi, `> <`);

  let body = node.textContent.trim();

  if (n.hasOwnProperty(`message_raw`)) {
    body = n.message_raw;
  }

  const notification = new window.Notification(formatMessageWithVars(n.title, n.title_vars), { 
    tag: `coinigy-${ n.notification_id.toString() }`,
    icon: `/assets/img/favicon.png`,
    body
  });

  notification.onclick = function(e) {
    if (n.url) {
      e.preventDefault();
      window.open(n.url, `_blank`);
    } else {
      window.focus();
    }
  };
};

const subscribeToNotifications = () => {
  listenForEvent(NOTIFICATION, (e: any) => {
    if (e.detail.sound) {
      playSound(e.detail.sound_override || e.detail.sound_id);
    }
  });

  if (!(`Notification` in window)) {
    return false;
  } else if (window.Notification.permission !== `denied`) {
    window.Notification.requestPermission((permission) => {
      if (permission === `granted`) {
        listenForEvent(NOTIFICATION, (e: any) => {
          showNotification(e.detail);
        });
      }
    });
  }
};

const formatMessageWithVars = (message: string, vars: string): string  => {
  return message.replace(/\%(\d+\$)?s/gi, (a, b) => {
    if (b) return vars.split(`,`)[parseInt(b.split(`$`)[0]) - 1];
    return vars;
  });
};

export { subscribeToNotifications, formatMessageWithVars };

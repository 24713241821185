// @flow
'use strict';

import React from 'react';
import { getActiveTheme } from './ThemeHelper.js';
import type { Market } from '../types/Market.js';
import exactMath from 'exact-math';

const trimZeroes = (n: string, force?: boolean = false) => {
  if (parseFloat(n) > 1 || force) {
    if (n.indexOf(`.`) < 0) return n;

    while (n[n.length - 1] === `0`) {
      n = n.substr(0, n.length - 1);

      if (n[n.length - 1] == `.`) {
        n = n.substr(0, n.length - 1);
        break;
      }
    }
  } 

  return n;
};

// truncates a decimal n to a certain number of digits without rounding up or down
const toFixedDown = (n: number, digits: any) => {
  if (isNaN(n)) return n.toString();
  return exactMath.floor(n, -digits).toFixed(digits);
};

const toFixedDecimals = (n: any, isBase?: boolean, marketPrecisionType?: string, market?: Market, forceFloor?: boolean) => {
  if (typeof n == `string`) n = parseFloat(n);

  //if (isNaN(n)) throw Error(`Invalid input for n: NaN`);

  if (market) {
    if (isBase) {
      switch(marketPrecisionType) {
      case `quantity`:
        if (forceFloor) {
          return trimZeroes(toFixedDown(n, 
            market.hasOwnProperty(`baseQuantityPrecision`) ? market.baseQuantityPrecision : 8
          ));
        }else {
          return trimZeroes(n.toFixed( 
            market.hasOwnProperty(`baseQuantityPrecision`) ? market.baseQuantityPrecision : 8
          ));
        }
        
      case `price`:
        return trimZeroes(n.toFixed(
          market.hasOwnProperty(`basePricePrecision`) ? market.basePricePrecision : 8
        ));
      case `balance`:
        return trimZeroes(n.toFixed(
          market.hasOwnProperty(`baseQuantityPrecision`) ? market.baseQuantityPrecision : 8
        ));
      }
    } else {
      switch(marketPrecisionType) {
      case `quantity`:
        return trimZeroes(n.toFixed(
          market.hasOwnProperty(`quoteQuantityPrecision`) ? market.quoteQuantityPrecision : 8
        ));
      case `price`:
        return trimZeroes(n.toFixed(
          market.hasOwnProperty(`basePricePrecision`) ? market.basePricePrecision : 8
        ));
      case `balance`:
        return trimZeroes(n.toFixed(
          market.hasOwnProperty(`basePricePrecision`) ? market.basePricePrecision : 8
        ));
      }
    }
  }

  return trimZeroes(n.toFixed(8));
};

const toFixedDecimalsHTML = (n: any, isBase?: boolean, marketPrecisionType?: string, market?: Market, z?: boolean, h?: boolean, appendText?: string, forcedPrecision?: number): any => {
  let q = trimZeroes(toFixedDecimals(n, isBase, marketPrecisionType, market), true);
  let r;
  let precision = 8;
  
  const isTwoDecimals = marketPrecisionType === `two_decimals` ? true : false;

  if (forcedPrecision) {
    precision = forcedPrecision;
  } else if (market) {
    if (isBase) {
      if (marketPrecisionType == `quantity`) precision = market.hasOwnProperty(`baseQuantityPrecision`) ? market.baseQuantityPrecision : 8;
      if (marketPrecisionType == `price` || isTwoDecimals) precision = market.hasOwnProperty(`basePricePrecision`) ? market.basePricePrecision : 8;
      if (marketPrecisionType == `balance`) precision = market.hasOwnProperty(`baseQuantityPrecision`) ? market.baseQuantityPrecision : 8;
    } else {
      if (marketPrecisionType == `quantity`) precision = market.hasOwnProperty(`quoteQuantityPrecision`) ? market.quoteQuantityPrecision : 8;
      if (marketPrecisionType == `price` || isTwoDecimals) precision = market.hasOwnProperty(`basePricePrecision`) ? market.basePricePrecision : 8;
      if (marketPrecisionType == `balance`) precision = market.hasOwnProperty(`basePricePrecision`) ? market.basePricePrecision : 8;
    }
  }

  if ((q.split(`.`)[1] && q.split(`.`)[1].length > precision)) r = `${ q }`;
  else if (q.indexOf(`.`) == -1) r = `${ q }.${ `0`.repeat(precision) }`;
  else r = `${ q }${ `0`.repeat(precision - q.split(`.`)[1].length) }`;

  r = formatLongNumber(r, isTwoDecimals);

  if (r.indexOf(`.`) > -1) {
    if (h) {
      r = `${ r.split(`.`)[0] }.${ r.split(`.`)[1] }`;
    } else {
      r = `${ r.split(`.`)[0] }.${ r.split(`.`)[1].replace(/([0+\s]+)$/g, `<i class="extra-zeros">$1</i>`) }`;
      r = r.replace(/\.\<i class="extra-zeros">([0+\s]+)<\/i>/, `<i class="extra-zeros">.$1</i>`);
      if(z){
        r = r.replace(`extra-zeros`, `hidden-zeros`);
      }
    }
  }

  if (h) {
    if (appendText) {
      return r + appendText;
    }else {
      return r;
    }
    
  } else {
    if (appendText) {
      return { __html: r + appendText };
    }else {
      return { __html: r };
    }
    
  }
};

const getSpreadFromBidAndAsk = (bid: number, ask: number, defaultLength?: number = 2) => {
  let spread = ask - bid, length = defaultLength;

  if (trimZeroes(toFixedDecimals(bid)).indexOf(`.`) > -1 &&
      trimZeroes(toFixedDecimals(bid)).split(`.`)[1].length > length) {
    length = trimZeroes(toFixedDecimals(bid)).split(`.`)[1].length;
  }
  if (trimZeroes(toFixedDecimals(ask)).indexOf(`.`) > -1 && 
      trimZeroes(toFixedDecimals(ask)).split(`.`)[1].length > length) {
    // length = ask.toString().split(`.`)[1].length;
    length = trimZeroes(toFixedDecimals(ask)).split(`.`)[1].length;
  }

  return spread.toFixed(length);
};

const formatLongNumber = (n: any, isTwoDecimals: boolean) => {
  
  if (n.toString().indexOf(`.`) > -1) {
    let [ints, decs] = n.toString().split(`.`);

    ints = ints.replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1,`);

    decs = isTwoDecimals ? 
      decs.replace(/(\d{2})/g, `$1 `) : 
      decs.replace(/(\d{4})/g, `$1 `) ;
   
    return  `${ ints }.${ isTwoDecimals ? decs.split(` `)[0] : decs }`; 
  }

  return n.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1,`);
};



const optimizeTextForWidth = (
  text: string,
  widthInEms: number, 
  isBolded?: boolean = false, 
  isMaxWidth?: boolean = false, 
  children?: any
) => {
  let widthOfTextInEms = text.split(``).map((c) => {
    if (getActiveTheme().bordersNoShadows) {
      return 0.5985714286;
    }

    if ([`1`,`2`,`3`,`4`,`5`,`6`,`7`,`8`,`9`,`0`].indexOf(c) > -1) {
      if (isBolded) return 0.56875;
      return 0.5625;
    } else if (c == `.`) {
      if (isBolded) return 0.28;
      return 0.2625;
    } else if (c == ` `) {
      return 0.25;
    } else if (c == `,`) {
      if (isBolded) return 0.22;
      return 0.195;
    } else {
      return 0;
    }
  }).reduce((a, t) => a + t, 0);

  let fontSize = widthInEms / widthOfTextInEms;

  if (isMaxWidth && fontSize > 1) return (
    <span>
      { children ? children : text }
    </span>
  );

  return (
    <span style={ { fontSize: `${ fontSize }em` } }>
      { children ? children : text }
    </span>
  );
};

/*
 Accepts any number/string that is in either scientific notation or number format
 and returns the decimal/number value as a string.

 An optional parameter for the amount
 of digits to return is specified to control number of decimal places to be returned.
 */
const convertScinotToDecimal = (value: any, digits?: number) => {
  value = parseFloat(value);

  var REGEX_SCIENTIFIC = /(\d+\.?\d*)e\d*(\+|-)(\d+)/;
  var valueString = value.toString();
  var result = REGEX_SCIENTIFIC.exec(valueString);
  var base;
  var positiveNegative;
  var exponents;
  var precision;
  var rVal;


  // is scientific
  if(result) {
    // [ '1e+32', '1', '+', '2', index: 0, input: '1e+32' ]
    base = result[ 1 ];
    positiveNegative = result[ 2 ];
    exponents = result[ 3 ];

    if(positiveNegative === `+`) {

      precision = parseInt(exponents);

      // base is a decimal
      if(base.indexOf(`.`) !== -1) {
        result = /(\d+\.)(\d)/.exec(base);

        // [ 2 ] == right side after decimal
        // [ 1 ] == left side before decimal
        // $FlowIgnore: suppressing this error
        precision -= result[ 2 ].length + result[ 1 ].length;

        rVal = base.split(`.`).join(``) + getZeros(precision);
      } else {
        rVal = base + getZeros(precision);
      }
    } else {
      precision = base.length + parseInt(exponents) - 1;

      // if it contains a period we want to drop precision by one
      if(base.indexOf(`.`) !== -1) {
        precision--;
      }

      // handle a max precision
      if(digits !== undefined) {
        precision = Math.min(precision, digits);
      }

      rVal = value.toFixed(precision);

      // check if it's only 0's for instance 0.000000 then just return 0
      if(/^0\.0+$/.test(rVal)) {
        rVal = `0`;
      }
    }
  } else if(!isNaN(value)) {
    if(digits) {
      rVal = value.toFixed(digits);
    } else {
      rVal = value.toString();
    }
  } else {
    rVal = value.toString();
  }

  return rVal;
};

function getZeros(count) {
  var rVal = ``;

  for(var i = 0; i < count; i++) {
    rVal += `0`;
  }

  return rVal;
}

function sanitizeNumberInputs (input: string, callback: any, trimDecimals?: number = 8) {
  // Will only run callback function if inputs is a STRING number positive and allows and trims decimals defaults to 8 decimals.
  const initialInput = input;
  if (initialInput === ``) {
    return callback(``);
  }
  if (/^\d+(\.\d*)?$/.test(initialInput)) {
    let result = initialInput;
    const wholeNumber = initialInput.split(`.`)[0];
    const decimals = initialInput.split(`.`)[1];

    //  Will replace initial '0' with next number in string, and will add '.' if necessary.
    if (wholeNumber[0] === `0` && wholeNumber.length === 2 && initialInput[1] !== `.`) {
      const wholeNumberNoInitialZero = wholeNumber.substring(1);
      result = wholeNumberNoInitialZero;
      if (initialInput[initialInput.length] === `.`) {
        result = wholeNumberNoInitialZero + `.`;
      }
    }

    // Will slice excessive decimals,
    if (decimals) {
      if (decimals !== `` && decimals.length > 0) {
        const slicedDecimals = decimals.slice(0, trimDecimals);
        result = wholeNumber + `.` + slicedDecimals;
      }
    } 

    return callback(result);
  }     
}

function countDecimals(value: any) {
  if (value == null || value.toString().indexOf(`.`) < 0) { 
    return 0;
  }else {
    if(Math.floor(parseFloat(value)) == parseFloat(value)) return 0;
    return value.toString().split(`.`)[1].length || 0; 
  }
}

function formatCommaSeparator(n: any) {
  var parts = n.toString().split(`.`);
  const numberPart = parts[0];
  const decimalPart = parts[1];
  const thousands = /\B(?=(\d{3})+(?!\d))/g;
  return numberPart.replace(thousands, `,`) + (decimalPart ? `.` + decimalPart : ``);
}

export { toFixedDecimals, toFixedDecimalsHTML, getSpreadFromBidAndAsk, formatLongNumber, optimizeTextForWidth,
  convertScinotToDecimal, countDecimals, sanitizeNumberInputs, toFixedDown, formatCommaSeparator };

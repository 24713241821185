// @flow
'use strict';

import React, { useEffect, useState } from 'react';
import { translate } from 'react-i18next';
import type { Account } from '../../types/Account.js';
import type { Exchange } from '../../types/Exchange.js';
import type { Market } from '../../types/Market.js';
import { getExchangeTradeApiVersion } from '../../helpers/ExchangeTradingHelper';
import {
  listenForEvent,
  removeEventListener,
  ORDER_ADDED,
  ORDER_ADDED_FAILED,
  PENDING_ORDER_ADDED,
  SILENT_NOTIFICATION,
} from '../../helpers/EventHelper.js';
import { getMarketFromPanelSettings } from '../../helpers/MarketPairHelper.js';
import { cancelV1Order, cancelV2Order } from '../../helpers/api/OrderApi.js';
import BalancesOrdersHistory from '../markets/BalancesOrdersHistory.jsx';

type Settings = {
  exchange: string,
  market: string,
};

type Props = {
  t: any,
  accounts: Array<Account>,
  exchanges: Array<Exchange>,
  markets: Array<Market>,
  settings: Settings,
  height: number,
};

const OrdersPanel = (props: Props) => {
  const { t, accounts, exchanges, markets, settings, height } = props;
  
  const [activeTab, setActiveTab] = useState(0);
  const [currentAuthId, setCurrentAuthId] = useState(null);
  const [currentExch, setCurrentExch] = useState(null);
  const [currentMarket, setCurrentMarket] = useState(null);
  
  const [exchFilter, setExchFilter] = useState(`all`);
  const [mktFilter, setMktFilter] = useState(`all`);
  const [authFilter, setAuthFilter] = useState(`all`);
  
  const [ordersLastUpdateTime, setOrdersLastUpdateTime] = useState(null);

  const changeActiveTab = (n: number) => {
    setActiveTab(n);
  };

  const getApiVersion = () => {
    return currentExch && getExchangeTradeApiVersion(currentExch);
  };

  const activeAccounts = accounts
    .filter((account: Account) => account.authVersion == getApiVersion()
      && account.authExchId === currentExch?.exchId
      && account.authTrade 
      && account.authActive)
    .sort((a, b) => {
      if (a.authNickname > b.authNickname) return 1;
      if (b.authNickname > a.authNickname) return -1;
      return 0;
    });


  const showMultiAuth = activeAccounts.length > 1;

  const getCurrentAuthIdValue = (force: boolean) => { 
    let initCurrentAuthId = !force && currentAuthId || 0;
    
    if (initCurrentAuthId === 0) { 
      initCurrentAuthId = ``;

      if (activeAccounts.length >= 1) {
        initCurrentAuthId = activeAccounts[0].authId;
      }

      setCurrentAuthId(initCurrentAuthId);
    }

    return initCurrentAuthId.toString();
  };

  const updateUserOrderFilters = (exchFilter: ?string, mktFilter: ?string, authFilter: ?string) => { 
    if (exchFilter) setExchFilter(exchFilter);

    if (mktFilter) setMktFilter(mktFilter);

    if (authFilter) {
      setAuthFilter(authFilter);
      if (showMultiAuth) setCurrentAuthId(authFilter);
    }
  };  

  const deleteOrder = (authId: number, orderId: number, exchCode: string) => {
    if (exchanges.some((e) => e.exchCode == exchCode && e.exchTradeEnabledV2 === true)) {
      cancelV2Order({ authId, orderId }, (data) => {
        if (data.success) refreshOrdersLastUpdateTime();
      });
    } else {
      cancelV1Order(orderId, () => {
        refreshOrdersLastUpdateTime();
      });
    }

    return true;
  };  

  const orderEventCallback = () => {
    refreshOrdersLastUpdateTime();
  };

  const silentNotificationCallback = (e: any) => {
    switch (e.detail.type) {
    case 221: // orders
      refreshOrdersLastUpdateTime();
      break;
    }
  };

  const refreshOrdersLastUpdateTime = () => {
    setOrdersLastUpdateTime(new Date().getTime());
  };

  useEffect(() => {
    setCurrentExch(exchanges.find((e) => e.exchCode === settings.exchange));
    setCurrentMarket(getMarketFromPanelSettings(markets, settings));
  }, [exchanges, markets, settings]);

  useEffect(() => {
    if (currentExch && currentMarket) {
      setAuthFilter(`all`);
      updateUserOrderFilters(currentExch.exchCode, currentMarket.displayName ,null);
      getCurrentAuthIdValue(true);
    }
  }, [currentExch?.exchCode, currentMarket?.displayName]);

  useEffect(() => {
    listenForEvent(ORDER_ADDED, orderEventCallback);
    listenForEvent(ORDER_ADDED_FAILED, orderEventCallback);
    listenForEvent(PENDING_ORDER_ADDED, orderEventCallback);
    listenForEvent(SILENT_NOTIFICATION, silentNotificationCallback);

    return () => {
      removeEventListener(ORDER_ADDED, orderEventCallback);
      removeEventListener(ORDER_ADDED_FAILED, orderEventCallback);
      removeEventListener(PENDING_ORDER_ADDED, orderEventCallback);
      removeEventListener(SILENT_NOTIFICATION, silentNotificationCallback);
    };

  }, [exchFilter, mktFilter, authFilter]);

  return (
    <section className='balances-orders-history-panel'>
      {
        currentExch && currentMarket && 
        (
          <BalancesOrdersHistory
            marketSwitcherOpen={ false }
            toggleOpen={ true }
            showLarger={ true }
            active={ { market: currentMarket, exchange: currentExch } }
            markets={ markets }
            activeBOHTab={ activeTab }
            updateBOHTab={ changeActiveTab }
            isFull={ true }
            exchFilter={ exchFilter }
            mktFilter={ mktFilter }
            authFilter={ authFilter }
            getCurrentAuthIdValue={ getCurrentAuthIdValue }
            updateUserOrderFilters={ updateUserOrderFilters }
            deleteOrder={ deleteOrder }
            balances={ [] }
            accounts={ accounts }
            exchanges={ exchanges }
            currentAuthId={ currentAuthId } 
            tabs={ [
              { key: `openOrders`, title: t(`orders:openOrders`) },
              { key: `orderHistory`, title: t(`orders:orderHistory`) }
            ] }
            showMultiAuth={ showMultiAuth }
            height={ height - 45 }
            ordersLastUpdateTime={ ordersLastUpdateTime } 
            refreshOrdersLastUpdateTime={ refreshOrdersLastUpdateTime }/>
        )
      }
      
    </section>
  );
};

OrdersPanel.getPanelTitle = (settings: Settings, markets: Array<Market>) => {
  const market = getMarketFromPanelSettings(markets, settings);

  return market && settings ? `${ market.exchName } - ${ market.displayName }` : `Orders`;
};


export default translate(`orders`)(OrdersPanel);

// @flow
'use strict';

import { SET_PREVIOUS_THEME } from '../index.js';

export function setPreviousTheme(payload: string) {
  return {
    type: SET_PREVIOUS_THEME,
    payload
  };
}


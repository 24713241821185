// @flow
'use strict';

import React, { useState } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { CURRENCY_OPTIONS } from "../../constants/balances.js";
import { selectQuoteCurrencyCode } from "../../actions/app/selectQuoteCurrencyCode.js";
import { setBalanceFilters } from '../../actions/balances/setBalanceFilters.js';
import { addZeroBalance, getBalanceTotal } from '../../helpers/BalancesHelper.js';
import { addWalletExchange } from '../../helpers/ExchangeTradingHelper.js';
import { toFixedDecimalsHTML } from "../../helpers/NumberHelper.js";
import CollapseLeft from '../../svgs/CollapseLeft.jsx';
import CollapseRight from '../../svgs/CollapseRight.jsx';
import type { Account } from '../../types/Account.js';
import type { Balance } from '../../types/Balance.js';
import type { Exchange } from '../../types/Exchange.js';
import type { Market } from '../../types/Market.js';
import BalancesSwitcher from '../balances/BalancesSwitcher.jsx';
import BalancesTable from '../balances/BalancesTable/index.jsx';
import SelectField from "../utilities/SelectField.jsx";

type Props = {
  t: any,
  accounts: Array<Account>,
  balances: Array<Balance>,
  balanceFilters: Array<Balance>,
  exchanges: Array<Exchange>,
  height: number,
  markets: Array<Market>,
  panelData: any,
  refreshBalance: (n: number, d: boolean) => Promise<any>,
  selectedQuoteCurrencyCode: string,
  selectQuoteCurrencyCode: (string) => void,
  setBalanceFilters: (balances: Array<Balance>) => void,
};

const BalancesPanel = (props: Props) => {
  const { t, accounts, balances: propsBalances, balanceFilters, exchanges: propsExchanges, markets, refreshBalance, selectedQuoteCurrencyCode, selectQuoteCurrencyCode, setBalanceFilters } = props;

  const [balanceSwitcherOpen, setBalanceSwitcherOpen] = useState(false);
  
  const onChangeCurrency = (currency: string) => {
    selectQuoteCurrencyCode(currency);
  };

  const onChangeFilter = (filters: Array<Balance>) => {
    setBalanceFilters(filters);
  };

  const toggleOpen = () => {
    setBalanceSwitcherOpen((prev) => !prev);
  };  

  /* 
  Inject "zero balance" object with the currency code "No Balances Detected."
  So user is able to select/de-select accounts even if they have no balances for a specific account. 
  **/
  const balances = addZeroBalance(accounts, propsBalances);

  const balanceTotal = toFixedDecimalsHTML(getBalanceTotal(balances, balanceFilters, selectedQuoteCurrencyCode), false, `balance`);

  const exchanges = addWalletExchange(propsExchanges);

  return (
    <div className="balances cols">
      <div className="heading heading-panel-body">
        <div className="total-balance">
          <div className="balance-value">
            <div className="total-balance-value">
              <span dangerouslySetInnerHTML={ balanceTotal } />
            </div>
          </div>
          <div className="balance-options">
            <SelectField
              label={ `` }
              name={ `currency` }
              options={ CURRENCY_OPTIONS }
              onChange={ (e, v) => onChangeCurrency(v) }
              value={ selectedQuoteCurrencyCode || `btc` }/>
          </div>
        </div>
      </div>
      <div className="main-content">
        <BalancesSwitcher
          className={ balanceSwitcherOpen ? `in` : `out` }
          accounts={ accounts }
          balances={ balances }
          exchanges={ exchanges }
          filters={ balanceFilters }
          refreshBalance={ refreshBalance }
          onChangeFilter={ (f) => onChangeFilter(f) }/>
        <a 
          className={ `collapse account-switcher-collapse ${ balanceSwitcherOpen ? `open` : `` } collapse-panel-body` } 
          data-testid={ `balance-switcher-toggle-container` } >
          <span
            onClick={ () => toggleOpen() }
            data-testid={ `balance-switcher-toggle` }
            className={ `vertical-text ${ balanceSwitcherOpen ? `panel-body-open` : `panel-body-close` } ` }>
            {
              balanceSwitcherOpen ?
                CollapseLeft(`balanceSwitcherOpen`) :
                CollapseRight(`balanceSwitcherOpen`)
            }
            {
              balanceSwitcherOpen ?
                `` :
                ` ` + t(`balances:accountsWallets`)
            }
            {
              balanceSwitcherOpen ?
                `` :
                CollapseRight(`balanceSwitcherOpen`)
            }
          </span>
        </a>
        <div className={ `main-section main-section-panel-body ${ balanceSwitcherOpen ? `` : `account-switcher-collapse` } ` }>
          <BalancesTable 
            accounts={ accounts }
            balances={
              balances.filter((filterBalance) => 
                !balanceFilters.find((balance) =>
                  balance.balanceAuthId === filterBalance.balanceAuthId &&
                      balance.balanceCurrId === filterBalance.balanceCurrId
                )
              ).filter((bal) => bal.balanceCurrId !== 0) 
            }
            exchanges={ exchanges }
            markets={ markets } />
        </div>
      </div>
    </div>
  );
};

BalancesPanel.getPanelTitle = () => {
  return `Balances`;
};

const mapStateToProps = (state) => ({
  balanceFilters: state.balances.filters,
  selectedQuoteCurrencyCode: state.app.selectedQuoteCurrencyCode
});

const mapDispatchToProps = (dispatch) => ({
  selectQuoteCurrencyCode: (currency) => dispatch(selectQuoteCurrencyCode(currency)),
  setBalanceFilters: (filters) => dispatch(setBalanceFilters(filters))
});

export default translate(`boards`)(connect(mapStateToProps, mapDispatchToProps)(BalancesPanel));

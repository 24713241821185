'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default () => SvgHelper(
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M9 16.875C13.3492 16.875 16.875 13.3492 16.875 9C16.875 4.65076 13.3492 1.125 9 1.125C4.65076 1.125 1.125 4.65076 1.125 9C1.125 13.3492 4.65076 16.875 9 16.875ZM12.608 8.108C13.0473 7.66866 13.0473 6.95634 12.608 6.517C12.1687 6.07766 11.4563 6.07766 11.017 6.517L7.875 9.65901L6.4205 8.2045C5.98116 7.76517 5.26884 7.76517 4.8295 8.2045C4.39017 8.64384 4.39017 9.35616 4.8295 9.7955L6.28401 11.25C7.16269 12.1287 8.58731 12.1287 9.46599 11.25L12.608 8.108Z" fill="#6FCF97"/>',
    {
        width: `18px`,
        height: `18px`,
        viewBox: `0 0 18 18`
    }
)
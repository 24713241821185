// @flow
'use strict';

import { SET_CURRENT_MARKET } from '../index.js';

export function setCurrentMarket(exchCode: string, displayName: string) {
  return {
    type: SET_CURRENT_MARKET,
    payload: { exchCode, displayName }
  };
}

const xlSize = {
  global: {
    "tabEnableFloat": true,
    "tabEnableRename": false,
    "tabEnableClose": false,
    "tabSetTabStripHeight": 32,
    // "borderSize": 350,
  },
  borders: [
    {
      "type": `border`,
      "location": `left`,
      // "minSize": 350,
      "selected": 0,
      "children": [
        {
          "type": `tab`,
          "id": `market-switcher`,
          "name": `Search`,
          "component": `market-switcher`,
          "enableFloat": false,
          "enableDrag": false,
        }
      ]
    }
  ],
  layout: {
    "type": `row`,
    "children": [
      {
        "type": `row`,
        "weight": 65,
        "children": [
          {
            "type": `tabset`,
            "weight": 75,
            "minWidth": 350,
            "minHeight": 150,
            "children": [
              {
                "type": `tab`,
                "id": `market-tv-chart`,
                "name": `Candlestick Chart`,
                "component": `market-tv-chart`,
                "enableFloat": false,
              },
              {
                "type": `tab`,
                "id": `market-depth-chart`,
                "name": `Depth Chart`,
                "component": `market-depth-chart`,
                "enableFloat": false,
              }
            ]
          },
          {
            "type": `tabset`,
            "weight": 25,
            "minWidth": 650,
            "minHeight": 32,
            "children": [
              {
                "type": `tab`,
                "id": `balances-orders-history`,
                "name": `Balances & Orders`,
                "component": `balances-orders-history`,
                "enableFloat": false,
              }
            ]
          }
        ]
      },
      {
        "type": `row`,
        "weight": 35,
        "children": [
          {
            "type": `tabset`,
            "weight": 5,
            "minWidth": 150,
            "minHeight": 75,
            "children": [
              {
                "type": `tab`,
                "id": `market-header`,
                "name": ``,
                "component": `market-header`,
                "enableFloat": false,
              }
            ]
          },
          {
            "type": `tabset`,
            "weight": 95,
            "selected": 0,
            "minWidth": 350,
            "minHeight": 150,
            "children": [
              {
                "type": `tab`,
                "id": `market-time-sales`,
                "name": `Time & Sales`,
                "component": `market-time-sales`,
                "enableFloat": false,
              },
              {
                "type": `tab`,
                "id": `market-trades`,
                "name": `Trade`,
                "component": `market-trades`,
                "enableFloat": false,
              },
              {
                "type": `tab`,
                "id": `market-alerts`,
                "name": `Alerts`,
                "component": `market-alerts`,
                "enableFloat": false,
              },
              {
                "type": `tab`,
                "id": `market-insights`,
                "name": `Insights`,
                "component": `market-insights`,
                "enableFloat": false,
              },
              {
                "type": `tab`,
                "id": `market-notes`,
                "name": `Notes`,
                "component": `market-notes`,
                "enableFloat": false,
              },
            ]
          }
        ]
      }
    ]
  }
};

const smallSize = {
  global: {
    "tabEnableFloat": true,
    "tabEnableRename": false,
    "tabEnableClose": false,
    "tabSetTabStripHeight": 32,
    // "borderSize": 350,
  },
  borders: [
    {
      "type": `border`,
      "location": `left`,
      // "minSize": 350,
      "selected": -1,
      "children": [
        {
          "type": `tab`,
          "id": `market-switcher`,
          "name": `Search`,
          "component": `market-switcher`,
          "enableFloat": false,
          "enableDrag": false,
        }
      ]
    }
  ],
  layout: {
    "type": `row`,
    "children": [
      {
        "type": `row`,
        "children": [
          {
            "type": `tabset`,
            "weight": 5,
            "minWidth": 150,
            "minHeight": 75,
            "children": [
              {
                "type": `tab`,
                "id": `market-header`,
                "name": ``,
                "component": `market-header`,
                "enableFloat": false,
              },
            ]
          },
          {
            "type": `tabset`,
            "weight": 95,
            "minWidth": 350,
            "minHeight": 150,
            "children": [
              {
                "type": `tab`,
                "id": `market-tv-chart`,
                "name": `Candlestick Chart`,
                "component": `market-tv-chart`,
                "enableFloat": false,
              },
              {
                "type": `tab`,
                "id": `market-depth-chart`,
                "name": `Depth Chart`,
                "component": `market-depth-chart`,
                "enableFloat": false,
              },
              {
                "type": `tab`,
                "id": `balances-orders-history`,
                "name": `Balances & Orders`,
                "component": `balances-orders-history`,
                "enableFloat": false,
              },
              {
                "type": `tab`,
                "id": `market-time-sales`,
                "name": `Time & Sales`,
                "component": `market-time-sales`,
                "enableFloat": false,
              },
              {
                "type": `tab`,
                "id": `market-trades`,
                "name": `Trade`,
                "component": `market-trades`,
                "enableFloat": false,
              },
              {
                "type": `tab`,
                "id": `market-alerts`,
                "name": `Alerts`,
                "component": `market-alerts`,
                "enableFloat": false,
              },
              {
                "type": `tab`,
                "id": `market-insights`,
                "name": `Insights`,
                "component": `market-insights`,
                "enableFloat": false,
              },
              {
                "type": `tab`,
                "id": `market-notes`,
                "name": `Notes`,
                "component": `market-notes`,
                "enableFloat": false,
              },
            ]
          },
        ]
      }
    ]
  }
};

export { xlSize, smallSize };

// @flow
'use strict';

import React, { useState, useEffect } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
// import { isFullAccessUser } from '../../helpers/UserApplicationsHelper.js';
import { getDeadCurrencies } from '../../helpers/api/CurrencyApi.js';
import Modal from '../utilities/Modal.jsx';
import UpgradeNow from '../user/UpgradeNow.jsx';
import CryptList from './CryptList.jsx';
import CryptDetail from './CryptDetail.jsx';

const TheCryptDataLayerComponent =  (props: any) => { 
  const { t, platformId, /* prefSubscriptionExpires, prefSubscriptionId, prefTrialUsed, */ subscriptionStatus, rehydrated } = props;
  const [isFullAccess, setIsFullAccess] = useState(true);
  const [currencies, setCurrencies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deadCurrency, setDeadCurrency] = useState(null);

  const { currCode } = useParams();

  const fetchData = () => {
    setIsLoading(true);
    getDeadCurrencies()
      .then((data) => {
        setIsLoading(false);
        setCurrencies(data.success ? data.result : []);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (currencies.length && currCode) {
      const dead = currencies.find((c) => c.currCode?.toLowerCase?.() === currCode?.toLowerCase?.());
      setDeadCurrency(dead);
    }
  }, [currencies, currCode]);

  // useEffect(() => { 
  //   if (rehydrated) {
  //     setIsFullAccess(isFullAccessUser(platformId, prefSubscriptionExpires, prefSubscriptionId, prefTrialUsed));
  //   }
  // }, [rehydrated, prefSubscriptionExpires, prefSubscriptionId, prefTrialUsed, platformId]);

  useEffect(() => { 
    if (rehydrated) {
      setIsFullAccess(platformId === 1 || ([`lifetime`, `active`].includes(subscriptionStatus) && platformId === 0));
    }
  }, [rehydrated, subscriptionStatus, platformId]);

  return (
    <div className='the-crypt-page'>
      { !isFullAccess &&
        <div className='upgrade-now-modal'>
          <Modal title={ `` } onClose={ () => {} }>
            <UpgradeNow t={ t }/>
          </Modal>
        </div>
      }
      {
        currCode ? 
          (deadCurrency?.currCode ? <CryptDetail currCode={ currCode } deadCurrency={ deadCurrency } t={ t } isFullAccess={ isFullAccess }/> : <></>)
          : 
          <CryptList currencies={ currencies } isLoading={ isLoading } isFullAccess={ isFullAccess }/>
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  platformId: state.userInfo.user.platformId,
  // prefSubscriptionExpires: state.userInfo?.userPrefs?.prefSubscriptionExpires,
  // prefSubscriptionId: state.userInfo?.userPrefs?.prefSubscriptionId,
  // prefTrialUsed: state.userInfo?.userPrefs?.prefTrialUsed,
  subscriptionStatus: state.userInfo?.subscriptionInfo?.subscriptionStatus,
  rehydrated: state._persist.rehydrated,
});

export { TheCryptDataLayerComponent as PureTheCryptDataLayerComponent };
export default translate(`theCrypt`)(connect(mapStateToProps, null)(TheCryptDataLayerComponent));

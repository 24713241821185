// @flow
'use strict';

import { SET_TICKER_PAUSED } from '../index.js';

export function setTickerPaused(payload: string) {
  return {
    type: SET_TICKER_PAUSED,
    payload
  };
}

'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path fill-rule="evenodd" clip-rule="evenodd" d="M10.9758 7.0747L10.7198 6.34486C10.0363 4.39548 8.17982 3 6 3C3.23858 3 1 5.23858 1 8C1 10.7614 3.23858 13 6 13H12C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C11.9144 7 11.8299 7.00356 11.7465 7.01051L10.9758 7.0747ZM6 14C2.68629 14 0 11.3137 0 8C0 4.68629 2.68629 2 6 2C8.61765 2 10.8438 3.67628 11.6635 6.01396C11.7744 6.00472 11.8867 6 12 6C14.2091 6 16 7.79086 16 10C16 12.2091 14.2091 14 12 14H6Z" fill="currentColor"/>`,
  {
    width: `16px`,
    height: `16px`,
    viewBox: `0 0 16 16`
  }
);
/* eslint-enable */




// @flow
'use strict';

import React, { useEffect, useRef, useState } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { initialState } from '../../reducers/redisPrefs.js';
import { updateRedisPrefPage } from '../../actions/redisPrefs/updateRedisPrefs.js';
import { getExchangesTags } from '../../helpers/api/ExchangeApi.js';
import { getSingleCurrency } from '../../helpers/api/CurrencyApi.js';
import useMarketsSummaries from '../../helpers/hooks/useMarketsSummaries.js';
import { updateTitle } from '../../helpers/BrowserTitleHelper.js';
import { subscribe, subscribeToAggregatedMarkets, unsubscribe } from '../../helpers/SocketClusterHelper.js';
import type { Account } from "../../types/Account.js";
import type { Balance } from "../../types/Balance.js";
import type { Market } from "../../types/Market.js";
import ScreenerTable from './ScreenerTable.jsx';
import UpgradeNow from '../user/UpgradeNow.jsx';
import Modal from '../utilities/Modal.jsx';
// import { isFullAccessUser } from '../../helpers/UserApplicationsHelper.js';

const API_POLL_MS = 90000, THROTTLE_MS = 500;
export type Props = {
  t: any,
  accounts: Array<Account>,
  balances: Array<Balance>,
  updateSettings: (n: any) => void,
  platformId: number,
  isAccountsInit: boolean, 
  isBalancesInit: boolean,
  settings: any,
  aggregatedMarkets: [],
  // prefSubscriptionExpires: string,
  // prefSubscriptionId: any,
  // prefTrialUsed: boolean,
  subscriptionStatus: string,
  favorites: Array<any>,
  markets: Array<Market>,
  rehydrated: boolean,
}

const ScreenerDataLayerComponent = (props: Props) => { 
  const { t, accounts, balances, updateSettings, platformId, isAccountsInit, isBalancesInit, aggregatedMarkets, 
    settings, /* prefSubscriptionExpires, prefSubscriptionId, prefTrialUsed, */ subscriptionStatus, rehydrated, favorites, markets: propMarkets } = props;
  const { pageSize, hideColumns, range, sort, sortOrder, myPositions, searchTerms, ignoreDead } = { ...initialState.prefPage.screener, ...settings };

  const [isReady, setIsReady] = useState(false);
  const [markets, setMarkets] = useState([]);
  const [marketsBalances, setMarketsBalances] = useState([]);
  const [marketsCurrencies, setMarketsCurrencies] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState(``);
  const [filters, setFilters] = useState({});
  const [pageNumberPositions, setPageNumberPositions] = useState({});
  const [intervalId, setIntervalId] = useState(null);
  const [isMarketsCurrenciesLoading, setIsMarketsCurrenciesLoading] = useState(false);
  const [exchangeTagsOptions, setExchangeTagsOptions] = useState([]);
  const [isFullAccess, setIsFullAccess] = useState(true);
  const isLoading  = useRef(true);
  const requestTime = useRef(null);
  const subscribedMarkets  = useRef([]);
  const accumTradesPrice  = useRef([]);

  updateTitle(t(`screener:title`), platformId); 

  const { getMarketsSummaries, getMarketsSummariesPositions } = useMarketsSummaries();

  const setIsLoading = (status) => {
    isLoading.current = status;
  };

  const convertSearchTermsToObject = (serachTerms: Array<string>) => {
    const filters = {};

    serachTerms.forEach((serachTerm) => {
      const st = serachTerm.split(`:`);
      if (st[0] === `Indicator`) {
        const indicatorFilters = st[1].split(`-`);

        if (indicatorFilters.length === 5) {
          const indicatorName = st[1].split(`-`, 3).join(``);
          filters[st[0]] = {
            ...filters[st[0]],
            [indicatorName]: {
              value: indicatorFilters[3],
              condition: indicatorFilters[4]
            }
          };
        }
      } else if ([`PercentBuys`, `ActivityScore`].includes(st[0])) {
        const percentBuysFilters = st[1].split(`-`);
  
        if (percentBuysFilters.length === 2) {
          filters[st[0]] = {
            value: percentBuysFilters[0],
            condition: percentBuysFilters[1]
          };
        }
      } else {
        filters[st[0]] = st[1];
      }
    });

    return filters;
  };

  const changeSettings = (valueObj: any) => {

    const hasValue = (propName) => {
      return valueObj[propName] !== undefined;
    };

    const miningAlgorithm = hasValue(`miningAlgorithm`) ? valueObj.miningAlgorithm : filters.MiningAlgorithm;
    const miningType = hasValue(`miningType`) ? valueObj.miningType : filters.MiningType;
    const exchangeTags = hasValue(`exchangeTags`) ? valueObj.exchangeTags : filters.ExchangeTags;
    const rsi7Min = hasValue(`rsi7Min`) ? valueObj.rsi7Min : filters.Indicator?.rsi7min;
    const rsi14Min = hasValue(`rsi14Min`) ? valueObj.rsi14Min : filters.Indicator?.rsi14min;
    const rsi7Hour = hasValue(`rsi7Hour`) ? valueObj.rsi7Hour : filters.Indicator?.rsi7hour;
    const rsi14Hour = hasValue(`rsi14Hour`) ? valueObj.rsi14Hour : filters.Indicator?.rsi14hour;
    const rsi7Day = hasValue(`rsi7Day`) ? valueObj.rsi7Day : filters.Indicator?.rsi7day;
    const rsi14Day = hasValue(`rsi14Day`) ? valueObj.rsi14Day : filters.Indicator?.rsi14day;
    const percentBuys = hasValue(`percentBuys`) ? valueObj.percentBuys : filters.PercentBuys;
    const activityScore = hasValue(`activityScore`) ? valueObj.activityScore : filters.ActivityScore;
    const favoriteMarkets = hasValue(`favoriteMarkets`) ? valueObj?.favoriteMarkets : filters.FavoriteMarkets;

    const searchMiningAlgorithm= miningAlgorithm && `MiningAlgorithm:${miningAlgorithm}`;
    const searchMiningType= miningType && `MiningType:${miningType}`;
    const searchExchangeTags = exchangeTags && `ExchangeTags:${exchangeTags}`;
    const searchRsi7Min = rsi7Min?.value && rsi7Min?.condition && `Indicator:rsi-7-min-${rsi7Min.value}-${rsi7Min.condition}`;
    const searchRsi14Min = rsi14Min?.value && rsi14Min?.condition && `Indicator:rsi-14-min-${rsi14Min.value}-${rsi14Min.condition}`;
    const searchRsi7Hour = rsi7Hour?.value && rsi7Hour?.condition && `Indicator:rsi-7-hour-${rsi7Hour.value}-${rsi7Hour.condition}`;
    const searchRsi14Hour = rsi14Hour?.value && rsi14Hour?.condition && `Indicator:rsi-14-hour-${rsi14Hour.value}-${rsi14Hour.condition}`;
    const searchRsi7Day = rsi7Day?.value && rsi7Day?.condition && `Indicator:rsi-7-day-${rsi7Day.value}-${rsi7Day.condition}`;
    const searchRsi14Day = rsi14Day?.value && rsi14Day?.condition && `Indicator:rsi-14-day-${rsi14Day.value}-${rsi14Day.condition}`;
    const searchPercentBuys = percentBuys?.value && percentBuys?.condition && `PercentBuys:${percentBuys.value}-${percentBuys.condition}`;
    const searchActivityScore = activityScore?.value && activityScore?.condition && `ActivityScore:${activityScore.value}-${activityScore.condition}`;
    const searchFavoriteMarkets = favoriteMarkets && `FavoriteMarkets:true`;

    const search = [
      searchMiningAlgorithm, searchMiningType, searchExchangeTags, searchRsi7Min, searchRsi14Min, searchRsi7Hour, 
      searchRsi14Hour, searchRsi7Day, searchRsi14Day, searchPercentBuys, searchActivityScore, searchFavoriteMarkets
    ].filter((s) => s);

    updateSettings({ 
      key: `screener`, 
      value: { 
        pageSize: valueObj?.pageSize || pageSize,
        hideColumns: valueObj?.hideColumns || hideColumns,
        myPositions: hasValue(`myPositions`) ? valueObj?.myPositions : myPositions,
        range: hasValue(`range`) ? valueObj?.range : range,
        sort: hasValue(`sort`) ? valueObj?.sort : sort,
        sortOrder: hasValue(`sortOrder`) ? valueObj?.sortOrder : sortOrder,
        ignoreDead: hasValue(`ignoreDead`) ? valueObj?.ignoreDead : ignoreDead,
        searchTerms: search, 
      } 
    });
  };

  const resetData = () => {
    setMarketsBalances([]);
    setMarketsCurrencies([]);
    setMarkets([]);
    setTotalRecords(0);
    setIsMarketsCurrenciesLoading(false);
    unsubscribeAllMarkets();
  };

  const getFavoriteMarketsExchMktId = () => {
    const exchMktIds = favorites?.map((f) => propMarkets.find((m) => m.marketName === f.marketName && m.exchCode === f.exchCode)?.exchmktId)
      .filter((s) => s)
      .join(`,`);

    return `ExchMktIds:${exchMktIds}`;
  };

  const getScreenerData = () => {
    requestTime.current = new Date().getTime();

    const getPage = () => {
      const lastPagination = pageNumberPositions[pageNumber -1];

      if (!myPositions || !lastPagination) return pageNumber+1;

      return lastPagination.offset ? lastPagination.page : lastPagination.page + 1;
    };

    //MUI-datatables starts at 0 
    const params = {
      accounts, 
      balances,
      requestId: requestTime.current,
      page: getPage(),
      pageSize: parseInt(pageSize), 
      range, 
      searchTerm: search, 
      sort, 
      sortOrder, 
      offset: myPositions && pageNumberPositions[pageNumber -1] ? pageNumberPositions[pageNumber -1].offset : 0,
      searchTerms: searchTerms.filter((s) => s.indexOf(`FavoriteMarkets:`) === -1),
      ignoreDead,
      favoriteMarkets: searchTerms.some((s) => s.indexOf(`FavoriteMarkets:`) > -1) ? getFavoriteMarketsExchMktId() : ``
    };

    if (myPositions) {
      if (accounts.length) {
        getMarketsSummariesPositions(params)
          .then((data) => {
            if (data.requestId === requestTime.current) {
              setMarketsBalances(data.balances);
              setMarkets(data.markets);
  
              // if there are more results -> pageNumber*pageSize + pageSize  (like show more)
              setTotalRecords(data.isLastPage ? pageNumber*parseInt(pageSize) + data.markets.length + data.offset : (pageNumber+1)*parseInt(pageSize) + parseInt(pageSize));
  
              setPageNumberPositions((prev) =>
                // $FlowIgnore: suppressing this error
                Object.keys(prev)
                  .filter((p: number) => p < pageNumber)
                  .reduce((accum, p) => { return { ...accum, [p]: prev[p] }; }, { [pageNumber]: { page: data.page, offset: data.offset } })
              );
  
              setIsLoading(false);
              getCurrencies(data.markets);
            }
          })
          .catch((err) => {
            if (err.requestId === requestTime.current) {
              resetData();
              setIsLoading(false);
            }
          });
      } else {
        resetData();
        setIsLoading(false);
      }

    } else {
      getMarketsSummaries(params)
        .then((data) => {
          if (data.requestId === requestTime.current) {
            setMarketsBalances(data.balances);
            setMarkets(data.markets);
            setTotalRecords(data.totalRecords);
            setIsLoading(false);
            getCurrencies(data.markets);
          }
        })
        .catch((err) => {
          if (err.requestId === requestTime.current) {
            resetData();          
            setIsLoading(false);            
          }
        });
    }
  };

  const getCurrencies = (mkts) => {
    const baseCurrencies = mkts?.map((market) => market.displayBaseCurrencyCode ?? market.baseCurrencyCode);
    const uniqueBaseCurrencies = [...new Set(baseCurrencies)];
    if (uniqueBaseCurrencies.length) {
      setIsMarketsCurrenciesLoading(true);
  
      Promise.all(uniqueBaseCurrencies.map((b) => getSingleCurrency(b)))
        .then((values) => {
          const currencies = values.map((v) => v.result ? v.result.data: ``);
          setMarketsCurrencies(currencies);
          setIsMarketsCurrenciesLoading(false);
        })
        .catch(() => {
          setMarketsCurrencies([]);
          setIsMarketsCurrenciesLoading(false);
        });
    }
  };  
  
  const changeMarketSubscription = (marketId: string, isSubscribe: boolean) => {    
    const subsObj = {
      type: `market`,
      data: marketId.split(`#`)
    };

    if (isSubscribe) {
      if (isFullAccess) subscribeMarket(subsObj);
    } else {
      unsubscribeMarket(subsObj);
    }
  };

  const getLSCXAggregatedMarkets = (marketName) => {
    return aggregatedMarkets?.filter((agM) => agM.marketName === marketName)[0].aggregatedMarkets.map((selectedAggregatedMarket) => {
      const [exchCode, marketName] = selectedAggregatedMarket.split(`:`);
      return {
        type: `market`,
        data: [exchCode, marketName]
      };
    });
  };

  const getTradeChannel = (exchCode: string, marketName: string) => {
    return `TRADE-${ exchCode }--${ marketName.replace(`/`, `--`) }`;
  };

  const subscribeMarket = (subsMarket) => {
    const marketId =  subsMarket.data.join(`#`);
    const isSubscribed = subscribedMarkets.current.some((sm) => sm === marketId);
    let marketsToSubscribe = [];

    if (subsMarket.data[0] === `DERI`) subsMarket.data[1] = subsMarket.data[1].substr(4, subsMarket.data[1].length);

    if (subsMarket.data[0] === `LSCX`) marketsToSubscribe = getLSCXAggregatedMarkets(subsMarket.data[1]);

    if (!isSubscribed) {
      subscribedMarkets.current.push(marketId);

      if (marketsToSubscribe.length) {  
        subscribeToAggregatedMarkets(marketsToSubscribe, { type: `market`, data: undefined }, (ch) => subscribeCallback(ch, { ...subsMarket, marketsToSubscribe }));
      } else {
        const tradeChannel = getTradeChannel(subsMarket.data[0], subsMarket.data[1]);
        subscribe([tradeChannel], (ch) => subscribeCallback(ch, subsMarket));
      }
    } 
  };

  const unsubscribeMarket = (subsMarket) => {
    const marketId =  subsMarket.data.join(`#`);
    const isSubscribed = subscribedMarkets.current.some((sm) => sm === marketId);
    let marketsToUnsubscribe = [];

    if (subsMarket.data[0] === `DERI`) subsMarket.data[1] = subsMarket.data[1].substr(4, subsMarket.data[1].length);

    if (subsMarket.data[0] === `LSCX`) marketsToUnsubscribe = getLSCXAggregatedMarkets(subsMarket.data[1]);

    if (isSubscribed) {
      let canUnsubscribe = true;

      subscribedMarkets.current = subscribedMarkets.current.filter((c) => c !== marketId);

      if (marketsToUnsubscribe.length) {
      // $FlowIgnore: suppressing this error
        marketsToUnsubscribe = marketsToUnsubscribe.filter((mts) => !subscribedMarkets.current.includes(mts.data.join(`#`)));

      } else {
        const tradeChannel = getTradeChannel(subsMarket.data[0], subsMarket.data[1]);
        marketsToUnsubscribe.push(tradeChannel);

        if (subscribedMarkets.current.some((sm) => sm.indexOf(`LSCX`) > -1 )) {
          canUnsubscribe = !aggregatedMarkets.some((am) => am.marketName === subsMarket.data[1] && am.aggregatedMarkets.includes(subsMarket.data.join(`:`)));
        }

        if (canUnsubscribe && subsMarket.data[0] === `DERI`) {
          canUnsubscribe = subscribedMarkets.current.every((sm) => {
            const _subsMarket =  sm.split(`#`);
            if (_subsMarket[0] !== `DERI`) return true;

            _subsMarket[1] = _subsMarket[1].substr(4, _subsMarket[1].length);
            return subsMarket.data[1] !== _subsMarket[1];
          });
        }        
      }

      if (canUnsubscribe) unsubscribe(marketsToUnsubscribe);
    } 
  };

  const unsubscribeAllMarkets = () => {
    const marketsToUnsubscribe = subscribedMarkets.current.slice(0);
    marketsToUnsubscribe.forEach((mId) => changeMarketSubscription(mId, false));
  };

  const subscribeCallback = (channels: any, market: any) => {
    for (const channel in channels) {
      const tradeChannel = channels[channel].channel || channels[channel].trades;
      if (tradeChannel) {
        tradeChannel.watch((d) => {

          const isAggregatedMarket = market.marketsToSubscribe && market.marketsToSubscribe.some((m) => m.data[0] === d.exchange && m.data[1] === d.label);

          if (!market.marketsToSubscribe || isAggregatedMarket) {

            const exchange = isAggregatedMarket ? market.data[0] : d.exchange;

            if (market.marketsToSubscribe) {
              market.marketsToSubscribe.reduce((accum,mkt) => [...accum, mkt.data[1]], []);
            }

            const acIdx = accumTradesPrice.current.findIndex((m) => m.exchCode === exchange && m.label === d.label);
            if (acIdx >= 0) {
              accumTradesPrice.current[acIdx].price = d.price;
              accumTradesPrice.current[acIdx].time = d.timestamp;
            } else {
              accumTradesPrice.current.push({ label: d.label, exchCode: exchange, price: d.price, time: d.timestamp });
            }
          }
        });
      }
    }
  };

  const updateMarketsPrice = () => {
    const trades = accumTradesPrice.current?.splice(0);

    if (trades.length) {
      setMarkets((prev) => prev.map((m) => {
        const t = trades.find((t) => m.exchCode === t.exchCode && m.marketName === t.label);
      
        if (t && m.lastTradePrice !== t.price) {
          m.lastTradePrice = t.price;
          if (m?.miniChartData?.length) m.miniChartData[m.miniChartData.length-1] = { price: t.price, time: t.time };

          if (t.price < m.low) m.low = t.price;

          if (t.price > m.high) m.high = t.price;
        } 
        return m;
      }));
    }
  };

  useEffect(() => {
    setIsReady(isAccountsInit && isBalancesInit && propMarkets.length > 0 && rehydrated);
  }, [isAccountsInit, isBalancesInit, propMarkets, rehydrated]);

  useEffect(() => {
    setFilters(convertSearchTermsToObject(searchTerms));
  }, [JSON.stringify(searchTerms)]);

  useEffect(() => {
    if (!isReady) return;

    setIsLoading(true);
    resetData();
    getScreenerData();
      
    const interval = setInterval(() => {
      if (!isLoading.current && isFullAccess)  getScreenerData();
    }, API_POLL_MS);
      
    setIntervalId(interval);

  }, [isReady, pageSize, pageNumber, sort, sortOrder, range, search, myPositions, JSON.stringify(searchTerms), ignoreDead]);

  useEffect(() => {
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [intervalId]);

  useEffect(() => {
    const marketsTradesIntervalId = setInterval(updateMarketsPrice, THROTTLE_MS);

    getExchangesTags()
      .then((data) => {
        if (data.success) {
          const tagOptions = data.result.reduce((accum, t) => [...accum, { label: t, value: t }], [{ value: ``, label: `` }]);
          setExchangeTagsOptions(tagOptions);
        }
      })
      .catch(() => setExchangeTagsOptions([{ value: ``, label: `` }]));

    return () => {
      clearInterval(marketsTradesIntervalId);
      unsubscribeAllMarkets();
    };
  }, []);

  // useEffect(() => { 
  //   if (rehydrated) {
  //     setIsFullAccess(isFullAccessUser(platformId, prefSubscriptionExpires, prefSubscriptionId, prefTrialUsed));
  //   }
  // }, [rehydrated, prefSubscriptionExpires, prefSubscriptionId, prefTrialUsed, platformId]);

  useEffect(() => { 
    if (rehydrated) {
      setIsFullAccess(platformId === 1 || ([`lifetime`, `active`].includes(subscriptionStatus) && platformId === 0));
    }
  }, [rehydrated, subscriptionStatus, platformId]);

  return (
    <div className="screener-page">
      <section>
        { !isFullAccess &&
        <div className='upgrade-now-modal'>
          <Modal title={ `` } onClose={ () => {} }>
            <UpgradeNow t={ t }/>
          </Modal>
        </div>
        }
        <ScreenerTable
          t={ t }
          markets={ markets }
          marketsBalances={ marketsBalances }
          marketsCurrencies={ marketsCurrencies }
          range={ range }
          totalRecords={ totalRecords }
          pageNumber={ pageNumber }
          setPageNumber={ setPageNumber }
          pageSize={ pageSize }
          searchTerm={ search } 
          setSearchTerm={ setSearch }
          sort={ sort }
          sortOrder={ sortOrder }
          myPositions={ myPositions }
          isLoading={ isLoading.current } 
          isMarketsCurrenciesLoading={ isMarketsCurrenciesLoading }
          hideColumns={ hideColumns }
          changeSettings={ changeSettings }
          changeMarketSubscription={ changeMarketSubscription }
          miningAlgorithm={ filters.MiningAlgorithm }
          miningType={ filters.MiningType }
          exchangeTags={ filters.ExchangeTags }
          exchangeTagsOptions={ exchangeTagsOptions }
          rsi7Min={ filters.Indicator?.rsi7min }
          rsi14Min={ filters.Indicator?.rsi14min }
          rsi7Hour={ filters.Indicator?.rsi7hour }
          rsi14Hour={ filters.Indicator?.rsi14hour }
          rsi7Day={ filters.Indicator?.rsi7day }
          rsi14Day={ filters.Indicator?.rsi14day }
          ignoreDead={ ignoreDead }
          percentBuys={ filters.PercentBuys }
          activityScore={ filters.ActivityScore }
          isFullAccess={ isFullAccess }
          favoriteMarkets={ !!filters.FavoriteMarkets }/>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({
  platformId: state.userInfo.user.platformId,
  settings: state.redisPrefs.prefPage?.screener,
  // prefSubscriptionExpires: state.userInfo?.userPrefs?.prefSubscriptionExpires,
  // prefSubscriptionId: state.userInfo?.userPrefs?.prefSubscriptionId,
  // prefTrialUsed: state.userInfo?.userPrefs?.prefTrialUsed,
  subscriptionStatus: state.userInfo?.subscriptionInfo?.subscriptionStatus,
  favorites: state.app.favorites,
  rehydrated: state._persist.rehydrated,
});

const mapDispatchToProps = (dispatch) => ({
  updateSettings: (p) => dispatch(updateRedisPrefPage(p)),
});

export { ScreenerDataLayerComponent as PureScreenerDataLayerComponent };
export default translate(`screener`)(connect(mapStateToProps, mapDispatchToProps)(ScreenerDataLayerComponent));

import React from 'react';
import { Link } from 'react-router-dom';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { toFixedDecimalsHTML } from '../../../helpers/NumberHelper.js';
import { setBalanceFormat } from "../../../helpers/BalancesHelper.js";
import { getMarketUrl } from '../../../helpers/MarketPairHelper.js';
import Trade from '../../../svgs/Trade.jsx';
import Button from '../../utilities/Button.jsx';

// Build table data
const buildTableData = (t, exchanges, accounts, balances, currency, openPopover, setOpenPopover, handleTradeClick, markets) => {
  
  if (balances.length > 0) {
    balances = balances.reduce((reducedBalances, balance, currentIndex) => {
      // Finds if any account is related to this balance
      const account = accounts.find((a) => a.authId == balance.balanceAuthId);
      // Finds if any exchange is related to this account
      const exchange = account ? exchanges.find((e) => e.exchId == account.authExchId) : null;
      
      const isWallet = exchange?.exchId === 999;

      const tradeMarketUrl = exchange && !isWallet && markets.length ? getMarketUrl(markets, exchange, balance.balanceCurrCode, currency.toUpperCase() ) : null;

      // Builds the collapsible table row with this balance account info
      const accountCollapsibleData =  {
        'exchangeIcon': (index) => (<td key={ index } className={ `align-right icon-col` }>
          { exchange && 
            <img 
              width={ 20 }              
              src={ `${ window.WWW_URL }/assets/img/exchange/${ exchange.exchCode }-icon.png` }
              onError={ (e) => {e.target.onerror = null; e.target.src=`${ window.WWW_URL }/assets/img/currency/empty.png`;} } /> 
          }
        </td>),
        'accountNickName': (index) => (<td key={ index } className={ `nickname-td` }>
          { exchange && account ? 
            <div> 
              { exchange.exchName }
              { ` ` }
              <span className={ `account-nickname` }>
                { account.authNickname }
              </span>                    
            </div> : null
          }
        </td>),
        'balanceAmountAvailable': (index) => (<td key={ index } className={ `align-right` }>
          <span dangerouslySetInnerHTML={ toFixedDecimalsHTML(balance.balanceAmountAvailable, true, setBalanceFormat(balance.balanceCurrCode)) } />
        </td>),
        'balanceAmountHeld': (index) => (<td key={ index } className={ `align-right` }>
          <span dangerouslySetInnerHTML={ toFixedDecimalsHTML(balance.balanceAmountHeld, true, setBalanceFormat(balance.balanceCurrCode)) } />
        </td>),
        'balanceAmountTotal': (index) => (<td key={ index } className={ `align-right` }>
          <span dangerouslySetInnerHTML={ toFixedDecimalsHTML(balance.balanceAmountTotal, true, setBalanceFormat(balance.balanceCurrCode)) } />
        </td>),
        'price': (index) => (<td key={ index } className={ `align-right` }>
          { !balance.lastTrade ? <Popover
            isOpen={ openPopover === currentIndex }
            positions={ [`right`] }
            content={ ({ position, childRect, popoverRect }) => (
              <ArrowContainer 
                position={ position }
                childRect={ childRect }
                popoverRect={ popoverRect }
                arrowColor={ `var(--gray-1)` }>
                <div className={ `popover-title` }>
                  { t(`balances:derivedExchangeRate`) }
                </div>
                <div className={ `popover-content` }>
                  { t(`balances:exchangeRate3DayVolume`) }
                </div>
              </ArrowContainer>
            ) }>
            <a
              onClick={ (e) => e.preventDefault() }
              onMouseEnter={ () => setOpenPopover(currentIndex) }
              onMouseLeave={ () => setOpenPopover(``) }>
              <img src={ `/assets/img/figma/Info.svg` } width="9" height="9" className={ `derived-exchrate-icon` } />
            </a>
            
          </Popover> : null }
          <span dangerouslySetInnerHTML={ toFixedDecimalsHTML(balance.lastTrade > 0 ? balance.lastTrade : balance.lastPrice, true, setBalanceFormat(balance.balanceCurrCode)) } />
        </td>),
        'currValue': (index) => (<td key={ index } className={ `align-right` }>
          <span dangerouslySetInnerHTML={ toFixedDecimalsHTML(balance.quoteBalance, true, setBalanceFormat(balance.balanceCurrCode)) } />
        </td>),
        "trade": (index) => ( <td key={ index } className={ `align-right action-col action-trade-col` }> 
          { tradeMarketUrl &&  <Link alt='trade' to={ tradeMarketUrl } onClick={ () => handleTradeClick(exchange.exchId, account.authId) }>
            <Button className={ `trade-button` } >
              <Trade />
              <label>
                { t(`app:trade`) } 
              </label>
            </Button>
          </Link> }  
        </td>),
      };

      // Finds if a balance with the current balance id was already added to the reduced balances array
      let e = reducedBalances.findIndex((a) => { return a.balanceCurrCode == balance.balanceCurrCode && a.balanceCurrId == balance.balanceCurrId; });

      const hasLastTrade = balance.lastTrade > 0;
      const hasLastPrice = balance.lastPrice > 0;

      // if the balance was already added we accumulate the values and add a row to the collapsible data
      if (e >= 0) {        
        // Gets the sums and weights to calculate the lastPriceAverageWeight by quoteBalance
        // if the balance lastPrice is zero it means that all the balances related to this market will be, so we set the values with 0
        // and then we avoid the divition
        let lastXSum = 0;
        if (hasLastTrade) {
          lastXSum = reducedBalances[e].lastXSum + (balance.lastTrade * balance.balanceAmountTotal);
        } else if (hasLastPrice) {
          lastXSum = reducedBalances[e].lastXSum + (balance.lastPrice * balance.balanceAmountTotal);
        } else {
          lastXSum = reducedBalances[e].lastXSum;
        } 
        
        const balanceAmountTotalSum =  reducedBalances[e].balanceAmountTotalSum + balance.balanceAmountTotal; 

        reducedBalances[e] = {
          ...reducedBalances[e],
          balanceAmountAvailable: reducedBalances[e].balanceAmountAvailable + balance.balanceAmountAvailable,
          balanceAmountHeld: reducedBalances[e].balanceAmountHeld + balance.balanceAmountHeld,
          balanceAmountTotal: reducedBalances[e].balanceAmountTotal + balance.balanceAmountTotal,
          lastXSum: lastXSum,
          balanceAmountTotalSum: balanceAmountTotalSum,
          lastPriceAvgWeight: lastXSum / balanceAmountTotalSum,
          quoteBalance: reducedBalances[e].quoteBalance + balance.quoteBalance,
          collapsibleData: reducedBalances[e].collapsibleData.concat(accountCollapsibleData)
        };
        return reducedBalances;

        // if not, a new balance is added to the reduced balances array
      } else {
        let lastXSum = 0;
        if (hasLastTrade) {
          lastXSum = balance.lastTrade * balance.balanceAmountTotal;
        } else if (hasLastPrice) {
          lastXSum = balance.lastPrice * balance.balanceAmountTotal;
        } 

        const balanceAmountTotalSum = balance.balanceAmountTotal; 

        balance = {
          ...balance, 
          balanceAuthId: -1,
          collapsibleData: [accountCollapsibleData],
          lastXSum: lastXSum, 
          balanceAmountTotalSum: balanceAmountTotalSum,
          lastPriceAvgWeight: lastXSum / balanceAmountTotalSum
        };
        return reducedBalances.concat(balance);
      }
    }, []);

    // Build main table columns
    const mainTableColumns = [
      {
        title: t(`app:currency`),
        key: `balanceCurrCode`,
        display: (row) => {
          const image = row.hasImage ? row.balanceCurrCode.toUpperCase() : `empty`;
          return (
            <div className='currency-col'>
              <img 
                src={ `${ window.WWW_URL }/assets/img/currency/${ image }.png` } 
                width={ 25 } />
              <span>
                <label className='curr-code'>
                  { row.balanceCurrCode }
                </label> 
                <label>
                  { row.balanceCurrName }
                </label>
              </span>
            </div>
          );
        },
        compareValue: (row) => [row.balanceCurrCode, row.balanceCurrName].join(`|`)
      },
      {
        title: t(`app:available`),
        key: `balanceAmountAvailable`,
        display: (row, price) => (
          <span dangerouslySetInnerHTML={ toFixedDecimalsHTML(price, true, setBalanceFormat(row.balanceCurrCode)) } />
        ),
        thClass: `align-right`,
        tdClass: `align-right`
      },
      {
        title: t(`app:held`),
        key: `balanceAmountHeld`,
        display: (row, price) => (
          <span dangerouslySetInnerHTML={ toFixedDecimalsHTML(price, true, setBalanceFormat(row.balanceCurrCode)) } />
        ),
        thClass: `align-right`,
        tdClass: `align-right`
      },
      {
        title: t(`app:total`),
        key: `balanceAmountTotal`,
        display: (row, price) => (
          <span dangerouslySetInnerHTML={ toFixedDecimalsHTML(price, true, setBalanceFormat(row.balanceCurrCode)) } />
        ),
        thClass: `align-right`,
        tdClass: `align-right`
      },
      {
        title: t(`app:price`),
        key: `lastPriceAvgWeight`,
        display: (row, price) => (
          <span dangerouslySetInnerHTML={ toFixedDecimalsHTML(price, true, setBalanceFormat(row.balanceCurrCode)) } />
        ),
        thClass: `align-right`,
        tdClass: `align-right`
      },
      {
        title: `${ currency?.toString()?.toUpperCase() } ${ t(`app:value`) }`,
        key: `quoteBalance`,
        display: (row, price) => (
          <span dangerouslySetInnerHTML={ toFixedDecimalsHTML(price, true, setBalanceFormat(row.balanceCurrCode)) } />
        ),
        thClass: `align-right`,
        tdClass: `align-right`
      }
    ];

    // Important! the collapsible columns array elements should match the collapsible data added to the balances array
    const collapsbileColumns = [ `exchangeIcon`, `accountNickName`, `balanceAmountAvailable`,`balanceAmountHeld`,`balanceAmountTotal`,`price`,`currValue`, `trade`];


    return { 
      balancesWithCollapsibleData: balances,
      mainTableColumns,
      collapsbileColumns
    };
  } else {
    return { 
      balancesWithCollapsibleData: [],
      mainTableColumns: [],
      collapsbileColumns: []
    };
  }
  
};


export default buildTableData;

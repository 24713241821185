// @flow
'use strict';

import React from 'react';
import { withRouter } from 'react-router-dom';
import ChoosePlan from './ChoosePlan.jsx';
import PlanPaymentDetails from './PlanPaymentDetails.jsx';
import PlanPurchaseComplete from './PlanPurchaseComplete.jsx';
import StepNavigations from '../utilities/StepNavigations.jsx';
import type { ProfileProps } from './ProfileDataLayer.jsx';

type State = {
  serviceHash: string,
  checkoutConfirmed: boolean
};

class ManageSubscription extends React.Component<ProfileProps, State> {
  constructor(props: ProfileProps) {
    super(props);

    this.state = {
      serviceHash: ``,
      checkoutConfirmed: false
    };
  }

  handleCheckout(billing: {
    name: string,
    street: string,
    city: string,
    state: string,
    zip: string,
    country: string,
    cc_num: string,
    cc_exp: string,
    cc_cvv: string
  }) {
    this.props.planCheckout && this.props.planCheckout(billing);
    this.setState({
      checkoutConfirmed: true
    });
  }

  handleStateChange(k: string, v: any) {
    this.setState({ [k]: v });
  }

  render() {
    let step;

    if (this.state.serviceHash.length == 0) {
      step = 1;
    } else {
      step = 2;
    }

    if (this.state.checkoutConfirmed) {
      step = 3;
    }

    return (
      <div className="manage-subscription">
        <StepNavigations
          currentStep={ step }
          data={ [this.props.t(`chooseAPlan`), this.props.t(`paymentDetails`), `Complete Purchase`] } />
        {
          step == 1 ? (
            <ChoosePlan
              t={ this.props.t }
              onChange={ this.handleStateChange.bind(this) }
              currentSubscription={ this.props.subscriptionInfo }
              subscriptionTypes={ this.props.subscriptionTypes } 
              cancelSubscription={ this.props.cancelSubscription } />
          ) : step == 2 ? (
            <PlanPaymentDetails
              t={ this.props.t }
              onChange={ this.handleStateChange.bind(this) }
              checkout={ this.handleCheckout.bind(this) }
              selectedSubscription={ 
                this.props.subscriptionTypes ? 
                  this.props.subscriptionTypes.find(({ serviceHash }) => serviceHash == this.state.serviceHash) :
                  undefined
              } />
          ) : step == 3 ? (
            <PlanPurchaseComplete
              t={ this.props.t }
              currentSubscription={ this.props.subscriptionInfo }
              history={ this.props.history } />
          ) : ``
        }
      </div>
    );
  }
} 

export default withRouter(ManageSubscription);

// @flow
'use strict';

import React, { useEffect, useMemo } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import * as orderApi from '../../helpers/api/OrderApi';
import { listenForEvent, NOTIFICATION, ORDER_ADDED, removeEventListener } from "../../helpers/EventHelper";
import { toLocalDate } from '../../helpers/DateHelper.js';
import { toFixedDecimals } from '../../helpers/NumberHelper.js';
import { translateOrder } from '../../helpers/OrderTransformationHelper.js';
import CancelOrderModal from '../orders/CancelOrderModal.jsx';
import PillToggle from "../utilities/PillToggle.jsx";
import Button from '../utilities/Button.jsx';
import TextField from '../utilities/TextField.jsx';
import OrderStatus from '../utilities/OrderStatus.jsx';
import Hint from '../utilities/Hint.jsx';
import Trash from "../../svgs/Trash.jsx";
import Search from "../../svgs/Search.jsx";
import { getExchangeTradeApiVersion } from '../../helpers/ExchangeTradingHelper';
import { isEmptyOrNull } from '../../helpers/ObjectHelper.js';
import type { Exchange } from '../../types/Exchange.js';
import useSetState from '../../helpers/useSetState';
import Checkbox from '../utilities/Checkbox.jsx';
import DataTable, { FILTER_CONTROL_SELECT } from '../utilities/DataTable';
import type { UserApplication } from '../../types/UserApplication.js';
import { getUserApplication } from '../../helpers/UserApplicationsHelper.js';

const NOTIFICATION_TYPES = [1, 2, 5, 20, 21, 22, 23, 84, 85, 86, 108, 151, 152, 153, 154, 155, 156, 157, 158, 159];

export type Props = {
  t: any,
  priceTypes: *,
  statusTypes: *,
  orderTypes: *,
  rehydrated: boolean,
  markets: any,
  exchanges: Array<Exchange>,
  userApplications: Array<any>,
  applicationsStatuses: Array<any>,
};

type State = {
  filterText: string,
  isTyping: boolean,
  cancelOrderModalOpen: boolean,
  cancelOrder: any,
  showCancelled: boolean,
  activeTab: number,
  lastUpdateTime: any,
};

const OrdersTable = (props: Props) => {
  const [state, setState] = useSetState < State > ({
    filterText: ``,
    editModalOpen: false,
    isTyping: false,
    cancelOrderModalOpen: false,
    cancelOrder: {},
    showCancelled: false,
    activeTab: 0,
    lastUpdateTime: 0,
  });

  const { rehydrated, userApplications, applicationsStatuses } = props;
  const { isTyping } = state;
  const userApplication: UserApplication = useMemo(() => getUserApplication(`LSCX`, userApplications, applicationsStatuses), [userApplications, applicationsStatuses]);

  const columns = [
    {
      title: props.t(`app:account`),
      key: `authNickname`,
    },
    {
      title: props.t(`app:exchange`),
      key: `exchange`,
      display: (row, exchange) => {
        return (
          <span className='cell-span'>
            <img
              className={ `exch-img` }
              src={ window.WWW_URL + `/assets/img/exchange/` + row.exchCode + `-icon.png` } 
              onError={ (e) => {e.target.onerror = null; e.target.src=`${ window.WWW_URL }/assets/img/currency/empty.png`;} } 
              width={ `20rem` }
              height={ `20rem` }/>
            { exchange }
          </span>
        );
      }
    },
    {
      title: props.t(`app:market`),
      key: `displayName`,
      display: (row, displayName) => {
        const logo = displayName?.split(`/`);
        return (
          <span className='cell-span gap'>
            { logo?.length >= 2 && 
            <span className='currencies-section'>
              <img
                src={ `${ window.WWW_URL }/assets/img/currency/${ logo[1] }.png` } 
                width={ 20 }
                height={ 20 }
                onError={  (e)=>{e.target.onerror = null; e.target.src=`${ window.WWW_URL }/assets/img/currency/empty.png`;} } />
              <img
                src={ `${ window.WWW_URL }/assets/img/currency/${ logo[0] }.png` }
                className='base-curr-logo'
                width={ 30 } 
                height={ 30 }        
                onError={ (e) => {e.target.onerror = null; e.target.src=`${ window.WWW_URL }/assets/img/currency/empty.png`;} } />
            </span>
            }
            { displayName }
          </span>
        );
      },
      compareValue: (row) => row.displayName
    },
    {
      title: props.t(`orderType`),
      key: `side`,
      display: (row: any, orderType: number) => props.orderTypes[orderType],
      filterControl: {
        type: FILTER_CONTROL_SELECT,
        options: Object.entries(props.orderTypes).map((orderType) => ({ 'value': orderType[0], 'label': orderType[1] }))
      }
    },
    {
      title: props.t(`priceType`),
      key: `orderType`,
      display: (row: any, priceType: number) => { 
        if (priceType === 3) {
          return props.t(`limit`);
        } else if (priceType === 5) {
          return props.t(`app:market`);
        } else if (priceType === 6) {
          return props.t(`stopLimit`);
        }
        return props.priceTypes[priceType];
      },
      filterControl: {
        type: FILTER_CONTROL_SELECT,
        options: userApplication.isVerified ? [
          { label: props.t(`app:market`), value: `5`, name: `marketOrderOption` },
          { label: props.t(`limit`), value: `3`, name: `LimitOption` },
          { label: props.t(`stopLimit`), value: `6`, name: `StopLimitOption` },
        ] : [
          { label: props.t(`limit`), value: `3`, name: `LimitOption` },
          { label: props.t(`stopLimit`), value: `6`, name: `StopLimitOption` },
        ]
      }
    },
    {
      title: props.t(`quantity`),
      key: `quantity`,
      thClass: `align-right`,
      tdClass: `align-right`,
      display: (row: any) => {
        const quantity = state.activeTab === 1 ? row.quantity - row.quantityRemaining : row.quantity;
        const market = props.markets.find((m) => m.exchCode == row.exchCode && m.displayName == row.displayName);
        return (
          <div className="align-right">
            {
              toFixedDecimals(quantity, true, `quantity`, market)
            }
          </div>
        );
      }
    },
    {
      title: props.t(`orderPrice`),
      key: `limitPrice`,
      display: (row, limitPrice) => {
        const price = props.statusTypes[row.status] === `Executed` && row.executedPrice !== null ? row.executedPrice : limitPrice;
        const market = props.markets.find((m) => m.exchCode == row.exchCode && m.displayName == row.displayName);
        return (
          <div className="align-right">
            {
              toFixedDecimals(price, true, `price`, market)
            }
          </div>
        );},
      thClass: `align-right`,
      tdClass: `align-right`
    },
    {
      title: props.t(`status`),
      key: `status`,
      display: (row: any, status: number) => {
        if (props.statusTypes[status] === `Stopped` && props.markets.length > 0) {
          const triggerMarket = props.markets.find((mkt) => mkt.exchmktId === row.triggerExchMktId);
          const activeMarket = props.markets.find((m) => m.exchCode == row.exchCode && m.displayName == row.displayName);
          const totalPrice = `${props.t(`app:total`)}: ${toFixedDecimals((parseFloat(row.limitPrice) * parseFloat(row.quantity)), true, `price`, activeMarket)} ${row.quoteCurrency}`;
          const content = `Trigger: ${triggerMarket.exchCode}:${row.displayName} @${row.stopPrice} | ` + totalPrice;
          return (
            <Hint
              position="right"
              content={ content }>
              <OrderStatus text={ props.statusTypes[status] } status={ status } />
            </Hint>
          );
        } else {
          return (
            <OrderStatus text={ props.statusTypes[status] } status={ status } />
          );
        }
      },
      filterControl: {
        type: FILTER_CONTROL_SELECT,
        options: Object.entries(props.statusTypes).map((statusType) => ({ 'value': statusType[0], 'label': statusType[1] }))
      }
    },
    {
      title: props.t(`app:time`),
      key: `orderTime`,
      display: (row, date) => toLocalDate(date),
      compareValue: (row) => toLocalDate(row.orderTime),
    },
    {
      title: props.t(`foreignId`),
      key: `foreignOrderId`
    },
    {
      title: props.t(`internalId`),
      key: `orderId`
    }
  ];


  const addColumnBeforeKey = (beforeKey, newColumn) => {
    const idx = columns.findIndex((col) => col.key === beforeKey);
    columns.splice(idx, 0, newColumn);
  };


  if (state.activeTab === 0) {
    columns.push({
      title: ``,
      key: `deleteOrder`,
      nosort: true,
      display: (row) => (
        <Button type="cancel" onClick={ () => openCancelOrderModal(row) }>
          { Trash(`Trash${row.orderId}`) }
          <span>
            { props.t(`app:cancel`) }
          </span>
        </Button>
      )
    });
  } else {
    const feeColumn =  {
      title: props.t(`fee`),
      key: `fee`,
      display: (row) => {
        const fee = row.fee !== null ? row.fee : `N/A`;
        return (
          <div className="align-right">
            { fee }
          </div>
        );},
      thClass: `align-right`,
      tdClass: `align-right`
    };

    addColumnBeforeKey(`status`, feeColumn);

    const totalColumn =  {
      title: props.t(`total`),
      key: `total`,
      display: (row) => {
        const market = props.markets.find((m) => m.exchCode == row.exchCode && m.displayName == row.displayName);
        const price = props.statusTypes[row.status] === `Executed` && row.executedPrice !== null ? row.executedPrice : row.limitPrice;
        const quantity = row.quantity - row.quantityRemaining;
        const total = price*quantity;
        return (
          <div className="align-right">
            { `${toFixedDecimals(total, true, `price`, market)} ${row.quoteCurrency}` }
          </div>
        );},
      thClass: `align-right`,
      tdClass: `align-right`,
      compareValue: (row) => {
        const price = props.statusTypes[row.status] === `Executed` && row.executedPrice !== null ? row.executedPrice : row.limitPrice;
        const quantity = row.quantity - row.quantityRemaining;
        return price*quantity;
      }
    };

    addColumnBeforeKey(`status`, totalColumn);
  }

  useEffect(() => {
    listenForEvent(NOTIFICATION, notificationHandler);
    listenForEvent(ORDER_ADDED, newOrderHandler);

    return () => {
      removeEventListener(NOTIFICATION, notificationHandler);
      removeEventListener(ORDER_ADDED, newOrderHandler);
    };
  }, []);


  useEffect(() => {
    let timer = null;

    if (rehydrated) {
      if (isTyping) {
        timer = setTimeout(() => setState({ isTyping: false }), 1000);
      } else {
        setState(() => ({ lastUpdateTime: new Date().getTime() }));
      }
    }

    return () => {
      if (timer) clearTimeout(timer);
    };

  }, [rehydrated, state.activeTab, isTyping]);

  function notificationHandler(e: any) {
    if (NOTIFICATION_TYPES.indexOf(e.detail.type) > -1) {
      setTimeout(() => setState(() => ({ lastUpdateTime: new Date().getTime() })), 500);
    }
  }

  function newOrderHandler() {
    setState(() => ({ lastUpdateTime: new Date().getTime() }));
  }

  function getData(currentPage: number, pageSize: number) {
    return new Promise((resolve) => {
      const options = {
        params: {
          pageSize,
          pageNumber: currentPage,
          SearchTerm: state.filterText,
        }
      };

      if (!state.showCancelled) {
        options.params = { ...options.params, Status: `Executed` };
      }

      if (state.activeTab === 0) {
        orderApi.getOrders(options, (data) => 
          resolve({
            result: data.result ? data.result.filter((order) => order.status !== 3).map(translateOrder) : [],
            totalRecords: data.totalRecords,
            totalPages: data.totalPages
          }));
      } else {
        orderApi.getOrderHistory(options, (data) => 
          resolve({
            result: data.result == undefined ? [] : data.result.map(translateOrder),
            totalRecords: data.totalRecords,
            totalPages: data.totalPages
          })
        );
      }
    });
  }

  function closeCancelOrderModal() {
    setState({ cancelOrderModalOpen: false, cancelOrder: {} });
  }

  function openCancelOrderModal(order: any) {
    setState({ cancelOrderModalOpen: true, cancelOrder: order });
    return true;
  }

  function handleInputChange(value: any, key: string, forceNumber?: boolean) {
    setState({
      [key]: forceNumber ? parseInt(value) : value
    });
  }

  function getApiVersion(exchanges: Array<Exchange>, cancelOrder: any) {
    const exch = exchanges.find((e) => e.exchCode === cancelOrder.exchCode);
    return  getExchangeTradeApiVersion(exch);
  }

  function cancelOrder() {
    const { cancelOrder } = state;
    if (!isEmptyOrNull(cancelOrder)) {
      const apiVersion = getApiVersion(props.exchanges, cancelOrder);
      if (apiVersion === 2) {
        orderApi.cancelV2Order({ authId: cancelOrder.authId, orderId: cancelOrder.orderId }, (data) => {
          if (data.success) {
            setState(() => ({ lastUpdateTime: new Date().getTime() }));
          }
        });
      } else {
        orderApi.cancelV1Order(cancelOrder.orderId, () => {
          setState(() => ({ lastUpdateTime: new Date().getTime() }));
        });
      }
      setState({ cancelOrderModalOpen: false, cancelOrder: {} });
    }

    return false;
  }

  return (
    <>
      <div className="header">
        <div className="title-holder">
          <h1 className="page-title">
            { props.t(`header:orders`) }
          </h1>
        </div>
        <div className="nav-holder">
          { state.activeTab === 1 &&
          <div className='cancelled-orders-checkbox'>
            <Checkbox 
              label={ props.t(`orders:showCancelled`) } 
              name="show-cancelled" 
              data-testid='show-cancelled' 
              onChange={ () => setState((state) => ({ showCancelled: !state.showCancelled, lastUpdateTime: new Date().getTime() })) } value={ state.showCancelled } />
          </div>
          }
          <div className="button-container">
            <PillToggle
              options={ [
                {
                  value: 0,
                  label: props.t(`orders:openOrders`)
                },
                {
                  value: 1,
                  label: props.t(`orders:orderHistory`)
                }
              ] }
              value={ state.activeTab }
              onChange={ (e, v) => setState(() => ({ activeTab: v })) } />
          </div>
          <div className="search">
            <TextField
              icon={ Search }
              label={ props.t(`app:search`) }
              name={ `search` }
              value={ state.filterText }
              onChange={ (e) => handleInputChange(e.target.value, `filterText`) }
              onDoubleClick={ () => handleInputChange(``, `filterText`) } />
          </div>
        </div>
      </div>
      <div className="orders-container">
        <div className="orders-table">
          <DataTable 
            id={ `orders-active-tab-${state.activeTab}` }
            columns={ columns } 
            data={ [] } 
            defaultSortBy={ `-orderTime` }
            filterText={ state.filterText }
            fetchData={ (currentPage, pageSize) => getData(currentPage, pageSize) }
            forceRefetch={ state.lastUpdateTime } />
        </div>
      </div>
      {
        state.cancelOrderModalOpen && (
          <CancelOrderModal
            label={ `Cancel Order` }
            confirm={ () => cancelOrder() }
            close={ () => closeCancelOrderModal() } />
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  priceTypes: state.orders.priceTypes,
  statusTypes: state.orders.statusTypes,
  orderTypes: state.orders.orderTypes,
  userApplications: state.userInfo.userApplications,
  applicationsStatuses: state.userInfo.applicationsStatuses,
  rehydrated: state._persist.rehydrated
});

export { OrdersTable as PureOrdersTable };
export default translate(`orders`)(connect(mapStateToProps)(OrdersTable));

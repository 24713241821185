// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import ReactHighcharts from 'highcharts-react-official';
import Highcharts from 'highcharts';
import BalancesEmptyData from './BalancesEmptyData.jsx';
import { getActiveTheme } from '../../helpers/ThemeHelper.js';
import { CHART_COLORS } from "../../constants/balances.js";
import type { Exchange } from "../../types/Exchange.js";
import type { Account } from "../../types/Account.js";
import type { Balance } from "../../types/Balance.js";

type Props = {
  t: any,
  hasData: boolean,
  balances?: Array<Balance>,
  currencyHistoryData: Map<string, Array<any>>,
  exchanges: Array<Exchange>,
  accounts: Array<Account>,
  theme: string,
  selectedQuoteCurrencyCode: string,
};

const pieConfig = (title: string, series: Array<any>, selectedQuoteCurrencyCode, theme) => ({
  legend: {
    enabled: true,
    itemStyle: {
      color: `white`,
    },
    itemHiddenStyle: {
      color: getActiveTheme(theme, true)[`--body-text-color`]
    },
    itemWidth: 100,
  },
  title: {
    text: title,
    style: {
      fontWeight: `normal`,
      fontSize: `2rem`,
      color: getActiveTheme(theme, true)[`--body-text-color-alpha-48`],
    }
  },
  tooltip: {
    backgroundColor: getActiveTheme(theme).gray2,
    borderColor: `transparent`,
    borderRadius: 4,
    distance: 40,
    style: {
      color: getActiveTheme(theme).body,
    },
    headerFormat: ``,
    pointFormat: `<span style="color:{point.color}">\u25CF</span> {point.name}: <b>{point.y} ` + 
                   `${ selectedQuoteCurrencyCode.toUpperCase() }</b><br/>`,
    valueDecimals: selectedQuoteCurrencyCode.toUpperCase() == `BTC` ? 8 : 2
  },
  plotOptions: {
    series: {
      animation: false,
    },
    pie: {
      allowPointSelect: true,
      cursor: `pointer`,
      borderWidth: 0,
      //size: 250,
      dataLabels: {
        enabled: true,
        connectorWidth: 1,
        inside: false,
        style: {
          color: getActiveTheme(theme).body,
        },
        distance: 20,
        formatter: function() {
          if (this.percentage > 0) {
            return `<b>${ this.point.name }</b><br/>${ this.percentage.toFixed(2) } %`;
          }
          return null;
        }
      }
    }
  },
  chart: {
    type: `pie`,
    borderColor: `transparent`,
    backgroundColor: getActiveTheme(theme)[`--gray2-theme-alpha`],
    zoomType: `xy`,
    height: 400,
    marginLeft: 0,
    marginRight: 1,
    id: `balanceChart`,
    resetZoomButton: {
      theme: {
        display: `block`
      }
    }
  },
  credits: {
    enabled: false
  },
  navigator: {
    enabled: false
  },
  scrollbar: {
    enabled: false
  },
  rangeSelector: {
    inputEnabled: false,
    buttonTheme: {
      visibility: `hidden`
    },
    labelStyle: {
      visibility: `hidden`
    }
  },
  series,
});

const BalancesPieChartComponent = (props: Props) => {
  const { t, balances, currencyHistoryData, exchanges, accounts, theme, selectedQuoteCurrencyCode, hasData } = props;

  if (!balances || !accounts || !exchanges || !currencyHistoryData
      || Array.from(currencyHistoryData.entries()).length === 0) {
    return (<BalancesEmptyData l={ hasData } />);
  }

  const exchangeData = exchanges.reduce((all, exchange) =>
    all.concat({
      name: exchange.exchCode,
      title: exchange.exchName,
      exchange: exchange.exchId,
      y: accounts
        .filter((account) => account.authExchId === exchange.exchId)
        .reduce((total, account) => total + balances
          .filter((balance) => balance.balanceAuthId === account.authId)
          .reduce((balanceTotal, balance) => balanceTotal + balance.quoteBalance, 0),
        0)
    }), []
  ).sort((a, b) => a.y > b.y ? -1 : 1).map((item, index) => ({ ...item, color: CHART_COLORS[index % CHART_COLORS.length] }));

  const data = Array.from(
    balances.reduce((currencyMap: Map<number, any>, currentBalance) => {
      if (currencyMap.has(currentBalance.balanceCurrId)) {
        const current = currencyMap.get(currentBalance.balanceCurrId);
        if (current) {
          current.quoteBalance = currentBalance.quoteBalance + current.quoteBalance;
          currencyMap.set(currentBalance.balanceCurrId, current);
        }
      } else {
        currencyMap.set(currentBalance.balanceCurrId, { ...currentBalance });
      }
      return currencyMap;
    }, new Map()).values()
  );

  const exchangeSeries = {
    name: props.t(`exchangeDistribution`),
    colorByPoint: true,
    data: exchangeData,
  };

  const currencyData = data.sort((a, b) => a.quoteBalance > b.quoteBalance ? -1 : 1).map((item, index) => ({
    color: CHART_COLORS[index % CHART_COLORS.length],
    title: item.balanceCurrCode,
    name: item.balanceCurrCode,
    y: parseFloat(item.quoteBalance)
  }));

  const currencySeries = {
    name: props.t(`currencyDistribution`),
    colorByPoint: true,
    data: currencyData
  };

  return (
    <div className="balance-chart">
      <div className="pies">
        <div className="pie-chart">
          <ReactHighcharts
            highcharts={ Highcharts }
            options={ pieConfig(t(`currencyDistribution`), [currencySeries], selectedQuoteCurrencyCode, theme) } />
        </div>
        <div className="pie-chart">
          <ReactHighcharts
            highcharts={ Highcharts }
            options={ pieConfig(t(`exchangeDistribution`), [exchangeSeries], selectedQuoteCurrencyCode, theme) } />
        </div>
      </div>
    </div>    
  );
};

const mapStateToProps = (state) => ({
  theme: state.redisPrefs.theme,
  selectedQuoteCurrencyCode: state.app.selectedQuoteCurrencyCode
});

export { BalancesPieChartComponent as PureBalancesPieChartComponent };
export default translate(`balances`)(connect(mapStateToProps)(BalancesPieChartComponent));

'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path fill-rule="evenodd" d="m16 10c0 1.8-1.5 3.3-3.3 3.3h-8.7c-2.2 0-4-1.8-4-4 0-2 1.6-3.7 3.6-3.9 0.8-1.6 2.5-2.7 4.4-2.7 2.4 0 4.4 1.7 4.9 4 1.7 0.1 3.1 1.5 3.1 3.3zm-1.3 0c0-1-0.8-1.9-1.9-2h-1l-0.2-1c-0.3-1.8-1.9-3-3.6-3-1.4 0-2.6 0.8-3.2 2l-0.4 0.6-0.7 0.1c-1.3 0.1-2.4 1.3-2.4 2.6 0 1.5 1.2 2.7 2.7 2.7h8.7c1.1 0 2-0.9 2-2zm-5.7-1.3h1.7l-2.7 2.6-2.7-2.6h1.7v-2h2z" fill="currentColor"/>`,
  {
    width: `16px`,
    height: `16px`,
    viewBox: `0 0 16 16`
  }
);

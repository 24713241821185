// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';

const BalancesEmptyData = ({ t, l }: { t: any, l?: boolean }) => (
  <div className="empty-data">
    {
      l ? (
        <span>
          { t(`noData`) }
        </span>
      ) : (
        <span>
          { t(`app:loading`) }
        </span>
      )
    }
  </div>
);

export { BalancesEmptyData as PureBalancesEmptyData };
export default translate(`balances`)(BalancesEmptyData);

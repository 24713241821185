'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path fill-rule="evenodd" clip-rule="evenodd" d="M7.13398 0.5C7.51888 -0.166666 8.48113 -0.166667 8.86603 0.5L15.7942 12.5C16.1791 13.1667 15.698 14 14.9282 14H1.0718C0.302005 14 -0.179121 13.1667 0.205779 12.5L7.13398 0.5ZM7 5C7 4.44772 7.44772 4 8 4C8.55229 4 9 4.44772 9 5V8C9 8.55229 8.55229 9 8 9C7.44772 9 7 8.55229 7 8V5ZM8 10C7.44772 10 7 10.4477 7 11C7 11.5523 7.44772 12 8 12C8.55229 12 9 11.5523 9 11C9 10.4477 8.55229 10 8 10Z" fill="#E0CC3D"/>`,
  {
    width: `16px`,
    height: `14px`,
    viewBox: `0 0 16 14`
  }
);

'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path class="clipboard" d="M11.333 2H12a2 2 0 0 1 2 2v9.333a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h.667c0-.736.597-1.333 1.333-1.333h4c.736 0 1.333.597 1.333 1.333zM4.667 3.333H4A.667.667 0 0 0 3.333 4v9.333c0 .369.299.667.667.667h8a.667.667 0 0 0 .667-.667V4A.667.667 0 0 0 12 3.333h-.667c0 .737-.597 1.334-1.333 1.334H6a1.333 1.333 0 0 1-1.333-1.334zM6 2v1.333h4V2H6z"/>`,
  {
    width: `1.5rem`,
    height: `1.5rem`,
    viewBox: `0 0 15 15`
  }
);
/* eslint-enable */




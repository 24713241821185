// @flow
'use strict';

import React from 'react';

export const MenuSeparator = (props: any) => {
  const { text } = props;
  return(
    <div className="menu-separator">
      <span>
        { text }
      </span>
    </div>
  );
};

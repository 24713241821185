// @flow
'use strict';

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Checkbox from '../utilities/Checkbox.jsx';
import SelectField from "../utilities/SelectField.jsx";
import Button from '../utilities/Button.jsx';
import type { ProfileProps } from "../user/ProfileDataLayer.jsx";
import sounds from '../../helpers/AlertSounds.js';

const Notifications = (props: ProfileProps) => {
  const { 
    t,
    onChange,
    save,
    tradeAlertSound,
  } = props;

  const {
    prefAlertEmail,
    prefAlertSms,
    prefAlertSite,
    prefTradeEmail,
    prefTradeSms,
    prefTradeSite,
    prefBalanceEmail,
    prefAlertNews,
    prefBlockSite,
    prefAlertNewMarket
  } = props.userPrefs || {};

  const [selectedAlertSound, setSelectedAlertSound] = useState(tradeAlertSound ? tradeAlertSound : ``);
  const isNotLscxUser = (useSelector((state) => state.userInfo.user.platformId) !== 1);

  useEffect(() => setSelectedAlertSound(tradeAlertSound), [tradeAlertSound]);

  return (
    <div className="notifactions-section">
      <h1 className="page-title">
        { t(`Notification options`) }
      </h1>
    
      <section>
        <div className="notification">
          <table>
            <tr>
              <td>
                <h3>
                  { t(`priceAlerts`) }
                </h3>
              </td>
              <td>
                <Checkbox
                  label={ t(`browser`) }
                  name={ `prefAlertSite` }
                  value={ prefAlertSite }
                  onChange={ (e) => {
                    if (onChange) onChange(`userPrefs`, { [`prefAlertSite`]: e.target.checked });
                  } } />
              </td>
              <td>
                <Checkbox
                  label={ t(`email`) }
                  name={ `prefAlertEmail` }
                  value={ prefAlertEmail }
                  onChange={ (e) => {
                    if (onChange) onChange(`userPrefs`, { [`prefAlertEmail`]: e.target.checked });
                  } } />
              </td>
              <td>
                {
                  isNotLscxUser && (
                    <Checkbox
                      label={ t(`sms`) }
                      name={ `prefAlertSms` }
                      value={ prefAlertSms }
                      onChange={ (e) => {
                        if (onChange) onChange(`userPrefs`, { [`prefAlertSms`]: e.target.checked });
                      } } />
                  ) 
                }
              </td>
            </tr>
            <tr>
              <td>
                <h3>
                  { t(`tradeAlerts`) }
                </h3>
              </td>
              <td>
                <Checkbox
                  label={ t(`browser`) }
                  name={ `prefTradeSite` }
                  value={ prefTradeSite }
                  onChange={ (e) => {
                    if (onChange) onChange(`userPrefs`, { [`prefTradeSite`]: e.target.checked });
                  } } />
              </td>
              <td>
                <Checkbox
                  label={ t(`email`) }
                  name={ `prefTradeEmail` }
                  value={ prefTradeEmail }
                  onChange={ (e) => {
                    if (onChange) onChange(`userPrefs`, { [`prefTradeEmail`]: e.target.checked });
                  } } />              
              </td>
              <td>
                { 
                  isNotLscxUser && (
                    <Checkbox
                      label={ t(`sms`) }
                      name={ `prefTradeSms` }
                      value={ prefTradeSms }
                      onChange={ (e) => {
                        if (onChange) onChange(`userPrefs`, { [`prefTradeSms`]: e.target.checked });
                      } } />
                  ) 
                }
              </td>

            </tr>
            <tr>
              <td>
                <h3>
                  { t(`dailyBalanceSummary`) }
                </h3>
              </td>
              <td />
              <td>
                <Checkbox
                  label={ t(`email`) }
                  name={ `prefBalanceEmail` }
                  value={ prefBalanceEmail }
                  onChange={ (e) => {
                    if (onChange) onChange(`userPrefs`, { [`prefBalanceEmail`]: e.target.checked });
                  } } />
              </td>
              <td />
            </tr>
            <tr>
              <td>
                <h3>
                  { t(`newsBulletins`) }
                </h3>
              </td>
              <td>
                <Checkbox
                  label={ t(`browser`) }
                  name={ `prefAlertNews` }
                  value={ prefAlertNews }
                  onChange={ (e) => {
                    if (onChange) onChange(`userPrefs`, { [`prefAlertNews`]: e.target.checked });
                  } } />
              </td>
              <td />
              <td />
            </tr>
            <tr>
              <td>
                <h3>
                  { t(`blockchainUpdates`) }
                </h3>
              </td>
              <td>
                <Checkbox
                  label={ t(`browser`) }
                  name={ `prefBlockSite` }
                  value={ prefBlockSite }
                  onChange={ (e) => {
                    if (onChange) onChange(`userPrefs`, { [`prefBlockSite`]: e.target.checked });
                  } } />
              </td>
              <td />
              <td />
            </tr>
            <tr>
              <td>
                <h3>
                  { t(`newMarkets`) }
                </h3>
              </td>
              <td>
                <Checkbox
                  label={ t(`browser`) }
                  name={ `prefAlertNewMarket` }
                  value={ prefAlertNewMarket }
                  onChange={ (e) => {
                    if (onChange) onChange(`userPrefs`, { [`prefAlertNewMarket`]: e.target.checked });
                  } } />
              </td>
              <td />
              <td />
            </tr>
            <tr>
              <td>
                <h3>
                  { t(`tradeAlertSound`) }
                </h3>
              </td>
              <td colSpan={ 3 }>
                <SelectField
                  label={ t(`Change trade alert sound`) }
                  name={ `alertSound` }
                  value={ selectedAlertSound ? selectedAlertSound : `` }
                  options={ sounds.map((s) => ({
                    label: s,
                    value: s
                  })) }
                  searchable={ true }
                  onChange={ (e, v) => {
                    if (onChange) onChange(`tradeAlertSound`, v);
                  } } />
              </td>
            </tr>
          </table>
          <Button
            type="primary"
            onClick={ () => {
              if (save) return save();
              return false;
            } }>
            { t(`saveChanges`) }
          </Button>
        </div>
      </section>
    </div>
  );
};

export default withRouter(Notifications);


// @flow
'use strict';

import React from 'react';

type Props = {
  status: any,
  text: any
};

const OrderStatus = ({ status, text = `` }: Props) => (
  <div className={ `order-status status-` + status } >
    { typeof text === `string` ? text.substring(0,13).trim() : text }
  </div>
);

export default OrderStatus;

// @flow
'use strict';

import { UPDATE_CSRF } from '../index.js';

export function updateCSRF(token: string) {
  return {
    type: UPDATE_CSRF,
    payload: { token }
  };
}

// @flow
'use strict';

import { UPDATE_CURRENT_FAVORITE } from '../index.js';
import type { Favorite } from '../../types/Favorite.js';

export function updateCurrentFavorite(updateFavorite: Favorite) {
  return {
    type: UPDATE_CURRENT_FAVORITE,
    payload: { updateFavorite }
  };
}

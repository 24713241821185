// @flow
'use strict';

import React from 'react';
import Logout from "../../../svgs/Logout.jsx";

type MenuLinkProps = {
  text: string,
}

export const LogoutLink = (props: MenuLinkProps) => {
  const { text } = props;

  return (
    <a
      href={ `${ window.WWW_URL }/auth/logout` }
      rel="noopener noreferrer">
      { Logout }
      <span>
        { text }
      </span>
    </a>
  );
};

// @flow
'use strict';

import React from 'react';
import Mail from "../../../svgs/Mail.jsx";
import { Link } from 'react-router-dom';

type MenuLinkProps = {
  closeDropdownMenus: any,
  text: string,
}

export const UserProfileLink = (props: MenuLinkProps) => {
  const { text, closeDropdownMenus } = props;

  
  return (
    <Link onClick={ () => closeDropdownMenus() } to="/user/profile">
      { Mail }
      <span>
        { text }
      </span>
    </Link>
  );
};

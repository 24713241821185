// @flow
'use strict';

import { TOGGLE_FAVORITE_FILTER } from '../index.js';

export function toggleFavoriteFilter(payload: boolean) {
  return {
    type: TOGGLE_FAVORITE_FILTER,
    payload
  };
}


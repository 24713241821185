// @flow
'use strict';

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from '../utilities/Modal.jsx';
import Tabs from "../utilities/Tabs.jsx";
import { $ } from '../../helpers/api/ApiHelper.js';
import { trimString } from "../../helpers/StringHelper.js";
import { createAccount } from '../../helpers/api/UserApi.js';
import { createWallet } from '../../helpers/api/UserApi.js';
import { refreshBalanceV2 } from '../../helpers/api/BalanceApi.js';
import type { Exchange } from '../../types/Exchange.js';
import type { Chain } from '../../types/Chain.js';
import type { Market } from '../../types/Market.js';
import type { Account } from '../../types/Account.js';
import type { UserApplication } from '../../types/UserApplication.js';
import ScrollableArea from '../utilities/ScrollableArea.jsx';
import EntityTable from '../utilities/EntityTable.jsx';
import TextField from '../utilities/TextField.jsx';
import Button from '../utilities/Button.jsx';
import SelectField from '../utilities/SelectField.jsx';
import Search from '../../svgs/Search.jsx';
import StepNavigations from '../utilities/StepNavigations.jsx';
import EditAccountModal from './EditAccountModal.jsx';
import Checkbox from '../utilities/Checkbox.jsx';
import LogoMark from '../../svgs/LogoMark.jsx';
import { 
  emitEvent, 
  UPDATE_ACCOUNT, 
  REFRESH_ACCOUNTS, 
  REFRESH_BALANCE, 
  DELETE_ACCOUNT,
  NOTIFICATION,
  SHOW_LSCX_MODAL
} from '../../helpers/EventHelper.js';
import { getUserApplication } from '../../helpers/UserApplicationsHelper';
// import { isFullAccessUser } from '../../helpers/UserApplicationsHelper.js';
import UpgradeNow from '../user/UpgradeNow.jsx';

// import Hint from '../utilities/Hint.jsx'; 
type Props = {
  t: any,
  exchanges: Array<Exchange>,
  accounts: Array<any>,
  currencies: Array<Chain>,
  close: () => void,
  active?: {
    exchange: Exchange,
    market: Market
  },
  platformId: number,
  history: { push: (any) => void },
  userApplications: Array<any>,
  applicationsStatuses: Array<any>,
  // prefSubscriptionExpires: string,
  // prefSubscriptionId: any,
  // prefTrialUsed: boolean,
  rehydrated: boolean,
};

type State = {
  activeTab: number,
  activeExch: number,
  activeExchName: string,
  activeExchCode: string,
  activeExchHasTrading: boolean,
  activeTutorialUrl: string,
  activeOptionalRequired: boolean,
  activeOptionalFieldName: string,
  activeOptionalFieldDesc: string,
  activeHasWhitelisting: boolean,
  currentStepKey: number,
  accountNickname: string,
  apiKey: string,
  secretKey: string,
  optionalKeys: string,
  tradingType: number,
  activeConnectAccount: number,
  currentStepSlack: number,
  slackBotNickname: string,
  slackToken: string,
  currentStepWallet: number,
  activeWallet: string,
  activeWalletCurrName: string,
  activeWalletFieldName: string,
  walletNickname: string,
  walletPublicKey: string,
  allowTrading: boolean,
  lastAuthId: number,
  isTesting: boolean,
  testingFailed: boolean,
  searchText: string,
  editAccountModalOpen: boolean,
  editingAccount?: Account,
  lscxCode: string,
  setFirst: string,
  userApplication: UserApplication
};

class NewAccountModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.getInitialState(); 
  }

  getInitialState() {
    return {
      activeTab: 0,
      activeExch: -1,
      activeExchName: ``,
      activeExchCode: ``,
      activeExchHasTrading: false,
      activeTutorialUrl: `https://youtube.com/coinigy`,
      activeOptionalRequired: false,
      activeOptionalFieldName: ``,
      activeOptionalFieldDesc: ``,
      activeHasWhitelisting: true,
      currentStepKey: 0,
      accountNickname: ``,
      apiKey: ``,
      secretKey: ``,
      optionalKeys: ``,
      tradingType: 0,
      activeConnectAccount: -1,
      currentStepSlack: -1,
      slackBotNickname: ``,
      slackToken: ``,
      currentStepWallet: 0,
      activeWallet: ``,
      activeWalletCurrName: ``,
      activeWalletFieldName: ``,
      walletNickname: ``,
      walletPublicKey: ``,
      allowTrading: true,
      lastAuthId: -1,
      isTesting: false,
      testingFailed: false,
      searchText: ``,
      editAccountModalOpen: false,
      lscxCode: `LSCX`,
      setFirst: `-1`,
      userApplication: {}
    };
  }
  
  
  handleInputChange(key: ?string, value: any) {
    if (key == `accountNickname`) value = value.replace(/,/g, ``);
    if (key) {
      this.setState({
        [key]: value
      });
    } else if (typeof value === `object`) {
      this.setState(value);
    }
    
  }

  sortDataExchanges(): Array<Exchange> {
    return this.props.exchanges.sort((a, b) => {
      if (a.exchCode > b.exchCode) return 1;
      if (a.exchCode < b.exchCode) return -1;
      return 0;
    });
  }

  pushApiAccount(): Promise<void> {
    return new Promise((resolve, reject) => {
      const promiseTimeout = setTimeout(() => {
        reject(new Error(`Promise timed out after 1 minute`));
      }, 60000);

      createAccount({
        apiKey: this.state.apiKey,
        apiSecret: this.state.secretKey,
        exchId: this.state.activeExch,
        apiNickname: this.state.accountNickname,
        optional1: this.state.optionalKeys,
        apiTradingType: this.state.tradingType
      }, (d) => {
        if (!d.success) {
          clearTimeout(promiseTimeout);
          reject(d.error);
        } else {
          clearTimeout(promiseTimeout);
          this.setState({ lastAuthId: d.result.authId });
          resolve();
          const authId = d.result.authId;
          $({
            url: `/private/user/accounts/${ authId }/tradingStatus`,
            type: `PUT`,
            body: {
              tradingEnabled: this.state.allowTrading
            },
            success: (data) => {
              if (data.success) {
                emitEvent(UPDATE_ACCOUNT, { authId, update: { authTrade: this.state.allowTrading } });
              }
            }
          });
        }
      }, () => {
        clearTimeout(promiseTimeout);
        reject(`An error occurred while attempting to add your API key. ` + 
        `If this continues, please contact support. Error: 200134`);
      });
    });
  }

  pushWalletAccount(): Promise<void> {
    return new Promise((resolve, reject) => {
      createWallet({
        address: this.state.walletPublicKey,
        nickname: this.state.walletNickname,
        currencyCode: this.state.activeWallet
      }, (d) => {
        if (!d.success) {
          reject(d.error);
        } else {
          this.setState({ lastAuthId: d.result.authId });
          resolve();
        }
      }, () => {
        reject(`An error occurred while attempting to add your API key. ` + 
        `If this continues, please contact support. Error: 200134`);
      });
    });
  }

  refreshApiBalance(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      refreshBalanceV2({
        balanceAuthId: this.state.lastAuthId
      }, (d) => {
        if (!d.success) {
          // reject(d.error);
          resolve(false);
        } else {
          resolve(true);
        }
      }, () => {
        reject(`An error occurred while attempting to test your API key. ` + 
        `If this continues, please contact support. Error: 200151`);
      });
    });
  }

  refreshWalletApiBalance(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      refreshBalanceV2({
        balanceAuthId: this.state.lastAuthId
      }, (d) => {
        if (!d.success) {
          // reject(d.error);
          resolve(false);
        } else {
          resolve(true);
        }
      }, () => {
        reject(`An error occurred while attempting to test your wallet key. ` + 
        `If this continues, please contact support. Error: 200151`);
      });
    });
  }

  deleteLastAuthId() {
    if (this.state.lastAuthId > 0) {
      $({
        url: `/private/user/accounts/${ this.state.lastAuthId }`,
        type: `DELETE`,
        success: (data) => {
          if (data.success) {
            emitEvent(DELETE_ACCOUNT, { authId: this.state.lastAuthId, update: false });
          }
          this.setState({ lastAuthId: -1 });
        },
        error: () => {
          emitEvent(NOTIFICATION, {
            notification_id: new Date().getTime(),
            title: `Error`,
            title_vars: ``,
            message_raw: `An error occurred while attempting to remove your API key. ` + 
            `If this continues, please contact support. Error: 200169`,
            message: `An error occurred while attempting to remove your API key. ` + 
            `If this continues, please contact support. Error: 200169`,
            message_vars: ``,
            pinned: false,
            style: `error`,
            url: ``
          });
          this.setState({ testingFailed: true });
          this.setState({ isTesting: false });
        }
      });
      return false;
    }
  }

  handleApiKeyCompleteClick() {
    this.setState({ isTesting: true });
    this.pushApiAccount().then(() => {
      this.refreshApiBalance().then((result) => {
        emitEvent(REFRESH_BALANCE);
        emitEvent(REFRESH_ACCOUNTS);
        if (result === true) {
          this.setState({ testingFailed: false });
          this.props.close();
        }else {
          this.setState({ testingFailed: true });
        }
        this.setState({ isTesting: false });
      }).catch(() => {
        this.setState({ testingFailed: true });       
        this.setState({ isTesting: false });
      });
    }).catch(() => {
      this.setState({ testingFailed: true });
      this.setState({ isTesting: false });
    });

    return false;
  }


  handleWalletApiKeyCompleteClick() {
    this.setState({ isTesting: true });
    this.pushWalletAccount().then(() => {
      this.refreshWalletApiBalance().then((result) => {
        emitEvent(REFRESH_BALANCE);
        emitEvent(REFRESH_ACCOUNTS);

        if (result == true) {
          this.setState({ testingFailed: false });
          this.props.close();
        }else {
          this.setState({ testingFailed: true });
        }
        this.setState({ isTesting: false });
      })
        .catch(() => {
          this.setState({ testingFailed: true });
          this.setState({ isTesting: false });
        });
      
    }).catch(() => {
      this.setState({ testingFailed: true });
      this.setState({ isTesting: false });
    });

    return false;
  }

  getTutorialUrl() {
    let tutUrl = this.state.activeTutorialUrl;
    return tutUrl.includes(`youtu.be`) 
      ? ( <iframe
        className={ `videoFrame` } 
        width="330" height="190" src={ `https://www.youtube.com/embed/` + 
    tutUrl.split(`https://youtu.be/`)[1] + `?modestbranding=1&showinfo=0&showsearch=0` } 
        frameBorder="0" allowFullScreen /> )
      : ( <a href={ tutUrl } target="_blank" rel="noopener noreferrer">
Click here for instructions on how to locate your 
        { ` ` }
        { this.state.activeExchName }
        { ` ` }
API keys.
      </a>);
  }

  validateStep2() {
    if (this.state.activeOptionalRequired && this.state.optionalKeys.length == 0) return true;
    if (this.state.accountNickname.length == 0) return true;
    if (this.state.apiKey.length == 0) return true;
    if (this.state.secretKey.length == 0) return true;
    return false;
  }

  clearInputs() {
    this.setState({
      tradingType: 0,
      accountNickname: ``,
      apiKey: ``,
      secretKey: ``,
      optionalKeys: ``
    });
    return false;
  }

  getKeyData() {
    let { activeExchName, accountNickname, apiKey, optionalKeys, allowTrading } = this.state;

    accountNickname = trimString(accountNickname, 20, true);
    apiKey = trimString(apiKey, 12, true);
    optionalKeys = trimString(optionalKeys, 12, true);
    return [
      {
        activeExchName,
        accountNickname,
        apiKey,
        secretKey: `●●●●●●●●●●`,
        allowTrading,
        optionalKeys        
      }
    ];
  }

  getWalletKeyData() {
    let { activeWallet, walletNickname, walletPublicKey } = this.state;

    walletNickname = trimString(walletNickname, 20, true);
    walletPublicKey = trimString(walletPublicKey, 12, true);

    return [
      {
        activeWallet,
        walletNickname,
        walletPublicKey
      }
    ];
  }

  UNSAFE_componentWillMount() {
    if (this.props.active) {
      this.handleInputChange(null,
        { 
          "activeExch": this.props.active[`exchange`].exchId, 
          "activeExchName": this.props.active[`exchange`].exchName, 
          "activeTutorialUrl": this.props.active[`exchange`].exchTutorialUrl, 
          "activeExchCode": this.props.active[`exchange`].exchCode, 
          "activeExchHasTrading": this.props.active[`exchange`].hasSpotTrading || this.props.active[`exchange`].hasFuturesTrading, 
          "activeOptionalRequired": this.props.active[`exchange`].optionalRequired, 
          "activeOptionalFieldName": this.props.active[`exchange`].optionalFieldName, 
          "activeOptionalFieldDesc": this.props.active[`exchange`].optionalFieldDesc, 
          "activeHasWhitelisting": this.props.active[`exchange`].exchHasWhitelisting 
        }
      );

    }

    const userApp = getUserApplication(`LSCX`, this.props.userApplications, this.props.applicationsStatuses);
    this.setState({
      userApplication: userApp
    });

  }

  closeEditAccountModal() {
    this.setState(this.getInitialState());
    this.props.close();
  }

  renderExchanges(exchanges: any) {
    return exchanges
      .map((exchange, i) => {
        if(exchange.exchBalanceEnabledV2 || exchange.exchTradeEnabledV2) {
          if(this.state.userApplication.isEligible && exchange.exchCode === this.state.lscxCode) {
            return this.renderExchangeItem (i, exchange, { order: this.state.setFirst });
          } else if(exchange.exchCode !== this.state.lscxCode) {
            return this.renderExchangeItem (i, exchange, null);
          } 
        }
      }); 
  }

  renderExchangeItem(key: any, exchange: any, customStyle: any ) {
    return  (
      <li
        key={ `exc-${ key }` }
        style={ customStyle }

        className={
          exchange.exchId === this.state.activeExch ?
            `exch-li active` :
            `exch-li`
        }
        
        onClick={ () => { this.exchangeModalSteps(exchange); } }>

        <div className={ `exch-logo-section` }>
          <img
            src={ `https://www.coinigy.com/assets/img/exchange/${ exchange.exchCode }-icon.png` }
            className={ `exch-icon` }
            alt={ exchange.exchName } />
      
          <span className={ `exch-name` }>
            { exchange.exchName }
          </span>
        </div>
        <div className={ `exch-code` }>
          { ` ` }
          { ` ` }
  Code: 
          { ` ` }
          { ` ${exchange.exchCode}` }
        </div>
        <div className={ `exch-supported-features` }>
        
          { exchange.exchBalanceEnabledV2 && (<div className="balance-tracking">
          Balance Monitoring
          </div>) }
          { exchange.hasSpotTrading && (<div className="spot-trading">
          Spot Trading
          </div>) }
          { exchange.hasFuturesTrading && (<div className="futures-trading">
          Futures Trading
          </div>) }
        </div>
        <div className="exchUrl">
          <a
            href={ `https://www.coinigy.com/site/exchange/` + exchange.exchCode }
            target="_blank" rel="noopener noreferrer">
            { `Sign up for ` + exchange.exchName }
          </a>
        </div>
      </li>
    );
  }

  renderNextStepModal(){
    const isLscxSelected = this.state.userApplication.isEligible && this.state.activeExchCode === this.state.lscxCode;
    const isNotLscxSelected = this.state.activeExchCode !== this.state.lscxCode;

    if(isLscxSelected) {
      if (this.state.userApplication.isVerified) {
        const LSCXExchange = this.props.exchanges.filter((e) => e.exchCode === `LSCX`);
        const LSCXAccount = LSCXExchange[0] ? this.props.accounts.filter((a) => a.authExchId == LSCXExchange[0].exchId) : [];
        if (LSCXAccount) {
          this.props.history.push(`/wallet/${LSCXAccount[0].authId}`);
        }
        
      }else {
        this.renderLscxModal();
      }
      
    } 
    else if(isNotLscxSelected) {
      this.clearInputs();
      this.handleInputChange(`currentStepKey`, 1);
    }
  }

  renderLscxModal() {
    this.closeEditAccountModal();
    emitEvent(SHOW_LSCX_MODAL);
  }

  exchangeModalSteps(exchange: any) {
    this.handleInputChange(null,
      {
        "activeExch": exchange.exchId,
        "activeExchName": exchange.exchName,
        "activeTutorialUrl": exchange.exchTutorialUrl,
        "activeExchCode": exchange.exchCode,
        "activeExchHasTrading": exchange.hasSpotTrading || exchange.hasFuturesTrading, 
        "activeOptionalRequired": exchange.optionalRequired,
        "activeOptionalFieldName": exchange.optionalFieldName,
        "activeOptionalFieldDesc": exchange.optionalFieldDesc,
        "activeHasWhitelisting": exchange.exchHasWhitelisting
      }
    );
  }

  isFullAccess() {
    return true; //this.props.rehydrated && isFullAccessUser(this.props.platformId, this.props.prefSubscriptionExpires, this.props.prefSubscriptionId, this.props.prefTrialUsed);
  }

  render() {
    const exchanges = this.sortDataExchanges();

    // const url = `https://support.coinigy.com/hc/en-us/sections/360000254474-Using-Exchanges-with-Coinigy`;

   
    if (this.state.editAccountModalOpen && this.state.editingAccount) {
      return (
        <EditAccountModal
          t={ this.props.t }
          exchanges={ this.props.exchanges || [] }
          account={ this.state.editingAccount || {} }
          currencies={ this.props.currencies || [] }
          close={ () => this.closeEditAccountModal() } />
      );
    }else {
      return (
        <div className="new-account-modal">
          { !this.isFullAccess() ?
            <div className='upgrade-now-modal'>
              <Modal title={ `` } onClose={ () => this.props.close() }>
                <UpgradeNow t={ this.props.t }/>
              </Modal>
            </div>
            : (
              <Modal
                title={ this.props.t(`user:addNewAccount`) }
                onClose={ () => this.props.close() }>
                <p>
                  { this.props.t(`user:connectAutomatically`) }
                </p>
                <Tabs
                  tabPosition="center"
                  activeTab={ this.state.activeTab }
                  onChangeTab={ (activeTab) => this.setState({ activeTab }) }
                  tabNames={ [
                    this.props.t(`user:cryptoExchange`), 
                    this.props.t(`user:walletAddress`)
                  ] }>
                  <div className="cryptocurrency">
                    <StepNavigations
                      currentStep={ this.state.currentStepKey }
                      goToStep={ (n) => this.handleInputChange(`currentStepKey`, n) }
                      data={ [
                        this.props.t(`user:selectYourExchange`),
                        this.props.t(`user:addAPIKeys`),
                        this.props.t(`user:verifyConnection`),
                      ] } />
                    {
                      // `<h5>
                      //   { this.props.t(`user:locateApiKeys`) }
                      // </h5>
                      // <div className="description">
                      //   <p>
                      //     { this.props.t(`user:lipsum`) }
                      //   </p>
                      //   <video width="200" height="100" controls />
                      // </div>
                      // <h5>
                      //   { this.props.t(`user:whitelistSettings`) }
                      // </h5>
                      // <div className="whitelist">
                      //   <p>
                      //     { this.props.t(`user:whitelistInfo`) }
                      //   </p>
                      //   <p>
                      //     { this.props.t(`user:whitelistIPNote`) }
                      //   </p>
                      //   <p>
                      //     { this.props.t(`user:whitelistIPAddresses`) }
                      //   </p>
                      // </div>`
                    }
                    <div className="optional-key">
                      {
                        this.state.currentStepKey === 0 && (
                          <div>
                            <h4>
                              { this.props.t(`user:selectYourExchange`) }
:
                            </h4>
                            <h5>
Scroll down for more exchanges
                            </h5>
                            <ScrollableArea>
                              <ul className="flexibleList">
                                { /* 
                            LIGHTSPEED CRYPTO AS FIRST OPTION
                            <li
                              key={ `exc-lscx` }
                              className={
                                `exch-li`
                              }
                              onClick={ () => {
                                alert(`hi`);
                              } }>
                              <div className={ `exch-logo-section` }>
                                <img
                                  src={ `https://www.coinigy.com/assets/img/exchange/LSCX-icon.png` }
                                  className={ `exch-icon` }
                                  alt={ `Lightspeed Crypto` } />
                                  
                                <span className={ `exch-name` }>
                                        Lightspeed Crypto
                                </span>
                              </div>
                              <div className={ `exch-code` }>
Code: 
                                { ` ` }
                                      LSCX
                              </div>
                              <div className={ `exch-supported-features` }>
                                <div className="balance-tracking">
                                      Balance Monitoring
                                </div>
                                <div className="spot-trading">
                                      Spot Trading
                                </div>
                              </div>
                              <div className="exchUrl">
                                <a
                                  href={ `https://www.coinigy.com/site/exchange/LSCX` }
                                  target="_blank" rel="noopener noreferrer">
                                  { `Sign up for Lightspeed Crypto` }
                                </a>
                              </div>
                            </li> */ }

                                { this.renderExchanges(exchanges) }

                              </ul>
                            </ScrollableArea>

                            <Button
                              type="primary"
                              disabled={ this.state.activeExch <= 0 }
                              onClick={ () => { 
                                this.renderNextStepModal();  
                                return false;
                              } }>
                              { `${ this.props.t(`app:next`) } (${ this.props.t(`user:addAPIKeys`) })` }
                            </Button>

                          </div>
                        )
                      }
                      {
                        this.state.currentStepKey === 1 && (
                          <div>
                            <div className="formSection">
                              <a
                                href={ `https://www.coinigy.com/site/exchange/${ this.state.activeExchCode }` }
                                target="_blank" rel="noopener noreferrer" >
                                <img 
                                  className={ `exchImg` }
                                  src={ `https://www.coinigy.com/assets/img/exchange/${ this.state.activeExchCode }.png` }
                                  alt={ this.state.activeExchName } />
                              </a>
                              <h3 className={ `pull-left` }>
                                {  
                                  this.props.t(`user:pleaseEnterYour`) + 
                            this.state.activeExchName + 
                            this.props.t(`user:apiInfoBelow`)  
                                }
                              </h3>
                              {
                                (this.props.exchanges.some((e) => e.exchCode == this.state.activeExchCode && e.hasFuturesTrading === true)) && (
                                  <SelectField
                                    options={ [
                                      { label: this.props.t(`user:spot`), value: 0 },
                                      //{ label: this.props.t(`user:spotMargin`), value: 1 },
                                      //{ label: this.props.t(`user:deposit`), value: 2 },
                                      { label: this.props.t(`user:futures`), value: 3 },
                                      //{ label: this.props.t(`user:wallet`), value: 4 },
                                      //{ label: this.props.t(`user:thirdPartyIntegration`), value: 5 }
                                    ] }
                                    label={ this.props.t(`user:tradingType`) }
                                    name="tradingType"
                                    value={ this.state.tradingType }
                                    searchable={ false }
                                    hintText={ this.props.t(`user:tradingTypeHint`) }
                                    onChange={ (e, v) => this.handleInputChange(`tradingType`, v) }/>
                                )
                              }
                              <TextField
                                label={ this.props.t(`user:accountNickname`) }
                                name={ `accountNickname` }
                                value={ this.state.accountNickname }
                                hintText={ this.props.t(`user:nicknameHint`) }
                                onChange={ (e) => this.handleInputChange(`accountNickname`, e.target.value) } />
                              <TextField
                                label={ this.props.t(`user:exchangeApiKey`) }
                                name={ `apiKey` }
                                value={ this.state.apiKey }
                                hintText={ this.props.t(`user:publicKeyHint`) }
                                onChange={ (e) => this.handleInputChange(`apiKey`, e.target.value) } />
                              <TextField
                                label={ this.props.t(`user:secretKey`) }
                                name={ `secretKey` }
                                value={ this.state.secretKey }
                                type={ `password` }
                                hintText={ this.props.t(`user:secretKeyHint`) }
                                onChange={ (e) => this.handleInputChange(`secretKey`, e.target.value) } />                        
                              {
                                this.state.activeOptionalRequired ? (
                                  <TextField
                                    label={ this.state.activeOptionalFieldName }
                                    name={ `optionalKeys` }
                                    value={ this.state.optionalKeys }
                                    hintText={ this.state.activeOptionalFieldDesc }
                                    onChange={ (e) => this.handleInputChange(`optionalKeys`, e.target.value) } />
                                )
                                  :
                                  null
                              }                 
                              { this.state.activeExchHasTrading && (
                                <Checkbox
                                  name={ `allowTrading` }
                                  className={ `checkbox` }
                                  label={ this.props.t(`user:allowTrading`) }
                                  value={ this.state.allowTrading } 
                                  onChange={ (e) => {
                                    this.setState({ allowTrading: e.target.checked });
                                  } }/>
                              ) }         
                          
                            </div>
                            <div className="tutorialSection">
                              <h3> 
                                { this.props.t(`user:locateApiKeys`) }
                              </h3>
                              {
                                this.getTutorialUrl()
                              }
                              <h3> 
                                { this.props.t(`user:whitelistSettings`) }
                              </h3>
                              <h3> 
                                {
                                  this.state.activeHasWhitelisting 
                                    ? ( <div>
                                      <p> 
                                        {
                                          this.props.t(`user:ipWhitelistingSupported`) 
                                        }
                                      </p>
                                      <p>
                                        { 
                                          this.props.t(`user:whitelistIPAddresses`)
                                        }
                                      </p>
                                      <p>
                                        {
                                          this.props.t(`user:whitelistIPNote`)
                                        }
                                      </p>
                                    </div> )
                                    : 
                                    this.props.t(`user:ipWhitelistingNotSupported`)
                                }
                              </h3>
                            </div>
                      
                            <Button
                              type="primary"
                              disabled={
                                this.validateStep2()
                              }
                              onClick={ () => {
                                this.handleInputChange(null, { currentStepKey: 2, testingFailed: false });
                                this.handleApiKeyCompleteClick();
                                return false;
                              } }>
                              { `${ this.props.t(`app:next`) } (${ this.props.t(`user:verifyConnection`) })` }
                            </Button>
                          </div>
                        )
                      }
                      { this.state.currentStepKey === 2 &&
                  <div>
                    
                    <div className="testConnection">
                      <h2> 
                        { this.props.t(`user:verifyConnection`) }
                      </h2>
                      

                      <h3>
  Account Information:
                      </h3>

                      

                      <EntityTable
                        columns={ [
                          {
                            title: this.props.t(`app:exchange`),
                            key: `activeExchName`,
                          },
                          {
                            title: this.props.t(`user:accountNickname`),
                            key: `accountNickname`
                          },
                          {
                            title: this.props.t(`user:key`),
                            key: `apiKey`
                          },
                          {
                            title: this.props.t(`user:secretKey`),
                            key: `secretKey`
                          },
                          (this.state.activeExchHasTrading ? 
                            {
                              title: this.props.t(`user:trading`),
                              key: `allowTrading`,
                              display: (row, data) => (
                                <div>
                                  <Checkbox
                                    label=""
                                    name="table-allowTrading"
                                    disabled={ true }
                                    value={ data }/>
                                </div>                    
                              ) 
                            } : {}),                         
                          ...(this.state.optionalKeys.length > 0 ? 
                            [{ title: this.state.activeOptionalFieldName, key: `optionalKeys` }] 
                            : [])                  
                        ] }
                        //filterText={ this.state.filterText }
                        data={ this.getKeyData() }
                        hidePagination={ true } />

                      <div className={ `connectionAnimation ${ this.state.isTesting ? `fullopacity` : `` }` }>
                        <div className="logo-wrapper">
                          { LogoMark[this.props.platformId ? this.props.platformId : 0] }
                        </div>
                        <div className={ 
                          `anim-wrapper ${ this.state.isTesting ? `animate` : `` }` 
                        }>
                          <div className="lds-ellipsis">
                            <div />
                            <div />
                            <div />
                            <div />
                          </div>
                        
                        </div>
                        <div className="exchange-wrapper">
                          <img
                            className={ `exchange-logo` }
                            src={ `https://www.coinigy.com/assets/img/exchange/${ this.state.activeExchCode }.png` }
                            alt={ this.state.activeExchName } />
                        </div>
                      </div>
                      <div className={ 
                        `contacting ${ !this.state.isTesting ? `dimmed` : `` }` 
                      }>
                        {
                          this.state.isTesting 
                            ? 
                            `${ this.props.t(`user:contacting`)} ${ this.state.activeExchName }...`
                            :
                            ( this.state.testingFailed 
                              ? 
                              this.props.t(`user:testFailed`) 
                              : 
                              this.props.t(`user:readyToTest`))
                          
                        }
                      </div>
                      
                    </div>
                    
                    <div>
                    
                      <Button
                        type={ this.state.testingFailed ? `cancel` : `primary` }
                        disabled={ this.state.isTesting }
                        onClick={ () =>  { 
                          if (this.state.testingFailed)
                          {
                            let lastAccount = this.props.accounts.find((account) => { return account.authId === this.state.lastAuthId; });

                            if (lastAccount) {
                              this.setState({
                                editAccountModalOpen: true, 
                                editingAccount: lastAccount 
                              });
                            }else {
                              this.handleInputChange(`currentStepKey`, 1);
                            }
                          }else {
                            this.handleApiKeyCompleteClick(); 
                          }
                          return false;
                        } 
                        }>
                        { 
                          this.state.testingFailed 
                            ? 
                            `← ` + this.props.t(`user:testApiFailed`) 
                            : 
                            this.props.t(`user:testMyConnection`) + ` & ` + this.props.t(`orders:addAccount`) 
                        }
                      </Button>
                    </div>
                  </div>
                      }

                    </div>
                  </div>
                  <div className="wallet-address">
                    <StepNavigations
                      currentStep={ this.state.currentStepWallet }
                      goToStep={ (n) => this.handleInputChange(`currentStepWallet`, n) }
                      data={ [
                        this.props.t(`user:selectYourCurrency`),
                        this.props.t(`user:addWalletAddress`),
                        this.props.t(`user:verifyConnection`),
                      ] } />
                    { this.state.currentStepWallet === 0 &&
                <div>
                  <h4>
                    { this.props.t(`user:selectYourCurrency`) }
                  </h4>
                  <h5>
Scroll down for more currencies
                  </h5>
                  <div className="search">
                    <TextField
                      icon={ Search }
                      label={ this.props.t(`app:search`) }
                      name={ `search` }
                      value={ this.state.searchText }
                      onChange={ (e) => this.handleInputChange(`searchText`, e.target.value) } />
                  </div>
                  <ScrollableArea>
                    <ul className="flexibleList">
                      { this.props.currencies && this.props.currencies.filter((currency) => currency.currCode.includes(this.state.searchText.toUpperCase()) || 
                      currency.currName.toUpperCase().includes(this.state.searchText.toUpperCase())).map((currency) => {
                        return (
                          <li
                            key={ currency.currCode }
                            className={ this.state.activeWallet == currency.currCode ? `active` : `` }
                            onClick={ () => {
                              this.handleInputChange(null,
                                {
                                  "activeWallet": currency.currCode,
                                  "activeWalletCurrName": currency.currName,
                                  "activeWalletFieldName": currency.walletFieldName,
                                  "walletNickname": ``,
                                  "walletPublicKey": ``
                                }
                              );
                            } }>
                            <div className={ `currency-image` }>
                              <img
                                src={ `https://www.coinigy.com/assets/img/currency/` + currency.currCode + `.png` } 
                                onError={ (e)=>{e.target.onerror = null; e.target.src=`https://www.coinigy.com/assets/img/currency/empty.png`;} } />
                            </div>
                            <div className={ `currency-name-wrapper` }>
                              <div className={ `currency-code` }>
                                { currency.currCode }
                              </div>
                              <div className={ `currency-name` }>
                                { currency.currName }
                              </div>
                            </div>
                          </li>
                        );
                      }) }
                    </ul>
                  </ScrollableArea>
                  <Button
                    type="primary"
                    disabled={
                      this.state.activeWallet.length <= 0
                    }
                    onClick={ () => {
                      this.handleInputChange(null, { 
                        "currentStepWallet": 1,
                      });
                      return false; 
                    } }>
                    { `${ this.props.t(`app:next`) } (${ this.props.t(`user:addWalletAddress`) })` }
                  </Button>
                </div>
                    }
                    { this.state.currentStepWallet === 1 &&
                <div className="confirm-data">
                  <div className="tutorialSection">
                    <div className={ `tutorial-currency-wrapper` }>
                      <div className={ `currency-image-large` }>
                        <img
                          src={ `https://www.coinigy.com/assets/img/currency/` + this.state.activeWallet + `.png` } 
                          onError={ (e)=>{e.target.onerror = null; e.target.src=`https://www.coinigy.com/assets/img/currency/empty.png`;} } />
                      </div>
                      <div className={ `currency-name-wrapper` }>
                        <div className={ `currency-code` }>
                          { this.state.activeWallet }
                        </div>
                        <div className={ `currency-name` }>
                          { this.state.activeWalletCurrName }
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <div className="formSection">
                    <h3 className={ `pull-left` }>
                      {  
                        this.props.t(`user:pleaseEnterYour`) + 
                            this.state.activeWalletCurrName + 
                            this.props.t(`user:walletInfoBelow`)  
                      }
                    </h3>
                    <TextField
                      label={ this.props.t(`user:walletNickname`) }
                      name={ `walletNickname` }
                      value={ this.state.walletNickname }
                      hintText={ this.props.t(`user:walletNicknameHint`) }
                      onChange={ (e) => this.handleInputChange(`walletNickname`, e.target.value) } />
                    
                    <TextField
                      label={ this.state.activeWalletFieldName.length > 0 
                        ? this.state.activeWallet + ` ` + this.state.activeWalletFieldName 
                        : this.state.activeWallet + ` ` + this.props.t(`user:walletPubkey`) }
                      name={ `walletKey` }
                      value={ this.state.walletPublicKey }
                      hintText={ this.props.t(`user:walletPubkeyHint`) }
                      onChange={ (e) => this.handleInputChange(`walletPublicKey`, e.target.value) } />
                  </div>
                  
                  <Button
                    disabled={ !this.state.walletNickname || !this.state.walletPublicKey  }
                    type="primary"
                    onClick={ () => {
                      this.handleInputChange(`currentStepWallet`, 2);
                      this.handleWalletApiKeyCompleteClick();
                      return false;
                    } }>
                    { this.props.t(`user:verifyConnection`) }
                  </Button>
                  
                </div>
                    }
                    { this.state.currentStepWallet === 2 &&
                <div>
                  
                  <div className="testConnection">
                    <h2> 
                      { this.props.t(`user:verifyConnection`) }
                    </h2>
                    <h3>
                      Account Information:
                    </h3>
                    <EntityTable
                      columns={ [
                        {
                          title: this.props.t(`app:wallet`),
                          key: `activeWallet`,
                        },
                        {
                          title: this.props.t(`user:walletNickname`),
                          key: `walletNickname`
                        },
                        {
                          title: this.state.activeWalletFieldName,
                          key: `walletPublicKey`
                        }
                      ] }
                      //filterText={ this.state.filterText }
                      data={ this.getWalletKeyData() }
                      hidePagination={ true } />

                    <div className={ `connectionAnimation ${ this.state.isTesting ? `fullopacity` : `` }` }>
                      <div className="logo-wrapper">
                        { LogoMark[this.props.platformId ? this.props.platformId : 0] }
                      </div>
                      <div className={ 
                        `anim-wrapper ${ this.state.isTesting ? `animate` : `` }` 
                      }>
                        <div className="lds-ellipsis">
                          <div />
                          <div />
                          <div />
                          <div />
                        </div>
                      
                      </div>
                      <div className="exchange-wrapper">
                        <img
                          className={ `currency-image` }
                          src={ `https://www.coinigy.com/assets/img/currency/${ this.state.activeWallet }.png` }
                          alt={ this.state.activeWallet } />
                      </div>
                    </div>
                    <div className={ 
                      `contacting ${ !this.state.isTesting ? `dimmed` : `` }` 
                    }>
                      {
                        this.state.isTesting 
                          ? 
                          `${ this.props.t(`user:contacting`)} ${ this.state.activeWallet }...`
                          :
                          ( this.state.testingFailed 
                            ? 
                            this.props.t(`user:testFailed`) 
                            : 
                            this.props.t(`user:readyToTest`))
                        
                      }
                    </div>
                    
                  </div>
                  
                  <div>
                  
                    <Button
                      type={ this.state.testingFailed ? `cancel` : `primary` }
                      disabled={ this.state.isTesting }
                      onClick={ () =>  { 
                        if (this.state.testingFailed)
                        {
                          let lastWallet = this.props.accounts.find((account) => { return account.authId === this.state.lastAuthId; });

                          if (lastWallet) {
                            this.setState({
                              editAccountModalOpen: true, 
                              editingAccount: lastWallet
                            });
                          }else {
                            this.handleInputChange(`currentStepWallet`, 1);
                          }
                        }else {
                          this.handleWalletApiKeyCompleteClick(); 
                        }
                        return false;
                      } 
                      }>
                      { 
                        this.state.testingFailed 
                          ? 
                          `← ` + this.props.t(`user:testWalletFailed`) 
                          : 
                          this.props.t(`user:testMyConnection`) + ` & ` + this.props.t(`orders:addWallet`) 
                      }
                    </Button>
                  </div>
                </div>
                    }
                  </div>

                </Tabs>  
              </Modal>)
          }
        </div> 
      );
    }
  }
}

const mapStateToProps = (state) => ({
  platformId: state.userInfo.user.platformId,
  userApplications: state.userInfo.userApplications,
  applicationsStatuses: state.userInfo.applicationsStatuses,
  // prefSubscriptionExpires: state.userInfo?.userPrefs?.prefSubscriptionExpires,
  // prefSubscriptionId: state.userInfo?.userPrefs?.prefSubscriptionId,
  // prefTrialUsed: state.userInfo?.userPrefs?.prefTrialUsed,
  rehydrated: state._persist.rehydrated
});

export { NewAccountModal as PureNewAccountModal };
export default withRouter(connect(mapStateToProps)(NewAccountModal));

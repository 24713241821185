// @flow
'use strict';

import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { toFixedDecimalsHTML } from '../../helpers/NumberHelper.js';

type Props = {
  columns: Array<any>,
  currentMkt: any,
  hideColumns: Array<string>,
  marketsBalances: Array<any>,
}

const MAPPED_COLUMNS = [`market`, `position`];

const ScreenerExpandableRow = (props: Props) => {
  const { columns, currentMkt, hideColumns, marketsBalances } = props;

  const unmappedColsCount = columns.length - MAPPED_COLUMNS.length + 1;
  const fullFillColSpan = unmappedColsCount - hideColumns.filter((c) => !MAPPED_COLUMNS.includes(c) ).length;
  const balances = marketsBalances.filter((bln) => currentMkt.accounts?.includes(bln.authId) && bln.marketId === currentMkt.marketId && bln.exchId === currentMkt.exchId);

  if (!balances.length) return null;

  const pricePrecision = currentMkt.pricePrecision === 2 ? `two_decimals` : `price`;

  // $FlowIgnore: suppressing this error
  if (!currentMkt.basePricePrecision) currentMkt.basePricePrecision = currentMkt.pricePrecision;

  return (
    balances.map<any>((b, idx) => 
      <TableRow key={ idx } className='mui-expandable-row'>
        <TableCell colSpan={ 1 } />
        <TableCell colSpan={ 1 } className={ `auth-nickname` }>
          { b.authNickname }
        </TableCell>
        { fullFillColSpan > 0 && <TableCell colSpan={ fullFillColSpan } /> }
        {  !hideColumns.find((c) => c === `position`) && 
        <TableCell colSpan={ 1 }>
          <div 
            className={ `nowrap align-right` }
            dangerouslySetInnerHTML={
              toFixedDecimalsHTML(b.balanceAmountTotal, false, pricePrecision, currentMkt)
            }/>
        </TableCell>
        }
      </TableRow>
    ) 
  );
};

export default ScreenerExpandableRow;

// @flow
'use strict';

import { createStore, compose } from 'redux';
import reducer, { initialState } from '../reducers';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// added redux-devtools-extension (development only)
const composeEnhancers = process.env.NODE_ENV === `development`
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose 
  : compose;

// prepare persist configuration
const persistConfig = {
  key: `root`,
  storage,
  blacklist: [`browser`],
  keyPrefix: `coinigy->`
};

let persistedReducer = persistReducer(persistConfig, reducer);

//when importing components in tests, persistReducer will return undefined
//eg import Board from '../../../src/components/boards/Board';
//unable to determine why as of 2020-03-27
//no localstorage in tests anyways to test redux persistence
if(typeof persistedReducer !== `function`){
  persistedReducer = reducer;
}

const store = createStore(
  persistedReducer,
  initialState,
  composeEnhancers()
);

persistStore(store);

export default store;


import React, { useState, useEffect } from 'react';
import QRCode from "react-qr-code";
import { postCoinigyTransfersCryptoDeposit } from '../../../helpers/api/UserApi.js';
import Modal from '../../utilities/Modal.jsx';
import Button from '../../utilities/Button.jsx';
import ProgressSpinner from '../../utilities/ProgressSpinner.jsx';
import Clipboard from '../../../svgs/Clipboard.jsx';


export const CryptoDepositModal = ({ close, t, currCode, accountId }) => {
  const [ errors, setErrors ] = useState(``);
  const [ loading, setLoading ] = useState(true);
  const [ receiveAddress, setReceiveAddress] = useState(``);

  const copyAddressToClipboard = () => {
    navigator.clipboard.writeText(receiveAddress);
    return false;
  };

  const getDepositAddress = () => {
    setLoading(true);
    setErrors(``);
    postCoinigyTransfersCryptoDeposit(accountId, { currCode }).then((data) => {
      if (data.success) {
        setReceiveAddress(data.result?.receiveAddress);
        setLoading(false);
      } else {
        let error;
        try {
          error = JSON.parse(data.error);
          setErrors(error?.message);
        } catch (e) {
          setErrors(data.error);
        } finally {
          setLoading(false);
        }
      }
    });
  };

  useEffect(getDepositAddress, []);
  
  return (
    <div className={ `deposit-modal` }>
      <Modal
        title={ `New ` + currCode + ` Deposit` }
        onConfirm={ false }
        onClose={ () => close(false) }
        displayOnClose={ false }>
        <div className={ `crypto-deposit-form` }>
          { receiveAddress &&
          <div>   
            <span className="confirm-deposit-body" style={ { fontSize: `2rem` } }>
              { `Please deposit at the following ` + currCode + ` address:` }
            </span>
            <div className="row">
              <QRCode value={ receiveAddress } className="qr-code"/>
            </div>
            <div className="row"> 
              <div className="align-center">
                { receiveAddress }
              </div>
              <div className="copy-to-clipboard" onClick={ () => copyAddressToClipboard() }>
                { Clipboard }
              </div>
            </div>
          </div>
          }
          {
            loading &&
                <div className="row loading-message-row">
                  <ProgressSpinner className={ `spinner` }/> 
                </div>
          }
          {
            errors &&
                <div className="row errors-message-row">
                  <span>
                    { errors }
                  </span>
                  <Button type="cancel" onClick={ getDepositAddress }>
                    { t(`Retry`) }
                  </Button>
                </div>
          }
        </div> 
      </Modal>
    </div>
  );
};

// @flow
'use strict';

import { UPDATE_USER_THEME, REWRITE_USER_THEME } from '../index.js';
import type { Theme } from '../../types/Theme.js';

export function updateUserTheme(key: string, color: any) {
  return {
    type: UPDATE_USER_THEME,
    payload: { key, color }
  };
}

export function rewriteUserTheme(theme: Theme) {
  return {
    type: REWRITE_USER_THEME,
    payload: theme
  };
}

// @flow
'use strict';

import React from 'react';
import Checkbox from '../utilities/Checkbox.jsx';
import ArrowUp from "../../svgs/ArrowUp.jsx";
import ArrowDown from "../../svgs/ArrowDown.jsx";
import type { Market } from '../../types/Market.js';
import type { Favorite } from '../../types/Favorite.js';
import type { Exchange } from '../../types/Exchange.js';

type Props = {
  title?: string,
  markets: Array<Market>,
  favorites: Array<Favorite>,
  active: boolean,
  toggleFavorite: (market: Market) => void,
  onActive: (id: number) => void,
  exchange: Exchange
};

const FavoriteExchange  = ({
  markets,
  favorites,
  active,
  onActive,
  toggleFavorite,
  exchange
}: Props) => {

  return (
    <div className="favorite-market">
      <div className="title">
        <a onClick={ onActive }>
          <img
            className={ `exch-img` }
            src={ window.WWW_URL + `/assets/img/exchange/` + exchange.exchCode + `-icon.png` }
            onError={ (e) => {e.target.onerror = null; e.target.src=`${ window.WWW_URL }/assets/img/currency/empty.png`;} } 
            width={ `15rem` }
            height={ `15rem` }/>
          <span className={ `exch-name` }>
            { ` ${exchange.exchName}` }
          </span>
          <span className={ `exch-code` }>
            { exchange.exchCode }
          </span>
          <span className="caret">
            { active ? ArrowUp : ArrowDown }
          </span>
        </a>
      </div>
      <div className={ active ? `body-markets in` : `body-markets` }>
        <div className="inner">
          { markets.sort((a: Market, b: Market) => {
            if (a.displayName > b.displayName) return 1;
            if (a.displayName < b.displayName) return -1;
            return 0;
          })
            .map((market) => {
              return (
                <Checkbox
                  key={ market.marketId }
                  label={ market.displayName }
                  name={ market.marketId.toString() }
                  value={ favorites.some((favorite) => favorite.exchCode == market.exchCode && favorite.displayName == market.displayName) }
                  onChange={ () => toggleFavorite && toggleFavorite(market) } />
              );
            })
          }
        </div>
      </div>
    </div>
  );
};

export default FavoriteExchange;

// @flow
'use strict';

import React from 'react';
import TheCryptDataLayer from '../components/theCrypt/TheCryptDataLayer.jsx';

const TheCryptPage = () => {
  return (
    <TheCryptDataLayer />
  );
};

export default TheCryptPage;


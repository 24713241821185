'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default (uniqueID = `CollapseRight` ) => SvgHelper(
  `<defs><path d="M2.414 7l5.293-5.293A1 1 0 0 0 6.293.293l-6 6a1 1 0 0 0 0 1.414l6 6a1 1 0 1 0 1.414-1.414L2.414 7z" id="${ uniqueID }a"/></defs><use fill="#BDD1FF" fill-rule="nonzero" class="collapse-right-icon" xlink:href="#${ uniqueID }a" transform="matrix(-1 0 0 1 8 0)"/>`,
  {
    width: `1rem`,
    height: `1.75rem`,
    viewBox: `0 0 8 14`
  }
);
/* eslint-enable */

// @flow
`use strict`;

import { $ } from "./ApiHelper";

export function getTrackableCurrencies(): Promise<any> {
  return new Promise((resolve, reject) => {
    $({
      url: `/private/chains/trackable`,
      success: resolve,
      error: reject
    });
  });
}

export function getSingleCurrency(currCode: string): Promise<any> {
  return new Promise((resolve, reject) => {
    $({
      url: `/public/currencies/${currCode}`,
      success: resolve,
      error: reject
    });
  });
}

export function getCurrencyImages(): Promise<any> {
  return new Promise((resolve, reject) => {
    $({
      url: `/public/currencies/images`,
      success: resolve,
      error: reject
    });
  });
}

export function getAllCurrencies(options: any, onSuccess: (data: any) => void) {
  const { isFiat, exchCode } = options.params;

  const qs = [
    `${isFiat ? `IsFiat=true`:``}`,
    `${exchCode ? `ExchCode=${exchCode}`:``}`
  ];
  
  $({
    url: `/public/currencies?${qs.filter((s) => s).join(`&`)}`,
    success: onSuccess,
  });
}

export function getSummariesCurrencies(options: any, onSuccess: (data: any) => void) {
  const { SearchTerm, Range, PageNumber, PageSize, Sort, SortOrder } = options.params;
  const qs = [
    `${SearchTerm ? `SearchTerm=`+SearchTerm:``}`, 
    `${Range !== undefined ? `Range=`+Range:``}`, 
    `${PageNumber !== undefined ? `PageNumber=`+PageNumber:``}`, 
    `${PageSize !== undefined ? `PageSize=`+PageSize:``}`,
    `${Sort !== undefined ? `Sort=`+Sort:``}`,
    `${SortOrder !== undefined ? `SortOrder=`+SortOrder:``}`
  ];
  $({
    url: `/public/currencies/summaries?${qs.filter((s) => s).join(`&`)}`,
    success: onSuccess,
  });
}

export function getDeadCurrencies(): Promise<any> {
  return new Promise((resolve, reject) => {
    $({
      url: `/private/currencies/dead`,
      success: resolve,
      error: reject
    });
  });
}

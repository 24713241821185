// @flow
'use strict';

import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import ArrowDown from '../../svgs/ArrowDown.jsx';
import { formatLongNumber, toFixedDown } from '../../helpers/NumberHelper.js';
import type { Currency } from '../../types/Currency';
import PillToggle from "../utilities/PillToggle.jsx";
import SentimentGauge from '../utilities/SentimentGauge.jsx';
import NewsFeed from '../utilities/NewsFeed.jsx';
import MarketEvents from '../markets/MarketEvents.jsx';
import MarketEventModal from './MarketEventModal.jsx';
import { updateInsightsSections } from '../../actions/markets/updateInsightsSections.js';
import CollapseDown from '../../svgs/CollapseDown.jsx';
import CollapseRight from '../../svgs/CollapseRight.jsx';
import * as newsApi from '../../helpers/api/NewsApi.js';
import * as exchangeApi from '../../helpers/api/ExchangeApi.js';
import moment from 'moment';

type Props = {
  t: any,
  baseCurrency: Currency,
  quoteCurrency: Currency,
  baseCurrencyItbSignals: any,
  quoteCurrencyItbSignals: any,
  currencies: Array<Currency>,
  searchTerm: any,
  sectionPrefs: Array<string>,
  changeInsightsSections: (n: any) => void,
  cmCalCategories?: any,
  cmCalCoins: any
};

type State = {
  marketCapCurrency: string,
  currencyDropdownOpen: boolean,
  mode: string,
  searchTerm: string,
  news: Array<{
    title: string,
    id: string,
    url: string,
    feedImage: string,
    feedName: string,
    pubDate: string
  }>,
  events: any,
  eventModalOpen: boolean,
  activeEvent: any
};

class MarketInsights extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let marketCapCurrency = ``;

    let state: State = {
      marketCapCurrency,
      currencyDropdownOpen: false,
      mode: `baseCurrency`,
      searchTerm: ``,
      news: [],
      events: [],
      eventModalOpen: false,
      activeEvent: []
    };
    const currency = this.props[state.mode];

    if (currency.marketCap) {
      state.marketCapCurrency = Object.keys(JSON.parse(currency.marketCap))[0];
    }

    this.state = state;
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {

    const currency = nextProps[prevState.mode];
    let marketCapCurrency = prevState.marketCapCurrency;

    if (currency && currency.marketCap && Object.keys(JSON.parse(currency.marketCap)).length > 0) {
      if (marketCapCurrency === ``) {
        marketCapCurrency = Object.keys(JSON.parse(currency.marketCap))[0];
      }
    } else {
      marketCapCurrency = ``;
    }

    return { marketCapCurrency };
  }

  toggleCurrencyDropdown(e: any) {
    if (!e) return false;
    e.preventDefault();

    this.setState({
      currencyDropdownOpen: !this.state.currencyDropdownOpen
    });

    return false;
  }

  setMarketCapCurrency(e: any, marketCapCurrency: string) {
    if (!e) return false;
    e.preventDefault();
    const currency = this.props[this.state.mode];
    if (currency && Object.keys(currency.marketCap).length === 0) return false;
    if (currency && currency.marketCap
      && !Object.keys(JSON.parse(currency.marketCap)).includes(marketCapCurrency)) {
      return false;
    }

    this.setState({
      marketCapCurrency,
      currencyDropdownOpen: false
    });

    return false;
  }

  changeResearchMode(mode: string) {
    if (!mode || !this.props.hasOwnProperty(mode)) return;
    const currency = this.props[mode];
    let marketCapCurrency;

    if (currency.marketCap) {
      marketCapCurrency = Object.keys(JSON.parse(currency.marketCap))[0];
    }

    this.setState({ mode, marketCapCurrency },
      () => { 
        this.handleSearchChange(currency.currName.length > 0 ? currency.currName : currency.currCode);
      });
    
  }

  handleSearchChange(search: string) {
    if (search !== this.state.searchTerm) {
      this.setState({ searchTerm: search, events: [] });
      search && search.length > 2 ? this.getNewsFeed(search) : this.getNewsFeed(``);
      this.getCoinMarketCalEvents();
    }
  }

  async getNewsFeed(searchTerm?: string) {
    try {
      let news = await newsApi.fetchNews(searchTerm);
      if (news.success && news.result.length > 0) {
        this.setState({ news: news.result });
      }else {
        this.setState({ news: [] });
        const currency = this.props[this.state.mode];

        news = await newsApi.fetchNews(currency.currCode);
        if (news.success && news.result.length > 0) {
          this.setState({ news: news.result });
        }else {
          this.setState({ news: [] });
        }
      }

    } catch (ex) {
      this.setState({ news: [] });
    }
  }

  async getCoinMarketCalEvents() {
    this.setState({ events: [] });

    const cmCalCoins = this.props.cmCalCoins;
    const cmCalCoin = cmCalCoins.find((c) => c.symbol === this.props[this.state.mode].currCode);

    try {
      if (cmCalCoin && cmCalCoin.id) {

        const dateRangeStart = moment().subtract(90,`d`).format(`YYYY-MM-DD`);
        const dateRangeEnd = moment().add(90,`d`).format(`YYYY-MM-DD`);
        let events = [];
        events = await exchangeApi.getCoinMarketCalEvents(cmCalCoin.id, dateRangeStart, dateRangeEnd);
        if (events && events.body) {
          this.setState({ events: events.body });
        }
      
      }
      
    } catch (ex) {
      this.setState({ events: [] });
    }
  }

  handleEventClick(e: any) {
    const activeEvent = this.state.events.find((item) => item.id == e);
    this.setState({ eventModalOpen: true, activeEvent });
  }

  closeEventModal() {
    this.setState({ eventModalOpen: false, activeEvent: [] });
  }

  toggleSection(sectionString: string) {
    if (this.props.sectionPrefs.includes(sectionString)) {
      const sectionsArr = this.props.sectionPrefs.filter((item) => item !== sectionString);
      this.props.changeInsightsSections(sectionsArr);

    }else {
      this.props.changeInsightsSections([...this.props.sectionPrefs, sectionString]);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.baseCurrency.currCode !== prevProps.baseCurrency.currCode
      || this.props.quoteCurrency.currCode !== prevProps.quoteCurrency.currCode) {
      const currency = this.props[this.state.mode];
      this.handleSearchChange(currency.currName.length > 0 ? currency.currName : currency.currCode);
    }

    if (prevProps.cmCalCoins !== this.props.cmCalCoins) {
      this.getCoinMarketCalEvents();
    }
  }

  componentDidMount() {
    this.getNewsFeed(this.props.baseCurrency.currName);
    this.getCoinMarketCalEvents();
  }

  render() {
    const currency = this.props[this.state.mode];
    const itbSignals = (this.state.mode === `baseCurrency` ? this.props.baseCurrencyItbSignals : this.props.quoteCurrencyItbSignals);
    
    // Remove ~ (tilde) in exchange-specific currency codes (MEME~BTRX becomes MEME)
    const displayBaseCurrCode = this.props.baseCurrency.currCode.indexOf(`~`) > -1 ? this.props.baseCurrency.currCode.split(`~`)[0] : this.props.baseCurrency.currCode;
    const displayQuoteCurrCode = this.props.quoteCurrency.currCode.indexOf(`~`) > -1 ? this.props.quoteCurrency.currCode.split(`~`)[0] : this.props.quoteCurrency.currCode;

    const getFormattedMarketCapCurrency = (marketCapCurrency: number) => marketCapCurrency > 1 ? formatLongNumber(toFixedDown(marketCapCurrency, 2), false) : formatLongNumber(marketCapCurrency, false);

    return (
      <div className="market-insights">
        <div className={ `market-insights-search` }>
          <PillToggle
            options={ [
              { label: this.props.baseCurrency.currName + ` (` + displayBaseCurrCode + `)`, value: `baseCurrency` },
              { label: this.props.quoteCurrency.currName + ` (` + displayQuoteCurrCode + `)`, value: `quoteCurrency` }
            ] }
            value={ this.state.mode }
            onChange={ (e, v) => this.changeResearchMode(v) }/>
          { /* <div
            className={ `news-search ${ this.state.searchTerm.length < 3 ? `greyed` : `` }` }
            onFocus={ () => this.setState({ mode: `searchTerm` }) }>
            <TextField
              label={ `Search` }
              name={ `news-search-input` }
              value={ this.state.searchTerm }
              onChange={ (e) => this.handleSearchChange(e.target.value) }/>
          </div> */ }
        </div>
        { /* <div className="search-results-text">
          Results for "
          { this.state.searchTerm }
            ":
        </div> */ }
        {
          currency && currency.currCode && (
            <div
              className="section-heading fade-in"
              onClick={ () => this.toggleSection(`currency`) }>
              <div>
                { this.props.t(`app:currency`) }
              </div>
              <div>
                {
                  this.props.sectionPrefs.includes(`currency`) ? (<CollapseDown />) : (<CollapseRight />)
                }
              </div>
            </div>
          )

        }
        {

          this.props.sectionPrefs.includes(`currency`) && currency.currCode && (
          
            <div className="research-info fade-in">
              <div className="research-currency">
                <div className="research_details">
                  <div className="research-header">
                    <div className="research_logo">
                      <img
                        src={ currency.urlLogo ? currency.urlLogo : `https://www.coinigy.com/assets/img/currency/`+currency.currCode+`.png` }
                        onError={ 
                          (e)=>{e.target.onerror = null; e.target.src=`https://www.coinigy.com/assets/img/currency/empty.png`;} 
                        } />
                    </div>
                    <div>
                      {
                        currency && currency.currCode ? (
                          <div className="research_name">
                            { currency.currName }
                            { ` ` }
                      (
                            { this.state.mode === `baseCurrency` ? displayBaseCurrCode : displayQuoteCurrCode }
                      )
                          </div>
                        ) : ``
                      }
                      <div className="research_description">
                        <p>
                          { currency.description }
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="research-social">
                    {
                      [
                        {
                          key: `urlAnnThread`,
                          title: this.props.t(`annThread`),
                          img: `/assets/img/bitcointalk.png`
                        },
                        {
                          key: `urlTwitter`,
                          title: this.props.t(`twitter`),
                          img: `/assets/img/twitter.png`
                        },
                        {
                          key: `urlFacebook`,
                          title: this.props.t(`facebook`),
                          img: `/assets/img/facebook.png`
                        },
                        {
                          key: `urlLinkedin`,
                          title: this.props.t(`linkedin`),
                          img: `/assets/img/linkedin.png`
                        },
                        {
                          key: `urlGplus`,
                          title: this.props.t(`googleplus`),
                          img: `/assets/img/googleplus.png`
                        },
                        {
                          key: `urlForum`,
                          title: this.props.t(`forum`),
                          img: `/assets/img/forum.png`
                        },
                        {
                          key: `urlWallet`,
                          title: this.props.t(`wallet`),
                          img: `/assets/img/wallet.png`
                        },
                        {
                          key: `urlWebsite`,
                          title: this.props.t(`website`),
                          img: `/assets/img/website.png`
                        },
                        {
                          key: `urlWhitepaper`,
                          title: this.props.t(`whitepaper`),
                          img: `/assets/img/whitepaper.png`
                        },
                        {
                          key: `urlBlockExplorer`,
                          title: this.props.t(`blockExplorer`),
                          img: `/assets/img/blockexplorer.png`
                        }
                      ].map((l, i) => {
                        if (currency.hasOwnProperty(l.key) && currency[l.key] && currency[l.key].toString().length > 0) {
                          return (
                            <a key={ i } target="_blank" rel="noopener noreferrer"  href={ currency[l.key] } title={ l.title }>
                              <div className={ `social-icon ` + l.key } />
                            </a>
                          );
                        }
                      })
                    }
                  </div>
                  <div className="research-market">
                    {
                      this.state.marketCapCurrency && this.state.marketCapCurrency.length > 0 && currency.marketCap ? (
                        <div className="market_element">
                          <div>
                            { this.props.t(`marketCap`) }
                          </div>
                          <div>
                            { getFormattedMarketCapCurrency(JSON.parse(currency.marketCap)[this.state.marketCapCurrency]) }
                            {
                              Object.keys(JSON.parse(currency.marketCap)).length > 1 ? (
                                <span className="currency clickable" onClick={ this.toggleCurrencyDropdown.bind(this) }>
                                  <span>
                                    { this.state.marketCapCurrency.toUpperCase() }
                                  </span>
                                  <span>
                                    { ArrowDown }
                                  </span>
                                  {
                                    this.state.currencyDropdownOpen ? (
                                      <ul className="dropdown">
                                        {
                                          Object.keys(JSON.parse(currency.marketCap)).map((c, i) => {
                                            if (c == this.state.marketCapCurrency) return ``;

                                            return (
                                              <li
                                                key={ i }
                                                onClick={ (e) => this.setMarketCapCurrency(e, c) }>
                                                { c.toUpperCase() }
                                              </li>
                                            );
                                          })
                                        }
                                      </ul>
                                    ) : ``
                                  }
                                </span>
                              ) : (
                                <span className="currency">
                                  <span>
                                    { this.state.marketCapCurrency.toUpperCase() }
                                  </span>
                                </span>
                              )
                            }
                          </div>
                        </div>
                      ) : ``
                    }
                    {
                      currency.availableSupply && currency.availableSupply.length > 0 ? (
                        <div className="market_element">
                          <div>
                            { this.props.t(`availableSupply`) }
                          </div>
                          <div>
                            { currency.availableSupply }
                          </div>
                        </div>
                      ) : ``
                    }
                    {
                      currency.miningTotalCirculation && currency.miningTotalCirculation.length > 0 ? (
                        <div className="market_element">
                          <div>
                            { this.props.t(`totalCirculation`) }
                          </div>
                          <div>
                            { currency.miningTotalCirculation }
                          </div>
                        </div>
                      ) : ``
                    }
                  </div>
                </div>
              </div>
            </div>
          )
        }
        {
          this.state.events.length > 0 && (
            <div
              className="section-heading fade-in"
              onClick={ () => this.toggleSection(`events`) }>
              <div>
                Events
              </div>
              <div>
                {
                  this.props.sectionPrefs.includes(`events`) ? (<CollapseDown />) : (<CollapseRight />)
                }
              </div>
            </div>
          )
        }
        {
          this.state.events.length > 0 && this.props.sectionPrefs.includes(`events`) && (
            <div className="research-events fade-in">
              <MarketEvents
                events={ this.state.events }
                currCode={ currency ? currency.currCode : `none` }
                onClickHandler={ this.handleEventClick.bind(this) } />
            </div>
          )
        }
        {
          this.state.eventModalOpen && (
            <MarketEventModal
              label={ `Confirm Order` }
              currCode={ currency ? currency.currCode : `none` }
              event={ this.state.activeEvent }
              close={ this.closeEventModal.bind(this) } />
          )
        }
        {
          itbSignals && (
            <div
              className="section-heading fade-in"
              onClick={ () => this.toggleSection(`signals`) }>
              <div>
                Signals
              </div>
              <div>
                {
                  this.props.sectionPrefs.includes(`signals`) ? (<CollapseDown />) : (<CollapseRight />)
                }
              </div>
            </div>
          )

        }
        {
          this.props.sectionPrefs.includes(`signals`) && itbSignals && (
            <div className="research-meters fade-in">
              {
                itbSignals?.signals?.find((s) => s.name === `addresses_net_growth`) && (
                  <SentimentGauge
                    title={ itbSignals.signals.find((s) => s.name === `addresses_net_growth`).title }
                    value={ itbSignals.signals.find((s) => s.name === `addresses_net_growth`).value }
                    score={ itbSignals.signals.find((s) => s.name === `addresses_net_growth`).score }
                    lowThreshold={ itbSignals.signals.find((s) => s.name === `addresses_net_growth`).thresholds.bearish }
                    highThreshold={ itbSignals.signals.find((s) => s.name === `addresses_net_growth`).thresholds.bullish }
                    info={ itbSignals.signals.find((s) => s.name === `addresses_net_growth`).info } />
                )
              }
              {
                itbSignals?.signals?.find((s) => s.name === `in_out_var`) && (
                  <SentimentGauge
                    title={ itbSignals.signals.find((s) => s.name === `in_out_var`).title }
                    value={ itbSignals.signals.find((s) => s.name === `in_out_var`).value }
                    score={ itbSignals.signals.find((s) => s.name === `in_out_var`).score }
                    lowThreshold={ itbSignals.signals.find((s) => s.name === `in_out_var`).thresholds.bearish }
                    highThreshold={ itbSignals.signals.find((s) => s.name === `in_out_var`).thresholds.bullish }
                    info={ itbSignals.signals.find((s) => s.name === `in_out_var`).info } />
                )
              }
              {
                itbSignals?.signals?.find((s) => s.name === `concentration_var`) && (
                  <SentimentGauge
                    title={ itbSignals.signals.find((s) => s.name === `concentration_var`).title }
                    value={ itbSignals.signals.find((s) => s.name === `concentration_var`).value }
                    score={ itbSignals.signals.find((s) => s.name === `concentration_var`).score }
                    lowThreshold={ itbSignals.signals.find((s) => s.name === `concentration_var`).thresholds.bearish }
                    highThreshold={ itbSignals.signals.find((s) => s.name === `concentration_var`).thresholds.bullish }
                    info={ itbSignals.signals.find((s) => s.name === `concentration_var`).info } />
                )
              }
              {
                itbSignals?.signals?.find((s) => s.name === `largetxs_var`) && (
                  <SentimentGauge
                    title={ itbSignals.signals.find((s) => s.name === `largetxs_var`).title }
                    value={ itbSignals.signals.find((s) => s.name === `largetxs_var`).value }
                    score={ itbSignals.signals.find((s) => s.name === `largetxs_var`).score }
                    lowThreshold={ itbSignals.signals.find((s) => s.name === `largetxs_var`).thresholds.bearish }
                    highThreshold={ itbSignals.signals.find((s) => s.name === `largetxs_var`).thresholds.bullish }
                    info={ itbSignals.signals.find((s) => s.name === `largetxs_var`).info } />
                )
              }
              <a
                href={ `https://app.intotheblock.com/coin/` + currency.currCode + `?pid=coinigy&utm_source=coinigy_widget` }
                className={ `itb-link` } target={ `_blank` }>
                <div className={ `itb-logo-container` }>
                  <img className={ `itb-logo` } src={  `/assets/img/intotheblock.svg` }/> 
                </div>
                <div className={ `itb-text-container` }>
              See more signals &raquo;
                </div>
              </a>
            </div>
          )
        }
        {
          this.state.news.length > 0 && (
            <div
              className="section-heading fade-in"
              onClick={ () => this.toggleSection(`news`) }>
              <div>
                News
              </div>
              <div>
                {
                  this.props.sectionPrefs.includes(`news`) ? (<CollapseDown />) : (<CollapseRight />)
                }
              </div>
            </div>
          )
        }
        {
          this.props.sectionPrefs.includes(`news`) && this.state.news.length > 0 && (
            <div className="market-news-section fade-in">
              <div className="news-info">
                <NewsFeed
                  news={ this.state.news } />
              </div>
            </div>
          )
        }
      </div>
    );
  }
}


// export { MarketInsights as PureMarketInsights };
// export default translate(`markets`)(MarketInsights);

const mapStateToProps = (state) => ({
  sectionPrefs: state.markets.insightsSections ? state.markets.insightsSections : [`currency`, `news`]
});

const mapDispatchToProps = (dispatch) => ({
  changeInsightsSections: (p) => dispatch(updateInsightsSections(p))
});

export { MarketInsights as PureMarketInsights };
export default translate(`markets`)(connect(mapStateToProps, mapDispatchToProps)(MarketInsights));

// @flow
'use strict';

import { RESET_ALL_BINDINGS } from '../index.js';

export function resetAllBindings() {
  return {
    type: RESET_ALL_BINDINGS
  };
}

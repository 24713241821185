// @flow
'use strict';

import { UPDATE_USER_DATA } from '../index.js';
import type { User } from '../../types/User.js';
import type { UserPrefs } from '../../types/UserPrefs.js';

export function updateUserData({ user, userPrefs }: { user?: User, userPrefs?: UserPrefs }) {
  return {
    type: UPDATE_USER_DATA,
    payload: { user, userPrefs }
  };
}

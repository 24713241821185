// @flow
'use strict';

import React from 'react';
import AlertsDataLayer from '../components/alerts/AlertsDataLayer.jsx';
import type { RouteProps } from '../types/RouteProps.js';

const AlertsPage = ({ markets, exchanges }: RouteProps) => {

  return (
    <AlertsDataLayer 
      markets={ markets }
      exchanges={ exchanges } />
  );
};

export default AlertsPage;

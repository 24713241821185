// @flow
'use strict';

import React from 'react';
import { withRouter } from 'react-router-dom';
import type { ProfileProps } from './ProfileDataLayer.jsx';
import UserProfile from './UserProfile.jsx';
import PrimeTrustContactInformation from './PrimeTrustContactInformation.jsx';

const LSCXAccountEditor = (props: ProfileProps) => {
  const { t, user, save, onChange } = props;

  if (!user) return null;

  return (
    <div className="my-account">
      <h1 className="page-title">
        { t(`header:userProfile`) }
      </h1>

      <section className="lscx-container billing-subscription-info">
        <UserProfile 
          t={ t }
          user={ user }
          save={ save }
          onChange={ onChange }/>

        <PrimeTrustContactInformation t={ t } user={ user }/>
      </section>
    </div>
  );
};


export default withRouter(LSCXAccountEditor);

// @flow
'use strict';

import React from 'react';
import Bug from "../../../svgs/Bug.jsx";
import { Link } from 'react-router-dom';
import { emitEvent, SHOW_REPORTBUG_MODAL } from '../../../helpers/EventHelper.js';


type MenuLinkProps = {
  closeDropdownMenus: any,
  text: string,
}

export const ReportBugLink = (props: MenuLinkProps) => {
  const { text, closeDropdownMenus } = props;
  return (
    <Link onClick={ () => { closeDropdownMenus(); emitEvent(SHOW_REPORTBUG_MODAL);  } } to={ {} }>
      { Bug }
      <span>
        { text }
      </span>
    </Link>
  );
};

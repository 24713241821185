// @flow
'use strict';

export default {
  SHOW_KEYBOARD_LEGEND: `?`,
  TOGGLE_MARKET_SWITCHER: `[`,
  TOGGLE_MARKET_INFO: `]`,
  TOGGLE_MARKET_ORDERS_TABLE: `'`,
  TOGGLE_MARKET_CHART: `=`,
  FOCUS_MARKET_FILTER: `f`,
  ENTER: `enter`,
  ESCAPE: `esc`,
  SHOW_LARGER_BOTTOM_MARKETS_PANEL: `o`,
  GO_TO_NEXT_MARKET: `k`,
  GO_TO_PREVIOUS_MARKET: `i`,
  GO_TO_NEXT_EXCHANGE: `j`,
  GO_TO_PREVIOUS_EXCHANGE: `u`,
  FOCUS_CHART: `c`,
  OPEN_DATA_TAB: `d`,
  OPEN_TRADE_TAB: `t`,
  OPEN_ALERTS_TAB: `a`,
  OPEN_INSIGHTS_TAB: `e`,
  OPEN_NOTES_TAB: `n`,
  OPEN_BUY: `b`,
  OPEN_SELL: `s`
};

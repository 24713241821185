// @flow
'use strict';

import React from 'react';
import { withRouter } from 'react-router-dom';
import Button from '../utilities/Button.jsx';
import TextField from '../utilities/TextField.jsx';
import EntityTable from '../utilities/EntityTable.jsx';
import Modal from '../utilities/Modal.jsx';
import Search from '../../svgs/Search.jsx';
import Trash from '../../svgs/Trash.jsx';
import Documentation from '../../svgs/Documentation.jsx';
import Github from '../../svgs/Github.jsx';
import type { ProfileProps } from './ProfileDataLayer.jsx';
import ApiDeleteModal from '../user/ApiDeleteModal.jsx';

type State = {
  filterText: string,
  modalOpen: boolean,
  showPrivateChannelId: boolean,
  deleteApiModalOpen: boolean,
  deletingKeyId: number
};

class AccountAPI extends React.Component<ProfileProps, State> {
  constructor(props: ProfileProps) {
    super(props);

    this.state = {
      filterText: ``,
      modalOpen: false,
      showPrivateChannelId: false,
      deleteApiModalOpen: false,
      deletingKeyId: 0
    };
  }

  handleFilterChange(e: any) {
    if (!e) return;
    this.setState({
      filterText: e.target.value
    });
  }

  modal(open: boolean, shouldReturn: boolean = false): any {
    if (![true, false].includes(open)) return;
    this.setState({
      modalOpen: open,
      showPrivateChannelId: false
    });
    if (shouldReturn) {
      return false;
    }
  }

  onShowPrivateChannelId(e: any) {
    if (e) e.preventDefault();
    this.setState({
      showPrivateChannelId: true
    });
    return false;
  }

  revokeKeyApi(key: string, e: any) {
    if (!key) return false;
    if (e) e.preventDefault();
    if (this.props.revokeKeyApi) {
      this.props.revokeKeyApi(key);
      this.closeDeleteApiModal();
      return true;
    }
    return false;
  }

  displaySecret(row: any, secret: string) {
    return secret ? secret : `[ ${ this.props.t(`hidden`) } ]`;
  }

  displayDateCreated(row: any, date: string) {
    return date.slice(0, 10);
  }

  displayRevoke(row: any) {
    if (!row) return null;
    return (
      <Button
        type="cancel"
        //onClick={ this.revokeKeyApi.bind(this, row.key) }>
        onClick={ () => { this.setState({ deleteApiModalOpen: true, deletingKeyId: row.key }); return true; } }>
        { Trash(`Trash${ row.key }`) }
      </Button>
    );
  }

  generateKey() {
    if (this.props.generateKey) {
      return this.props.generateKey();
    }
    return false;
  }

  closeDeleteApiModal() {
    this.setState({ deleteApiModalOpen: false, deletingKeyId: undefined });
  }

  render () {
    let columns = [
      {
        title: this.props.t(`key`),
        key: `key`
      },
      {
        title: this.props.t(`secret`),
        key: `secret`,
        display: this.displaySecret.bind(this)
      },
      {
        title: this.props.t(`created`),
        key: `dateCreated`,
        display: this.displayDateCreated.bind(this)
      },
      {
        title: this.props.t(`version`),
        key: `apiVersion`
      },
      {
        title: ``,
        key: `note`
      },
      {
        title: ``,
        key: `revoke`,
        nosort: true,
        class: `revoke`,
        display: this.displayRevoke.bind(this)
      }
    ];

    return (

      <div className="api-account">
        {
          this.state.deleteApiModalOpen && (
            <ApiDeleteModal
              t={ this.props.t }
              title={ this.props.t(`app:genericConfirm`) }
              close={ () => this.closeDeleteApiModal() }
              authKey={ this.state.deletingKeyId }
              confirm={ (key) => this.revokeKeyApi(key) } />
          )
        }
        <section>
          <div className='trading-api'>
            <div className="top">
              <h1 className="page-title">
                { this.props.t(`tradingApi`) }
              </h1>

              <Button
                type="primary"
                onClick={ this.generateKey.bind(this) }>
                { this.props.t(`generateKey`) }
              </Button>
            </div>
            <div>
              <TextField
                icon={ Search }
                label={ this.props.t(`app:search`) }
                name={ `search` }
                value={ this.state.filterText }
                onChange={ this.handleFilterChange.bind(this) } />
            </div>
            <EntityTable
              columns={ columns }
              data={ this.props.apiKeys || [] }
              defaultSortBy="-dateCreated"
              pageSize={ `all` }
              hidePagination={ true }
              filterText={ this.state.filterText } />
          </div>
        </section>

        <div className='trading-doc'>
          <section>
            <div>
              <h1>
                { `Documentation and Code` }
              </h1>
              <div className='text'>
                { `Explore all our API Documentation (v1) on Apiary and Docs and code examples for all Coinigy developer APIs on GitHub.` }
              </div>
              <div>
                <Button useSpanInsteadOfButton onClick={ () => { return false;} } className="secondary">
                  <a target="_blank" rel="noopener noreferrer" href="https://api.coinigy.com/api/v2/docs/">
                    <span>
                      { Documentation }
                      { this.props.t(`apiDocs`) }
                    </span>
                  </a>
                </Button>
              </div>
              <div>
                <Button useSpanInsteadOfButton onClick={ () => { return false;} } className="secondary">
                  <a target="_blank" rel="noopener noreferrer" href="https://github.com/coinigy/">
                    <span>
                      { Github }
                      { this.props.t(`cgyGithub`) }
                    </span>
                  </a>
                </Button>
              </div>
            </div>
          </section>

          <section>
            <div>
              <h1>
                { `Private Channel API` }
              </h1>
              <div className='text'>
                { `Your Private Channel allows access to sensitive account information via the Websocket API.` }
              </div>
              <div>
                <Button
                  type="cancel"
                  onClick={ this.modal.bind(this, true) }>
                  { this.props.t(`revealPrivateChannelId`) }
                </Button>
              </div>      
            </div>
          </section>
        </div>
        {
          this.state.modalOpen && (
            <Modal
              title=""
              onClose={ this.modal.bind(this, false, true) }>
              <div className="madal-dialog">
                <span>
                  { this.props.t(`privateChannelId`) }
                </span>
                { !this.state.showPrivateChannelId && (
                  <div className="preview-dialog">
                    <p>
                      { this.props.t(`privateChannelWarning`) }
                    </p>
                    <Button
                      type="secondary"
                      onClick={ this.onShowPrivateChannelId.bind(this) }>
                      { this.props.t(`understood`) }
                    </Button>
                  </div>
                )
                }
                { this.state.showPrivateChannelId && (
                  <div className="private-dialog">
                    <p>
                      { this.props.t(`privateChannelDialog`) }
                    </p>
                    <p>
                      { this.props.t(`socketAPIInfo`) }
                    </p>
                    <p className="private_channel_id">
                      { this.props.privateChannelId }
                    </p>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://coinigy.docs.apiary.io/#reference/websocket-api">
                      { this.props.t(`websocketAPIDocs`) }
                    </a>
                    { /* <p> TODO: add link to reset user hash
                    To reset your Private Channel ID at any time,
                    <a>
                      { ` click here.` }
                    </a>
                  </p> */ }
                  </div>
                )
                }
              </div>
            </Modal>
          )
        }
      </div>
    );
  }
}

export default withRouter(AccountAPI);

'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path d="M17.8571 0H2.14286C1.57454 0 1.02949 0.225765 0.627628 0.627628C0.225765 1.02949 0 1.57454 0 2.14286L0 17.8571C0 18.4255 0.225765 18.9705 0.627628 19.3724C1.02949 19.7742 1.57454 20 2.14286 20H8.27009V13.2004H5.45759V10H8.27009V7.56071C8.27009 4.78616 9.92188 3.25357 12.4518 3.25357C13.6634 3.25357 14.9304 3.46964 14.9304 3.46964V6.19286H13.5344C12.1589 6.19286 11.7299 7.04643 11.7299 7.92188V10H14.8004L14.3094 13.2004H11.7299V20H17.8571C18.4255 20 18.9705 19.7742 19.3724 19.3724C19.7742 18.9705 20 18.4255 20 17.8571V2.14286C20 1.57454 19.7742 1.02949 19.3724 0.627628C18.9705 0.225765 18.4255 0 17.8571 0Z" fill="currentColor"/>`,
  {
    width: `20px`,
    height: `20px`,
    viewBox: `0 0 20 20`
  }
);
/* eslint-enable */

// @flow
'use strict';

import {
  TOGGLE_MARKET_CLICKABILITY,
  UPDATE_ONLINE
} from '../actions/index.js';

type State = {
  marketsAreClickable: boolean
};

type Action = {
  type: string,
  payload: any
};

export const initialState: State = {
  marketsAreClickable: true,
  online: false,
};

export default function browser(state: State = initialState, action: Action) {
  switch(action.type) {
  case TOGGLE_MARKET_CLICKABILITY:
    return {
      ...state,
      marketsAreClickable: action.payload
    };
  case UPDATE_ONLINE:
    return {
      ...state,
      online: action.payload.online
    };
  }
  return state;
}

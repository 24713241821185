// @flow
'use strict';

import React, { useEffect } from 'react';
import Button from '../utilities/Button.jsx';


import Close from '../../svgs/Close.jsx';
import { 
  listenForEvent, 
  removeEventListener,
  ESCAPE
} from '../../helpers/EventHelper.js';

type Props = {
  title: any,
  confirmText?: string,
  cancelText?: string,
  onConfirm?: () => void,
  onClose: () => void,
  children?: any,
  confirmDisabled?: boolean,
  titleIcon?: string,
  titleLink?: any,
  displayOnClose?: boolean,
  handleEscape?: boolean,
};

const Modal = (props: Props) => { 
  const { title, confirmText, cancelText, onConfirm, onClose, children, confirmDisabled, titleIcon, titleLink, displayOnClose, handleEscape = true } = props;
  const isDisplayOnClose = displayOnClose == false || displayOnClose === undefined;
  useEffect(() => {
    if (handleEscape) {
      listenForEvent(ESCAPE, handleEscapePress);
      return () => {
        removeEventListener(ESCAPE, handleEscapePress);
      };
    }
  }, [handleEscape]);

  const handleEscapePress = () => {
    onClose();
  };

  return (
    <div className="modal__wrapper">
      <div 
        className="modal__backdrop"
        onClick={ () => { onConfirm ? null : onClose(); } } />
      <div className={ `modal__inner ${ cancelText || (confirmText && onConfirm) ? `has-actions` : `` }` }>
        <div className="modal__header">
          <h3 className="title">
            { titleIcon && ( <img src={ titleIcon } className={ `title-icon` } /> ) }
            { title }
            { titleLink }
          </h3>
          { isDisplayOnClose &&
            <a className={ `modal-close` } onClick={ () => onClose() } data-testid={ `close-link` }>
              { Close() }
            </a>
          }

        </div>
        <div className="modal__body">
          { children }
        </div>
        <div className="modal__actions">
          {
            cancelText && (
              <Button
                type="cancel"
                onClick={ () => {
                  onClose();
                  return true;
                } }>
                { cancelText }
              </Button>
            )
          }
          {
            confirmText && onConfirm && (
              <Button
                type={ cancelText ? `confirm` : `primary` }
                disabled={ !!confirmDisabled }
                onClick={ () => {
                  onConfirm();
                  return true;
                } }>
                { confirmText }
              </Button>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default Modal;

// @flow
'use strict';

import React from 'react';
import { useStateRef } from '../../helpers/useStateRef';
import { translate } from 'react-i18next';
import Checkbox from '../utilities/Checkbox.jsx';
import Collapsible from '../utilities/Collapsible.jsx';
import Refresh from '../../svgs/Refresh.jsx';
import { toLocalDate } from '../../helpers/DateHelper.js';
import type { Balance } from '../../types/Balance.js';
import type { Account } from '../../types/Account.js';
import type { Exchange } from '../../types/Exchange.js';

type Props = {
  t: any,
  refreshBalance: (n: number, d: boolean) => Promise<any>,
  exchanges: Array<Exchange>,
  balances: Array<Balance>,
  filters: Array<Balance>,
  accounts: Array<Account>,
  className: string,
  onChangeFilter: (Array<Balance>) => void
};

type AccountBalance = Account & {
  balances: Array<Balance>
};

type ExchangeAccount = Exchange & {
  accounts: Array<AccountBalance>
};

const BalancesSwitcher = (props: Props) =>  {
  const [refreshing, setRefreshing, refreshing_ref] = useStateRef([]);
  const { t, refreshBalance, exchanges: propsExchanges, balances: propsBalances, filters: propsFilters, accounts: propsAccounts, className, onChangeFilter } = props;

  const handleFilterChange = (balances: Array<Balance>, value: boolean) => {
    let filters = propsFilters.filter((f) => {
      return !balances.find((b) => {
        return b.balanceAuthId == f.balanceAuthId && b.balanceCurrId == f.balanceCurrId;
      });
    });

    if (!value) {
      filters = filters.concat(balances);
    }

    onChangeFilter(filters);
  };

  const balanceIsActive = (balance: Balance) => {
    return !propsFilters.find((filterBalance) => {
      return balance.balanceAuthId == filterBalance.balanceAuthId && 
             balance.balanceCurrId == filterBalance.balanceCurrId;
    });
  };

  const accountIsActive = (account: Account) => {
    return !propsFilters.find((balance) => account.authId == balance.balanceAuthId);
  };

  const accountIsPartiallyActive = (account: AccountBalance) => {
    let filteredBalances = propsFilters.filter((balance) => account.authId == balance.balanceAuthId).length;

    return filteredBalances < account.balances.length;
  };

  const exchangeIsActive = (exchange: ExchangeAccount) => {
    return exchange.accounts.filter((account) => !accountIsActive(account)).length == 0;
  };

  const exchangeIsPartiallyActive = (exchange: ExchangeAccount) => {
    return exchange.accounts.filter((account) => accountIsPartiallyActive(account)).length > 0;
  };

  const allActive = (exchanges: Array<ExchangeAccount>) => {
    return exchanges.filter((exchange) => !exchangeIsActive(exchange)).length == 0;
  };

  const anyActive = (exchanges: Array<ExchangeAccount>) => {
    return exchanges.filter((exchange) => exchangeIsPartiallyActive(exchange)).length > 0;
  };

  const getBalancesFromExchange = (exchange: ExchangeAccount): Array<Balance> => {
    return exchange.accounts.reduce((balances, account) => {
      return balances.concat(account.balances);
    }, []);
  };

  const refreshBalances = (paramRefreshing: Array<any>) => {
    const refreshingAccounts = refreshing_ref.current.concat(paramRefreshing);
    setRefreshing(refreshingAccounts);

    if (refreshingAccounts.length > 0) {
      refreshingAccounts.filter((r) => r !== `all`).forEach((account, index) => {
        refreshBalance(account, index + 1 !== refreshingAccounts.length);
      });
    }
    
    setTimeout(() => {
      setRefreshing(refreshing_ref.current.filter((r) => !paramRefreshing.includes(r)));
    }, 1000);  
  };

  const accountStalenessClass = (account: Account) => {
    let ms = +(new Date(account.authFetched.toString()));
    const now = Date.now();

    if (account.authVersion == 2) {
      const balances = +(new Date(account.balancesRefreshed));
      const orders = +(new Date(account.ordersRefreshed));

      if (balances < orders) ms = balances;
      if (orders <= balances) ms = orders;

      if (account.authTrade == false) {
        ms = balances;
      }
    }

    const SUPERFRESH = 300000;
    const GREEN = 43200000;
    const YELLOW = 259200000;

    if ((now - ms) <= SUPERFRESH) return `fresh`;
    if ((now - ms) <= GREEN) return `green`;
    if ((now - ms) <= YELLOW) return `yellow`;
    return `red`;
  };

  const accounts: Array<AccountBalance> = propsAccounts.filter((account) => account.authActive === true)
    .map((account) => ({
      ...account,
      balances: propsBalances
        .filter((balance) => balance.balanceAuthId == account.authId)
        .sort((a, b) => a.balanceCurrCode > b.balanceCurrCode ? 1 : -1)
    }));

  const exchanges: Array<ExchangeAccount> = propsExchanges.map((exchange) => ({
    ...exchange,
    accounts: accounts.filter((account) => account.authExchId == exchange.exchId)
  })).filter((exchange) => exchange.accounts.length > 0);

  return (
    <div className={ `aside ${ className }` }>
      <h2>
        { t(`Filter`) }
      </h2>
      <div className="select-all">
        <Checkbox
          name={ `all` }
          label={ t(`All accounts/wallets`) }
          value={ allActive(exchanges) } 
          partial={ anyActive(exchanges) }
          onChange={ (e) => {
            handleFilterChange(propsBalances, e.target.checked);
          } } />
        <span
          onClick={ 
            () => !refreshing_ref.current.includes(`all`) && 
              refreshBalances([`all`].concat(
                propsAccounts.filter((a) => a.authActive == true)
                  .map((a) => a.authId)
              )) 
          }
          className={ 
            `refresh-balance ${ refreshing.includes(`all`) ? `animate` : `` }` 
          }>
          { Refresh() }
        </span>
      </div>
      <div className="options">
        { 
          exchanges.sort((a, b) => 
            a.exchName === b.exchName ? 0 : (a.exchName > b.exchName ? 1 : -1)
          ).map((exchange) => (
            <Collapsible
              key={ `collapsible-${ exchange.exchCode }` }
              title={ 
                <Checkbox
                  name={ exchange.exchName }
                  label={
                    <span className={ `exch-container` }> 
                      <img
                        className={ `exch-img` }
                        src={ window.WWW_URL + `/assets/img/exchange/` + exchange.exchCode + `-icon.png` } 
                        onError={ (e) => {e.target.onerror = null; e.target.src=`${ window.WWW_URL }/assets/img/currency/empty.png`;} } 
                        width={ `20rem` }
                        height={ `20rem` }/>
                      <span className={ `exch-name` }>
                        { exchange.exchName } 
                        { ` ` }
                        { exchange.exchCode === `LSCX` && `*` }
                      </span>
                    </span>
                  }
                  value={ exchangeIsActive(exchange) }
                  partial={ exchangeIsPartiallyActive(exchange) }
                  onChange={ (e) => {
                    handleFilterChange(getBalancesFromExchange(exchange), e.target.checked);
                  } } /> 
              }>
              { 
                exchange.accounts.map((account) => (
                  <Collapsible
                    key={ `collapsible-${ exchange.exchCode }-${ account.authId }` }
                    title={ 
                      <span>
                        <Checkbox
                          name={ `auth-${ account.authId }` }
                          label={ 
                            <span>
                              { 
                                account.authNickname 
                                  ? (
                                    account.authNickname.length > 21 
                                      ? account.authNickname.substring(0,21) + `…`
                                      : account.authNickname
                                  ) 
                                  : account.authNickname
                              }
                            </span>
                          }
                          value={ accountIsActive(account) }
                          partial={ accountIsPartiallyActive(account) }
                          title={ `Last Updated: ${ toLocalDate(account.authFetched.toString()) }` }
                          onChange={ (e) => {
                            handleFilterChange(account.balances, e.target.checked);
                          } } />
                        <span
                          onClick={ 
                            () => !refreshing_ref.current.includes(account.authId) && refreshBalances([account.authId]) 
                          }
                          className={ 
                            `refresh-balance ${refreshing.includes(account.authId) ? `animate` : `` } ` +
                            `${ accountStalenessClass(account) }`
                          }>
                          { Refresh() }
                        </span>
                      </span>
                    }>
                    { 
                      account.balances.length > 0 ? account.balances.map((balance) => (
                        <div
                          className="item"
                          key={ 
                            `collapsible-checkbox-${ exchange.exchCode }-` + 
                            `${ account.authId }-${ balance.balanceCurrId }` 
                          }>
                          <Checkbox
                            name={ `${ balance.balanceCurrCode }-${ balance.balanceAuthId }` }
                            label={ balance.balanceCurrCode }
                            value={ balanceIsActive(balance) }
                            onChange={ (e) => { 
                              handleFilterChange([balance], e.target.checked);
                            } } />
                        </div>
                      ))
                        : (
                          <div
                            className="item"
                            key={ 
                              `collapsible-checkbox-${ exchange.exchCode }-` + 
                            `${ account.authId }-nobalance` 
                            }>
                            <Checkbox
                              name={ `${ exchange.exchCode }-${ account.authId }` }
                              label={ t(`balances:noBalance`) }
                              value={ false }
                              onChange={ (e) => { 
                                handleFilterChange([], e.target.checked);
                              } } />
                          </div>
                        )
                    }
                  </Collapsible>
                ))
              }
            </Collapsible>
          ))
        }
      </div>
    </div>
  );
};

export { BalancesSwitcher as PureBalancesSwitcher };
export default translate(`balances`)(BalancesSwitcher);

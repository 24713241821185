// @flow
'use strict';
import React, { useState, useEffect, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { translate } from 'react-i18next';
import Dropzone from 'react-dropzone';
import Modal from '../utilities/Modal.jsx';
import Button from '../utilities/Button.jsx';
import Warning from '../../svgs/Warning.jsx';
import type { User } from '../../types/User.js';
import { 
  createPrimeTrustToken, 
  getPrimeTrustAccount, 
  getPrimeTrustContacts, 
  postPrimeTrustAccountId, 
  uploadKycDocuments, 
  postKycDocumentCheck, 
  patchContactById,
  postApplicationStarted } from '../../helpers/api/UserApi.js';
//, getPrimeTrustToken removed 5/5/22
import {
  emitEvent,
  NOTIFICATION
} from '../../helpers/EventHelper.js';
import PrimeTrustTermsIframe from '../utilities/PrimeTrustTermsIframe.jsx';
import TextField from '../utilities/TextField.jsx';
import type { ptContact } from '../../types/ptContact.js';
import type { UserApplication } from '../../types/UserApplication.js';
import { getUserApplication } from '../../helpers/UserApplicationsHelper';


type Props = {
  t: any,
  theme: any,
  user: User,
  onClose: () => void,
};

const PrimeTrustAccountModal = (props: Props) => {
  const { t, user, onClose } = props;
  const [step, setStep] = useState(1);
  const [ptToken, setPtToken] = useState(null);
  const [showDropzone, setShowDropzone] = useState(false);
  const [showContactInfo, setShowContactInfo] = useState(false);
  const [ptUserNumber, setPtUserNumber] = useState(0);
  const [ptUserStatus, setPtUserStatus] = useState(null);
  const [ptContacts, setPtContacts] = useState([]);
  const [ptContactId, setPtContactId] = useState(0);
  const [kycDocuments, setKycDocuments] = useState([]);
  const [errorText, setErrorText] = useState(``);
  const [ptRequiredActions, setPtRequiredActions] = useState(``);
  const [contactsToPatch, setContactsToPatch] = useState<ptContact>({});

  const userApplications = useSelector((state) => state.userInfo.userApplications);
  const applicationsStatuses = useSelector((state) => state.userInfo.applicationsStatuses);

  const debug = true;
  const uat = false;

  const log = (msg) => {
    debug && console.info(msg);
  };

  const userApplication: UserApplication = useMemo(() => getUserApplication(`LSCX`, userApplications, applicationsStatuses), [userApplications, applicationsStatuses]);

  // add PT script to the page only once
  useEffect(() => {
    const primeTrustScript = document.getElementById(`prime-trust-script`);
    if (!primeTrustScript) {
      const script = document.createElement(`script`);
      script.src = uat ? `https://sandbox.bootstrapper.primetrust-cdn.com/bootstrap.js` : `https://bootstrapper.primetrust-cdn.com/bootstrap.js`;
      script.id = `prime-trust-script`;
      script.async = true;
      document.body ? document.body.appendChild(script) : null;
    }
    return () => {
      if (step == 3) {
        // We are intentionally provoking a page reload to clean up undesired additional eventListener registered by PrimeTrust
        // More info here: https://gitlab.coinigy.com/v20/web-app/-/issues/1086
        setTimeout(function() {
          window.location.reload();
        }, 5000);
        
      }
    };
  }, [step]);

  useEffect(() => {
    if (step == 1) {
      // If user hasn't signed the agreement yet, show it to them.
      if (userApplication.isAgreementSigned) {
        setStep(2);
      } 
    }
    if (step == 2) {
      // User has accepted agreement, so attempt to put them through PT Account Creation Wizard
      setPtToken(null);
      if (!userApplication.isAwaiting) {
        // According to our database, the user has already applied
        log(`== lscxApplied=true`);
        
        // Attempt to find their account if one exists
        //getPrimeTrustAccountUAT({}, (data) => {
        getPrimeTrustAccount({}, (data) => {
          if (data.success == true) {
            log(`== Bosonic account exists! (lscxApplied=true)`);
            // Assign their userNumber and status (open/closed)
            setPtUserNumber(data.result.number);
            setPtUserStatus(data.result.status[0].toUpperCase() + data.result.status.substring(1));
          }
        });
        
      } else {
        log(`== lscxApplied=false`);
        //getPrimeTrustAccountUAT({}, (data) => {
        getPrimeTrustAccount({}, (data) => {
          if (data.success == true) {
            // Account exists on Bosonic but we don't show them as having applied
            log(`== Bosonic account exists! (lscxApplied=false)`);
            setPtUserNumber(data.result.number);
            setPtUserStatus(data.result.status[0].toUpperCase() + data.result.status.substring(1));

          } else {
            // Account doesn't exist on Bosonic
            log(`== Bosonic account doesnt exist`);
            // Fetch previous token
            //getPrimeTrustToken({}, (data) => {  
            createPrimeTrustToken({}, (data) => {
              if (data.success) {
                // A previous token exists, so use it.
                log(`== Previous token exists. setting token to ` + data.result);
                setPtToken(data.result);
              } else {
                // No previous token, so create a new one.
                log(`== No previous token. creating a new one`);
                //console.log(data);
                //createPrimeTrustTokenUAT({}, (data) => {  
                createPrimeTrustToken({}, (data) => { 
                  if (data.success) {
                    log(`== Successfully created new token`);
                    setPtToken(data.result);
                  }else {
                    log(`== Error, unable to create a new token`);
                    // TODO
                    setErrorText(`Unable to fetch a token. Please contact support. Code: 126`);
                  }
                });
              }
            });
          }
        });
      }
    }
    if (step == 3 && ptToken) {
      // launch widget
      window.primeTrustReady = function (pt) {
        pt.launchCreateAccount({
          target: document.getElementById(`prime-trust-widget`),
          width: `900`,
          height: `250`,
          resourceTokenHash: ptToken,
          events: {
            resourceCreated: (accountId) => {
              postPrimeTrustAccountId({ accountId: accountId, ptWidgetToken: ptToken }, (data) => {
                if (!data.success) {
                  emitEvent(NOTIFICATION, {
                    notification_id: new Date().getTime(),
                    title: `Error`,
                    title_vars: ``,
                    message_raw: `An error occurred during the registration process: ${data.error}`,
                    message: `An error occurred during the registration process: ${data.error}`,
                    message_vars: ``,
                    pinned: true,
                    style: `error`,
                    url: ``
                  });
                  onClose();
                }
                if (data.success) {
                  setStep(4);
                }
              });
            }
          }
        });
      };
    }
  }, [step]);

  // Execute code when ptUserNumber and ptUserStatus are populated
  useEffect(() => {
     
    if (ptUserNumber > 0 && ptUserStatus && !userApplication.isAccountClosed) {
      log(`== user exists, their account number is ` + ptUserNumber + ` and account status = ` + ptUserStatus);
      // getPrimeTrustContactsUAT({}, (data) => { 
      getPrimeTrustContacts({}, (data) => { 
        // Fetch list of contacts under this account
        if (data.result) {
          setPtContacts(data.result);
          setPtContactId(data.result[0].id);
        }
      });
    }
    log(`== lscxAccountClosed = ` + userApplication.isAccountClosed?.toString());
    if (userApplication.isAwaiting) {
      // Account is not marked as closed in our db, so we'll let them attempt to fetch a token and re-apply.
      createPrimeTrustToken({}, (data) => {
        if (data.success) {
          // A previous token exists, so use it.
          log(`== Previous token exists. setting token to ` + data.result + ` (alt code block)`);
          setPtToken(data.result);
        } else {
          // No previous token, so create a new one.
          log(`== No previous token. creating a new one (alt code block)`);
          //console.log(data);
          //createPrimeTrustTokenUAT({}, (data) => {  
          createPrimeTrustToken({}, (data) => { 
            if (data.success) {
              log(`== Successfully created new token (alt code block)`);
              setPtToken(data.result);
            }else {
              log(`== Error, unable to create a new token (alt code block)`);
              // TODO
              setErrorText(`Unable to fetch a token. Please contact support. Code: 206`);
            }
          });
        } 
      });
    }
  }, [ptUserNumber, ptUserStatus]);

  useEffect(() => {
    if (ptContacts.length > 0) {
      log((`== contacts exist for this user. they are as follows:`));
      //log(ptContacts);
      
      ptContacts.forEach((contact) => {
        log(contact);
        //requiredActions = requiredActions + contact.kycR
        contact.kycRequiredActions.forEach((ra) => {
          setPtRequiredActions(ptRequiredActions + ra.description + ` `);
        });
      });
    }
  }, [ptContacts]);

  const displayRequiredActions = () => {
    return (
      <>
        { /* <b>
Account Status:
          { ` ` }
        </b>
        { ` ` }
        { ptUserStatus }
        <br/>
        <br/> */ }
        <b>
Required Actions:
        </b>
        { ` ` }
        { ptRequiredActions }
      </>
    );
  };

  const warningSection = () => {
    return (
      <div className={ `warning-section` }>
        <span className={ `warning-icon` }>
          { Warning }
        </span>
        <span className={ `remember-user-email` }>
          { t(`rememberUserEmail`, { userEmail: user.email }) }
          { ` P.O. Boxes are not accepted.` }
        </span>
      </div>
    );
  };

  const onFileDrop = (acceptedFiles) => {
    log(acceptedFiles);
    
    const kycD = kycDocuments;
    acceptedFiles.forEach((af) => {
      kycD.push({ name: af.path, status: `Uploading...`, id: ``, fileType: ``, fileCountry: `` });
      setKycDocuments(kycD);
    });

    uploadKycDocuments({ Image: acceptedFiles, contactId: ptContactId }, (data) => {
      log(`== uploading document...`);  
      let i = 0;
      if (data.success === true) {
        log(`== document upload success`);
        let kycD = kycDocuments;
        for (i = 0; i < acceptedFiles.length; i++) {
          kycD[kycD.length - (i+1)] = { name: kycD[kycD.length - (i+1)].name, status: `Upload complete.`, id: data.result.data.id, fileType: ``, fileCountry: `` };
        }
        setKycDocuments(kycD);
      } else {
        log(`== document upload error`);
        let kycD = kycDocuments;
        for (i = 0; i < acceptedFiles.length; i++) {
          kycD[kycD.length - (i+1)] = { name: kycD[kycD.length - (i+1)].name, status: `Upload failed. File format invalid or file too large.`, id: ``, fileType: ``, fileCountry: `` };
        }
        setKycDocuments(kycD);
      }
    }, () => {
      log(`== upload failure.`);
      let kycD = kycDocuments;
      for (var i = 0; i < acceptedFiles.length; i++) {
        kycD[kycD.length - (i+1)] = { name: kycD[kycD.length - (i+1)].name, status: `Upload failed. File format invalid or file too large.`, id: ``, fileType: ``, fileCountry: `` };
      }
      setKycDocuments(kycD);
    });
    return acceptedFiles;
  };


  const displayKycContacts = () => {
    if (ptContacts.length > 0) {
      
      const ptc = ptContacts[0];
      
      const maskedSSN = ptc.taxIdNumber ? ptc.taxIdNumber.replace(/\d(?=\d{4})/mg, `#`) : ``;

      const businessFields = [
        {
          label: `Entity Name`,
          name: `Name`,
          value: contactsToPatch.hasOwnProperty(`name`) ? contactsToPatch.name : (ptc.name || ``),
          key: `name`
        },
        {
          label: `Entity Phone Number (Including Country Code)`,
          name: `PhoneNumber`,
          value: contactsToPatch.hasOwnProperty(`phoneNumber`) ? contactsToPatch.phoneNumber : (ptc.primaryPhoneNumber.number || ``),
          key: `phoneNumber`
        },
        {
          label: `Entity Address Country`,
          name: `Country`,
          value: contactsToPatch.hasOwnProperty(`country`) ? contactsToPatch.country : (ptc.primaryAddress.country || ``),
          key: `country`
        },
        {
          label: `Street Address`,
          name: `Street1`,
          value: contactsToPatch.hasOwnProperty(`street1`) ? contactsToPatch.street1 : (ptc.primaryAddress.street1 || ``),
          key: `street1`
        },
        {
          label: `Street Address 2 (Apt, Suit, etc.)`,
          name: `Street2`,
          value: contactsToPatch.hasOwnProperty(`street2`) ? contactsToPatch.street2 : (ptc.primaryAddress.street2 || ``),
          key: `street2`
        },
        {
          label: `City`,
          name: `City`,
          value: contactsToPatch.hasOwnProperty(`city`) ? contactsToPatch.city : (ptc.primaryAddress.city || ``),
          key: `city`
        },
        {
          label: `State`,
          name: `Region`,
          value: contactsToPatch.hasOwnProperty(`region`) ? contactsToPatch.region : (ptc.primaryAddress.region || ``),
          key: `region`
        },
        {
          label: `Zip Code`,
          name: `PostalCode`,
          value: contactsToPatch.hasOwnProperty(`postalCode`) ? contactsToPatch.postalCode : (ptc.primaryAddress.postalCode || ``),
          key: `postalCode`
        },
        {
          label: `Tax Country`,
          name: `TaxCountry`,
          value: contactsToPatch.hasOwnProperty(`taxCountry`) ? contactsToPatch.taxCountry : (ptc.taxCountry || ``),
          key: `taxCountry`
        },
        {
          label: `Tax State`,
          name: `TaxState`,
          value: contactsToPatch.hasOwnProperty(`taxState`) ? contactsToPatch.taxState : (ptc.taxState || ``),
          key: `taxState`
        },
        {
          label: `Tax Id Number`,
          name: `TaxIdNumber`,
          value: contactsToPatch.hasOwnProperty(`taxIdNumber`) ? contactsToPatch.taxIdNumber : (maskedSSN || ``),
          key: `taxIdNumber`
        },
      ];

      
      const personFields = [
        {
          label: `Full Name`,
          name: `Name`,
          value: contactsToPatch.hasOwnProperty(`name`) ? contactsToPatch.name : (ptc.name || ``),
          key: `name`
        },
        {
          label: `Phone Number (Including Country Code)`,
          name: `PhoneNumber`,
          value: contactsToPatch.hasOwnProperty(`phoneNumber`) ? contactsToPatch.phoneNumber : (ptc.primaryPhoneNumber.number || ``),
          key: `phoneNumber`
        },
        {
          label: `Country of Residence`,
          name: `Country`,
          value: contactsToPatch.hasOwnProperty(`country`) ? contactsToPatch.country : (ptc.primaryAddress.country || ``),
          key: `country`
        },
        {
          label: `Street Address`,
          name: `Street1`,
          value: contactsToPatch.hasOwnProperty(`street1`) ? contactsToPatch.street1 : (ptc.primaryAddress.street1 || ``),
          key: `street1`
        },
        {
          label: `Street Address 2 (Apt, Suit, etc.)`,
          name: `Street2`,
          value: contactsToPatch.hasOwnProperty(`street2`) ? contactsToPatch.street2 : (ptc.primaryAddress.street2 || ``),
          key: `street2`
        },
        {
          label: `City`,
          name: `City`,
          value: contactsToPatch.hasOwnProperty(`city`) ? contactsToPatch.city : (ptc.primaryAddress.city || ``),
          key: `city`
        },
        {
          label: `State / Region`,
          name: `Region`,
          value: contactsToPatch.hasOwnProperty(`region`) ? contactsToPatch.region : (ptc.primaryAddress.region || ``),
          key: `region`
        },
        {
          label: `Postal Code`,
          name: `PostalCode`,
          value: contactsToPatch.hasOwnProperty(`postalCode`) ? contactsToPatch.postalCode : (ptc.primaryAddress.postalCode || ``),
          key: `postalCode`
        },
        {
          label: `Tax Country`,
          name: `TaxCountry`,
          value: contactsToPatch.hasOwnProperty(`taxCountry`) ? contactsToPatch.taxCountry : (ptc.taxCountry || ``),
          key: `taxCountry`
        },
        {
          label: `Tax State`,
          name: `TaxState`,
          value: contactsToPatch.hasOwnProperty(`taxState`) ? contactsToPatch.taxState : (ptc.taxState || ``),
          key: `taxState`
        },
        {
          label: `Tax Id Number`,
          name: `TaxIdNumber`,
          value: contactsToPatch.hasOwnProperty(`taxIdNumber`) ? contactsToPatch.taxIdNumber : (maskedSSN || ``),
          key: `taxIdNumber`
        },
        {
          label: `Date of Birth`,
          name: `DateOfBirth`,
          value: contactsToPatch.hasOwnProperty(`dateOfBirth`) ? contactsToPatch.dateOfBirth : (ptc.dateOfBirth || ``),
          key: `dateOfBirth`
        },
        // { // replaced with ClientInput
        //   label: `Phone Number`,
        //   name: `PhoneNumber`,
        //   value: ptc.primaryPhoneNumber.number || ``,
        //   key: `PhoneNumber`
        // },
        // {
        //   label: `Accepts SMS`,
        //   name: `Sms`,
        //   value: contactsToPatch.sms ? contactsToPatch.sms : (ptc.primaryPhoneNumber.sms || ``),
        //   key: `sms`
        // },
      ];

      const fieldsToMap = ptc.contactType === `natural_person` ? [...personFields] : [...businessFields];

      return (
        <div className="group-input">
          {
            fieldsToMap.map((props, i) => (
              <TextField 
                key={ i }
                { ...props } 
                onChange={ (e) => { 
                  
                  //let editedContactsToPatch = contactsToPatch;
                  //editedContactsToPatch[props.key] = e.target.value;
                 
                  const updatedValue = {};
                  updatedValue[props.key] = e.target.value;
                  updatedValue[`contactId`] = ptContacts[0].id;

                  const updatedObject: ptContact = () => {
                    return {
                      ...contactsToPatch,
                      ...updatedValue
                    };
                  };
                  
                  setContactsToPatch(updatedObject);
                  
                }
                } />
            ))
          }
        </div>
      );
      
    }
  };

  const displayKycDocuments = () => {
    if (kycDocuments.length > 0) {
      return (<div className="kyc-documents">
        <span className='attached-docs'>
Attached Documents:
        </span>        
        <br/>
        { kycDocuments.map((doc, i) => {
          return (<div key={ doc.name + i }>
            { doc.name }
            { ` - ` }
            <select
              className={ `select-file-type` }
              name={ `fileType` + i } value={ kycDocuments[i].fileType } onChange={ (e) => {
                let newKycDocuments = kycDocuments;
                newKycDocuments[i].fileType = e.target.value;
                setKycDocuments(newKycDocuments);  
              } }>
              <option value="">
Document Type...
              </option>
              <option value="DRIVERS_LICENSE">
Drivers License
              </option>
              <option value="GOVERNMENT_ID">
Government ID
              </option>
              <option value="RESIDENCE_PERMIT">
Residence Permit
              </option>
              <option value="UTILITY_BILL">
Utility Bill
              </option>
              <option value="PASSPORT">
Passport
              </option>
              <option value="OTHER">
Other
              </option>
            </select>
            { ` - ` }
            <select
              className={ `select-file-country` }
              name={ `fileCountry` + i } value={ kycDocuments[i].fileCountry } onChange={ (e) => {
                let newKycDocuments = kycDocuments;
                newKycDocuments[i].fileCountry = e.target.value;
                setKycDocuments(newKycDocuments);  
              } }>
              <option value="">
Country...
              </option>
              <option value="AF">
Afghanistan
              </option>
              <option value="AX">
Åland Islands
              </option>
              <option value="AL">
Albania
              </option>
              <option value="DZ">
Algeria
              </option>
              <option value="AS">
American Samoa
              </option>
              <option value="AD">
Andorra
              </option>
              <option value="AO">
Angola
              </option>
              <option value="AI">
Anguilla
              </option>
              <option value="AQ">
Antarctica
              </option>
              <option value="AG">
Antigua and Barbuda
              </option>
              <option value="AR">
Argentina
              </option>
              <option value="AM">
Armenia
              </option>
              <option value="AW">
Aruba
              </option>
              <option value="AU">
Australia
              </option>
              <option value="AT">
Austria
              </option>
              <option value="AZ">
Azerbaijan
              </option>
              <option value="BS">
Bahamas
              </option>
              <option value="BH">
Bahrain
              </option>
              <option value="BD">
Bangladesh
              </option>
              <option value="BB">
Barbados
              </option>
              <option value="BY">
Belarus
              </option>
              <option value="BE">
Belgium
              </option>
              <option value="BZ">
Belize
              </option>
              <option value="BJ">
Benin
              </option>
              <option value="BM">
Bermuda
              </option>
              <option value="BT">
Bhutan
              </option>
              <option value="BO">
Bolivia, Plurinational State of
              </option>
              <option value="BQ">
Bonaire, Sint Eustatius and Saba
              </option>
              <option value="BA">
Bosnia and Herzegovina
              </option>
              <option value="BW">
Botswana
              </option>
              <option value="BV">
Bouvet Island
              </option>
              <option value="BR">
Brazil
              </option>
              <option value="IO">
British Indian Ocean Territory
              </option>
              <option value="BN">
Brunei Darussalam
              </option>
              <option value="BG">
Bulgaria
              </option>
              <option value="BF">
Burkina Faso
              </option>
              <option value="BI">
Burundi
              </option>
              <option value="KH">
Cambodia
              </option>
              <option value="CM">
Cameroon
              </option>
              <option value="CA">
Canada
              </option>
              <option value="CV">
Cape Verde
              </option>
              <option value="KY">
Cayman Islands
              </option>
              <option value="CF">
Central African Republic
              </option>
              <option value="TD">
Chad
              </option>
              <option value="CL">
Chile
              </option>
              <option value="CN">
China
              </option>
              <option value="CX">
Christmas Island
              </option>
              <option value="CC">
Cocos (Keeling) Islands
              </option>
              <option value="CO">
Colombia
              </option>
              <option value="KM">
Comoros
              </option>
              <option value="CG">
Congo
              </option>
              <option value="CD">
Congo, the Democratic Republic of the
              </option>
              <option value="CK">
Cook Islands
              </option>
              <option value="CR">
Costa Rica
              </option>
              <option value="CI">
Côte d&apos;Ivoire
              </option>
              <option value="HR">
Croatia
              </option>
              <option value="CU">
Cuba
              </option>
              <option value="CW">
Curaçao
              </option>
              <option value="CY">
Cyprus
              </option>
              <option value="CZ">
Czech Republic
              </option>
              <option value="DK">
Denmark
              </option>
              <option value="DJ">
Djibouti
              </option>
              <option value="DM">
Dominica
              </option>
              <option value="DO">
Dominican Republic
              </option>
              <option value="EC">
Ecuador
              </option>
              <option value="EG">
Egypt
              </option>
              <option value="SV">
El Salvador
              </option>
              <option value="GQ">
Equatorial Guinea
              </option>
              <option value="ER">
Eritrea
              </option>
              <option value="EE">
Estonia
              </option>
              <option value="ET">
Ethiopia
              </option>
              <option value="FK">
Falkland Islands (Malvinas)
              </option>
              <option value="FO">
Faroe Islands
              </option>
              <option value="FJ">
Fiji
              </option>
              <option value="FI">
Finland
              </option>
              <option value="FR">
France
              </option>
              <option value="GF">
French Guiana
              </option>
              <option value="PF">
French Polynesia
              </option>
              <option value="TF">
French Southern Territories
              </option>
              <option value="GA">
Gabon
              </option>
              <option value="GM">
Gambia
              </option>
              <option value="GE">
Georgia
              </option>
              <option value="DE">
Germany
              </option>
              <option value="GH">
Ghana
              </option>
              <option value="GI">
Gibraltar
              </option>
              <option value="GR">
Greece
              </option>
              <option value="GL">
Greenland
              </option>
              <option value="GD">
Grenada
              </option>
              <option value="GP">
Guadeloupe
              </option>
              <option value="GU">
Guam
              </option>
              <option value="GT">
Guatemala
              </option>
              <option value="GG">
Guernsey
              </option>
              <option value="GN">
Guinea
              </option>
              <option value="GW">
Guinea-Bissau
              </option>
              <option value="GY">
Guyana
              </option>
              <option value="HT">
Haiti
              </option>
              <option value="HM">
Heard Island and McDonald Islands
              </option>
              <option value="VA">
Holy See (Vatican City State)
              </option>
              <option value="HN">
Honduras
              </option>
              <option value="HK">
Hong Kong
              </option>
              <option value="HU">
Hungary
              </option>
              <option value="IS">
Iceland
              </option>
              <option value="IN">
India
              </option>
              <option value="ID">
Indonesia
              </option>
              { /* <option value="IR">
Iran, Islamic Republic of
              </option> */ }
              <option value="IQ">
Iraq
              </option>
              <option value="IE">
Ireland
              </option>
              <option value="IM">
Isle of Man
              </option>
              <option value="IL">
Israel
              </option>
              <option value="IT">
Italy
              </option>
              <option value="JM">
Jamaica
              </option>
              <option value="JP">
Japan
              </option>
              <option value="JE">
Jersey
              </option>
              <option value="JO">
Jordan
              </option>
              <option value="KZ">
Kazakhstan
              </option>
              <option value="KE">
Kenya
              </option>
              <option value="KI">
Kiribati
              </option>
              { /* <option value="KP">
Korea, Democratic People&apos;s Republic of
              </option> */ }
              <option value="KR">
Korea, Republic of
              </option>
              <option value="KW">
Kuwait
              </option>
              <option value="KG">
Kyrgyzstan
              </option>
              <option value="LA">
Lao People&apos;s Democratic Republic
              </option>
              <option value="LV">
Latvia
              </option>
              <option value="LB">
Lebanon
              </option>
              <option value="LS">
Lesotho
              </option>
              <option value="LR">
Liberia
              </option>
              <option value="LY">
Libya
              </option>
              <option value="LI">
Liechtenstein
              </option>
              <option value="LT">
Lithuania
              </option>
              <option value="LU">
Luxembourg
              </option>
              <option value="MO">
Macao
              </option>
              <option value="MK">
Macedonia, the former Yugoslav Republic of
              </option>
              <option value="MG">
Madagascar
              </option>
              <option value="MW">
Malawi
              </option>
              <option value="MY">
Malaysia
              </option>
              <option value="MV">
Maldives
              </option>
              <option value="ML">
Mali
              </option>
              <option value="MT">
Malta
              </option>
              <option value="MH">
Marshall Islands
              </option>
              <option value="MQ">
Martinique
              </option>
              <option value="MR">
Mauritania
              </option>
              <option value="MU">
Mauritius
              </option>
              <option value="YT">
Mayotte
              </option>
              <option value="MX">
Mexico
              </option>
              <option value="FM">
Micronesia, Federated States of
              </option>
              <option value="MD">
Moldova, Republic of
              </option>
              <option value="MC">
Monaco
              </option>
              <option value="MN">
Mongolia
              </option>
              <option value="ME">
Montenegro
              </option>
              <option value="MS">
Montserrat
              </option>
              <option value="MA">
Morocco
              </option>
              <option value="MZ">
Mozambique
              </option>
              <option value="MM">
Myanmar
              </option>
              <option value="NA">
Namibia
              </option>
              <option value="NR">
Nauru
              </option>
              <option value="NP">
Nepal
              </option>
              <option value="NL">
Netherlands
              </option>
              <option value="NC">
New Caledonia
              </option>
              <option value="NZ">
New Zealand
              </option>
              <option value="NI">
Nicaragua
              </option>
              <option value="NE">
Niger
              </option>
              <option value="NG">
Nigeria
              </option>
              <option value="NU">
Niue
              </option>
              <option value="NF">
Norfolk Island
              </option>
              <option value="MP">
Northern Mariana Islands
              </option>
              <option value="NO">
Norway
              </option>
              <option value="OM">
Oman
              </option>
              <option value="PK">
Pakistan
              </option>
              <option value="PW">
Palau
              </option>
              <option value="PS">
Palestinian Territory, Occupied
              </option>
              <option value="PA">
Panama
              </option>
              <option value="PG">
Papua New Guinea
              </option>
              <option value="PY">
Paraguay
              </option>
              <option value="PE">
Peru
              </option>
              <option value="PH">
Philippines
              </option>
              <option value="PN">
Pitcairn
              </option>
              <option value="PL">
Poland
              </option>
              <option value="PT">
Portugal
              </option>
              <option value="PR">
Puerto Rico
              </option>
              <option value="QA">
Qatar
              </option>
              <option value="RE">
Réunion
              </option>
              <option value="RO">
Romania
              </option>
              <option value="RU">
Russian Federation
              </option>
              <option value="RW">
Rwanda
              </option>
              <option value="BL">
Saint Barthélemy
              </option>
              <option value="SH">
Saint Helena, Ascension and Tristan da Cunha
              </option>
              <option value="KN">
Saint Kitts and Nevis
              </option>
              <option value="LC">
Saint Lucia
              </option>
              <option value="MF">
Saint Martin (French part)
              </option>
              <option value="PM">
Saint Pierre and Miquelon
              </option>
              <option value="VC">
Saint Vincent and the Grenadines
              </option>
              <option value="WS">
Samoa
              </option>
              <option value="SM">
San Marino
              </option>
              <option value="ST">
Sao Tome and Principe
              </option>
              <option value="SA">
Saudi Arabia
              </option>
              <option value="SN">
Senegal
              </option>
              <option value="RS">
Serbia
              </option>
              <option value="SC">
Seychelles
              </option>
              <option value="SL">
Sierra Leone
              </option>
              <option value="SG">
Singapore
              </option>
              <option value="SX">
Sint Maarten (Dutch part)
              </option>
              <option value="SK">
Slovakia
              </option>
              <option value="SI">
Slovenia
              </option>
              <option value="SB">
Solomon Islands
              </option>
              <option value="SO">
Somalia
              </option>
              <option value="ZA">
South Africa
              </option>
              <option value="GS">
South Georgia and the South Sandwich Islands
              </option>
              <option value="SS">
South Sudan
              </option>
              <option value="ES">
Spain
              </option>
              <option value="LK">
Sri Lanka
              </option>
              <option value="SD">
Sudan
              </option>
              <option value="SR">
Suriname
              </option>
              <option value="SJ">
Svalbard and Jan Mayen
              </option>
              <option value="SZ">
Swaziland
              </option>
              <option value="SE">
Sweden
              </option>
              <option value="CH">
Switzerland
              </option>
              <option value="SY">
Syrian Arab Republic
              </option>
              <option value="TW">
Taiwan, Province of China
              </option>
              <option value="TJ">
Tajikistan
              </option>
              <option value="TZ">
Tanzania, United Republic of
              </option>
              <option value="TH">
Thailand
              </option>
              <option value="TL">
Timor-Leste
              </option>
              <option value="TG">
Togo
              </option>
              <option value="TK">
Tokelau
              </option>
              <option value="TO">
Tonga
              </option>
              <option value="TT">
Trinidad and Tobago
              </option>
              <option value="TN">
Tunisia
              </option>
              <option value="TR">
Turkey
              </option>
              <option value="TM">
Turkmenistan
              </option>
              <option value="TC">
Turks and Caicos Islands
              </option>
              <option value="TV">
Tuvalu
              </option>
              <option value="UG">
Uganda
              </option>
              <option value="UA">
Ukraine
              </option>
              <option value="AE">
United Arab Emirates
              </option>
              <option value="GB">
United Kingdom
              </option>
              <option value="US">
United States
              </option>
              <option value="UM">
United States Minor Outlying Islands
              </option>
              <option value="UY">
Uruguay
              </option>
              <option value="UZ">
Uzbekistan
              </option>
              <option value="VU">
Vanuatu
              </option>
              <option value="VE">
Venezuela, Bolivarian Republic of
              </option>
              <option value="VN">
Viet Nam
              </option>
              <option value="VG">
Virgin Islands, British
              </option>
              <option value="VI">
Virgin Islands, U.S.
              </option>
              <option value="WF">
Wallis and Futuna
              </option>
              <option value="EH">
Western Sahara
              </option>
              <option value="YE">
Yemen
              </option>
              <option value="ZM">
Zambia
              </option>
              <option value="ZW">
Zimbabwe
              </option>
            </select>
            <br/>
            <span className={ doc.status == `Upload complete.` ? `green` : `red` }>
              { doc.status }
            </span>
            <hr />
          </div>);
        }) }
      </div>);
    }
  };

  const submitKycDocuments = () => {
    log(`== fetching kyc documents`);
    
    kycDocuments.forEach((kycDoc) => {
      log(kycDoc);
      if (kycDoc.id.length > 0) {
        // This API call is hard-coded to USA right now
        // Tell PT to check the KYC document and do a new review
        postKycDocumentCheck({ contactId: ptContactId, uploadedDocumentId: kycDoc.id, kycDocumentCountry: kycDoc.fileCountry, kycDocumentType: kycDoc.fileType }, (data) => { 
          if (data.success) {
            emitEvent(NOTIFICATION, {
              notification_id: new Date().getTime(),
              title: `Document Submitted Successfully`,
              title_vars: ``,
              message_raw: `Your document, ${kycDoc.name}, was successfully submitted for review.`,
              message: `Your document, ${kycDoc.name}, was successfully submitted for review.`,
              message_vars: ``,
              pinned: false,
              style: `info`,
              url: ``
            });
            onClose();
          } else {
            emitEvent(NOTIFICATION, {
              notification_id: new Date().getTime(),
              title: `Document Submission Error`,
              title_vars: ``,
              message_raw: `We were unable to submit your document, ${kycDoc.name}, for review. Please try again.`,
              message: `We were unable to submit your document, ${kycDoc.name}, for review. Please try again.`,
              message_vars: ``,
              pinned: false,
              style: `error`,
              url: ``
            });
            onClose();
          }
        });
      }
    });
  };

  const validateKycDocuments = () => {
    let docsInvalid = false;
    kycDocuments.map((doc) => { 
      if (doc.fileType == ``) {
        docsInvalid = true;
      } 
      if (doc.fileCountry == ``) {
        docsInvalid = true;
      } 
    });
    if (kycDocuments.length < 1) {
      docsInvalid = true;
    }
    return docsInvalid;
  };
  
  if (userApplication.isAccountClosed) {
    return (
      <div className="prime-trust-modal">
        <Modal 
          titleIcon={ `${ window.WWW_URL }/assets/img/platform/LightspeedCrypto.png` }
          title={ `` } 
          onClose={ onClose }>
          <div className="ls-formatted-text">
            <h2>
              { t(`header:accountClosed`) }
            </h2>
            <p>
              We’re sorry, but your Lightspeed Crypto account has been closed.
            </p>
            <p>
            If you would like more information on why your account was closed, you can contact us by visiting our 
              { ` ` }
              <a className={ `blue` } href={ `https://lightspeedcrypto.com/en/why-us/` } rel="noopener noreferrer" target="_blank">
                support page
              </a>
              .
            </p>
          </div>
        </Modal>
      </div>
    );
  } else {
    return (userApplication.isDenied || userApplication.isVerified || ptRequiredActions.length > 0) ? ( // APPLICATION IS DENIED
      <div className={ `prime-trust-modal step-${step}` }>
        <Modal
          titleIcon={ `${ window.WWW_URL }/assets/img/platform/LightspeedCrypto.png` }
          title={ `` } 
          onClose={ onClose } >
          <div id={ `` } >
            { userApplication.isDenied && 
              <div className={ `denied` }>
                <h2>
  Application Denied
                </h2>
                <p>
                Unfortunately, we were unable to approve your application.  Please see below or check your e-mail for the status of your application and next steps.
                </p>
              </div>
            }

            {
              ptRequiredActions.length > 0 && !showDropzone && !showContactInfo && (
                <p>
                  {
                    displayRequiredActions()
                  }
                </p>
              )
            }
            {
              !showDropzone && !showContactInfo && (
                <>
                  <p>
                    <b>
To update your contact information and resubmit your documents, 
                      { ` ` }
                      <a className={ `blue` } onClick={ () => setShowContactInfo(true) }>
                        { ` ` }
                  please click here.
                        { ` ` }
                      </a>
                    </b>
                  </p>
                  { /* <p>
                    <b>
In the event that you need to resubmit your documents, 
                      { ` ` }
                      <a className={ `blue` } onClick={ () => setShowDropzone(true) }>
                        { ` ` }
                  please click here.
                        { ` ` }
                      </a>
                    </b>
                  </p> */ }
                </>
              )
            }
            
            { // KYC Contact Info Form
              showContactInfo && (
                <div className={ `contact-info-section` }>
                  { ptContacts.length > 0 && ptContacts[0].contactType === `natural_person` &&
                    <p className={ `step` }>
                      Step 1/2
                    </p>
                  }
                  <p>
                    Please edit and resubmit corrected contact information using the form below:
                  </p>
                  {
                    displayKycContacts()
                  } 
                  <Button 
                    className={ `submit-contacts-button` } 
                    type={ `confirm` }
                    disabled={ 
                      Object.keys(contactsToPatch).length < 1
                    }
                    onClick={ () => { 

                      let patchObj: ptContact = {};
                      const beforeObj: ptContact = {};

                      if (ptContacts[0].contactType === `company` && !contactsToPatch.hasOwnProperty(`name`)) {
                        beforeObj[`name`] = ptContacts[0].name;
                      }

                      // group street1, street2, city, country together
                      if (contactsToPatch.hasOwnProperty(`street1`) || 
                      contactsToPatch.hasOwnProperty(`street2`) || 
                      contactsToPatch.hasOwnProperty(`city`) || 
                      contactsToPatch.hasOwnProperty(`country`) ||
                      contactsToPatch.hasOwnProperty(`region`) ||
                      contactsToPatch.hasOwnProperty(`postalCode`)
                      ) {
                        beforeObj[`street1`] = ptContacts[0].primaryAddress.street1;
                        beforeObj[`street2`] = ptContacts[0].primaryAddress.street2;
                        beforeObj[`city`] = ptContacts[0].primaryAddress.city;
                        beforeObj[`country`] = ptContacts[0].primaryAddress.country;
                        beforeObj[`region`] = ptContacts[0].primaryAddress.region;
                      }

                      patchObj = {
                        ...beforeObj,
                        ...contactsToPatch
                      };

                      //console.log(contactsToPatch); 
                      log(`== PATCHing the following contact information: `);
                      log(patchObj);
                      

                      

                      patchContactById(patchObj, (data) => { 
                        if (data.success) {
                          if (data.result.contactType === `natural_person`) {
                            setShowDropzone(true);
                            setShowContactInfo(false);
                          } else {
                            onClose();
                          }

                          // comment out ghetto filecheck
                          // log(`== submitting a check on all previously uploaded documents (to trigger cip check)`);
    
                          // let kycCheckSubmitted = false;
                          // getKycDocuments(patchObj, (data) => { 
                          //   console.log(data);
                          //   if (data.success) {
                          //     console.log(data.result.data);

                              
                          //     data.result.data.forEach((kycDoc) => {
                          //       // add country from user's existing (or just-updated) contact info
                          //       if (patchObj.hasOwnProperty(`country`)) {
                          //         kycDoc[`fileCountry`] = patchObj.country;
                          //       }else if (ptContacts[0].primaryAddress.hasOwnProperty(`country`)) {
                          //         kycDoc[`fileCountry`] = ptContacts[0].primaryAddress.country;
                          //       }



                                
                          //       console.log(kycDoc);
                          //       postKycDocumentCheck({ contactId: patchObj.contactId, uploadedDocumentId: kycDoc.id, kycDocumentCountry: kycDoc.fileCountry, kycDocumentType: kycDoc.type  }, (data) => { 
                          //         log(`== kycDoc check result:`);
                          //         log(data);

                          //         if (data.success) {
                          //           kycCheckSubmitted = true;
                          //         }
                          //       });
                          //     });
                              
                          //   } else {
                          //     emitEvent(NOTIFICATION, {
                          //       notification_id: new Date().getTime(),
                          //       title: `Error`,
                          //       title_vars: ``,
                          //       message_raw: `Error while fetching documents.<br/>` + data.error.toUpperCase(),
                          //       message: `Error while fetching documents.<br/>` + data.error.toUpperCase(),
                          //       message_vars: ``,
                          //       pinned: false,
                          //       style: `error`,
                          //       url: ``
                          //     });    
                          //   }
                          // });
                          // if (kycCheckSubmitted == true) {
                          //   emitEvent(NOTIFICATION, {
                          //     notification_id: new Date().getTime(),
                          //     title: `Information Submitted Successfully`,
                          //     title_vars: ``,
                          //     message_raw: `Your updated information was successfully submitted for review.`,
                          //     message: `Your updated information was successfully submitted for review.`,
                          //     message_vars: ``,
                          //     pinned: false,
                          //     style: `info`,
                          //     url: ``
                          //   });
                          // } else {
                          //   emitEvent(NOTIFICATION, {
                          //     notification_id: new Date().getTime(),
                          //     title: `Submission Error`,
                          //     title_vars: ``,
                          //     message_raw: `There was an error while submitting your kyc check.`,
                          //     message: `There was an error while submitting your kyc check.`,
                          //     message_vars: ``,
                          //     pinned: false,
                          //     style: `error`,
                          //     url: ``
                          //   });
                          // }
                          
                          // onClose();
                        } else {
                          emitEvent(NOTIFICATION, {
                            notification_id: new Date().getTime(),
                            title: `Submission Error`,
                            title_vars: ``,
                            message_raw: `There was an error while submitting your information for review.<br/>` + data.error.toUpperCase(),
                            message: `There was an error while submitting your information for review.<br/>` + data.error.toUpperCase(),
                            message_vars: ``,
                            pinned: true,
                            style: `error`,
                            url: ``
                          });
                          onClose();
                        }
                      });

                      return true;
                    } }>
                    { `Submit Updated Information` }
                  </Button>
                  { ptContacts.length > 0 && ptContacts[0].contactType === `natural_person` &&
                    <Button 
                      className={ `skip-button` } 
                      type={ `primary` }
                      disabled={ 
                        false
                      }
                      onClick={ () => { setShowContactInfo(false); setShowDropzone(true); return true; } }>
                      { `Skip »` }
                    </Button>
                  }
                </div>
              )
            }
            { // KYC File Upload Form
              showDropzone && (
                <div className={ `upload-section` }>
                  <p className={ `step` }>
                    Step 2/2
                  </p>
                  <p>
                    Please upload your new documents here:
                  </p>
                  <div className={ `dropzone-container` }>
                    <Dropzone onDrop={ (acceptedFiles) => onFileDrop(acceptedFiles) } multiple={ false }>
                      { ({ getRootProps, getInputProps }) => (
                        <section className="dropzone">
                          <div { ...getRootProps() }>
                            <input type='file' name={ `dropzone-input` } id={ `dropzone-input` } { ...getInputProps() } />
                            <p>
                              Drag & drop some files here, or click to select files to upload.
                            </p>
                          </div>
                        </section>
                      ) }
                    </Dropzone>
                  </div>
                  <h5 className={ `blue` }>
                    Maximum file size: 8 MB. File types accepted: .jpg, .png, .pdf
                  </h5>
                  {
                    displayKycDocuments()
                  }
                  {
                    validateKycDocuments() && (
                      <p className={ `info-message` }>
Note: You must select a Document Type and Country for each file before submitting.
                      </p>
                    )
                  }
                  <Button 
                    className={ `submit-documents-button` } 
                    type={ `confirm` }
                    disabled={ 
                      validateKycDocuments() 
                    }
                    onClick={ () => { submitKycDocuments(); return true; } }>
                    { `Submit Application For Review` }
                  </Button>
                  
                </div>
              )
            }
          </div>
        </Modal>
      </div>
    ) : ( // NEW/PENDING APPLICATION
      <div className={ `prime-trust-modal step-${step} ${step === 1 ? `add-height` : ``}` }>
        <Modal
          titleIcon={ `${ window.WWW_URL }/assets/img/platform/LightspeedCrypto.png` }
          title={ step > 2 ? warningSection() : `` } 
          onClose={ onClose } >
          <>
            { step === 1 && (
              <PrimeTrustTermsIframe t={ t } setStep={ setStep } onClose={ onClose } />
            ) }
            { step === 2 && (
              <div className={ `prime-trust-user-accept` } >
                { !userApplication.isAwaiting ? 
                  <div className="ls-formatted-text">
                    <h2>
Application Pending
                    </h2>
                    <p>
                      { t(`userRegistered`) }
                    </p>
                    <p>
                      { t(`sendEmail`) }
                    </p>
                  </div>
                  : 
                  <div className="ls-formatted-text">
                    <h2>
Welcome to Lightspeed Crypto!
                    </h2>
                    <p className={ `first-p-block` }>
We’re thrilled to show you why Lightspeed Crypto is 
                      { ` ` }
                      <em>
the
                      
                      
trading technology built for active traders.
                      </em>
                    </p>
                    <div className="main-body">
                      <p className="medium-text">
During your trial, you will have the ability to manage, track, and trade your digital 
wallets across multiple exchanges by linking them to Lightspeed Crypto. For you to access our 
industry-leading prices on Bitcoin and Ethereum, you must complete our application.
                        
                      </p>
                      <img className="portfolio-img" src={ `/assets/img/chart-portfolio-increasing.png` } width="150" />
                    </div>
                    <Button 
                      className={ `activate-light-speed-button` } 
                      type={ `primary` }
                      disabled={ !ptToken }
                      onClick={ () => { setStep(3); postApplicationStarted({}, () => { log(`== application startDate posted`); }); return true; } }>
                      { t(`activateLightSpeed`) }
                    </Button>
                    <span className={ `error-text` }>
                      {
                        errorText
                      }
                    </span>
                    <p className={ `small-text` }>
Not ready to complete your application? 
                      { ` ` }
                      <span
                        className={ `close-modal-link` }
                        onClick={ () => onClose() }>
Click here to access your trial.
                      </span>
                        
&nbsp;If you need help or have a question,  
                      { ` ` }
                      <a
                        href={ `https://lightspeedcrypto.com/en/support/` }
                        rel="noopener noreferrer"
                        target="_blank"
                        className={ `learn-more-button` }
                        onClick={ () => false }>
visit our support page to send us a message or schedule a call.
                      </a>
                      
                    </p>
                  </div>
                }
              </div>
            ) }
            { step === 3 && (
              <div id={ `prime-trust-widget` } />
            )
            }
            { step === 4 && (
              <div id={ `` } >
                <img className="logo" src={ `${ window.WWW_URL }/assets/img/platform/LightspeedCrypto.png` } />
                <h4>
                  { t(`thanksForSubmission`) }
                </h4>
              </div>
            ) }
          </>
        </Modal>
      </div>
    );
  }
};

export default translate(`primeTrustModal`)(connect()(PrimeTrustAccountModal));

'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path d="M10 1C8.8181 1 7.64778 1.23279 6.55585 1.68508C5.46392 2.13738 4.47177 2.80031 3.63604 3.63604C1.94821 5.32387 1 7.61305 1 10C1 13.978 3.583 17.353 7.156 18.55C7.606 18.622 7.75 18.343 7.75 18.1V16.579C5.257 17.119 4.726 15.373 4.726 15.373C4.312 14.329 3.727 14.05 3.727 14.05C2.908 13.492 3.79 13.51 3.79 13.51C4.69 13.573 5.167 14.437 5.167 14.437C5.95 15.805 7.273 15.4 7.786 15.184C7.867 14.599 8.101 14.203 8.353 13.978C6.355 13.753 4.258 12.979 4.258 9.55C4.258 8.551 4.6 7.75 5.185 7.111C5.095 6.886 4.78 5.95 5.275 4.735C5.275 4.735 6.031 4.492 7.75 5.653C8.461 5.455 9.235 5.356 10 5.356C10.765 5.356 11.539 5.455 12.25 5.653C13.969 4.492 14.725 4.735 14.725 4.735C15.22 5.95 14.905 6.886 14.815 7.111C15.4 7.75 15.742 8.551 15.742 9.55C15.742 12.988 13.636 13.744 11.629 13.969C11.953 14.248 12.25 14.797 12.25 15.634V18.1C12.25 18.343 12.394 18.631 12.853 18.55C16.426 17.344 19 13.978 19 10C19 8.8181 18.7672 7.64778 18.3149 6.55585C17.8626 5.46392 17.1997 4.47177 16.364 3.63604C15.5282 2.80031 14.5361 2.13738 13.4442 1.68508C12.3522 1.23279 11.1819 1 10 1Z" fill="#3B96FF"/>`,
  {
    width: `20px`,
    height: `20px`,
    viewBox: `0 0 20 20`
  }
);
/* eslint-enable */

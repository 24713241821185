// @flow
'use strict';

import { SET_SUBSCRIPTION_TYPES } from '../index.js';

export function setSubscriptionTypes(payload: boolean) {
  return {
    type: SET_SUBSCRIPTION_TYPES,
    payload
  };
}

import React, { useState, useEffect } from 'react';
import EntityTable from '../../utilities/EntityTable.jsx';
import { getTransfers, getTransferTypes } from '../../../helpers/api/UserApi.js';
import { toLocalDate } from '../../../helpers/DateHelper.js';


export const DetailsHistory = ({ t, currencies }) => {
  const [ transfers, setTransfers ] = useState([]);
  const [ transferTypes, setTransferTypes ] = useState([]);
  

  useEffect(() => {
    getTransferTypes((r) => {
      setTransferTypes(r.result);
      getTransfers((response) => {
        if (response.success) {
          setTransfers(response.result);
        }
      });
    });
  }, []);

  const getDirection = (d) => {
    const dir = [`Incoming`, `Outgoing`];
    return dir[d];
  };

  const getTransferType = (type) => {
    
    return t(`accounts:` + transferTypes.filter((tT) => tT.id == type)[0].name);
  };

  const getCurrencyDetails = (row) => currencies.filter((c) => c.currId === row.currId)?.[0];

  const getStatus = (s) => {
    
    const st = [`Pending`, `Settled`, `Cancelled`];
    return st[s];
  };

  const getTransactionLink = (row) => {

    if (!row.txId) return false;

    const currency = getCurrencyDetails(row);

    const url = {
      BTC: `https://www.blockchain.com/btc/tx/${row.txId}`,
      ETH: `https://etherscan.io/tx/${row.txId}`,
      LTC: `https://blockchair.com/litecoin/transaction/${row.txId}`,
      BCH: `https://blockchair.com/bitcoin-cash/transaction/${row.txId}`
    };

    return url[currency.currCode];
  };

  return (
    <div className="details-table fade-in">
      <EntityTable
        defaultSortBy={ `-timestamp` }
        columns={ [
          {
            title: t(`time`),
            key: `timestamp`,
            display: (row) => (
              <>
                {
                  toLocalDate(row.updatedAt)
                }
              </>
            ),
          
          },
          {
            title: `Transfer ID`,
            key: `requestId`,
            display: (row) => {
              const transactionLink = getTransactionLink(row);
              
              return (
                <>
                  { transactionLink ? <a href={ getTransactionLink(row) } target="_blank" rel="nofollow noopener noreferrer">
                    { row.requestId }
                    <img src={ `/assets/img/figma/Newtab.svg` } width="15"/>
                  </a> : row.requestId }
                </>
              );
            }
          },
          {
            title: `Request Type`,
            key: `transferRequestType`,
            display: (row) => (
              <>
                {
                  getTransferType(row.requestType)
                }
              </>
            )
          },
          {
            title: t(`direction`),
            key: `transferDirection`,
            display: (row) => (
              <>
                {
                  getDirection(row.transferDirection)
                }
              </>
            )
          },
          {
            title: `Account/Address`,
            key: `walletAddress`
          },
          {
            title: `Total Amount`,
            key: `quantity`,
            display: (row) => (
              <>
                {
                  row.quantity && (row.quantity + ` ` + getCurrencyDetails(row).currCode)
                }
              </>
            ),
            thClass: `align-right`,
            tdClass: `align-right`
          },
          {
            title: t(`currency`),
            key: `currId`,
            display: (row) => (
              <div className="align-left">
                <img 
                  src={ `${ window.WWW_URL }/assets/img/currency/${ getCurrencyDetails(row).currCode }.png` } 
                  width={ 20 } />
                { ` ` }
                <span className="bold">
                  { getCurrencyDetails(row).currName }
                  { ` ` }
(
                  { getCurrencyDetails(row).currCode }
)
                </span>
              </div>
            ),
            thClass: `align-left`,
          },
          {
            title: `Status`,
            key: `status`,
            display: (row) => (
              <div className={ `status status-` + row.status }>
                {
                  getStatus(row.status)
                }
              </div>
            )
          },
          {
            title: `Label`,
            key: `label`,
            tdClass: `ellipsis`
          },
        ] }
        data={ transfers || [] }
        filterText={ null }
        pageSize={ 100 }
        hidePagination={ true }
        noDataText={ `No data found` }/>
    </div>
  );
};

'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path d="M17.8571 0H2.14286C0.959821 0 0 0.959821 0 2.14286V17.8571C0 19.0402 0.959821 20 2.14286 20H17.8571C19.0402 20 20 19.0402 20 17.8571V2.14286C20 0.959821 19.0402 0 17.8571 0ZM15.6741 7.08929C15.683 7.21429 15.683 7.34375 15.683 7.46875C15.683 11.3393 12.7366 15.7991 7.35268 15.7991C5.69196 15.7991 4.15179 15.317 2.85714 14.4866C3.09375 14.5134 3.32143 14.5223 3.5625 14.5223C4.93304 14.5223 6.19196 14.058 7.19643 13.2723C5.91071 13.2455 4.83036 12.4018 4.45982 11.2411C4.91071 11.308 5.31696 11.308 5.78125 11.1875C4.44196 10.9152 3.4375 9.73661 3.4375 8.3125V8.27679C3.82589 8.49554 4.28125 8.62946 4.75893 8.64732C4.35733 8.38019 4.02812 8.0178 3.80066 7.59248C3.5732 7.16716 3.45457 6.69214 3.45536 6.20982C3.45536 5.66518 3.59821 5.16518 3.85268 4.73214C5.29464 6.50893 7.45982 7.66964 9.88839 7.79464C9.47321 5.80804 10.9598 4.19643 12.7455 4.19643C13.5893 4.19643 14.3482 4.54911 14.8839 5.12054C15.5446 4.99554 16.1786 4.75 16.7411 4.41518C16.5223 5.09375 16.0625 5.66518 15.4554 6.02679C16.0446 5.96429 16.6161 5.79911 17.1429 5.57143C16.7455 6.15625 16.2455 6.67411 15.6741 7.08929Z" fill="currentColor"/>`,
  {
    width: `20px`,
    height: `20px`,
    viewBox: `0 0 20 20`
  }
);
/* eslint-enable */

'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default () => SvgHelper(
    '<rect x="5" y="5" width="14" height="10" rx="2" stroke="#3B96FF" fill="none"/><rect x="2" y="18" width="20" height="1" rx="1" fill="#3B96FF"/>',
    {
        width: `24px`,
        height: `24px`,
        viewBox: `0 0 24 24`,
        fill: `none`
    }
)
// @flow
'use strict';

import React from 'react';
import { withRouter } from 'react-router-dom';
import Checkbox from '../utilities/Checkbox.jsx';
import RadioButtons from '../utilities/RadioButtons.jsx';
import Button from '../utilities/Button.jsx';
import { userSettings } from "../../helpers/SettingsHelper.js";
import type { ProfileProps } from "../user/ProfileDataLayer.jsx";
import UniversalThemeEditor from '../user/UniversalThemeEditor.jsx';

type Props = ProfileProps;

const Interface = (props: Props) => {
  const {  t, priceClick, zeroStyle, depthStyle, overrideChartTheme, marketSwitcherColorFilled, tickerPosition, marketSwitcherVersion = `v2`, onChange, save } = props;

  const updateUIPref = (field: string, e: any) => {
    const value = userSettings[field][e.target.value];
    if (field !== value && onChange) {
      onChange(field, value);
    }
  };
  
  return (
    <div className="profile-theme-editor">
      <h1 className="page-title">
        { t(`settings:interface`) }
      </h1>
      
      <UniversalThemeEditor t />

      <section className="ui-preferences">
        <h1>
          { t(`Other Settings`) }
        </h1>
        <div>
          <div>
            <RadioButtons
              label={ t(`clickingPlaces`) }
              options={ 
                [
                  { label: t(`app:alert`), value: 0, name: `priceClick-${ userSettings.priceClick[0] }` },
                  { label: t(`app:order`), value: 1, name: `priceClick-${ userSettings.priceClick[1] }` },
                ]
              }
              name={ `priceClick` }
              value={ userSettings.priceClick.findIndex((i) => i === priceClick) }
              onChange={ (e) => updateUIPref(`priceClick`, e) } />
            <RadioButtons
              label={ t(`zeroesStyle`) }
              options={
                [
                  { label: t(`gray`), value: 0, name: `zeroes-${ userSettings.zeroStyle[0] }` },
                  { label: t(`white`), value: 1, name: `zeroes-${ userSettings.zeroStyle[1] }` },
                  { label: t(`app:none`), value: 2, name: `zeroes-${ userSettings.zeroStyle[2] }` },
                ]
              }
              name={ `zeroStyle` }
              value={
                userSettings.zeroStyle.findIndex((i) => i === zeroStyle)
              }
              onChange={ (e) => updateUIPref(`zeroStyle`, e) } />
            <RadioButtons
              label={ t(`depthColor`) }
              options={ 
                [
                  { label: t(`redGreen`), value: 0, name: `depth-${ userSettings.depthStyle[0] }` },
                  { label: t(`blue`), value: 1, name: `depth-${ userSettings.depthStyle[1] }` },
                  { label: t(`app:none`), value: 2, name: `depth-${ userSettings.depthStyle[2] }` },
                ]
              }
              name={ `depthStyle` }
              value={ userSettings.depthStyle.findIndex((i) => i === depthStyle) }
              onChange={ (e) => updateUIPref(`depthStyle`, e) } />
            <RadioButtons
              label={ t(`marketNavigationAssignedColorStyle`) }
              options={
                [
                  { label: t(`thinBorder`), value: false, name: `Thin` },
                  { label: t(`filled`), value: true, name: `Filled` }
                ]
              }
              name={ `marketSwitcherColorFilled` }
              value={ marketSwitcherColorFilled }
              onChange={ (e) => {
                if (onChange) onChange(`marketSwitcherColorFilled`, e.target.value == `true`);
              } } />
            <RadioButtons
              label={ t(`Ticker placed on`) }
              options={ 
                [
                  { label: t(`Footer`), value: `footer`, name: `footer` },
                  { label: t(`Header`), value: `header`, name: `header` },
                ]
              }
              name={ `tickerPosition` }
              value={ tickerPosition }
              onChange={ (e) => {
                if (onChange) onChange(`tickerPosition`, e.target.value);
              } } />
            <h4>
              { t(`tvStyles`) }
            </h4>
            <Checkbox
              label={ t(`overrideStyles`) }
              name="overrideChartTheme"
              value={ !!overrideChartTheme }
              onChange={ (e) => {
                if (onChange) onChange(`overrideChartTheme`, e.target.checked);
              } } />
            <div style={ { marginTop: `3rem` } }>
              <RadioButtons
                label={ t(`Market Search Panel`) }
                options={ 
                  [
                    { label: t(`Classic`), value: `v1`, name: `v1` },
                    { label: t(`New Version`), value: `v2`, name: `v2` },
                  ]
                }
                name={ `marketSwitcherVersion` }
                value={ marketSwitcherVersion }
                onChange={ (e) => {
                  if (onChange) onChange(`marketSwitcherVersion`, e.target.value);
                } } />
            </div>
          </div>
          <Button
            type="primary"
            onClick={ () => {
              if (save) return save();
              return false;
            } }>
            { t(`saveChanges`) }
          </Button>
        </div>
      </section>
    </div>
  );
};

export default withRouter(Interface);

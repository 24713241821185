// @flow
'use strict';

import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { getMarketPair } from '../../helpers/MarketPairHelper.js';
import TextField from '../utilities/TextField.jsx';
import Search from "../../svgs/Search.jsx";
import type { ProfileProps } from './ProfileDataLayer.jsx';
import FavoriteExchange from "./FavoriteExchange.jsx";

const Favorites = (props: ProfileProps) => {
  const { exchanges, t, favorites, toggleFavorite, markets } = props;
  const [ filterText, setFilterText ] = useState<string>(``);
  const [ activeExchange, setActiveExchange ] = useState<number>(0);
  
  const handleInputChange = (param: string) => setFilterText(param.toUpperCase());

  const handleActiveExchange = (exchId: number) => {
    if (exchanges) {
      if (exchId !== activeExchange) {
        setActiveExchange(exchId);       
      } else {
        setActiveExchange(0);
      }
    }
  };
  
  const parsedMarkets = (markets || []).filter((m) => {
    if (filterText == ``) return true;
    if (filterText.indexOf(`:`) < 0) {
      return getMarketPair(m)
        .toString()
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) > -1;
    } else {
      const exchFilter = filterText.split(`:`)[0].toLowerCase();
      const mktFilter = filterText.split(`:`)[1].toLowerCase();
      return getMarketPair(m)
        .toString()
        .toLowerCase()
        .indexOf(mktFilter) > -1 && m.exchCode.toLowerCase().indexOf(exchFilter) > -1;
    }
  });

  const sortedExchanges = (exchanges || []).filter((e) => {
    return parsedMarkets.filter((m) => e.exchCode == m.exchCode).length > 0;
  }).sort((a, b) => {
    if (a.exchCode > b.exchCode) return 1;
    if (a.exchCode < b.exchCode) return -1;
    return 0;
  });
  
  return (
    <div className="favorites">
      <section>
        <div>
          <div className="top">
            <h1 className="page-title">
              { t(`favoriteMarkets`) }
            </h1>
            <TextField
              icon={ Search }
              label={ t(`app:search`) }
              name={ `search` }
              value={ filterText }
              onChange={ (e) => handleInputChange(e.target.value) } />
          </div>
          <div className='favorite-markets'>
            { 
              sortedExchanges.map((exchange) => {
                const active = activeExchange === exchange.exchId;
                return (
                  <FavoriteExchange
                    key={ exchange.exchId }
                    exchange={ exchange }
                    markets={ active ? parsedMarkets.filter((m) => m.exchCode == exchange.exchCode) : [] }
                    favorites={ favorites ? favorites : [] }
                    active={ active }
                    toggleFavorite={ toggleFavorite ? toggleFavorite : () => {} }
                    onActive={ () => handleActiveExchange(exchange.exchId) } />
                );
              })
            }
          </div>
        </div>
      </section>
    </div>
  );
};

export default withRouter(Favorites);

// @flow
'use strict';

import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

const Crypt = ({ currency, isClickable = true }: any) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(`/the-crypt/${currency.currCode}`);
  };

  const yearFrom = useMemo(() => {
    return new Date(Date.parse(currency.firstTradeDate)).getFullYear();
  },[currency.firstTradeDate]);

  const yearTo = useMemo(() => {
    return new Date(Date.parse(currency.lastTradeDate)).getFullYear();
  },[currency.lastTradeDate]);

  return (
    <li className='crypt-item' style={ { cursor: isClickable ? `pointer` : `default` } } onClick={ () => { if (isClickable) handleClick();} }>
      <div>
        <img
          src={ `${ window.WWW_URL }/assets/img/currency/${ currency.currCode }.png` }
          className='base-curr-logo'
          width={ 40 } 
          height={ 40 }        
          onError={ (e) => {e.target.onerror = null; e.target.src=`${ window.WWW_URL }/assets/img/currency/empty.png`;} } />
        <div>
          <p className='name'>
            { currency?.currName || `` }
          </p>
          <p className='code'>
            { currency?.currCode || `` }
          </p>
        </div>
        <div className='years'>
          { yearFrom && yearTo ? `${yearFrom}-${yearTo}` : `` }
        </div>
      </div>
    </li>
  );
};

export default Crypt;

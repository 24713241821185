// @flow
'use strict';

import React from 'react';
import Modal from '../utilities/Modal.jsx';

type Props = {
  onClose: () => void,
};

const ChangePasswordModal = (props: Props) => {
  const { onClose } = props;

  return (
    <div className={ `change-password-modal` }>
      <Modal title={ `` } onClose={ onClose } >
        <iframe src={ `${ window.WWW_URL }/user/account?v2=true#password` } title="change password" height="280" width="550" frameBorder="0" scrolling="no" seamless="seamless"/>
      </Modal>
    </div>
  );
};

export default ChangePasswordModal;

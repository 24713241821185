// @flow

import { $ } from "./ApiHelper";

export function fetchNews(searchTerm?: string): Promise<any> {
  return new Promise((resolve, reject) => {
    $({
      url: searchTerm ? `/private/news/?searchTerm=` + encodeURIComponent(searchTerm) + `&pageSize=10` : `/private/news?pageSize=10`,
      //url: `/private/news`,
      success: resolve,
      error: reject
    });
  });
}

export function getPublicNews(): Promise<any> {
  return new Promise((resolve, reject) => {
    $({
      url: `/public/news`,
      success: resolve,
      error: reject
    });
  });
}

// @flow
'use strict';

import React, { useEffect } from 'react';
import Close from '../../svgs/Close.jsx';
import { 
  listenForEvent, 
  removeEventListener,
  ESCAPE
} from '../../helpers/EventHelper.js';

type Props = {
  confirmText?: string,
  cancelText?: string,
  onConfirm?: () => void,
  onClose: () => void,
  children?: any,
};

const TryMobileAppModal = (props: Props) => {
  let { confirmText, cancelText, onConfirm, onClose, children } = props;

  useEffect(() => {
    listenForEvent(ESCAPE, handleEscapePress);
    return () => {
      removeEventListener(ESCAPE, handleEscapePress);
    };
  }, []);

  const handleEscapePress = () => {
    onClose();
  };


  return (
    <div className="modal__wrapper">
      <div 
        className="modal__backdrop"
        onClick={ () => onConfirm ? null : onClose() } />
      <div className={ `modal__inner ${ cancelText || (confirmText && onConfirm) ? `has-actions` : `` } try-mobile` }>
        <div className="modal__header try-mobile">
          <a onClick={ () => onClose() }>
            { Close(Math.random().toString(36).substring(7)) }
          </a>
        </div>
        <div className="modal__body try-mobile">
          { children }
        </div>
      </div>
    </div>
  );
};


export default TryMobileAppModal;

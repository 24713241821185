// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import Modal from '../utilities/Modal.jsx';
import PanelForm from './PanelForm.jsx';
import type { PanelType } from '../../types/PanelType.js';
import type { Market } from '../../types/Market.js';
import type { Exchange } from '../../types/Exchange.js';
import type { Account } from '../../types/Account.js';
import type { Panel as PanelEntity } from '../../types/Panel.js';
import { NOTES } from '../../helpers/PanelTypeHelper';

type Props = {
  t: any,
  panelTypes: Array<PanelType>,
  markets: Array<Market>,
  exchanges: Array<Exchange>,
  accounts: Array<Account>,
  save: (o: any) => void,
  title: string,
  close: () => void,
  disabled: boolean,
  data?: any,
  panels?: Array<PanelEntity>,
};

type State = {
  typeId: number,
  sizeId: number,
  isUpdate: boolean,
  settings: any
};

const DEFAULT_EXCHANGE = `GDAX`;
const DEFAULT_MARKET = `BTC/USD`;

class PanelModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      typeId: this.props.data ? this.props.data.typeId : -1,
      sizeId: this.props.data ? this.props.data.sizeId : -1,
      isUpdate: !!this.props.data,
      settings: this.props.data ? JSON.parse(this.props.data.settingsJson) : { }
    };
  }


  getExistingPanelSettings(activeType: PanelType) {
    const existingPanel = this.props.panels?.find((p) => p.typeId === activeType.typeId);
    if (existingPanel) {
      return JSON.parse(existingPanel.settingsJson);
    }

    return null;
  }

  getDefaultSettingsFromCustomOptions(activeType?: PanelType) {
    if (!activeType) return { };

    const existingPanelSettings = this.getExistingPanelSettings(activeType);

    if (existingPanelSettings) {
      return existingPanelSettings;
    }

    if (activeType.typeId === NOTES) {
      return {
        exchange: DEFAULT_EXCHANGE,
        market: DEFAULT_MARKET
      };
    }

    return Object.keys(activeType.customOptions).reduce((settings: any, key: string) => {
      let keyValue = ``;

      if (key === `exchange`) {
        keyValue = DEFAULT_EXCHANGE;
      } else if (key === `market`) {
        keyValue = DEFAULT_MARKET;
      } else if (activeType?.customOptions[key]?.type === `List`) {
        if (typeof activeType?.customOptions[key]?.items[0] === `string`) {
          keyValue = activeType?.customOptions[key]?.items[0];
        } else {
          keyValue = activeType?.customOptions[key]?.items[0]?.id;
        }
      } else if (activeType?.customOptions[key]?.type === `Boolean`) {
        keyValue = false;
      }
      
      return { 
        ...settings, 
        [key]: keyValue
      };
    }, { });
  }

  proxyAdd() {
    let settings = this.state.settings;
    if (settings.market == ``) delete settings.market;    

    this.props.save({
      typeId: this.state.typeId,
      sizeId: this.state.sizeId,
      panelSetting: JSON.stringify(settings)
    });
  }

  handleInputChange(v: any, key: string) {
    if (key == `typeId`) {
      const panelType = this.props.panelTypes.find((t) => t.typeId == v);
      
      this.setState({
        ...this.getInitialState(),
        settings: this.getDefaultSettingsFromCustomOptions(panelType),
        sizeId: panelType?.sizes[0]?.id ?? 1,
        [key]: v
      });

    } else {
      this.setState({
        [key]: v
      });
    }
  }

  handleCustomInputChange(v: any, key: string, modifier: (s: any) => any = (s) => s) {
    this.setState({
      settings: {
        ...modifier(this.state.settings),
        [key]: v
      }
    });
  }

  render() {
    return (
      <Modal
        title={ this.props.title }
        onConfirm={ this.proxyAdd.bind(this) }
        onClose={ this.props.close }
        confirmText={ this.props.t(`app:save`) }
        cancelText={ this.props.t(`app:cancel`) }
        confirmDisabled={ this.props.disabled }>
        <PanelForm
          markets={ this.props.markets }
          exchanges={ this.props.exchanges }
          accounts={ this.props.accounts }
          t={ this.props.t }
          panelTypes={ this.props.panelTypes }
          typeId={ this.state.typeId }
          sizeId={ this.state.sizeId }
          isUpdate={ this.state.isUpdate }
          settings={ this.state.settings }
          handleCustomInputChange={ this.handleCustomInputChange.bind(this) }
          handleInputChange={ this.handleInputChange.bind(this) } />
      </Modal>
    );
  }
}

export { PanelModal as PurePanelModal };
export default translate(`boards`)(PanelModal);

// @flow
'use strict';

import React from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { translate } from 'react-i18next';
import TextField from '../utilities/TextField.jsx';
import SelectField from '../utilities/SelectField.jsx';
import Button from "../utilities/Button.jsx";
import type { Market } from '../../types/Market.js';
import type { Exchange } from '../../types/Exchange.js';
import sounds from '../../helpers/AlertSounds.js';
import { updateAlertSound } from '../../actions/markets/updateAlertSound.js';
import { getMarketPair } from '../../helpers/MarketPairHelper.js';

type Props = {
  t: any,
  markets: Array<Market>,
  exchanges: Array<Exchange>,
  options: Array<{
    label: string,
    value: any,
    icon?: any,
    compareValue?: any
  }>,
  hasActiveMarket: boolean,
  exchId: number,
  marketId: number,
  price: string,
  note: string,
  conditionalOperator: string,
  reqMsg: string,
  onSubmit: (e: any) => boolean,
  onChange: (e: any, key: string, forceNumber?: boolean, value?: any) => void,
  overrideAlertSound?: string,
  refreshCurrentPrice: () => void,
  confirmText: string,
  interval: string,
  numCandles: number
};

const VolumeAlertForm = (props: Props) => {
  const { t, markets, exchanges, hasActiveMarket, exchId, marketId, price, note, 
    overrideAlertSound, conditionalOperator, reqMsg, onSubmit, onChange, 
    confirmText, interval, numCandles } = props;

  const sound = useSelector((state) => state.markets.alertSound);
  const dispatch = useDispatch();

  const currentMkt = markets.find((itm)=> itm.marketId == marketId);
  const marketPair = currentMkt ? getMarketPair(currentMkt) : null;

  return (
    <div>
      <div className="alert-container">
        {
          !hasActiveMarket && (
            <div>
              <SelectField
                options={ exchanges.map((exch) => ({
                  label: exch.exchName,
                  value: exch.exchId
                })).sort((a, b) => {
                  if (a.label > b.label) return 1;
                  if (b.label > a.label) return -1;
                  return 0;
                }) }
                label={ t(`app:exchange`) }
                name="selectExchange"
                value={ exchId }
                searchable={ true }
                onChange={ (e, v) => onChange(e, `exchId`, true, v) } />
            </div>
          )
        }
        {
          !hasActiveMarket && (
            <div>
              <SelectField
                options={ markets.filter((mkt) => mkt.exchId == exchId).map((mkt) => ({
                  label: mkt.marketName,
                  value: mkt.marketId
                })).sort((a, b) => {
                  if (a.label > b.label) return 1;
                  if (b.label > a.label) return -1;
                  return 0;
                }) }
                label={ t(`app:market`) }
                name="selectMarket"
                value={ marketId }
                searchable={ true }
                onChange={ (e, v) => onChange(e, `marketId`, true, v) } />
            </div>
          )
        }
        
        <div className="input-row add-right-margin line-row">  
          <div className="inputs-container default-select">
            <SelectField
              options={ [{
                label: `1 Minute Candle`,
                value: `1m`
              },
              {
                label: `1 Hour Candle`,
                value: `1h`
              },
              {
                label: `1 Day Candle`,
                value: `1d`
              }] }
              name="selectInterval"
              label={ `` }
              onChange={ (e, v) => onChange(e, `interval`, false, v) }
              value={ interval }/>                         
          </div>
          <div className="inputs-label sound-label text">
          rises above the Volume Moving Average
          </div>
        </div>
        
        <div className="input-row add-right-margin">
          
          <div className="inputs-label sound-label text">
              of the previous
          </div>
          
          <div className="inputs-container num-candles">
            {
              (
                <TextField
                  name="selectnumCandles"
                  label={ t(`app:value`) }
                  onChange={ (e, v) => onChange(e, `numCandles`, true, v) }
                  value={ numCandles.toString() }/>
              )
            }
          </div>

          <div className="inputs-label sound-label minute-candles text">
              minute candles by
          </div>

          <div className="inputs-container num-candles value-input">
            {
              (
                <TextField
                  label={ t(`app:value`) }
                  name={ `new-alert-value` }
                  value={ price.toString() }
                  onChange={ (e) => onChange(e, `price`) } />
              )
            }
          </div>

          <div className="inputs-label num-candles-select">
            <SelectField
              options={ [
                { label: marketPair?.base ?? `--`, value: `GreaterThanOrEqual` },
                { label: `%`, value: `PercentChange` }
              ] }
              label={ `` }
              name="conditionalOperator"
              value={ conditionalOperator }
              searchable={ false }
              disabled={ !currentMkt }
              onChange={ (e, v) => onChange(e, `conditionalOperator`, false, v) }/>
          </div>

        </div>

       
        <div className="input-row line-row">
          <div className="inputs-label sound-label">
            { t(`sound`) }
          </div>
          <div className="inputs-container sound-select">
          
            <SelectField
              options={ sounds.map((s) => ({
                label: s,
                value: s
              })) }
              label={ t(`sound`) }
              name="sound"
              value={ overrideAlertSound || sound }
              searchable={ true }
              onChange={ (e, v) => {
                dispatch(updateAlertSound(v));
                if (overrideAlertSound) onChange(e, `sound`, false, v);
              } } />
          
          </div>
          <div
            className={ `select-preview` + (sound == `none` ? ` disabled` : ``) }>
            <div className="preview-button">
              <audio id="alertPreview" src={ `https://coinigy.com/assets/sounds/${overrideAlertSound || sound}.wav` } type="audio/wav" />
              <a onClick={ () => {
                let a = document.getElementById(`alertPreview`);
                if (a instanceof HTMLAudioElement) {
                  a.play();
                }
              } }>
              Play sound         
              </a>
            </div>
          </div>
        </div>
        <div className="input-row">
          <div className="inputs-label note-label">
            { t(`note`) }
          </div>
          <div className="inputs-container alert-note">
            <TextField
              label={ t(`app:optional`) }
              name={ `new-alert-note` }
              value={ note }
              
              onChange={ (e) => onChange(e, `note`) } />
          </div>
        </div>
        
      </div>
      <div>
        <Button
          type="primary"
          className={ `add-alert-button` }
          disabled={ !currentMkt }
          onClick={ onSubmit }>
          { confirmText }
        </Button>
      </div>
      <div className={ `bottom-form-section` }>
        { 
          reqMsg 
        }
      </div>
    </div>);
};

const mapStateToProps = (state) => ({ 
  sound: state.markets.alertSound
});

const mapDispatchToProps = (dispatch) => ({
  updateAlertSound: (sound) => dispatch(updateAlertSound(sound))
});

export { VolumeAlertForm as PureVolumeAlertForm };
export default translate(`alerts`)(connect(mapStateToProps, mapDispatchToProps)(VolumeAlertForm));

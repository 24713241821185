'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path d="M12.502 11h-.794l-.276-.274A6.47 6.47 0 0 0 13 6.5 6.5 6.5 0 1 0 6.5 13a6.47 6.47 0 0 0 4.225-1.566l.276.274v.792l4.998 4.991L17.49 16l-4.988-5zM6.5 11a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9z" fill="currentColor" fill-rule="evenodd"/>`,
  {
    width: `2.25rem`,
    height: `2.25rem`,
    viewBox: `0 0 15 15`
  }
);
/* eslint-enable */

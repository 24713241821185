// @flow
'use strict';

import React, { useState } from 'react';
import { TableSearch } from "mui-datatables";
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';

const FAQLink = `https://support.coinigy.com/hc/en-us/articles/13438854861979-How-do-I-use-Coinigy-s-Crypto-Screener-`;
type Props = {
  t: any,
  handleSearch: (search: any) => void,
  options: any,
  searchText: string,
}

const ScreenerSearch = (props: Props) => { 
  const { t, handleSearch, options, searchText } = props;

  const [showTitle, setShowTitle] = useState(true); 

  return (
    <div>
      { showTitle ? (
        <div className='mui-custom-title' onClick={ () => setShowTitle(false) }>
          <div >
            <SearchIcon/>
          </div>
        
          <Typography variant="h6">
            { t(`screener:title`) }
          </Typography>

          <a
            href={ FAQLink }
            rel="noopener noreferrer"
            target="_blank"
            onClick={ (e) => e.stopPropagation() }>
            { `Screener user guide` }
          </a>
        </div>)
        :
        <div className='mui-custom-search'>
          <TableSearch
            searchText={ searchText }
            onSearch={ handleSearch }
            onHide={ () => {
              handleSearch(``); 
              setShowTitle(true);
            }  }
            options={ options }/> 
        </div>      
      }
    </div>
  );
};

export default ScreenerSearch;

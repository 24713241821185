'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path class="connector" d="M6.567 7.275a2.669 2.669 0 0 1 0 1.45l3.54 2.063a2.667 2.667 0 1 1-.674 1.15L5.896 9.877a2.667 2.667 0 1 1 0-3.753l3.538-2.065A2.669 2.669 0 0 1 12 .667a2.667 2.667 0 1 1-1.895 4.543L6.567 7.275zM5.17 7.36a.677.677 0 0 1-.038-.064 1.332 1.332 0 1 0 .038.064zm5.696-3.325a1.332 1.332 0 1 0 2.265-1.404 1.332 1.332 0 0 0-2.265 1.404zm-.057 8.028a1.333 1.333 0 1 0 .082-.14.674.674 0 0 1-.082.14z"/>`,
  {
    width: `1.5rem`,
    height: `1.6rem`,
    viewBox: `0 0 15 16`
  }
);
/* eslint-enable */




// @flow
'use strict';

import React from 'react';
import { getOhlc } from '../../helpers/api/BalanceApi';
import { getMarketFromPanelSettings } from '../../helpers/MarketPairHelper.js';
import MarketChartPanelChart from './MarketChartPanelChart.jsx';
import CoinigyBaseComponent from '../CoinigyBaseComponent.jsx';
import type { Trade } from '../../types/Trade.js';
import type { Exchange } from '../../types/Exchange.js';
import type { Market } from '../../types/Market.js';

type OHLCV = {
  open: number,
  high: number,
  low: number,
  close: number,
  volume: number,
  timeStart: string,
  timeEnd: string,
  closingTradeTime: string,
  openingTradeTime: string,
};

type Settings = {
  exchange: string,
  market: string,
  chartType: number,
  chartResolution: string,
  showVolume: boolean,
  showVolumeProfile: boolean
};

type Props = {
  settings: Settings,
  panelData: any,
  realWidth: number,
  realHeight: number,
  width: number,
  height: number,
  market: ?Market
};

type State = {
  ohlcv: Array<OHLCV>
};

const ONE_MINUTE = 60000;
const ONE_HOUR = 3600000;
const ONE_DAY = ONE_HOUR * 24;
const ONE_MONTH = ONE_DAY * 30;
const THREE_MONTHS = (ONE_DAY * 30) * 3;
const SIX_MONTHS = THREE_MONTHS * 2;
const ONE_YEAR = ONE_DAY * 365;

const config = {
  '1M': {
    data: `m`,
    agg: ONE_MINUTE,
    show: ONE_DAY
  },
  '3M': {
    data: `m`,
    agg: ONE_MINUTE * 3,
    show: ONE_DAY * 3
  },
  '5M': { 
    data: `m`,
    agg: ONE_MINUTE * 5,
    show: ONE_DAY * 5
  },
  '10M': {
    data: `m`,
    agg: ONE_MINUTE * 10,
    show: ONE_DAY * 8
  },
  '15M': {
    data: `m`,
    agg: ONE_MINUTE * 15,
    show: ONE_DAY * 8
  },
  '30M': {
    data: `m`,
    agg: ONE_MINUTE * 30,
    show: ONE_DAY * 8
  },
  '1H': {
    data: `h`,
    agg: ONE_HOUR,
    show: ONE_MONTH
  },
  '2H': {
    data: `h`,
    agg: ONE_HOUR * 2,
    show: THREE_MONTHS
  },
  '3H': {
    data: `h`,
    agg: ONE_HOUR * 3,
    show: THREE_MONTHS
  },
  '4H': {
    data: `h`,
    agg: ONE_HOUR * 4,
    show: THREE_MONTHS
  },
  '6H': {
    data: `h`,
    agg: ONE_HOUR * 6,
    show: THREE_MONTHS
  },
  '8H': {
    data: `h`,
    agg: ONE_HOUR * 8,
    show: SIX_MONTHS
  },
  '12H': {
    data: `h`,
    agg: ONE_HOUR * 12,
    show: SIX_MONTHS
  },
  '1D': {
    data: `d`,
    agg: ONE_DAY,
    show: ONE_YEAR
  },
  '3D': {
    data: `d`,
    agg: ONE_DAY * 3,
    show: ONE_YEAR
  },
  '1W': {
    data: `d`,
    agg: ONE_DAY * 7,
    show: ONE_YEAR * 2
  },
  '2W': {
    data: `d`,
    agg: ONE_DAY * 14,
    show: ONE_YEAR * 4
  },
  '1Mo': {
    data: `d`,
    agg: ONE_DAY * 30,
    show: ONE_YEAR * 10
  }
};

class MarketChartPanel extends CoinigyBaseComponent<Props, State> {
  static getPanelTitle: (settings: Settings, markets: Array<Market>, exchanges: Array<Exchange>) => string;

  constructor(props: Props) {
    super(props);

    this.state = {
      ohlcv: []
    };
  }


  componentDidMount() {
    this._isMounted = true;
    this.getData(this.props);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    let mkt = `${ this.props.settings.exchange }:${ this.props.settings.market }`,
      newMkt = `${ nextProps.settings.exchange }:${ nextProps.settings.market }`,
      chartResolution = this.props.settings.chartResolution,
      newChartResolution = nextProps.settings.chartResolution;

    if (mkt !== newMkt || chartResolution != newChartResolution) return this.getData(nextProps);

    if (!this.props.panelData.hasOwnProperty(mkt) || !nextProps.panelData.hasOwnProperty(mkt)) return;
    if (!this.props.panelData[mkt].trades || !nextProps.panelData[mkt].trades) return;

    let t = this.props.panelData[mkt].trades.filter((t) => !t.backfillTrade),
      n = nextProps.panelData[mkt].trades.filter((t) => !t.backfillTrade);

    if (n.length == 0) return;
    
    let delta = n.length - t.length,
      newTrades = n.slice(0, delta);

    if (delta == 0) return;

    this.setState({
      ohlcv: this.compileData(newTrades, this.state.ohlcv)
    });
  }

  compileData(newTrades: Array<Trade>, _ohlcv: Array<OHLCV> = this.state.ohlcv) {
    let ohlcv: Array<OHLCV> = _ohlcv.sort((a, b) => {
      if (+(new Date(a.timeStart)) < +(new Date(b.timeStart))) return -1;
      if (+(new Date(b.timeStart)) < +(new Date(a.timeStart))) return 1;
      return 0;
    });

    newTrades.forEach((trade) => {
      if (ohlcv.length == 0) return;

      let candleIndex = ohlcv.findIndex((o) => {
        return +(new Date(o.timeStart)) <= +(new Date(trade.time)) && +(new Date(o.timeEnd)) >= +(new Date(trade.time));
      });

      if (candleIndex == -1) {
        let interval = +(new Date(ohlcv[ohlcv.length - 1].timeEnd)) - +(new Date(ohlcv[ohlcv.length - 1].timeStart));

        while (+(new Date(ohlcv[ohlcv.length - 1].timeEnd)) < +(new Date(trade.time))) {
          let newTimeStart =(new Date(+(new Date(ohlcv[ohlcv.length - 1].timeEnd)) + 1000)).toISOString(),
            prevBarClose = ohlcv.length > 0 ? ohlcv[ohlcv.length - 1].close : 0;

          candleIndex = ohlcv.push({
            ...ohlcv[ohlcv.length - 1],
            open: prevBarClose,
            high: prevBarClose,
            low: prevBarClose,
            close: prevBarClose,
            volume: 0,
            timeStart: newTimeStart,
            timeEnd: (new Date(+(new Date(newTimeStart)) + interval)).toISOString()
          });

          candleIndex -= 1;
        }
      }

      let candle = ohlcv[candleIndex];
      if (!candle) return; // candle does not exist for the timeframe newTrade needs.

      if(!candle.closingTradeTime)
        candle.closingTradeTime = ( (d) => new Date(d.setDate(d.getDate()-1)) )(new Date).toISOString();

      if(!candle.openingTradeTime)
        candle.openingTradeTime = ( (d) => new Date(d.setDate(d.getDate()+1)) )(new Date).toISOString();

      ohlcv[candleIndex] = {
        ...candle,
        open: +(new Date(trade.time)) < +(new Date(candle.openingTradeTime)) ? trade.price : candle.open,
        high: candle.high >= trade.price ? candle.high : trade.price,
        low: candle.low <= trade.price ? candle.low : trade.price,
        close: +(new Date(trade.time)) > +(new Date(candle.closingTradeTime)) ? trade.price : candle.close,
        volume: candle.volume + trade.quantity,
        closingTradeTime: +(new Date(trade.time)) > +(new Date(candle.closingTradeTime)) ?
          trade.time :
          candle.closingTradeTime,
        openingTradeTime: +(new Date(trade.time)) < +(new Date(candle.openingTradeTime)) ?
          trade.time :
          candle.openingTradeTime
      };
    });

    return ohlcv;
  }

  aggOHLCV(ohlcv: Array<OHLCV>, agg: number): Array<OHLCV> {
    return Array.from(ohlcv.reduce((m, o) => {
      let k = +(new Date(o.timeStart)) - (+(new Date(o.timeStart)) % agg);
      m.set(k, (m.get(k) || []).concat([o]));
      return m;
    }, new Map())).map(([timeStart: number, a: Array<OHLCV>]): OHLCV => {
      return a.reduce((r, o, j) => {
        if (j !== 0) r.volume += o.volume;
        if (o.high > r.high) r.high = o.high;
        if (o.low < r.low) r.low = o.low;
        if (j == (a.length - 1)) {
          r.close = o.close;
          let nextBarEnd = (+(new Date(o.timeEnd)) + agg) - ((+(new Date(o.timeEnd)) + agg) % agg) - 1000;
          r.timeEnd = new Date(nextBarEnd).toISOString();
        }
        return r;
      }, { ...a[0], timeStart: (new Date(timeStart)).toISOString() });
    });
  }

  getData(props: Props = this.props) {
    let { exchange, market, chartResolution } = props.settings,
      opts = config[chartResolution] || config[`1M`];

    getOhlc(
      { exchange: exchange,
        market: market,
        data: opts.data,
        params: {
          EndDate: (new Date()).toISOString(),
          StartDate: (new Date(new Date() - opts.show - (new Date() % opts.agg))).toISOString()
        } },
      (data) => {
        if (data.success) {
          this.setStateSafe({
            ohlcv: this.aggOHLCV(data.result, opts.agg)
          });
        }
      }
    );
  }

  render() {
    return (
      <MarketChartPanelChart
        name={ `${ this.props.settings.exchange }:${ this.props.settings.market }` }
        ohlcv={ this.state.ohlcv.map((o) => ({ ...o, date: new Date(o.timeStart) })) }
        realWidth={ this.props.realWidth }
        realHeight={ this.props.realHeight }
        width={ this.props.width }
        height={ this.props.height }
        market={ this.props.market }
        showVolume={ this.props.settings.showVolume }
        showVolumeProfile={ this.props.settings.showVolumeProfile }
        type={
          this.props.settings.chartType == 3 ? `area` :
            this.props.settings.chartType == 2 ? `candlestick` :
              `line`
        } />
    );
  }
}

MarketChartPanel.getPanelTitle = (settings: Settings, markets: Array<Market>) => {
  let market = getMarketFromPanelSettings(markets, settings);

  return market && settings ? market.exchName + ` - ` + market.displayName +
         ` (` + settings.chartResolution + `)` : `Market Chart`;
};

export default MarketChartPanel;

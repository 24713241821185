// @flow
'use strict';

import React from 'react';
import type { Market } from '../types/Market.js';
import type { Exchange } from '../types/Exchange.js';
import type { Account } from '../types/Account.js';
import type { Balance } from '../types/Balance.js';
import ArbMatrixDataLayer from '../components/arbmatrix/ArbMatrixDataLayer.jsx';

type Props = {
  aggregatedMarkets?: [],
  exchanges: Array<Exchange>,
  markets: Array<Market>,
  accounts: Array<Account>,
  balances: Array<Balance>,
  isBalancesInit: boolean,
  isAccountsInit: boolean,
  isExchangesInit: boolean,
};

const ArbMatrixPage = (props: Props) => {
  const { aggregatedMarkets, exchanges, markets, accounts, balances, isBalancesInit, isAccountsInit, isExchangesInit } = props;

  return (
    <ArbMatrixDataLayer 
      exchanges={ exchanges }
      markets={ markets }
      aggregatedMarkets={ aggregatedMarkets } 
      accounts={ accounts }
      balances={ balances }
      isBalancesInit={ isBalancesInit }
      isAccountsInit={ isAccountsInit }
      isExchangesInit={ isExchangesInit } />
  );
};

export default ArbMatrixPage;

// @flow
'use strict';
import type { Panel as PanelEntity } from '../types/Panel.js';

export const PANEL_ID_PREFIX = `panelId-`;
export const BOARD_ID_PREFIX = `boardId-`;

export function migrateBoardToFlexlayoutVersion (panels: Array<PanelEntity>) {
  const chunk = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));    
    }

    return chunks;
  };

  const chunkTabs = () => {
    let tabs = panels.map((panel) => {
      return {
        type: `tab`,
        id: `${PANEL_ID_PREFIX}${panel?.panelId}`,
        name: ``,
        config: {
          "panelId": `${PANEL_ID_PREFIX}${panel?.panelId}`,
          "boardId": `${BOARD_ID_PREFIX}${panel?.boardId}`,
          "typeId": panel?.typeId
        }
      };
    });

    if (tabs.length > 6) tabs = chunk(tabs, 3);
    if (tabs.length > 3) tabs = chunk(tabs, 2);

    return tabs;
  };

  const tabs = chunkTabs();

  const tabsets = tabs.map((ct) => {
    const length = Array.isArray(ct) ? ct.length : 1;
    return {
      type: `tabset`,
      weight: 100/length,
      children: ct
    };
  });

  const rows = tabsets.map<any>((t) => {
    const length = Array.isArray(t.children) ? t.children.length : 1;
    const children = Array.isArray(t.children) ? t.children : [t.children];
    return {
      type: `row`,
      weight: 100/length,
      children: children.map((c) =>{
        const children = Array.isArray(c) ? c : [c];
        return {
          type: `tabset`,
          weight: 100,
          children: children
        };
      })
    };
  });

  return {
    type: `row`,
    children: rows
  };
}

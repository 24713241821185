// @flow
'use strict';

const translateOrder = (o: *) => {
  if (!o.orderTime) {
    o = { 
      ...o, 
      orderTime: o.openTime ? o.openTime : o.closeTime ? o.closeTime : o.updateTime ? o.updateTime : null
    };
  }

  if (!o.orderId) {
    o = { 
      ...o,
      orderId: o.coinigyOrderId
    };
  }

  return o;
};

export { translateOrder };

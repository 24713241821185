// @flow
'use strict';

import React from 'react';
import SelectField from '../utilities/SelectField.jsx';
import TextField from '../utilities/TextField.jsx';
import Checkbox from '../utilities/Checkbox.jsx';
import { getMarketPair } from '../../helpers/MarketPairHelper.js';
import { supportedPanels, NOTES } from '../../helpers/PanelTypeHelper';
import type { PanelType } from '../../types/PanelType.js';
import type { Market } from '../../types/Market.js';
import type { Exchange } from '../../types/Exchange.js';
import type { Account } from '../../types/Account.js';

type Props = {
  t: any,
  panelTypes: Array<PanelType>,
  markets: Array<Market>,
  exchanges: Array<Exchange>,
  accounts: Array<Account>,
  typeId: number,
  sizeId: number,
  isUpdate: boolean,
  settings: any,
  customOptionFilter?: (n: *) => boolean,
  handleCustomInputChange: *,
  handleInputChange: *
};

class PanelForm extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  buildCustomInputs(type: PanelType): Array<any> {
    const customOptionFilter = this.props.customOptionFilter || (() => true);
    return Object.keys(type.customOptions).filter(customOptionFilter).map((o) => {
      const option = type.customOptions[o];

      const exchange = this.props.exchanges.find((e) => e.exchCode === this.props.settings?.exchange);

      if (/^List\<([^\>]*)\>$/.test(option.type)) {
        switch (option.type.match(/^List\<([^\>]*)\>$/)[1]) {
        case `Market`:
          return (
            <SelectField
              key={ o }
              searchable={ true }
              label={ option.label || this.props.t(`app:market`) }
              name="market"
              value={ this.props.settings[o] || `` }
              options={
                this.props.markets.filter((m) => m.exchCode == this.props.settings.exchange).map((m) => ({
                  label: getMarketPair(m).toString(),
                  value: m.marketName
                })).sort((a, b) => {
                  if (a.label > b.label) return 1;
                  if (a.label < b.label) return -1;
                  return 0;
                })
              }
              onChange={ (e, v) => this.props.handleCustomInputChange(v, o) } />
          );
        case `Exchange`:
          return (
            <SelectField
              key={ o }
              searchable={ true }
              label={ option.label || this.props.t(`app:exchange`) }
              name="exchange"
              value={ exchange || `` }
              options={
                this.props.exchanges.map((e) => ({
                  label: e.exchName,
                  value: e
                })).sort((a, b) => {
                  if (a.label > b.label) return 1;
                  if (a.label < b.label) return -1;
                  return 0;
                })
              }
              onChange={ (e, v) => this.props.handleCustomInputChange(v.exchCode, o, (s) => ({
                ...s,
                market: ``,
                exchId: v.exchId
              })) } />
          );
        case `Account`:
          return (
            <SelectField 
              key={ o }
              searchable={ false }
              label={ option.label || this.props.t(`app:account`) }
              name="account"
              value={ this.props.settings[o] || `` }
              options={
                this.props.accounts.filter((m) => m.authExchId == exchange?.exchId).map((e) => ({
                  label: e.authNickname,
                  value: e.authId
                }))
              }
              onChange={ (e, v) => this.props.handleCustomInputChange(v, o) }/>
          );
        }
      } else if (option.type == `List`) {
        const label = o.replace(/_/g, ` `).replace(/\b(\w)/gi, (a) => a.toUpperCase()).replace(/([A-Z]+|\d+)/g, (m) => {
          return ` ${ m }`;
        }).replace(/^\s/, ``);

        if (typeof option.items[0] == `string`) {
          return (
            <SelectField
              key={ o }
              label={ option.label || label }
              name={ o }
              value={ this.props.settings[o] || `` }
              options={
                option.items.map((v) => ({
                  label: v,
                  value: v
                }))
              }
              onChange={ (e, v) => this.props.handleCustomInputChange(v, o) } />
          );
        }

        return (
          <SelectField
            key={ o }
            label={ option.label || label }
            name={ o }
            value={ this.props.settings[o] || `` }
            options={
              option.items.map((v) => ({
                label: v.label,
                value: v.id
              }))
            }
            onChange={ (e, v) => this.props.handleCustomInputChange(v, o) } />
        );
      } else if (option.type == `String`) {
        const label = o.replace(/_/g, ` `).replace(/\b(\w)/gi, (a) => a.toUpperCase()).replace(/([A-Z]+|\d+)/g, (m) => {
          return ` ${ m }`;
        }).replace(/^\s/, ``);

        return (
          <TextField
            key={ o }
            label={ option.label || label }
            name={ o }
            value={ this.props.settings[o] || `` }
            onChange={ (e) => this.props.handleCustomInputChange(e.target.value, o) } />
        );
      } else if (option.type == `Boolean`) {
        const label = o.replace(/_/g, ` `).replace(/\b(\w)/gi, (a) => a.toUpperCase()).replace(/([A-Z]+|\d+)/g, (m) => {
          return ` ${ m }`;
        }).replace(/^\s/, ``);

        return (
          <div key={ o } className="checkbox-wrapper">
            <Checkbox
              label={ option.label || label }
              name={ o }
              value={ !!this.props.settings[o] }
              onChange={ (e) => this.props.handleCustomInputChange(e.target.checked, o) } />
          </div>
        );
      }

      return null;
    });
  }

  buildNotesInputs() {
    return (
      <>
        <SelectField
          key={ `exchange` }
          searchable={ true }
          label={ this.props.t(`app:exchange`) }
          name="exchange"
          value={ this.props.settings[`exchange`] || `` }
          options={
            this.props.exchanges.map((e) => ({
              label: e.exchName,
              value: e.exchCode
            })).sort((a, b) => {
              if (a.label > b.label) return 1;
              if (a.label < b.label) return -1;
              return 0;
            })
          }
          onChange={ (e, v) => this.props.handleCustomInputChange(v, `exchange`, (s) => ({
            ...s,
            market: ``
          })) }/>
        <SelectField
          key={ `market` }
          searchable={ true }
          label={ this.props.t(`app:market`) }
          name="market"
          value={ this.props.settings[`market`] || `` }
          options={  this.props.markets.filter((m) => m.exchCode == this.props.settings.exchange).map((m) => ({
            label: getMarketPair(m).toString(),
            value: m.marketName
          })).sort((a, b) => {
            if (a.label > b.label) return 1;
            if (a.label < b.label) return -1;
            return 0;
          }) }
          onChange={ (e, v) => this.props.handleCustomInputChange(v, `market`) } /> 
      </>
    );
  }

  render() {
    const activeType = this.props.panelTypes.find((t) => t.typeId == this.props.typeId);
    return (
      <div className="panel-form">
        <SelectField
          label={ this.props.t(`app:type`) }
          name={ `typeId` }
          value={ this.props.typeId }
          disabled={ this.props.isUpdate }
          options={ this.props.panelTypes.map((p) => ({
            value: p.typeId,
            label: p.titleFormat
          })).filter((p) => supportedPanels.includes(p.value)) }
          onChange={ (e, v) => this.props.handleInputChange(v, `typeId`) } />

        { /* {
          activeType && (
            <SelectField
              label={ this.props.t(`app:size`) }
              name={ `size` }
              value={ this.props.sizeId }
              options={ activeType.sizes.map((s) => ({
                value: s.id,
                label: `${ s.label } (${ s.width }x${ s.height })`
              })) }
              onChange={ (e, v) => this.props.handleInputChange(v, `sizeId`) } />
          )
        } */ }
        {
          activeType && this.props.isUpdate &&
          (
            activeType.typeId === NOTES ? 
              this.buildNotesInputs()
              : 
              this.buildCustomInputs(activeType)
          )
        }
      </div>
    );
  }
}

export default PanelForm;

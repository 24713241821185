// @flow
'use strict';

import React from 'react';
import Button from '../utilities/Button.jsx';
import TextField from '../utilities/TextField.jsx';
import Modal from "../utilities/Modal.jsx";
import EntityTable from "../utilities/EntityTable.jsx";
import Close from '../../svgs/Close.jsx';
import { toFixedDecimals } from "../../helpers/NumberHelper.js";
import type { Account } from "../../types/Account.js";
import type { Balance } from "../../types/Balance.js";

type AccountWithBalances = Account & {
  balances?: Array<Balance>
};

type Props = {
  t: any,
  date: string,
  retrievePrev: () => Array<Balance>,
  goToNextDay: () => boolean,
  goToPrevDay: () => boolean,
  save: (b: Array<Balance>, n: Array<Balance>, c: Array<Balance>, d: Array<Balance>) => void,
  exit: () => void,
  accounts: Array<Account>,
  balances: Array<Balance>,
  isFirstDay: boolean,
  isLastDay: boolean
};

type State = {
  balances: Array<Balance & {
    isNewBalance?: boolean,
    isDiffCurrCode?: boolean
  }>,
  editedBalanceIds: Array<number>,
  deletedBalanceIds: Array<number>,
  clonedPrev: boolean
};

class BalanceEditModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = this.getInitialState();
  }

  getInitialState(props: Props = this.props) {
    let balances: Array<Balance & { isNewBalance?: boolean, isDiffCurrCode?: boolean }> = props.balances.map((b) => ({ 
      ...b, 
      isNewBalance: false,
      isDiffCurrCode: false
    }));

    return {
      balances,
      editedBalanceIds: [],
      deletedBalanceIds: [],
      clonedPrev: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (JSON.stringify(this.props.balances) !== JSON.stringify(nextProps.balances) || 
        this.props.date !== nextProps.date) {
      this.setState(this.getInitialState(nextProps));
    }
  }

  addNewBalanceForAccount(account: AccountWithBalances) {
    this.setState({
      balances: this.state.balances.concat([{
        balanceId: Date.now(),
        isNewBalance: true,
        isDiffCurrCode: false,
        balanceAuthId: account.authId,
        balanceCurrCode: `BTC`,
        balanceCurrId: 1,
        balanceAmountAvailable: 0,
        balanceAmountHeld: 0,
        balanceAmountTotal: 0,
        quoteBalance: 0,
        balanceQuoteCurrCode: `BTC`,
        lastPrice: 0,
        balanceDate: 
          this.state.balances.length > 0 ? 
            this.state.balances[0].balanceDate : 
            new Date(`${ this.props.date } UTC`).toISOString(),
        date: 
          this.state.balances.length > 0 ? 
            this.state.balances[0].date : 
            +new Date(`${ this.props.date } UTC`),
        balanceHidden: false,
        hasImage: false
      }])
    });
  }

  handleBalanceEdit(balanceId: number, key: string, value: *) {
    this.setState({
      editedBalanceIds: this.state.editedBalanceIds.concat([balanceId]).reduce((a, id) => {
        return a.includes(id) ? a : a.concat([id]);
      }, []),
      balances: this.state.balances.map((b) => {
        if (b.balanceId == balanceId) {
          let edit: any = { [key]: value };

          if (key == `lastPrice`) {
            edit.balanceAmountTotal = toFixedDecimals(value * b.balanceAmountAvailable, true, `price`);
          } else if (key == `balanceAmountTotal`) {
            edit.balanceAmountAvailable = toFixedDecimals(value / b.lastPrice, true, `price`);
          } else if (key == `balanceAmountAvailable`) {
            edit.balanceAmountTotal = toFixedDecimals(value * b.lastPrice, true, `price`);
          } else if (key == `balanceCurrCode` && !b.isDiffCurrCode && !b.isNewBalance) {
            edit.previousBalanceCurrCode = b.balanceCurrCode;
            edit.isDiffCurrCode = true;
          }

          return {
            ...b,
            ...edit
          };
        }

        return b;
      })
    });
  }

  handleBalanceDelete(balanceId: number) {
    this.setState({
      deletedBalanceIds: this.state.deletedBalanceIds.concat([balanceId]).reduce((a, id) => {
        return a.includes(id) ? a : a.concat([id]);
      }, [])
    });
  }

  handleClonePrevDay() {
    this.setState({
      balances: this.state.balances.concat(this.props.retrievePrev().map((b) => ({
        ...b,
        isNewBalance: true,
        isDiffCurrCode: false,
        balanceId: Date.now(),
        balanceDate: 
          this.state.balances.length > 0 ? 
            this.state.balances[0].balanceDate : 
            new Date(`${ this.props.date } UTC`).toISOString(),
        date: 
          this.state.balances.length > 0 ? 
            this.state.balances[0].date : 
            +new Date(`${ this.props.date } UTC`),
      }))),
      deletedBalanceIds: this.state.balances.map((b) => b.balanceId || Date.now()),
      clonedPrev: true
    });

    return true;
  }

  save() {
    let deletedBalanceIds = this.state.deletedBalanceIds,
      existingBalancesRaw = this.state.balances.filter((b) => 
        !b.isNewBalance && !b.isDiffCurrCode && !deletedBalanceIds.includes(b.balanceId)
      ),
      newBalancesRaw = this.state.balances.filter((b) => b.isNewBalance && !deletedBalanceIds.includes(b.balanceId)),
      changedCurrCodesRaw = this.state.balances.filter((b) => 
        b.isDiffCurrCode && !deletedBalanceIds.includes(b.balanceId)
      );

    let existingBalances: Array<Balance> = existingBalancesRaw.filter((b) => {
      return this.state.editedBalanceIds.includes(b.balanceId);
    }).map((b) => {
      delete b.isNewBalance;
      delete b.isDiffCurrCode;
      return b;
    });

    let deletedBalances: Array<Balance> = deletedBalanceIds.filter((balanceId) => {
      return !newBalancesRaw.find((b) => b.balanceId == balanceId);
    }).map((balanceId) => this.state.balances.find((b) => b.balanceId == balanceId)).filter((b) => !!b).map((b) => {
      // $FlowIgnore: suppressing this error
      delete b.isNewBalance;
      // $FlowIgnore: suppressing this error
      delete b.isDiffCurrCode;
      // $FlowIgnore: suppressing this error
      return b;
    });

    let newBalances: Array<Balance> = newBalancesRaw.map((b) => {
      delete b.isNewBalance;
      delete b.isDiffCurrCode;
      delete b.balanceId;
      return b;
    });

    let changedCurrCodes: Array<Balance> = changedCurrCodesRaw.map((b) => {
      delete b.isNewBalance;
      delete b.isDiffCurrCode;
      return b;
    });

    // $FlowIgnore: suppressing this error
    this.props.save(existingBalances, newBalances, changedCurrCodes, deletedBalances);
  }

  render() {
    let accountsWithBalances: Array<AccountWithBalances> = this.state.balances.filter((b) => {
      return !this.state.deletedBalanceIds.includes(b.balanceId);
    }).reduce((accounts, balance) => {
      return accounts.map((account) => {
        if (account.authId == balance.balanceAuthId) {
          let a: AccountWithBalances = { ...account };
          if (!a.hasOwnProperty(`balances`)) a.balances = [];
          if (a.hasOwnProperty(`balances`) && Array.isArray(a.balances)) a.balances.push(balance);
          return a;
        }

        return account;
      });
    }, this.props.accounts.map((a) => ({ ...a, balances: [] })));

    return (
      <Modal
        title={ `Editing Balances for ${ this.props.date }` }
        onConfirm={ this.save.bind(this) }
        onClose={ this.props.exit }
        confirmText={ this.props.t(`app:save`) }
        cancelText={ this.props.t(`app:cancel`) }>
        <div className="editing-balances-modal-nav">
          {
            !this.props.isFirstDay && (
              <Button onClick={ this.props.goToPrevDay }>
                Previous Day
              </Button>
            )
          }
          <div className="spacer" />
          {
            !this.props.isLastDay && (
              <Button onClick={ this.props.goToNextDay }>
                Next Day
              </Button>
            )
          }
        </div>
        <div className="editing-balances-modal">
          {
            accountsWithBalances.map((account: AccountWithBalances) => (
              <div key={ account.authId }>
                <h3>
                  <span>
                    { account.authNickname }
                  </span>
                  <Button
                    type="default"
                    onClick={ () => {
                      this.addNewBalanceForAccount(account);
                      return true;
                    } }>
                    + Add Balance
                  </Button>
                </h3>
                <EntityTable
                  hidePagination
                  columns={ [
                    {
                      nosort: true,
                      title: ``,
                      key: `delete`,
                      display: (row) => (
                        <a onClick={ () => this.handleBalanceDelete(row.balanceId) } style={ { cursor: `pointer` } }>
                          { Close(row.balanceId) }
                        </a>
                      )
                    },
                    {
                      nosort: true,
                      title: this.props.t(`app:currency`),
                      key: `balanceCurrCode`,
                      display: (row, code) => (
                        <TextField
                          label=""
                          compact
                          value={ code }
                          name={ `balanceCurrCode` }
                          onChange={ (e) => 
                            this.handleBalanceEdit(row.balanceId, `balanceCurrCode`, e.target.value)
                          } />
                      )
                    },
                    {
                      nosort: true,
                      title: this.props.t(`app:available`),
                      key: `balanceAmountAvailable`,
                      display: (row, price) => (
                        <TextField
                          label=""
                          compact
                          value={ price }
                          type={ `number` }
                          name={ `balanceAmountAvailable` }
                          onChange={ (e) => 
                            this.handleBalanceEdit(row.balanceId, `balanceAmountAvailable`, e.target.value)
                          } />
                      )
                    },
                    {
                      nosort: true,
                      title: this.props.t(`app:exchangeRate`) + ` (BTC)`,
                      key: `lastPrice`,
                      display: (row, price) => (
                        <TextField
                          label=""
                          compact
                          value={ price }
                          type={ `number` }
                          name={ `lastPrice` }
                          onChange={ (e) => 
                            this.handleBalanceEdit(row.balanceId, `lastPrice`, e.target.value)
                          } />
                      )
                    },
                    {
                      nosort: true,
                      title: this.props.t(`app:total`),
                      key: `balanceAmountTotal`,
                      display: (row, price) => (
                        <TextField
                          label=""
                          compact
                          value={ price }
                          type={ `number` }
                          name={ `balanceAmountTotal` }
                          onChange={ (e) => 
                            this.handleBalanceEdit(row.balanceId, `balanceAmountTotal`, e.target.value)
                          } />
                      )
                    }
                  ] }
                  data={ account.balances || [] }
                  defaultSortBy={ `+balanceId` }
                  pageSize={ (account.balances || []).length } />
              </div>
            ))
          }
        </div>
        {
          !this.props.isFirstDay && !this.state.clonedPrev && (
            <div className="editing-balances-modal-nav">
              <div className="spacer" />
              <Button type="cancel" onClick={ this.handleClonePrevDay.bind(this) }>
                { `Clone Previous Day's Balances` }
              </Button>
              <div className="spacer" />
            </div>
          )
        }
      </Modal>
    );
  }
}

export default BalanceEditModal;

// @flow
'use strict';

import keymap from '../kb.js';

import {
  SET_KEY_SHORTCUT,
  RESET_ALL_BINDINGS
} from '../actions/index.js';

type Action = {
  type: string,
  payload: any
};

export const initialState: * = keymap;

export default function app(state: * = initialState, action: Action) {
  switch(action.type) {
  case SET_KEY_SHORTCUT:
    return {
      ...state,
      [action.payload.shortcut]: action.payload.key
    };
  case RESET_ALL_BINDINGS:
    return keymap;
  }
  return state;
}

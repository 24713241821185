'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default (uniqueId = `MarketUpArrow`) => SvgHelper(
  `<defs><path d="M11 6.414l-4.293 4.293a1 1 0 1 1-1.414-1.414l6-6a1 1 0 0 1 1.414 0l6 6a1 1 0 1 1-1.414 1.414L13 6.414V20a1 1 0 0 1-2 0V6.414z" id="${ uniqueId }a"/></defs><g transform="translate(-5 -3)" fill="none" fill-rule="evenodd"><mask id="${ uniqueId }b" fill="#fff"><use xlink:href="#${ uniqueId }a"/></mask><use fill="#000" fill-rule="nonzero" xlink:href="#${ uniqueId }a"/><g mask="url(#${ uniqueId }b)" fill="#66A57C"><path d="M0 0h24v24H0z"/></g></g>`,
  {
    width: `1.4rem`,
    height: `1.8rem`,
    viewBox: `0 0 14 18`
  }
);
/* eslint-enable */

// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import NewsFeed from '../utilities/NewsFeed.jsx';
import type { Market } from '../../types/Market.js';

type Settings = {
  searchTermOptional: string
};

type Props = {
  t: any,
  market: Market,
  settings: Settings,
  panelData: any
};

const NewsFeedPanel = ({ t, settings, panelData }: Props) => {
  if (panelData.hasOwnProperty(`NEWSFEED`) && 
      panelData[`NEWSFEED`][settings.searchTermOptional || ``]) {

    if (panelData[`NEWSFEED`][settings.searchTermOptional || ``].length == 0 && 
        (settings.searchTermOptional || ``).length > 0) {
      return (
        <div className="panel-loading">
          <span>
            There are no news results for
            { ` ` }
            { `"${ settings.searchTermOptional }"` }
          </span>
        </div>
      );
    }

    return (
      <div className="news-feed-panel">
        <NewsFeed news={ panelData[`NEWSFEED`][settings.searchTermOptional || ``] } />
      </div>
    );
  } else {
    return (
      <div className="panel-loading">
        <span>
          { t(`app:loading`) }
        </span>
      </div>
    );
  }
};

NewsFeedPanel.getPanelTitle = (settings: Settings) => {
  if (settings.searchTermOptional && settings.searchTermOptional.length > 0) {
    return `"${ settings.searchTermOptional }" News`;
  }

  return `News Feed`;
};

export default translate(`boards`)(NewsFeedPanel);

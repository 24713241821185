// @flow
`use strict`;

import { $ } from "./ApiHelper";

export function getPriceTypes(onSuccess: (data: any) => void) {
  $({
    url: `/private/orderPriceTypes`,
    success: onSuccess
  });
}

export function getOrderTypes(onSuccess: (data: any) => void) {
  $({
    url: `/private/orderTypes`,
    success: onSuccess
  });
}

export function getStatusTypes(onSuccess: (data: any) => void) {
  $({
    url: `/private/orderStatusTypes`,
    success: onSuccess
  });
}

export function getOrders(options: any, onSuccess: (data: any) => void) {
  const { pageSize, pageNumber, SearchTerm, ExchMktId, AuthId } = options.params;
  const qs = `?PageSize=${pageSize}&PageNumber=${pageNumber}${SearchTerm ? `&SearchTerm=`+SearchTerm:``}${ExchMktId ? `&ExchMktId=`+ExchMktId:``}${AuthId ? `&AuthId=`+AuthId:``}`;
  $({
    url: `/private/user/orders${qs}`,
    success: onSuccess
  });
}

export function getOrderHistory(options: any, onSuccess: (data: any) => void) {
  const { pageSize, pageNumber, SearchTerm, ExchMktId, AuthId, Status, Since } = options.params;
  const qs = `${SearchTerm ? `&SearchTerm=`+SearchTerm:``}${ExchMktId ? `&ExchMktId=`+ExchMktId:``}${AuthId ? `&AuthId=`+AuthId:``}${Status ? `&Status=`+Status:``}${Since ? `&Since=`+Since:``}`;
  
  $({
    url: `/private/user/orders/history?PageSize=${pageSize}&PageNumber=${pageNumber}${qs}`,
    success: onSuccess
  });
}

export function cancelV2Order(options: any, onSuccess?: (data: any) => void) {
  $({
    url: `/private/user/accounts/${ options.authId }/orders/${ options.orderId }`,
    type: `DELETE`,
    version: `v2`,
    success: onSuccess || (() => {})
  });
}

export function cancelV1Order(orderId: *, onSuccess?: (data: any) => void) {
  $({
    url: `/post/CancelOrder`,
    type: `POST`,
    body: {
      order_id: orderId
    },
    version: `v1`,
    success: onSuccess || (() => {})
  });
}

export function setV1Order(body: any, onSuccess: (data: any) => void) {
  $({
    url: `/post/AddInternalOrder`,
    type: `POST`,
    body,
    version: `v1`,
    success: onSuccess
  });
}

export function setV2LimitOrder(authId: number, body: any, onSuccess: (data: any) => void) {
  $({
    url: `/private/user/accounts/${ authId }/orders`,
    type: `POST`,
    body,
    version: `v2`,
    success: onSuccess
  });
}

export function setV2StopLimitOrder(authId: number, body: any, onSuccess: (data: any) => void) {
  $({
    url: `/private/user/accounts/${ authId }/orders`,
    type: `POST`,
    body,
    version: `v2`,
    success: onSuccess
  });
}

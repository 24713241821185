// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import * as alertApi from '../../helpers/api/AlertApi';
import { listenForEvent, removeEventListener, NOTIFICATION, ALERT_ADDED } from '../../helpers/EventHelper.js';
import { toLocalDate } from '../../helpers/DateHelper.js';
import { convertScinotToDecimal } from '../../helpers/NumberHelper.js';
import DataTable, { FILTER_CONTROL_SELECT } from '../utilities/DataTable';
import PillToggle from "../utilities/PillToggle.jsx";
import TextField from '../utilities/TextField.jsx';
import CoinigyBaseComponent from '../CoinigyBaseComponent.jsx';
import Search from '../../svgs/Search.jsx';
import type { HistoryAlert } from '../../types/HistoryAlert.js';
import type { Market } from '../../types/Market.js';
import type { Exchange } from '../../types/Exchange.js';
import { updateAlertActiveType } from '../../actions/markets/updateAlertActiveType.js';
import Button from '../utilities/Button.jsx';
import Modal from '../utilities/Modal.jsx';
import NewAlert from './NewAlert.jsx';

const API_POLL_MS = 90000;
const NOTIFICATION_TYPES = [3, 223];

type Props = {
  t: any,
  title: string,
  markets?: Array<Market>,
  exchanges?: Array<Exchange>,
  getTickerData?: (e: Exchange, m: Market, c: (p: number) => void) => void,
  changeActiveTab: (n: any) => void,
  rehydrated: boolean,
  updateAlertType: (alertType: number) => void,
};

type State = {
  historyAlerts: Array<HistoryAlert>,
  filterText: string,
  isTyping: boolean,
  apiInterval: any,
  newModalOpen: boolean,
  lastUpdateTime: any,
};

class HistoryAlertsTable extends CoinigyBaseComponent<Props, State> {
  notificationHandler: (e: any) => void;
  newAlertHandler: (e: any) => void;

  constructor(props: Props) {
    super(props);

    this.notificationHandler = this.handleNotification.bind(this);

    this.state = {
      historyAlerts: [],
      historyCount: 0,
      historyPage: 1,
      historyPages: 1,
      filterText: ``,
      isTyping: false,
      apiInterval: -1,
      newModalOpen: false,
      lastUpdateTime: 0
    };
  }


  componentDidMount() {
    this._isMounted = true;

    listenForEvent(NOTIFICATION, this.notificationHandler);
    listenForEvent(ALERT_ADDED, this.newAlertHandler);
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.state.apiInterval);
    removeEventListener(NOTIFICATION, this.notificationHandler);
    removeEventListener(ALERT_ADDED, this.newAlertHandler);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {

    if (prevState.isTyping == true && this.state.isTyping == false) {
      this.setStateSafe({ lastUpdateTime: new Date().getTime() });
    }
  }

  handleNotification(e: any) {
    if (NOTIFICATION_TYPES.indexOf(e.detail.type) > -1) {
      setTimeout(() => this.setStateSafe({ lastUpdateTime: new Date().getTime() }), 500);
    }
  }

  successCallback = (data: any) => {
    return {
      result: this.mapRawHistoryAlertsToHistoryAlerts(data.result),
      totalRecords: data.totalRecords,
      totalPages: data.totalPages
    };
  };

  getData(currentPage: number, pageSize: number): any {
    return new Promise((resolve) => {

      const apiEndpointFunction = alertApi.getAlertsHistory;
      
      let options = {
        params: {
          PageSize: pageSize,
          PageNumber: currentPage,
          SearchTerm: this.state.filterText
        }
      };

      clearInterval(this.state.apiInterval);

      this.setStateSafe({
        apiInterval: setInterval(() => {
          apiEndpointFunction(options, (data) => resolve(this.successCallback(data)));
        }, API_POLL_MS),
      }, () => {
        apiEndpointFunction(options, (data) => resolve(this.successCallback(data)));
      });
    });
  }

  handleInputChange(value: any, key: string, forceNumber?: boolean) {
    this.setStateSafe({
      [key]: forceNumber ? parseInt(value) : value
    });

    if (key == `filterText`) {
      this.setStateSafe({ historyPage: 1, isTyping: true });

      setTimeout(() => {
        this.setStateSafe({ isTyping: false });
      }, 1000);
      
    }
  }

  render = () => {
    let columns = [
      {
        title: this.props.t(`app:exchange`),
        key: `exchange`,
        display: (row, exchange) => {
          const exchCode = this.props.exchanges?.find( (e) => e.exchName === exchange)?.exchCode || ``;
          return (
            <span className='cell-span'>
              <img
                className={ `exch-img` }
                src={ window.WWW_URL + `/assets/img/exchange/` + exchCode + `-icon.png` } 
                onError={ (e) => {e.target.onerror = null; e.target.src=`${ window.WWW_URL }/assets/img/currency/empty.png`;} } 
                width={ `20rem` }
                height={ `20rem` }/>
              { exchange }
            </span>
          );
        }
      },
      {
        title: this.props.t(`app:market`),
        key: `market`,
        display: (row, market) => {
          const logo = market?.split(`/`);
          return (
            <span className='cell-span gap'>
              { logo?.length >= 2 && 
              <span className='currencies-section'>
                <img
                  src={ `${ window.WWW_URL }/assets/img/currency/${ logo[1] }.png` } 
                  width={ 20 }
                  height={ 20 }
                  onError={  (e)=>{e.target.onerror = null; e.target.src=`${ window.WWW_URL }/assets/img/currency/empty.png`;} } />
                <img
                  src={ `${ window.WWW_URL }/assets/img/currency/${ logo[0] }.png` }
                  className='base-curr-logo'
                  width={ 30 } 
                  height={ 30 }        
                  onError={ (e) => {e.target.onerror = null; e.target.src=`${ window.WWW_URL }/assets/img/currency/empty.png`;} } />
              </span>
              }
              { market }
            </span>
          );
        }
      },
      {
        title: this.props.t(`app:type`),
        key: `type`,
        filterControl: {
          type: FILTER_CONTROL_SELECT,
          options: [
            {
              label: `Price`,
              value: `price`
            },
            {
              label: `Volume`,
              value: `volume`
            },
          ]
        }
      },
      {
        title: this.props.t(`app:criteria`),
        key: `criteria`
      },
      {
        title: this.props.t(`alerts:note`),
        key: `note`
      },
      {
        title: this.props.t(`app:triggered`),
        key: `time`,
        display: (row, date) => toLocalDate(date),
        compareValue: (row) => toLocalDate(row.time),
      }
    ];

    return (
      <>
        <div className="header">
          <div className="title-holder">
            <h1 className="page-title">
              { this.props.t(`app:alert`) }
            </h1>
            <div>
              <Button
                className="add-alert"
                type="ghost" onClick={ () => { this.setStateSafe({ newModalOpen: true }); return false; } }>
                { `+ ` + this.props.t(`newAlert`) }
              </Button>
            </div>
          </div>
          <div className="nav-holder">
            <div className="button-container">
              <PillToggle
                options={ [
                  {
                    value: 0,
                    label: this.props.t(`alerts:activeAlerts`)
                  },
                  { 
                    value: 1,
                    label: this.props.t(`alerts:alertHistory`)
                  }
                ] }
                value={ 1 }
                onChange={ (e, v) => {
                  this.setStateSafe({ lastUpdateTime: new Date().getTime() });
                  this.props.changeActiveTab(v); 
                } } />
            </div>
            <div className="search">
              <TextField
                icon={ Search }
                label={ this.props.t(`app:search`) }
                name={ `search-history` }
                value={ this.state.filterText }
                onChange={ (e) => this.handleInputChange(e.target.value, `filterText`) }
                onDoubleClick={ () => this.handleInputChange(``, `filterText`) } />
            </div>
          </div>
        </div>
        <div className="alerts-container">
          <div className="alerts-table">
            <DataTable
              id={ `history-alerts-table` }
              columns={ columns }
              defaultSortBy={ `-time` }
              filterText={ this.state.filterText }
              fetchData={ (currentPage, pageSize) => this.getData(currentPage, pageSize) }
              isFullWidth/>
          </div>
          {
            this.state.newModalOpen && (
              <Modal
                title={ this.props.t(`newAlert`)  }
                onClose={ () => this.setStateSafe({ newModalOpen: false }) }>
                <NewAlert
                  markets={ this.props.markets || [] }
                  exchanges={ this.props.exchanges || [] }
                  getTickerData={ this.props.getTickerData || (() => { }) }
                  updateAlertType={ this.props.updateAlertType }
                  onRequestClose={ () => this.setStateSafe({ newModalOpen: false }) } />
              </Modal>
            )
          }
        </div>
      </>
    );
  }

  mapRawHistoryAlertsToHistoryAlerts(rawAlerts: Array<any>): Array<HistoryAlert> {
    let alerts: Array<HistoryAlert> = rawAlerts.map<HistoryAlert>((raw) => {

      if (raw.type == 0)
      {
        let direction = raw.condition === `GreaterThanOrEqual` ? `above` : `below`;
        let alert: HistoryAlert = {
          alertId: raw.alertId,
          exchMktId: raw.exchMktId,
          exchange: raw.exchangeName,
          market: raw.marketName,
          type: `PRICE`,
          note: raw.note,
          sound: raw.sound,
          time: raw.triggeredOn,
          criteria: `Price goes ${direction} ${convertScinotToDecimal(raw.price)}`,
          data: raw
        };
        return alert;
      } else if(raw.type == 1){
        let alert: HistoryAlert = {
          alertId: raw.alertId,
          exchMktId: raw.exchMktId,
          exchange: raw.exchangeName,
          market: raw.marketName,
          type: `VOLUME`,
          note: raw.note,
          sound: raw.sound,
          time: raw.triggeredOn,
          criteria: `Current volume crossed above ${raw.numCandles}x${raw.interval} VMA by
                    ${convertScinotToDecimal(raw.value)}${raw.condition == `PercentChange` ? `%` : ` ${raw.marketName.split(`/`)[0]}`}`,
          data: raw
        };
        return (alert: HistoryAlert);
      } else {
        throw new Error(`Unexpected history alert type: ${raw.type}`);
      }
    });
    return alerts;
  }
}


const mapDispatchToProps = (dispatch) => ({
  updateAlertType: (alertType) => dispatch(updateAlertActiveType(alertType))
});

export { HistoryAlertsTable as PureAlertsTable };
export default translate(`alerts`)(connect(null, mapDispatchToProps)(HistoryAlertsTable));

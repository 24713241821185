// @flow
'use strict';

import React from 'react';

type Props = {
  options: Array<{
    label: string,
    value: any,
    name: string,
    disabled?: boolean
  }>,
  value: any,
  name: string,
  label?: string,
  disabled?: boolean,
  onChange: (e: any, value: boolean) => void
};

const RadioButtons = ({ options, value, name, onChange, label, disabled }: Props) => {
  return (
    <div className={ `radiobutton-group ${ label ? `has-label` : `` } ${ disabled ? `disabled` : `` }` }>
      {
        label && (
          <span className="label">
            { label }
          </span>
        )
      }
      {
        options.map((o) => {
          let props = { };
          if (o.disabled || disabled) props.disabled = `disabled`;

          return (
            <label 
              key={ o.name }
              htmlFor={ o.name }>
              <input
                data-testid={ `radio-button-${ o.name }` }
                type="radio" 
                value={ o.value }
                name={ name }
                id={ o.name }
                checked={ value == o.value }
                onChange={ onChange }
                { ...props } />
              <span>
                { o.label }
              </span>
            </label>
          );
        })
      }
    </div>
  );
};

export default RadioButtons;

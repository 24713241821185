// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import Modal from '../utilities/Modal.jsx';

type Props = {
  t: any,
  title: string,
  confirm: () => void,
  disabled: boolean,
  close: () => void,
  all: boolean
};

const SessionDeleteModal = function (props: Props) {
  return (
    <Modal
      title={ props.title }
      onConfirm={ () => props.confirm() }
      onClose={ props.close }
      confirmText={ props.t(`app:confirm`) }
      cancelText={ props.t(`app:cancel`) }
      confirmDisabled={ false } >
      <>
        <p>
          { props.all ? props.t(`user:confirmDeleteAllSessions`) : props.t(`user:confirmDeleteSession`) }
        </p>
      </>
    </Modal>
  );
};

export { SessionDeleteModal as PureSessionDeleteModal };
export default translate(`users`)(SessionDeleteModal);

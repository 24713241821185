// @flow
'use strict';

import React from 'react';
import Modal from '../utilities/Modal.jsx';
import TextField from '../utilities/TextField.jsx';
import Copy from '../../svgs/Copy.jsx';

type Props = {
  boardTag: string,
  url: string,
  close: () => void
};

let copyURLToClipboard = (e: any) => {
  e.preventDefault();

  const toCopy: any = document.getElementById(`boardSnapshotURL`);

  if (toCopy) {
    toCopy.focus();
    toCopy.select();
    document.execCommand(`copy`);
  }
};

const BoardSnapshotModal = ({ boardTag, url, close }: Props) => (
  <Modal
    title={ `Share Your Board Snapshot` }
    onClose={ close }>
    <div className="board-share">
      <TextField
        readOnly
        label={ `Board Snapshot URL` }
        name="boardSnapshotURL"
        compact={ true }
        value={ url } />
      <button onClick={ copyURLToClipboard }>
        { Copy }
      </button>
    </div>

    <p>
      <a
        className="twitter-share-button"
        data-url=" "
        target="_blank" rel="noopener noreferrer" href={ 
          `https://twitter.com/intent/tweet?via=coinigy&text=` + 
        encodeURIComponent(`Check out my Board! \n` + 
        `${ url } \n\n` + 
        `You can import it to your Coinigy account here: \n` + 
        `${ window.location.protocol }//${ window.location.host }/boards/${ boardTag }`)
        }>
        Tweet
      </a>
    </p>
  </Modal>
);


export default BoardSnapshotModal;

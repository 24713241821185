'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path fill-rule="evenodd" clip-rule="evenodd" d="M4 2H11C11.5523 2 12 2.44772 12 3V4H11C11 3.44772 10.5523 3 10 3H4C3.44772 3 3 3.44772 3 4H2C2 2.89543 2.89543 2 4 2ZM3 5H11H13C13.5523 5 14 5.44772 14 6V12C14 12.5523 13.5523 13 13 13H3C2.44772 13 2 12.5523 2 12V5H3ZM1 5V4C1 2.34315 2.34315 1 4 1H11C12.1046 1 13 1.89543 13 3V4C14.1046 4 15 4.89543 15 6V12C15 13.1046 14.1046 14 13 14H3C1.89543 14 1 13.1046 1 12V5ZM12 10C12.5523 10 13 9.55229 13 9C13 8.44772 12.5523 8 12 8C11.4477 8 11 8.44772 11 9C11 9.55229 11.4477 10 12 10Z" fill="currentColor"/>`,
  {
    width: `16px`,
    height: `16px`,
    viewBox: `0 0 16 16`
  }
);

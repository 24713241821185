// @flow
'use strict';

import { UPDATE_RESOLUTION } from '../index.js';

export function updateResolution(payload: string) {
  return {
    type: UPDATE_RESOLUTION,
    payload
  };
}

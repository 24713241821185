// @flow
'use strict';

import React from 'react';
import Help from "../../svgs/Help.jsx";

export const ToggleHelpButton = (props: any) => {
  const { toggleHelpMenu } = props;

  return(
    <a className="help-menu-link" data-testid={ `help-menu-link` } onClick={ () => toggleHelpMenu() }>
      { Help }
    </a>
  );
};

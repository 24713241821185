'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default () => SvgHelper(
  `<path fill-rule="evenodd" clip-rule="evenodd" d="M3.48019 6.83284C3.99828 4.81993 5.82631 3.33333 7.99988 3.33333C9.11867 3.33333 10.1453 3.72659 10.9496 4.38356L9.80461 5.5286C9.38463 5.94857 9.68207 6.66667 10.276 6.66667L13.3332 6.66667C13.7014 6.66667 13.9999 6.36819 13.9999 6V2.94281C13.9999 2.34887 13.2818 2.05143 12.8618 2.4714L11.8961 3.4371C10.8482 2.54161 9.48715 2 7.99988 2C5.2032 2 2.85497 3.9128 2.18894 6.50049C2.09717 6.85706 2.31183 7.22052 2.6684 7.31229C3.02496 7.40407 3.38842 7.18941 3.48019 6.83284ZM13.8108 9.49951C13.9026 9.14294 13.6879 8.77948 13.3314 8.68771C12.9748 8.59593 12.6113 8.81059 12.5196 9.16716C12.0015 11.1801 10.1734 12.6667 7.99988 12.6667C6.88137 12.6667 5.85441 12.2733 5.05015 11.6165L6.19526 10.4714C6.61524 10.0514 6.31779 9.33333 5.72386 9.33333H2.66667C2.29848 9.33333 2 9.63181 2 10L2 13.0572C2 13.6511 2.71809 13.9486 3.13807 13.5286L4.10374 12.5629C5.15168 13.4585 6.51237 14 7.99988 14C10.7966 14 13.1448 12.0872 13.8108 9.49951Z" fill="currentColor" fill-opacity="0.6"/>`,
  {
    width: `16px`,
    height: `16px`,
    viewBox: `0 0 16 16`
  }
);
/* eslint-enable */

// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import type { Trade } from '../../types/Trade.js';
import { getActiveTheme } from '../../helpers/ThemeHelper.js';
import ReactHighcharts from 'highcharts-react-official';
import Highcharts from 'highcharts';

type Props = {
  t: any,
  trades: Array<Trade>,
  theme: any
};

const getOptions = (data) => {
  return {
    title: { text: null },
    legend: { enabled: false },
    exporting: { enabled: false },
    credits: { enabled: false },
    navigator: { enabled: false },
    scrollbar: { enabled: false },
    margin: [0, 0, 0, 0],
    chart: {
      type: `spline`,
      spacingTop: 0,
      zoomType: `x`,
      backgroundColor: `rgba(0,0,0,0)`,
      padding: 10,
      margin: 0,
      marginBottom: 0,
      marginLeft: 10,
      marginRight: 10,
      className: `thin-chart-inside`
    },
    xAxis: {
      title: { text: null },
      labels: { enabled: false },
      lineWidth: 0,
      minorGridLineWidth: 0,
      minorTickLength: 0,
      tickLength: 0,
      lineColor: `transparent`
    },
    yAxis: {
      title: { text: null },
      labels: { enabled: false },
      gridLineColor: `transparent`
    },
    series: [{
      type: `line`,
      data: data
    }],
    plotOptions: {
      series: {
        animation: false,
        dataLabels: { enabled: false },
        states: {
          hover: {
            enabled: false,
            lineWidth: 2
          }
        },
        marker: {
          enabled: false
        }
      }
    },
    tooltip: {
      enabled: true,
      formatter: function () {
        return this.y;
      }
    }
  };
};

class StreamingTradesThinChart extends React.Component<Props> {
  chartComponent: *;

  constructor(props: Props) {
    super(props);
    this.chartComponent = React.createRef();
  }

  UNSAFE_componentWillReceiveProps() {
    const chartData = this.getChartData();
    if(this.chartComponent.current && chartData){
      this.chartComponent.current?.chart.series[0].setData(chartData);
      this.chartComponent.current?.chart.series[0].update({ 
        color: this.getChartColor(chartData), 
        options: this.chartComponent.current?.chart.series[0].options 
      });
    }
  }

  shouldComponentUpdate() {
    this.chartComponent?.current?.chart.reflow();
    return false;
  }

  getChartData() {
    let { trades } = this.props;
    const chartData = [];
    trades.sort((a, b) => {
      if (+new Date(a.time) > +new Date(b.time)) return -1;
      if (+new Date(b.time) > +new Date(a.time)) return 1;
      if (a.market_history_id < b.market_history_id) return 1;
      if (b.market_history_id < a.market_history_id) return -1;
      if (a.price < b.price) return 1;
      if (b.price < a.price) return -1;
      return 0;
    }).slice(0, 50).forEach( (t) => {

      chartData.push([new Date(t.time), t.price]);
    });
    chartData.sort((a, b) => {
      if (+a[0] > +b[0]) return 1;
      if (+b[0] > +a[0]) return -1;
      return 0;
    });
    return chartData;
  }

  getChartColor(chartData) {
    let { theme } = this.props;
    const activeTheme = getActiveTheme(theme, true);
    if (chartData.length > 0) {
      let a = chartData[0][1];
      let b = chartData[chartData.length - 1][1];
      
      if (a > b) {
        return activeTheme[`--red-`];
      }
      if (a < b) {
        return activeTheme[`--green-`];
      }
     
    }

    return activeTheme[`--body-text-color`];
  }

  render() {
    return (
      <div className="thin-chart">
        <span>
          &nbsp;
          &nbsp;
          { this.props.t(`priceTrend`) }
          &nbsp;
          (Last 50 Trades)
        </span>
        <ReactHighcharts
          constructorType={ `chart` }
          ref={ this.chartComponent }
          highcharts={ Highcharts }
          options={ getOptions(this.getChartData()) } />
      </div>
    );
  }
}


export default translate(`markets`)(StreamingTradesThinChart);

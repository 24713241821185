// @flow
'use strict';

import React from 'react';
import { connect } from 'react-redux';
//import { toLocalDate } from '../../helpers/DateHelper.js';
import { updateFavorites } from '../../actions/app/updateFavorites.js';
import { batchUpdateRedisPrefs } from '../../actions/redisPrefs/updateRedisPrefs.js';
import { setMarketSwitcherColorMode } from '../../actions/app/setMarketSwitcherColorMode.js';
import { setMarketSwitcherVersion } from '../../actions/app/setMarketSwitcherVersion.js';
import { updateUserData } from '../../actions/userInfo/updateUserData.js';
import { changeGlobalPageSize } from '../../actions/app/changeGlobalPageSize.js';
import CoinigyBaseComponent from '../CoinigyBaseComponent.jsx';
import type { Market } from '../../types/Market.js';
import type { Exchange } from '../../types/Exchange.js';
import type { Favorite } from '../../types/Favorite.js';
import type { User } from '../../types/User.js';
import type { UserPrefs } from '../../types/UserPrefs.js';
import type { Account } from "../../types/Account";
import {
  getSubscriptionData,
  getUserData,
  setUserData,
  setUserPreferences,
  getUserKeys,
  setUserKey,
  delUserKeys,
  getReferrals,
  getSessions,
  getActivity,
  addFavorite,
  deleteFavorite,
  getInvoices,
  killSession,
  setUsername
} from '../../helpers/api/UserApi.js';
import {
  emitEvent,
  NOTIFICATION
} from '../../helpers/EventHelper.js';

type Session = {
  loginTime: string,
  lastActivity: string,
  expiry: string,
  ipAddress: string,
  userAgent: string,
  sessionId: string,
  sessionType: string
};

type Props = {
  t: any,
  updateUserData: (o: any) => void,
  setMarketSwitcherColorMode: (b: boolean) => void,
  setMarketSwitcherVersion: (version: string) => void,
  changePageSize: (n: any) => void,
  activeTab: {
    name: string,
    text: string,
    icon: string,
    component?: any
  },
  userHash: string,
  favorites: Array<Favorite>,
  markets: Array<Market>,
  exchanges: Array<Exchange>,
  accounts: Array<Account>,
  updateFavorites: Function,
  user: User,
  userPrefs: UserPrefs,
  autoSaveEnabled: boolean,
  overrideChartTheme: boolean,
  marketSwitcherColorFilled: boolean,
  priceClick: string,
  zeroStyle: string,
  depthStyle: string,
  updateUserSettings: Function,
  pageSize: any,
  nativeCurrency: string,
  nativeCurrencyId: number,
  tradeAlertSound: string,
  fiatCurrencies: any,
  prefAccountingValuation: string,
  rehydrated: boolean,
  tickerPosition: string,
  marketSwitcherVersion: string,
};

type State = {
  user: User,
  userPrefs: UserPrefs,
  subscriptionInfo: {
    prefUserId: string,
    prefSubscriptionId: number,
    subscriptionPaid: boolean,
    prefSubscriptionExpires: string,
    prefPromoId: number,
    subscriptionPaymentType: string,
    prefLifetime: boolean,
    prefServiceTier: number,
    serviceName: string,
    serviceHash: string,
    daysLeft: number,
    subscriptionStatus: string,
    referralBalance: string
  },
  subscriptionTypes: Array<{
    serviceName: string,
    serviceDescription: string,
    serviceHash: string,
    serviceDurationMonths: number,
    serviceOrder: number,
    servicePriceBtc: number,
    servicePriceUser: number,
    serviceFullPriceBtc: number,
    serviceFullPriceUsd: number,
    serviceRecurring: boolean,
    isDefault: boolean
  }>,
  referrals: Array<{
    referralDate: string,
    userCode: string,
    referralCredited: boolean,
    referralCreditedDate: string,
    referralPaid: boolean,
    referralPaidDate: string,
    referralPayout: string
  }>,
  modifiedState: Array<{
    key: string,
    value: any
  }>,
  apiKeys: Array<{
    key: string,
    secret: string,
    level: number,
    ipAddresses: string,
    ignoreLimits: boolean,
    isPrivateKey: boolean,
    dateCreated: string,
    apiVersion: string,
    note: string,
    channelLimit: number
  }>,
  sessions: Array<Session>,
  activity: Array<{
    notificationId: number,
    type: number,
    title: string,
    message: string,
    style: string,
    time: string,
    messageVar: string,
    titleVars: string,
    pinned: boolean
  }>,
  autoSaveEnabled: boolean,
  overrideChartTheme: boolean,
  marketSwitcherColorFilled: boolean,
  priceClick: string,
  zeroStyle: string,
  depthStyle: string,
  tradeAlertSound: string,
  prefAccountingValuation: string,
  invoices: Array<{
    paymentId: number,
    paymentUserEmail: string,
    paymentCode: string,
    paymentDescription: string,
    paymentType: string,
    paymentAmountBtc: number,
    paymentAmountUsd: number,
    paymentDate: string
  }>,
  tickerPosition: string,
  marketSwitcherVersion: string,
};

export const defaultNullToEmptyString = (obj: any): any => {
  if (obj === null){
    return ``;
  } else {
    return Object.keys(obj).reduce((a, k) => {
      if (obj[k] !== null) {
        a[k] = obj[k];
      } else {
        a[k] = ``;
      }
      return a;
    }, { });
  }
};

export class ProfileDataLayer extends CoinigyBaseComponent<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  getInitialState(props: Props) {
    return {
      user: props.user,
      userPrefs: props.userPrefs,
      subscriptionInfo: {
        prefUserId: ``,
        prefSubscriptionId: 0,
        subscriptionPaid: true,
        prefSubscriptionExpires: (new Date()).toString(),
        prefPromoId: 0,
        subscriptionPaymentType: ``,
        prefLifetime: true,
        prefServiceTier: 0,
        serviceName: ``,
        serviceHash: ``,
        daysLeft: 0,
        subscriptionStatus: ``,
        referralBalance: ``
      },
      subscriptionTypes: [],
      modifiedState: [],
      apiKeys: [],
      referrals: [],
      sessions: [],
      activity: [],
      autoSaveEnabled: !!props.autoSaveEnabled,
      overrideChartTheme: !!props.overrideChartTheme,
      marketSwitcherColorFilled: !!props.marketSwitcherColorFilled,
      priceClick: props.priceClick,
      zeroStyle: props.zeroStyle,
      depthStyle: props.depthStyle,
      tradeAlertSound: props.tradeAlertSound,
      prefAccountingValuation: props.prefAccountingValuation,
      invoices: [],
      tickerPosition: props.tickerPosition,
      marketSwitcherVersion: props.marketSwitcherVersion,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    
    if (this.props.rehydrated) {
      this.setStateSafe(this.getInitialState(this.props));
      this.getData();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  
  shouldComponentUpdate(nextProps: Props) {
    if (this.props.rehydrated !== nextProps.rehydrated) {
      this.setStateSafe(this.getInitialState(nextProps));
      this.getData();
    }

    return true;
  }

  getData() {
    this.onGetSubscriptionData();
    this.onGetPreferencesUser();
    this.onGetUserKeys();
    this.onGetReferrals();
    this.onGetSessions();
    this.onGetActivity();
    this.onGetInvoices();
  }

  onGetSubscriptionData() {
    getSubscriptionData((data) => {
      let subscriptionInfo, subscriptionTypes;

      if (data.result == null) {
        subscriptionInfo = {
          prefUserId: ``,
          prefSubscriptionId: -1,
          subscriptionPaid: false,
          prefSubscriptionExpires: ``,
          prefPromoId: -1,
          subscriptionPaymentType: ``,
          prefLifetime: false,
          prefServiceTier: -1,
          serviceName: ``,
          serviceHash: ``,
          daysLeft: 0,
          subscriptionStatus: ``,
          referralBalance: ``
        };

        subscriptionTypes = [];
      } else {
        subscriptionInfo = defaultNullToEmptyString(data.result.subscriptionInfo);
        subscriptionTypes = data.result.subscriptionTypes;
      }

      this.setStateSafe({
        subscriptionInfo,
        subscriptionTypes
      });
    });
  }

  onGetPreferencesUser() {
    getUserData((data) => {
      this.setStateSafe({
        user: defaultNullToEmptyString(data.result.user),
        userPrefs: defaultNullToEmptyString(data.result.userPrefs)
      });
    });
  }

  onGetUserKeys() {
    getUserKeys((data) => {
      this.setStateSafe({ apiKeys: data.result });
    });
  }

  onGetReferrals() {
    getReferrals((data) => {
      this.setStateSafe({ referrals: data.result });
    });
  }

  onGetSessions() {
    getSessions((data) => {
      //const utcNow = Date.parse(new Date().toDateString());
      // this.setStateSafe({ sessions: data.result.filter((d) => Date.parse(toLocalDate(d.expiry)) > utcNow) });
      this.setStateSafe({ sessions: data.result });
    });
  }

  onGetActivity() {
    getActivity(null, (data) => {
      this.setStateSafe({ activity: data.result });
    });
  }

  onGetInvoices() {
    getInvoices((data) => {
      this.setStateSafe({ invoices: data.result });
    });
  }

  killSessions(sessions: Array<Session> = []) {
    sessions.forEach((s) => {
      killSession(s.sessionId, () => {
        this.setStateSafe({ sessions: this.state.sessions.filter((t) => t.sessionId !== s.sessionId ) });
      });
    });
  }

  purchasePackSms(/*packSms: string*/) {
    // TODO: do something with the "packSms"
  }

  toggleFavorite(market: Market) {
    let isFavorite = this.props.favorites.filter((f) => {
      return f.exchCode == market.exchCode && f.displayName == market.displayName;
    }).length > 0;

    if (isFavorite) {
      deleteFavorite({ exchmktId: market.exchmktId }, (data) => {
        if (data.success) this.props.updateFavorites(data.result);
      });
    } else {
      addFavorite({ body: { exchmktId: market.exchmktId } }, (data) => {
        if (data.success) this.props.updateFavorites(data.result);
      });
    }
  }

  onChange(key: string, value: any) {
    if (typeof value == `object`) {
      this.setState({
        [key]: {
          ...this.state[key],
          ...value
        },
        modifiedState: [
          ...this.state.modifiedState,
          { key, value }
        ]
      });
    } else {
      this.setState({
        [key]: value,
        modifiedState: [
          ...this.state.modifiedState,
          { key, value }
        ]
      });
    }
  }

  saveUsername(newUsername: string) {
    setUsername({ payload: newUsername }, (data) => {
      if (data.success) {
        this.props.user.username = newUsername;
        this.props.userPrefs.prefIsUsernameSet = true;

        this.props.updateUserData({
          user: this.props.user,
          userPrefs: this.props.userPrefs
        });
      }
    });
  }


  save() {
    let payload = { };

    this.state.modifiedState.forEach((o) => {
      if (!payload.hasOwnProperty(o.key)) payload[o.key] = { };
      if (typeof o.value == `object`) {
        payload[o.key] = {
          ...payload[o.key],
          ...o.value
        };
      } else {
        payload[o.key] = o.value;
      }
    });

    let showSaveNotification = false;

    Object.keys(payload).forEach((k) => {
      switch(k) {
      case `user`:
        setUserData({ payload: payload[k] }, (data) => {
          if (data.success) {
            this.props.updateUserData({
              user: data.result
            });
          }
        });
        break;
      case `userPrefs`:
        // PUT
        setUserPreferences({ payload: {
          pref_alert_email: this.props.userPrefs.prefAlertEmail,
          pref_alert_sms: this.props.userPrefs.prefAlertSms,
          pref_alert_site: this.props.userPrefs.prefAlertSite,
          pref_trade_email: this.props.userPrefs.prefTradeEmail,
          pref_trade_sms: this.props.userPrefs.prefTradeSms,
          pref_trade_site: this.props.userPrefs.prefTradeSite,
          pref_balance_email: this.props.userPrefs.prefBalanceEmail,
          pref_alert_news: this.props.userPrefs.prefAlertNews,
          pref_block_site: this.props.userPrefs.prefBlockSite,
          pref_alert_new_market: this.props.userPrefs.prefAlertNewMarket,
          ...Object.keys(payload[k]).reduce((a, t) => {
            let o = ``;

            if (t == `prefAlertEmail`) o = `pref_alert_email`;
            if (t == `prefAlertSms`) o = `pref_alert_sms`;
            if (t == `prefAlertSite`) o = `pref_alert_site`;
            if (t == `prefTradeEmail`) o = `pref_trade_email`;
            if (t == `prefTradeSms`) o = `pref_trade_sms`;
            if (t == `prefTradeSite`) o = `pref_trade_site`;
            if (t == `prefBalanceEmail`) o = `pref_balance_email`;
            if (t == `prefAlertNews`) o = `pref_alert_news`;
            if (t == `prefBlockSite`) o = `pref_block_site`;
            if (t == `prefAlertNewMarket`) o = `pref_alert_new_market`;

            a[o] = payload[k][t];

            return a;
          }, { })
        } }, () => {
          this.props.updateUserData({
            userPrefs: payload[k]
          });
        });
        break;
      case `autoSaveEnabled`:
        this.props.updateUserSettings({
          autoSaveDisabled: !payload[k]
        });
        break;
      case `marketSwitcherColorFilled`:
        this.props.setMarketSwitcherColorMode(payload[k]);
        showSaveNotification = true;
        break;

      case `marketSwitcherVersion`:
        this.props.setMarketSwitcherVersion(payload[k]);
        showSaveNotification = true;
        break;
  
      case `priceClick`:
      case `zeroStyle`:
      case `depthStyle`:
      case `tradeAlertSound`:
      case `prefAccountingValuation`:
      case `tickerPosition`:
      case `overrideChartTheme`:
        // eslint-disable-next-line
        let key: string = k;
        this.props.updateUserSettings({
          [key]: payload[k]
        });
        showSaveNotification = true;
        break;
      }
    });

    if (showSaveNotification) {
      emitEvent(NOTIFICATION, {
        notification_id: new Date().getTime(),
        title: ``,
        title_vars: ``,
        message_raw: ``,
        message: `Your preferences have been saved.`,
        message_vars: ``,
        pinned: false,
        style: `success`,
        url: ``
      });

    }
    return true;
  }

  revokeKeyApi(key: string) {
    delUserKeys({ key }, () => {
      this.getData();
    });
    return false;
  }

  generateKey() {
    setUserKey((data) => {
      if (data.success) {
        this.setState(({ apiKeys }) => ({ apiKeys: [data.result, ...apiKeys] }));
      }
    });
  }

  cancelSubscription() {
    alert(`need call to cancel subscription`); // TODO: CANCEL SUB
  }

  planCheckout(billing: {
    name: string,
    street: string,
    city: string,
    state: string,
    zip: string,
    country: string,
    cc_num: string,
    cc_exp: string,
    cc_cvv: string
  }) {
    alert(`do something with billing info: ${ billing.name }`);
  }

  render() {
    const { component } = this.props.activeTab;

    return component ? React.cloneElement(component, {
      t: this.props.t,
      user: this.state.user,
      userPrefs: this.state.userPrefs,
      autoSaveEnabled: this.state.autoSaveEnabled,
      overrideChartTheme: this.state.overrideChartTheme,
      marketSwitcherColorFilled: this.state.marketSwitcherColorFilled,
      priceClick: this.state.priceClick,
      zeroStyle: this.state.zeroStyle,
      depthStyle: this.state.depthStyle,
      subscriptionInfo: this.state.subscriptionInfo,
      subscriptionTypes: this.state.subscriptionTypes,
      privateChannelId: this.props.userHash,
      referrals: this.state.referrals,
      markets: this.props.markets,
      accounts: this.props.accounts,
      exchanges: this.props.exchanges,
      favorites: this.props.favorites,
      onChange: this.onChange.bind(this),
      save: this.save.bind(this),
      saveUsername: this.saveUsername.bind(this),
      apiKeys: this.state.apiKeys,
      revokeKeyApi: this.revokeKeyApi.bind(this),
      generateKey: this.generateKey.bind(this),
      sessions: this.state.sessions,
      activity: this.state.activity,
      invoices: this.state.invoices,
      toggleFavorite: this.toggleFavorite.bind(this),
      cancelSubscription: this.cancelSubscription.bind(this),
      planCheckout: this.planCheckout.bind(this),
      killSessions: this.killSessions.bind(this),
      currentPack: `0.00 ≈ 0`,
      purchasePackSms: this.purchasePackSms.bind(this),
      changePageSize: this.props.changePageSize.bind(this),
      pageSize: this.props.pageSize,       
      nativeCurrency: this.props.nativeCurrency,
      nativeCurrencyId: this.props.nativeCurrencyId,
      tradeAlertSound: this.state.tradeAlertSound,
      fiatCurrencies: this.props.fiatCurrencies,
      prefAccountingValuation: this.state.prefAccountingValuation,
      tickerPosition: this.state.tickerPosition,
      marketSwitcherVersion: this.state.marketSwitcherVersion,
    }) : null;
  }
}

const mapStateToProps = (state) => ({
  userHash: state.userInfo.user_hash,
  favorites: state.app.favorites,
  user: state.userInfo.user,
  userPrefs: state.userInfo.userPrefs,
  autoSaveEnabled: !state.redisPrefs.autoSaveDisabled,
  priceClick: state.redisPrefs.priceClick,
  zeroStyle: state.redisPrefs.zeroStyle,
  depthStyle: state.redisPrefs.depthStyle,
  overrideChartTheme: state.redisPrefs.overrideChartTheme,
  marketSwitcherColorFilled: state.app.marketSwitcherColorFilled,
  pageSize: state.app.pageSize,
  nativeCurrency: state.redisPrefs.prefNativeCurrency,
  nativeCurrencyId: state.redisPrefs.prefNativeCurrencyId,
  tradeAlertSound: state.redisPrefs.tradeAlertSound,
  fiatCurrencies: state.redisPrefs.fiatCurrencies,
  prefAccountingValuation: state.redisPrefs.prefAccountingValuation,
  rehydrated: state._persist.rehydrated,
  tickerPosition: state.redisPrefs.tickerPosition,
  marketSwitcherVersion: state.app.marketSwitcherVersion,
});

const mapDispatchToProps = (dispatch) => ({
  updateFavorites: (favorites) => dispatch(updateFavorites(favorites)),
  updateUserData: (data) => dispatch(updateUserData(data)),
  updateUserSettings: (data) => dispatch(batchUpdateRedisPrefs(data)),
  setMarketSwitcherColorMode: (b) => dispatch(setMarketSwitcherColorMode(b)),
  changePageSize: (p) => dispatch(changeGlobalPageSize(p)),
  setMarketSwitcherVersion: (b) => dispatch(setMarketSwitcherVersion(b)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDataLayer);
export type ProfileProps = {
  t: any,
  user?: User,
  userPrefs?: UserPrefs,
  subscriptionInfo?: {
    prefUserId: string,
    prefSubscriptionId: number,
    subscriptionPaid: boolean,
    prefSubscriptionExpires: string,
    prefPromoId: number,
    subscriptionPaymentType: string,
    prefLifetime: boolean,
    prefServiceTier: number,
    serviceName: string,
    serviceHash: string,
    daysLeft: number,
    subscriptionStatus: string,
    referralBalance: string
  },
  subscriptionTypes?: Array<{
    serviceName: string,
    serviceDescription: string,
    serviceHash: string,
    serviceDurationMonths: number,
    serviceOrder: number,
    servicePriceBtc: number,
    servicePriceUser: number,
    serviceFullPriceBtc: number,
    serviceFullPriceUsd: number,
    serviceRecurring: boolean,
    isDefault: boolean
  }>,
  modifiedState?: Array<{
    key: string,
    value: any
  }>,
  apiKeys?: Array<{
    key: string,
    secret: string,
    level: number,
    ipAddresses: string,
    ignoreLimits: boolean,
    isPrivateKey: boolean,
    dateCreated: string,
    apiVersion: string,
    note: string,
    channelLimit: number
  }>,
  privateChannelId?: string,
  referrals?: Array<{
    referralDate: string,
    userCode: string,
    referralCredited: boolean,
    referralCreditedDate: string,
    referralPaid: boolean,
    referralPaidDate: string,
    referralPayout: string
  }>,
  sessions?: Array<{
    loginTime: string,
    lastActivity: string,
    expiry: string,
    ipAddress: string,
    userAgent: string,
    sessionId: string,
    sessionType: string
  }>,
  activity?: Array<{
    notificationId: number,
    type: number,
    title: string,
    message: string,
    style: string,
    time: string,
    messageVar: string,
    titleVars: string,
    pinned: boolean
  }>,
  invoices?: Array<{
    paymentId: number,
    paymentUserEmail: string,
    paymentCode: string,
    paymentDescription: string,
    paymentType: string,
    paymentAmountBtc: number,
    paymentAmountUsd: number,
    paymentDate: string
  }>,
  markets?: Array<Market>,
  exchanges?: Array<Exchange>,
  favorites?: Array<Favorite>,
  onChange?: (key: string, value: any) => void,
  save?: () => boolean,
  saveUsername?: (username: string) => boolean,
  revokeKeyApi?: (apiKeys: any) => boolean,
  generateKey?: () => boolean,
  toggleFavorite?: (market: Market) => void,
  cancelSubscription?: () => void,
  planCheckout?: (billing: {
    name: string,
    street: string,
    city: string,
    state: string,
    zip: string,
    country: string,
    cc_num: string,
    cc_exp: string,
    cc_cvv: string
  }) => void,
  autoSaveEnabled?: boolean,
  overrideChartTheme?: boolean,
  marketSwitcherColorFilled?: boolean,
  priceClick?: string,
  zeroStyle?: string,
  depthStyle?: string,
  killSessions?: (sessions: Array<Session>) => void,
  currentPack?: string,
  purchasePackSms?: (packSms: string) => void,
  pageSize?: any,
  changePageSize?: (n: any) => void,
  history: { push: (any) => void },
  fiatCurrencies?: any,
  nativeCurrency?: string,
  nativeCurrencyId?: number,
  tradeAlertSound?: string,
  prefAccountingValuation?: string,
  tickerPosition?: string,
  marketSwitcherVersion?: string,
};

// @flow
'use strict';

import { SET_BALANCE_FILTERS } from '../index.js';
import type { Balance } from '../../types/Balance.js';

export function setBalanceFilters(filters: Array<Balance>) {
  return {
    type: SET_BALANCE_FILTERS,
    payload: filters
  };
}

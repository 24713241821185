// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import Modal from '../../utilities/Modal.jsx';


const TwoFactorAuthenticationInstructionsModal = (props) => {
  const { t, onClose, platformId } = props;

  const platform = platformId === 1 ? `Lightspeed Crypto`: `Coinigy`;
  const platformEmail = platformId === 1 ? `support@lightspeedcrypto.com` : `support@coinigy.com`;

  return (
    <div className={ `two-factor-authentication-instructions-modal` }>
      <Modal
        title={ t(`twoFactorAdditionalInstructionsTitle`) }
        onClose={ onClose }>
        <div>
          <p>
            { t(`twoFactorAdditionalInstructionsExplanation1`, { platform }) }
          </p>

          <p>
            { t(`twoFactorAdditionalInstructionsExplanation2`) }
            <a 
              href={ `https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2` }
              rel="nofollow noopener noreferrer"
              target="_blank">
              { `Google Authenticator` }
            </a>
            { t(`twoFactorAdditionalInstructionsExplanation3`) }
            <a
              href={ `https://www.authy.com` }
              rel="nofollow noopener noreferrer"
              target="_blank">
              { `Authy` }
            </a>
            { t(`twoFactorAdditionalInstructionsExplanation4`) }
          </p>

          <p>
            { t(`twoFactorAdditionalInstructionsExplanation5`, { platform }) }
          </p>

          <p>
            <a
              aria-label={ `Google Authenticator Documentation` }
              href={ `https://support.google.com/accounts/answer/1066447` }
              rel="nofollow noopener noreferrer"
              target="_blank">
        Google Authenticator Documentation
            </a>
          </p>

          <p>
            { t(`twoFactorAdditionalInstructionsSupport`, { email: platformEmail }) }
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default translate(`user`)(TwoFactorAuthenticationInstructionsModal);

// @flow
'use strict';

import MarketDataPanel from '../components/boards/MarketDataPanel.jsx';
import MarketDepthChartPanel from '../components/boards/MarketDepthChartPanel.jsx';
import MarketChartPanel from '../components/boards/MarketChartPanel.jsx';
import StreamingTradesPanel from '../components/boards/StreamingTradesPanel.jsx';
import MarketOrderbookPanel from '../components/boards/MarketOrderbookPanel.jsx';
import NewsFeedPanel from '../components/boards/NewsFeedPanel.jsx';
import PriceTrendPanel from '../components/boards/PriceTrendPanel.jsx';
import NotesPanel from '../components/boards/NotesPanel.jsx';
import InsightsPanel from '../components/boards/InsightsPanel.jsx';
import OrdersPanel from '../components/boards/OrdersPanel.jsx';
import BalancesPanel from '../components/boards/BalancesPanel.jsx';
import EmptyPanel from '../components/boards/EmptyPanel.jsx';
import OrderFormPanel from '../components/boards/OrderFormPanel.jsx';
import TradingViewChartPanel from '../components/boards/TradingViewChartPanel.jsx';
import MarketSplitTradesPanel from '../components/boards/MarketSplitTradesPanel.jsx';

export const MARKET_CHART = 1;
export const MARKET_DATA = 2;
export const MARKET_DEPTH = 3;
export const MARKET_RECENT_TRADES = 4;
export const MARKET_ORDERBOOK = 5;
export const NEWS_FEED = 6;
export const PRICE_TREND = 8;
export const INSIGHTS = 9;
export const NOTES = 10;
export const ORDER_FORM = 11;
export const ORDERS = 12;
export const BALANCES = 13;
export const TRADING_VIEW_CHART = 14;
export const MARKET_SPLIT_TRADES = 16;

export const NEED_CURRENCIES = [ORDER_FORM];
export const NEED_BALANCES = [ORDER_FORM, BALANCES];
export const NEED_ACCOUNTS = [ORDER_FORM, BALANCES, ORDERS, TRADING_VIEW_CHART];
export const NEED_MARKETS = [ORDER_FORM, BALANCES, ORDERS];
export const NEED_EXCHANGES = [MARKET_RECENT_TRADES, ORDER_FORM, BALANCES, ORDERS, MARKET_SPLIT_TRADES];

export const supportedPanels = [
  MARKET_CHART,
  MARKET_DATA,
  MARKET_DEPTH,
  MARKET_RECENT_TRADES,
  MARKET_ORDERBOOK,
  NEWS_FEED,
  PRICE_TREND,
  INSIGHTS,
  NOTES,
  ORDER_FORM,
  ORDERS,
  BALANCES,
  TRADING_VIEW_CHART,
  MARKET_SPLIT_TRADES
];

export const getComponentFromPanelTypeId = (panelTypeId: number) => {
  switch (panelTypeId) {
  case MARKET_CHART:
    return MarketChartPanel;
  case MARKET_DATA:
    return MarketDataPanel;
  case MARKET_DEPTH:
    return MarketDepthChartPanel;
  case MARKET_RECENT_TRADES:
    return StreamingTradesPanel;
  case MARKET_ORDERBOOK:
    return MarketOrderbookPanel;
  case NEWS_FEED:
    return NewsFeedPanel;
  case PRICE_TREND:
    return PriceTrendPanel;
  case INSIGHTS: 
    return InsightsPanel;
  case NOTES:
    return NotesPanel;
  case ORDER_FORM:
    return OrderFormPanel;
  case ORDERS:
    return OrdersPanel;
  case BALANCES:
    return BalancesPanel;
  case TRADING_VIEW_CHART:
    return TradingViewChartPanel;
  case MARKET_SPLIT_TRADES:
    return MarketSplitTradesPanel;
  default:
    return EmptyPanel;
  }
};

// @flow
'use strict';

import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '../../utilities/TextField.jsx';
import PillToggle from "../../utilities/PillToggle.jsx";
import ProgressSpinner from '../../utilities/ProgressSpinner.jsx';
import SelectField from '../../utilities/SelectField.jsx';
import { MarketSelectorList } from "./MarketSelectorList.jsx";
import Search from "../../../svgs/Search.jsx";
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { setMarketSelectorFilters } from '../../../actions/app/setMarketSelectorFilters.js';


type Props = {
  markets: Array<any>,
  isLoading: boolean,
  close: () => void,
  updateMarketName: (displayName: string) => void
};

const PILL_OPTIONS = [
  { 
    value: 1,
    label: `Favorites`,
  },
  {
    value: 0,
    label: `All`,
  },
];

const SORT_OPTIONS = [
  {
    value: 0,
    label: `▲ Alphabetical A-Z`,
  },
  {
    value: 1,
    label: `▼ Alphabetical Z-A`,
  }
];

const MarketSelector = (props: Props) => {
  const { markets, isLoading, close, updateMarketName } = props;
  
  const favorites = useSelector((state) => state.app.favorites);
  const filters = useSelector((state) => state.app.marketSelectorFilters);
  const dispatch = useDispatch();
  const dispatchSetMarketSelectorFilters = (o: any) => dispatch(setMarketSelectorFilters(o));
  
  const [marketsList, setMarketsList] = useState([]);
  const [inputSearchValue, setInputSearchValue] = useState(``);
  const [filterOption, setFilterOption] = useState(filters?.view || 0);
  const [sort, setSort] = useState(filters?.sort || 0);

  const uniqueFavorites = [...new Set(favorites?.map((item) => item.displayName))]
    .map((m) => ({
      'label': m,
      'value': m,
    })
    );
  
  const refListContainer = useRef();

  useEffect(() => {
    const list = filterOption === 0 ? markets : uniqueFavorites;
    const sortedList = list.sort((a, b) => {
      if (a.label > b.label) return sort === 0 ? 1 : -1;
      if (b.label > a.label) return sort === 0 ? -1 : 1;
      return 0;
    });
    setMarketsList(!inputSearchValue ? sortedList : sortedList.filter((l) => l?.label?.toLowerCase?.()?.indexOf(inputSearchValue?.toLowerCase?.()) > -1 ));

    dispatchSetMarketSelectorFilters({ view: filterOption, sort });

  }, [inputSearchValue, filterOption, isLoading, uniqueFavorites.length, sort]);

  return (
    <div className={ `market-selector-container fade-in` }>
      <div
        className="market-selector-backdrop" onClick={ (e) => {
          close?.();
          e.stopPropagation();
        } } />
      <div className={ `market-selector fade-in` }>
        <div className={ `market-selector-header` }>
          <div className={ `filter-container` }>
            <TextField
              icon={ Search }
              label={ `Find markets` }
              name="findMarkets"
              value={ inputSearchValue }
              compact={ true }
              onDoubleClick={ () => setInputSearchValue(``) }
              onChange={ (e) => setInputSearchValue(e.target.value) }
              onEnter={ () => setInputSearchValue(``) }/>

            <div className={ `sort` }>
              <SelectField
                searchable={ false }
                label={ `` }
                name={ `sort-markets` }
                value={ sort }
                options={ SORT_OPTIONS }
                hideValue
                hideCaret
                icon={ <ImportExportIcon sx={ { fontSize: `3rem`, cursor: `pointer` } }/> }
                onChange={ (e, v) => setSort(v) }/>
            </div>
          </div>

          <div>
            <PillToggle
              options={ PILL_OPTIONS }
              value={ filterOption }
              onChange={ (e, v) => setFilterOption(v) }/>
          </div>
        </div>

        <div ref={ refListContainer } className={ `market-selector-list-container` }>
          { !isLoading && <MarketSelectorList 
            markets={ marketsList } 
            refListContainer={ refListContainer } 
            favorites={ favorites }
            close={ close }
            updateMarketName={ updateMarketName }/>
          }
          { !isLoading && marketsList.length === 0 && 
          <div className='no-data'>
            { `There is no data to show for this view.` }
          </div>
          }
          { isLoading && <ProgressSpinner className={ `spinner` }/> }
        </div>
      </div>
    </div>
  );
};

export default MarketSelector;

// @flow
'use strict';

import React from 'react';
import { useVirtual } from 'react-virtual';
import FavoritesStar from '../../../svgs/FavoritesStar.jsx';
import FavoritesStarFilled from '../../../svgs/FavoritesStarFilled.jsx';

type Props = {
  markets: Array<any>,
  refListContainer: any,
  favorites: Array<any>,
  close: () => void,
  updateMarketName: (displayName: string) => void
};

export const MarketSelectorList = (props: Props) => {
  const { markets, refListContainer, favorites, close, updateMarketName } = props;
  
  const rowVirtualizer = useVirtual({
    parentRef: refListContainer,
    size: markets.length,
    overscan: 5,
  });
  
  const { virtualItems: virtualMarkets, totalSize } = rowVirtualizer;
  const paddingTop = virtualMarkets.length > 0 ? virtualMarkets?.[0]?.start || 0 : 0;
  const paddingBottom = virtualMarkets.length > 0 ? totalSize - (virtualMarkets?.[virtualMarkets.length - 1]?.end || 0) : 0;

  return (
    <div className={ `market-selector-list` }>
      <div>
        { paddingTop > 0 && (
          <div style={ { height: `${paddingTop}px` } } />
        ) }
        <ul>
          {
            virtualMarkets.map((m) => {
              const isFavorite = favorites.some((f) => f.displayName === markets[m.index]?.label);
              const [base, curr] = markets[m.index]?.label?.split(`/`);

              return (
                <li key={ markets[m.index]?.value }>
                  <div
                    className={ `market-selector-item` }
                    onClick={ () => {
                      updateMarketName(markets[m.index]?.label);
                      close();
                    } }>
                    <div className={ `flex` } >
                      <div className={ `currencies-section` }>
                        <div className={ `quote-curr-logo` }>
                          <img
                            className={ `curr-logo` }
                            // $FlowIgnore: suppressing this error
                            src={ `${ window.WWW_URL }/assets/img/currency/${ curr }.png` } 
                            height={ `20rem` } 
                            onError={ (e)=>{e.target.onerror = null; e.target.src=`https://www.coinigy.com/assets/img/currency/empty.png`;} } />
                        </div>
                        <div className={ `base-curr-logo` }>
                          <img
                            className={ `curr-logo` }
                            // $FlowIgnore: suppressing this error
                            src={ `${ window.WWW_URL }/assets/img/currency/${ base }.png` } 
                            height={ `20rem` } 
                            onError={ (e)=>{e.target.onerror = null; e.target.src=`https://www.coinigy.com/assets/img/currency/empty.png`;} } />
                        </div>
                      </div>

                      <div className={ `market-name-section` }>
                        { markets[m.index]?.label }
                      </div>
                    </div>
                  
                    <div className={ `fav-star-container` }>    
                      <div className={ `fav-star ${isFavorite ? `is-favorite` : ``}` }>
                        { isFavorite ? FavoritesStarFilled(`MarketsSearchStarFilled`) : FavoritesStar(`MarketsSearchStar`) }
                      </div>
                    </div>
                  </div>
                </li>
              );})
          }
        </ul>
        { paddingBottom > 0 && (
          <div style={ { height: `${paddingBottom}px` } } />
        ) }
      </div>
    </div>
  );
};

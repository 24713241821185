// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import CoinigyBaseComponent from '../CoinigyBaseComponent.jsx';
import CKEditor from '@ckeditor/ckeditor5-react';
import SelectField from '../utilities/SelectField.jsx';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
  getUserExistingNotes
} from "../../helpers/api/UserApi.js";

type Props = {
  t: any,
  marketLabel: string,
  notes: string,
  updateNotes: (note: string) => void,
  disattached?: boolean,
  switchNotes?: (e: any) => void,
};

type State = {
  savedNotes: [],
};

class MarketNotes extends CoinigyBaseComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      savedNotes: []
    };
  }

  componentDidMount() {
    if (this.props.disattached) {
      getUserExistingNotes((res) => {
        if (res.success) {
          this.setState({
            savedNotes: [...res.result.notes.map((n) => ({ label: n, value: n })) ]
          });
        }
      });
    }
  }


  render() {
    return (
      <div className="market-notes">
        <div className="top">
          <span className="title">
            { this.props.t(`app:notes`) }
          </span>
          {
            this.props.disattached ? 
              this.state.savedNotes.length > 1 ? 
                <SelectField 
                  searchable={ this.state.savedNotes.length > 9 ? true : false }
                  label={ `Created notes...` }
                  value={ `` }
                  onChange={ (e, v) => this.props.switchNotes && this.props.switchNotes(v) }
                  options={ this.state.savedNotes }/>
                : 
                null
              :
              <span className="subtitle">
                { this.props.marketLabel.split(` `)[1] }
              </span>
          }
        </div>
        <CKEditor
          editor={ ClassicEditor }
          data={ this.props.notes || `` }
          // onInit={ (editor) => {
          //   // You can store the "editor" and use when it is needed.
          //   // console.log( `Editor is ready to use!`, editor );
          // } }
          onChange={ ( event, editor ) => {
            const data = editor.getData();
            this.props.updateNotes(data);
            // console.log( { event, editor, data } );
          } }/>        
      </div>
    );
  }
}

export { MarketNotes as PureMarketNotes };
export default translate(`markets`)(MarketNotes);

// @flow
'use strict';

import { SET_KEY_SHORTCUT } from '../index.js';

export function setKeyboardShortcut(shortcut: string, key: string) {
  return {
    type: SET_KEY_SHORTCUT,
    payload: {
      shortcut,
      key
    }
  };
}

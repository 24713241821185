// @flow
'use strict';

import React from 'react';
import { useTransition, animated } from 'react-spring';
import Notification from './Notification.jsx';
import { Portal } from './Portal.jsx';

const NotificationTray = ({ notifications, removeNotification }: any) => {
  const animateNotifications = useTransition(notifications, {
    exitBeforeEnter: false,
    from: { transform: `translateX(100vh)` },
    enter: (item) => ([{ transform: `translateX(0vh)` },{ height: `${item.height + 1}rem` }]),
    leave: [{ transform: `translateX(100vh)` },{ height: `0rem` }],
    config: { duration: 500 }
  });

  return (
    <Portal className={ `notifications-portal` }> 
      {  
        animateNotifications((animationStyle, notificationProps, index) => ( 
          <animated.div style={ animationStyle } className={ `notification-animation-cointainer` } key={ index }>
            <Notification
              onClose={  removeNotification } 
              { ...notificationProps }/>
          </animated.div>
        ))
      }
    </Portal>
  );
};

export default NotificationTray;

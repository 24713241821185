// @flow
'use strict';

import { UPDATE_ORDER_FORM_ACTIVE_TYPE } from '../index.js';

export function updateOrderFormActiveType(payload: number) {
  return {
    type: UPDATE_ORDER_FORM_ACTIVE_TYPE,
    payload
  };
}

// @flow
'use strict';

import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getMarketFromPanelSettings } from '../../helpers/MarketPairHelper.js';
import StreamingTradesThinChart from "../markets/StreamingTradesThinChart.jsx";

const PriceTrendPanel = ({ panelData, settings, t, theme }: any) => {

  const mkt = `${ settings.exchange }:${ settings.market }`;
  if (
    panelData.hasOwnProperty(mkt) && 
    panelData[mkt].trades &&
    panelData[mkt].trades.length > 0
  ) {
    return (
      <StreamingTradesThinChart 
        trades={ panelData[mkt].trades }
        theme={ theme }/> 
    );
  } else {
    return (
      <div className="panel-loading">
        <span>
          { t(`app:loading`) }
        </span>
      </div>
    );
  }
};

// $FlowIgnore: suppressing this error
PriceTrendPanel.getPanelTitle = (settings: Settings, markets: Array<Market>) => {
  const market = getMarketFromPanelSettings(markets, settings);
  return market ? market.exchName + ` - ` + market.displayName : `Price Trend`;
};

const mapStateToProps = (state) => ({
  theme: state.redisPrefs.theme
});

export default translate(`boards`)(connect(mapStateToProps)(PriceTrendPanel));

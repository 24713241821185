// @flow

export function ucfirst(value: string) {
  return `${value.substr(0, 1).toUpperCase()}${value.substr(1, value.length)}`;
}
export function trimString(value: string, trimLength: number, includeEllipses: boolean) {

  let returnString = ``;
  if (trimLength > 0) returnString = value.substr(0, trimLength);
  if (includeEllipses && value.length > (trimLength - 1)) returnString += `...`;

  return returnString;
}

'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default (uniqueID = `CollapseDown`) => SvgHelper(
  `<path d="M7.46719 11.0094L3.21719 6.75937C2.92344 6.46562 2.92344 5.99062 3.21719 5.7L3.92344 4.99375C4.21719 4.7 4.69219 4.7 4.98281 4.99375L7.99531 8.00625L11.0078 4.99375C11.3016 4.7 11.7766 4.7 12.0672 4.99375L12.7797 5.69687C13.0734 5.99062 13.0734 6.46562 12.7797 6.75625L8.52969 11.0062C8.23594 11.3031 7.76094 11.3031 7.46719 11.0094Z" fill="currentColor"/>`,
  {
    width: `16px`,
    height: `16px`,
    viewBox: `0 0 16 16`
  }
);
/* eslint-enable */

// @flow
'use strict';

import { RESET_TO_THEME } from '../index.js';

export function resetToTheme(theme: string) {
  return {
    type: RESET_TO_THEME,
    payload: theme
  };
}

// @flow
'use strict';

import { UPDATE_ALERT_ACTIVE_TYPE } from '../index.js';

export function updateAlertActiveType(payload: number) {
  return {
    type: UPDATE_ALERT_ACTIVE_TYPE,
    payload
  };
}

// @flow
'use strict';

import React from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { translate } from 'react-i18next';

import Button from '../utilities/Button.jsx';

import { updateUserData } from '../../actions/userInfo/updateUserData';

import { setUserLightSpeedAgreement } from '../../helpers/api/UserApi.js';

import {
  emitEvent,
  NOTIFICATION
} from '../../helpers/EventHelper.js';

type Props = {
  t: any,
  setStep: (step: number) => void,
  onClose: () => void,
};

const PrimeTrustTermsIframe = (props: Props) => {
  const { t, setStep, onClose } = props;

  const user = useSelector((state) => state.userInfo.user);
  const userPrefs = useSelector((state) => state.userInfo.userPrefs);
  
  const dispatch = useDispatch();

  // TODO: use the actual pdf file
  const pdfSrc = `/assets/pdf/LightspeedCryptoAgreement.pdf`;

  // Decline function removed by william
  // const handleDecline = () => {
  //   emitEvent(NOTIFICATION, {
  //     notification_id: new Date().getTime(),
  //     title: `You have declined the Lightspeed Crypto Agreement`,
  //     title_vars: ``,
  //     messageRaw: `You must accept the Lightspeed Crypto Agreement to continue using the trading platform.`,
  //     message: `You must accept the Lightspeed Crypto Agreement to continue using the trading platform.`,
  //     message_vars: ``,
  //     pinned: true,
  //     style: `error`,
  //     url: ``
  //   });

  //   onClose();
  // };

  const acceptTerms = () => {
    const userData = {
      user,
      userPrefs: {
        ...userPrefs,
        lscxAgreementSigned: true,
      }
    };

    setUserLightSpeedAgreement({ 
      lscxAgreementSigned: true
    }, (data) => {
      if (data.success) {
        dispatch(updateUserData(userData));
        if (setStep) { setStep(2); }
        onClose();
      } else {
        emitEvent(NOTIFICATION, {
          notification_id: new Date().getTime(),
          title: `Lightspeed Crypto Agreement`,
          title_vars: ``,
          messageRaw: `There was an error accepting the Lightspeed Crypto Agreement, please try again later.`,
          message: `There was an error accepting the Lightspeed Crypto Agreement, please try again later.`,
          message_vars: ``,
          pinned: true,
          style: `error`,
          url: ``
        });
        onClose();
      }
      
    });
  };

  return (
    <div className={ `prime-trust-pdf-accept` }>
      <iframe src={ `${pdfSrc}#view=fitH` } title="primeTrustAgreement" height="80%" width="100%" />
      <div className={ `prime-trust-pdf-actions` }>
        <Button 
          className={ `accept-terms-button` } 
          type={ `confirm` }
          onClick={ () => { 
            acceptTerms();
            return true; } }>
          { t(`iAccept`) }
        </Button>
        { /* <Button 
          className={ `decline-terms-button` } 
          type={ `cancel` }
          onClick={ () => { handleDecline(); return true; } }>
          { t(`iDecline`) }
        </Button> */ }
      </div>
    </div>
  );
};

export default translate(`primeTrustModal`)(connect()(PrimeTrustTermsIframe));

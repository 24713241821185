// @flow

import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Desktop from '../../../svgs/Desktop.jsx';
import Mobile from '../../../svgs/Mobile.jsx';
import TimesCheck from '../../../svgs/TimesCheck.jsx';
import Verified from '../../../svgs/Verified.jsx';
import type { ProfileProps } from "../ProfileDataLayer.jsx";
import { post2fa, setEnable2fa } from '../../../helpers/api/UserApi.js';
import { emitEvent, NOTIFICATION } from '../../../helpers/EventHelper.js';
import Button from '../../utilities/Button.jsx';
import TwoFactorAuthenticationModal from './TwoFactorAuthenticationModal.jsx';
import TwoFactorAuthenticationInstructionsModal from './TwoFactorAuthenticationInstructionsModal.jsx';

const TwoFactorAuthentication = (props: ProfileProps) => {
  const { t, onChange, user } = props;
  const [secretKey, setSecretKey] = useState(``);
  const [showModal, setShowModal] = useState(false);
  const [showInstructionModal, setShowInstructionModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  const fA2Enabled = user && user.fA2Enabled;
  const platform = user?.platformId === 1 ? `Lightspeed Crypto`: `Coinigy`;
  
  const availableApps = [
    {
      name: `Google Authenticator`,
      availableDevices: [
        Mobile
      ],
      downloads: [
        {
          description: t(`header:googlePlayLinkLabel`),
          image: `/assets/img/figma/Play_Store_Badge_EN.svg`,
          link: `https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2`,
        },
        {
          description: t(`header:appStoreLinkLabel`),
          image: `/assets/img/figma/App_Store_Badge_US-UK.svg`,
          link: `https://apps.apple.com/app/google-authenticator/id388497605`,
        },
      ]
    },
    {
      name: `Authy`,
      availableDevices: [
        Mobile, Desktop
      ],
      downloads: [
        {
          description: t(`twoFactorAuthAuthyDescription`),
          link: `https://www.authy.com/`,
          label: t(`twoFactorAuthAuthyLabel`)
        }
      ]
    },     
  ];

  const showError = (error: string) => {
    emitEvent(NOTIFICATION, {
      notification_id: new Date().getTime(),
      title: t(`twoFactorAuth`),
      title_vars: ``,
      message_raw: ``,
      message: error,
      message_vars: ``,
      pinned: false,
      style: `error`,
      url: ``
    });
  };

  const handleOpenVerifyModal = (open: boolean) => {
    if (open && user?.fA2Enabled === false) {
      setIsLoading(true);
      
      post2fa((data) => {
        if (data.success) {
          setSecretKey(data.result.manualEntryKey);
          setShowModal(true);
        } else {
          showError(data.error);
        }
        setIsLoading(false);
      });
    } else {
      setSecretKey(``);
      setShowModal(open);
    }

    return false; 
  };

  const handleConfirmClick = (authCode: string) => {
    setIsLoading(true);
    
    const options = {
      payload: {
        secret: secretKey,
        authCode: authCode,
        enable: !fA2Enabled
      }
    }; 

    setEnable2fa(options, (data) => {
      if (data.success && data.result) {
        if (onChange) onChange(`user`, { [`fA2Enabled`]: !fA2Enabled });
      }

      setSecretKey(``);
      setShowModal(false);
      setIsLoading(false);
    }); 

    return false;
  };

  return (
    <div className="two-factor-authentication">
      <h1 className="page-title">
        { t(`twoFactorAuth`) }
      </h1>

      <section>
        <div className="notification">
          <h2>
            <span className="subtitle">
              { fA2Enabled ? <Verified /> : <TimesCheck /> } 
              { t(`twoFactorAuthAbbreviatedStatus`, { enabled: fA2Enabled ? t(`enabled`): t(`disabled`) }) }
            </span>              
          </h2>

          <div>
            <h3>
              <div>
                { t(`twoFactorAuthExplanation1`, { platform })  } 
              </div>
              <div>
                { t(`twoFactorAuthExplanation2`) }
              </div>
            </h3>       

            <span className="button-container">
              <Button type={ !fA2Enabled ? `primary`: `cancel` }  onClick={ () => handleOpenVerifyModal(true) } disabled={ isLoading }>
                { !fA2Enabled ? t(`enable`) : t(`disable`) }
              </Button>

              { fA2Enabled ? 
                <p>
                  { t(`twoFactorDisclaimer`) }
                </p>
                :
                <Button type='secondary'  onClick={ () => { setShowInstructionModal(true); return false; } }>
                  { t(`twoFactorAdditionalInstructions`) }
                </Button>
              }
            </span>
          </div>
        </div>


        <div className="notification">
          <h2>
            { t(`twoFactorAuthAbbreviatedApps`) }
          </h2>

          <div>
            { availableApps.map((item, idx) => <div key={ idx }>
              <span className="available-apps-container">
                <h3>
                  { item.name }
                </h3>

                { item.availableDevices.map((device, idx) => <span key={ idx }>
                  { device() }
                </span>) }
              </span>

              <ul className="downloads-container">
                { item.downloads.map((download, idx) => <li key={ idx }>
                  { download.image ? 
                    <a
                      aria-label={ download.description }
                      href={ download.link }
                      rel="nofollow noopener noreferrer"
                      target="_blank">
                      <img src={ download.image }  alt={ download.description } /> 
                    </a>
                    :
                    <div>
                      <p>
                        { download.description }
                      </p>
                        
                      <Button
                        useSpanInsteadOfButton
                        alt={ download.description } 
                        type='primary'
                        onClick={ () => false }>
                        <a
                          aria-label={ download.description }
                          href={ download.link }
                          rel="nofollow noopener noreferrer"
                          target="_blank">
                          { download.label }
                        </a>
                      </Button>
                    </div>
                  }
                </li>) }
              </ul>
            </div>) }
          </div>
        </div>
      </section>

      { showModal && !isLoading && 
        <TwoFactorAuthenticationModal
          t={ t }
          fA2Enabled={ fA2Enabled }
          onClose={ () => setShowModal(false) }
          onConfirm={ handleConfirmClick }
          platformId={ user?.platformId }
          secretKey={ secretKey }/>
      }

      { showInstructionModal && 
        <TwoFactorAuthenticationInstructionsModal 
          t
          onClose={ () => setShowInstructionModal(false) }
          platformId={ user?.platformId }/>
      }        
    </div>
  );
};


export default withRouter(TwoFactorAuthentication);

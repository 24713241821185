// @flow
'use strict';

import React from 'react';
import ReactDatePicker from 'react-datepicker';
import Today from '../../svgs/Today.jsx';

type DatePickerOptions = {
  value: any,
  onChange: (any) => void,
  min?: any,
  max?: any,
  dateFormat?: string,
  disabled?: boolean,
  monthsOnly?: boolean,
  yearsOnly?: boolean
};

const DatePicker = (props: DatePickerOptions) => {
  const { value, onChange, min, max, dateFormat, disabled, monthsOnly, yearsOnly } = props;
  const format = dateFormat ? dateFormat : `MM/dd/yyyy`;

  return (
    <div className="datepicker">
      <span>
        <Today/>
      </span>
      <ReactDatePicker
        disabled={ disabled }
        showMonthYearPicker={ monthsOnly }
        showYearPicker={ yearsOnly }
        dateFormat={ format }
        maxDate={ max && max }
        minDate={ min && min }
        onChange={ (v) => onChange(v) }
        selected={ value } />
    </div>
  );
  
};

export default DatePicker;

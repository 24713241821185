// @flow

import { $ } from "./ApiHelper";

export function addAlert(body: any, onSuccess: (data: any) => void) {
  $({
    url: `/private/user/alerts`,
    type: `POST`,
    body,
    success: onSuccess
  });
}

export function editAlert(id: string, body: any, onSuccess: (data: any) => void) {
  $({
    url: `/private/user/alerts/${ id }`,
    type: `PUT`,
    body,
    success: onSuccess
  });
}

export function deleteAlert(body: Array<number>, onSuccess: (data: any) => void) {
  $({
    url: `/private/user/alerts/delete-request`,
    type: `POST`,
    body,
    success: onSuccess
  });
}

export function getAlerts(options: any, onSuccess: (data: any) => void) {
  $({
    url: `/private/user/alerts`,
    success: onSuccess,
    params: options.params
  });
}

export function getAlertsHistory(options: any, onSuccess: (data: any) => void) {
  $({
    url: `/private/user/alerts/history`,
    success: onSuccess,
    params: options.params
  });
}


// @flow
'use strict';

import React, { useEffect, useRef, useState } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { updateTitle } from '../../helpers/BrowserTitleHelper.js';
import { subscribe, subscribeToAggregatedMarkets, unsubscribe } from '../../helpers/SocketClusterHelper.js';
import { getMarketsOverview } from '../../helpers/api/MarketsApi.js';
import type { Market } from "../../types/Market.js";
import type { Exchange } from "../../types/Exchange.js";
import type { Account } from "../../types/Account.js";
import type { Balance } from "../../types/Balance.js";
import SelectField from '../utilities/SelectField.jsx';
import ArbMatrixTable from './ArbMatrixTable.jsx';
import ArbMatrix from '../../svgs/ArbMatrix.jsx';
import Refresh from '../../svgs/Refresh.jsx';
import UpgradeNow from '../user/UpgradeNow.jsx';
import Modal from '../utilities/Modal.jsx';
import Hint from '../utilities/Hint.jsx';
import Checkbox from '../utilities/Checkbox.jsx';
import PauseCircle from '@mui/icons-material/PauseCircleFilled';
import PlayCircle from '@mui/icons-material/PlayCircleFilled';
import MarketSelector from './MarketSelector/MarketSelector.jsx';
// import { isFullAccessUser } from '../../helpers/UserApplicationsHelper.js';

const THROTTLE_MS = 1500;
const DEFAULT_MARKET = `BTC/USD`;
const DEFAULT_SORT = `percent`;
const DEFAULT_SORT_ORDER = `DESC`;
const FAQLink = `https://support.coinigy.com/hc/en-us/articles/13439015781915-How-do-I-use-Coinigy-s-ArbMatrix-on-V2-`;
export type Props = {
  t: any,
  platformId: number,
  aggregatedMarkets: [],
  exchanges: Array<Exchange>,
  markets: Array<Market>,
  accounts: Array<Account>,
  balances: Array<Balance>,
  isBalancesInit: boolean,
  isAccountsInit: boolean,
  isExchangesInit: boolean,
  // prefSubscriptionExpires: string,
  // prefSubscriptionId: any,
  // prefTrialUsed: boolean,
  subscriptionStatus: string,
  rehydrated: boolean,
}

const ArbMatrixDataLayerComponent = (props: Props) => { 
  const { t, platformId, aggregatedMarkets, exchanges, markets, accounts, balances, 
    isBalancesInit, isAccountsInit, isExchangesInit, /* prefSubscriptionExpires, prefSubscriptionId, prefTrialUsed, */ subscriptionStatus, rehydrated } = props;
  
  const [marketName, setMarketName] = useState(DEFAULT_MARKET);
  const [uniqueMarkets, setUniqueMarkets] = useState([]);
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingUniqueMarkets, setIsLoadingUniqueMarkets] = useState(true);
  const [intervalId, setIntervalId] = useState(null);
  const [exchangesTo, setExchangesTo] = useState([]);
  const [isFullAccess, setIsFullAccess] = useState(true);
  const subscribedMarkets  = useRef([]);
  const accumTradesPrice  = useRef([]);
  const autoSort = useRef(true);
  const sortOptionValue  = useRef(`${DEFAULT_SORT}|${DEFAULT_SORT_ORDER}`);
  const [isRefresh, setIsRefresh] = useState(false);
  const [showAuthTradeEnabled, setShowAuthTradeEnabled] = useState(false);
  const [showMarketSelector, setShowMarketSelector] = useState(false);

  updateTitle(t(`title`), platformId);

  const sortOptions = [
    {
      'label': `▲ Exchange A-Z`,
      'value': `exchange|ASC`
    },
    {
      'label': `▼ Exchange Z-A`,
      'value': `exchange|DESC`
    },
    {
      'label': `▲ Percent Gain`,
      'value': `percent|ASC`
    },
    {
      'label': `▼ Percent Gain`,
      'value': `percent|DESC`
    }
  ];

  const fetchData =  () => {
    getMarketsOverview(marketName)
      .then((data) => {
        const bData = buildData(data?.result);

        if (isFullAccess) {
          bData.forEach((exch) => {
            subscribeMarket({ data: [exch.exch_code, exch.mkt_name] });
          });
        }
        
        const sData = sortData(sortOptionValue.current, bData);

        setData(sData);
        setIsLoading(false);
        setIntervalId(setInterval(updateData, THROTTLE_MS));
      });
  };

  const buildData = (data: any) => {
    return data.map( (exchFrom) => {
      const basePricePrecision = getPrecision(exchFrom.exch_code);

      const compare = data.reduce((accum, exchTo) => {
        //SPREAD
        //const spreadPriceDiff = parseFloat(exchTo.ask) - parseFloat(exchFrom.bid);
        //const spreadPercent = (spreadPriceDiff / parseFloat(exchFrom.bid)) * 100;
        
        //INVERSE SPREAD
        const inversePercent = exchTo.bid && exchFrom.ask ? (parseFloat(exchTo.bid) - parseFloat(exchFrom.ask)) / parseFloat(exchTo.bid) * 100 : null;

        const exchToPrecision = getPrecision(exchTo.exch_code);

        return [
          ...accum,
          {
            exch_code: exchTo.exch_code,
            ask: exchTo.ask,
            bid: exchTo.bid,
            ask_qty: exchFrom.ask_qty,
            bid_qty: exchTo.bid_qty,
            //spreadPercent: spreadPercent || null,
            inversePercent: inversePercent,
            basePricePrecision: exchToPrecision
          }
        ];
      }, []);


      // get exchName from exchCode
      const exchName = exchanges ? exchanges.find((e) => e.exchCode === exchFrom.exch_code)?.exchName : ``;
      
      const authTradeEnabled = hasAuthTradeEnabled(exchFrom.exch_mkt_id);
      
      return {
        ...exchFrom,
        exch_name: exchName,
        basePricePrecision,
        authTradeEnabled,
        compare
      };
    });
  };

  const updateData = () => {
    const trades = accumTradesPrice.current?.splice(0);

    if (trades.length) {
      setData((prev) => {

        const updated = prev?.map((m) => {
          const t = trades.find((t) => m.exch_code === t.exchCode && m.mkt_name === t.label);
          if (t?.price) m.last_price = t.price;
          if (t?.ask) m.ask = t.ask;
          if (t?.bid) m.bid = t.bid;
          if (t?.ask_qty) m.ask_qty = t.ask_qty;
          if (t?.bid_qty) m.bid_qty = t.bid_qty;
          
          return m;
        });

        const bData = buildData(updated);
        
        return autoSort.current ? sortData(sortOptionValue.current, bData): bData;
      });
    }
  };

  const sortData = (sortValues, arrData: any = data) => {
    sortOptionValue.current = sortValues;
    const [sortValue, sortOrderValue] = sortValues.split(`|`);

    if (sortValue === `percent`) {
      const flatData = arrData.reduce((arr, d: any) => {
        const flatCompare = d.compare.map((c: any) => ({ exchFrom: d.exch_code, ...c }));
        return [
          ...arr,
          ...flatCompare
        ];
      }, [])
        .sort((a, b) =>(sortOrderValue === `ASC`) ? 
          a.inversePercent - b.inversePercent
          :
          // $FlowIgnore: suppressing this error
          (b.inversePercent !== null) - (a.inversePercent !== null) || b.inversePercent - a.inversePercent
        );
    
      const exchTo = [...new Set(flatData?.map((item) => item.exch_code))];
      setExchangesTo(exchTo);
  
      const sortedData = [...new Set(flatData?.map((item) => item.exchFrom))].map((e) => arrData.find((d) => d.exch_code === e));
      
      // $FlowIgnore: suppressing this error
      return sortedData;

    } else if (sortValue === `exchange`) {
      const sortedData = arrData.sort((a, b) => {
        if (a.exch_code.toLowerCase() > b.exch_code.toLowerCase()) return sortOrderValue === `ASC` ? 1 : -1;
        if (b.exch_code.toLowerCase() > a.exch_code.toLowerCase()) return sortOrderValue === `ASC` ? -1 : 1;
        return 0;
      });

      const exchTo = arrData.map((item) => item.exch_code);
      setExchangesTo(exchTo);

      // $FlowIgnore: suppressing this error
      return sortedData;
    }
  };

  const getPrecision = (exchCode) => {
    return markets.find((m) => m.exchCode === exchCode && m.displayName === marketName)?.basePricePrecision || 2;
  };

  const hasAuthTradeEnabled = (exchMktId) => {
    const mkt = markets.find((m) => m.exchmktId === exchMktId);
    if (mkt) {
      const mktAcc = accounts.filter((a) => mkt.exchId === a.authExchId && a.authTrade).map((account) => account.authId);
      
      const authTradeEnabled = balances.some((balance) =>
        mktAcc.includes(balance.balanceAuthId) && mkt.baseCurrCode === balance.balanceCurrCode
      );

      return authTradeEnabled;
    }

    return false;
  };

  const getLSCXAggregatedMarkets = (marketName) => {
    return !aggregatedMarkets.length ? [] : aggregatedMarkets?.filter((agM) => agM.marketName === marketName)[0].aggregatedMarkets.map((selectedAggregatedMarket) => {
      const [exchCode, marketName] = selectedAggregatedMarket.split(`:`);
      return {
        type: `market`,
        data: [exchCode, marketName]
      };
    });
  };

  const subscribeMarket = (subsMarket) => {
    const marketId =  subsMarket.data.join(`#`);
    const isSubscribed = subscribedMarkets.current.some((sm) => sm === marketId);
    let marketsToSubscribe = [];

    if (subsMarket.data[0] === `DERI`) subsMarket.data[1] = subsMarket.data[1].substr(4, subsMarket.data[1].length);

    if (subsMarket.data[0] === `LSCX`) marketsToSubscribe = getLSCXAggregatedMarkets(subsMarket.data[1]);

    if (!isSubscribed) {
      subscribedMarkets.current.push(marketId);

      if (marketsToSubscribe.length) {  
        subscribeToAggregatedMarkets(marketsToSubscribe, { type: `market`, data: subsMarket.data }, (ch) => subscribeCallback(ch, { ...subsMarket, marketsToSubscribe }));
      } else {
        marketsToSubscribe = [{ type: `market`, data: subsMarket.data }];
        subscribe(marketsToSubscribe, (ch) => subscribeCallback(ch, subsMarket));
      }
    } 
  };

  const subscribeCallback = (channels: any, market: any) => {
    for (const channel in channels) {
      const tradeChannel = channels[channel].trades;
      if (tradeChannel) {
        tradeChannel.watch((d) => {
          const isAggregatedMarket = market.marketsToSubscribe && market.marketsToSubscribe.some((m) => m.data[0] === d.exchange && m.data[1] === d.label);

          if (!market.marketsToSubscribe || isAggregatedMarket) {
            const exchange = isAggregatedMarket ? market.data[0] : d.exchange;

            const acIdx = accumTradesPrice.current.findIndex((m) => m.exchCode === exchange && m.label === d.label);
            if (acIdx >= 0) {
              accumTradesPrice.current[acIdx].price = d.price;
            } else {
              accumTradesPrice.current.push({ label: d.label, exchCode: exchange, price: d.price, ask: undefined, bid: undefined, ask_qty: undefined, bid_qty: undefined });
            }
          }
        });
      }

      const orderChannel = channels[channel].orders;
      if (orderChannel) {
        orderChannel.watch((d) => { 
          if (d.length) {
            const exchange = d[0].exchange;
            const minSell = Math.min(...d.filter((df) => df.ordertype === `Sell`).map((o) => o.price));
            const maxBuy = Math.max(...d.filter((df) => df.ordertype === `Buy`).map((o) => o.price));
  
            const askQty = Math.min(...d.filter((df) => df.ordertype === `Sell`).map((o) => o.quantity));
            const bidQty = Math.max(...d.filter((df) => df.ordertype === `Buy`).map((o) => o.quantity));
  
            const acIdx = accumTradesPrice.current.findIndex((m) => m.exchCode === exchange && m.label === market.data[1]);
            if (acIdx >= 0) {
              accumTradesPrice.current[acIdx].ask = minSell;
              accumTradesPrice.current[acIdx].bid = maxBuy;
              accumTradesPrice.current[acIdx].ask_qty = askQty;
              accumTradesPrice.current[acIdx].bid_qty = bidQty;
            } else {
              accumTradesPrice.current.push({ label: market.data[1], exchCode: exchange, price: undefined, ask: minSell, bid: maxBuy, ask_qty: askQty, bid_qty: bidQty });
            }
          }
        });
      }
    }
  };

  const unsubscribeAllMarkets = () => {
    const marketsToUnsubscribe = subscribedMarkets.current.slice(0);
    marketsToUnsubscribe.forEach((mId) => {
      const subsObj = {
        type: `market`,
        data: mId.split(`#`)
      };
      unsubscribeMarket(subsObj);
    } );
  };

  const unsubscribeMarket = (subsMarket) => {
    const marketId =  subsMarket.data.join(`#`);
    const isSubscribed = subscribedMarkets.current.some((sm) => sm === marketId);
    let marketsToUnsubscribe = [];

    if (subsMarket.data[0] === `DERI`) subsMarket.data[1] = subsMarket.data[1].substr(4, subsMarket.data[1].length);

    if (subsMarket.data[0] === `LSCX`) marketsToUnsubscribe = getLSCXAggregatedMarkets(subsMarket.data[1]);

    if (isSubscribed) {
      subscribedMarkets.current = subscribedMarkets.current.filter((c) => c !== marketId);

      if (marketsToUnsubscribe.length) {
        marketsToUnsubscribe.push({ type: `market`, data: subsMarket.data });
      } else {
        marketsToUnsubscribe = [{ type: `market`, data: subsMarket.data }];
      }

      unsubscribe(marketsToUnsubscribe);
    }
  };

  useEffect(() => {
    if (rehydrated && markets.length) {
      const unique = [...new Set(markets?.map((item) => item.displayName))]
        .sort((a, b) => {
          if (a > b) return 1;
          if (b > a) return -1;
          return 0;
        })
        .map((m) => ({
          'label': m,
          'value': m,
        })
        );

      setUniqueMarkets(unique);
      setIsLoadingUniqueMarkets(false);
    }
  }, [rehydrated, markets]);

  useEffect(() => {
    if (!isLoadingUniqueMarkets && isBalancesInit && isAccountsInit && isExchangesInit) {
      setIsLoading(true);
      setData([]);
      fetchData();      
    }

    return () => {
      unsubscribeAllMarkets();
    };
  }, [marketName, isLoadingUniqueMarkets, isBalancesInit, isAccountsInit, isExchangesInit]);

  useEffect(() => {
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [intervalId]);

  // useEffect(() => { 
  //   if (rehydrated) {
  //     setIsFullAccess(isFullAccessUser(platformId, prefSubscriptionExpires, prefSubscriptionId, prefTrialUsed));
  //   }
  // }, [rehydrated, prefSubscriptionExpires, prefSubscriptionId, prefTrialUsed, platformId]);

  useEffect(() => { 
    if (rehydrated) {
      setIsFullAccess(platformId === 1 || ([`lifetime`, `active`].includes(subscriptionStatus) && platformId === 0));
    }
  }, [rehydrated, subscriptionStatus, platformId]);

  useEffect(() => {
    if (isRefresh) setTimeout(() => setIsRefresh(false), 600);
  }, [isRefresh]);

  return (
    <div className="arbmatrix-page">
      { !isFullAccess &&
        <div className='upgrade-now-modal'>
          <Modal title={ `` } onClose={ () => {} }>
            <UpgradeNow t={ t }/>
          </Modal>
        </div>
      }
      <div className="header">
        <div className="title-container">
          { ArbMatrix }
          <h1 className="page-title">
            { t(`header:arbMatrix`) }
          </h1>
          <a
            href={ FAQLink }
            rel="noopener noreferrer"
            target="_blank"
            onClick={ (e) => e.stopPropagation() }>
            { `ArbMatrix user guide` }
          </a>
        </div>
        <div className="flex">

          <div className="trading-enabled-filter">
            <Checkbox 
              label={ t(`limitMyAccounts`) } 
              name="show-trade-enabled" 
              data-testid='show-auth-trade-enabled' 
              onChange={ () => setShowAuthTradeEnabled((prev) => !prev) } value={ showAuthTradeEnabled } />
          </div>

          <SelectField
            label={ `` }
            name="sort"
            value={ sortOptionValue.current }
            onChange={ (e, v) => {
              setData(sortData(v));
            } }
            options={ sortOptions } 
            disabled={ !isFullAccess }/>
       
          { isFullAccess && <div className='flex'>
            <div 
              className={ `refresh ${autoSort.current || isRefresh ? `rotate` : ``}` } 
              onClick={ () => { 
                if (!autoSort.current) { 
                  setIsRefresh(true);
                  setData(sortData(sortOptionValue.current));
                } 
              } }>
              { Refresh() }
            </div>
            <div onClick={ () => autoSort.current = !autoSort.current }>
              <Hint
                content={ t(`automaticallySortHint`, { action: autoSort.current ? `Pause` : `Start` }) }>
                { autoSort.current ? 
                  <PauseCircle className='pause'/>
                  :
                  <PlayCircle  className='play'/>
                }
              </Hint>
            </div>
          </div> 
          }
        </div>
      </div>
      { isFullAccess && showMarketSelector && <MarketSelector markets={ uniqueMarkets } isLoading={ isLoadingUniqueMarkets } close={ () => setShowMarketSelector(false) } updateMarketName={ setMarketName }/> }

      <ArbMatrixTable 
        t={ t }
        isLoading={ isLoading }
        data={ data }
        marketName={ marketName }
        exchangesTo={ exchangesTo } 
        isFullAccess={ isFullAccess }
        showAuthTradeEnabled={ showAuthTradeEnabled }
        openMarketSelector={ () => setShowMarketSelector(true) }/>
    </div>
  );
};

const mapStateToProps = (state) => ({
  platformId: state.userInfo.user.platformId,
  // prefSubscriptionExpires: state.userInfo?.userPrefs?.prefSubscriptionExpires,
  // prefSubscriptionId: state.userInfo?.userPrefs?.prefSubscriptionId,
  // prefTrialUsed: state.userInfo?.userPrefs?.prefTrialUsed,
  subscriptionStatus: state.userInfo?.subscriptionInfo?.subscriptionStatus,
  rehydrated: state._persist.rehydrated,
});

export { ArbMatrixDataLayerComponent as PureArbMatrixDataLayerComponent };
export default translate(`arbMatrix`)(connect(mapStateToProps, null)(ArbMatrixDataLayerComponent));

// @flow
'use strict';

import { useCallback } from 'react';
import type { Account } from "../../types/Account.js";
import type { Balance } from "../../types/Balance.js";
type Props = {
  accounts: Array<Account>,
  balances: Array<Balance>,
  markets: Array<any>,
}

const useMarketsBalances = () => {

  const mapMarketBalances = (accounts, market, balances, accum) => {
    const bln = balances.reduce((accum, balance) => [
      ...accum, {
        ...balance,
        marketId: market.marketId,
        exchId: market.exchId,
        authId: balance.balanceAuthId,
        authNickname: accounts.find((account) => account.authId === balance.balanceAuthId)?.authNickname,
      }
    ], []);
   
    return  [...accum.filter((b) => !(market.marketId === b.marketId && market.exchId === b.exchId)), ...bln];
  };

  const getMarketsBalances = useCallback((props: Props) => {
    const { accounts, markets, balances } = props;

    // $FlowIgnore: suppressing this error
    const marketsBalances = markets.filter((market) => 
      balances.find((balance) =>
        market.accounts.includes(balance.balanceAuthId) && 
        market.baseCurrencyCode === balance.balanceCurrCode
      )
    );

    const blnc = markets.reduce((accum, market) => {
      const bln = balances.filter((balance) => market.accounts.includes(balance.balanceAuthId) && market.baseCurrencyCode === balance.balanceCurrCode);
      return mapMarketBalances(accounts, market, bln, accum);
    }, []);

    return {
      balances: blnc,
      marketsBalances
    };
  }, []);

  return { getMarketsBalances };
};

export default useMarketsBalances;

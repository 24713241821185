// @flow
'use strict';

import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getMarketFromPanelSettings } from '../../helpers/MarketPairHelper.js';
import MarketInsights from "../markets/MarketInsights.jsx";

type Props = {
  t: any,
  panelData: any, 
  settings: any, 
  currencies: any,
};


const InsightsPanel = (props: Props) => {
  const { panelData, settings, t, currencies } = props;
  const [ base, quote ] = settings.market.split(`/`);

  if (
    panelData.hasOwnProperty(base) && 
    panelData.hasOwnProperty(quote)
  ) {
    return (
      <MarketInsights
        baseCurrency={ panelData[base].currData }
        quoteCurrency={ panelData[quote].currData }
        baseCurrencyItbSignals={ panelData[base].signals }
        quoteCurrencyItbSignals={ panelData[quote].signals }
        currencies={ currencies }
        cmCalCoins={ [] }/>
    );
  } else {
    return (
      <div className="panel-loading">
        <span>
          { t(`app:loading`) }
        </span>
      </div>
    );
  }
};

// $FlowIgnore: suppressing this error
InsightsPanel.getPanelTitle = (settings: Settings, markets: Array<Market>) => {
  const market = getMarketFromPanelSettings(markets, settings);
  return market ? market.exchName + ` - ` + market.displayName : `Insights`;
};

const mapStateToProps = (state) => ({
  theme: state.redisPrefs.theme
});

export default translate(`boards`)(connect(mapStateToProps)(InsightsPanel));

// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import Modal from '../utilities/Modal.jsx';

type Props = {
  t: any,
  label: string,
  delete: () => void,
  close: () => void
};

const DeleteBoardModal = (props: Props) => {
  const { t, delete: deleteBoard, close, label } = props;

  return (
    <Modal
      title={ t(`app:genericConfirm`) }
      onConfirm={ deleteBoard }
      onClose={ close }
      confirmText={ t(`app:confirm`) }
      cancelText={ t(`app:cancel`) }>
      <p>
        { t(`deleteConfirm1`) }
        { ` ` }
        <strong>
          { label }
        </strong>
        { t(`deleteConfirm2`) }
      </p>
    </Modal>
  );
};

export { DeleteBoardModal as PureDeleteBoardModal };
export default translate(`boards`)(DeleteBoardModal);

// @flow
'use strict';

import { UPDATE_ONLINE } from '../index.js';

export function updateOnline(online: boolean) {
  return {
    type: UPDATE_ONLINE,
    payload: { online }
  };
}

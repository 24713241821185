// @flow
'use strict';

import { CHANGE_GLOBAL_PAGE_SIZE } from '../index.js';

export function changeGlobalPageSize(payload: any) {
  return {
    type: CHANGE_GLOBAL_PAGE_SIZE,
    payload
  };
}


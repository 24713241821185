// @flow
'use strict';

import React, { useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import ProgressSpinner from '../utilities/ProgressSpinner.jsx';
import { toFixedDecimalsHTML } from '../../helpers/NumberHelper.js';
import Search from "../../svgs/Search.jsx";

export type Props = {
  t: any,
  isLoading: boolean,
  data: Array<any>, 
  marketName: string,
  exchangesTo: Array<any>,
  isFullAccess: boolean,
  showAuthTradeEnabled: boolean,
  openMarketSelector: () => void
}

const ArbMatrixTable = (props: Props) => { 
  const { t, isLoading, data, marketName, exchangesTo, isFullAccess, showAuthTradeEnabled, openMarketSelector } = props;

  const [columns, setColumns] = useState([]);
  const [arbMatrixData, setArbMatrixData] = useState([]);

  const getTradeMarketUrl = (marketDisplayName, exchCode) => `/markets/${ exchCode }/${ marketDisplayName }`;

  const renderExchange = (exchCode, exchName, lastPrice, orderType, orderValue, quantity, precision, authTradeEnabled) => {
    return (
      <div
        className={ `mui-cell-exchange ${ authTradeEnabled ? `auth-trade` : `` }` }
        onClick={ () => {if (isFullAccess) {
          window.open(getTradeMarketUrl(marketName, exchCode), `_blank`);
        }} }>
      
        <span className="exchange">
          <img 
            width={ 20 }              
            src={ `${ window.WWW_URL }/assets/img/exchange/${ exchCode }-icon.png` }
            onError={ (e) => {e.target.onerror = null; e.target.src=`${ window.WWW_URL }/assets/img/currency/empty.png`;} } /> 
          { exchName } 
        </span>
        <div className="exch-item">
          <label className="last">
            { t(`app:last`) }
          </label>
          <div
            className={ `nowrap` }
            dangerouslySetInnerHTML={
              toFixedDecimalsHTML(lastPrice || 0, false, ``, undefined, false, false, ``, precision)
            }/>            
        </div>
        <div 
          className={ `exch-item ${orderType.toUpperCase() === `BID` ? `green` : `red` }` }>
          <label>
            { `${orderType}` }
          </label>
          { orderValue ?
            <div
              className={ `nowrap align-right bidAsk` }
              dangerouslySetInnerHTML={
                toFixedDecimalsHTML(orderValue, false, ``, undefined, false, false, ``, precision)
              }/>        
            :
            <div className="na">
              { `N/A` }
            </div>
          }    
        </div>
        <div 
          className={ `exch-item` }>
          <label className="qty">
            { `QTY` }
          </label>
          { quantity ?
            <div
              className={ `nowrap align-right` }
              dangerouslySetInnerHTML={
                toFixedDecimalsHTML(quantity, false, ``, undefined, false, false, ``, precision)
              }/>        
            :
            <div className="na">
              { 0.00 }
            </div>
          }    
        </div>
      </div>
    ); 
  };

  const renderArbitrage = (exchCode, dataIndex) => {
    const exchCompare = arbMatrixData[dataIndex]?.compare?.find( (e) => e.exch_code === exchCode);
    if (exchCompare) {
      const availableQty = exchCompare.bid_qty > exchCompare.ask_qty ? exchCompare.ask_qty : exchCompare.bid_qty;
      
      return (
        <div
          className={ `mui-cell-arbitrage nowrap align-right` } 
          onClick={ () => {
            if (isFullAccess) {
              window.open(getTradeMarketUrl(marketName, exchCode), `_blank`);
              if (exchCode !== arbMatrixData[dataIndex].exch_code) window.open(getTradeMarketUrl(marketName, arbMatrixData[dataIndex].exch_code), `_blank`);
            }
          } }>
          { exchCompare.inversePercent !== null &&
            <div
              className={ `percent nowrap ${exchCompare.inversePercent > 0 ? `green` : `red` }` }
              dangerouslySetInnerHTML={
                toFixedDecimalsHTML(exchCompare.inversePercent, false,`two_decimals`, undefined, false, false, `%`)
              }/>
          }
          <div
            className={ `nowrap` }
            dangerouslySetInnerHTML={
              toFixedDecimalsHTML(availableQty || 0, false, ``, undefined, false, false, ``, arbMatrixData[dataIndex].baseQuantityPrecision)
            }/>
        </div>
      );
    }
  };

  const getColumns = () => {
    const columns = [
      {
        name: `market`,
        label: marketName,
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({
            style: {
              whiteSpace: `nowrap`,
              position: `sticky`,
              left: 0,
              background: `var(--gray-1)`,
              zIndex: 100,
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: `nowrap`,
              position: `sticky`,
              left: 0,
              zIndex: 101,
            },
          }),
          customHeadLabelRender: () => { 
            return (
              <div className={ `nowrap align-left mui-cell-market` } onClick={ () => isFullAccess && openMarketSelector() }>
                <div>
                  { t(`app:market`) }
                </div>
                <div>                  
                  <span>
                    { Search }
                  </span>
                  <span className={ `market-selected` }>
                    { marketName }
                  </span>
                </div>
              </div>
            );
          },
          customBodyRenderLite: (dataIndex) => renderExchange(arbMatrixData[dataIndex]?.exch_code, 
            arbMatrixData[dataIndex]?.exch_name, 
            arbMatrixData[dataIndex]?.last_price, 
            t(`app:ask`), 
            arbMatrixData[dataIndex]?.ask, 
            arbMatrixData[dataIndex]?.ask_qty, 
            arbMatrixData[dataIndex]?.basePricePrecision,
            arbMatrixData[dataIndex]?.authTradeEnabled)
        }
      }];

    return exchangesTo.reduce((cols, exchTo) => {
      
      const exch = arbMatrixData.find((d) => d.exch_code === exchTo);

      if (!exch) return cols;

      return [
        ...cols,
        {
          name: exch.exch_code,
          label: exch.exch_code,
          options: {
            filter: false,
            sort: false,
            customHeadLabelRender: () => renderExchange(exch.exch_code, exch.exch_name, exch.last_price, t(`app:bid`), exch.bid, exch.bid_qty, exch.basePricePrecision, exch.authTradeEnabled),
            customBodyRenderLite: (dataIndex) => renderArbitrage(exch.exch_code, dataIndex)
          }
        },
      ];
    }, columns);
  };
  
  const options = {
    tableId: `mui-datatables-arbmatrix`,
    responsive: `standard`,
    serverSide: true,
    download: false,
    filter: false,
    print: false,
    viewColumns: false,
    selectableRows: `none`,
    search: false,
    searchAlwaysOpen: false,
    searchOpen: false,   
    expandableRowsHeader: false,
    expandableRows: false,
    pagination: false,
    textLabels: {
      body: {
        noMatch: isLoading ? <ProgressSpinner /> :  t(`app:noData`),
      },
    }
  };

  useEffect(() => {
    setArbMatrixData(showAuthTradeEnabled ? data.filter( (d) => d.authTradeEnabled ) : data);
  }, [data, showAuthTradeEnabled]);

  useEffect(() => {
    setColumns(getColumns());
  }, [arbMatrixData]);

  return (
    <section className="fade-in">
      <MUIDataTable
        data={ arbMatrixData }
        columns={ columns }
        options={ options }/>
    </section>
  );
};
export default ArbMatrixTable;

// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { getMarketFromPanelSettings } from '../../helpers/MarketPairHelper.js';
import StreamingTradesList from '../markets/StreamingTradesList.jsx';
import type { Market } from '../../types/Market.js';
import type { Exchange } from '../../types/Exchange.js';

type Settings = {
  exchange: string,
  market: string
};

type Props = {
  t: any,
  zeroStyle: string,
  market: Market,
  exchanges: Array<Exchange>,
  settings: Settings, 
  panelData: any,
  limitDeviation: boolean,
  theme: string
};

const MarketSplitTradesPanel = ({ t, zeroStyle, market, settings, panelData, exchanges }: Props) => {
  const mkt = `${ settings.exchange }:${ settings.market }`;
  if (
    panelData.hasOwnProperty(mkt) && 
    panelData[mkt].trades &&
    panelData[mkt].trades.length > 0 && 
    market
  ) {
    return (
      <div className="market-split-trades-panel">
        <div className="buy-container">
          <div className="title">
            { t(`boards:recentBuys`) }
          </div>
          <StreamingTradesList
            trades={ panelData[mkt].trades.filter((t) => t.type.toLowerCase() === `buy`) }
            market={ market }
            zeroStyle={ zeroStyle } 
            exchanges={ exchanges }/>
        </div>
        <div className="sell-container">
          <div className="title">
            { t(`boards:recentSells`) }
          </div>
          <StreamingTradesList
            trades={ panelData[mkt].trades.filter((t) => t.type.toLowerCase() === `sell`) }
            market={ market }
            zeroStyle={ zeroStyle } 
            exchanges={ exchanges }/>          
        </div>
      </div>
    );
  } else {
    return (
      <div className="panel-loading">
        <span>
          { t(`app:loading`) }
        </span>
      </div>
    );
  }
};

MarketSplitTradesPanel.getPanelTitle = (settings: Settings, markets: Array<Market>) => {
  let market = getMarketFromPanelSettings(markets, settings);

  return market ? market.exchName + ` - ` + market.displayName : `Market Recent Trades`;
};

const mapStateToProps = (state) => ({
  zeroStyle: state.redisPrefs.zeroStyle
});

export default translate(`boards`)(connect(mapStateToProps)(MarketSplitTradesPanel));

// @flow
'use strict';

import React, { useState, useEffect } from 'react';
import { useStateRef } from '../../helpers/useStateRef';
import { translate } from 'react-i18next';
import CollapsibleRow from './CollapsibleRow.jsx';

type Props = {
  t: any,
  columns: Array<{
    title: string,
    key: string,
    display?: Function,
    nosort?: boolean,
    thClass?: string,
    tdClass?: string,
    compareValue?: Function
  }>,
  data: Array<any>,
  defaultSortBy?: string,
  hasActionButtons?: boolean,
  pageSize?: number,
  filterText?: string,
  requestMoreData?: Function,
  rowHover?: Function,
  rowUnhover?: Function,
  tableUnhover?: Function,
  requestMoreDataThreshold?: number,
  hidePagination?: boolean,
  noDataText?: string,
  isBalancesOrdersHistory?: boolean,
  totalRecords: number,
  totalPages: number,
  collapsibleColumns?: Array<string>,
  hasCollapsibleRows?: boolean,
};

const EntityTable = (props: Props) => {
  const { t, columns, data, defaultSortBy, pageSize, filterText, requestMoreData, rowHover, rowUnhover, tableUnhover, hidePagination, noDataText,
    isBalancesOrdersHistory, totalRecords, totalPages, hasCollapsibleRows, collapsibleColumns, hasActionButtons }  =  props;

  let _sortBy;
  let _sortDirection;
  if (defaultSortBy) {
    _sortBy = defaultSortBy;

    if (defaultSortBy[0] == `-` || defaultSortBy[0] == `+`) {
      _sortDirection = defaultSortBy[0];
      _sortBy = defaultSortBy.substr(1, defaultSortBy.length);
    }
  }

  const [sortBy, setSortBy] = useState(_sortBy || ``);
  const [sortDirection, setSortDirection] = useState(_sortDirection || `-`);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState(data);
  const [tableRows,  setTableRows] = useState(null);
  const [showActionButtons, setShowActionButtons, showActionButtons_ref] = useStateRef(false);

  useEffect(() => {
    let reset = pageSize !== pageSize
      || filterText !== filterText;

    if (reset) setCurrentPage(1);
  },[pageSize, filterText]);

  useEffect(() => {
    setTableData(data);
  },[data]);

  useEffect(() => {
    setTableData(data.filter((d) => {
      if (!filterText) return true;
      return columns
        .map((c) => {
          if (d.hasOwnProperty(c.key)) {
            if (c.compareValue) {
              return c.compareValue(d, d[c.key]);
            } else if (c.display) {
              return c.display(d, d[c.key]);
            } else {
              return d[c.key];
            }
          } else {
            return ``;
          }
        })
        .join(`|`)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) > -1;
    }));
  }, [filterText, data]);

  useEffect(() => {
    if (tableData.length > 0) {
      setTableRows(
        tableData.sort((a, b) => {
          if (sortBy.length == 0) return 0;

          let _a = a[sortBy],
            _b = b[sortBy],
            desc = sortDirection == `-`;

          if (_a > _b) {
            return desc ? -1 : 1;
          } else if (_a < _b) {
            return desc ? 1 : -1;
          } else {
            return 0;
          }
        }).map(hasCollapsibleRows ? renderCollapsibleRows : renderNormalRows));
    } else { 
      setTableRows(renderNoDataRow());
    }
  }, [tableData, sortBy, sortDirection, filterText, currentPage]);

  useEffect(() => {
    // Should render action buttons on hover or scrollbar fully scrolled.
    const scrollableTable = document.querySelector(`.entity-table-wrapper`);
    if (hasActionButtons) {
      shouldRenderActionButtons(scrollableTable);
      scrollableTable?.addEventListener(`scroll`, (e: any) => shouldRenderActionButtons(e.target));
    }
    return () => {
      scrollableTable?.removeEventListener(`scroll`, (e: any) => shouldRenderActionButtons(e.target));
    };
  }, []);

  const onHeaderClick = (e: any, key: string) => {
    if (e) e.preventDefault();
    if (!key) return false;
    if (key == sortBy) {
      setSortDirection(sortDirection == `-` ? `+` : `-`);
      setCurrentPage(1);
    } else {
      setSortBy(key);
      setSortDirection(`-`);
      setCurrentPage(1);
    }

    return false;
  };

  const nextPage = (e: any) => {
    if (e) e.preventDefault();

    let nextPage: number = currentPage + 1;

    if (requestMoreData) {
      requestMoreData(nextPage, pageSize);
    }

    setCurrentPage(nextPage);

    return false;
  };

  const prevPage = (e: any) => {
    if (e) e.preventDefault();

    let prevPage: number = currentPage === 1 ? 1 : currentPage - 1;

    setCurrentPage(prevPage);

    if (requestMoreData) {
      requestMoreData(prevPage, pageSize);
    }

    return false;
  };

  const noClick = (e: any) => {
    if (!e) return false;
    e.preventDefault();

    return false;
  };

  const columnsMinWidth = () => {
    // column keys that should have a min-width applied
    return [
      `quantity`, 
      `limitPrice`,
      `orderTime`
    ];
  };

  let _pageSize = pageSize ? pageSize : 0;
    
  let _totalRecords = totalRecords || tableData.length;

  let _totalPages = totalPages || 1;

  let start = (currentPage - 1 ) * _pageSize;
  let end = start + _pageSize;
  let lastPage = false;
  let firstPage = false;
  if (currentPage === 1) {
    firstPage = true;
  }

  if (end >= _totalRecords) {
    end = _totalRecords;
    lastPage = true;
  }

  if (start + 1 > end) {
    start = end - 1;
  }

  const renderNoDataRow = () => { 
    return (
      <tr>
        <td
          colSpan={ columns.length }
          className="align-center">
          { !noDataText ? t(`noData`) : noDataText }
        </td>
      </tr>
    );
  };

  const renderCollapsibleRows = (d, i) => {
    const buildCollapsibleData = d.collapsibleData.length > 0 && collapsibleColumns ? (
      <table className={ `collapsible-table` }>
        <tbody>
          { d.collapsibleData.map((row, i) => (
            <tr key={ i }>
              { collapsibleColumns.map((col, index) => (
                d.collapsibleData[i][col](index) 
              )) }
            </tr>
          )) }
        </tbody>
      </table>
    ) : <></>;

    return (
      <CollapsibleRow
        isOpen={ false }
        key={ i }
        collapsibleData={ buildCollapsibleData }
        even={ i % 2 === 0 }>
        { columns.map((col) => (
          <td
            key={ `${ i }${ col.key }` }
            className={
              (col.tdClass || ``) +
                      (isBalancesOrdersHistory &&
                      (columnsMinWidth().includes(col.key)) ? ` min-width` : ``) }>
            {
              col.display ?
                col.display(d, d[col.key]) :
                d[col.key]
            }
          </td>
        )) 
        }
      </CollapsibleRow>
    );
  };

  const renderNormalRows = (d, i) => {
    return (
      <tr
        onMouseEnter={
          () => { rowHover ? rowHover(i, d, { sortBy,sortDirection, currentPage }) : null; }
        }
        onMouseLeave={
          () => { rowUnhover ? rowUnhover(i, d, { sortBy,sortDirection, currentPage }) : null; }
        }
        key={ i }>
        {
          columns.map((col) => (
            <td
              key={ `${ i }${ col.key }` }
              className={
                (col.tdClass || ``) +
                  (isBalancesOrdersHistory &&
                  (columnsMinWidth().includes(col.key)) ? ` min-width` : ``) }>
              {
                col.display ?
                  col.display(d, d[col.key]) :
                  d[col.key]
              }
            </td>
          ))
        }
      </tr>
    );
  };

  const shouldRenderActionButtons = (e: any) => {
    if (hasActionButtons && e) {
      const buttonsWith = 10; // bonus width
      const elementWidthDiff = e.scrollLeft + e.offsetWidth + buttonsWith >=  e.children[0].offsetWidth;
      if (elementWidthDiff !== showActionButtons_ref.current) {
        setShowActionButtons(elementWidthDiff);
      }
    } 
  };

  return (
    <div className="entity-table-component" onMouseLeave={ () => { tableUnhover ? tableUnhover() : null; } } >
      <div className="entity-table-wrapper">
        <table className={ `entity-table ${showActionButtons ? `show-action-buttons` : ``}` }>
          <thead onMouseEnter={ () => { tableUnhover ? tableUnhover() : null; } } >
            <tr>
              {
                columns.map((col, i) => (
                  <th
                    key={ i }
                    className={ (col.nosort ? `nosort` : ``) + 
                        (col.thClass || ``) + 
                        (isBalancesOrdersHistory && 
                          (columnsMinWidth().includes(col.key)) ? ` min-width` : ``) }
                      
                    onClick={ !col.nosort ? (e) => onHeaderClick(e, col.key) : (e) => noClick(e) }>
                    { col.title }
                    {
                      sortBy == col.key && sortDirection == `+` ? (
                        <i>
                          { `▲` }
                        </i>
                      ) : sortBy == col.key && sortDirection == `-` ? (
                        <i>
                          { `▼` }
                        </i>
                      ) : ``
                    }
                  </th>
                ))
              }
            </tr>
          </thead>
          <tbody>
            { tableRows }
          </tbody>
        </table>
      </div>
      {
        !hidePagination && tableData.length > 0 ? (
          <div className="pagination">
            <div>
              <span className="showing">
                { t(`showing`) }
              </span>
              { ` ` }
              { start + 1 }
                -
              { end }
              { ` ` }
              { t(`of`) }
              { ` ` }
              { _totalRecords }
              { ` ` }
              { t(`entries`) }
            </div>
            <div>
              { t(`page`) }
              { ` ` }
              { currentPage }
              { ` ` }
              { t(`of`) }
              { ` ` }
              { _totalPages }
              { ` ` }

              <a
                className={ firstPage ? `noclick` : `` }
                onClick={ !firstPage ? (e) => prevPage(e) : (e) => noClick(e) }>
                { t(`previous`) }
              </a>
              <a
                className={ lastPage ? `noclick` : `` }
                onClick={ !lastPage ? (e) => nextPage(e) : (e) => noClick(e) }>
                { t(`next`) }
              </a>
            </div>
          </div>
        ) : ``
      }
    </div>
  );
  
};

export { EntityTable as PureEntityTable };
export default translate(`app`)(EntityTable);

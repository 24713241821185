// @flow
'use strict';

import type { Exchange } from '../types/Exchange';

const getExchangeTradeApiVersion = (exchange?: Exchange): number => {
  if (exchange && exchange.exchTradeEnabledV2) {
    return 2;
  }

  if (exchange && exchange.exchTradeEnabled) {
    return 1;
  }

  return 0;
};


const addWalletExchange = (exchanges: Array<Exchange>): Array<any> => {
  return  [...exchanges, {
    exchId: 999,
    exchName: `Wallet`,
    exchCode: `Wallet`,
    exchAdded: `wallet`,
    exchEnabled: true,
    exchBalanceEnabled: true,
    exchMarketsInverted: false,
    exchPricesIndexed: true,
    exchTradeEnabled: true,
    exchUrl: ``,
  }];
};

export { getExchangeTradeApiVersion, addWalletExchange };

// @flow
'use strict';

import { combineReducers } from 'redux';
import app, { initialState as appState } from './app.js';
import balances, { initialState as balancesState } from './balances.js';
import boards, { initialState as boardsState } from './boards.js';
import markets, { initialState as marketsState } from './markets.js';
import redisPrefs, { initialState as redisPrefsState } from './redisPrefs.js';
import theme, { initialState as themeState } from './theme.js';
import userInfo, { initialState as userInfoState } from './userInfo.js';
import browser, { initialState as browserState } from './browser.js';
import orders, { initialState as ordersState } from './orders.js';
import kb, { initialState as kbState } from './kb.js';

export const initialState = {
  app: appState,
  balances: balancesState,
  boards: boardsState,
  markets: marketsState,
  redisPrefs: redisPrefsState,
  theme: themeState,
  userInfo: userInfoState,
  browser: browserState,
  orders: ordersState,
  kb: kbState
};

export default combineReducers({
  app,
  balances,
  boards,
  markets,
  redisPrefs,
  theme,
  userInfo,
  browser,
  orders,
  kb
});

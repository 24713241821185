// @flow
'use strict';

import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import AlertForm from './AlertForm.jsx';
import VolumeAlertForm from './VolumeAlertForm.jsx';
import * as alertApi from '../../helpers/api/AlertApi';
import { getMarketPair } from '../../helpers/MarketPairHelper.js';
import { toFixedDecimals } from '../../helpers/NumberHelper.js';
import type { Market } from '../../types/Market.js';
import type { Exchange } from '../../types/Exchange.js';
import {
  emitEvent,
  listenForEvent,
  removeEventListener,
  MARKET_ALERT_MODE_ACTIVATED,
  PRICE_CHANGE_REQUESTED,
  STOP_PRICE_CHANGE_REQUESTED,
  ALERT_CONFIRM_REQUESTED,
  ALERT_ADDED,
  POPULATE_ALERT_FORM
} from '../../helpers/EventHelper.js';
import CoinigyBaseComponent from '../CoinigyBaseComponent.jsx';
import PillToggle from "../utilities/PillToggle.jsx";

type Props = {
  t: any,
  markets: Array<Market>,
  exchanges: Array<Exchange>,
  sound: string,
  lastPrice: number,
  options: Array<{
    label: string,
    value: any,
    icon?: any,
    compareValue?: any
  }>,
  active?: {
    market: Market,
    exchange: Exchange,
  },
  marketLabel?: string,
  interval: string,
  numCandles: number,
  alertType: number,
  getTickerData?: (e: Exchange, m: Market, c: (p: number) => void) => void,
  updateAlertType: (v: string) => void,
  onRequestClose?: () => void
};

type State = {
  exchId: number,
  marketId: number,
  price: string,
  note: string,
  conditionalOperator: string,
  sound: string,
  reqMsg: string,
  interval: string,
  numCandles: number
};

class NewAlert extends CoinigyBaseComponent<Props, State> {
  tvMsgHandler: (e: any) => void;
  populateFormHandler: (e: any) => void;

  constructor(props: Props) {
    super(props);
    this.state = this.getInitialState();

    this.tvMsgHandler = this.handleTVMessage.bind(this);
    this.populateFormHandler = this.handlePopulateForm.bind(this);
  }

  getInitialState(preserveMkt: boolean = false) {
    const { lastPrice, active, sound } = this.props;
    const { market, exchange } = active || {};

    let price = market ? toFixedDecimals(lastPrice || 0, false, `price`, market) : (lastPrice || 0).toString();

    return {
      exchId: preserveMkt ? this.state.exchId : (this.hasActiveMarket() && exchange.exchId) || -1,
      marketId: preserveMkt ? this.state.marketId : (this.hasActiveMarket() && market.marketId) || -1,
      price: this.props.alertType == 0 || this.props.alertType == undefined ? price : ``,
      note: ``,
      conditionalOperator: `GreaterThanOrEqual`,
      sound: sound || `pop`,
      reqMsg: ``,
      alertType: this.props.alertType != undefined ? this.props.alertType : 0,
      interval: `1m`,
      numCandles: 20
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.sendToTV();

    listenForEvent(PRICE_CHANGE_REQUESTED, this.tvMsgHandler);
    listenForEvent(STOP_PRICE_CHANGE_REQUESTED, this.tvMsgHandler);
    listenForEvent(ALERT_CONFIRM_REQUESTED, this.tvMsgHandler);
    listenForEvent(POPULATE_ALERT_FORM, this.populateFormHandler);
  }

  componentWillUnmount() {
    this._isMounted = false;
    removeEventListener(PRICE_CHANGE_REQUESTED, this.tvMsgHandler);
    removeEventListener(STOP_PRICE_CHANGE_REQUESTED, this.tvMsgHandler);
    removeEventListener(ALERT_CONFIRM_REQUESTED, this.tvMsgHandler);
    removeEventListener(POPULATE_ALERT_FORM, this.populateFormHandler);
  }

  sendToTV(props: Props = this.props) {
    if (!props.active) return;
    this.updateTV(props);
  }

  updateTV(props: Props = this.props, state: State = this.state) {
    if (!props.active) return;

    emitEvent(MARKET_ALERT_MODE_ACTIVATED, {
      price: state.price,
      string: `${ state.price } ${ getMarketPair(props.active.market).quote }`,
      alertType: this.props.alertType != undefined ? this.props.alertType : 0
    });
  }

  handleTVMessage(e: any) {
    const active = this.props.active;
    switch(e.type) {
    case PRICE_CHANGE_REQUESTED:
      if (!active || this.props.alertType != 0) return;
      this.setStateSafe({
        conditionalOperator: parseFloat(e.detail) > this.props.lastPrice ? `GreaterThanOrEqual` : `LessThanOrEqual`,
        price: toFixedDecimals(e.detail, false, `price`, active.market)
      }, () => {
        if (!this.props.active) return;
        this.updateTV();
      });
      break;
    case ALERT_CONFIRM_REQUESTED:
      this.handleSubmit(undefined, () => {
        this.updateTV();
      });
      break;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { market: currentMarket } = this.props.active || { market: { marketId: 0 } };
    if (this.hasActiveMarket() && nextProps.active) {
      const { market, exchange } = nextProps.active;
      let lastPrice = nextProps.lastPrice;
      lastPrice = market ? toFixedDecimals(lastPrice || 0, false, `price`, market) : (lastPrice || 0).toString();

      if (this.props.alertType !== nextProps.alertType) {
        this.setStateSafe({
          price: nextProps.alertType == 0 ? lastPrice : ``
        }, () => this.sendToTV(nextProps));

      }

      if (parseFloat(this.props.lastPrice) === 0 || market.marketId !== currentMarket.marketId) {
        this.setStateSafe({
          price: this.props.alertType == 0 ? lastPrice : ``,
          marketId: market.marketId,
          exchId: exchange.exchId,
          sound: nextProps.sound
        }, () => this.sendToTV(nextProps));

      }
    }
  }

  handleInputChange(e: any, key: string, forceNumber?: boolean, value?: any) {
    let state = { };
    if (value) {
      if (value == ``) value = 0;
      if (forceNumber) {
        state[key] = parseInt(value);
      }else {
        state[key] = value;
      }
      
    } else {
      if (key == `note`) {
        state[key] = e.target.value;
      }else {
        if (e.target.value == ``) e.target.value = 0;
      
        if (/^\d+(\.\d*)?$/.test(e.target.value)) {
          if (forceNumber) { 
            state[key] = parseInt(e.target.value.toString().replace(/\D/g,``)); 
          }else {
            state[key] = e.target.value;
          }
        
        }      
      }
    }
    
    if (key == `exchId`) state.marketId = -1;
    
    if (this.props.alertType == 0 && key == `price`) {
      state.conditionalOperator = parseFloat(state.price) > this.props.lastPrice ? `GreaterThanOrEqual` : `LessThanOrEqual`;
    }

    this.setStateSafe(state, () => {
      this.updateTV();

      let activeExch = this.props.exchanges.filter((e) => e.exchId == this.state.exchId)[0],
        activeMkt = this.props.markets.filter((m) => {
          return m.exchId == this.state.exchId && m.marketId == this.state.marketId;
        })[0];

      if ((key == `exchId` || key == `marketId`) && !!activeExch && !!activeMkt) {
        this.props.getTickerData && this.props.getTickerData(activeExch, activeMkt, (price: number) => {
          this.setStateSafe({
            price: price ? toFixedDecimals(price, false, `price`, activeMkt) : 0
          });
        });
      }
    });

  }

  handleSubmit(e?: any, callback?: () => void): boolean {
    if (e) e.preventDefault();

    let market = this.props.markets.find((m) => m.exchId == this.state.exchId && m.marketId == this.state.marketId);
    if (!market) return false;

    // Volume alerts - percentage fix (UI says [N]% but backend requires [N / 100]%)
    //let percentFix = (this.props.alertType == 1 && this.state.conditionalOperator == `PercentChange`) ? (parseFloat(this.state.price) / 100).toString() : null;

    let body = {
      type: this.props.alertType,
      exchMktId: market.exchmktId,
      value: this.state.price,
      note: this.state.note,
      sound: this.props.sound,
      condition: this.state.conditionalOperator,
      interval: this.state.interval,
      numCandles: this.state.numCandles
    };


    if (!body.exchMktId) {
      this.setStateSafe({ reqMsg: this.props.t(`validMktExch`) });
      return false;
    }

    //delete body.reqMsg;

    alertApi.addAlert(body, (data) => {
      let alertType = this.props.alertType;
      if (data.success) {
        emitEvent(ALERT_ADDED, data.result);
        this.setStateSafe({
          ...this.getInitialState(true),
          numCandles: this.state.numCandles,
          alertType,
          reqMsg: this.props.t(`newAlertSuccess`)
        }, () => { 
          callback && callback();
          this.props.onRequestClose && this.props.onRequestClose();
        } );
      } else {
        this.setStateSafe({
          reqMsg: this.props.t(`newAlertError`)
        }, () => callback && callback());
      }
    });

    return false;
  }

  hasActiveMarket(): boolean {
    return !!this.props.active && !!this.props.active.market;
  }

  refreshCurrentPrice() {
    let active = this.props.active,
      lastPrice = this.props.lastPrice;

    if (active && lastPrice) {
      this.setStateSafe({
        price: toFixedDecimals(lastPrice, false, `price`, active.market)
      }, () => this.updateTV());
    } else {
      let activeExch = this.props.exchanges.filter((e) => e.exchId == this.state.exchId)[0],
        activeMkt = this.props.markets.filter((m) => {
          return m.exchId == this.state.exchId && m.marketId == this.state.marketId;
        })[0];

      if (!!activeExch && !!activeMkt) {
        this.props.getTickerData && this.props.getTickerData(activeExch, activeMkt, (price: number) => {
          this.setStateSafe({
            price: toFixedDecimals(price, false, `price`, activeMkt)
          });
        });
      }
    }
  }

  handlePopulateForm(e: any): void {
    let active = (this.props && this.props.active) ? this.props.active : { market: undefined, exchange: undefined };
    let price = e.detail.data.price;

    if(e.detail.data.type != 0){
      price = e.detail.data.value;
    }

    this.props.updateAlertType(e.detail.data.type);

    this.setStateSafe({
      conditionalOperator: e.detail.data.condition,
      price: toFixedDecimals(price, false, `price`, active.market),
      note: e.detail.data.note,
      sound: e.detail.data.sound,
      interval: e.detail.data.interval ? e.detail.data.interval : `1d`,
      numCandles: e.detail.data.numCandles ? e.detail.data.numCandles : 0
    }, () => {
      if (!this.props.active) return;
      this.updateTV();
    });
  }

  render() {
    return (
      <div className="new-alert fade-in">
        <div className="top">
          <div className="title">
            { this.props.t(`newAlert`) }
          </div>

          <div>
            <PillToggle
              options={ [
                {
                  value: 0,
                  label: `Price`,
                },
                { 
                  value: 1,
                  label: `Volume`,
                }
              ] }
              value={ this.props.alertType != undefined ? this.props.alertType : 0 }
              onChange={ (e, v) => this.props.updateAlertType(v) }/>
          </div>

        </div>
        {
          parseInt(this.props.alertType) == 0 &&
          <AlertForm
            hasActiveMarket={ this.hasActiveMarket() }
            exchanges={ this.props.exchanges }
            markets={ this.props.markets }
            exchId={ this.state.exchId }
            marketId={ this.state.marketId }
            price={ this.state.price }
            note={ this.state.note }
            sound={ this.state.sound }
            overrideAlertSound={ this.state.sound }
            conditionalOperator={ this.state.conditionalOperator }
            reqMsg={ this.state.reqMsg }
            onSubmit={ (e) => this.handleSubmit(e, () => this.updateTV()) }
            options={ this.props.options }
            onChange={ this.handleInputChange.bind(this) }
            refreshCurrentPrice={ this.refreshCurrentPrice.bind(this) }
            confirmText={ this.props.t(`newAlertConfirm`) }
            marketLabel={ this.props.marketLabel } />
        }
        {
          parseInt(this.props.alertType) == 1 &&
            <VolumeAlertForm
              hasActiveMarket={ this.hasActiveMarket() }
              exchanges={ this.props.exchanges }
              markets={ this.props.markets }
              exchId={ this.state.exchId }
              marketId={ this.state.marketId }
              price={ this.state.price }
              note={ this.state.note }
              sound={ this.state.sound }
              overrideAlertSound={ this.state.sound }
              conditionalOperator={ this.state.conditionalOperator }
              reqMsg={ this.state.reqMsg }
              onSubmit={ (e) => this.handleSubmit(e, () => this.updateTV()) }
              options={ this.props.options }
              interval={ this.state.interval }
              numCandles={ this.state.numCandles }
              onChange={ this.handleInputChange.bind(this) }
              confirmText={ this.props.t(`newAlertConfirm`) } />
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sound: state.markets.alertSound,
  alertType: state.markets.marketsAlertActiveType
});

export { NewAlert as PureNewAlert };
export default translate(`alerts`)(connect(mapStateToProps)(NewAlert));

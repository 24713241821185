// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import { getMarketPair } from '../../helpers/MarketPairHelper.js';
import { toFixedDecimalsHTML, optimizeTextForWidth, toFixedDown } from '../../helpers/NumberHelper.js';
import MarketUpArrow from '../../svgs/MarketUpArrow.jsx';
import MarketDownArrow from '../../svgs/MarketDownArrow.jsx';
import FavoritesStar from '../../svgs/FavoritesStar.jsx';
import FavoritesStarFilled from '../../svgs/FavoritesStarFilled.jsx';
import type { Market } from '../../types/Market.js';
import type { Exchange } from '../../types/Exchange.js';
import { adjustTradePrice } from '../../helpers/AdjustTradeHelper.js';
import { emitEvent, MARKETS_PRICE_CLICK } from '../../helpers/EventHelper.js';

//const contractExchs = [`DERI`];

type Props = {
  t: any,
  market: Market,
  exchange: Exchange,
  lastPrice: number,
  lastTradeType: string,
  marketDirection: number,
  isFavorite: boolean,
  toggleFavorite: Function,
  inlineMode: boolean,
  ticker: {
    volume: number,
    last: number,
    high: number,
    low: number,
    ask: number,
    bid: number
  },
  bestBid: number,
  bestAsk: number,
  handleCurrLogoClick: (currCode: string) => void,
  openPrice: number
};

const MarketHeader = (props: Props) => {
  const { t, market, exchange, lastPrice, lastTradeType, marketDirection, isFavorite, toggleFavorite, inlineMode,
    ticker, bestBid, bestAsk, handleCurrLogoClick, openPrice } = props;

  const percentage = openPrice > 0 ? parseFloat(toFixedDown(((lastPrice - openPrice) / openPrice) * 100, 2)) : 0;



  return inlineMode ? (
    <div className={ `market-header ${inlineMode ? `tabs-market-header` : ``}` } >
      <div className={ `currencies-section` } >
        <div className={ `quote-curr-logo` }>
          <img
            className={ `curr-logo` }
            src={ `${ window.WWW_URL }/assets/img/currency/${ market.quoteImageName }.png` } 
            height={ `20rem` } 
            onError={ 
              (e)=>{e.target.onerror = null; e.target.src=`https://www.coinigy.com/assets/img/currency/empty.png`;} 
            }
            onClick={ () => handleCurrLogoClick(getMarketPair(market).quote) } />
        </div>
        <div className={ `base-curr-logo` }>
          <img
            className={ `curr-logo` }
            src={ `${ window.WWW_URL }/assets/img/currency/${ market.baseImageName }.png` } 
            height={ `20rem` } 
            onError={ 
              (e)=>{e.target.onerror = null; e.target.src=`https://www.coinigy.com/assets/img/currency/empty.png`;} 
            }
            onClick={ () => handleCurrLogoClick(getMarketPair(market).base) } />
        </div>
        <div className={ `exch-icon` }>
          <a href={ `${ window.WWW_URL }/site/exchange/${ exchange.exchCode }/` } target={ `_blank` }>
            <img
              src={ `${ window.WWW_URL }/assets/img/exchange/${ exchange.exchCode }-icon.png` }
              height={ `20rem` } 
              className={  `exch-logo-icon`  } />
          </a>
        </div>
      </div>        
      <div className={ `market-name-section` }>
        <div>
          <span className={ `market-name-large` }>
            { getMarketPair(market).toString() }
          </span>
          <a onClick={ toggleFavorite } className={ isFavorite ? `is-favorite fav-star` : `fav-star` }>
            {
              isFavorite ?
                FavoritesStarFilled(`MarketsHeaderStar`) :
                FavoritesStar(`MarketsHeaderStar`)
            }
          </a>
        </div>
      </div>
      <div className={ `market-price-section` }>
        <span className={ `last-price` }>
          {
            lastPrice &&
            optimizeTextForWidth(
              toFixedDecimalsHTML(lastPrice, false, `price`, market, false, true),
              5.7,
              false,
              true,
              (
                <span
                  className={ marketDirection < 0 ? `last-price flash-red` : `last-price flash-green` }
                  dangerouslySetInnerHTML={
                    lastPrice ?
                      toFixedDecimalsHTML((exchange.exchCode == `LSCX` ? adjustTradePrice(lastPrice, lastTradeType) : lastPrice), false, `price`, market, false) :
                      { __html: t(`app:loading`) }
                  } />
              )
            )
          }
        </span>
      </div>
    </div>
  ) 
    : (
      <div className={ `market-header ${inlineMode ? `tabs-market-header` : ``}` } >
        <div className={ `currencies-section` } >
          <div className={ `quote-curr-logo` }>
            <img
              className={ `curr-logo` }
              src={ `${ window.WWW_URL }/assets/img/currency/${ market.quoteImageName }.png` } 
              height={ `30rem` } 
              onError={ 
                (e)=>{e.target.onerror = null; e.target.src=`https://www.coinigy.com/assets/img/currency/empty.png`;} 
              }
              onClick={ () => handleCurrLogoClick(getMarketPair(market).quote) } />
          </div>
          <div className={ `base-curr-logo` }>
            <img
              className={ `curr-logo` }
              src={ `${ window.WWW_URL }/assets/img/currency/${ market.baseImageName }.png` } 
              height={ `30rem` } 
              onError={ 
                (e)=>{e.target.onerror = null; e.target.src=`https://www.coinigy.com/assets/img/currency/empty.png`;} 
              }
              onClick={ () => handleCurrLogoClick(getMarketPair(market).base) } />
          </div>
          <div className={ `exch-icon` }>
            <a href={ `${ window.WWW_URL }/site/exchange/${ exchange.exchCode }/` } target={ `_blank` }>
              <img
                src={ `${ window.WWW_URL }/assets/img/exchange/${ exchange.exchCode }-icon.png` }
                height={ `22rem` } 
                className={  `exch-logo-icon`  } />
            </a>
          </div>
        </div>
        <div className={ `market-name-section` }>
          <div>
            <span className={ `market-name-large` }>
              { getMarketPair(market).toString() }
            </span>
            <a onClick={ toggleFavorite } className={ isFavorite ? `is-favorite fav-star` : `fav-star` }>
              {
                isFavorite ?
                  FavoritesStarFilled(`MarketsHeaderStar`) :
                  FavoritesStar(`MarketsHeaderStar`)
              }
            </a>
          </div>
          <div>
            <span className={ `exch-name` }>
              { exchange.exchName }
            </span>
            <span className={ `curr-name` }>
              { getMarketPair(market).toFullString() }
            </span>
          </div>
        </div>
        <div className={ `market-price-section` }>
          <span className={ `last-price` }>
            {
              lastPrice && 
              optimizeTextForWidth(
                toFixedDecimalsHTML(lastPrice, false, `price`, market, true, true),
                5.7,
                false,
                true,
                (
                  <span
                    className={ marketDirection < 0 ? `last-price flash-red` : `last-price flash-green` }
                    dangerouslySetInnerHTML={
                      lastPrice ?
                        toFixedDecimalsHTML((exchange.exchCode == `LSCX` ? adjustTradePrice(lastPrice, lastTradeType) : lastPrice), false, `price`, market, true) :
                        { __html: t(`app:loading`) }
                    } />
                )
              )
            }
          </span>
          {
            lastPrice && (
              <span className={ `percent-change ${percentage > 0 ? `green` : `red`}` }>
                {
                  percentage > 0 ? `+` : ``
                }
                { percentage }
              %&nbsp;
                { 
                  percentage >= 0 ? MarketUpArrow() : MarketDownArrow() 
                }
              </span>
            )
          }
          
        </div>
        <div className={ `ohlc-section` }>
          <div className={ `low-high` }>
            <div>
              <span className={ `high-label` }>
                { t(`app:high`) }
              </span>
              <span
                className={ `high-value` }
                dangerouslySetInnerHTML={
                  ticker.high > 0 ?
                    toFixedDecimalsHTML(ticker.high, false, `price`, market, false) :
                    { __html: `` }
                } />
            </div>
            <div>
              <span className={ `low-label` }>
                { t(`app:low`) }
              </span>
              <span
                className={ `low-value` }  
                dangerouslySetInnerHTML={
                  ticker.low > 0 ?
                    toFixedDecimalsHTML(ticker.low, false, `price`, market, false) :
                    { __html: `` }
                } />
            </div>
          </div>
          <div className={ `bid-ask` }>
            <div>
            
              <span
                className={ `bid-value` } onClick={ () => {
                  emitEvent(MARKETS_PRICE_CLICK, {
                    price: bestBid
                  });
                } }>
                {
                  bestBid && 
                  optimizeTextForWidth(
                    toFixedDecimalsHTML(bestBid, false, `price`, market, false, true),
                    8,
                    true,
                    false,
                    (
                      <span
                        className={ `` }
                        dangerouslySetInnerHTML={
                          bestBid ?
                            toFixedDecimalsHTML(bestBid, false, `price`, market, false) :
                            { __html: `` }
                        } />
                    )
                  )
                }
              </span>
              <span
                className={ `ask-value` } onClick={ () => {
                  emitEvent(MARKETS_PRICE_CLICK, {
                    price: bestAsk
                  });
                } }>
                {
                  bestAsk && 
                  optimizeTextForWidth(
                    toFixedDecimalsHTML(bestAsk, false, `price`, market, false, true),
                    8,
                    true,
                    false,
                    (
                      <span
                        className={ `` }
                        dangerouslySetInnerHTML={
                          bestAsk ?
                            toFixedDecimalsHTML(bestAsk, false, `price`, market, false) :
                            { __html: `` }
                        } />
                    )
                  )
                }
              </span>
            </div>
         
            <div>
              <span className={ `bid-label` }>
                { t(`app:bid`) }
              </span>
              <span className={ `ask-label` }>
                { t(`app:ask`) }
              </span>
            
            </div>
          </div>
          <div className={ `volume` }>
            <div>
              <span className={ `volume-base-label` }>
                { t(`app:vol`) }
              </span>
              <span
                className={ `volume-base` } >
                {
                  ticker.volume && 
                  optimizeTextForWidth(
                    toFixedDown(ticker.volume, ticker.volume > 1 ? 0 : 4).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `,`) + ` BTC`,
                    5,
                    true,
                    true,
                    (
                      <span
                        className={ `` }
                        dangerouslySetInnerHTML={
                          ticker.volume ?
                            { __html: toFixedDown(ticker.volume, ticker.volume > 1 ? 0 : 4).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `,`) + ` ` + getMarketPair(market).base } :
                            { __html: `` }
                        } />
                    )
                  )
                }
              </span>
            </div>
            <div>
              <span
                className={ `volume-quote` }>
                {
                  ticker.volume > -1 && ticker.high > 0 && ticker.low > 0 &&
                optimizeTextForWidth(
                  toFixedDown(ticker.volume * ((ticker.high + ticker.low) / 2), 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `,`) + ` USD`,
                  8,
                  true,
                  true,
                  (
                    <span
                      className={ `` }
                      dangerouslySetInnerHTML={
                        ticker.volume ?
                          { __html: toFixedDown(ticker.volume * ((ticker.high + ticker.low) / 2), 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `,`) + ` ` + getMarketPair(market).quote } :
                          { __html: `` }
                      } />
                  )
                )
                }
              </span>
            </div>
          </div>
        </div>
      </div>
    );
};

export { MarketHeader as PureMarketHeader };
export default translate(`markets`)(MarketHeader);

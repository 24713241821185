// @flow
'use strict';

import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import TextField from '../utilities/TextField.jsx';
import EntityTable from '../utilities/EntityTable.jsx';
import Search from '../../svgs/Search.jsx';
import type { ProfileProps } from './ProfileDataLayer.jsx';
import { toLocalDate } from '../../helpers/DateHelper.js';
import { formatMessageWithVars } from '../../helpers/NotificationHelper.js';

const AccountActivity = (props: ProfileProps) => {
  const [ filterText, setFilterText ] = useState<string>(``);
  const { t, activity } = props;
  const getClassName = (style: string) => {
    switch (style) {
    case `success`:
      return `green`;
    case `info`:
      return `blue`;
    default:
      return `red`;
    }
  };

  const columns = [
    {
      title: t(`app:time`),
      key: `time`,
      nosort: true,
      display: (row, time) => toLocalDate(time)
    },
    {
      title: t(`app:title`),
      key: `title`,
      nosort: true,
      display: (row) => (
        <span>
          <span className={ `big-bullet ${ getClassName(row.style) }` } >
            &bull;
          </span>
          { ` ` }
          { formatMessageWithVars(row.title, row.titleVars) }
        </span>
      )
    },
    {
      title: t(`message`),
      key: `message`,
      display: (row) => formatMessageWithVars(row.message, row.messageVars)
    }
  ];

  return (
    <div className="profile-table-data">
      <h1 className="page-title">
        { t(`header:activityLog`) }
      </h1>
      <section>
        <div>
          <div className="header">
            <div className="right-side">
              <div className="search">
                <TextField
                  icon={ Search }
                  label={ t(`app:search`) }
                  name={ `search` }
                  value={ filterText }
                  onChange={ (e) => setFilterText(e.target.value) } />
              </div>
            </div>
          </div>
          <EntityTable
            columns={ columns }
            data={ activity || [] }
            defaultSortBy="time"
            pageSize={ `all` }
            hidePagination={ true }
            filterText={ filterText } />
        </div>
      </section>
    </div>
  );
};

export default withRouter(AccountActivity);

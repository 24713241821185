// @flow
'use strict';

import React from 'react';

type Props = {
  currentStep: number,
  data: Array<string>,
  goToStep?: (n: number) => void
};

const StepNavigations  = ({ currentStep, data, goToStep }: Props) => {
  return (
    <div className="page-nav">
      <ul>
        {
          data.map((item, i) => (
            <li
              data-testid={ `step-nav-${i}` }
              key={ `step-${ i }-${ item }` }
              onClick={ () => {
                if (currentStep > i && goToStep) return goToStep(i);
              } }
              className={ 
                currentStep === i ? `active` : 
                  currentStep > i && goToStep ? `complete clickable` : 
                    currentStep > i ? `complete` : `` 
              }>
              {
                currentStep > i ? (
                  <span className="badge completed">
              &#10004;
                  </span>
                ) :
                  (
                    <span className="badge">
                      { i + 1 }
                    </span>
                  )
              }

              <span>
                { item }
              </span>
            </li>  
          ))
        }
      </ul>
    </div>
  );
};

export default StepNavigations;

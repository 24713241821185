// @flow
'use strict';

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { translate } from 'react-i18next';
import type { UserApplication } from '../../../types/UserApplication.js';
import { 
  emitEvent, 
  SHOW_BAKKT_ACCOUNT_MODAL
} from '../../../helpers/EventHelper.js';
import { getUserApplication } from '../../../helpers/UserApplicationsHelper';
import Wallet from '../../../svgs/Wallet.jsx';


type Props = {
  t: any,
  styleClass: string,
};

const BakktButton = (props: Props) => {
  const { t, styleClass } = props;


  const userApplications = useSelector((state) => state.userInfo.userApplications);
  const applicationsStatuses = useSelector((state) => state.userInfo.applicationsStatuses);

  const exchActionsClass = styleClass ? `exch-actions ${styleClass}` : `exch-actions`;

  const CGYUserApplication: UserApplication = useMemo(() => getUserApplication(`CGY`, userApplications, applicationsStatuses), [userApplications, applicationsStatuses]);

  return (
    <div className={ exchActionsClass }>
      {
        CGYUserApplication.isEligible && (
          <li className="subscription-status">
            {
              (CGYUserApplication.isEligible && CGYUserApplication.isAwaiting) && (
                <a rel="noopener noreferrer" onClick={ () => { emitEvent(SHOW_BAKKT_ACCOUNT_MODAL); } }>
                  <div className={ `sign-up-ls-crypto` }>
                    <div className={ `sign-up-wallet-icon` }>
                      { Wallet }
                    </div>
                    <div>
                      { t(`Create Wallet`) }
                    </div>
                  </div>
                </a>
              )
            }
          </li>
        )
      }
    </div>
  );
};

export { BakktButton as PureBakktButton };
export default translate(`user`)(BakktButton);

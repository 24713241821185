// @flow
'use strict';

import React, { useState, useEffect } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { getMarketPair } from '../../../helpers/MarketPairHelper.js';
import { getActiveTheme, translateHex } from '../../../helpers/ThemeHelper.js';
import { updateFavorites } from '../../../actions/app/updateFavorites.js';
import { pushToHistory } from '../../../actions/markets/pushToHistory.js';
import { toggleFavoriteFilter } from '../../../actions/markets/toggleFavoriteFilter.js';
import { setCurrentMarket } from '../../../actions/app/setCurrentMarket.js';
import { setMarketSwitcherColorMode } from '../../../actions/app/setMarketSwitcherColorMode.js';
import { changeMarket } from '../../../actions/markets/changeMarket.js';
import {
  emitEvent,
  listenForEvent,
  removeEventListener,
  PAGE_SHORTCUT,
  MARKET_CHANGE,
  ADVANCE_TOUR,
  SET_MARKET_FILTER,
  ADD_CHART_SYMBOL
} from '../../../helpers/EventHelper.js';
//import { Link, withRouter } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import * as userApi from '../../../helpers/api/UserApi';
import FavoritesStar from '../../../svgs/FavoritesStar.jsx';
import FavoritesStarFilled from '../../../svgs/FavoritesStarFilled.jsx';
import Info from '../../../svgs/Info.jsx';
import TextField from '../../utilities/TextField.jsx';
import ScrollableArea from "../../utilities/ScrollableArea.jsx";
import type { Market } from '../../../types/Market.js';
import type { Exchange } from '../../../types/Exchange.js';
import type { Favorite } from '../../../types/Favorite.js';
import type { Account } from "../../../types/Account.js";
import type { RedisPrefs } from "../../../types/RedisPrefs.js";
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { useHistory } from 'react-router-dom';
import { useVisibleMarkets } from './useVisibleMarkets';
import { useVisibleExchanges } from './useVisibleExchanges';
import { useActiveMarketIndex } from './useActiveMarketIndex';
import { useActiveExchangeIndex } from './useActiveExchangeIndex';
import MarketColorPicker from './../MarketColorPicker.jsx';
type Props = {
  t: any,
  useCallback?: boolean,
  callback?: *,
  className: string,
  exchanges: Array<Exchange>,
  markets: Array<Market>,
  accounts: Array<Account>,
  favorites: Array<Favorite>,
  redisPrefs: RedisPrefs,
  active: {
    exchange: Exchange,
    market: Market
  },
  updateFavorites: Function,
  pushToHistory: (e: string, m: string) => void,
  setCurrentMarket: (e: string, m: string) => void,
  marketSwitcherColorFilled: boolean,
  setMarketSwitcherColorMode: (b: boolean) => void,
  toggleFavoriteFilter: (b: boolean) => void,
  changeMarket: (b: boolean) => void,
  marketsAreClickable: boolean,
  favoritesOnly: boolean,
  platformId: number,
};

const getOptimizedTextColor = (r, g, b) => {
  const o = Math.round(((r * 299) + (g * 587) + (b * 114)) / 1000);
  return (o > 125) ? getActiveTheme().gray3 : getActiveTheme().body;
};

const MarketSwitcherComponent = (props: Props) => {
  const { t, useCallback, callback, className, exchanges, markets, accounts,
    favorites, redisPrefs, active, updateFavorites, pushToHistory, 
    setCurrentMarket, marketSwitcherColorFilled, 
    setMarketSwitcherColorMode, toggleFavoriteFilter, marketsAreClickable, 
    favoritesOnly, platformId, changeMarket
  } = props;

  const [activeExch, setActiveExch] = useState<string>(active.exchange.exchCode);
  const [filterText, setFilterText] = useState<string>(``);
  const [filterPopover, setFilterPopover] = useState<boolean>(false);
  const [activeExchMarket, setActiveExchMarket] = useState({});
  const [marketColorModalOpen, setMarketColorModalOpen] = useState<boolean>(false);
  const [marketColorModalMarket, setMarketColorModalMarket] = useState<string>(``);
  const visibleMarkets = useVisibleMarkets({ markets, filterText, favoritesOnly, favorites });
  const visibleExchanges = useVisibleExchanges({ visibleMarkets, exchanges });
  const activeMarketIndex = useActiveMarketIndex({ visibleMarkets, activeExchMarket });
  const activeExchangeIndex = useActiveExchangeIndex({ visibleExchanges, activeExchMarket });
  const history = useHistory();

  const handleClick = (url) => {
    history.push(url);
    changeMarket(true);
  };

  useEffect(() => {
    props.pushToHistory(activeExch, getMarketPair(props.active.market).toString());
    props.setCurrentMarket(activeExch, getMarketPair(props.active.market).toString());
  }, []);

  useEffect(() => {
    const LOCAL_STORAGE = `firstLogin`;
    const isLsUser = platformId === 1;
    const firstLogin =  isLsUser && localStorage.getItem(LOCAL_STORAGE);
    const isToggleFavorite = !firstLogin && isLsUser;
 
    isToggleFavorite && toggleFavoriteFilter(true);
    isToggleFavorite && localStorage.setItem(LOCAL_STORAGE, `true`);
    
  }, [platformId]);

  useEffect(() => {
    setActiveExch(active.exchange.exchCode);
  }, [active.exchange.exchCode]);

  useEffect(() => {
    setActiveExchMarket(active);
  }, [active.market.marketId, active.exchange.exchId]);

  useEffect(() => {
    // select first exchange when toggling favorites filter.
    if (favoritesOnly) {
      if (visibleExchanges.length > 0 && !visibleExchanges.map((e) => e.exchCode).includes(activeExch)) {
        setActiveExch(visibleExchanges[0].exchCode);
      }
    }
  }, [favoritesOnly]);

  const updateActiveExchFromMktChange = (e: any) => {
    if (!e || !e.detail || e.detail.split(`:`).length !== 2) return;
    const [exchCode, mktName] = e.detail.split(`:`);
    pushToHistory(exchCode, mktName);
    setCurrentMarket(active.market.exchCode, getMarketPair(active.market).toString());
    setActiveExch(exchCode);
  };

  useEffect(() => {
    listenForEvent(MARKET_CHANGE, updateActiveExchFromMktChange);
    return () => removeEventListener(MARKET_CHANGE, updateActiveExchFromMktChange);
  }, [MARKET_CHANGE, updateActiveExchFromMktChange, active]);

  const handleInputChange = (e: any, key: string) => {
    if (!e) return;
    if (key === `filterText` && e.target.value[e.target.value.length - 1] === `:`) {
      const exchCode = e.target.value.substring(0, e.target.value.length - 1);
      const filteredExchanges = exchanges.filter((e) => {
        return e.exchCode.toLowerCase() === exchCode.toLowerCase();
      });
      if (filteredExchanges.length === 1) {
        setActiveExch(filteredExchanges[0].exchCode);
      }
    }
    setFilterText(e.target.type === `checkbox` ? e.target.checked : e.target.value);
  };

  const changeActiveExchange = (activeExch: string) => {
    if (!activeExch || activeExch === ``) return false;
    setActiveExch(activeExch);
    return false;
  };

  const handleFilterEnter = () => {
    const m = visibleMarkets[0];

    if (m && marketsAreClickable) {
      history.push(`/markets/${ m.exchCode }/${ getMarketPair(m).toString() }`);
    }
  };

  const nextMarket = () => {
    if (marketsAreClickable) {
      const index = activeMarketIndex + 1;
      if (index <= visibleMarkets.length) {        
        const m = visibleMarkets[index];
        history.push(`/markets/${ m.exchCode }/${ getMarketPair(m).toString() }`);
      }
    }
  };

  const prevMarket = () => {
    if (marketsAreClickable) {
      const index = activeMarketIndex - 1;
      if (index > 0) {        
        const m = visibleMarkets[index];
        history.push(`/markets/${ m.exchCode }/${ getMarketPair(m).toString() }`);
      }
    }
  };

  const nextExchange = () => {
    if (marketsAreClickable) {
      const index = activeExchangeIndex + 1;
      if (index <= visibleExchanges.length) {
        const e = visibleExchanges[index];
        const m = visibleMarkets.filter((m) => m.exchCode === e.exchCode)[0];
        history.push(`/markets/${ m.exchCode }/${ getMarketPair(m).toString() }`);
      }
    }
  };

  const prevExchange = () => {
    if (marketsAreClickable) {
      const index = activeExchangeIndex - 1;
      if (index <= visibleExchanges.length) {
        const e = visibleExchanges[index];
        const m = visibleMarkets.filter((m) => m.exchCode === e.exchCode)[0];
        history.push(`/markets/${ m.exchCode }/${ getMarketPair(m).toString() }`);
      }
    }
  };

  const handleKeyboardShortcut = (e: any) => {
    if (!e || !e.detail || !e.detail.action) return;
    switch (e.detail.action) {
    case `FOCUS_MARKET_FILTER`:
      setTimeout(() => document.getElementById(`filter`)?.focus(), 1);
      break;
    case `GO_TO_NEXT_MARKET`:
      nextMarket();
      break;
    case `GO_TO_PREVIOUS_MARKET`:
      prevMarket();
      break;
    case `GO_TO_NEXT_EXCHANGE`:
      nextExchange();
      break;
    case `GO_TO_PREVIOUS_EXCHANGE`:
      prevExchange();
      break;
    }
  };

  useEffect(() => {
    listenForEvent(PAGE_SHORTCUT, handleKeyboardShortcut);
    return () => removeEventListener(PAGE_SHORTCUT, handleKeyboardShortcut);
  }, [PAGE_SHORTCUT, handleKeyboardShortcut, marketsAreClickable, visibleMarkets]);

  const handleSetMarketFilter = (e: any) => {
    setFilterText(e.detail);
    const exchFilter = e.detail.split(`:`)[0];
    const exchToSelect = visibleExchanges.filter((e) => {
      return e.exchCode === exchFilter; 
    });
    setActiveExch(exchToSelect[0].exchCode);
  };

  useEffect(() => {
    listenForEvent(SET_MARKET_FILTER, handleSetMarketFilter);
    return () => removeEventListener(SET_MARKET_FILTER, handleSetMarketFilter);
  }, [SET_MARKET_FILTER, handleSetMarketFilter, visibleExchanges]);

  const getMarketColor = (mkt: string) => {
    const mktColor = redisPrefs.mktColors.filter((m) => m.display_name === mkt)[0];
    if (mktColor) return mktColor.color;
    return ``;
  };

  const getOptimizedColor = (_color: string) => {
    if (!marketSwitcherColorFilled) return;

    if (_color[0] == `#`) {
      const color = translateHex(_color);

      return getOptimizedTextColor(color.r, color.g, color.b);
    } else if (_color[0] == `r`) {
      const color = _color.split(`(`)[1].split(`)`)[0].split(`,`);

      return getOptimizedTextColor(parseInt(color[0]), parseInt(color[1]), parseInt(color[2]));
    }

    return;
  };

  const toggleFavorite = (e: Market) => {
    if (!e) return;
    const isFavorite = favorites.filter((f) => {
      return f.exchCode === e.exchCode && f.displayName === e.displayName;
    }).length > 0;

    if (isFavorite) {
      userApi.deleteFavorite({ exchmktId: e.exchmktId }, (data) => {
        data.success && updateFavorites(data.result);
      });
    } else {
      userApi.addFavorite({ body: { exchmktId: e.exchmktId } }, (data) => {
        data.success && updateFavorites(data.result);
      });
    }
  };

  const handleContextMenuClick = (e: any, { market: m, action }: { market: Market, action: string } = {}) => {
    if (!m || !action) return;
    switch (action) {
    case `nav_new_tab`:
      window.open(`/markets/${ m.exchCode }/${ getMarketPair(m).toString() }`, `_blank`);
      break;
    case `nav_this_tab`:
      if (!marketsAreClickable) return false;
      history.push(`/markets/${ m.exchCode }/${ getMarketPair(m).toString() }`);
      break;
    case `assign_color`:
      setMarketColorModalOpen(true);
      setMarketColorModalMarket(getMarketPair(m).toString());
      break;
    case `toggle_favorites`:
      toggleFavorite(m);
      break;
    case `set_color_mode`:
      setMarketSwitcherColorMode(!marketSwitcherColorFilled);
      break;
    case `add_symbol_to_chart`:
      addSymbolToChart(m);
      break;
    }
  };

  const addSymbolToChart = (m: any) => {
    emitEvent(ADD_CHART_SYMBOL, m);
  };

  return (
    <>
      <div className={ 
        `market-switcher fade-in ${ className } ${ marketSwitcherColorFilled ? `fill-mode` : `` }` 
      }>
        <div className="filter-row">
          <label htmlFor="favoritesOnly" className={ favoritesOnly ? `active` : `` }>
            <input
              type="checkbox"
              id="favoritesOnly"
              checked={ favoritesOnly }
              onChange={ (e) => toggleFavoriteFilter(e.target.checked) } />
            <div>
              {
                !favoritesOnly ?
                  FavoritesStar(`MarketSwitcher`) :
                  FavoritesStarFilled(`MarketSwitcher`)
              }
            </div>
          </label>
          <TextField
            onDoubleClick={ () => handleInputChange({ target: { value: `` } }, `filterText`) }
            label={ t(`filter`) }
            name="filter"
            value={ filterText }
            compact={ true }
            onChange={ (e) => handleInputChange(e, `filterText`) }
            onEnter={ () => handleFilterEnter() }/>
          <Popover
            isOpen={ filterPopover }
            positions={ [ `right` ] }
            content={ ({ position, childRect, popoverRect }) => (
              <ArrowContainer 
                position={ position }
                childRect={ childRect }
                popoverRect={ popoverRect }
                arrowColor={ `var(--gray-1)` }>
                <div className={ `popover-content` }>
                  { t(`user:filterPopover`) }
                </div>
              </ArrowContainer>
            ) }>
            <label
              className="filter-popover" 
              onMouseEnter={ () => setFilterPopover(true) }
              onMouseLeave={ () => setFilterPopover(false) }>
              { Info }
            </label>
          </Popover>
        </div>
        <div className="list-row">
          <ScrollableArea>
            <ul>
              {
                visibleExchanges.map((e) => (
                  <li key={ e.exchId }>
                    <a
                      onClick={ () => changeActiveExchange(e.exchCode) }
                      className={ `exch-link ` + 
                          `${ active.exchange.exchCode === e.exchCode ? `current` : `` } ` +
                        `${ activeExch === e.exchCode ? `active` : `` } ` + (
                        accounts.find((a) => a.authExchId === e.exchId && a.authTrade) ? 
                          `trading-enabled` : 
                          ``
                      )
                      }>
                      <img
                        className={ `exch-img` }
                        src={ window.WWW_URL + `/assets/img/exchange/` + e.exchCode + `-icon.png` } 
                        width={ `15rem` }
                        height={ `15rem` }/>
                      <div className={ `exch-container` }>
                        <span className={ `exch-code` }>
                          { e.exchCode }
                        </span>
                        <br/>
                        <span className={ `exch-name` }>
                          { e.exchName }
                        </span>
                      </div>
                    </a>
                  </li>
                ))
              }
            </ul>
          </ScrollableArea>
          <ScrollableArea>
            <ul>
              {
                visibleMarkets.filter((m) => m.exchCode === activeExch).map((m: Market) => (
                  <li key={ m.exchmktId }>
                    <ContextMenuTrigger
                      id="MarketSwitcherActions"
                      name={ `${ m.exchCode }/${ getMarketPair(m).toString() }` }
                      collect={ () => ({ market: m }) }
                      className="market-switcher-context-menu">
                      {

                        useCallback ? (
                          <a
                            onClick={ () => callback && callback(m.exchCode, m.marketName) }
                            className={  `curr-link ${ active.market.exchmktId === m.exchmktId ? `active` : `` } ` }
                            style={ 
                              getMarketColor(getMarketPair(m).toString()).length > 0 ? {
                                [(marketSwitcherColorFilled ? `backgroundColor` : `borderRightColor`).toString()]:
                                getMarketColor(getMarketPair(m).toString()),
                                color: getOptimizedColor(getMarketColor(getMarketPair(m).toString()))
                              } : undefined
                            }>
                            <img
                              className={ `curr-img` }
                              src={ 
                                m.baseHasImage ? window.WWW_URL + `/assets/img/currency/${m.baseImageName}.png` : window.WWW_URL + `/assets/img/currency/empty.png` 
                              }
                              onError={ 
                                (e)=>{e.target.onerror = null; e.target.src=window.WWW_URL + `/assets/img/currency/empty.png`;} 
                              }
                              width={ `15rem` }
                              height={ `15rem` } />
                            <div className={ `curr-container` }>
                              <span className={ `curr-code` }>
                                { getMarketPair(m).toString() }
                              </span>
                              <br/>
                              <span
                                className={ `curr-name` }  style={ 
                                  getMarketColor(getMarketPair(m).toString()).length > 0 ? {
                                    [(marketSwitcherColorFilled ? `backgroundColor` : `borderRightColor`).toString()]:
                                    getMarketColor(getMarketPair(m).toString()),
                                    color: getOptimizedColor(getMarketColor(getMarketPair(m).toString()))
                                  } : undefined
                                }>
                                { m.baseCurrName }
                              </span>
                            </div>
                          </a>
                        ) : (
                          <a
                            rel="noopener noreferrer"
                          
                            className={ `curr-link` + 
                              `${ active.market.exchmktId == m.exchmktId ? ` active` : 
                                ` ${ marketsAreClickable ? `` : ` disabled`}`}`
                            }
                            style={
                              getMarketColor(getMarketPair(m).toString()).length > 0 ? {
                                [(marketSwitcherColorFilled ? `backgroundColor` : `borderRightColor`).toString()]:
                                getMarketColor(getMarketPair(m).toString()),
                                color: getOptimizedColor(getMarketColor(getMarketPair(m).toString()))
                              } : undefined
                            }
                            onClick={ () => {
                              if (m && m.exchCode === `LSCX` && m.displayName === `BTC/USD`) {
                                emitEvent(ADVANCE_TOUR);
                              }
                              if (marketsAreClickable) {
                                handleClick(`/markets/${ m.exchCode }/${ getMarketPair(m).toString() }`);
                              } else {
                                handleClick(`#`);
                              }
                            } }>
                            { /*
                            TODO: add market SVG image 
                            <img src={ `https://www.coinigy.com/s/appsvg/${ m.exchCode }/${ getMarketPair(m).toString() }/1Y` } />
                            */ }
                            <img
                              className={ `curr-img` }
                              src={ 
                                m.baseHasImage ? window.WWW_URL + `/assets/img/currency/${m.baseImageName}.png` : window.WWW_URL + `/assets/img/currency/empty.png` 
                              }
                              width={ `15rem` }
                              height={ `15rem` } />
                            <div className={ `curr-container` }>
                              <span className={ `curr-code` }>
                                { getMarketPair(m).toString() }
                              </span>
                              <br/>
                              <span
                                className={ `curr-name` }  style={
                                  getMarketColor(getMarketPair(m).toString()).length > 0 ? {
                                    [(marketSwitcherColorFilled ? `backgroundColor` : `borderRightColor`).toString()]:
                                getMarketColor(getMarketPair(m).toString()),
                                    color: getOptimizedColor(getMarketColor(getMarketPair(m).toString()))
                                  } : undefined
                                }>
                                { m.baseCurrName }
                              </span>
                            </div>
                          </a>
                        )
                      }
                    </ContextMenuTrigger>
                  </li>
                ))
              }
            </ul>
          </ScrollableArea>
        </div>
      </div>
      <ContextMenu id="MarketSwitcherActions" className="market-switcher-context-menu">
        <MenuItem
          onClick={ handleContextMenuClick.bind(this) }
          data={ { action: `add_symbol_to_chart` } }>
          { t(`addSymboltoChart`) }
        </MenuItem>
        <MenuItem
          onClick={ handleContextMenuClick.bind(this) }
          data={ { action: `nav_new_tab` } }>
          { t(`openInNewTab`) }
        </MenuItem>
        <MenuItem
          onClick={ handleContextMenuClick.bind(this) }
          data={ { action: `nav_this_tab` } }>
          { t(`openInCurrentTab`) }
        </MenuItem>
        <MenuItem divider />
        <MenuItem
          onClick={ handleContextMenuClick.bind(this) }
          data={ { action: `assign_color` } }>
          { t(`assignColor`) }
        </MenuItem>
        <MenuItem
          onClick={ handleContextMenuClick.bind(this) }
          data={ { action: `toggle_favorites` } }>
          { t(`toggleFavorite`) }
        </MenuItem>
        <MenuItem
          onClick={ handleContextMenuClick.bind(this) }
          data={ { action: `set_color_mode` } }>
          {
            marketSwitcherColorFilled ? 
              t(`shrinkAssignedColors`) :
              t(`expandAssignedColors`)
          }
        </MenuItem>
      </ContextMenu>
      {
        marketColorModalOpen && marketColorModalMarket.length > 0 && (
          <MarketColorPicker 
            marketDisplayName={ marketColorModalMarket } 
            onClose={ () => {
              setMarketColorModalOpen(false); 
              setMarketColorModalMarket(``); 
            } }/>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  redisPrefs: state.redisPrefs,
  favorites: state.app.favorites,
  marketSwitcherColorFilled: state.app.marketSwitcherColorFilled,
  marketsAreClickable: state.browser.marketsAreClickable,
  favoritesOnly: state.markets.favoriteFilter,
  platformId: state.userInfo.user.platformId,
});

const mapDispatchToProps = (dispatch) => ({
  updateFavorites: (favorites) => dispatch(updateFavorites(favorites)),
  pushToHistory: (e, m) => dispatch(pushToHistory(e, m)),
  setCurrentMarket: (e, m) => dispatch(setCurrentMarket(e, m)),
  setMarketSwitcherColorMode: (b) => dispatch(setMarketSwitcherColorMode(b)),
  toggleFavoriteFilter: (b) => dispatch(toggleFavoriteFilter(b)),
  changeMarket: (b) => dispatch(changeMarket(b))
});

export { MarketSwitcherComponent as PureMarketSwitcherComponent };
export default withRouter(translate(`markets`)(connect(mapStateToProps, mapDispatchToProps)(MarketSwitcherComponent)));

// @flow
'use strict';

import React from 'react';
import { ToolPanelFilterItem } from '../DataTable';

export const ToolPanelFilter = ({ table, setColumnFilterFn }: any) => {
  return (
    <div className='tool-panel-wrapper'>
      <div>
        <h3>
          { `Filters` }
        </h3>

        { table.getAllLeafColumns().map((column) => {
          if (column.columnDef.header() && column.getCanFilter()) {
            return (<ToolPanelFilterItem key={ column.id } column={ column } setColumnFilterFn={ setColumnFilterFn } />);
          }
        })
        }
      </div>
    </div>
  );
};

// @flow
'use strict';

import React, { useState, useEffect, useRef } from 'react';
import { translate } from 'react-i18next';
import Search from '../../svgs/Search.jsx';
import ArrowDown from '../../svgs/ArrowDown.jsx';
import ArrowUp from '../../svgs/ArrowUp.jsx';
import ScrollableArea from "../utilities/ScrollableArea.jsx";
import { listenForEvent, removeEventListener, ENTER } from '../../helpers/EventHelper.js';

type Props = {
  t: any,
  options: Array<{
    label: any,
    value: any,
    compareValue?: any,
    icon?: any,
    onClick?: (e: any, value: any) => void
  }>,
  label: string,
  name: string,
  value: any,
  defaultText?: string,
  searchable?: boolean,
  hideValue?: boolean,
  disabled?: boolean,
  onChange: (e: any, value: string) => void,
  icon?: any,
  iconOfLabel?: boolean,
  useSpan?: boolean,
  isLeftOfInput?: boolean,
  hintText?: string,
  renderOption?: (option: any, index: number, acc: Array<any>, defaultProps: any) => any,
  hideCaret?: boolean,
  maxOptions?: number
};

const SelectField = (props: Props) => {
  const { t, options, label, name, defaultText, searchable, hideValue, disabled, onChange, icon, iconOfLabel, useSpan, isLeftOfInput, hintText, renderOption, hideCaret = false, maxOptions } = props;
  let { value } = props;
  const [filterText, setFilterText] = useState(``);
  const [open, setOpen] = useState(false);
  const [searchFieldFocused, setSearchFieldFocused] = useState(false);
  const [searchFieldDirty, setSearchFieldDirty] = useState(false);

  const searchRef = useRef<HTMLInputElement | null>(null);
  const optionsRefs = useRef([]);

  // prevent errors when a null value is present
  if (value === null || value === undefined) {
    value = ``;
  }

  useEffect(() => {
    listenForEvent(ENTER, handleEnterEvent);
    return () => {
      removeEventListener(ENTER, handleEnterEvent);
    };
  }, []);

  useEffect(() => {
    if (open === true) {
      let selectedIndex = options.findIndex((o) => {
        if (o.compareValue) return o.compareValue == value;
        return o.value == value;
      });

      if (selectedIndex > -1) return optionsRefs.current[selectedIndex]?.focus();

      if (searchable) searchRef.current?.focus();
    }
  }, [open]);

  const handleEnterEvent = () => {
    if (searchFieldFocused) handleSearchFieldKeyPress({ key: `Enter` });
  };

  const openDropdown = () => {
    if (disabled) return false;
    setOpen(true);
  };

  const closeDropdown = () => {
    setOpen(false);
  };

  const handleSearchFieldInputChange = (e: any) => {
    setFilterText(e.target.value);
    setSearchFieldDirty(e.target.value.length > 0);
  };

  const handleSearchFieldFocus = () => {
    setSearchFieldFocused(true);
  };

  const handleSearchFieldBlur = () => {
    setSearchFieldFocused(false);
  };

  const handleSearchFieldKeyPress = (e: any) => {
    return e.key == `Enter` ? optionsRefs.current[0]?.click() : true;
  };

  const handleOnChange = (v: any, e: any) => {
    setOpen(false);
    setFilterText(``);
    setSearchFieldFocused(false);
    setSearchFieldDirty(false);
    onChange(e, v);
  };

  const getFilteredOptions = () => {
    return options.filter((o) => {
      if (filterText.length == 0) return [];

      if (o.label.toLowerCase().indexOf(filterText.toLowerCase()) > -1) {
        return true;
      }

      return o.value.toString().toLowerCase().indexOf(filterText.toLowerCase()) > -1;
    });
  };

  let _value = t(`select`);

  if (defaultText) {
    _value = defaultText;
  }

  if (value.toString().length > 0) {
    let selected = options.find((o) => {
      if (o.compareValue) return o.compareValue == value;
      return o.value == value;
    });
    if (selected) _value = !iconOfLabel ? selected.label : selected.icon;
  }

  let hasHint = hintText !== undefined ? true : false;

  return (
    <div
      className={ `select-field ${open ? `focused` : ``} ` +
        `${(typeof value == `string` ? value.length > 0 :
          value >= 0) ? `dirty` : ``
        } ` +
        `${disabled ? `disabled` : ``} ${isLeftOfInput ? `select-field-left` : ``}` }>
      <label
        htmlFor={ name }
        onClick={ () => openDropdown() }>
        {
          label.length > 0 ? (
            <span className="label">
              { label }
            </span>
          ) : ``
        }
        {
          (!hideValue && !icon) && (
            <input
              data-testid={ `read-only-input` }
              type="text"
              name={ name }
              id={ name }
              value={ _value }
              readOnly={ true }
              onFocus={ () => openDropdown() } />
          )
        }
        { icon }
        { iconOfLabel && _value }
        { !hideCaret &&
          <span className="caret">
            { open ? ArrowUp : ArrowDown }
          </span>
        }
      </label>
      {
        open ? (
          <div>
            <div
              className="select-field__dropdown-backdrop"
              data-testid="select-field__dropdown-backdrop"
              onClick={ () => closeDropdown() } />
            <div className="select-field__dropdown">
              <ScrollableArea>
                {
                  searchable ? (
                    <label
                      htmlFor={ `selectFieldSearch-${name}` }
                      className={ `selectFieldSearch ${searchFieldFocused ? `focused` : ``} ` +
                        `${searchFieldDirty ? `dirty` : ``}` }>
                      <span className="icon">
                        { Search }
                      </span>
                      <input
                        type="text"
                        data-testid={ `search-input` }
                        ref={ searchRef }
                        name={ `selectFieldSearch-${name}` }
                        id={ `selectFieldSearch-${name}` }
                        placeholder={ `${t(`search`)}...` }
                        value={ filterText }
                        onKeyDown={ (e) => {
                          if (e.key == `ArrowDown` || e.key == `ArrowUp`) {
                            e.preventDefault();
                            e.stopPropagation();
                            let o = getFilteredOptions();

                            if (o.length == 0) return false;

                            if (e.key == `ArrowDown`) {
                              optionsRefs.current[0]?.focus();
                            } else {
                              optionsRefs.current[o.length - 1]?.focus();
                            }

                            return false;
                          }
                        } }
                        onKeyPress={ (e) => handleSearchFieldKeyPress(e) }
                        onChange={ (e) => handleSearchFieldInputChange(e) }
                        onFocus={ () => handleSearchFieldFocus() }
                        onBlur={ () => handleSearchFieldBlur() } />
                    </label>
                  ) : ``
                }
                <ul>
                  {
                    getFilteredOptions().map((o, i, a) => {
                      let isAction = o.value < -1;
                      let classes = (o.compareValue ? (o.compareValue == value) : o.value === value)
                        ? `selected`
                        : ``;

                      classes += isAction ? ` actionLink` : ``;


                      let props = {
                        ref: (el) => { optionsRefs.current[i] = el; },
                        onClick: (e) => handleOnChange(o.value, e),
                        onKeyDown: (e) => {
                          if (e.key == `ArrowUp`) {
                            e.preventDefault();
                            e.stopPropagation();
                            if (i == 0) {
                              if (searchable) searchRef.current?.focus();
                              return false;
                            }

                            optionsRefs.current[i - 1]?.focus();
                            return false;
                          } else if (e.key == `ArrowDown`) {
                            e.preventDefault();
                            e.stopPropagation();
                            if (i + 1 == a.length) {
                              if (searchable) searchRef.current?.focus();
                              return false;
                            }

                            optionsRefs.current[i + 1]?.focus();
                            return false;
                          }
                        },
                        onKeyPress: (e) => e.key == `Enter` ? handleOnChange(o.value, e) : true,
                        tabIndex: 0,
                        className: classes
                      };

                      return (
                        <li key={ i }>
                          {

                            renderOption ?
                              renderOption(o, i, options, props)
                              : useSpan ? (
                                <span { ...props }>
                                  {
                                    o.icon ? (
                                      <span className="icon">
                                        { o.icon }
                                      </span>
                                    ) : ``
                                  }
                                  { o.label }
                                </span>
                              ) : (
                                <a { ...props }>
                                  {
                                    o.icon ? (
                                      <span className="icon">
                                        { o.icon }
                                      </span>
                                    ) : ``
                                  }
                                  { o.label }
                                </a>
                              )
                          }
                        </li>
                      );
                    })
                      .slice(0, maxOptions )
                  }
                </ul>
              </ScrollableArea>
            </div>
          </div>
        ) : ``
      }
      {
        hasHint ? (
          <span className="hintText">
            { hintText }
          </span>
        ) : ``
      }
    </div>
  );
};

export { SelectField as PureSelectField };
export default translate(`app`)(SelectField);

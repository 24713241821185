// @flow
'use strict';

import React from 'react';

type Props = {
  options: Array<{
    label: string,
    value: any,
    compareValue?: any,
    disabled?: any,
  }>,
  value: any,
  disabled?: boolean,
  onChange: (e: any, value: string) => void,
  optionalClass?: string
};

const PillToggle = ({ options, value, onChange, disabled, optionalClass }: Props) => {
  let props = { };

  if (disabled) {
    props.disabled = `disabled`;
  }

  return (
    <div className={ `pill-toggle ${ disabled ? `disabled` : `` } ${ optionalClass ? optionalClass : `` }` }>
      {
        options.map((o) => (
          <button
            key={ o.compareValue ? o.compareValue : o.value }
            onClick={ (e) => onChange(e, o.value) }
            className={ `pill ${ (o.compareValue ? (o.compareValue == value) : (o.value == value)) ? `active` : `` }` }
            disabled={ o.disabled }
            { ...props }>
            { o.label }
          </button>
        ))
      }
    </div>
  );
};

export default PillToggle;

// @flow
'use strict';
import React, { useEffect, useState, useRef } from 'react';
import { translate } from 'react-i18next';
import { Layout, Model, Actions } from 'flexlayout-react';
import MarketHeader from './MarketHeader.jsx';
import MarketSwitcher from './MarketSwitcher/MarketSwitcher.jsx';
import MarketSearch from './MarketSearch';
import MarketChart from './MarketChart.jsx';
import MarketOrders from './MarketOrders.jsx';
import BalancesOrdersHistory from './BalancesOrdersHistory.jsx';
import MarketVerticalDepthChart from './MarketVerticalDepthChart.jsx';
import StreamingTradesList from './StreamingTradesList.jsx';
import NewOrder from '../orders/NewOrder.jsx';
import NewAlert from "../alerts/NewAlert.jsx";
import MarketAlerts from './MarketAlerts.jsx';
import MarketInsights from './MarketInsights.jsx';
import MarketNotes from './MarketNotes.jsx';
import { getMarketPair } from '../../helpers/MarketPairHelper.js';
import { toFixedDecimals } from '../../helpers/NumberHelper.js';
import {
  emitEvent,
  listenForEvent,
  removeEventListener,
  MARKET_BUY_MODE_DEACTIVATED,
  MARKET_SELL_MODE_DEACTIVATED,
  MARKET_ALERT_MODE_DEACTIVATED,
  DELETE_BUY_NODE,
  DELETE_BUY_STOP_NODE,
  DELETE_SELL_NODE,
  DELETE_SELL_STOP_NODE,
  BUY_MODE_REQUESTED,
  SELL_MODE_REQUESTED,
  ALERT_MODE_REQUESTED,
  WINDOW_RESIZED
} from '../../helpers/EventHelper.js';
import { getLayout, putLayout } from '../../helpers/api/UserApi.js';
import Markets from '../../svgs/Markets.jsx';
import { xlSize, smallSize } from '../../constants/marketsFlexlayoutDefault.js';
import 'flexlayout-react/style/dark.css';

const MarketFlexlayout = (props: any) => {
  const [model, setModel] = useState(null);
  const [adjustSplitTimestamp, setAdjustSplitTimestamp] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const layoutRef = useRef();

  const marketLabel = props.active?.market && props.active?.exchange?.exchCode ? `${ props.active.exchange.exchCode } ${ getMarketPair(props.active.market).toString() }` : ``;

  const isSmall = props.size.indexOf(`xl`) < 0;

  const onModelChange = (model) => {
    if (!isSmall) {
      const id = setTimeout(() => {
        const jsonText = JSON.stringify(model.toJson());
        putLayout(`markets_layout`, { payload: jsonText });
      }, 800);

      // $FlowIgnore: suppressing this error
      setTimeoutId(id);
    }
  };

  const onAction = (action) => {
    if (action.type === `FlexLayout_SelectTab`) {
      const tabNode = model?.getNodeById(action.data.tabNode);
      const tabsetChildren = tabNode?.getParent()?.getChildren();
      const selectedTab = tabNode?.getComponent();
      const hasTradesTab = tabsetChildren?.some((tab) => tab?.getComponent() === `market-trades`);
      const hasAlertsTab = tabsetChildren?.some((tab) => tab?.getComponent() === `market-trades`);

      if (selectedTab !== `market-trades` && hasTradesTab) {
        emitEvent(MARKET_BUY_MODE_DEACTIVATED);
        emitEvent(MARKET_SELL_MODE_DEACTIVATED);

        // remove on-chart order markers if any tab other than 'Trade' is visible
        emitEvent(DELETE_BUY_NODE);
        emitEvent(DELETE_BUY_STOP_NODE);
        emitEvent(DELETE_SELL_NODE);
        emitEvent(DELETE_SELL_STOP_NODE);
      } 
      
      if (selectedTab !== `market-alerts` && hasAlertsTab) {
        emitEvent(MARKET_ALERT_MODE_DEACTIVATED);
      }

      if (selectedTab === `market-time-sales`) setAdjustSplitTimestamp(new Date().getTime());

    } else if (action.type === `FlexLayout_AdjustSplit`) {
      const tabNode = model?.getNodeById(`market-time-sales`);
      if (tabNode?.isVisible()) setAdjustSplitTimestamp(new Date().getTime());
    }

    return action;
  };

  const titleFactory = (node) => {
    const component = node.getComponent();

    if (component === `market-switcher`) {
      return {
        titleContent: <span className='market-switcher-button'>
          { Markets }
          { `Search` }
        </span>
      };
    } else if (component === `market-header`) {
      return {
        titleContent: marketLabel
      };
    } else if (component === `market-time-sales`) {
      // Return "Time & Sales" if the market is not on CGY, otherwise return "Live Quotes"
      return {
        titleContent: props.active?.exchange?.exchCode && props.active.exchange.exchCode === `CGY` ? `Live Quotes` : `Time & Sales`,
      };
    }
  };

  const factory = (node) => {
    const component = node.getComponent();

    if (component === `market-switcher`) {
      const MarketSwitcherComponent = props.marketSwitcherVersion === `v1` ? MarketSwitcher : MarketSearch;
      return <MarketSwitcherComponent
        className={ `is-flexlayout` }
        exchanges={ props.exchanges }
        markets={ props.markets }
        accounts={ props.accounts }
        favorites={ props.favorites }
        active={ props.active } 
        useHeight={ true }
        height={ node.getRect()?.height }/>;
    } else if (component === `market-tv-chart`) {
      return <MarketChart
        active={ props.active }
        activeAlerts={ props.activeAlerts }
        bid={ props.bid }
        ask={ props.ask }
        tvcOrderHistory={ props.tvcOrderHistory } 
        tvcOpenOrders={ props.tvcOpenOrders }
        chartClass={ props.chartClass } />;
    } else if (node.isVisible() && component === `market-depth-chart`) {
      return <MarketOrders
        marketSwitcherOpen={ false }
        marketInfoOpen={ false }
        showLarger={ true }
        active={ props.active }
        markets={ props.markets }
        buys={ props.buys }
        sells={ props.sells }
        isFull={ true }
        openOrders={ props.openOrders }
        activeAlerts={ props.activeAlerts }
        orderHistory={ props.orderHistory }
        balances={ props.balances }
        accounts={ props.accounts }
        exchanges={ props.exchanges }
        deleteOrder={ props.deleteOrder }
        currentAuthId={ props.currentAuthId }
        activeTab={ props.activeOrdersTab }
        showDepthOrdersAlerts={ props.showDepthOrdersAlerts }
        toggleShowDepthOrdersAlerts={ props.toggleShowDepthOrdersAlerts }
        lastPrice={ props.lastPrice }
        lastTradeType={ props.lastTradeType }
        width={ node.getRect()?.width }
        height={ node.getRect()?.height * .7 }/>;
    } else if (node.isVisible() && component === `market-header`) {
      return <MarketHeader
        exchange={ props.active.exchange }
        market={ props.active.market }
        isFavorite={ props.isFavorite }
        toggleFavorite={ props.toggleFavorite }
        ticker={ props.ticker }
        lastPrice={ props.lastPrice }
        lastTradeType={ props.lastTradeType }
        marketDirection={ props.marketDirection }
        inlineMode={ node.getRect()?.width < 500 || node.getRect()?.height < 120 }
        abbreviate={ props.abbreviateHeader }
        bestBid={ props.bestBid }
        bestAsk={ props.bestAsk }
        handleCurrLogoClick={ props.handleCurrLogoClick }
        openPrice={ props.openPrice }/>;
    } else if (node.isVisible() && component === `balances-orders-history`) {
      return <BalancesOrdersHistory
        marketSwitcherOpen={ props.marketSwitcherOpen }
        toggleOpen={ props.toggleOpen }
        showLarger={ props.showLarger }
        active={ props.active }
        markets={ props.markets }
        activeBOHTab={ props.activeBOHTab }
        updateBOHTab={ props.updateBOHTab }
        isFull={ props.isFull }
        exchFilter={  props.exchFilter }
        mktFilter={  props.mktFilter }
        authFilter={  props.authFilter }
        getCurrentAuthIdValue={ props.getCurrentAuthIdValue }
        updateUserOrderFilters={ props.updateUserOrderFilters }
        deleteOrder={ props.deleteOrder }
        balances={ props.balances }
        accounts={ props.accounts }
        exchanges={ props.exchanges }
        currentAuthId={ props.currentAuthId } 
        height={ node.getRect()?.height }
        ordersLastUpdateTime={ props.ordersLastUpdateTime }
        refreshOrdersLastUpdateTime={ props.refreshOrdersLastUpdateTime }/>;
    } else if (node.isVisible() && component === `market-time-sales`) {
      return <div className={ `market-time-sales ${node.getRect()?.width < 480 ? `small` : `` }` }>
        {
          props.buys?.length > 0 && props.sells?.length > 0 && (
            <MarketVerticalDepthChart
              market={ props.active && props.active.market }
              buys={ props.buys }
              sells={ props.sells }
              zeroStyle={ props.zeroStyle }
              depthStyle={ props.depthStyle }
              theme={ props.theme } 
              openOrders={ props.openOrders } />
          )
        }
        <StreamingTradesList
          market={ props.active && props.active.market }
          trades={ props.trades }
          zeroStyle={ props.zeroStyle } 
          exchanges={ props.exchanges }/>
      </div>;
    } else if (node.isVisible() && component === `market-trades`) {
      return <NewOrder
        markets={ props.markets }
        exchanges={ props.exchanges }
        currencies={ props.currencies }
        active={ props.active }
        lastPrice={ props.lastPrice }
        marketLabel={ marketLabel }
        accounts={ props.accounts }
        balances={ props.balances }
        options={ Object.keys(props.ticker).filter((t) => t !== `volume`).map((k) => ({
          label: `${ props.t(`app:${ k }`) } (${ toFixedDecimals(props.ticker[k], true, `price`, props.active.market) })`,
          value: toFixedDecimals(props.ticker[k], true, `price`, props.active.market)
        })) }
        currentAuthId={ props.currentAuthId }
        setCurrentAccount={ props.setCurrentAccount }
        orderFormType={ props.orderFormType }
        updateOrderFormType={ props.updateOrderFormType }
        updateBOHTab={ props.updateBOHTab }
        bestBid={ props.bestBid }
        bestAsk={ props.bestAsk }
        buys={ props.buys }
        sells={ props.sells }/>;
    } else if (node.isVisible() && component === `market-alerts`) {
      return <>
        <NewAlert
          markets={ props.markets }
          exchanges={ props.exchanges }
          active={ props.active }
          lastPrice={ props.lastPrice }
          marketLabel={ marketLabel }
          options={ Object.keys(props.ticker).filter((t) => t !== `volume`).map((k) => ({
            label: `${ props.t(`app:${ k }`) } (${ toFixedDecimals(props.ticker[k], false, `price`, props.active.market) })`,
            value: toFixedDecimals(props.ticker[k], false, `price`, props.active.market)
          })).concat([
            {
              label: `All Time High (${ props.range.allTime.high })`,
              value: props.range.allTime.high
            },
            {
              label: `All Time Low (${ props.range.allTime.low })`,
              value: props.range.allTime.low
            },
            {
              label: `30 Day High (${ props.range[`30D`].high })`,
              value: props.range[`30D`].high
            },
            {
              label: `30 Day Low (${ props.range[`30D`].low })`,
              value: props.range[`30D`].low
            },
            {
              label: `7 Day High (${ props.range[`7D`].high })`,
              value: props.range[`7D`].high
            },
            {
              label: `7 Day Low (${ props.range[`7D`].low })`,
              value: props.range[`7D`].low
            },
            {
              label: `1 Day High (${ props.range[`1D`].high })`,
              value: props.range[`1D`].high
            },
            {
              label: `1 Day Low (${ props.range[`1D`].low })`,
              value: props.range[`1D`].low
            }
          ]) }
          alertType={ props.alertType }
          updateAlertType={ props.updateAlertType } />
        <MarketAlerts
          active={ props.active }
          deleteAlert={ props.deleteAlert }
          exchanges={ props.exchanges }
          markets={ props.markets }
          height={ node.getRect()?.height }
          alertsLastUpdateTime={ props.alertsLastUpdateTime }
          refreshAlertsLastUpdateTime={ props.refreshAlertsLastUpdateTime }/>
      </>;
    } else if (node.isVisible() && component === `market-insights`) {
      return <MarketInsights
        baseCurrency={ props.researchBaseCurrency }
        quoteCurrency={ props.researchQuoteCurrency }
        baseCurrencyItbSignals={ props.baseCurrencyItbSignals }
        quoteCurrencyItbSignals={ props.quoteCurrencyItbSignals }
        currencies={ props.currencies }
        cmCalCategories={ props.cmCalCategories }
        cmCalCoins={ props.cmCalCoins }/>;
    } else if (node.isVisible() && component === `market-notes`) {
      return <MarketNotes
        marketLabel={ marketLabel }
        notes={ props.notes }
        updateNotes={ props.updateNotes } />;
    }
  };

  const handleEvent = (event: any) => {
    switch(event.type) {
    case BUY_MODE_REQUESTED:
    case SELL_MODE_REQUESTED:
      model?.doAction(Actions.selectTab(`market-trades`));
      break;
    case ALERT_MODE_REQUESTED:
      model?.doAction(Actions.selectTab(`market-alerts`));
      break;
    }
  };

  const setModelJson = (json) => {
    if (props.marketSwitcherVersion === `v1`) {
      json.global.borderSize = 200;
    } else {
      json.global.borderSize = 350;
    }
    setModel(Model.fromJson(json));
  };

  useEffect(() => {
    listenForEvent(BUY_MODE_REQUESTED, handleEvent);
    listenForEvent(SELL_MODE_REQUESTED, handleEvent);
    listenForEvent(ALERT_MODE_REQUESTED, handleEvent);

    return () => {
      removeEventListener(BUY_MODE_REQUESTED, handleEvent);
      removeEventListener(SELL_MODE_REQUESTED, handleEvent);
      removeEventListener(ALERT_MODE_REQUESTED, handleEvent);
    };
  }, [model]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (adjustSplitTimestamp) {
        emitEvent(WINDOW_RESIZED);
      }
    }, 400);

    return () => {
      clearTimeout(timeout);
    };
  }, [adjustSplitTimestamp]);

  useEffect(() => {
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  useEffect(() => {
    if (isSmall) {
      setModelJson(smallSize);
    } else {
      getLayout(`markets_layout`)
        .then((data) => {
          if (data.success && data.result) {
            setModelJson(JSON.parse(data.result));
          } else {
            setModelJson(xlSize);
          }
        })
        .catch(() => setModelJson(xlSize));
    }
  }, [isSmall]);

  useEffect(() => {
    const tabNode = model?.getNodeById(props.tourSelectedTabNodeId);
    if (tabNode && !tabNode.isVisible()) {
      model?.doAction(Actions.selectTab(props.tourSelectedTabNodeId));
    }
  }, [props.tourSelectedTabNodeId]);

  return (
    <>
      { model &&
        <Layout 
          ref={ layoutRef }
          model={ model }
          onModelChange={ onModelChange }
          onAction={ onAction }
          titleFactory={ titleFactory }
          factory={ factory } 
          popoutURL={ `/popout.html` }
          realtimeResize={ true }/>
      }
    </>
  );
};

export default translate(`markets`)(MarketFlexlayout);

// @flow
'use strict';

import {
  UPDATE_ACTIVE_BOARD_ID,
  UPDATE_BOARD_COLUMNS,
  TOGGLE_BOARD_LOCKED
} from '../actions/index.js';

type State = {
  activeBoardId: number,
  boardColumns: number,
  boardLocked: boolean
};

type Action = {
  type: string,
  payload: any
};

export const initialState: State = {
  activeBoardId: -1,
  boardColumns: 8,
  boardLocked: false
};

export default function boards(state: State = initialState, action: Action) {
  switch(action.type) {
  case UPDATE_ACTIVE_BOARD_ID:
    return {
      ...state,
      activeBoardId: action.payload
    };
  case UPDATE_BOARD_COLUMNS:
    return {
      ...state,
      boardColumns: action.payload
    };
  case TOGGLE_BOARD_LOCKED:
    return {
      ...state,
      boardLocked: !state.boardLocked
    };
  }
  return state;
}

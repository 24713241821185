// @flow
'use strict';

import React from 'react';
import Globe from "../../../svgs/Globe.jsx";

export const MOTDLink = (props: any) => {
  const { text, triggerMOTDModal } = props;

  return(
    <a data-testid={ `trigger-daily-briefing-modal` } onClick={ () => triggerMOTDModal() }>
      { Globe }
      <span>
        { text }
      </span>
    </a>
  );
};

'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path fill-rule="evenodd" clip-rule="evenodd" d="M1 11C1 10.4477 1.44772 10 2 10C2.23694 10 2.50857 9.89855 2.71775 9.7172C2.92468 9.53781 3 9.34657 3 9.2V6.2C3 3.3933 5.06511 1 8 1C10.9349 1 13 3.3933 13 6.2V9.2C13 9.34657 13.0753 9.53781 13.2823 9.7172C13.4914 9.89855 13.7631 10 14 10C14.5523 10 15 10.4477 15 11C15 11.5523 14.5523 12 14 12H2C1.44772 12 1 11.5523 1 11ZM14 11C13.2933 11 12.5806 10.5927 12.2276 10C12.0841 9.75901 12 9.48737 12 9.2V6.2C12 3.8804 10.3196 2 8 2C5.6804 2 4 3.8804 4 6.2V9.2C4 9.48737 3.91595 9.75901 3.77242 10C3.41944 10.5927 2.70674 11 2 11H14ZM10.6667 6.13337C10.6667 4.58698 9.54647 3.33337 8.00007 3.33337C7.99995 4.39251 8.00001 5.63862 8.00007 6.77465L8.00007 9.33337H10.6667V6.13337ZM10 13C10 14.1046 9.10457 15 8 15C6.89543 15 6 14.1046 6 13H10Z" fill="currentColor"/>`,
  {
    width: `16px`,
    height: `16px`,
    viewBox: `0 0 16 16`
  }
);
/* eslint-enable */

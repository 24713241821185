// @flow
'use strict';

import React from 'react';
import AlertBell from "../../../svgs/AlertBell.jsx";
import { NavLink } from 'react-router-dom';

type MenuLinkProps = {
  closeDropdownMenus: any,
  text: string,
}

export const NotificationsLink = (props: MenuLinkProps) => {
  const { text, closeDropdownMenus } = props;

  return (
    <NavLink onClick={ () => closeDropdownMenus() } to="/settings/notifications">
      { AlertBell }
      <span>
        { text }
      </span>
    </NavLink>
  );
  
};

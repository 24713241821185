// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { scaleTime } from "d3-scale";
import { ChartCanvas, Chart } from "react-stockcharts";
import { CandlestickSeries,
  AreaSeries,
  LineSeries,
  BarSeries,
  VolumeProfileSeries } from "react-stockcharts/lib/series";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import { getActiveTheme } from '../../helpers/ThemeHelper.js';
import { last, head, functor } from "react-stockcharts/lib/utils";
import { CrossHairCursor, MouseCoordinateY, EdgeIndicator } from "react-stockcharts/lib/coordinates";
import { OHLCTooltip, OHLCTooltipDisplayFactory } from "react-stockcharts/lib/tooltip";
import { format } from "d3-format";
import { timeFormat } from "d3-time-format";
import { sma, change } from "react-stockcharts/lib/indicator";
import { extent as d3Extent } from "d3-array";
import type { Market } from '../../types/Market.js';

type OHLCV = {
  open: number,
  high: number,
  low: number,
  close: number,
  volume: number,
  timeStart: string,
  timeEnd: string,
  date: any
};

type Props = {
  t: any,
  ohlcv: Array<OHLCV>,
  name: string,
  width: number,
  height: number,
  realWidth: number,
  type: string,
  theme: string,
  market: Market,
  showVolume: boolean,
  showVolumeProfile: boolean
};

const initialBarsInView = 120;
const margin = { left: 50, right: 65, top: 10, bottom: 25 };
const defaultPrecision = 8;
const xAccessor = (d) => d && d.date ? d.date : new Date().toISOString();

const xExtents = (data) => {
  const xAccessor = (d) => d && d.date ? d.date : new Date().toISOString(),
    start = xAccessor(last(data)),
    end = xAccessor(data[Math.max(0, data.length-initialBarsInView > 0 ? data.length-initialBarsInView : 0)]),
    xExtents = [start, end];
        
  return d3Extent(xExtents.map((d) => functor(d)).map((each) => each(data, xAccessor)));
};

const smaVolume20 = sma()
  .id(3)
  .options({
    windowSize: 20,
    sourcePath: `volume`,
  })
  .merge((d, c) => {d.smaVolume20 = c;})
  .accessor((d) => d.smaVolume20);

const getObjectWidth = (props, moreProps, widthRatio) => {
  const { xScale, xAccessor, plotData } = moreProps;

  const width = Math.abs(
    (xScale(xAccessor(last(plotData))) - xScale(xAccessor(head(plotData)))) / (plotData.length - 1)
  );
  
  return width * widthRatio;
};

const getCandleWidth = (props, moreProps) => {
  return getObjectWidth(props, moreProps, .35);
};

const getBarWidth = (props, moreProps) => {
  return getObjectWidth(props, moreProps, .5);
};

const MarketChartPanelChart = ({ 
  t, 
  ohlcv, 
  name, 
  width, 
  realWidth, 
  height, 
  type, 
  theme, 
  market,
  showVolume,
  showVolumeProfile
}: Props) => {
  let precisionFormat = format(`.${ market?.basePricePrecision || defaultPrecision }f`);

  if (ohlcv.length == 0) {
    return (
      <div className="panel-loading">
        <span>
          { t(`app:loading`) }
        </span>
      </div>
    );
  }

  const activeTheme = getActiveTheme(theme, true);
  const gridHeight = height - margin.top - margin.bottom;
  const gridWidth = width - margin.left - margin.right;
  const yGrid = { innerTickSize: -1 * gridWidth, tickStrokeOpacity: 0.2 };
  const xGrid = { innerTickSize: -1 * gridHeight, tickStrokeOpacity: 0.2 };

  const changeCalculator = change();
  ohlcv = changeCalculator(ohlcv);
  smaVolume20(ohlcv);

  return (
    <ChartCanvas
      height={ height }
      ratio={ window.devicePixelRatio || 1 }
      width={ width }
      margin={ margin }
      type={ `hybrid` }
      seriesName={ name }
      data={ ohlcv }
      xAccessor={ xAccessor }
      displayXAccessor={ xAccessor }
      xExtents={ xExtents }
      clamp={ `left` }
      xScale={ scaleTime() }>
      <Chart id={ 1 } yExtents={ (d) => [d.high, d.low] }>
        <XAxis
          axisAt="bottom"
          orient="bottom"
          ticks={ Math.ceil(width / 106) }
          stroke={ activeTheme[`--gray-1`] }
          tickStroke={ activeTheme[`--body-text-color`] }
          fontFamily={ activeTheme[`--font-stack`] }
          fontSize={ 9 }
          { ...xGrid } />
        <YAxis
          axisAt="right"
          orient="right"
          ticks={ 10 }
          stroke={ activeTheme[`--gray-1`] }
          tickStroke={ activeTheme[`--body-text-color`] }
          fontFamily={ activeTheme[`--font-stack`] }
          fontSize={ 9 }
          { ...yGrid } />
        <MouseCoordinateY
          at="right"
          orient="right"
          rectHeight={ 15 }
          rectWidth={ 30 }
          yAxisPad={ 8 }
          fontSize={ 10 }
          fontFamily={ activeTheme[`--font-stack`] }
          displayFormat={ precisionFormat } />
        {
          showVolumeProfile && (
            <VolumeProfileSeries
              fill={ ({ type }) => type === `up` ? getActiveTheme(theme).green : getActiveTheme(theme).red }
              opacity={ .35 }/>
          )
        }
        {
          type == `candlestick` ? (
            <CandlestickSeries
              wickStroke={ activeTheme[`--body-text-color-alpha-20`] }
              fill={ (d) => d.close > d.open ? getActiveTheme(theme).green : getActiveTheme(theme).red }
              stroke={ activeTheme[`--body-text-color-alpha-20`] }
              opacity={ 1 }
              candleStrokeWidth={ 1 }
              width={ getCandleWidth }/>
          ) : type == `area` ? (
            <AreaSeries
              stroke={ activeTheme[`--light-blue-`] }
              fill={ activeTheme[`--light-blue-alpha-50`] }
              yAccessor={ (d) => d.close } />
          ) : (
            <LineSeries
              stroke={ activeTheme[`--light-blue-`] }
              yAccessor={ (d) => d.close } />
          )
        }
        <CrossHairCursor stroke={ activeTheme[`--body-text-color`] } />
        <EdgeIndicator
          itemType="last" orient="right" edgeAt="right"
          yAccessor={ (d) => d.close }
          fill={ (d) => d.close > d.open ? getActiveTheme(theme).green : getActiveTheme(theme).red }
          textFill={ activeTheme[`--button-text-color`] }
          rectHeight={ 11 }
          rectWidth={ 25 }
          yAxisPad={ 8 }
          displayFormat={ precisionFormat }
          fontFamily={ activeTheme[`--font-stack`] }
          lineStroke={ activeTheme[`--body-text-color-alpha-20`] }
          lineOpacity={ 0.5 }
          fontSize={ 9 }/>
        {
          realWidth > 2 && (
            <OHLCTooltip
              labelFill={ activeTheme[`--body-text-color`] }
              fontSize={ 9 }
              ohlcFormat={ precisionFormat }
              volumeFormat={ format(`.4f`) }
              xDisplayFormat={ timeFormat(`%Y-%m-%d %H:%M`) }
              origin={ [0, -8] }>
              {  
                OHLCTooltipDisplayFactory(({ open, close }) => 
                  open > close ? getActiveTheme().red : getActiveTheme().green) 
              }
            </OHLCTooltip>
          )
        }

      </Chart> 
      {
        showVolume && (
          <Chart
            id={ 2 }
            yExtents={ [(d) => d.volume, smaVolume20.accessor()] }
            height={ 70 }
            origin={ (w, h) => [0, h - 70] }>
            <YAxis
              axisAt="left"
              orient="left"
              ticks={ 4 }
              fontSize={ 8 }
              stroke={ activeTheme[`--body-text-color`] }
              tickStroke={ activeTheme[`--body-text-color`] }
              fontFamily={ activeTheme[`--font-stack`] }/>
            <MouseCoordinateY
              at="left"
              orient="left"
              fontSize={ 10 }
              rectHeight={ 15 }
              rectWidth={ 30 }
              yAxisPad={ -8 }
              fontFamily={ activeTheme[`--font-stack`] }
              displayFormat={ format(`.2f`) } />
            <BarSeries
              yAccessor={ (d) => d.volume }
              width={ getBarWidth }
              opacity={ .5 }
              fill={ (d) => d.close > d.open ? getActiveTheme(theme).green : getActiveTheme(theme).red } />
            <AreaSeries 
              yAccessor={ smaVolume20.accessor() } 
              stroke={ smaVolume20.stroke() } 
              fill={ smaVolume20.fill() } />
          </Chart>
        )
      }
    </ChartCanvas>
  );
};

const mapStateToProps = (state) => ({
  theme: state.redisPrefs.theme
});

export { MarketChartPanelChart as PureMarketChartPanelChart };
export default translate(`boards`)(connect(mapStateToProps)(MarketChartPanelChart));

// @flow
'use strict';

import React from 'react';
import Info from "../../svgs/Info.jsx";

export const TourThisPageButton = (props: any) => {
  const { text, triggerPageTour } = props;

  return(
    <li>
      <a data-testid={ `trigger-page-tour` } onClick={ () => triggerPageTour() }>
        { Info }
        <span>
          { text }
        </span>
      </a>
    </li>
  );
};

// @flow
'use strict';

import React, { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { getActiveTheme } from '../../helpers/ThemeHelper.js';
import { toLocalDate } from "../../helpers/DateHelper.js";
import { getOhlc } from '../../helpers/api/BalanceApi.js';
import { getSingleCurrency } from '../../helpers/api/CurrencyApi.js';
import { getDeadMarkets } from '../../helpers/api/MarketsApi.js';
import CollapseLeft from '../../svgs/CollapseLeft.jsx';
import Crypt from './Crypt.jsx';
import TheCrypt from '../../svgs/TheCrypt.jsx';
import SelectField from '../utilities/SelectField.jsx';
require(`highcharts/modules/stock`)(Highcharts);

const dateFormat = `%Y%Y%Y%Y-%M%M-%D%D`;

const CryptDetail = ({ currCode, deadCurrency, t, isFullAccess }: any) => {
  const [currency, setCurrency] = useState({});
  const [options, setOptions] = useState(null);
  const [quoteCurrCodes, setQuoteCurrCodes] = useState([]);
  const [selectedQuoteCurrCode, setSelectedQuoteCurrCode] = useState(null);
  const [markets, setMarkets] = useState([]);
  
  const theme = getActiveTheme(undefined, true);

  const history = useHistory();
  
  const onBack = () => {
    history.push(`/the-crypt`);
  };

  const startDate = useMemo(() => {
    return toLocalDate(new Date(Date.parse(deadCurrency.firstTradeDate)), dateFormat);
  },[deadCurrency.firstTradeDate]);

  const endDate = useMemo(() => {
    return toLocalDate(new Date(Date.parse(deadCurrency.lastTradeDate)), dateFormat);
  },[deadCurrency.lastTradeDate]);

  const promiseOhlc = (exchName, exchCode, market) => new Promise((resolve) => {
    getOhlc({ 
      exchange: exchCode,
      market: market,
      data: `d`,
      params: { EndDate: endDate, StartDate: startDate } 
    }, (data) => {
      resolve({
        id: exchCode,
        name: `${exchName} (${exchCode})`,
        data: data.success ? data.result.map((d) => ([Date.parse(d.timeEnd), d.close])) : []
      });      
    }
    );
  });

  useEffect(() => {
    getSingleCurrency(currCode).then((data) => setCurrency(data.success ? data.result.data : {}));

    getDeadMarkets(currCode).then((data) => { 
      if (data.result?.length) {
        setSelectedQuoteCurrCode(data.result[0].quoteCurrCode);

        const quotes = [...new Set(data.result.map((d) => d.quoteCurrCode))].map((d) => ({ value: d, label: d } ));
        setQuoteCurrCodes(quotes);

        setMarkets(data.result);
      }
    });
  }, [currCode]);

  useEffect(() => {
    if (markets.length && selectedQuoteCurrCode) {
      const filteredMarkets = markets.filter((m) => m.quoteCurrCode === selectedQuoteCurrCode);
      
      Promise.all(filteredMarkets.map((m) => ( promiseOhlc(m.exchName, m.exchCode, m.marketName) )))
        .then((values) => {
          const opt = {
            chart: {
              backgroundColor: theme[`--gray2-theme-alpha`],
              style: { "fontFamily": theme[`--font-stack`], "fontSize": `1.7rem` }
            },
            colors: [ `#2caffe`, `#544fc5`, `#00e272`, `#fe6a35`, `#6b8abc`, `#d568fb`, `#2ee0ca`, `#fa4b42`, `#feb56a`, `#91e8e1` ],
            rangeSelector: {
              selected: 4,
              inputStyle: { "fontFamily": theme[`--font-stack`], color: theme[`--brand-blue-`], "fontWeight": `bold` },
              labelStyle: { "fontFamily": theme[`--font-stack`], color: theme[`--body-text-color`], "fontSize": `1.7rem` },
              enabled: isFullAccess,
            },
            yAxis: {
              labels: {
                format: `{#if (gt value 0)}+{/if}{value}%`
              },
              plotLines: [{
                value: 0,
                width: 2,
                color: theme[`--body-text-color`],
              }]
            },
            plotOptions: {
              series: {
                compare: `percent`,
                showInNavigator: true
              }
            },
            tooltip: {
              pointFormat: `<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.change}%)<br/>`,
              // valueDecimals: 2,
              split: true,
              enabled: isFullAccess,
            },
            legend: {
              enabled: true,
              itemHoverStyle: { "fontFamily": theme[`--font-stack`], "color": theme[`--body-text-color`] },
              itemHiddenStyle: { "fontFamily": theme[`--font-stack`], "color": theme[`--body-text-color-alpha-38`] },
              itemStyle: { "fontFamily": theme[`--font-stack`], "color": theme[`--body-text-color-alpha-70`] }
            },
            scrollbar: {
              barBorderRadius: 0,
            // barBackgroundColor: theme[`--gray-2-table-alpha`],
            // barBorderColor: theme[`--gray-2-table-alpha`],
            // buttonBackgroundColor: theme[`--gray-2-table-alpha`],
            // buttonBorderColor: theme[`--gray-2-table-alpha`],
            // buttonArrowColor: theme[`--body-text-color`],
            // rifleColor: theme[`--body-text-color`],
            },
            credits: ``,
            series: values,
          };
          setOptions(opt);
        }
        );
    }
  }, [markets, selectedQuoteCurrCode]);

  return (
    <div className='the-crypt-page'>
      <div className='header'>
        <h1 className="page-title" style={ { gap: `.5rem` } }>
          <span onClick={ onBack } className='arrow' style={ { cursor: `pointer`, marginRight: `2rem` } }>
            <CollapseLeft />
          </span>

          <span className='logo'>
            { TheCrypt }
          </span>
          { `The Crypt` }
        </h1>
      </div>
      <div className='the-crypt-detail'>
        <Crypt currency={ deadCurrency } isClickable={ false }/>

        <div  style={ { maxWidth: `80%` } }>
          <h2>
            { `${currency?.currName || ``} ${ currency?.currCode ? [`(`,currency.currCode.toUpperCase(),`)`].join(``) : ``}` }
          </h2>

          <p style={ { fontSize: `1.7rem` } }>
            { currency?.description }
          </p>
          
          <div className="research-social">
            {
              [
                {
                  key: `urlAnnThread`,
                  title: t(`markets:annThread`),
                  img: `/assets/img/bitcointalk.png`
                },
                {
                  key: `urlTwitter`,
                  title: t(`markets:twitter`),
                  img: `/assets/img/twitter.png`
                },
                {
                  key: `urlFacebook`,
                  title: t(`markets:facebook`),
                  img: `/assets/img/facebook.png`
                },
                {
                  key: `urlLinkedin`,
                  title: t(`markets:linkedin`),
                  img: `/assets/img/linkedin.png`
                },
                {
                  key: `urlGplus`,
                  title: t(`markets:googleplus`),
                  img: `/assets/img/googleplus.png`
                },
                {
                  key: `urlForum`,
                  title: t(`markets:forum`),
                  img: `/assets/img/forum.png`
                },
                {
                  key: `urlWallet`,
                  title: t(`markets:wallet`),
                  img: `/assets/img/wallet.png`
                },
                {
                  key: `urlWebsite`,
                  title: t(`markets:website`),
                  img: `/assets/img/website.png`
                },
                {
                  key: `urlWhitepaper`,
                  title: t(`markets:whitepaper`),
                  img: `/assets/img/whitepaper.png`
                },
                {
                  key: `urlBlockExplorer`,
                  title: t(`markets:blockExplorer`),
                  img: `/assets/img/blockexplorer.png`
                }
              ].map((l, i) => {
                if (currency.hasOwnProperty(l.key) && currency[l.key] && currency[l.key].toString().length > 0) {
                  return (
                    <a key={ i } target="_blank" rel="noopener noreferrer"  href={ isFullAccess ? currency[l.key] : `/` } title={ l.title }>
                      <div className={ `social-icon ` + l.key } />
                    </a>
                  );
                }
              })
            }
          </div>
        </div>
      </div>
      { quoteCurrCodes.length  && options ? 
        <div style={ {
          height: `55rem`,
          width: `90vw`,
          overflow: `auto`,
          margin: `2rem 5vw 0`
        } }>
          <div style={ { width: `20rem`, padding: `0 1rem`, margin: 0 } }>
            <SelectField
              searchable={ false }
              label={ t(`app:currency`) }
              name={ `selectedQuoteCurrCode` }
              value={ selectedQuoteCurrCode }
              options={ quoteCurrCodes }
              onChange={ (e, v) => setSelectedQuoteCurrCode(v) }
              disabled={ !isFullAccess }/>
          </div>

          <HighchartsReact 
            highcharts={ Highcharts }
            constructorType={ `stockChart` }
            options={ options }/>
        </div>
        :
        <></>
      }
    </div>
  );
};

export default CryptDetail;

// @flow
'use strict';

import React from 'react';
import TextField from '../utilities/TextField.jsx';
import ScrollableArea from "../utilities/ScrollableArea.jsx";


type Props = {
  callback?: *,
  markets: Array<any>,
  active: { exchmktId: number },
  search: string,
  changeSearch: (search: string) => void
};


const DemoMarketSwitcher = (props: Props) => {
  const { callback, markets, active, search, changeSearch } = props;

  return (
    <div className={ `demo-market-switcher market-switcher fade-in fill-mode` }>
      <div className="filter-row">
        <TextField
          onDoubleClick={ () => changeSearch(``) }
          label={ `Filter` }
          name="filter"
          value={ search }
          compact={ true }
          onChange={ (e) => changeSearch(e.target.value) }/>
      </div>
      <div className="list-row">
        <ScrollableArea>
          <ul>
            {
                markets?.map((m) => (
                  <li key={ m.exchmktId }>
                    <a
                      onClick={ () => callback && callback({
                        exchange: m.exchSlug, 
                        baseCurrCode: m.displayBaseCurrencyCode ?? m.baseCurrencyCode, 
                        quoteCurrCode: m.displayQuoteCurrencyCode ?? m.quoteCurrencyCode
                      }) }
                      className={ `exch-link ` + 
                        `${ active.exchmktId=== m.exchmktId ? `current active` : `` } `
                      }>
                      <div className={ `exch-container` }>
                        <span className={ `exch-code` }>
                          <img
                            className={ `exch-img` }
                            src={ window.WWW_URL + `/assets/img/currency/${m.displayBaseCurrencyCode ?? m.baseCurrencyCode}.png` } 
                            width={ `15rem` }
                            height={ `15rem` }/>
                          { m.displayName }
                        </span>
                        <br/>
                        <span className={ `exch-name` }>
                          <img
                            className={ `exch-img` }
                            src={ window.WWW_URL + `/assets/img/exchange/` + m.exchCode + `-icon.png` } 
                            width={ `15rem` }
                            height={ `15rem` }/>
                          { m.exchName }
                        </span>
                      </div>
                    </a>
                  </li>
                ))
            }
          </ul>
        </ScrollableArea>
      </div>
    </div>
  );
};


export default DemoMarketSwitcher;

// @flow
'use strict';

import React from 'react';
import { connect } from 'react-redux';
import { toFixedDecimals } from '../../helpers/NumberHelper.js';
import MarketLargeDepthChart from './MarketLargeDepthChart.jsx';
import type { Market } from "../../types/Market";
import type { MarketOrder } from '../../types/MarketOrder.js';
import { getActiveTheme } from '../../helpers/ThemeHelper.js';
import { 
  emitEvent, 
  MARKETS_PRICE_CLICK, 
} from '../../helpers/EventHelper.js';

type Props = {
  theme: string,
  buys: Array<MarketOrder>,
  sells: Array<MarketOrder>,
  marketSwitcherOpen: boolean,
  marketInfoOpen: boolean,
  market: Market,
  maxBid: number,
  minAsk: number,
  showLarger: boolean,
  resetZoomText: string,
  openOrders: Array<any>,
  activeAlerts: Array<any>,
  showDepthOrdersAlerts: boolean,
  width?: number,
  height?: number,
};

class MarketDepthChartGraph extends React.Component<Props> {
  resizeHandler: () => void;

  constructor(props: Props) {
    super(props);

    this.resizeHandler = this.onWindowResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener(`resize`, this.resizeHandler);
  }

  componentWillUnmount() {
    window.removeEventListener(`resize`, this.resizeHandler);
  }

  onWindowResize() {
    this.forceUpdate();  
  }

  getChartWidth(isMarketSwitcherOpen: boolean = this.props.marketSwitcherOpen, isMarketInfoOpen: boolean = this.props.marketInfoOpen) {
    const mobileModeMaxSize = 1115;
    const rem = parseInt(getActiveTheme(this.props.theme).appScale);
    let width = window.innerWidth - (5 * rem);

    if (isMarketSwitcherOpen && window.innerWidth > mobileModeMaxSize) width -= 26 * rem;
    if (isMarketInfoOpen && window.innerWidth > mobileModeMaxSize) width -= 76 * rem;

    return width;
  }

  shouldComponentUpdate(nextProps: Props): boolean {
    return this.props.buys !== nextProps.buys ||
           this.props.sells !== nextProps.sells ||
           this.props.showLarger !== nextProps.showLarger ||
           this.getChartWidth(this.props.marketSwitcherOpen, this.props.marketInfoOpen) !==
           this.getChartWidth(nextProps.marketSwitcherOpen, nextProps.marketInfoOpen) ||
           this.props.width !== nextProps.width ||
           this.props.height !== nextProps.height;
  }

  onPriceClick = (e: any) => {
    e.preventDefault;
    
    if (e.target.id != `activeMarker`) {
      emitEvent(MARKETS_PRICE_CLICK, {
        price: parseFloat(toFixedDecimals(e.xAxis[0].value, false, `price`, this.props.market))
      });
    }
  }

  render() {
    return (
      <MarketLargeDepthChart
        buys={ this.props.buys }
        sells={ this.props.sells }
        market={ this.props.market }
        maxBid={ this.props.maxBid }
        minAsk={ this.props.minAsk }
        resetZoomText={ this.props.resetZoomText }
        width={ this.props.width || this.getChartWidth() }
        height={ this.props.height ? this.props.height : this.props.showLarger ? 300 : 100 }
        onPriceClick={ this.onPriceClick }
        openOrders={ this.props.openOrders }
        activeAlerts={ this.props.activeAlerts }
        showDepthOrdersAlerts={ this.props.showDepthOrdersAlerts } />
    );
  }
}

const mapStateToProps = (state) => ({
  theme: state.redisPrefs.theme
});

export { MarketDepthChartGraph as PureMarketDepthChartGraph };
export default connect(mapStateToProps)(MarketDepthChartGraph);

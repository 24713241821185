// @flow
'use strict';

import {
  UPDATE_THEME,
  UPDATE_MARKETS_COLLAPSE_PREFS,
  SET_FIAT_CURRENCIES,
  UPDATE_PAGE_PREFS
} from '../actions/index.js';

import type { RedisPrefs } from '../types/RedisPrefs.js';

type Action = {
  type: string,
  payload: any
};

export const initialState: RedisPrefs = {
  marketSwitcherOpen: true,
  marketInfoOpen: true,
  ordersTableOpen: true,
  showLargerOrdersTable: false,
  marketChartOpen: true,
  mktColors: [],
  showOrderHistory: true,
  showOpenPositions: true,
  showCurrentAskLine: true,
  showCurrentBidLine: true,
  balanceSwitcherOpen: true,
  kbShortcutsEnabled: true,
  autoSaveDisabled: false,
  limitDepthChartDeviation: false,
  priceClick: `alert`,
  zeroStyle: `gray`,
  depthStyle: `redgreen`,
  theme: `Dark`,
  language: `en`,
  prefNativeCurrency: ``,
  prefNativeCurrencyId: 0,
  fiatCurrencies: [],
  tradeAlertSound: ``,
  prefAccountingValuation: `FIFO`,
  prefPage: {
    alerts: {
      pageSize: 25
    },
    balancesOrderHistory: {
      pageSize: 25
    },
    marketAlerts: {
      pageSize: 25
    },
    orders: {
      pageSize: 25
    },
    screener: {
      pageSize: 25,
      hideColumns: [
        `quoteCurrencyName`, `miningAlgorithm`, `miningType`, `rsi7Min`, `rsi14Min`, `rsi7Hour`, `rsi14Hour`, 
        `rsi7Day`, `rsi14Day` ,`position`
      ],
      sort: 1,
      sortOrder: 1,
      range: 0,
      searchTerms: [],
      myPositions: false,
      ignoreDead: true,
    },
  },
  tickerPosition: `footer`,
  overrideChartTheme: true
};

export default function redisPrefs(state: RedisPrefs = initialState, action: Action) {
  switch(action.type) {
  case UPDATE_THEME:
    return {
      ...state,
      theme: action.payload.theme
    };
  case UPDATE_MARKETS_COLLAPSE_PREFS:
    if (action.payload.hasOwnProperty(`marketChartOpen`) && !action.payload.marketChartOpen) {
      action.payload.ordersTableOpen = true;
    }

    if (action.payload.hasOwnProperty(`ordersTableOpen`) && !action.payload.ordersTableOpen) {
      action.payload.marketChartOpen = true;
    }

    return {
      ...state,
      ...action.payload
    };
  case SET_FIAT_CURRENCIES:
    return {
      ...state,
      fiatCurrencies: [...action.payload]
    };
  case UPDATE_PAGE_PREFS:
    return {
      ...state,
      prefPage: {
        ...state.prefPage,
        [action.payload.key]: {
          ...state.prefPage[action.payload.key] || {},
          ...action.payload.value
        }
      }
    }; 
  }    
  return state;
}

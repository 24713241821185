// @flow
`use strict`;

import { $ } from "./ApiHelper";

export function getBalances(options: any, onSuccess: (data: any) => void) {
  $({
    url: `/private/user/balances`,
    params: options.params,
    success: onSuccess
  });
}

export function getBalancesHistory(options: any, onSuccess: (data: any) => void, onError?: (data: any) => void) {
  $({
    url: `/private/user/balances/history`,
    params: options.params,
    success: onSuccess,
    error: onError || (() => {})
  });
}

export function getOhlc(options: any, onSuccess: (data: any) => void, onError?: (data: any) => void) {
  $({
    url: `/private/exchanges/${ options.exchange }/markets/${ options.market }/ohlc/${ options.data }`,
    params: options.params,
    success: onSuccess,
    error: onError || (() => {})
  });
}

export function getPublicOhlc(options: any): Promise<any> {
  return new Promise((resolve, reject) => {
    $({
      url: `/public/exchanges/${ options.exchange }/markets/${ options.market }/ohlc/${ options.data }`,
      success: resolve,
      error: reject
    });
  });
}

export function refreshBalanceV1(options: any, onSuccess: (data: any) => void, onError?: (data: any) => void) {
  $({
    url: `/cron/routines/UpdateBalance/${ options.balanceAuthId }`,
    version: `v1`,
    success: onSuccess,
    error: onError || (() => {})
  });
}

export function refreshBalanceV1Silent(options: any, onSuccess: (data: any) => void, onError?: (data: any) => void) {
  $({
    url: `/cron/routines/UpdateBalance/${ options.balanceAuthId }/1/`,
    version: `v1`,
    success: onSuccess,
    error: onError || (() => {})
  });
}

export function refreshBalanceV2(options: any, onSuccess: (data: any) => void, onError?: (data: any) => void) {
  $({
    url: `/private/user/accounts/${ options.balanceAuthId }/balances/refresh`,
    type: `POST`,
    version: `v2`,
    success: onSuccess,
    error: onError || (() => {})
  });
}

export function refreshOrdersV1(options: any, onSuccess: (data: any) => void, onError?: (data: any) => void) {
  $({
    url: `/cron/routines/UpdateOrders/${ options.balanceAuthId }`,
    version: `v1`,
    success: onSuccess,
    error: onError || (() => {})
  });
}

export function refreshOrdersV1Silent(options: any, onSuccess: (data: any) => void, onError?: (data: any) => void) {
  $({
    url: `/cron/routines/UpdateOrders/${ options.balanceAuthId }/1/`,
    version: `v1`,
    success: onSuccess,
    error: onError || (() => {})
  });
}

export function refreshOrdersV2(options: any, onSuccess: (data: any) => void, onError?: (data: any) => void) {
  $({
    url: `/private/user/accounts`,
    type: `PUT`,
    body: { AuthIds: [options.balanceAuthId] },
    version: `v2`,
    success: onSuccess,
    error: onError || (() => {})
  });
}

export function getAccountBalance(authId: string, onSuccess: (data: any) => void, onError?: (data: any) => void) {
  $({
    url: `/private/user/accounts/${authId}/balances?QuoteCurrId=189`, // Find out if we need to make quotecurrId a variable
    type: `GET`,
    version: `v2`,
    success: onSuccess,
    error: onError || (() => {})
  });
}

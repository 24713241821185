// @flow
'use strict';

import React, { useMemo, useEffect, useState } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { toPng } from 'html-to-image';
import PillToggle from '../utilities/PillToggle.jsx';
import ReturnsRadarTable from './ReturnsRadarTable.jsx';
import CurrencySelector from './CurrencySelector/CurrencySelector.jsx';
import Modal from '../utilities/Modal.jsx';
import UpgradeNow from '../user/UpgradeNow.jsx';
import { updateTitle } from '../../helpers/BrowserTitleHelper.js';
import { getSummariesCurrencies } from '../../helpers/api/CurrencyApi.js';
import Search from "../../svgs/Search.jsx";
import ReturnsRadar from "../../svgs/ReturnsRadar.jsx";
import Download from "../../svgs/Download.jsx";
// import { isFullAccessUser } from '../../helpers/UserApplicationsHelper.js';

export type Props = {
  t: any,
  platformId: number,
  // prefSubscriptionExpires: string,
  // prefSubscriptionId: any,
  // prefTrialUsed: boolean,
  subscriptionStatus: string,
  rehydrated: boolean,
}

const ReturnsRadarDataLayerComponent =  (props: Props) => { 
  const { t, platformId,/*  prefSubscriptionExpires, prefSubscriptionId, prefTrialUsed, */ subscriptionStatus, rehydrated } = props;
  const [isFullAccess, setIsFullAccess] = useState(true);
  const [showCurrencySelector, setShowCurrencySelector] = useState(false);
  const [activeTab, setActiveTab] = useState(`monthly`);
  const [currCode, setCurrCode] = useState(`btc`);
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [sortTable, setSortTable] = useState([]);
  
  updateTitle(t(`title`), platformId);

  const tabs = [
    { value: `monthly`, label: t(`monthlyReturns`) },
    { value: `quarterly`, label: t(`quarterlyReturns`) }
  ];
  
  const onStateChangeDataTable = (state) => {
    setSortTable(state?.sorting);
  };

  const data = useMemo(
    () => {
      const isQuarterly = activeTab === `quarterly`;

      const getOpenPrice = (chartData, currentYear, currentMonth, isQuarterly) => {
        let compareDate;
        if (isQuarterly) {
          compareDate = currentMonth === 3 ? `${currentYear - 1}-12` : `${currentYear}-${currentMonth - 3}`;
        } else {
          compareDate = currentMonth === 1 ? `${currentYear - 1}-12` : `${currentYear}-${currentMonth - 1}`;
        }
    
        return chartData.find((d) => {
          const date = new Date(d.time);
          return `${date.getFullYear()}-${date.getMonth() + 1}` === compareDate;
        })?.price;
      };

      const getPercentChange = (chartData, currentYear, currentMonth, closePrice, isQuarterly) => {
        const openPrice = getOpenPrice(chartData, currentYear, currentMonth, isQuarterly);
    
        return openPrice !== undefined ? (closePrice  * 100 / openPrice) - 100 : undefined; 
      };

      const data = Object.entries(chartData.reduce((accum, data) => {
        const date = new Date(data.time);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        
        if (isQuarterly && month % 3 !== 0) return accum;
        
        if (accum[year]) {
          accum[year] = {
            ...accum[year],
            [month]: {
              close: data.price,
              time: data.time,
              change: getPercentChange(chartData, year, month, data.price, isQuarterly),
              open: getOpenPrice(chartData, year, month, isQuarterly),
            }
          };
          return accum;
        } else {
          return {   
            [year]: {
              [month]: {
                close: data.price,
                time: data.time,
                change: getPercentChange(chartData, year, month, data.price, isQuarterly),
                open: getOpenPrice(chartData, year, month, isQuarterly),
              }
            },
            ...accum,
          };
        }
      },{})
      ).map(([time, columns]) => ({
        time: parseInt(time),
        columns
      }));
    
      const avgColumns = Object.entries(data.reduce((accum, d: any) => {
        Object.entries(d.columns).forEach(([time, value]: any) => {
          if (value.change !== undefined) {
            accum[time] = !accum[time] ? [value.change] : [...accum[time], value.change];
          }
        });
        return accum;
      }, {})).reduce((accum, [time, values]: any) => ({
        ...accum,
        [time]: {
          change: values.reduce((a, b) => a + b, 0) / values.length
        }
      }
      ), {});

      const isSortTimeAsc = sortTable?.find((s) => s.id === `time`)?.desc === false;

      return data.length ? [...data, { 'time': isSortTimeAsc ? `9999-avg` : `0-avg`, columns: avgColumns }] : [];
    },
    [chartData, sortTable, activeTab]
  );

  const downloadPic = () => {
    toPng(document.querySelector(`.return-radar-table table`))
      .then((dataUrl) => {
        const link = document.createElement(`a`);
        link.download = `${currCode}-${activeTab}.png`;
        link.href = dataUrl;
        link.click();
      });
  };

  // useEffect(() => { 
  //   if (rehydrated) {
  //     setIsFullAccess(isFullAccessUser(platformId, prefSubscriptionExpires, prefSubscriptionId, prefTrialUsed));
  //   }
  // }, [rehydrated, prefSubscriptionExpires, prefSubscriptionId, prefTrialUsed, platformId]);

  useEffect(() => { 
    if (rehydrated) {
      setIsFullAccess(platformId === 1 || ([`lifetime`, `active`].includes(subscriptionStatus) && platformId === 0));
    }
  }, [rehydrated, subscriptionStatus, platformId]);

  useEffect(() => {
    setIsLoading(true);

    getSummariesCurrencies({ params: { SearchTerm: currCode, Range: 4 } }, (data) => {
      if (data.success) {
        const result = data.result.find((q) => q?.currCode?.toLowerCase?.() === currCode?.toLowerCase?.());
        setChartData(result.chartData ?? []);
      } else {
        setChartData([]);
      }
      setIsLoading(false);
    });
  }, [currCode]);

  return (
    <div className="returns-radar-page">
      { !isFullAccess &&
        <div className='upgrade-now-modal'>
          <Modal title={ `` } onClose={ () => {} }>
            <UpgradeNow t={ t }/>
          </Modal>
        </div>
      }

      <div className="header">
        <div className="title-container">
          <h1
            className="page-title">
            { ReturnsRadar }
            { t(`header:returnsRadar`) }
          </h1>
        </div>        

        <div className="nav-holder">
          <div className={ `nowrap align-left currency-container` } onClick={ () => isFullAccess && setShowCurrencySelector(true) }>
            <div>
              <span>
                { Search }
              </span>
              <span className={ `currency-selected` }>
                { currCode?.toUpperCase?.() }
              </span>
            </div>
          </div>

          <PillToggle
            options={ tabs }
            value={ activeTab }
            onChange={ (e, v) => {
              if (isFullAccess) setActiveTab(v);
            } } />

          <span className='download-container' onClick={ downloadPic }>
            { Download }
          </span>
        </div>
      </div>

      <ReturnsRadarTable 
        activeTab={ activeTab }
        data={ data }
        isLoading={ isLoading }
        onStateChangeDataTable={ onStateChangeDataTable }
        t={ t }/>

      { isFullAccess && showCurrencySelector && <CurrencySelector close={ () => setShowCurrencySelector(false) } setCurrency={ setCurrCode }/> }
    </div>
  );
};

const mapStateToProps = (state) => ({
  platformId: state.userInfo.user.platformId,
  // prefSubscriptionExpires: state.userInfo?.userPrefs?.prefSubscriptionExpires,
  // prefSubscriptionId: state.userInfo?.userPrefs?.prefSubscriptionId,
  // prefTrialUsed: state.userInfo?.userPrefs?.prefTrialUsed,
  subscriptionStatus: state.userInfo?.subscriptionInfo?.subscriptionStatus,
  rehydrated: state._persist.rehydrated,
});

export { ReturnsRadarDataLayerComponent as PureReturnsRadarDataLayerComponent };
export default translate(`returnsRadar`)(connect(mapStateToProps, null)(ReturnsRadarDataLayerComponent));

// @flow
'use strict';

import { TOGGLE_MARKET_CLICKABILITY } from '../index.js';

export function updateMarketsAreClickable(clickable: boolean) {
  return {
    type: TOGGLE_MARKET_CLICKABILITY,
    payload: clickable
  };
}

// @flow
'use strict';

import React from 'react';

const ProBadge = () => (
  <span className='pro-badge'>
    { `pro` }
  </span>
);

export default ProBadge;

// @flow
'use strict';

import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Button from '../utilities/Button.jsx';
import DatePicker from "../utilities/DatePicker.jsx";
import { downloadReport } from '../../helpers/ReportsHelper';
import type { ProfileProps } from './ProfileDataLayer.jsx';


const CustomerStatements = (props: ProfileProps) => {
  
  const { t } = props;
  
  //const btnClass = `cs-btn`;
  const END_DATE = `endDate`;
  const START_DATE = `startDate`;
  const DATE_FORMAT = `MM-dd-yyyy`;

  const actualDate = new Date(); 
  const minDate = new Date(`03-01-2022`);

  const period = `month`; //[ period, setPeriod] = useState(`month`);
  const [ endDate, setEndDate ] = useState(new Date());
  const [ startDate, setStartDate ] = useState(new Date(new Date().getFullYear(), new Date().getMonth() -1, 1));
  

  // const periodEvent = (e, period) => {
  //   setPeriod(period);
  //   return false;
  // };

  const handleUpdateState = (key, date, period) => {
    //console.log(`before`, key, date, period);

    // update startDate to the first day of the month for month/year reports
    // update endDate to the end of the month (or today, whichever is lower) for month/year reports
    if (period != `day`) {
      if (key == `startDate`) {
        date = new Date(date.getFullYear(), date.getMonth(), 1);
      }
      if (key == `endDate`) {
        date = new Date(date.getFullYear(), date.getMonth()+1, 0);
        const today = new Date();
        if (date > today) {
          date = new Date();
        }
      }
    }
    
    //console.log(`after`, key, date, period);
    return (key === START_DATE) ? setStartDate(date) : setEndDate(date);
  };
    

  // const activeButton = (btnPeriod) => 
  //   period === btnPeriod ? `${btnClass} active` : btnClass;


  // const RenderButtonsPeriod = () => {
  //   return <>
  //     <div className="section">
  //       <h4 className="flex">
  //         { t(`user:period`) }
  //       </h4>
  //       <div className="section-inner flex space-between">
  //         <Button
  //           type="primary"
  //           className={ activeButton(`day`) }
  //           onClick={ (e) => periodEvent(e, `day`) }>
  //           { t(`user:day`) }
  //         </Button>

  //         <Button
  //           type="primary"
  //           className={ activeButton(`month`) }
  //           onClick={ (e) => periodEvent(e, `month`) }>
  //           { t(`user:month`) }
  //         </Button>

  //         <Button
  //           type="primary"
  //           className={ activeButton(`year`) }
  //           onClick={ (e) => periodEvent(e, `year`) }>
  //           { t(`user:year`) }
  //         </Button>
  //       </div>
  //     </div>
  //   </>;
  // };

  const RenderDatePickerPeriod = () => {
    return <>
      <div className="section">
        { /*         <h4 className="flex">
          { t(`user:dateRange`) }
        </h4> */ }

        <div className="section-inner flex justify-center">
          {
            period == `day` && (
              <div className="start flex justify-center">
                <DatePicker
                  value={ startDate }
                  min={ minDate }
                  max={ actualDate }
                  dateFormat={ DATE_FORMAT }
                  name={ `prefAlertSite` }
                  onChange={ (value) => handleUpdateState(START_DATE, value, period) }/>
              </div>
            )
          }
          {
            period == `day` && (
              <div className="end flex justify-center">
                <DatePicker
                  value={ endDate }
                  min={ minDate }
                  max={ actualDate }
                  dateFormat={ DATE_FORMAT }
                  onChange={ (value) => handleUpdateState(END_DATE, value, period) }/>
              </div>
            )
          }
          {
            period == `month` && (
              <div className="start flex justify-center">
                <DatePicker
                  value={ startDate }
                  min={ minDate }
                  max={ actualDate }
                  dateFormat={ DATE_FORMAT }
                  name={ `prefAlertSite` }
                  monthsOnly={ true }
                  onChange={ (value) => { handleUpdateState(START_DATE, value, period); handleUpdateState(END_DATE, value, period); } }/>
              </div>
            )
          }
          {
            period == `year` && (
              <div className="start flex justify-center">
                <DatePicker
                  value={ startDate }
                  min={ minDate }
                  max={ actualDate }
                  dateFormat={ DATE_FORMAT }
                  name={ `prefAlertSite` }
                  yearsOnly={ true }
                  onChange={ (value) => { handleUpdateState(START_DATE, value, period); handleUpdateState(END_DATE, value, period); } }/>
              </div>
            )
          }

        </div>
      </div>
    </>;
  };

  const RenderDownloadButtons = () => {
    return <>
      <div className="section">
        { /*         <h4 className="flex">
          { t(`user:reportType`) }
        </h4> */ }
        <div className="section-inner flex justify-center">
          <Button
            type="primary"
            className="cs-btn"
            onClick={ () => 
              downloadReport(`pdf`, period, startDate, endDate) }>
            { t(`downloadPDF`) }
          </Button>

          { /* Json Report        
          <Button
            type="primary"
            className="cs-btn"
            onClick={ () => 
              downloadReport(`json`, period, startDate, endDate) }>
            { t(`downloadJSON`) }
          </Button>
        */  }
        </div>
      </div>
    </>;
  };

  return (
    <div>
      <section>
        <div>
          <h1>
            { t(`user:customerStatements`) } 
          </h1>
          <div className="flex justify-center mb-5">
            <div className="inner-content">
              { /* <RenderButtonsPeriod /> */ }
              <div className="flex align-center">
                <h3 className="justify-center align-left">
Choose a date to generate and download a monthly account statement:
                </h3>
                <RenderDatePickerPeriod />
              </div>
              <RenderDownloadButtons />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};


export default withRouter(CustomerStatements);

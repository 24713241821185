// @flow
'use strict';

/**
 * It takes in a list of markets, a filter text, a favorites only flag, and a list of favorites, and
 * returns a list of markets that match the filter text, and if favorites only is true, only the
 * favorites
 * @param props - any - this is the props object that is passed to the component.
 * @returns An array of markets that are visible and available for the user.
 */

import { useState, useEffect } from 'react';
import { getMarketPair, sortMarkets } from '../../../helpers/MarketPairHelper.js';

export const useVisibleMarkets = (props: any) => {
  const [visibleMarkets, setVisibleMarkets] = useState([]);
  const { markets, filterText, favoritesOnly, favorites } = props;

  useEffect(() => {
    setVisibleMarkets(markets.filter((m) => {
      if (favoritesOnly && !favorites.some((f) => {
        return f.exchCode === m.exchCode && f.displayName === m.displayName;
      })) return false;

      if (filterText === ``) return true;

      if (filterText.indexOf(`:`) < 0) {
        const displayName = getMarketPair(m).toString().toLowerCase().indexOf(filterText.toLowerCase()) > -1;
        const currencyName = getMarketPair(m).baseFull?.toLowerCase().indexOf(filterText.toLowerCase()) > -1;
        return currencyName || displayName; 
      } else {
        const [exchFilter, mktFilter] = filterText.split(`:`).map((value) => value.toLowerCase());
        return getMarketPair(m).toString().toLowerCase().indexOf(mktFilter) > -1 && m.exchCode.toLowerCase().indexOf(exchFilter) > -1;
      }
    }).sort(sortMarkets));
  }, [markets, filterText, favoritesOnly, favorites]);

  return visibleMarkets;
};

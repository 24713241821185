// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import InvalidMarketPanelHelper from './InvalidMarketPanelHelper.jsx';
import EditPanelHelper from './EditPanelHelper.jsx';
import { getMarketFromPanelSettings, getExchangeFromPanelSettings } from '../../helpers/MarketPairHelper.js';
import { 
  getComponentFromPanelTypeId, 
  NOTES,
  NEED_CURRENCIES,
  NEED_BALANCES,
  NEED_ACCOUNTS,
  NEED_MARKETS,
  NEED_EXCHANGES 
} from '../../helpers/PanelTypeHelper.js';
import type { PanelType as ActualPanelType } from '../../types/PanelType.js';
import type { Panel as PanelType } from '../../types/Panel.js';
import type { Market } from '../../types/Market.js';
import type { Exchange } from '../../types/Exchange.js';
import type { Account } from '../../types/Account.js';
import type { Balance } from '../../types/Balance.js';
import type { Currency } from '../../types/Currency.js';

type Props = {
  locked: boolean,
  t: any,
  panelTypes: Array<ActualPanelType>,
  markets: Array<Market>,
  exchanges: Array<Exchange>,
  currencies?: Array<Currency>,
  panel: PanelType,
  realWidth: number,
  realHeight: number,
  width: number,
  height: number,
  panelData: any,
  cancelEditPanelInline: (id: number) => void,
  savePanelInline: (id: number, body: any) => void,
  modifyingPanelsInline: Array<number>,
  canCreateNewPanel: boolean,
  editMarket: (panel: any) => void,
  setPanelMarket: any,
  accounts?: Array<Account>,
  balances?: Array<Balance>,
  refreshBalance: (n: number, d: boolean) => Promise<any>,
};

class Panel extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  shouldComponentUpdate(nextProps: Props) {
    const modifying = this.props.modifyingPanelsInline.includes(this.props.panel.panelId);
    const nextModifying = nextProps.modifyingPanelsInline.includes(nextProps.panel.panelId);

    if (modifying || nextModifying) {
      return modifying !== nextModifying;
    }

    return true;
  }

  render() {
    const {
      t,
      locked,
      panelTypes,
      markets,
      exchanges,
      panel,
      realWidth,
      realHeight,
      width,
      height,
      cancelEditPanelInline,
      savePanelInline,
      modifyingPanelsInline,
      canCreateNewPanel,
      editMarket,
      panelData,
      setPanelMarket,
      accounts,
      balances,
      currencies,
      refreshBalance
    } = this.props;

    const settings = JSON.parse(panel.settingsJson || `{}`);
    let Component = getComponentFromPanelTypeId(panel.typeId);

    const { customOptions } = panelTypes.find((t) => t.typeId == panel.typeId) || { customOptions: { } };

    if (
      customOptions.hasOwnProperty(`market`) && 
      customOptions.hasOwnProperty(`exchange`) &&
      !getMarketFromPanelSettings(markets, JSON.parse(panel.settingsJson || `{}`)) && 
      markets.length > 0 &&
      panel.typeId !== NOTES
    ) {
      Component = InvalidMarketPanelHelper(panel, editMarket);
    }

    if (modifyingPanelsInline.includes(panel.panelId)) {
      Component = EditPanelHelper(
        t, 
        panelTypes, 
        panel.typeId, 
        panel.sizeId, 
        settings, 
        markets,
        accounts, 
        exchanges, 
        (d) => savePanelInline(panel.panelId, d), 
        () => cancelEditPanelInline(panel.panelId),
        canCreateNewPanel
      );
    }

    return (
      <div
        id={ `PANEL_${ panel.panelId }` }
        className={ `panel ${ locked ? `locked` : `` }` }>
        <div className="panel-body">
          <Component
            market={ getMarketFromPanelSettings(markets, JSON.parse(panel.settingsJson || `{}`)) }
            exchange={ getExchangeFromPanelSettings(exchanges, JSON.parse(panel.settingsJson || `{}`)) }
            settings={ JSON.parse(panel.settingsJson || `{}`) }
            panelData={ panelData }
            setPanelMarket={ setPanelMarket }
            realWidth={ realWidth }
            realHeight={ realHeight }
            width={ width }
            height={ height }
            panel={ panel }
            markets={ NEED_MARKETS.includes(panel.typeId) ? markets : [] }
            exchanges={ NEED_EXCHANGES.includes(panel.typeId) ? exchanges : [] }
            currencies={ NEED_CURRENCIES.includes(panel.typeId) ? currencies : [] }
            balances={ NEED_BALANCES.includes(panel.typeId) ? balances : [] }
            accounts={ NEED_ACCOUNTS.includes(panel.typeId) ? accounts ? accounts : [] : [] }
            refreshBalance={ refreshBalance }/>
        </div>
      </div>
    );
  }
}

export { Panel as PurePanel };
export default translate(`boards`)(Panel);

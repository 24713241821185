// @flow
'use strict';

const THEMES = {
  Light: {
    appScale: `8px`,
    fontStack: `'Roboto', 'Helvetica', Arial, sans-serif`,
    backgroundImage: ``,
    backgroundRepeat: ``,
    bordersNoShadows: false,
    invertToLight: true,
    transparency: 0,
    leftSidebarTransparency: 0,
    rightSidebarTransparency: 0,
    tabTransparency: 0,
    tableTransparency: 0,
    borders: `#FFFFFF`,
    button: `#FFFFFF`,
    body: `#303942`,
    highlight: `#3986F7`,
    brand: `#3B96FF`,
    gray1: `#edf1f6`,
    gray2: `#FFFFFF`,
    gray3: `#EDF1F6`,
    lightBlue: `#3986F7`,
    //red: `#C35A54`,
    //green: `#66A57C`,
    red: `#CD4B50`,
    green: `#50AF7B`,
    yellow: `#CAFF78`
  },
  Dark: {
    appScale: `8px`,
    fontStack: `'Roboto', 'Helvetica', Arial, sans-serif`,
    backgroundImage: ``,
    backgroundRepeat: ``,
    bordersNoShadows: false,
    invertToLight: false,
    transparency: 0,
    leftSidebarTransparency: 0,
    rightSidebarTransparency: 0,
    tabTransparency: 0,
    tableTransparency: 0,
    borders: `#FFFFFF`,
    button: `#FFFFFF`,
    body: `#FFFFFF`,
    highlight: `#FFFFFF`,
    brand: `#3986F7`,
    gray1: `#0E0E0F`,
    gray2: `#19191B`,
    gray3: `#000000`,
    lightBlue: `#3986F7`,
    red: `#FF4242`,
    green: `#50AF7B`,
    yellow: `#CAFF78`
  },
  Classic: {
    appScale: `8px`,
    fontStack: `'Roboto', 'Helvetica', 'Arial', 'sans-serif'`,
    backgroundImage: `https://www.coinigy.com/assets/img/wallpapers/wall_num2.jpg`,
    backgroundRepeat: `cover`,
    bordersNoShadows: false,
    invertToLight: false,
    transparency: 80,
    leftSidebarTransparency: 80,
    rightSidebarTransparency: 80,
    tabTransparency: 80,
    tableTransparency: 80,
    borders: `#FFFFFF`,
    button: `#FFFFFF`,
    body: `#FFFFFF`,
    highlight: `#FFFFFF`,
    brand: `#3377C6`,
    gray1: `#2B2A2A`,
    gray2: `#161515`,
    gray3: `#15335E`,
    lightBlue: `#97CEF5`,
    //red: `#FF0000`,
    //green: `#39A608`,
    red: `#FF4242`,
    green: `#50AF7B`,
    yellow: `#CAFF78`
  }
};

export default THEMES;

// @flow
'use strict';

import React, { useState } from 'react';
import { translate } from 'react-i18next';
import { useSelector } from 'react-redux';
import QRCode from "react-qr-code";
import PillToggle from "../utilities/PillToggle.jsx";

const MobileAppQRCode = ( props: any ) => {
  const { t } = props;

  const [store, setStore] = useState(`appStore`);
  const platformId = useSelector((state) => state.userInfo.user.platformId);

  const MOBILE_APP = {
    'appStore': {
      link: platformId === 1 ? `https://apps.apple.com/us/app/lightspeed-crypto/id6443884604` : `https://apps.apple.com/us/app/coinigy/id1317482120`,
      linkImage: `/assets/img/figma/App_Store_Badge_US-UK.svg`,
      linkLabel: t(`appStoreLinkLabel`),
    },
    'googlePlay': {
      link: platformId === 1 ? `https://play.google.com/store/apps/details?id=com.lightspeedcrypto` : `https://play.google.com/store/apps/details?id=com.coinigy&hl=en_US&gl=US`,
      linkImage: `/assets/img/figma/Play_Store_Badge_EN.svg`,
      linkLabel: t(`googlePlayLinkLabel`),
    }
  };  

  return(
    <div className="mobile-app-qr-code" data-testid={ `mobile-app-qr-code` }>
      <PillToggle
        options={ [
          { label: t(`appStoreLabel`), value: `appStore` },
          { label: t(`googlePlayLabel`), value: `googlePlay` }
        ] }
        value={ store }
        onChange={ (e, v) => setStore(v) } />

      <div className="qr-code-container">
        <QRCode value={ MOBILE_APP[store].link } size={ 200 } viewBox={ `0 0 200 200` }/>                
      </div>
      
      <label>
        { t(`coinigyGetOnMobileDevice`) }
      </label>  
      
      <a
        aria-label={ MOBILE_APP[store].linkLabel }
        href={ MOBILE_APP[store].link }
        rel="nofollow noopener noreferrer"
        target="_blank">
        <img src={ MOBILE_APP[store].linkImage } />
      </a>
    </div>
  );
};

export { MobileAppQRCode as PureMobileAppQRCode };
export default translate(`header`)(MobileAppQRCode);

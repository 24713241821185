// @flow
'use strict';

import React, { useState, useEffect, useRef } from 'react';
import { translate } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import NewOrder from '../orders/NewOrder.jsx';
import type { Account } from '../../types/Account.js';
import type { Currency } from '../../types/Currency.js';
import type { Balance } from '../../types/Balance.js';
import type { Market } from '../../types/Market.js';
import type { Exchange } from '../../types/Exchange.js';
import { toFixedDecimals } from '../../helpers/NumberHelper.js';
import { updateOrderFormActiveType } from '../../actions/markets/updateOrderFormActiveType.js';
import {
  emitEvent,
  DELETE_BUY_NODE,
  DELETE_BUY_STOP_NODE,
  DELETE_SELL_NODE,
  DELETE_SELL_STOP_NODE,
  SWITCH_TO_BUY_MODE,
  SWITCH_TO_SELL_MODE,
} from '../../helpers/EventHelper.js';

type Props = {
  setPanelMarket: (exchCode: string, mktName: string, activePanelEdit: any) => void,
  t: any,
  panelData: any, 
  settings: any, 
  panel: any,
  markets: Array<Market>,
  exchanges: Array<Exchange>,
  currencies: Array<Currency>,
  balances: Array<Balance>,
  accounts: Array<Account>,
  orderFormType: string,
  updateOrderFormType: (orderFormType: string) => void,
};

const MIN_WIDTH_DUAL_MODE = 550;
const ORDER_TYPE_BUY = 1;
const ORDER_TYPE_SELL = 2;

const OrderFormPanel = (props: Props) => {
  const { settings, t, panelData, panel, exchanges, markets, currencies, balances, accounts, orderFormType, updateOrderFormType } = props;
  const [exchangeIdSelectBox, setExchangeIdSelectBox] = useState<number>(-1);
  const [currentAuthId, setCurrentAuthId] = useState<number>(-1);
  const history = useHistory();

  // $FlowIgnore: suppressing this error
  const elementRef = useRef<HTMLDivElement>(null);
  const orderTypeId = useRef(ORDER_TYPE_BUY);
  const isSmallContainer = useRef(false);

  const redirectToAccounts = () => {
    history.push(`/accounts`);
  };

  const handleExchangeMarketOnChange = (e: any) => {
    const { field, value } = e;
    if (field === `market`) {
      const newExchange = exchanges.find((exchange) => exchange.exchId === exchangeIdSelectBox);
      if (newExchange) {
        props.setPanelMarket(newExchange.exchCode, value.currencyText+`/`+ value.baseCurrencyText, panel);
      }
    } 
    if (field === `exchange`) {
      setExchangeIdSelectBox(value.exchId);
    }
  };

  const setCurrentAccount = (id: number) => {
    if (currentAuthId !== id) {
      setCurrentAuthId(id);
    }
  };

  const handleOrderFormTypeChange = (oFType: string, updateMode: boolean = true) => {
    emitEvent(DELETE_BUY_NODE);
    emitEvent(DELETE_BUY_STOP_NODE);
    emitEvent(DELETE_SELL_NODE);
    emitEvent(DELETE_SELL_STOP_NODE);

    if (isSmallContainer.current) {
      updateOrderFormType(`single`);
      if (updateMode) {
        if (orderTypeId.current === ORDER_TYPE_BUY) { 
          emitEvent(SWITCH_TO_SELL_MODE);
          orderTypeId.current = ORDER_TYPE_SELL;
        } else {
          emitEvent(SWITCH_TO_BUY_MODE);
          orderTypeId.current = ORDER_TYPE_BUY;
        }
      }
    } else {
      updateOrderFormType(oFType);
    }
  };

  const activeExchange = exchanges.filter((exchange) => exchange.exchCode === settings.exchange)[0];
  const activeMarket = markets.filter((market) => market.exchCode === activeExchange.exchCode && market.displayName === settings.market)[0];
    
  const active = {
    exchange: activeExchange,
    market: activeMarket
  };
  
  const mkt = `${ settings.exchange }:${ settings.market }`;

  const isReady = (
    markets.length > 0 &&
    exchanges.length > 0 && 
    currencies.length > 0 &&
    panelData.hasOwnProperty(mkt)
  );

  // Start observing the element when the panel is ready
  useEffect(() => {
    if (isReady) {
      const element = elementRef?.current;
  
      if (!element) return;
  
      const observer = new ResizeObserver((entries) => {
        for (const entry of entries) {
          // $FlowIgnore: suppressing this error
          if (entry?.contentBoxSize) {
            const contentBoxSize = entry.contentBoxSize[0];
            if (contentBoxSize?.inlineSize < MIN_WIDTH_DUAL_MODE) {
              if (!isSmallContainer.current) {
                isSmallContainer.current = true;
                handleOrderFormTypeChange(`single`, false);
              }
            } else {
              isSmallContainer.current = false;
            }
          }
        }
      });
  
      observer.observe(element);
      
      return () => {
        // Cleanup the observer by unobserving all elements
        observer.disconnect();
      };
    }
  }, [isReady]);

  useEffect(() => {
    setExchangeIdSelectBox(activeExchange.exchId);
  }, []);
  
  if (isReady) {
    const options = panelData[mkt].ticker ?
      Object.keys(panelData[mkt].ticker).filter((t) => t !== `volume`).map((k) => ({
        label: t(`app:${ k }`) + `(${ toFixedDecimals(panelData[mkt].ticker[k] ?? 0, true, `price`, active.market) })`,
        value: toFixedDecimals(panelData[mkt].ticker[k] ?? 0, true, `price`, active.market)
      })) 
      :
      [];
    
    return (
      <div ref={ elementRef }>
        <NewOrder
          t={ t }
          markets={ markets }
          exchanges={ exchanges }
          currencies={ currencies }
          balances={ balances ? balances : [] }
          accounts={ accounts ? accounts : [] }
          redirectToAccounts={ redirectToAccounts }
          detachedOnChange={ handleExchangeMarketOnChange }
          active={ active }
          lastPrice={ panelData[mkt]?.ticker?.last }
          bestBid={
            panelData[mkt]?.depth?.bids?.length > 0 ?
              panelData[mkt].depth.bids.reduce(function(prev, curr) {
                return prev.price > curr.price ? prev : curr;
              }).price :
              panelData[mkt]?.ticker?.bid
          }
          bestAsk={
            panelData[mkt]?.depth?.asks?.length > 0 ?
              panelData[mkt].depth.asks.reduce(function(prev, curr) {
                return prev.price < curr.price ? prev : curr;
              }).price :
              panelData[mkt]?.ticker?.ask
          }
          updateBOHTab={ () => {} }
          orderFormType={ orderFormType }
          options={ options }
          setCurrentAccount={ setCurrentAccount }
          currentAuthId={ currentAuthId }
          updateOrderFormType={ handleOrderFormTypeChange }/> 
      </div>
    );
  } else {
    return (
      <div className="panel-loading">
        <span>
          { t(`app:loading`) }
        </span>
      </div>
    );
  }

};

// $FlowIgnore: suppressing this error
OrderFormPanel.getPanelTitle = (settings: Settings) => {
  return settings ? `${settings.exchange}:${settings.market}` : `New Order`;
};

const mapStateToProps = (state) => ({
  orderFormType: state.markets.marketsOrderFormActiveType
});

const mapDispatchToProps = (dispatch) => ({
  updateOrderFormType: (orderFormType) => dispatch(updateOrderFormActiveType(orderFormType)),
});

export { OrderFormPanel as PureOrderFormPanel };
export default translate(`boards`)(connect(mapStateToProps, mapDispatchToProps)(OrderFormPanel));


// @flow
'use strict';

import React, { useState, useEffect } from 'react';
import EntityTable from '../utilities/EntityTable.jsx';
import Button from '../utilities/Button.jsx';
import TextField from '../utilities/TextField.jsx';
import { sanitizeNumberInputs, toFixedDown, toFixedDecimalsHTML } from './../../helpers/NumberHelper.js';


type CalculatorMode = 'sell' | 'buy';

const fiatFormatter = new Intl.NumberFormat(`en-US`, {
  style: `currency`,
  currency: `USD`,
});

const calculateFee = (fee: number, mode: CalculatorMode) => {
  if (mode === `buy`) {
    return (1 + fee);
  } else {
    return (1 - fee);
  }
};

const createTableData = (exchanges, calculatorEneabledExchanges, calculatorMode, orderSizeInt, lastPrice) => {
  const costsTable = exchanges.filter((e) => calculatorEneabledExchanges.includes(e.exchCode)).map((e, ) => {
    const objet = {
      exchCode: e.exchCode,
      allInCost: toFixedDown((orderSizeInt) * lastPrice * (calculateFee(e.exchFee, calculatorMode)), 2), 
      exchFee: e.exchFee
    };
    return objet;
  }).sort((a, b) => a.allInCost - b.allInCost);
  if (calculatorMode === `sell`) {
    costsTable.reverse();
  }
  costsTable.map((e) => {
    const currencyImage = `${ window.WWW_URL }/assets/img/exchange/${ e.exchCode}-icon.png`;
    const srcImage = <img className="exch-img" src={ currencyImage } width="20rem" height="20rem" />;
    e.currency = <div className="flex align-center">
      { srcImage  }
      { e.exchCode } 
    </div>;
    e.fees= `$ ${parseFloat(e.allInCost * e.exchFee).toFixed(2)}`;
    
    return e;
  });
  return costsTable;
};

export const AllInCostCalculator = ({ t, active, exchanges, calculatorEneabledExchanges, lastPrice }: any) => {
  const [calculatorMode, setCalculatorMode] = useState<CalculatorMode>(`buy`);
  const [orderSize, setOrderSize] = useState<number>(1);
  const [calculations, setCalculations] = useState([]);

  useEffect(() => {
    const orderSizeInt = orderSize !== `` ? orderSize : 0;
    setCalculations(createTableData(exchanges, calculatorEneabledExchanges, calculatorMode, orderSizeInt, lastPrice));
  }, [active, exchanges, orderSize, calculatorMode]);

  return (
    <div className="all-in-cost-calculator fade-in">
      <div className="fees-parameters">
        <p>
          { t(`adjustedPriceComparison`) }
        </p>
        <div className="calculator-actions">

          <div className="calculator-action-container gray">
            <div className="row button-switch">
              <Button className={ calculatorMode === `buy` ? `active` : `inactive` } type={ `confirm` } onClick={ () => { setCalculatorMode(`buy`); return false; } }>
                { t(`buy`) }
              </Button>
              <Button className={ calculatorMode === `sell` ? `active` : `inactive` } type={ `cancel` } onClick={ () => { setCalculatorMode(`sell`); return false; } }>
                { t(`sell`) }
              </Button>
            </div>
          </div>

          <div className="calculator-action-container">
            <div className={ `row order-size flex` }>

              <div className="inputs-container"> 
                <div className={ `inputs-label` }>
                  { `${ t(`orderSize`) }` }
                </div>
                <TextField 
                  label={ `` } 
                  name={ `orderSize` } 
                  onChange={ (e) => sanitizeNumberInputs(e.target.value,  setOrderSize) } 
                  value={ orderSize }/>
              </div>
            </div> 
          </div>

        </div>
        <div className="calculator-actions">
          <div className="calculator-action-container">
            <div className={ `row market-name` }>
              <p>
                { `Current ${active.market.displayName} Price ${ toFixedDecimalsHTML(lastPrice, false, `price`, active.market, false, true) }` }
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="fees-table">
        <EntityTable 
          columns={ [
            { title: ``, key: `currency`, nosort: true },
            { title: `Fees`, key: `fees`, nosort: true },
            { title: calculatorMode === `buy` ? `Total Cost` : `Total Proceeds`, key: `allInCost`, display: (row) => 
              <span>
                { `${ fiatFormatter.format(row.allInCost) }` }
              </span>, nosort: true, thClass: ` total-cost`, tdClass: `total-cost` },
          ] }
          data={ calculations }
          pageSize={ 5 }
          filterText={ `` }
          hidePagination={ true }/>
      </div>
    </div>
  );
};

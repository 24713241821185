// @flow
'use strict';

import React from 'react';
import Connector from "../../../svgs/Connector.jsx";
import { Link } from 'react-router-dom';

type MenuLinkProps = {
  closeDropdownMenus: any,
  isDevelopmentOrStaging: boolean,
  text: string,
}

export const ReferralProgramLink = (props: MenuLinkProps) => {
  const { isDevelopmentOrStaging, text, closeDropdownMenus } = props;

  if (isDevelopmentOrStaging) {
    return (
      <Link onClick={ () => closeDropdownMenus() } to="/user/referrals">
        { Connector }
        <span>
          { text }
        </span>
      </Link>
    );
  } else {
    // In Prod we will redirect the user to V1
    return (
      <a
        href={ `${ window.WWW_URL }/user/account#referral` }
        rel="noopener noreferrer"
        target="_blank">
        { Connector }
        <span>
          { text }
        </span>
      </a>
    );
  }
};

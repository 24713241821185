import React, { useState, useEffect } from 'react';
import Modal from '../../utilities/Modal.jsx';
import TextField from '../../utilities/TextField.jsx';
import ProgressSpinner from '../../utilities/ProgressSpinner.jsx';
import { 
  postCoinigyTransfersCryptoWalletVerify,
  postCoinigyTransfersCryptoFeeEstimate,
  postCoinigyTransfersCryptoWithdraw
} from '../../../helpers/api/UserApi.js';
import { sanitizeNumberInputs } from '../../../helpers/NumberHelper.js';
import TimesCheck from '../../../svgs/TimesCheck.jsx';
import Verified from '../../../svgs/Verified.jsx';

const LOADING = 0;
const SUCCESS = 1;
const ERROR = 2;

export const CryptoWithdrawModal = ({ close, t, currCode, accountId }) => {
  const [ errors, setErrors ] = useState(``);
  const [ walletAddress, setWalletAddress ] = useState(``);
  const [ quantity, setQuantity ] = useState(``);
  const [ feeEstimate, setFeeEstimate ] = useState(null);
  const [ walletAddressVerify, setWalletAddressVerify ] = useState(LOADING);
  const [ isOpenConfirm, setIsOpenConfirm ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const onVerify = () => {
    setIsOpenConfirm(true);
  };

  const onConfirm = () => {
    setLoading(true);
    postCoinigyTransfersCryptoWithdraw(accountId, {
      currency: currCode,
      quantity,
      receivingWallet: walletAddress,
      feeEstimateId: feeEstimate?.id
    }).then((data) => {
      if (data.success) {
        close(false);
        setLoading(false);
      } else {               
        let error;
        try {
          error = JSON.parse(data.error);
          setErrors(error?.message);
        } catch (e) {
          setErrors(data.error);
        } finally {
          setIsOpenConfirm(false);
          setLoading(false);
        }
      }
    });
  };

  useEffect(() => {
    setFeeEstimate(null);
    const id = setTimeout(() => {
      postCoinigyTransfersCryptoFeeEstimate(accountId, { currCode, quantity: parseFloat(quantity), receivingWallet: walletAddress })
        .then((data) => setFeeEstimate(data?.result));
    }, 300);
      
    return () => clearTimeout(id);
  }, [quantity, walletAddress]);

  useEffect(() => {
    setWalletAddressVerify(LOADING);
    const id = setTimeout(() => {
      postCoinigyTransfersCryptoWalletVerify(accountId, { currCode, walletAddress })
        .then((data) => setWalletAddressVerify(data.success && data.result.isValid ? SUCCESS : ERROR))
        .catch(() => setWalletAddressVerify(ERROR));
    }, 300);
      
    return () => clearTimeout(id);
  }, [walletAddress]);
  
  return (
    <div className={ `withdraw-modal` }>
      <Modal
        title={ `New ` + currCode + ` Withdraw` }
        onConfirm={ onVerify }
        confirmText={ t(`withdraw`) }
        onClose={ () => close(false) }
        displayOnClose={ false }
        confirmDisabled={ walletAddressVerify !== SUCCESS || !quantity || !parseFloat(quantity) > 0 }>
        <div className={ `crypto-withdraw-form` }>
          <div>   
            <div>
              <TextField 
                label={ t(`Quantity`) }
                name={ `quantity` }
                value={ quantity }
                type={ `number` }
                onChange={ (e) => sanitizeNumberInputs(e?.target?.value, setQuantity) }/>

              <div className='row' style={ { alignItems: `center`, gap: `1rem` } }>
                <TextField 
                  label={ t(`Wallet Address`) }
                  name={ `walletAddress` }
                  value={ walletAddress }
                  onChange={ (e) => setWalletAddress(e?.target?.value) }/>
                { walletAddress && walletAddressVerify === LOADING && <ProgressSpinner className={ `spinner` }/> }
                { walletAddress && walletAddressVerify === ERROR && <TimesCheck /> }
                { walletAddress && walletAddressVerify === SUCCESS && <Verified /> }
              </div> 
            </div>
          </div>
          {
            errors &&
                <div className="row errors-message-row">
                  { errors }
                </div>
          }
        </div> 
      </Modal>
      { isOpenConfirm && 
      <Modal
        title={ t(`app:genericConfirm`) }
        onConfirm={ onConfirm }
        onClose={ () => setIsOpenConfirm(false) }
        confirmText={ t(`app:confirm`) }
        cancelText={ t(`app:cancel`) }
        confirmDisabled={ walletAddressVerify !== SUCCESS || !feeEstimate?.id || loading }>
        { walletAddressVerify !== SUCCESS || !feeEstimate?.id ? 
          <ProgressSpinner className={ `spinner` }/>
          :
          <>
            <p className="modal-subtitle"  style={ { textAlign: `left`, padding: `0 2rem`, fontSize: `1.75rem` } }>
              { `Please double-check the details, and press Confirm when you're ready to transmit.` }
            </p>
            <div style={ { textAlign: `left`, padding: `0 2rem` } }>
              <div>
                <span style={ { fontWeight: `bold`, padding: `1rem .5rem` } }>
                  { `Wallet Address:` }
                </span>
                <span>
                  { walletAddress }
                </span>
              </div>
              <div>
                <span style={ { fontWeight: `bold`, padding: `1rem .5rem` } }>
                  { `Quantity:` }
                </span>
                <span>
                  { quantity }
                </span>
              </div>
              <div>
                <span style={ { fontWeight: `bold`, padding: `1rem .5rem` } }>
                  { `The fee estimate:` }
                </span>
                <span>
                  { feeEstimate?.fee }
                </span>
              </div>
            </div>
          </>
        }
      </Modal>
      }
    </div>
  );
};
